import { ResponsiveBar } from '@nivo/bar';
import React, { FunctionComponent } from 'react';
import { Container, Header, Statistic } from 'semantic-ui-react';

type Props = {
  chartData: {
    month: string;
    date: string;
    mails: number;
    'mails uniques': number;
  }[];
  totalCount: number;
  uniqTotalCount: number;
};

export const ProStatsSolicitationsView: FunctionComponent<Props> = ({
  chartData,
  totalCount,
  uniqTotalCount,
}) => {
  return (
    <Container fluid>
      <Header>Mails</Header>
      <br />
      <Statistic.Group size="tiny">
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#FA7921' }}>
            {totalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#FA7921', fontWeight: 'bold' }}>
            Demandes mails
          </Statistic.Label>
        </Statistic>
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#CB5200' }}>
            {uniqTotalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#CB5200', fontWeight: 'bold' }}>
            Demandes mails uniques
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#FA7921', '#CB5200']}
          groupMode="grouped"
          data={chartData}
          keys={['mails', 'mails uniques']}
          indexBy="month"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisLeft={{
            tickValues: chartData.length,
          }}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};
