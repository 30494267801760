import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

type ProReviewReplyModeratedProps = {
  event: MongoEvent;
};

const ProReviewReplyModerated: FunctionComponent<
  ProReviewReplyModeratedProps
> = ({ event }) => {
  if (event.version === 1) {
    return (
      <TimelineEvent
        date={event.date}
        icon="comment"
        title="Réponse à l'avis modérée"
        initiator="bilik-user"
        bilikUserName={getBilikUserName(event.initiator.bilik)}
      >
        <h3>
          Modération de la réponse à l&apos;avis sur{' '}
          <strong>{event.actors.pro.proPresentation.name}</strong>
        </h3>
        <div>
          {getBilikUserName(event.initiator.bilik)} a{' '}
          {event.data.type === 'validate' ? 'approuvé' : 'refusé'} la réponse à
          l&apos;avis
        </div>
        <div>
          <b>Réponse: </b>
          {event.data.proReview.reply}
        </div>
        <div>
          <Label
            as="a"
            onClick={(): void => {
              window.open(
                `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
                '_blank',
              );
            }}
          >
            <Icon name="eye" size="small" />
            Voir avis
          </Label>
        </div>
      </TimelineEvent>
    );
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewReplyModerated (version ${event.version})`}
    />
  );
};

export default ProReviewReplyModerated;
