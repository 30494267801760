import { Button, Modal } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { StopSms } from 'generated/graphql';
import { format } from 'path';
import { formatTelephone } from 'utils';

type StopSmsViewProps = {
  telephone?: string;
  isStopped?: boolean;
  stopSmss: StopSms[];
  unStopTelephone: () => Promise<void>;
  stopTelephone: () => Promise<void>;
  isModalOpened: boolean;
  trigger?: React.ReactElement;
  closeModal: () => void;
  isLoading: boolean;
};

export const StopSmsView: FunctionComponent<StopSmsViewProps> = ({
  unStopTelephone,
  isStopped,
  stopSmss,
  closeModal,
  stopTelephone,
  telephone,
  isModalOpened,
  trigger,
  isLoading,
}) => {
  return !isStopped ? (
    <Modal open={isModalOpened} trigger={trigger}>
      <Modal.Header>Bloquer les envois de sms vers ce particulier</Modal.Header>

      <Modal.Content>
        <p>Vous vous apprêtez à bloquer le/les téléphone(s) suivant(s) : </p>
        <ul>{telephone ? <li> {formatTelephone(telephone)}</li> : null}</ul>
        <p>
          <strong>
            La personne ne pourra plus reçevoir de SMS automatique de la part de
            Bilik.
          </strong>
          <br />
          Cela ne conserne uniquement les SMS envoyé via le short code
          &quot;Bilik&quot;.
        </p>
        <p>Etes-vous sûr de vouloir faire cela ?</p>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={closeModal}>Fermer</Button>
        <Button primary onClick={stopTelephone} loading={isLoading}>
          Bloquer
        </Button>
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal open={isModalOpened} trigger={trigger}>
      <Modal.Header>
        Débloquer les envois de sms vers ce particulier
      </Modal.Header>

      <Modal.Content>
        <p>Vous vous apprêtez à débloquer le/les identitiée(s) suivante(s) :</p>
        <ul>
          {stopSmss.map((stopSms) => (
            <li key={stopSms.id}>{stopSms.telephone})</li>
          ))}
        </ul>
        <p>
          <strong>
            La personne pourra de nouveau reçevoir des SMS automatique de la
            part de Bilik.
          </strong>
          <br />
          Cela ne conserne uniquement les SMS envoyé via le short code
          &quot;Bilik&quot;.
        </p>
        <p>Etes-vous sûr de vouloir faire cela ?</p>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={closeModal}>Fermer</Button>
        <Button primary onClick={unStopTelephone} loading={isLoading}>
          Débloquer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
