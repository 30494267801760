import React, { FunctionComponent, useCallback } from 'react';
import { RefuseProReviewFormContainer } from './refuse-pro-review-form-container';
import { ToastError, ToastSuccess, ToastWarn } from 'utils';
import { apiClient } from 'axios-client';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { useReviewLocker } from 'hooks/use-review-locker/use-review-locker';

type RefuseProReviewFormRepositoryProps = {
  proReviewId: number;
  onCompleted: () => void;
};

export const RefuseProReviewFormRepository: FunctionComponent<
  RefuseProReviewFormRepositoryProps
> = ({ proReviewId, onCompleted }) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  const { unlock } = useReviewLocker(proReviewId);

  const refuseProReview = useCallback(
    async (reason: string) => {
      try {
        await unlock();

        const response = await apiClient.post(`/form/review-pro/refuse`, {
          proReviewId,
          reason,
        });

        response.data.success === true
          ? ToastSuccess('Succès', 'Avis refusé.')
          : ToastWarn('Attention', response.data.message);
      } catch (e) {
        ToastError('Erreur', "Impossible de refuser l'avis.");
      }

      onCompleted();
    },
    [proReviewId, currentBilikPerson],
  );

  return <RefuseProReviewFormContainer refuseProReview={refuseProReview} />;
};
