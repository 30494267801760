import { setIn } from 'formik';
import { ZohoInvoice } from 'interfaces/zoho-invoices.interface';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Container, Loader } from 'semantic-ui-react';
import { ToastError } from 'utils';
import ProInvoicesView from './pro-invoices-view';

type ProInvoicesContainerProps = {
  fetchInvoices: (
    pageNumber: number,
  ) => Promise<{ invoices: ZohoInvoice[]; hasMorePage: boolean }>;
};

const ProInvoicesContainer: FunctionComponent<ProInvoicesContainerProps> = ({
  fetchInvoices,
}) => {
  const [page, setPage] = useState(1);
  const [invoices, setInvoices] = useState<ZohoInvoice[]>();
  const [loading, setLoading] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [error, setError] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);

    const response = await fetchInvoices(page).catch((error) => {
      ToastError('Erreur', 'Impossible de charger les factures');
      setLoading(false);
      setError(true);
      throw error;
    });

    setInvoices([...(invoices ?? []), ...response.invoices]);
    setHasMorePage(response.hasMorePage);
    setLoading(false);
  }, [page, fetchInvoices]);

  const fetchMore = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    fetch();
  }, [page]);

  if (error) {
    return (
      <Container fluid textAlign="center">
        <Button
          style={{ marginTop: '15px' }}
          type="button"
          onClick={(): void => {
            setError(false);
            fetch();
          }}
        >
          Rééssayer
        </Button>
      </Container>
    );
  }

  if (!invoices) {
    return (
      <Loader
        size="large"
        active
        inline="centered"
        content="Chargement des factures..."
      />
    );
  }

  return (
    <ProInvoicesView
      fetchMore={fetchMore}
      hasMorePage={hasMorePage}
      invoices={invoices}
      loading={loading}
    />
  );
};

export default ProInvoicesContainer;
