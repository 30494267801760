import {
  Button,
  Icon,
  Label,
  Search,
  SearchResultsProps,
  Divider,
} from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { getProViewStatusColor } from '../../utils/index';
import styled from 'styled-components';

const SearchStyled = styled(Search)`
  & .results {
    width: 500px !important;
  }
`;

type Props = {
  value: string;
  loading: boolean;
  onBlur: () => void;
  onClick: () => void;
  onKeyPress: (key: string) => void;
  onResultSelect: (value) => void;
  onSearchChange: (data?: string) => void;
  open: boolean;
  results: SearchResultsProps;
  defaultValue?: string;
};

const ProPresentationSearchFilterView: FunctionComponent<Props> = ({
  value,
  loading,
  onBlur,
  onClick,
  onKeyPress,
  onResultSelect,
  onSearchChange,
  open,
  results,
  defaultValue,
}) => {
  const resultRenderer = useCallback(
    ({ title, proViews, mainTrade }) => (
      <>
        <p>
          <b>{title}</b>
        </p>
        {proViews.map((proView, index) => (
          <Fragment key={`search-result-pro-view-${index}`}>
            <Icon
              key={`icon-${proView.id}`}
              size="small"
              name="circle"
              color={getProViewStatusColor(proView.status)}
            />
            <span>
              <b>{proView.bilikZone.mainCityCode}</b>
              {` - ${mainTrade}`}
              <ul style={{ margin: 0, listStyle: 'none', paddingLeft: '15px' }}>
                {proView.proPeople.map((proPerson, index) => (
                  <li key={`pro-person-${index}`}>
                    {proPerson.proPerson.givenName}{' '}
                    {proPerson.proPerson.familyName}
                  </li>
                ))}
              </ul>
            </span>
            {index < proViews.length - 1 ? <Divider /> : ''}
          </Fragment>
        ))}
      </>
    ),
    [],
  );

  if (defaultValue) {
    return (
      <Button
        as="div"
        style={{ marginLeft: '12px', marginRight: '0' }}
        labelPosition="right"
      >
        <Button color="blue" onClick={onClick}>
          <Icon name="delete" />
        </Button>
        <Label as="a" basic pointing="left">
          {defaultValue}
        </Label>
      </Button>
    );
  }

  return (
    <SearchStyled
      fluid
      open={open}
      style={{
        display: 'inline-block',
        marginLeft: '12px',
      }}
      input={{
        icon: value ? <Icon name="times" link onClick={onClick} /> : 'search',
        iconPosition: 'left',
      }}
      noResultsMessage="Pas de résultats."
      loading={loading}
      placeholder="Professionnel..."
      onBlur={(): void => onBlur()}
      onKeyPress={(event): void => onKeyPress(event.key)}
      onResultSelect={(_, data): void => onResultSelect(data.result)}
      resultRenderer={resultRenderer}
      onSearchChange={(_, data): void => onSearchChange(data.value)}
      results={results}
      value={value}
    />
  );
};

export default ProPresentationSearchFilterView;
