import { apiClient } from 'axios-client';
import { ZohoCreditNote } from 'interfaces/zoho-credit-note.interface';
import React, { FunctionComponent, useCallback } from 'react';
import ProCreditNotesContainer from './pro-credit-notes-container';

type ProCreditNotesRepositoryProps = {
  proOrganizationId: number;
};

const ProCreditNotesRepository: FunctionComponent<
  ProCreditNotesRepositoryProps
> = ({ proOrganizationId }) => {
  const fetchCreditNotes = useCallback(
    async (pageNumber: number) => {
      const endpoint = `/zoho/consumer-credit-notes?${new URLSearchParams({
        pro_organization_id: String(proOrganizationId),
        page_number: String(pageNumber),
      })}`;

      const creditNotes = await apiClient
        .get<ZohoCreditNote[]>(endpoint)
        .then((response) => response.data);

      return creditNotes;
    },
    [proOrganizationId],
  );

  return <ProCreditNotesContainer fetchCreditNotes={fetchCreditNotes} />;
};

export default ProCreditNotesRepository;
