import { InvoiceStatus } from 'components/invoice-status/invoice-status';
import { ZohoInvoice } from 'interfaces/zoho-invoices.interface';
import React, { Fragment, FunctionComponent } from 'react';
import { Button, Icon, Message, Table } from 'semantic-ui-react';
import { formatDate } from 'utils';

type ProInvoicesViewProps = {
  fetchMore: () => void;
  invoices: ZohoInvoice[];
  loading: boolean;
  hasMorePage: boolean;
};

const ProInvoicesView: FunctionComponent<ProInvoicesViewProps> = ({
  invoices,
  fetchMore,
  loading,
  hasMorePage,
}) => {
  if (invoices.length === 0) {
    return <Message>Il n&apos;y a pas de factures</Message>;
  }

  return (
    <>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>N° facture</Table.HeaderCell>
            <Table.HeaderCell>Montant HT</Table.HeaderCell>
            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Télécharger</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {invoices.map((invoice, index) => (
            <Fragment key={`invoices-${index}`}>
              <Table.Row>
                <Table.Cell>{formatDate(invoice.date)}</Table.Cell>
                <Table.Cell>{invoice.invoice_number}</Table.Cell>
                <Table.Cell>{Math.ceil(invoice.total / 1.2)}€ HT</Table.Cell>
                <Table.Cell>{invoice.total}€ TTC</Table.Cell>
                <Table.Cell>
                  <InvoiceStatus status={invoice.status} />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    disabled={invoice.status === 'draft'}
                    target="_blank"
                    href={invoice.invoice_url}
                    icon="download"
                  />
                </Table.Cell>
              </Table.Row>
            </Fragment>
          ))}
        </Table.Body>
      </Table>
      {hasMorePage ? (
        <Button
          type="button"
          floated="right"
          loading={loading}
          disabled={loading}
          icon
          labelPosition="right"
          onClick={fetchMore}
        >
          <Icon name="arrow down" />
          Voir plus
        </Button>
      ) : null}
    </>
  );
};

export default ProInvoicesView;
