import { FormikProps, withFormik } from 'formik';
import React, {
  Fragment,
  useEffect,
  useState,
  FunctionComponent,
  useCallback,
} from 'react';
import { Button, Card, Icon, Modal, Form } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils/toast';
import * as Yup from 'yup';
import Label from 'components/label/label';
import FormField from 'components/form-field/form-field';
import { apiClient } from 'axios-client';

type Modal = {
  open: boolean;
};

export const ZohoConfigurationCard: FunctionComponent = () => {
  const [modal, setModal] = useState<Modal>({ open: false });

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="group" />
        <Card.Header>Configuration Zoho</Card.Header>
        <Card.Description>
          Configuration du Zoho self-client.{' '}
          <a
            href="https://api-console.zoho.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Console Zoho
          </a>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Modal
          size="small"
          open={modal.open}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          closeIcon
          onClose={(): void => {
            setModal({ open: false });
          }}
          trigger={
            <Button
              fluid
              primary
              onClick={(): void => {
                setModal({ open: true });
              }}
            >
              Ouvrir
            </Button>
          }
        >
          <Modal.Header>Configuration du self-client Zoho</Modal.Header>
          <Modal.Content>
            <Label>Scope :</Label>
            <p style={{ wordBreak: 'break-all' }}>
              ZohoBooks.contacts.ALL,ZohoBooks.settings.ALL,ZohoBooks.estimates.ALL,ZohoBooks.invoices.ALL,ZohoBooks.customerpayments.ALL,ZohoBooks.creditnotes.ALL,ZohoBooks.projects.ALL,ZohoBooks.expenses.ALL,ZohoBooks.salesorders.ALL,ZohoBooks.purchaseorders.ALL,ZohoBooks.bills.ALL,ZohoBooks.debitnotes.ALL,ZohoBooks.vendorpayments.ALL,ZohoBooks.banking.ALL,ZohoBooks.accountants.ALL
            </p>
            <ZohoConfigurationForm />
          </Modal.Content>
          <Modal.Actions>
            <ZohoOrganizationConfiguration />
            <Button
              onClick={(): void => {
                setModal({ open: false });
              }}
            >
              Fermer
            </Button>
          </Modal.Actions>
        </Modal>
      </Card.Content>
    </Card>
  );
};

const ZohoOrganizationConfiguration: FunctionComponent = () => {
  const [zohoOrganizations, setZohoOrganizations] = useState<any>([]); // @TODO distant ZohoOrganization type
  const [loading, setLoading] = useState<boolean>(false);

  const syncZohoOrganization = useCallback(async (zohoOrganization) => {
    setLoading(true);
    await apiClient
      .post(`/zoho/configure-zoho-organizations`, {
        ids: [zohoOrganization.organization_id],
      })
      .catch((error) => {
        ToastError('Erreur', "Impossible de synchroniser l'organisation");
        throw error;
      });
    ToastSuccess('Succès', 'Organization synchronisée');
    setLoading(false);
  }, []);

  useEffect(() => {
    const getOrganizations = async (): Promise<void> => {
      const zohoOrganizations = await apiClient
        .get(`/zoho/zoho-organizations`)
        .then((response) => response.data)
        .catch((error) => {
          ToastError(
            'Erreur',
            'Impossible de charger les organisations, le client zoho est initialisé ?',
          );
          throw error;
        });
      setZohoOrganizations(zohoOrganizations);
    };
    getOrganizations();
  }, []);
  return zohoOrganizations ? (
    <Fragment>
      {zohoOrganizations.map((zohoOrganization) => (
        <Button
          key={zohoOrganization.organization_id}
          disabled={loading}
          loading={loading}
          onClick={(): Promise<void> => syncZohoOrganization(zohoOrganization)}
        >
          Synchroniser {zohoOrganization.name}
        </Button>
      ))}
    </Fragment>
  ) : null;
};

type FormValues = {
  clientId: string;
  clientSecret: string;
  code: string;
};

type InnerFormProps = FormikProps<FormValues>;

const InnerForm: FunctionComponent<InnerFormProps> = ({
  handleSubmit,
  handleReset,
}) => (
  <Form onSubmit={handleSubmit} onReset={handleReset}>
    <FormField type="text" label="Client Id" name="clientId" required />
    <FormField type="text" label="Client Secret" name="clientSecret" required />
    <FormField type="text" label="Code" name="code" required />

    <Button primary type="submit">
      Envoyer
    </Button>
  </Form>
);

type ZohoConfigurationFormProps = Record<string, unknown>;

const ZohoConfigurationForm = withFormik<
  ZohoConfigurationFormProps,
  FormValues
>({
  validateOnChange: true,
  validationSchema: Yup.object({
    clientId: Yup.string().required('Client id est requis !'),
    clientSecret: Yup.string().required('Client secret est requis !'),
    code: Yup.string().required('Code est requis !'),
  }),
  mapPropsToValues: () => ({
    clientId: '',
    clientSecret: '',
    code: '',
  }),
  handleSubmit: async (values) => {
    await apiClient
      .post(`/zoho/generate-refresh-token`, {
        clientId: values.clientId,
        clientSecret: values.clientSecret,
        code: values.code,
      })
      .catch((error) => {
        ToastError(
          'Erreur',
          'Configuration impossible, client ou code non valide ?',
        );
        throw error;
      });

    ToastSuccess('Succès', 'OAuth2 Initialisé');
  },
})(InnerForm);
