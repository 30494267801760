import { apiClient } from 'axios-client';
import { useGloballyBlockedLazyQuery } from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent, useCallback } from 'react';
import { GloballyBlockedContainer } from './globally-blocked-container';

type GloballyBlockedRepositoryProps = {
  triggerNotBlocked?: React.ReactElement;
  triggerBlocked: React.ReactElement;
  email?: string;
  telephone?: string;
  onActionTriggered?: () => void;
};

export const GloballyBlockedRepository: FunctionComponent<
  GloballyBlockedRepositoryProps
> = ({
  email,
  telephone,
  triggerNotBlocked,
  triggerBlocked,
  onActionTriggered,
}) => {
  const [globallyBlockedQuery, { data }] = useGloballyBlockedLazyQuery();

  const { currentBilikPerson } = useCurrentBilikPerson();

  const fetchGloballyBlockedIdentities = useCallback(
    async (identifiers: string[]) =>
      await globallyBlockedQuery({
        variables: {
          identifiers: identifiers,
        },
      }),
    [],
  );

  const insertGloballyBlockedIdentity = useCallback(
    async (identifier: string, reason: string) => {
      return await apiClient
        .post(`/form/globally-blocked/`, {
          identifier,
          reason,
          namespace: 'bilik',
          action: 'create',
        })
        .then((response) => {
          return response.data;
        });
    },
    [currentBilikPerson],
  );

  const deleteGloballyBlockedIdentity = useCallback(
    async (identifier: string) => {
      return await apiClient
        .post(`/form/globally-blocked/`, {
          identifier,
          namespace: 'bilik',
          action: 'delete',
        })
        .then((response) => {
          return response.data;
        });
    },
    [currentBilikPerson],
  );

  return (
    <GloballyBlockedContainer
      fetchGloballyBlockedIdentities={fetchGloballyBlockedIdentities}
      email={email}
      telephone={telephone}
      globallyBlockedIdentities={data?.globallyBlockedIdentity}
      insertGloballyBlockedIdentity={insertGloballyBlockedIdentity}
      deleteGloballyBlockedIdentity={deleteGloballyBlockedIdentity}
      triggerNotBlocked={triggerNotBlocked}
      triggerBlocked={triggerBlocked}
      onActionTriggered={onActionTriggered}
    />
  );
};
