import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

type ProReviewDuplicatedProps = {
  event: MongoEvent;
};

const ProReviewDuplicated: FunctionComponent<ProReviewDuplicatedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return (
      <TimelineEvent
        date={event.date}
        icon="comment"
        title="Avis dupliqué"
        initiator="bilik-user"
        bilikUserName={getBilikUserName(event.initiator.bilik)}
      >
        <h3>
          Avis de{' '}
          <span>
            {event.actors.pro.proPresentation.name} (
            {event.actors.pro.proPresentation.mainTrade?.name})
          </span>{' '}
          dupliqué sur{' '}
          <span>
            {event.data.toPro?.proPresentation.name} (
            {event.data.toPro?.proPresentation.mainTrade?.name})
          </span>
        </h3>
        <div>
          <Label
            as="a"
            onClick={(): void => {
              window.open(
                `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
                '_blank',
              );
            }}
          >
            <Icon name="eye" size="small" />
            Voir avis
          </Label>
        </div>
      </TimelineEvent>
    );
  }
  if (event.version === 2) {
    return (
      <TimelineEvent
        date={event.date}
        icon="comment"
        title="Avis dupliqué"
        initiator="bilik-user"
        bilikUserName={getBilikUserName(event.initiator.bilik)}
      >
        <h3>
          Avis de <span>{event.data.fromProPresentation.name}</span> dupliqué
          sur <span>{event.data.toProPresentation.name}</span>
        </h3>
        <div>
          <Label
            as="a"
            onClick={(): void => {
              window.open(
                `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
                '_blank',
              );
            }}
          >
            <Icon name="eye" size="small" />
            Voir avis
          </Label>
        </div>
      </TimelineEvent>
    );
  }
  if (event.version === 3) {
    return (
      <TimelineEvent
        date={event.date}
        icon="comment"
        title="Avis dupliqué"
        initiator="bilik-user"
        bilikUserName={getBilikUserName(event.initiator.bilik)}
      >
        <h3>
          Avis de{' '}
          <span>
            {event.data.fromProPresentation.name} (
            {event.data.fromProPresentation.mainTradeName})
          </span>{' '}
          dupliqué sur{' '}
          <span>
            {event.data.toProPresentation.name} (
            {event.data.toProPresentation.mainTradeName})
          </span>
        </h3>
        <div>
          <Label
            as="a"
            onClick={(): void => {
              window.open(
                `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
                '_blank',
              );
            }}
          >
            <Icon name="eye" size="small" />
            Voir avis
          </Label>
        </div>
      </TimelineEvent>
    );
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewDuplicated (version ${event.version})`}
    />
  );
};

export default ProReviewDuplicated;
