import { apiClient } from 'axios-client';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import FieldError from 'components/field-error/field-error';
import Label from 'components/label/label';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Label as LabelUi } from 'semantic-ui-react';
import { formatTelephone, ToastError, ToastSuccess } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';

type ProTelephonesFieldProps = {
  proViewId: number;
};

const ProTelephonesField: FunctionComponent<ProTelephonesFieldProps> = ({
  proViewId,
}) => {
  const { values, errors, setFieldValue } =
    useFormikContext<ProFormUpdateFields>();
  const onBuyProNumber = useCallback(async (): Promise<void> => {
    if (!values.proPresentation.proOrganizationId) {
      ToastError(
        'Erreur',
        'Vous devez dabord ajouter une entreprise avant de pouvoir générer un numéro',
      );
      return;
    }

    await apiClient
      .post(`/form/twilio/set-pro-number`, {
        proViewId,
      })
      .then((response) => {
        ToastSuccess('Succès', 'Numéro de téléphone attribué');
        setFieldValue('telephones', [...values.telephones, response.data]);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          ToastError(
            'Erreur',
            "Il n'y a plus de numéros VONAGE disponibles pour le moment.",
          );
        } else {
          ToastError('Erreur', "Impossible d'attribuer le numéro VONAGE");
        }
        throw error;
      });
  }, [proViewId, values.telephones]);

  return (
    <>
      <Label>
        Numéro call tracking
        {values.telephones && values.telephones.length
          ? values.telephones.map((telephone, index) => (
              <LabelUi
                key={`call-tracking-number-${index}`}
                size="big"
                style={{ marginLeft: '15px' }}
              >
                {formatTelephone(telephone)}
              </LabelUi>
            ))
          : null}
        {values.status !== 'archived' &&
        (!values.telephones || values.telephones.length === 0) ? (
          <ConfirmModal
            trigger={
              <Button
                type="button"
                className="warning"
                compact
                style={{ marginLeft: '15px', marginBottom: '15px' }}
              >
                Générer
              </Button>
            }
            header="Confirmation"
            content={
              <p>
                Cette action va attribuer un nouveau numéro VONAGE. Continuer ?
              </p>
            }
            onConfirm={onBuyProNumber}
          />
        ) : null}
        <div style={{ height: '15px' }} />
      </Label>
      {errors.telephones ? (
        <FieldError color="red" style={{ marginLeft: '10px' }}>
          {errors.telephones}
        </FieldError>
      ) : null}
    </>
  );
};

export default ProTelephonesField;
