import { Formik } from 'formik';
import {
  ProOrganizationFormUpdateQuery,
  ProOrganizationSetInput,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ProOrganizationFormModalProps } from './pro-organization-form-modal';
import { ProOrganizationFormFields } from './pro-organization-form-fields-type';
import { Loader, Modal } from 'semantic-ui-react';
import ProOrganizationFormView from './pro-organization-form-view';
import { ToastError, ToastSuccess } from 'utils';
import proOrganizationFormValidationSchema from './pro-organization-form-validation-schema';
import { formattedPostalAddress } from 'utils/formatted-postal-address';

type ProOrganizationFormUpdateContainerProps = ProOrganizationFormModalProps & {
  proOrganizationId: number;
  proOrganization?: ProOrganizationFormUpdateQuery['proOrganizationByPk'];
  updateProOrganization: (
    proOrganization: ProOrganizationSetInput,
  ) => Promise<void>;
};

const ProOrganizationFormUpdateContainer: FunctionComponent<
  ProOrganizationFormUpdateContainerProps
> = ({
  proOrganization,
  // proPresentationId,
  updateProOrganization,
  onClose,
  ...rest
}) => {
  const onSubmit = useCallback(
    async (values: ProOrganizationFormFields): Promise<void> => {
      await updateProOrganization({
        name: values.name.trim(),
        street1: values.street1.trim(),
        street2: values.street2.trim(),
        postalCode: values.postalCode.trim(),
        city: values.city.trim(),
        siret: values.siret.trim(),
        isForeign: values.isForeign,
        tvaNumber: values.tvaNumber.trim(),
        billingEmail: values.billingEmail.trim(),
        businessStatus: values.businessStatus,
      }).catch((error) => {
        ToastError('Erreur', "Impossible d'enregistrer l'entreprise");
        throw error;
      });

      if (onClose) {
        onClose();
      }

      ToastSuccess('Succès', 'Entreprise modifiée');
    },
    [updateProOrganization],
  );

  if (!proOrganization) {
    return (
      <Modal.Content>
        <Loader active inline="centered" />
      </Modal.Content>
    );
  }

  const initialValues: ProOrganizationFormFields = {
    name: proOrganization.name || '',
    street1: proOrganization.street1 || '',
    street2: proOrganization.street2 || '',
    postalCode: proOrganization.postalCode || '',
    city: proOrganization.city || '',
    siret: proOrganization.siret || '',
    isForeign: proOrganization.isForeign || false,
    tvaNumber: proOrganization.tvaNumber || '',
    billingEmail: proOrganization.billingEmail || '',
    formattedAddress:
      formattedPostalAddress({
        street: proOrganization.street1,
        postalCode: proOrganization.postalCode,
        city: proOrganization.city,
      }) || '',
    businessStatus: proOrganization.businessStatus || '',
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={proOrganizationFormValidationSchema}
    >
      <ProOrganizationFormView onClose={onClose} {...rest} shouldStep={false} />
    </Formik>
  );
};

export default ProOrganizationFormUpdateContainer;
