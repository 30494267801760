import {
  ProReviewInvitationBoolExp,
  useProReviewInvitationsCountQuery,
} from 'generated/graphql';

type ProReviewInvitationsCounter = {
  count?: number;
  loading: boolean;
};

export const useProReviewInvitationsCount = (
  where?: ProReviewInvitationBoolExp,
): ProReviewInvitationsCounter => {
  const { data, loading } = useProReviewInvitationsCountQuery({
    variables: {
      where,
    },
  });

  return {
    loading,
    count: data?.proReviewInvitationAggregate.aggregate?.count,
  };
};
