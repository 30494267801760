import { Button, Divider, Icon, Label, List, Modal } from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { find } from 'remeda';
import { MongoEvent } from 'interfaces/events.interface';
import TimelineSmsAction from './timeline-sms-action';
import TimelineSmsDetails from './timeline-sms-details';
import { ToastError } from 'utils/toast';
import HelpText from 'components/help-text/help-text';
import { apiClient } from 'axios-client';

type TimelineSmsPreviewProps = {
  messageId: string;
  title: string;
};

const TimelineSmsPreview: FunctionComponent<TimelineSmsPreviewProps> = ({
  messageId,
  title,
}) => {
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState<boolean>(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState<boolean>(false);
  const [smsEvents, setSmsEvents] = useState<MongoEvent[]>([]);
  const [sms, setSms] = useState<MongoEvent>();
  const [isDelivered, setIsDelivered] = useState<boolean>(false);

  useEffect(() => {
    apiClient
      .get(`/event/messageId/${messageId}`, {})
      .then((result) => {
        const data: MongoEvent[] = result.data;
        if (
          !!find(
            data,
            (sms: MongoEvent) =>
              sms.type === 'SmsStatusUpdated' &&
              (sms.data.twilio?.SmsStatus === 'delivered' ||
                sms.data.vonage?.status === 'delivered'),
          )
        ) {
          setIsDelivered(true);
        }
        if (
          !!find(
            data,
            (sms: MongoEvent) =>
              sms.type === 'SmsStatusUpdated' &&
              (['undelivered', 'failed'].includes(sms.data.twilio?.SmsStatus) ||
                ['rejected', 'undeliverable'].includes(
                  sms.data.vonage?.status,
                )),
          )
        ) {
          setIsDelivered(false);
        }
        setSms(find(data, (sms: MongoEvent) => sms.type === 'SmsSent'));
        setSmsEvents(result.data);
      })
      .catch((error) => {
        ToastError('Erreur', `HTTP Error ${error.message}`);
      });
  }, [messageId]);
  return (
    <div>
      <b>{title}</b>
      {sms ? (
        <Fragment>
          <Label
            as="a"
            onClick={(): void => {
              setIsModalPreviewOpen(true);
            }}
          >
            <Icon name="eye" size="small" />
            Aperçu
          </Label>
          <Modal
            open={isModalPreviewOpen}
            onClose={(): void => {
              setIsModalPreviewOpen(false);
            }}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            closeIcon
          >
            <Modal.Header>Aperçu</Modal.Header>
            <Modal.Content>
              <TimelineSmsDetails sms={sms} />
              <Divider />
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sms.data.vonage
                    ? sms.data.vonage.text
                    : sms.data.twilio.Body,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  setIsModalPreviewOpen(false);
                }}
              >
                Fermer
              </Button>
            </Modal.Actions>
          </Modal>
          <Label
            as="a"
            onClick={(): void => {
              setIsModalDetailOpen(true);
            }}
          >
            <Icon name="info circle" size="small" />
            Détails
          </Label>
          <Modal
            open={isModalDetailOpen}
            onClose={(): void => {
              setIsModalDetailOpen(false);
            }}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            closeIcon
          >
            <Modal.Header>Détails</Modal.Header>
            <Modal.Content>
              <TimelineSmsDetails sms={sms} />
              <Divider />
              <List>
                {smsEvents && smsEvents.length > 0
                  ? smsEvents.map((event) => {
                      if (event.data.vonage) {
                        switch (event.data.vonage.status) {
                          case 'sent':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Envoyé"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );
                          case 'delivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Délivré"
                                color="green"
                                icon="mobile alternate"
                              />
                            );
                          case 'rejected':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Rejeté"
                                color="red"
                                icon="mobile alternate"
                              />
                            );
                          case 'undeliverable':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Impossible d'envoyer"
                                color="red"
                                icon="mobile alternate"
                              />
                            );

                          default:
                            return null;
                        }
                      } else {
                        switch (event.data.twilio.SmsStatus) {
                          case 'delivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Délivré"
                                color="green"
                                icon="mobile alternate"
                              />
                            );
                          case 'undelivered':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Non délivré"
                                color="red"
                                icon="mobile alternate"
                              />
                            );
                          case 'failed':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Erreur"
                                color="red"
                                icon="mobile alternate"
                              />
                            );
                          case 'sent':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Envoyé"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );
                          case 'delivery_unknown':
                            return (
                              <TimelineSmsAction
                                key={event._id}
                                date={event.date}
                                text="Inconnu"
                                color="grey"
                                icon="mobile alternate"
                              />
                            );

                          default:
                            return null;
                        }
                      }
                    })
                  : "Pas d'activités enregistré"}
              </List>
              {!isDelivered ? (
                <HelpText>
                  <Icon name="warning sign" />
                  Envoyé ne veut pas forcément dire que le destinataire à reçu
                  le sms.
                </HelpText>
              ) : null}
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  setIsModalDetailOpen(false);
                }}
              >
                Fermer
              </Button>
            </Modal.Actions>
          </Modal>
        </Fragment>
      ) : null}
    </div>
  );
};

export default TimelineSmsPreview;
