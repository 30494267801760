import React, { FunctionComponent } from 'react';
import ProPersonFormUpdateRepository from './pro-person-form-update-repository';
import ProPersonFormCreateRepository from './pro-person-form-create-repository';

export type ProPersonFormModalProps = {
  open: boolean;
  onClose: () => void;
};

type ProPersonFormProps = ProPersonFormModalProps & {
  proPersonId?: number | null;
  onProPersonCreated?: (proPersonId: number) => void;
  onProPersonUpdated?: (proPersonId: number) => void;
};

const ProPersonFormModal: FunctionComponent<ProPersonFormProps> = ({
  proPersonId,
  onProPersonCreated,
  onProPersonUpdated,
  ...rest
}) => {
  return (
    <>
      {proPersonId ? (
        <ProPersonFormUpdateRepository
          proPersonId={proPersonId}
          onProPersonUpdated={onProPersonUpdated}
          {...rest}
        />
      ) : (
        <ProPersonFormCreateRepository
          onProPersonCreated={onProPersonCreated}
          {...rest}
        />
      )}
    </>
  );
};

export default ProPersonFormModal;
