import React, { FunctionComponent } from 'react';
import { Label, Popup } from 'semantic-ui-react';

type DuplicateCheckerIndicatorViewProps = {
  isDuplicateReview: boolean;
  openProReviews: () => void;
};

export const DuplicateCheckerIndicatorView: FunctionComponent<
  DuplicateCheckerIndicatorViewProps
> = ({ isDuplicateReview, openProReviews }) => {
  // Only one review with this IP.
  if (!isDuplicateReview) {
    return (
      <Label size="mini" color="green">
        Non
      </Label>
    );
  }

  // More than one review with this IP.
  return (
    <Popup
      content="Plusieurs avis ont été envoyé à ce pro avec la même adresse mail ou le même nom/prénom."
      trigger={
        <Label
          style={{ cursor: 'pointer' }}
          onClick={openProReviews}
          size="mini"
          color="red"
        >
          Verifier
        </Label>
      }
    />
  );
};
