import { startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import React, { FunctionComponent } from 'react';
import { useProReviewInvitationsCountByDateQuery } from 'generated/graphql';
import { ProStatsProReviewInvitationsContainer } from './pro-stats-pro-review-invitations-container';

type Props = {
  startDate: Date;
  endDate: Date;
  proPresentationId: number;
};

export const ProStatsProReviewInvitationRepository: FunctionComponent<Props> = ({
  startDate,
  endDate,
  proPresentationId,
}) => {
  const { data } = useProReviewInvitationsCountByDateQuery({
    variables: {
      before: startOfMonth(startDate),
      after: endOfMonth(endDate),
      proPresentationId: proPresentationId,
    },
  });

  return (
    <ProStatsProReviewInvitationsContainer
      countProReviewInvitationsByMonth={
        data?.countProReviewInvitationsByMonthByProPresentation
      }
      startDate={startDate}
      endDate={endDate}
    />
  );
};
