import {
  AccountInsertInput,
  useAccountFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import {
  addKeycloakUserGroup,
  createKeycloakUser,
  getGroupId,
  getKeycloakUserIdByEmail,
} from 'utils';
import AccountFormCreateContainer from './account-form-create-container';
import { AccountFormModalProps } from './account-form-modal';

type AccountFormCreateRepositoryProps = AccountFormModalProps & {
  onAccountCreated?: (accountId: number) => void;
};

const AccountFormCreateRepository: FunctionComponent<
  AccountFormCreateRepositoryProps
> = ({ onAccountCreated, ...rest }) => {
  /**
   * it return a boolean to validate succes
   * we add the pro to keycloak by creating a keycloak user, and adding this user to the pro-person group
   */
  const addToKeycloak = useCallback(
    async (email?: string): Promise<boolean> => {
      if (email) {
        try {
          // we set the email as the keycloak account username
          await createKeycloakUser({
            email,
            username: email,
            enabled: true,
          });

          const groupId = await getGroupId('pro-people/pros');
          const keycloakUserId = await getKeycloakUserIdByEmail(email);

          if (groupId && keycloakUserId) {
            const status = await addKeycloakUserGroup(keycloakUserId, groupId);
            return status === 204;
          }
        } catch (err) {
          console.error(err);
        }
      }

      return false;
    },
    [],
  );

  const [accountFormCreateMutation] = useAccountFormCreateMutation();

  const createAccount = useCallback(async (account: AccountInsertInput) => {
    const createdAccount = await accountFormCreateMutation({
      variables: { account },
    });

    const keycloakAccountCreationSuccess = await addToKeycloak(account.email);

    if (!keycloakAccountCreationSuccess) {
      throw Error('Keycloak account creation failed');
    }

    if (onAccountCreated && createdAccount.data?.insertAccountOne) {
      onAccountCreated(createdAccount.data?.insertAccountOne?.id);
    }
  }, []);

  return <AccountFormCreateContainer createAccount={createAccount} {...rest} />;
};

export default AccountFormCreateRepository;
