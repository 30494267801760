import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import { useSelectCityFieldQuery } from 'generated/graphql';

type SelectCityFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & {
  except?: number[];
  bilikZoneId?: number;
};

const SelectCityField: FunctionComponent<SelectCityFieldProps> = ({
  multiple,
  except,
  bilikZoneId,
  ...rest
}) => {
  const { data, loading, error } = useSelectCityFieldQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        bilikZoneId: { _eq: bilikZoneId },
      },
    },
  });

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.cities) {
      const cities =
        except && except.length > 0
          ? data.cities.filter((city) => !except.includes(city.id))
          : data.cities;

      return cities.map((city) => ({
        key: city.id,
        value: city.id,
        text: city.name,
      }));
    }
    return [];
  }, [data?.cities, except]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des villes...';
    }
    if (multiple) {
      return 'Selectionnez des villes...';
    }
    return 'Selectionnez une ville...';
  }, [loading, multiple]);

  if (error) return <Fragment>Error! ${error.message}</Fragment>;

  return (
    <FormField
      type="select"
      disabled={loading}
      loading={loading}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      selection
      {...rest}
    />
  );
};
export default SelectCityField;
