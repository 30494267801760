import React, { FunctionComponent } from 'react';
import { Container, Icon, Label, Modal } from 'semantic-ui-react';

type Props = {
  reason: string;
};

const ModalBlockedIdentityInfo: FunctionComponent<Props> = ({ reason }) => {
  return (
    <Modal
      trigger={
        <Icon
          name="info circle"
          color="blue"
          style={{ float: 'right', cursor: 'pointer' }}
        />
      }
      closeIcon
    >
      <Modal.Header>
        <Icon name="info circle" color="blue" />
        Raison : {reason}
      </Modal.Header>
      <Modal.Content>
        <Container textAlign="left">
          <Label color="blue" ribbon>
            Conditions de blocage : appels
          </Label>
          <ul>
            <li>
              Les appels pris en compte pour le blocage sont ceux qui :{' '}
              <ul>
                <li>ont été réalisés il y a moins d&apos;une heure</li>
                <li>font moins de 3 secondes</li>
                <li>sont tous fait à un professionnel différent</li>
              </ul>
            </li>
            <li>
              Ces catégories ne sont pas prise en compte par le système
              anti-spam :
              <ul>
                <li>Dépannage plomberie</li>
                <li>Serrurier</li>
                <li>Dépannage chaudière</li>
              </ul>
            </li>
            <li>
              Le particulier ayant déjà réalisé une sollicitation ou un avis
              avec ce numéro n&apos;est pas bloqué, mais suspecté de spam
              s&apos;il est repéré par le système.
            </li>
            <li>
              Si le particulier est déjà suspecté depuis le jour précédent, il
              est bloqué.
            </li>
          </ul>
          <Label color="blue" ribbon>
            Conditions de blocage : Sms
          </Label>
          <ul>
            <li>
              Les sms pris en compte pour le blocage sont ceux qui :{' '}
              <ul>
                <li>ont été réalisés il y a moins d&apos;une heure</li>
                <li>ont un texte identique</li>
              </ul>
            </li>
            <li>Toutes les catégories de métiers sont pris en compte</li>
          </ul>
        </Container>
      </Modal.Content>
    </Modal>
  );
};

export default ModalBlockedIdentityInfo;
