import React, { FunctionComponent, useCallback } from 'react';
import {
  FreshdeskEvent,
  MongoEvent,
  Ticket,
} from 'interfaces/events.interface';
import { ContactInfos, TimelineContainer } from './timeline-container';
import { TimelinePrivateIndividualQuery } from 'generated/graphql';
import Client from 'client';
import gql from 'graphql-tag';
import { ApolloQueryResult } from '@apollo/client';
import { apiClient } from 'axios-client';

type Props = {
  searchValue: string;
};

export type TimelineData = {
  [key: string]: (MongoEvent | FreshdeskEvent)[];
};

const TimelineRepository: FunctionComponent<Props> = ({ searchValue }) => {
  const fetchPrivateIndividuals = useCallback(
    async (
      searchValue: string,
    ): Promise<ApolloQueryResult<TimelinePrivateIndividualQuery>> => {
      return Client.query({
        query: gql`
          query timelinePrivateIndividualQuery($value: String!) {
            timelinePrivateIndividual(
              where: {
                _or: [
                  { email: { _ilike: $value } }
                  { telephone: { _eq: $value } }
                ]
              }
            ) {
              email
              telephone
              givenName
              familyName
              addressLocality
            }
          }
        `,
        variables: {
          value: searchValue,
        },
      });
    },
    [],
  );

  const fetchEvents = useCallback(
    async (filters: any, contactInfos: ContactInfos) =>
      await apiClient
        .post<MongoEvent[]>(`/event/pi-timeline`, {
          filters: {
            ...filters,
            eventTypes: filters?.eventTypes ? filters?.eventTypes : undefined, // Event types filter
          },
          contactInfos: contactInfos,
        })
        .then((response) => response.data),
    [],
  );

  const fetchFreshdeskTickets = useCallback(
    async (email: string): Promise<Ticket[]> => {
      try {
        const response = await apiClient.post(`/form/freshdesk/tickets`, {
          email: email,
        });

        // Map the response to a Ticket array
        const tickets: Ticket[] = response.data.map((ticket) => {
          return {
            id: ticket.id,
            created_at: ticket.created_at,
            updated_at: ticket.updated_at,
            subject: ticket.subject,
          };
        });

        return tickets;
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  return (
    <TimelineContainer
      fetchEvents={fetchEvents}
      searchValue={searchValue}
      fetchPrivateIndividuals={fetchPrivateIndividuals}
      fetchFreshdeskTickets={fetchFreshdeskTickets}
    />
  );
};
export default TimelineRepository;
