import React, { FunctionComponent } from 'react';
import { Serie } from '@nivo/line';
import DefaultGraphView from '../default-graph-view';

type Props = {
  data: Serie[];
};

const CallsGraphView: FunctionComponent<Props> = ({ data }) => {
  return <DefaultGraphView data={data} colors={['#0099ff', '#80ccff']} />;
};

export default CallsGraphView;
