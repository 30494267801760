import React, { FunctionComponent } from 'react';
import { Label } from 'semantic-ui-react';

export type InvoiceStatusKind =
  | 'unpaid'
  | 'partially_paid'
  | 'overdue'
  | 'draft'
  | 'viewed'
  | 'sent'
  | 'paid'
  | 'void';

interface InvoiceStatusProps {
  status: InvoiceStatusKind;
}

export const InvoiceStatus: FunctionComponent<InvoiceStatusProps> = ({
  status,
}) => {
  switch (status) {
    case 'unpaid':
      return <Label color="red">Impayée</Label>;
    case 'partially_paid':
      return <Label color="yellow">Partiellement payée</Label>;
    case 'overdue':
      return <Label color="orange">En retard</Label>;
    case 'draft':
      return <Label color="grey">Brouillon</Label>;
    case 'viewed':
      return <Label color="purple">Vue</Label>;
    case 'sent':
      return <Label color="blue">Envoyée</Label>;
    case 'paid':
      return <Label color="green">Payée</Label>;
    default:
      return <Label>{status}</Label>;
  }
};
