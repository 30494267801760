import { Grid } from 'semantic-ui-react';
import { NumberParam, ObjectParam, useQueryParams } from 'use-query-params';
import React, { FunctionComponent } from 'react';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { TradeSearchFilter } from 'components/trade-search-filter';
import { TradesInfoTable } from './trades-info-table';

const TradesInfoListPage: FunctionComponent = () => {
  const [filters, setFilters] = useQueryParams({
    tradeId: NumberParam,
    orderBy: ObjectParam,
  });

  return (
    <Content>
      <ContentHeader>
        <Grid columns={1}>
          <Grid.Column>
            <ContentHeaderTitle>Catégorie</ContentHeaderTitle>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <div style={{ display: 'inline-block', marginLeft: '-12px' }}>
          <TradeSearchFilter
            onDelete={(): void => {
              setFilters({
                tradeId: undefined,
              });
            }}
            tradeId={filters.tradeId || undefined}
            onResultSelect={(event, { result }): void => {
              setFilters({
                tradeId: result.id,
              });
            }}
          />
        </div>
        <TradesInfoTable
          filters={{
            tradeId: filters.tradeId ?? undefined,
          }}
          orderBy={{
            column: filters.orderBy?.column ?? 'tradeProViews',
            direction: filters.orderBy?.direction ?? 'descending',
          }}
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
            });
          }}
        />
      </ContentBody>
    </Content>
  );
};

export default TradesInfoListPage;
