import React, { FunctionComponent } from 'react';
import { Label, Popup } from 'semantic-ui-react';

type IpCheckerIndicatorViewProps = {
  isfirstReviewWithThisIp: boolean;
  isMultipleReviewsWithThisIpOnSamePro: boolean;
  openProReviews: () => void;
};

export const IpCheckerIndicatorView: FunctionComponent<
  IpCheckerIndicatorViewProps
> = ({
  isfirstReviewWithThisIp,
  isMultipleReviewsWithThisIpOnSamePro,
  openProReviews,
}) => {
  // Only one review with this IP.
  if (isfirstReviewWithThisIp) {
    return (
      <Label size="mini" color="green">
        1er avis avec cette IP
      </Label>
    );
  } else if (isMultipleReviewsWithThisIpOnSamePro) {
    return (
      <Popup
        content="Cette IP a laissé plusieurs avis sur ce pro"
        trigger={
          <Label
            style={{ cursor: 'pointer' }}
            onClick={openProReviews}
            size="mini"
            color="red"
          >
            Verifier
          </Label>
        }
      />
    );
  }

  // More than one review with this IP.
  return (
    <Popup
      content="Cette IP a laissé plusieurs avis sur différents pros"
      trigger={
        <Label
          style={{ cursor: 'pointer' }}
          onClick={openProReviews}
          size="mini"
          color="red"
        >
          Avis fait sur d&apos;autres pros
        </Label>
      }
    />
  );
};
