import React, { FunctionComponent } from 'react';
import { withFormik, FormikProps } from 'formik';
import { Form, Button } from 'semantic-ui-react';
import { fr } from 'date-fns/locale';
import { ToastInfo, ToastError } from 'utils';
import { format, setDate } from 'date-fns';
import FormField from 'components/form-field/form-field';
import { Invoice } from 'pages/export-sepa/invoice.type';
import exportSepaValidationSchema from './export-sepa-validation-schema';
import { apiClient } from 'axios-client';

type FormValues = {
  onlySepaFile: boolean;
  directDebitDate: string;
  includePostalBank: boolean;
};

type InnerFormProps = FormikProps<FormValues>;

const InnerForm: FunctionComponent<InnerFormProps> = ({
  handleSubmit,
  handleReset,
  setFieldValue,
  isSubmitting,
  values,
}) => (
  <Form onSubmit={handleSubmit} onReset={handleReset}>
    <FormField
      name="includePostalBank"
      type="checkbox"
      label="Inclure banque postale"
    />
    <Form.Group widths="equal">
      <FormField
        label="Date choisie pour le transfert"
        values={values}
        required
        name="directDebitDate"
        type="date"
        dateFormat="dd/MM/yyyy"
        locale={fr}
      />
      <Button
        style={{ marginTop: '23px', minWidth: '300px' }}
        type="button"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={(): void => {
          setFieldValue('onlySepaFile', true);
          handleSubmit();
        }}
      >
        Générer le SEPA Uniquement
      </Button>
      <Button
        style={{ marginTop: '23px', minWidth: '300px' }}
        type="button"
        loading={isSubmitting}
        disabled={isSubmitting}
        primary
        onClick={(): void => {
          setFieldValue('onlySepaFile', false);
          handleSubmit();
        }}
      >
        Générer le SEPA et Enregistrer les paiements
      </Button>
    </Form.Group>
  </Form>
);

type ExportSepaFormProps = {
  invoices: Invoice[];
  zohoOrganizationId: number;
};

const ExportSepaForm = withFormik<ExportSepaFormProps, FormValues>({
  validateOnChange: true,
  validationSchema: exportSepaValidationSchema(),
  mapPropsToValues: () => ({
    directDebitDate: setDate(new Date(), 15).toISOString(),
    onlySepaFile: false,
    includePostalBank: false,
  }),
  handleSubmit: async (values, { props: { invoices, zohoOrganizationId } }) => {
    const invoicesFiltered = invoices.filter((invoice) => !invoice.hide);

    if (invoicesFiltered.length === 0) {
      ToastInfo('Info', 'Pas de factures à exporter');
      return;
    }

    const sepa = await apiClient
      .post(
        `/zoho/invoice/sepa`,
        {
          invoices: invoicesFiltered,
          directDebitDate: values.directDebitDate,
          onlySepaFile: values.onlySepaFile,
          zohoOrganizationId,
          includePostalBank: values.includePostalBank,
        },
        {
          responseType: 'blob',
        },
      )
      .then((response) => response.data)
      .catch((error) => {
        ToastError('Erreur', 'Impossible de générer le ficher SEPA');
        throw error;
      });

    const element = document.createElement('a');
    const file = new Blob([sepa]);
    element.href = URL.createObjectURL(file);
    element.download = `sepa-${format(new Date(), 'yyyy-MM')}-${
      invoicesFiltered.length
    }.zip`;
    document.body.appendChild(element);
    element.click();
  },
})(InnerForm);

export default ExportSepaForm;
