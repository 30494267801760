import { Button, Icon, Image, Loader, Modal, Popup } from 'semantic-ui-react';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { ToastError } from 'utils/toast';
import { apiClient } from 'axios-client';
import env from 'env';
import Dropzone from 'components/dropzone/dropzone';
import ImageDeleteButton from 'components/image-delete-button/image-delete-button';
import { useDropzone } from 'react-dropzone';
import {
  FixedCropper,
  FixedCropperRef,
  ImageRestriction,
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

interface OverlayProps {
  simplified?: boolean;
}

const Overlay: FunctionComponent<OverlayProps> = ({ simplified = false }) => (
  <div
    style={{
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      pointerEvents: 'none',
    }}
  >
    {/* Dashed horizontal line */}
    <div
      style={{
        position: 'absolute',
        top: '75%',
        left: '0',
        right: '0',
        border: '1px dashed red',
        pointerEvents: 'none',
      }}
    />
    {/* Top horizontal line */}
    <div
      style={{
        position: 'absolute',
        top: '15%',
        left: '0',
        right: '0',
        border: '1px solid red',
        pointerEvents: 'none',
      }}
    />
    {/* Bottom horizontal line */}
    <div
      style={{
        position: 'absolute',
        bottom: '15%',
        left: '0',
        right: '0',
        border: '1px solid red',
        pointerEvents: 'none',
      }}
    />
    {!simplified && (
      <>
        {/* Vertical line */}
        <div
          style={{
            position: 'absolute',
            top: '15%',
            bottom: '15%',
            left: '50%',
            border: '1px dashed red',
            pointerEvents: 'none',
          }}
        />
        {/* Dimmer top */}
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '85%',
            left: '0',
            right: '0',
            opacity: '0.5',
            backgroundColor: 'red',
            pointerEvents: 'none',
          }}
        />
        {/* Dimmer Bottom */}
        <div
          style={{
            position: 'absolute',
            top: '85%',
            bottom: '0',
            left: '0',
            right: '0',
            opacity: '0.5',
            backgroundColor: 'red',
            pointerEvents: 'none',
          }}
        />
      </>
    )}
  </div>
);

type AvatarUploadProps = {
  onChange: (event: Event, data: { value: string | null }) => void;
  onRestore?: () => void;
  restorePopupContent?: ReactNode;
  value: string | null;
};

const AvatarUpload: FunctionComponent<AvatarUploadProps> = ({
  onChange,
  onRestore,
  restorePopupContent,
  value,
}) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const cropper = useRef<FixedCropperRef>(null);
  const [isUploading, setIsUploading] = useState(false);

  const upload = useCallback(
    async (image: File): Promise<void> => {
      setIsUploading(true);
      const formData = new FormData();

      formData.append('file', image);

      const imageHash: string = await apiClient
        .post(`${env.API_URL}/images`, formData)
        .then((response) => {
          return response.data.id;
        })
        .catch((error) => {
          ToastError('Erreur', "Impossible d'upload l'image");
          throw error;
        });

      setIsUploading(false);
      onChange(new Event('onChange'), { value: imageHash });
    },
    [onChange],
  );

  const handleEditClick = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: !!value,
    multiple: false,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
    onDrop: async (droppedFiles: File[], errors) => {
      const file = droppedFiles[0];
      if (errors.length > 0) {
        console.error(errors[0].errors[0].message);
        return;
      }
      if (file.size < 10485760) {
        await upload(file);
      } else {
        ToastError(file.name, 'Upoad impossible : Fichier trop volumineux !');
      }
    },
  });

  return (
    <>
      {value ? (
        <div style={{ position: 'relative' }}>
          <Image
            fluid
            src={`${env.API_URL}/images/${value}/full/450,450/0/default.jpg`}
          />
          <Overlay simplified />
          <ImageDeleteButton
            confirm
            onDelete={(): void => {
              onChange(new Event('onChange'), { value: null });
            }}
          />
          <Button
            icon
            onClick={handleEditClick}
            style={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              margin: '10px',
            }}
            type="button"
          >
            <Icon name="edit" />
          </Button>
        </div>
      ) : (
        <Dropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
        >
          <Icon size="huge" name="user outline" />
          <div style={{ marginTop: '15px' }}>
            {!isUploading ? (
              <Button positive type="button">
                <Icon name="upload" />
                Ajouter un avatar
              </Button>
            ) : (
              <Loader active inline />
            )}
          </div>
        </Dropzone>
      )}
      {value && (
        <Modal open={isEditModalOpen} onClose={handleEditModalClose}>
          <Modal.Header>Edit Image</Modal.Header>
          <Modal.Content>
            <div
              style={{
                position: 'relative',
              }}
            >
              <FixedCropper
                ref={cropper}
                // Fill image by default
                defaultSize={({ imageSize, visibleArea }) => {
                  return {
                    width: (visibleArea || imageSize).width,
                    height: (visibleArea || imageSize).height,
                  };
                }}
                src={`${env.API_URL}/images/${value}/full/0,0/0/default.jpg`}
                onInteractionEnd={() => {
                  setIsUpdated(true);
                }}
                stencilSize={{ width: 450, height: 450 }}
                backgroundWrapperProps={{
                  scaleImage: false, // Disable zoom on scroll
                }}
                imageRestriction={ImageRestriction.stencil}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '450px', // Stencil size
                  height: '450px', // Stencil size
                  pointerEvents: 'none',
                }}
              >
                <Overlay />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '24px',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ flexBasis: '30%', paddingRight: '12px' }}>
                <p>Une seule personne :</p>
                <ul>
                  <li>
                    Sommet de la tête (cheveux compris) aligné sur la ligne
                    rouge du dessus.
                  </li>
                  <li>Menton aligné sur la ligne en pointillé.</li>
                </ul>
              </div>
              <div style={{ flexBasis: '20%', paddingRight: '12px' }}>
                <img
                  width="100%"
                  src="/avatar-example-1.png"
                  alt="Avatar exemple 1"
                />
              </div>
              <div style={{ flexBasis: '30%', paddingRight: '12px' }}>
                <p>Plusieurs personnes :</p>
                <ul>
                  <li>
                    Sommet de la tête de la personne la plus grande (cheveux
                    compris) aligné sur la ligne rouge du dessus.
                  </li>
                  <li>
                    Menton de la personne la plus petite aligné sur la ligne en
                    pointillé. Possibilité de dépasser jusqu&apos;à la ligne
                    rouge du dessous si impossible.
                  </li>
                </ul>
              </div>
              <div style={{ flexBasis: '20%' }}>
                <img
                  width="100%"
                  src="/avatar-example-2.png"
                  alt="Avatar exemple 2"
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleEditModalClose} basic>
              Fermer
            </Button>
            {onRestore && (
              <Popup
                disabled={!restorePopupContent}
                content={restorePopupContent}
                trigger={
                  <Button onClick={onRestore} primary>
                    Restaurer la photo originale
                  </Button>
                }
              />
            )}
            <Button
              disabled={isUploading}
              loading={isUploading}
              onClick={async () => {
                const isSquare =
                  cropper.current?.getImage()?.width ===
                  cropper.current?.getImage()?.height;

                // Do not update if the image has not been edited except if it's not a square
                // (FixedCropper generated new hash each time. And we should avoid creating much images)
                if (!isUpdated && isSquare) {
                  onChange(new Event('onChange'), { value });
                  setEditModalOpen(false);
                  return;
                }

                const blob = await new Promise<Blob | null>((resolve) => {
                  cropper.current?.getCanvas()?.toBlob((blob) => {
                    resolve(blob);
                  });
                });

                if (!blob) return;

                const file = new File([blob], 'avatar.png');
                await upload(file);
                setIsUpdated(false);
                setEditModalOpen(false);
              }}
              positive
            >
              Valider
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default AvatarUpload;
