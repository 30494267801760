import {
  Search,
  SearchProps,
  SearchResultData,
  SearchResultsProps,
} from 'semantic-ui-react';
import React, { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';

// Override style to fix search input when we are into an <Form> component
const SearchStyled = styled(Search)`
  input {
    border-radius: 3px !important;
    border-color: #767676 !important;
    padding: 5px !important;

    &:focus {
      border-color: #3c8dbc !important;
    }
  }
`;

type AccountSearchFilterViewProps = {
  value?: string;
  onSearchChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: SearchProps,
  ) => void;
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  searchResults?: SearchResultsProps;
  loading?: boolean;
  style?: CSSProperties;
  placeholder?: string;
};

export const AccountSearchFilterView: FunctionComponent<
  AccountSearchFilterViewProps
> = ({
  value,
  searchResults,
  loading,
  onSearchChange,
  onResultSelect,
  style,
  placeholder,
}) => (
  <SearchStyled
    style={{
      display: 'inline-block',
      marginLeft: '12px',
      ...style,
    }}
    input={{
      icon: 'search',
      iconPosition: 'left',
    }}
    noResultsMessage="Pas de résultats"
    placeholder={placeholder ?? 'Contact...'}
    value={value}
    results={searchResults}
    onSearchChange={onSearchChange}
    loading={loading}
    onResultSelect={onResultSelect}
  />
);
