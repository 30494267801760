import Mailcheck from 'mailcheck';
import { useEffect, useState } from 'react';

const domains = [
  'msn.com',
  'bellsouth.net',
  'telus.net',
  'comcast.net',
  'optusnet.com.au',
  'earthlink.net',
  'qq.com',
  'sky.com',
  'icloud.com',
  'mac.com',
  'sympatico.ca',
  'googlemail.com',
  'att.net',
  'xtra.co.nz',
  'web.de',
  'cox.net',
  'gmail.com',
  'ymail.com',
  'aim.com',
  'rogers.com',
  'verizon.net',
  'rocketmail.com',
  'google.com',
  'optonline.net',
  'sbcglobal.net',
  'aol.com',
  'me.com',
  'btinternet.com',
  'charter.net',
  'shaw.ca',
];
domains.push('orange.fr', 'free.fr');

export const useMailSuggestion = (email: string): string => {
  const [suggestion, setSuggestion] = useState('');

  useEffect(() => {
    Mailcheck.run({
      domains,
      email,
      suggested: function (suggestion) {
        setSuggestion(suggestion.full);
      },
      empty: function (element) {
        setSuggestion('');
      },
    });
  }, [email]);

  return suggestion;
};
