import { OrderBy, useProPeopleTableQuery } from 'generated/graphql';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import React, { FunctionComponent } from 'react';
import { ProPeopleTableContainer } from './pro-people-table-container';

type ProPeopleTableRepository = {
  filters?: {
    id?: number;
    bilikZoneId?: number;
    proViewStatus?: string;
  };
  limit?: number;
  offset?: number;
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
};

export const ProPeopleTableRepository: FunctionComponent<
  ProPeopleTableRepository
> = ({ limit, offset, orderBy, onSort, filters }) => {
  const { currentBilikZone } = useCurrentBilikZone();

  const { loading, data } = useProPeopleTableQuery({
    variables: {
      where: {
        id: filters?.id ? { _eq: filters.id } : undefined, // id filter
        proViews: {
          proView: {
            status: {
              _eq: filters?.proViewStatus ?? undefined,
            },
            bilikZoneId: { _eq: currentBilikZone?.id ?? undefined },
          },
        },
        // Keep only proPeople that have an email or a telephone
        _or: [
          {
            _and: [
              { telephone: { _neq: '' } },
              { telephone: { _isNull: false } },
            ],
          },
          {
            _and: [{ email: { _isNull: false } }, { email: { _neq: '' } }],
          },
        ],
      },
      limit,
      offset,
      orderBy: orderBy
        ? [
            {
              [orderBy.column]:
                orderBy.direction === 'ascending' ? OrderBy.Asc : OrderBy.Desc,
            },
          ]
        : undefined,
    },
  });

  return (
    <ProPeopleTableContainer
      loading={loading}
      proPeople={data?.proPerson}
      onSort={onSort}
      orderBy={orderBy}
    />
  );
};
