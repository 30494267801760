import React, { FunctionComponent, useCallback } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import { formatDateTime, formatTelephone } from 'utils';
import { Table, Icon, Dimmer, Loader, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { pathOr } from 'remeda';
import { CallsTableGloballyBlockedQuery } from 'generated/graphql';
import ProFullName from 'components/pro-full-name/pro-full-name';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import { CallRecording } from 'components/call-recording/call-recording';
import { CallTranscription } from 'components/call-transcription/call-transcription';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';

type CallsTableViewProps = {
  calls: MongoEvent[];
  loading: boolean;
  filters: {
    currentBilikZoneId?: number;
    proViewId?: number;
  };
  orderBy;
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
  globallyBlocked: CallsTableGloballyBlockedQuery['globallyBlockedIdentity'];
};

const CallsTableView: FunctionComponent<CallsTableViewProps> = ({
  calls,
  filters,
  orderBy,
  loading,
  onSort,
  onFilter,
  globallyBlocked,
}) => {
  const { currentBilikZone } = useCurrentBilikZone();
  const isCurrentUserAdmin = useIsUserAdmin();

  const getTelephone = useCallback((call: MongoEvent): string => {
    switch (call.version) {
      case 0:
        return call.data.callrCall.cliNumber;
      case 1:
        return call.data.callrCall.cli_number;
      default:
        return call.initiator.pi.telephone;
    }
  }, []);

  const getDuration = useCallback((call: MongoEvent): number => {
    switch (call.version) {
      case 0:
        return call.data.callrCall.durationAnswered;
      case 1:
        return call.data.callrCall.duration_answered;
      case 2:
      case 3:
        return call.data?.twilio?.CallDuration ?? call.data?.twilio?.Duration;
      default:
        return call.data.vonage.duration;
    }
  }, []);

  const onSortByDateClicked = useCallback((): void => {
    if (onSort) {
      onSort(
        'date',
        orderBy?.direction === 'ascending' ? 'descending' : 'ascending',
      );
    }
    if (onFilter) {
      onFilter('page', '1');
    }
  }, [onSort, onFilter, orderBy?.direction]);

  const isGloballyBlocked = useCallback((call: MongoEvent): boolean => {
    return globallyBlocked.some(
      (value) => value.identifier.split(':')[1] === getTelephone(call),
    );
  }, []);

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={orderBy?.direction}
              onClick={onSortByDateClicked}
            >
              Date
            </Table.HeaderCell>
            {!filters.proViewId ? (
              <>
                <Table.HeaderCell className="not sortable">
                  Pro / Zone
                </Table.HeaderCell>
              </>
            ) : null}
            <Table.HeaderCell className="not sortable">
              Numéro du particulier
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Durée de l&apos;appel
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Enregistrement
            </Table.HeaderCell>
            {filters.proViewId ? (
              <Table.HeaderCell className="not sortable">
                Détail (redirections)
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {calls.map((call: any, i) => (
            <Table.Row key={i}>
              <Table.Cell>{formatDateTime(call.date)}</Table.Cell>
              {!filters.proViewId ? (
                <>
                  <Table.Cell>
                    {call.actors?.pro !== undefined ? (
                      <>
                        <Link
                          to={`/pros/update/${call.actors?.pro?.proView._id}/calls`}
                        >
                          <ProFullName
                            name={call.actors?.pro?.proPresentation.name}
                            mainCityCode={
                              !currentBilikZone && !filters.proViewId
                                ? call.actors?.pro?.bilikZone.mainCityCode
                                : null
                            }
                            mainTradeName={
                              call.actors?.pro?.proPresentation.mainTrade?.name
                            }
                          />
                        </Link>
                      </>
                    ) : (
                      call.actors?.bilik?.bilikZone.mainCity
                    )}
                  </Table.Cell>
                </>
              ) : null}
              <Table.Cell>
                <Popup
                  position="top center"
                  trigger={
                    <Link
                      to={`/timeline/${encodeURIComponent(getTelephone(call))}`}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <Icon fitted name="time" />
                      <Icon fitted name="long arrow alternate down" />
                    </Link>
                  }
                  content="Timeline du particulier"
                />
                &nbsp; &nbsp;
                {formatTelephone(getTelephone(call))}
                &nbsp; &nbsp;
                {isGloballyBlocked(call) ? (
                  <Popup
                    position="top center"
                    trigger={<Icon color="red" name="dont" />}
                    content="Bloqué"
                  />
                ) : null}
              </Table.Cell>
              <Table.Cell>
                {Math.floor(getDuration(call) / 60)} min{' '}
                {getDuration(call) % 60} sec
              </Table.Cell>
              <Table.Cell>
                <CallRecording
                  conversationUuid={call.data.vonage?.conversation_uuid}
                  duration={getDuration(call)}
                />
                {isCurrentUserAdmin && (
                  <CallTranscription
                    conversationUuid={call.data.vonage?.conversation_uuid}
                  />
                )}
              </Table.Cell>
              {filters.proViewId ? (
                <Table.Cell>
                  {pathOr(call, ['data', 'twilio', 'Forwarded'], false)
                    ? 'Appel redirigé'
                    : null}
                  {pathOr(call, ['data', 'vonage', 'forwarded'], false)
                    ? 'Appel redirigé'
                    : null}
                </Table.Cell>
              ) : null}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default CallsTableView;
