import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Button, Modal, Checkbox } from 'semantic-ui-react';
import {
  SlugRedirect,
  useDeleteSlugRedirectbyIdsMutation,
} from 'generated/graphql';
import { ToastError } from 'utils';
import './slug-redirect-modal.css';

type SlugRedirectModalProps = {
  circularDependencies: SlugRedirect[];
};

const SlugRedirectModal: FunctionComponent<SlugRedirectModalProps> = ({
  circularDependencies,
}) => {
  const [slugRedirectIds, setSlutRedirectIds] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(
    Boolean(circularDependencies.length),
  );
  const [deleteSlugRedirectByIds] = useDeleteSlugRedirectbyIdsMutation();

  useEffect(() => {
    setIsOpen(Boolean(circularDependencies.length));
  }, [circularDependencies]);

  const deleteSelectedSlugRedirect = useCallback(async (ids: number[]) => {
    try {
      await deleteSlugRedirectByIds({ variables: { ids } });
    } catch (err) {
      ToastError('Erreur', 'Impossible de supprimer les redirections de slug');
      console.error(err);
    }
  }, []);

  const handleCheckboxClick = useCallback(
    (isChecked: boolean | undefined, slugRedirectId) => {
      const ids = slugRedirectIds;
      if (isChecked) {
        ids.push(slugRedirectId);
      } else {
        const index = ids.indexOf(slugRedirectId);
        ids.splice(index, 1);
      }

      setSlutRedirectIds(ids);
    },
    [slugRedirectIds],
  );

  const onClick = useCallback(() => {
    if (slugRedirectIds.length) {
      deleteSelectedSlugRedirect(slugRedirectIds);
      setIsOpen(false);
    } else {
      ToastError(
        'Erreur',
        'Vous devez obligatoirement supprimer une redirection de slug',
      );
    }
  }, [deleteSelectedSlugRedirect, setIsOpen, slugRedirectIds]);

  return (
    <>
      <Modal
        size="tiny"
        open={isOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          Redirection de slug infini, veuillez supprimer au moins une
          redirection
        </Modal.Header>
        <Modal.Content>
          {circularDependencies.length
            ? circularDependencies.map((redirection) => (
                <Checkbox
                  onClick={(_, data): void =>
                    handleCheckboxClick(data.checked, data.value)
                  }
                  value={redirection.id}
                  className="redirect-checkbox"
                  key={redirection.id}
                  label={`${redirection.fromslug} ➔
                ${redirection.toslug}`}
                />
              ))
            : null}
          <Button
            type="button"
            className="redirect-submit-button"
            onClick={(): void => onClick()}
            primary
          >
            Enregistrer
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default SlugRedirectModal;
