import styled from 'styled-components';

const FieldError = styled.div`
  color: #dd4b39;
  font-weight: 400;
  padding: 0;
  margin: 5px 0 0 0;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
`;

export default FieldError;
