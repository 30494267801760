import { AxiosError } from 'axios';
import { apiClient } from 'axios-client';
import { MongoEvent } from 'interfaces/events.interface';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ToastError } from 'utils';
import { CallCheckerIndicatorView } from './call-checker-indicator-view';

type CallCheckerIndicatorRepositoryProps = {
  telephone: string;
  proViewIds: number[];
};

type Data = {
  calls: MongoEvent[];
};

export const CallCheckerIndicatorRepository: FunctionComponent<CallCheckerIndicatorRepositoryProps> = ({
  proViewIds,
  telephone,
}) => {
  const [mongoEvents, setMongoEvents] = useState<Data>({ calls: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    apiClient
      .post<MongoEvent[]>(`/event/pi-calls`, {
        piTelephone: telephone,
      })
      .then((response) => {
        setMongoEvents({ calls: response.data });
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        ToastError('Erreur', 'Impossible de vérifier les appels');
        console.error(error);
        setLoading(false);
      });
  }, [telephone]);

  const [hasPiCalledPro, hasPiCalledOtherPros] = useMemo(() => {
    let _hasPiCalledPro = false;
    let _hasPiCalledOtherPros = false;
    const proViewIdsCalled = mongoEvents.calls.map(
      (call) => (call.actors.pro?.proView?.id as unknown) as string,
    );

    const callToThisPro = proViewIdsCalled.find((proViewId) => {
      return proViewIds.find(
        (thisProViewId) => proViewId === `/pro_views/${thisProViewId}`,
      );
    });

    const oneCallToOtherPro = proViewIdsCalled.find((proViewId) => {
      return proViewIds.find(
        (thisProViewId) => proViewId !== `/pro_views/${thisProViewId}`,
      );
    });

    if (callToThisPro) {
      _hasPiCalledPro = true;
    }

    if (oneCallToOtherPro) {
      _hasPiCalledOtherPros = true;
    }

    return [_hasPiCalledPro, _hasPiCalledOtherPros];
  }, [mongoEvents]);

  const openTimeline = useCallback(() => {
    window.open(
      `/timeline/${encodeURIComponent(
        telephone,
      )}?eventTypes=CallDenied_CallStatusUpdated_CallRegionBlocked`,
    );
  }, []);

  return (
    <CallCheckerIndicatorView
      hasPiCalledOtherPros={hasPiCalledOtherPros}
      hasPiCalledPro={hasPiCalledPro}
      loading={loading}
      openTimeline={openTimeline}
    />
  );
};
