import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-less/semantic.less';
import App from './app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);
