import { Formik, FormikHelpers } from 'formik';
import { ProReviewFormUpdateQuery, ProReviewSetInput } from 'generated/graphql';

import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Loader } from 'semantic-ui-react';
import { formatE164Telephone, ToastError, ToastSuccess } from 'utils';
import { ProReviewFormFields } from './pro-review-form-fields.type';
import { proReviewFormValidationSchema } from './pro-review-form-validation-schema';
import { ProReviewFormView } from './pro-review-form-view';
import { addCrs } from 'utils/geometry';

type ProReviewFormUpdateContainerProps = {
  updateProReview: (proReview: ProReviewSetInput) => Promise<unknown>;
  formData?: ProReviewFormUpdateQuery['proReviewByPk'];
  loading: boolean;
};

export const ProReviewFormUpdateContainer: FunctionComponent<
  ProReviewFormUpdateContainerProps
> = ({ loading, updateProReview, formData }) => {
  const history = useNavigate();

  const initialValues: ProReviewFormFields = useMemo(() => {
    return {
      addressLocality: formData?.addressLocality ?? '',
      comment: formData?.comment ?? '',
      privateComment: formData?.privateComment ?? '',
      dateOfWork: formData?.dateOfWork ?? null,
      email: formData?.email ?? '',
      familyName: formData?.familyName ?? '',
      givenName: formData?.givenName ?? '',
      reply: formData?.reply === null ? undefined : formData?.reply,
      reviewAspect: formData?.reviewAspect ?? '',
      reviewBody: formData?.reviewBody ?? '',
      telephone: formData?.telephone ?? '',
      geoCoordinates: formData?.geoCoordinates ?? undefined,
      source: formData?.source === 'BILIK' ? '' : formData?.source ?? '',
      isExternalSource: formData?.source !== 'BILIK',
      rating: formData?.rating ?? 0,
    };
  }, [loading]);

  const hasInitialReply = useMemo(
    () => initialValues.reply !== undefined,
    [initialValues],
  );

  const onSubmit = useCallback(
    async (
      values: ProReviewFormFields,
      actions: FormikHelpers<ProReviewFormFields>,
    ) => {
      try {
        // If has inital reply let's polith the final value so it contains a space (not empty string)
        // Else hardcode the reply to null.
        values.reply = !hasInitialReply ? undefined : values.reply;

        // Set source = "BILIK" if the source is not external
        if (!values.isExternalSource) {
          values.source = 'BILIK';
        }
        delete values.isExternalSource;

        await updateProReview({
          ...values,
          reviewBody: values.reviewBody.trim(),
          privateComment: values.privateComment?.trim(),
          comment: values.comment.trim(),
          reply: values.reply?.trim(),
          email: values.email !== 'NULL' ? values.email.toLowerCase() : 'NULL',
          geoCoordinates:
            values?.addressLocality !== 'NULL'
              ? addCrs(values?.geoCoordinates)
              : undefined,
          telephone:
            values.telephone !== 'NULL'
              ? formatE164Telephone(values.telephone)
              : 'NULL',
          source: values.source === '' ? 'BILIK' : values.source,
        });

        ToastSuccess('Succès', 'Avis enregistré.');
        actions.setSubmitting(false);
        history(-1);
      } catch (e) {
        ToastError('Erreur', "Impossible d'enregistrer l'avis");
      }
    },
    [updateProReview, history],
  );

  if (loading) {
    return (
      <Loader size="big" active inline="centered" content="Chargement..." />
    );
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={proReviewFormValidationSchema(hasInitialReply)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <ProReviewFormView action="update" />
    </Formik>
  );
};
