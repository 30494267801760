import React, { FunctionComponent, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { ProReviewInvitationsGraphView } from './pro-review-invitations-graph-view';
import { ProReviewInvitationGraphQuery } from 'generated/graphql';
import { startOfMonth } from 'date-fns';
import { isSameMonth } from 'date-fns/esm';
import { convertTableData } from '../utils/convert-table-data';
import { CountByMonth } from '../utils/count-by-month.type';
import { Serie } from '@nivo/line';

type ProReviewInvitationsGraphContainerProps = {
  loading: boolean;
  proReviewInvitations?: ProReviewInvitationGraphQuery['proReviewInvitation'];
  date: Date;
  compareDate: Date;
};

export const ProReviewInvitationsGraphContainer: FunctionComponent<
  ProReviewInvitationsGraphContainerProps
> = ({ loading, date, compareDate, proReviewInvitations }) => {
  const [chartData, setChartData] = useState<Serie[]>([]);

  useEffect(() => {
    if (!proReviewInvitations) return;

    const countByMonth: CountByMonth[] = [];

    proReviewInvitations.forEach((solicitation) => {
      const solicitationDate = new Date(solicitation.dateCreated);
      const solicitationMonth = startOfMonth(solicitationDate);

      const existingMonth = countByMonth.find((item) =>
        isSameMonth(item.month, solicitationMonth),
      );

      if (existingMonth) {
        existingMonth.count++;
      } else {
        countByMonth.push({
          month: solicitationMonth,
          count: 1,
        });
      }
    });

    setChartData(
      convertTableData(countByMonth, [
        date.getFullYear(),
        compareDate.getFullYear(),
      ]),
    );
  }, [proReviewInvitations]);

  if (loading) {
    return (
      <div style={{ height: 276 }}>
        <Loader
          size="large"
          active
          inline="centered"
          style={{ marginTop: 224 }}
        />
      </div>
    );
  }

  return <ProReviewInvitationsGraphView data={chartData} />;
};
