import {
  isValidPhoneNumber,
  parsePhoneNumber,
  PhoneNumber,
} from 'libphonenumber-js';

// Format international telephone :
// To national format for french number (ex: 01 23 45 67 89)
// To formatted international number for other countries (ex: +32 1 23 45 67 89)
// (replacing all spaces by non-breaking space)
export const formatTelephone = (telephone: string) => {
  if (!isValidPhoneNumber(telephone)) {
    throw new Error(
      `${telephone} is not a valid telephone number, E164 format needed`,
    );
  }

  const phoneNumber: PhoneNumber = parsePhoneNumber(telephone);

  if (phoneNumber.country === 'FR') {
    return phoneNumber.formatNational().replace(/ /g, '\xa0'); // Replace all space by non-breaking space
  }

  return phoneNumber.formatInternational().replace(/ /g, '\xa0'); // Replace all space by non-breaking space
};

// Format french telephone to E.164 format
// Format international telephone to E.164 format
export const formatE164Telephone = (telephone: string) => {
  const phoneNumber: PhoneNumber = parsePhoneNumber(telephone, 'FR');
  return phoneNumber.format('E.164');
};

// Check if french telephone is valid (0680762247 => true)
// Check if international telephone is valid (+321234567 => true)
export const isValidFrTelephone = (value?: string) => {
  try {
    const phoneNumber: PhoneNumber = parsePhoneNumber(value || '', 'FR');
    return isValidPhoneNumber(phoneNumber?.number);
  } catch (error) {
    return false;
  }
};
