import React, { FunctionComponent } from 'react';
import { Loader } from 'semantic-ui-react';
import { TradesFormUpdate } from '../trade-form';
import { Navigate, useParams } from 'react-router-dom';
import { useTradeUpdatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentContainer from 'components/content-container/content-container';
import ContentBody from 'components/content-body/content-body';

const TradeUpdatePage: FunctionComponent = () => {
  const { tradeId } = useParams();

  const { data, loading } = useTradeUpdatePageQuery({
    variables: { id: Number(tradeId) },
  });

  if (!loading && !data?.tradeByPk) return <Navigate to="/404" replace />;

  if (!data?.tradeByPk) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Catégorie : {data.tradeByPk.name}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <TradesFormUpdate />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default TradeUpdatePage;
