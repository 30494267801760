import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router';
import BilikPeopleListPage from 'pages/bilik-people/bilik-people-list-page';
import BilikPersonCreatePage from 'pages/bilik-people/bilik-person-create-page';
import BilikPersonUpdatePage from 'pages/bilik-people/bilik-person-update-page';
import BilikZoneCreatePage from 'pages/bilik-zones/bilik-zone-create-page';
import BilikZoneUpdatePage from 'pages/bilik-zones/bilik-zone-update-page';
import BilikZonesListPage from 'pages/bilik-zones/bilik-zones-list-page';
import DashboardPage from 'pages/dashboard/dashboard-page';
import ExportGuidePage from 'pages/export-guide/export-guide-page';
import { ExportSepaPage } from 'pages/export-sepa/export-sepa-page';
import ProReviewInvitationsCreatePage from 'pages/pro-review-invitations/pro-review-invitations-create-page';
import { ProReviewsCreatePage } from 'pages/pro-reviews/pro-reviews-create-page';
import ProReviewsListPage from 'pages/pro-reviews/pro-reviews-list-page';
import ProsListPage from 'pages/pros/pros-list-page';
import ProPeopleListPage from 'pages/pro-people/pro-people-list-page';
import ProUpdatePage from 'pages/pros/pro-update-page';
import SolicitationsListPage from 'pages/solicitations/solicitations-list-page';
import TimelinePage from 'pages/timeline/timeline-page';
import ToolsPage from 'pages/tools/tools-page';
import TradeCreatePage from 'pages/trades/trade-create-page';
import TradeUpdatePage from 'pages/trades/trade-update-page/trade-update-page';
import TradesListPage from 'pages/trades/trades-list-page';
import NotFoundPage from 'pages/not-found/not-found-page';
import ProReviewUpdatePage from 'pages/pro-reviews/pro-reviews-update-page';
import AdminRoute from 'components/admin-route/admin-route';
import { CallsListPage } from 'pages/calls/calls-list-page';
import { usePageTracking } from 'hooks/use-page-tracking/use-page-tracking';
import CitiesListPage from 'pages/cities/cities-list-page';
import CityUpdatePage from 'pages/cities/city-update-page';
import CityCreatePage from 'pages/cities/city-create-page';
import SmsReceivedPage from 'pages/sms-received/sms-received-page';
import TradesGuideListPage from 'pages/trades-guide/trades-guide-list-page';
import TradesInfoListPage from 'pages/trades-info/trades-info-list-page';

const Routes: FunctionComponent = () => {
  usePageTracking();

  return (
    <Switch>
      {/* Pro */}
      <Route path="/" element={<Navigate to="/pros" replace />} />
      <Route path="/pros" element={<ProsListPage />} />
      <Route path="/pros/update/:proViewId" element={<ProUpdatePage />} />
      <Route path="/pros/update/:proViewId/:path" element={<ProUpdatePage />} />
      {/* Home */}
      <Route path="/dashboard" element={<DashboardPage />} />
      {/* Appels */}
      <Route path="/calls" element={<CallsListPage />} />
      {/* Timeline */}
      <Route path="/timeline/:searchValue" element={<TimelinePage />} />
      {/* Solicitations */}
      <Route path="/solicitations" element={<SolicitationsListPage />} />
      {/* Solicitations */}
      <Route path="/sms-received" element={<SmsReceivedPage />} />
      {/* Pro peoples */}
      <Route path="/pro_people" element={<ProPeopleListPage />} />
      {/* Pro trades */}
      <Route path="/trades" element={<TradesListPage />} />
      <Route
        path="/trades/create"
        element={
          <AdminRoute>
            <TradeCreatePage />
          </AdminRoute>
        }
      />
      <Route
        path="/trades/update/:tradeId"
        element={
          <AdminRoute>
            <TradeUpdatePage />
          </AdminRoute>
        }
      />
      {/* Trades-info */}
      <Route path="/trades-info" element={<TradesInfoListPage />} />
      {/* trades Guide */}
      <Route path="/trades_guide" element={<TradesGuideListPage />} />
      {/* Bilik zones */}
      <Route
        path="/bilik_zones"
        element={
          <AdminRoute>
            <BilikZonesListPage />
          </AdminRoute>
        }
      />
      <Route
        path="/bilik_zones/create"
        element={
          <AdminRoute>
            <BilikZoneCreatePage />
          </AdminRoute>
        }
      />
      <Route
        path="/bilik_zones/update/:bilikZoneId"
        element={
          <AdminRoute>
            <BilikZoneUpdatePage />
          </AdminRoute>
        }
      />
      {/* Cities */}
      <Route
        path="/cities"
        element={
          <AdminRoute>
            <CitiesListPage />
          </AdminRoute>
        }
      />
      <Route
        path="/cities/create"
        element={
          <AdminRoute>
            <CityCreatePage />
          </AdminRoute>
        }
      />
      <Route
        path="/cities/update/:cityId"
        element={
          <AdminRoute>
            <CityUpdatePage />
          </AdminRoute>
        }
      />
      {/* Export-guide */}
      <Route
        path="/export_guide"
        element={
          <AdminRoute>
            <ExportGuidePage />
          </AdminRoute>
        }
      />
      {/* Tools */}
      <Route
        path="/tools"
        element={
          <AdminRoute>
            <ToolsPage />
          </AdminRoute>
        }
      />
      {/* Bilik people */}
      <Route
        path="/bilik_people"
        element={
          <AdminRoute>
            <BilikPeopleListPage />
          </AdminRoute>
        }
      />
      <Route
        path="/bilik_people/create"
        element={
          <AdminRoute>
            <BilikPersonCreatePage />
          </AdminRoute>
        }
      />
      <Route
        path="/bilik_people/update/:bilikPersonId"
        element={
          <AdminRoute>
            <BilikPersonUpdatePage />
          </AdminRoute>
        }
      />
      {/* Pro reviews */}
      <Route path="/pro_reviews" element={<ProReviewsListPage />} />
      <Route
        path="/pro_reviews/update/:proReviewId"
        element={<ProReviewUpdatePage />}
      />
      <Route
        path="/pro_reviews/create/:proPresentationId"
        element={<ProReviewsCreatePage />}
      />
      {/* Pro review invitations */}
      <Route
        path="/pro_review_invitations/create/:proPresentationId/:proViewId"
        element={<ProReviewInvitationsCreatePage />}
      />
      {/* Zoho export SEPA tool */}
      <Route path="/export_sepa" element={<ExportSepaPage />} />
      {/* 404 */}
      <Route path="*" element={<NotFoundPage />} />
    </Switch>
  );
};
export default Routes;
