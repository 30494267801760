import React, { Fragment, FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { pathOr } from 'remeda';
import { formatTelephone } from 'utils';

type CallIdentityBlockedProps = {
  event: MongoEvent;
};

const CallIdentityBlocked: FunctionComponent<CallIdentityBlockedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      color="red"
      icon="phone"
      date={event.date}
      title="Appel bloqué"
      initiator="particulier"
    >
      <h3>
        Le particulier a tenté d&apos;appeler{' '}
        <span>
          {pathOr(
            event,
            ['actors', 'pro', 'proView'] as unknown as [keyof MongoEvent],
            false,
          ) ? (
            <Fragment>
              {event.actors.pro.proPresentation.name} (
              {event.actors.pro.bilikZone.mainCityCode} -{' '}
              {event.actors.pro.proPresentation.mainTrade?.name})
            </Fragment>
          ) : (
            'un Pro inconnu ou plus dans Bilik'
          )}{' '}
        </span>
        via le {formatTelephone(event.initiator.pi.telephone)} (bloqué)
      </h3>
    </TimelineEvent>
  );
};

export default CallIdentityBlocked;
