import React, { FunctionComponent, useCallback } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import AppLayout from 'components/app-layout/app-layout';
import Client from 'client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Loader } from 'semantic-ui-react';
import { keycloak, setRefreshToken, setToken, setTokenExp } from './utils';
import styled from 'styled-components';
import env from 'env';

const envColor = (): string => {
  switch (env.ENVIRONMENT) {
    case 'development':
      return 'green !important';
    case 'e2e':
      return 'fuchsia !important';
    case 'staging':
      return 'orange !important';
    default:
      return '#357ca5';
  }
};

const envName = (): string => {
  switch (env.ENVIRONMENT) {
    case 'development':
      return 'Development';
    case 'e2e':
      return 'E2e';
    case 'staging':
      return 'Staging';
    default:
      return '';
  }
};

const EnvBanner = styled.div`
  display: block;
  height: 50px;
  position: sticky;
  background: ${envColor()};
  top: 0;
  left: 0;
  z-index: 5000;

  &:before {
    content: '${envName()}';
    color: #fff;
    line-height: 50px;
    vertical-align: middle;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    text-align: center;
  }
`;

const App: FunctionComponent = () => {
  const handleAuthentication = useCallback(() => {
    if (!keycloak.authenticated) {
      keycloak.login({ scope: 'offline_access' });
    }
  }, [keycloak.authenticated]);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={async (event): Promise<void> => {
        if (event === 'onReady') {
          handleAuthentication();
        }

        if (
          event === 'onAuthSuccess' &&
          keycloak.token &&
          keycloak.refreshToken &&
          keycloak.tokenParsed &&
          keycloak.tokenParsed.exp
        ) {
          setToken(keycloak.token);
          setRefreshToken(keycloak.refreshToken);
          setTokenExp(keycloak.tokenParsed.exp);
        }
      }}
      LoadingComponent={<Loader />}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover
        hideProgressBar
      />
      <ApolloProvider client={Client}>
        {env.ENVIRONMENT !== 'production' ? <EnvBanner /> : null}
        <AppLayout />
      </ApolloProvider>
    </ReactKeycloakProvider>
  );
};
export default App;
