import { ResponsiveBar } from '@nivo/bar';
import { ProReviewInvitationsCountByDateQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Container, Header, Statistic } from 'semantic-ui-react';

type Props = {
  chartData: ProReviewInvitationsCountByDateQuery['countProReviewInvitationsByMonthByProPresentation'];
  totalCount: number;
};

export const ProStatsProReviewInvitationsView: FunctionComponent<Props> = ({
  chartData,
  totalCount,
}) => {
  return (
    <Container fluid>
      <Header>Relances d&apos;avis</Header>
      <br />
      <Statistic horizontal size="tiny">
        <Statistic.Value style={{ color: '#9BC53D' }}>
          {totalCount}
        </Statistic.Value>
        <Statistic.Label style={{ color: '#9BC53D', fontWeight: 'bold' }}>
          Relances d&apos;avis
        </Statistic.Label>
      </Statistic>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#9BC53D']}
          groupMode="grouped"
          data={chartData}
          keys={['count']}
          indexBy="month"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          axisLeft={{
            tickValues: chartData.length,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};
