import styled from 'styled-components';

const HelpText = styled.p`
  font-style: italic;
  display: block;
  margin-top: -5px;
  margin-bottom: 5px;
  color: #737373;
`;

export default HelpText;
