import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de;
  margin-left: 230px;
  font-weight: 600;
`;

const MainFooter: FunctionComponent = () => (
  <Wrapper>Copyright © Bilik. All rights reserved.</Wrapper>
);

export default MainFooter;
