import { CityFormUpdateQuery, CitySetInput } from 'generated/graphql';
import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';

import { CityFormFields } from './city-form-fields.type';
import CityFormView from './city-form-view';
import { Loader } from 'semantic-ui-react';
import { cityFormValidationSchema } from './city-form-validation-schema';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';
import { addCrs } from 'utils/geometry';

type CityFormUpdateProps = {
  updateCity: (city: CitySetInput) => Promise<void>;
  formData?: CityFormUpdateQuery['cityByPk'];
  cityId: number;
  loading: boolean;
};

const CityFormUpdateContainer: FunctionComponent<CityFormUpdateProps> = ({
  loading,
  formData,
  updateCity,
  cityId,
}) => {
  const history = useNavigate();

  const [bilikZoneId] = useQueryParam('bilikZoneId', NumberParam);

  const onSubmit = useCallback(
    async (
      values: CityFormFields,
      actions: FormikHelpers<CityFormFields>,
    ): Promise<void> => {
      try {
        await updateCity({
          id: cityId,
          name: values?.name,
          slug: values?.slug,
          bilikZoneId: values?.bilikZoneId,
          area: addCrs(values?.area),
          geoNameId: values?.geoNameId,
          population: values?.population,
        });

        ToastSuccess('Succès', 'Ville enregistrée');
        actions.setSubmitting(false);
        history(
          `${bilikZoneId ? `/bilik_zones/update/${bilikZoneId}` : '/cities'}`,
        );
      } catch {
        ToastError('Erreur', "Impossible d'enregistrer la zone");
      }
    },
    [updateCity, history, cityId],
  );

  const initialValues: CityFormFields = useMemo(() => {
    return {
      name: formData?.name || '',
      slug: formData?.slug || '',
      bilikZoneId: formData?.bilikZoneId || undefined,
      area: formData?.area || undefined,
      geoNameId: formData?.geoNameId || undefined,
      population: formData?.population || 0,
    };
  }, [loading]);

  if (loading) {
    return (
      <Loader size="big" active inline="centered" content="Chargement..." />
    );
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={cityFormValidationSchema({
        currentSlug: formData?.slug,
      })}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <CityFormView />
    </Formik>
  );
};

export default CityFormUpdateContainer;
