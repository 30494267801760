import * as Yup from 'yup';

export const ProReviewInvitationsFormValidationSchema =
  (): Yup.ObjectSchema => {
    return Yup.object().shape({
      proReviewInvitations: Yup.array().of(
        Yup.object().shape({
          givenName: Yup.string().required('Prénom requis !'),
          familyName: Yup.string().required('Nom requis !'),
          email: Yup.string()
            .required('Email requis !')
            .email('Adresse email non valide !'),
          dateOfWork: Yup.date()
            .nullable()
            .max(new Date(), 'La date ne peut pas être dans le futur !'),
        }),
      ),
    });
  };
