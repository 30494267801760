import { useProViewProPersonDeleteConfirmModalQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import ProViewProPersonDeleteConfirmModalContainer from './pro-view-pro-person-delete-confirm-modal-container';
import { ProViewProPersonDeleteConfirmModalProps } from './pro-view-pro-person-delete-confirm-modal-view';

type ProViewProPersonDeleteConfirmModalRepositoryProps = ProViewProPersonDeleteConfirmModalProps & {
  currentProViewId?: number;
  proPersonId: number;
  proPersonEmail?: string;
  onConfirm?: (proPersonId: number) => void;
};

const ProViewProPersonDeleteConfirmModalRepository: FunctionComponent<ProViewProPersonDeleteConfirmModalRepositoryProps> = ({
  proPersonId,
  proPersonEmail,
  currentProViewId,
  onConfirm,
  onClose,
  ...rest
}) => {
  const { data, loading } = useProViewProPersonDeleteConfirmModalQuery({
    variables: {
      proPersonId,
      proPersonEmail: proPersonEmail || '', // Hacky but working
    },
  });

  return (
    <ProViewProPersonDeleteConfirmModalContainer
      data={data}
      loading={loading}
      currentProViewId={currentProViewId}
      proPersonId={proPersonId}
      onClose={onClose}
      onConfirm={onConfirm}
      {...rest}
    />
  );
};
export default ProViewProPersonDeleteConfirmModalRepository;
