import React, { FunctionComponent, useCallback } from 'react';
import { Label } from 'semantic-ui-react';
import { IpCheckerIndicatorView } from './ip-checker-indicator-view';

type IpCheckerIndicatorContainer = {
  isFirstReviewWithThisIp: boolean;
  isMultipleReviewsWithThisIpOnSamePro: boolean;
  ip?: string;
  loading: boolean;
};

export const IpCheckerIndicatorContainer: FunctionComponent<
  IpCheckerIndicatorContainer
> = ({
  ip,
  isFirstReviewWithThisIp,
  isMultipleReviewsWithThisIpOnSamePro,
  loading,
}) => {
  const openProReviews = useCallback(() => {
    window.open(
      `/pro_reviews?ip=${ip}&isAllZones=1`, // Filters
    );
  }, [ip]);

  if (loading) {
    return (
      <Label size="mini" color="grey">
        Chargement...
      </Label>
    );
  }

  return (
    <IpCheckerIndicatorView
      openProReviews={openProReviews}
      isfirstReviewWithThisIp={isFirstReviewWithThisIp}
      isMultipleReviewsWithThisIpOnSamePro={
        isMultipleReviewsWithThisIpOnSamePro
      }
    />
  );
};
