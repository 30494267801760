import React, { FunctionComponent } from 'react';

import { BilikPersonFormUpdate } from './bilik-person-form';
import { Loader } from 'semantic-ui-react';
import { Navigate, useParams } from 'react-router';
import { useBilikPersonUpdatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentBody from 'components/content-body/content-body';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentContainer from 'components/content-container/content-container';

const BilikPersonUpdatePage: FunctionComponent = () => {
  const params = useParams();
  const bilikPersonId = Number(params.bilikPersonId);
  const { data, loading } = useBilikPersonUpdatePageQuery({
    variables: {
      id: bilikPersonId,
    },
  });

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  if (!data?.bilikPersonByPk) return <Navigate to="/404" replace />; // Bilik person not found

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Utilisateur : {data?.bilikPersonByPk?.givenName}{' '}
          {data?.bilikPersonByPk?.familyName}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <BilikPersonFormUpdate />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default BilikPersonUpdatePage;
