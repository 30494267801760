import React, { FunctionComponent, useCallback } from 'react';
import { Label } from 'semantic-ui-react';
import { ProReviewInvitationCheckerIndicatorView } from './pro-review-invitation-checker-indicator-view';

type ProReviewInvitationCheckerIndicatorContainerProps = {
  loading: boolean;
  email: string;
  hasPiBeenInvitedByOtherPros: boolean;
  wasInitiatedWithProReviewInvitation: boolean;
  proViewId: number;
};

export const ProReviewInvitationCheckerIndicatorContainer: FunctionComponent<
  ProReviewInvitationCheckerIndicatorContainerProps
> = ({
  wasInitiatedWithProReviewInvitation,
  hasPiBeenInvitedByOtherPros,
  loading,
  email,
  proViewId,
}) => {
  const openPiTimeline = useCallback(() => {
    window.open(
      `/timeline/${email}?eventTypes=FormCreateProReviewInvitation_ProReviewInvitationToggleAutorespond`,
    );
  }, [email]);

  const openProReviewInvitations = useCallback(() => {
    window.open(`/pros/update/${proViewId}/review_invitations`);
  }, [email]);

  if (loading) {
    return (
      <Label size="mini" color="grey">
        Chargement...
      </Label>
    );
  }

  return (
    <ProReviewInvitationCheckerIndicatorView
      hasPiBeenInvitedByOtherPros={hasPiBeenInvitedByOtherPros}
      wasInitiatedWithProReviewInvitation={wasInitiatedWithProReviewInvitation}
      openPiTimeline={openPiTimeline}
      openProReviewInvitations={openProReviewInvitations}
    />
  );
};
