import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { toast } from 'react-toastify';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities';
import {
  ToastError,
  getToken,
  isTokenExpired,
  setRefreshToken,
  setToken,
  setTokenExp,
} from 'utils/';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { keycloak, getRefreshToken } from './utils/index';
import jwt from 'jsonwebtoken';
import env from 'env';

const cache = new InMemoryCache();

const refreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: (): boolean => isTokenExpired(),
  fetchAccessToken: (): Promise<Response> => {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', env.KEYCLOAK_CLIENT_ID);
    params.append('refresh_token', getRefreshToken() ?? '');

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return fetch(
      `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/token`,
      {
        method: 'POST',
        headers,
        credentials: 'include',
        body: params,
      },
    );
  },
  handleFetch: (token: string): void => {
    const decodedToken = jwt.decode(token) as { exp: number };

    setToken(token);
    setTokenExp(decodedToken.exp);
  },
  handleResponse:
    () =>
    async (response: Response): Promise<{ access_token: string }> => {
      const parsedResponse = await response.json();
      setRefreshToken(parsedResponse['refresh_token']);

      return {
        access_token: parsedResponse['access_token'],
      };
    },
  handleError: (err): void => {
    console.error(
      'Error',
      "Impossible de rafraichir le token d'identification, essayer de vous reconnecter pour pouvoir continuer à utiliser le site",
    );
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: ZenObservable.Subscription | null = null;
      Promise.resolve(operation)
        .then((subOperation) => {
          const accessToken = getToken();
          if (accessToken) {
            subOperation.setContext({
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }); //handle ends here
        })
        .catch(observer.error.bind(observer));

      return (): void => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const context = operation.getContext();

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message !== 'Could not verify JWT: JWTExpired') {
        ToastError(
          '[GraphQL error]:',
          `Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      }

      return console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    console.error(`[Network error]:`, networkError, context);
    if (context && context.response && context.response.status) {
      if (context.response.status === 502) {
        toast.error('Erreur 502: Bad gateway. Contactez un développeur.');
      }
    }
  }
});

const httpLink = new HttpLink({
  uri: `${env.API_URL}/backend/v1/graphql`,
});

const Client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
  link: ApolloLink.from([
    refreshLink as unknown as ApolloLink,
    errorLink,
    requestLink,
    httpLink,
  ]),
});

export default Client;
