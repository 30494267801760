import ProOrganizationFormModal from 'components/pro-organization-form-modal/pro-organization-form-modal';
import SelectProOrganizationField from 'components/select-pro-organization-field/select-pro-organization-field';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';

type ProOrganizationModalState = {
  open: boolean;
  id?: number;
};

type ProOrganizationFieldProps = {
  billingEmailSuggestions: string[];
  name: string;
  value: number | null;
  label?: string;
  helpText?: string;
  onProOrganizationUpdated?: () => void;
};

const ProOrganizationField: FunctionComponent<ProOrganizationFieldProps> = ({
  billingEmailSuggestions,
  value,
  label,
  helpText,
  name,
  onProOrganizationUpdated: onUpdate,
}) => {
  const { setFieldValue } = useFormikContext();

  const [proOrganizationModal, setProOrganizationModal] =
    useState<ProOrganizationModalState>({ open: false });

  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  return (
    <>
      <Form.Group widths="equal">
        <SelectProOrganizationField
          label={label}
          helpText={helpText}
          name={name}
          selection
          search
          clearable
          shouldRefetch={shouldRefetch}
          selectOnBlur={false}
          onRefetch={(): void => {
            setShouldRefetch(false);
          }}
        />
        {value ? (
          <Button
            icon
            primary
            style={{ minWidth: '45px', marginTop: '45px' }}
            type="button"
            onClick={(): void => {
              setProOrganizationModal({ open: true, id: value });
            }}
          >
            <Icon name="pencil alternate" />
          </Button>
        ) : null}
        <Button
          icon
          positive
          style={{ minWidth: '45px', marginTop: '45px' }}
          type="button"
          onClick={(): void => {
            setProOrganizationModal({ open: true });
          }}
        >
          <Icon name="plus" />
        </Button>
      </Form.Group>
      <ProOrganizationFormModal
        billingEmailSuggestions={billingEmailSuggestions}
        open={proOrganizationModal.open}
        proOrganizationId={proOrganizationModal.id}
        onClose={(): void => {
          setProOrganizationModal({ open: false });
        }}
        onProOrganizationCreated={(proOrganizationId): void => {
          setFieldValue(name, proOrganizationId);
          setShouldRefetch(true);
        }}
        onProOrganizationUpdated={onUpdate}
      />
    </>
  );
};

export default ProOrganizationField;
