import { useSolicitationCheckerIndicatorQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { SolicitationCheckerIndicatorContainer } from './solicitation-checker-indicator-container';

type SolicitationCheckerIndicatorRepositoryProps = {
  email: string;
  proPresentationId: number;
};

export const SolicitationCheckerIndicatorRepository: FunctionComponent<SolicitationCheckerIndicatorRepositoryProps> = ({
  email,
  proPresentationId,
}) => {
  const { data, loading } = useSolicitationCheckerIndicatorQuery({
    variables: { email },
  });

  const hasMadeSolicitationBefore = data?.solicitation.length !== 0;

  const hasActiveSolicitationToOtherPros =
    data?.solicitation.find((solicitation) => {
      return (
        solicitation.proView.proPresentation.id !== proPresentationId &&
        solicitation.isAutorespondActive
      );
    }) !== undefined;

  const hasSolicitedReviewedPro =
    data?.solicitation.find((solicitation) => {
      return solicitation.proView.proPresentation.id === proPresentationId;
    }) !== undefined;

  return (
    <SolicitationCheckerIndicatorContainer
      loading={loading}
      email={email}
      hasMadeSolicitationBefore={hasMadeSolicitationBefore}
      hasSolicitedReviewedPro={hasSolicitedReviewedPro}
      hasActiveSolicitationToOtherPros={hasActiveSolicitationToOtherPros}
    />
  );
};
