import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Container, Icon, Image, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const isMimetypeImage = (mimetype: string): boolean =>
  mimetype.indexOf('image/') !== -1;

type DocumentPreviewModalProps = {
  open: boolean;
  onClose: () => void;
  document?: {
    mimeType: string;
    url: string;
    name: string;
    options?: unknown;
  };
};

// In order to center the pdf canvas
const CenteredDocument = styled(Document)`
  div > canvas {
    margin: auto !important;
  }
`;

const DocumentPreviewModal: FunctionComponent<DocumentPreviewModalProps> = ({
  open,
  onClose,
  document,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);

  // We only know the number page once the document is loaded.
  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  const onCloseCallback = useCallback(() => {
    setPageNumber(1);
    onClose();
  }, [onClose]);

  // Memoize document props to prevent useless reloading
  const documentProps = useMemo(() => {
    if (document) {
      return {
        file: {
          url: document.url,
        },
        options: document.options,
      };
    } else {
      return {};
    }
  }, [document]);

  // Do not render the modal if there is no document
  if (!document) return null;

  if (isMimetypeImage(document.mimeType)) {
    return (
      <Modal
        open={open}
        onClose={onCloseCallback}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Document : {document.name}</Modal.Header>
        <Modal.Content>
          <Container fluid textAlign="center">
            <Image wrapped src={document.url} />
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={onCloseCallback}>
            Fermer
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return (
    <Modal
      open={open}
      onClose={onCloseCallback}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
    >
      <Modal.Header>{document.name}</Modal.Header>
      <Modal.Content>
        <CenteredDocument
          {...documentProps}
          loading={
            <div
              style={{
                height: 926,
              }}
            />
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page scale={1.1} pageNumber={pageNumber} />
        </CenteredDocument>
        <Container fluid textAlign="center">
          <p>
            {pageNumber}/{numPages}
          </p>
          {pageNumber !== 1 ? (
            <Button onClick={(): void => setPageNumber((pageNumber || 1) - 1)}>
              <Icon name="arrow left" />
              Page précédente
            </Button>
          ) : null}
          {pageNumber < (numPages || 0) ? (
            <Button onClick={(): void => setPageNumber((pageNumber || 0) + 1)}>
              Page suivante
              <Icon name="arrow right" />
            </Button>
          ) : null}
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onCloseCallback}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DocumentPreviewModal;
