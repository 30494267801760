import React, { useEffect, useMemo, useState } from 'react';
import { CallsGraph } from 'pages/dashboard/calls-graph/index';
import {
  Segment,
  Header,
  Loader,
  Divider,
  Grid,
  Container,
  Select,
  Label,
  Message,
} from 'semantic-ui-react';
import { SolicitationsGraph } from './solicitations-graph/index';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import { GlobalStats } from './global-stats/global-stats';
import { ProReviewInvitationsGraphRepository } from './pro-review-invitations-graph/pro-review-invitations-graph-repository';
import SmsReceivedGraphRepository from './sms-received-graph/sms-received-graph-repository';
import { TradeSearchFilter } from 'components/trade-search-filter';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useDashboardPageProViewQuery } from 'generated/graphql';
import { formatTelephone } from 'utils';
import { ProContactsGraphs } from './pro-contacts-graphs';

const now = new Date();

const DashboardPage = (): JSX.Element => {
  const { currentBilikZone } = useCurrentBilikZone();
  const [tradeId, setTradeId] = useQueryParam('tradeId', NumberParam);
  const [proViewIds, setProViewIds] = useState<number[] | undefined>(undefined);
  const [activeBilikTelephoneFilter, setActiveBilikTelephoneFilter] = useState<
    string | undefined
  >(undefined);
  const [proViewPublishedIds, setProViewPublishedIds] = useState<
    number[] | undefined
  >(undefined);

  const [year, setYear] = useQueryParam('year', NumberParam);
  const [compareYear, setCompareYear] = useQueryParam(
    'compareYear',
    NumberParam,
  );

  // From 2011 to now
  const yearOptions = useMemo(
    () =>
      Array.from(Array(now.getFullYear() - 2011).keys()).map((index) => ({
        key: now.getFullYear() - index,
        value: now.getFullYear() - index,
        text: `${now.getFullYear() - index}`,
      })),
    [],
  );

  // Fetch all proViewIds for the current tradeId
  // We dont filter published pro or something else because this is used for flux data (mongodb call/sms)
  const { loading: proViewLoading, data: proViews } =
    useDashboardPageProViewQuery({
      variables: {
        where: {
          bilikZoneId: currentBilikZone?.id
            ? {
                _eq: currentBilikZone.id,
              }
            : undefined,
          proPresentation: tradeId
            ? {
                _or: [
                  {
                    mainTradeId: {
                      _eq: tradeId,
                    },
                  },
                  {
                    secondaryTradeId: {
                      _eq: tradeId,
                    },
                  },
                ],
              }
            : undefined,
        },
      },
    });

  useEffect(() => {
    if (proViews?.proView.length) {
      setProViewIds(proViews.proView.map((proView) => proView.id));
      setProViewPublishedIds(
        proViews.proView
          .filter((proView) => proView.status === 'published')
          .map((proView) => proView.id),
      );
      return;
    }
    setProViewIds(undefined);
    setProViewPublishedIds(undefined);
  }, [proViews]);

  useEffect(() => setActiveBilikTelephoneFilter(undefined), [currentBilikZone]);

  if (currentBilikZone === undefined || proViewLoading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Tableau de bord -{' '}
          {currentBilikZone
            ? currentBilikZone.mainCityCode
            : 'Toutes les zones'}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <div style={{ marginBottom: '15px', marginLeft: '-12px' }}>
          <TradeSearchFilter
            tradeId={tradeId ?? undefined}
            onDelete={(): void => {
              setTradeId(undefined);
            }}
            onResultSelect={(event, { result }): void => {
              setTradeId(result.id);
            }}
          />
        </div>
        {proViewIds?.length && proViewPublishedIds?.length ? (
          <ContentContainer>
            <GlobalStats
              filters={{
                tradeId: tradeId ?? undefined,
                proViewIds: proViewIds,
                bilikZoneId: currentBilikZone?.id,
              }}
            />
            <Segment.Group>
              <Segment>
                <Container fluid textAlign="right">
                  <Select
                    value={year ?? yearOptions[0].value}
                    selection
                    options={yearOptions}
                    onChange={(event, { value }): void =>
                      setYear(value as number)
                    }
                  />
                  <Label>vs</Label>
                  <Select
                    value={compareYear ?? yearOptions[1].value}
                    selection
                    options={yearOptions}
                    onChange={(event, { value }): void =>
                      setCompareYear(value as number)
                    }
                  />
                </Container>
                <Divider hidden />
              </Segment>
              <ProContactsGraphs
                bilikZoneId={currentBilikZone?.id}
                proViewIds={proViewIds}
                tradeId={tradeId ?? undefined}
                year={year ?? undefined}
                compareYear={compareYear ?? undefined}
              />
              <Segment>
                <Grid columns={2}>
                  <Grid.Column>
                    <Header>Evolution des appels vers les pros</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Header>Evolution des demandes mail</Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              <Segment>
                <Grid columns={2}>
                  <Grid.Column>
                    <CallsGraph
                      bilikZoneId={currentBilikZone?.id}
                      proViewIds={proViewIds}
                      year={year ?? undefined}
                      compareYear={compareYear ?? undefined}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <SolicitationsGraph
                      bilikZoneId={currentBilikZone?.id}
                      tradeId={tradeId ?? undefined}
                      year={year ?? undefined}
                      compareYear={compareYear ?? undefined}
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
              <Segment>
                <Grid columns={2}>
                  <Grid.Column>
                    <Header>Evolution des demandes sms</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Header>Evolution des demandes d&apos;avis</Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              <Segment>
                <Grid columns={2}>
                  <Grid.Column>
                    <SmsReceivedGraphRepository
                      bilikZoneId={currentBilikZone?.id}
                      proViewIds={proViewIds}
                      year={year ?? undefined}
                      compareYear={compareYear ?? undefined}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <ProReviewInvitationsGraphRepository
                      bilikZoneId={currentBilikZone?.id}
                      tradeId={tradeId ?? undefined}
                      year={year ?? undefined}
                      compareYear={compareYear ?? undefined}
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
              {currentBilikZone ? (
                <>
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column>
                        <Header>
                          Evolution des appels vers Bilik{' '}
                          {currentBilikZone.slug}
                        </Header>
                        <Container style={{ marginTop: '10px' }}>
                          <Label
                            as="a"
                            color={
                              !activeBilikTelephoneFilter ? 'blue' : undefined
                            }
                            onClick={() =>
                              setActiveBilikTelephoneFilter(undefined)
                            }
                          >
                            Tous
                          </Label>
                          <Label
                            as="a"
                            color={
                              activeBilikTelephoneFilter ===
                              currentBilikZone?.telephone
                                ? 'blue'
                                : undefined
                            }
                            onClick={() =>
                              setActiveBilikTelephoneFilter(
                                currentBilikZone?.telephone,
                              )
                            }
                          >
                            {formatTelephone(currentBilikZone.telephone)}
                          </Label>
                          {currentBilikZone.telephoneSms !==
                          currentBilikZone?.telephone ? (
                            <Label
                              as="a"
                              color={
                                activeBilikTelephoneFilter ===
                                currentBilikZone?.telephoneSms
                                  ? 'blue'
                                  : undefined
                              }
                              onClick={() =>
                                setActiveBilikTelephoneFilter(
                                  currentBilikZone?.telephoneSms,
                                )
                              }
                            >
                              {formatTelephone(currentBilikZone.telephoneSms)}
                            </Label>
                          ) : null}
                        </Container>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column>
                        <CallsGraph
                          year={year ?? undefined}
                          filters={[
                            {
                              'actors.bilik.bilikZone.id': currentBilikZone.id,
                            },
                            activeBilikTelephoneFilter
                              ? {
                                  'data.twilio.CalledVia':
                                    activeBilikTelephoneFilter,
                                }
                              : {},
                          ]}
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </>
              ) : null}
            </Segment.Group>
          </ContentContainer>
        ) : (
          <Message>
            Aucun pro pour cette catégorie dans la zone sélectionnée
          </Message>
        )}
      </ContentBody>
    </Content>
  );
};

export default DashboardPage;
