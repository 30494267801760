import React, { FunctionComponent, useEffect, useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { MongoEvent } from 'interfaces/events.interface';
import SmsReceivedTableView from './sms-received-table-view';
import { ToastError } from 'utils';
import { CallsTableGloballyBlockedQuery } from 'generated/graphql';

type SmsReceivedTableProps = {
  fetchSmsReceived: (skip: number, limit: number) => Promise<MongoEvent[]>;
  itemPerPage: number;
  orderBy?: {
    column: string;
    direction: string;
  };
  filters?: {
    page?: number;
    bilikZoneId?: number;
    proViewId?: number;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
  globallyBlocked: CallsTableGloballyBlockedQuery['globallyBlockedIdentity'];
};

const CallsTableContainer: FunctionComponent<SmsReceivedTableProps> = ({
  fetchSmsReceived,
  itemPerPage,
  orderBy,
  filters,
  onSort,
  onFilter,
  globallyBlocked,
}) => {
  const [smsReceivedList, setSmsReceivedList] = useState<MongoEvent[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setLoading(true);

      const newSmsReceived = await fetchSmsReceived(
        filters?.page ? itemPerPage * (filters.page - 1) : 0,
        itemPerPage,
      ).catch((error) => {
        ToastError('Erreur', 'Impossible de charger la liste des sms');
        setError(true);
        setLoading(false);
        throw error;
      });
      setSmsReceivedList(newSmsReceived);
      setLoading(false);
    };
    fetch();
  }, [fetchSmsReceived]);

  if (error) {
    return null;
  }

  if (!smsReceivedList) {
    return (
      <Loader active inline="centered" style={{ marginTop: '14px' }}>
        Chargement...
      </Loader>
    );
  }

  if (smsReceivedList.length == 0) {
    return (
      <Message>
        <p>Aucun SMS enregistrés</p>
      </Message>
    );
  }
  return (
    <SmsReceivedTableView
      onFilter={onFilter}
      onSort={onSort}
      loading={loading}
      smsReceivedList={smsReceivedList}
      filters={{
        currentBilikZoneId: filters?.bilikZoneId,
        proViewId: filters?.proViewId,
      }}
      orderBy={orderBy}
      globallyBlocked={globallyBlocked}
    />
  );
};

export default CallsTableContainer;
