import React, { useEffect, useState } from 'react';
import { CityField } from 'components/city-field';
import { useFormikContext } from 'formik';
import { FunctionComponent } from 'react';
import { Form } from 'semantic-ui-react';

type Props = {
  submit: boolean;
  onSubmit: () => void;
  onStopLoading: () => void;
};

export const ProReviewActionModalCityView: FunctionComponent<Props> = ({
  submit,
  onSubmit,
  onStopLoading,
}) => {
  const { handleSubmit, handleReset, submitForm } = useFormikContext();

  const [isCitySelected, setIsCitySelected] = useState(false);

  useEffect(() => {
    if (submit) {
      onSubmit();
      submitForm();
    }
    if (!isCitySelected) {
      onStopLoading();
    }
  }, [submit]);

  return (
    <>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <CityField
          cityFieldName="addressLocality"
          geometryFieldName="geoCoordinates"
          drawMode="Point"
          onPlaceSelected={() => {
            setIsCitySelected(true);
          }}
        />
      </Form>
    </>
  );
};
