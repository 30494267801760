import { CurrentBilikPersonQuery } from 'generated/graphql';
import { FunctionComponent, useState } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import React from 'react';

type ClaimModalProps = {
  member: Partial<CurrentBilikPersonQuery['bilikPerson'][0]>;
  onAccept: () => void;
  onRefuse: (url: string) => void;
};

export const ClaimModal: FunctionComponent<ClaimModalProps> = ({
  member,
  onAccept,
  onRefuse,
}) => {
  const [open, setOpen] = useState<boolean>(true);

  const close = (): void => {
    setOpen(false);
  };

  const refuse = (): void => {
    onRefuse('/pros');
    close();
  };

  const accept = (): void => {
    onAccept();
    close();
  };

  return (
    <Modal
      size="small"
      open={open}
      onClose={(): void => {
        setOpen(false);
      }}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>Prendre la main ?</Modal.Header>
      <Modal.Content>
        <p>
          {member.givenName} {member.familyName} est en train de modifier cette
          page.
          <br />
          Voulez vous prendre la main ? {member.givenName} perdra toutes ses
          modifications en cours.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={refuse}>
          <Icon name="remove" />
          Non
        </Button>
        <Button positive onClick={accept}>
          <Icon name="checkmark" />
          Oui
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
