import { Formik } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import DocumentSendFormModalValidationSchema from './document-send-form-modal-validation-schema';
import DocumentSendFormModalView from './document-send-form-modal-view';

export interface DocumentSendFormAdditionalFields {
  givenName: boolean;
  familyName: boolean;
}

export type DocumentSendFormModalFields = {
  telephone: string;
  proReceiptEmail: string;
  givenName?: string;
  familyName?: string;
};

type DocumentSendFormModalContainerProps = {
  open: boolean;
  onSubmitDocument?: (
    values: DocumentSendFormModalFields,
  ) => Promise<{ data: { success: boolean; message: string } }>;
  onClose: () => void;
  receiptEmailSuggestions?: string[];
  proPersonSuggestions?: {
    givenName: string;
    familyName: string;
    telephone: string;
  }[];
  additionalFields?: DocumentSendFormAdditionalFields;
};

const DocumentSendFormModalContainer: FunctionComponent<
  DocumentSendFormModalContainerProps
> = ({
  onSubmitDocument,
  receiptEmailSuggestions,
  proPersonSuggestions,
  onClose,
  additionalFields,
  ...rest
}) => {
  const onSubmit = useCallback(
    async (values: DocumentSendFormModalFields) => {
      if (onSubmitDocument) {
        const response = await onSubmitDocument(values);

        if (response.data?.success === true) {
          ToastSuccess(
            'Succès',
            'Le document a bien été envoyé pour signature',
          );
        } else {
          ToastError('Erreur', response.data?.message);
        }
      }
      onClose();
    },
    [onSubmitDocument, onClose],
  );

  const initialValues = useMemo(() => {
    const givenName = additionalFields?.givenName
      ? {
          givenName: proPersonSuggestions?.[0]?.givenName || '',
        }
      : {};

    const familyName = additionalFields?.familyName
      ? {
          familyName: proPersonSuggestions?.[0]?.familyName || '',
        }
      : {};

    return {
      ...givenName,
      ...familyName,
      proReceiptEmail: receiptEmailSuggestions?.[0] || '',
      telephone: proPersonSuggestions?.[0]?.telephone || '',
    };
  }, [receiptEmailSuggestions, proPersonSuggestions]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={DocumentSendFormModalValidationSchema(additionalFields)}
      validateOnChange={true}
    >
      <DocumentSendFormModalView
        onClose={onClose}
        additionalFields={additionalFields}
        receiptEmailSuggestions={receiptEmailSuggestions}
        proPersonSuggestions={proPersonSuggestions}
        {...rest}
      />
    </Formik>
  );
};

export default DocumentSendFormModalContainer;
