import { useEffect } from 'react';
import { Subject } from 'rxjs';

export const refetchReviewCounterSubject = new Subject<void>();
export const refetchReviewCounter = (): void =>
  refetchReviewCounterSubject.next();

type useRefetchReviewActionParam = Array<(unknown?) => void>;
export const useRefetchReviewAction = (
  refetches: useRefetchReviewActionParam,
): void => {
  // Observe the refetch counter action
  useEffect(() => {
    const subscription = refetchReviewCounterSubject.subscribe(
      // Each time the action is triggered, the function body react to it
      () => refetches.map((refetch) => refetch()),
    );

    // Unsubscribe to the subject so we don't have a leaked subscription
    return (): void => subscription.unsubscribe();
  }, [...refetches]);
};
