import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { BilikZoneFormFields } from './bilik-zone-form-fields.type';
import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import SelectBilikPersonField from 'components/select-bilik-person-field/select-bilik-person-field';
import SelectZohoOrganizationField from 'components/select-zoho-organization-field/select-zoho-organization-field';
import Map from 'components/map/map';
import HelpText from 'components/help-text/help-text';
import {
  convertLayersToPolygon,
  excludeColor,
  includeColor,
} from 'utils/geometry';
import { GeoJSON } from 'react-leaflet';
import { Polygon, MultiPolygon } from 'geojson';
import Label from 'components/label/label';
import { useBilikZoneFormGetOtherAreaQueryLazyQuery } from 'generated/graphql';
import MapDraw from 'components/map/map-draw/map-draw';
import { FeatureGroup as LeafletFeatureGroup } from 'leaflet';
import SelectCityField from 'components/select-city-field/select-city-field';

type ModalState = {
  open: boolean;
  polygon?: Polygon;
};

const BilikZoneFormView: FunctionComponent = () => {
  const mapDrawRef = useRef<LeafletFeatureGroup>(null);
  const [getbilikZoneOtherAreas, { data: bilikZoneOtherAreas }] =
    useBilikZoneFormGetOtherAreaQueryLazyQuery();

  const { values, setFieldValue, handleReset, handleSubmit, isSubmitting } =
    useFormikContext<BilikZoneFormFields>();

  const [modalState, setModalState] = useReducer(
    (state: ModalState, newState: Partial<ModalState>) => ({
      ...state,
      ...newState,
    }),
    {
      open: false,
    },
  );

  const saveAreaModal = useCallback(() => {
    if (mapDrawRef.current) {
      const layers = mapDrawRef.current.getLayers();

      if (layers.length === 0) {
        setFieldValue('area', undefined);
        return;
      }

      const area = convertLayersToPolygon(layers);

      setFieldValue('area', area);
    }
  }, [mapDrawRef]);

  useEffect(() => {
    if (modalState.open) {
      getbilikZoneOtherAreas({
        variables: {
          currentBilikZoneId: values.id ?? 0,
        },
      });
    }
  }, [modalState.open]);

  console.log('bilikZoneOtherAreas', values.area);

  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <Segment.Group>
        <Segment color="blue">
          <Header>Général</Header>
        </Segment>
        <Segment>
          <FormField
            type="text"
            placeholder="Ex: Grenoble et Agglo"
            label="Nom"
            name="name"
            required
          />
          <FormField
            type="text"
            placeholder="Ex: grenoble"
            label="Slug"
            helpText="Le slug est la version normalisée du nom. Il ne contient généralement que des lettres minuscules non accentuées, des chiffres et des traits d’union."
            name="slug"
            required
          />
          <SelectBilikPersonField
            name="managers"
            label="Responsable(s) de la zone"
            required
            multiple
            search
            selection
            filters={{
              exceptIds: values.members.length > 0 ? values.members : undefined,
            }}
          />
          <SelectBilikPersonField
            name="members"
            label="Membres"
            multiple
            search
            selection
            clearable
            filters={{
              exceptIds:
                values.managers.length > 0 ? values.managers : undefined,
            }}
          />
          <FormField
            type="email"
            placeholder="Ex: grenoble@bilik.fr"
            label="Email générique"
            name="genericEmail"
            required
          />
          <FormField
            type="textarea"
            placeholder="..."
            label="L'équipe"
            helpText="Message affiché dans le footer de la zone"
            name="teamMessage"
            required
            rows={8}
          />
          <Label required htmlFor="area">
            Surface de la zone
          </Label>
          <HelpText>
            La surface de la zone permet de savoir si une ville non référencée
            se situe à proximité de la zone
          </HelpText>
          <Button
            type="button"
            icon
            onClick={(): void => {
              setModalState({ open: true });
            }}
          >
            <Icon name="map marker alternate" /> Dessiner la surface de la zone
          </Button>
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Call tracking</Header>
        </Segment>
        <Segment>
          <FormField
            label="Numéro call tracking SMS"
            type="telephone"
            name="telephoneSms"
            placeholder="Ex: 0651727985"
            required
          />
          <FormField
            label="Numéro call traking fixe"
            type="telephone"
            name="telephone"
            placeholder="Ex: 0451245874"
            required
          />
          <Container fluid>
            <Form.Group widths="equal">
              <FormField
                label="Numéro de téléphone cible"
                type="telephone"
                name="callTrackingTarget.telephone"
                placeholder="Ex: 06 44 64 27 74"
                required
              />
              <FormField
                type="text"
                placeholder="Ex: Nom"
                label="Libéllé"
                name="callTrackingTarget.name"
                required
              />
            </Form.Group>
          </Container>
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Ville</Header>
        </Segment>
        <Segment>
          <SelectCityField
            label="Ville principale"
            helpText="Sélectionnez la ville principale parmis les villes référencées attachées à la BilikZone (Si elle n'existe pas, créez la ville après avoir créé la BilikZone et attachez-la ensuite)"
            name="mainCityId"
            search
            selection
            bilikZoneId={values.id ?? 0}
          />
          <FormField
            type="text"
            placeholder="Ex: GRE"
            label="Code ville principale"
            helpText="3 caractères, en lettres majuscules"
            name="mainCityCode"
            required
          />
          <FormField
            type="text"
            placeholder="Ex: Grenoble & environs"
            label="Nom de la bilikZone dans le guide"
            name="guideName"
            required
          />
          <FormField
            type="text"
            placeholder="Ex: 38"
            label="Code département"
            name="regionPostalCode"
            required
          />
          <FormField
            type="checkbox"
            label="Possède des arrondissements ?"
            name="hasDistrict"
          />
          <FormField
            type="checkbox"
            label="Possède plusieurs codes postaux ?"
            name="hasMultiplePostalCode"
          />
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Adresse</Header>
        </Segment>
        <Segment>
          <FormField
            type="text"
            placeholder="Ex: 15 rue Pierre Dupont"
            label="Adresse"
            name="streetAddress"
            required
          />
          <FormField
            type="text"
            placeholder="Ex: 38000"
            label="Code postal"
            name="postalCode"
            required
          />
          <FormField
            type="text"
            placeholder="Ex: Grenoble"
            label="Ville"
            name="addressLocality"
            required
          />
          <FormField
            type="text"
            placeholder="Ex: BP 12345"
            label="Boite postale"
            name="postOfficeBoxNumber"
          />
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Zoho</Header>
        </Segment>
        <Segment>
          <SelectZohoOrganizationField
            label="Zoho Organization"
            helpText="Il faut regarder à quel entreprise la zone est rattachée dans Zoho"
            name="zohoOrganizationId"
            selection
            search
            clearable
          />
        </Segment>
      </Segment.Group>
      <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
        <Button
          primary
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Container>
      <Modal
        open={modalState.open}
        size="large"
        onClose={(): void => {
          setModalState({ open: false });
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Positionnez le marqueur sur la carte</Modal.Header>
        <Modal.Content>
          <Map
            style={{ width: '100%', height: '650px' }}
            center={[46, 3]}
            zoom={10}
            doubleClickZoom={false}
          >
            {bilikZoneOtherAreas?.bilikZone.map((bilikZone, index) => (
              <GeoJSON
                pmIgnore={true}
                key={`area-${index}`}
                data={bilikZone.area}
                style={{
                  color: excludeColor,
                }}
              />
            ))}
            <MapDraw
              ref={mapDrawRef}
              options={{
                position: 'bottomright',
                drawPolygon: true,
              }}
            >
              <GeoJSON
                data={values.area as Polygon | MultiPolygon}
                style={{
                  color: includeColor,
                }}
              />
            </MapDraw>
          </Map>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              setModalState({ open: false });
            }}
          >
            Annuler
          </Button>
          <Button
            positive
            onClick={(): void => {
              saveAreaModal();
              setModalState({
                open: false,
              });
            }}
          >
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    </Form>
  );
};

export default BilikZoneFormView;
