import { Serie } from '@nivo/line';
import { apiClient } from 'axios-client';
import {
  eachMonthOfInterval,
  endOfYear,
  startOfYear,
  subYears,
} from 'date-fns';
import { useProViewPublishedCountByMonth } from 'hooks/use-pro-view-published-count-by-month/use-pro-view-published-count-by-month';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { CountByMonth } from '../utils/count-by-month.type';
import ProContactsGraphsContainer from './pro-contacts-graph-container';

type ProContactsGraphsRepositoryProps = {
  bilikZoneId?: number;
  proViewIds?: number[];
  tradeId?: number;
  year?: number;
  compareYear?: number;
};

const now = new Date();

const ProContactsGraphsRepository: FunctionComponent<
  ProContactsGraphsRepositoryProps
> = ({
  bilikZoneId,
  proViewIds,
  tradeId,
  year = now.getFullYear(),
  compareYear = subYears(now, 1).getFullYear(),
}) => {
  const months = useMemo(() => {
    const date = new Date(now);
    date.setFullYear(year);

    const compareDate = new Date(now);
    compareDate.setFullYear(compareYear);

    const dateMonths = eachMonthOfInterval({
      start: startOfYear(date),
      end: endOfYear(date),
    });

    const compareDateMonths = eachMonthOfInterval({
      start: startOfYear(compareDate),
      end: endOfYear(compareDate),
    });

    return [...dateMonths, ...compareDateMonths];
  }, [year, compareYear]);

  const { data: countByMonthProViews } = useProViewPublishedCountByMonth({
    months,
    where: {
      bilikZoneId: bilikZoneId
        ? {
            _eq: bilikZoneId,
          }
        : undefined,
      proPresentation: tradeId
        ? {
            _or: [
              {
                mainTradeId: { _eq: tradeId },
              },
              { secondaryTradeId: { _eq: tradeId } },
            ],
          }
        : undefined,
    },
  });

  const getYearData = useCallback(
    async (year: number, proViewIds?: number[], bilikZoneId?: number) => {
      const date = new Date(now);
      date.setFullYear(year);

      const result = await apiClient.post('event/stats', {
        after: startOfYear(date),
        before: endOfYear(date),
        bilikZoneId,
        proViewIds,
        groupByMonth: true,
      });

      const countByMonth: CountByMonth[] = result.data.map((item) => ({
        month: item._id.month,
        count: item.newContacts,
      }));

      return countByMonth;
    },
    [],
  );

  return (
    <ProContactsGraphsContainer
      getYearData={getYearData}
      proViewCountData={countByMonthProViews}
      proViewIds={proViewIds}
      year={year}
      compareYear={compareYear}
    />
  );
};

export default ProContactsGraphsRepository;
