import { LastCheckedProReview } from 'components/last-checked-pro-review';
import { useFormikContext } from 'formik';
import { useProInfosQueryLazyQuery } from 'generated/graphql';
import { range } from 'remeda';
import React, { FunctionComponent, useEffect } from 'react';
import {
  List,
  Label as LabelUi,
  Divider,
  Popup,
  Loader,
} from 'semantic-ui-react';
import { formatDate, formatTelephone } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import { Link } from 'react-router-dom';
import { round } from 'utils/round';
import { Rating } from 'components/rating';

type ProInfosListProps = {
  proViewId?: number;
};

const ProInfosList: FunctionComponent<ProInfosListProps> = ({ proViewId }) => {
  const { values } = useFormikContext<ProFormUpdateFields>();
  const [proInfosQuery, proInfosQueryResult] = useProInfosQueryLazyQuery();

  useEffect(() => {
    if (proViewId) {
      proInfosQuery({ variables: { proViewId } });
    }
  }, [proViewId]);

  const proView = proInfosQueryResult.data?.proViewByPk;
  const proPresentation = proView?.proPresentation;

  return (
    <List verticalAlign="middle">
      <List.Item>
        <List.Content
          floated="right"
          style={{ textAlign: 'right', marginBottom: '15px' }}
        >
          {proPresentation?.proReviewsAggregate.aggregate?.avg?.rating ? (
            <>
              <Rating
                value={round(
                  proPresentation?.proReviewsAggregate.aggregate?.avg?.rating,
                  10,
                )}
              />

              <p>
                {round(
                  proPresentation.proReviewsAggregate.aggregate?.avg?.rating,
                  10,
                )}{' '}
                sur {proPresentation.okProReviews.aggregate?.count} avis
              </p>
            </>
          ) : (
            "Pas d'avis validés"
          )}
        </List.Content>
        <List.Content>Notes</List.Content>
      </List.Item>
      <List.Item>
        <List.Content floated="right">
          <LabelUi color="grey">
            {values.telephones && values.telephones.length > 0
              ? formatTelephone(values.telephones[0])
              : 'Aucun'}
          </LabelUi>
        </List.Content>
        <List.Content>Numéro(s) call tracking</List.Content>
      </List.Item>
      {values.telephones && values.telephones.length > 1
        ? range(1, values.telephones.length).map((index) => (
            <List.Item key={`telephone-list-${index}`}>
              <List.Content floated="right">
                <LabelUi color="grey">
                  {formatTelephone(values.telephones[index])}
                </LabelUi>
              </List.Content>
            </List.Item>
          ))
        : null}
      <Divider hidden />
      {proViewId ? (
        <>
          <List.Item>
            <List.Content floated="right">
              {proView ? (
                <LabelUi color="grey">
                  {formatDate(proView.dateCreated)}
                </LabelUi>
              ) : (
                <Loader active inline size="tiny" />
              )}
            </List.Content>
            <List.Content>Créé le</List.Content>
          </List.Item>

          {proView?.datePublished && (
            <List.Item>
              <List.Content floated="right">
                <LabelUi color="grey">
                  {formatDate(proView?.datePublished)}
                </LabelUi>
              </List.Content>
              <List.Content>Publié le</List.Content>
            </List.Item>
          )}
          {proPresentation?.proOrganization?.firstDatePublished?.date && (
            <List.Item>
              <List.Content floated="right">
                <LabelUi color="grey">
                  {formatDate(
                    proPresentation.proOrganization?.firstDatePublished?.date,
                  )}
                </LabelUi>
              </List.Content>
              <List.Content>Recommandé depuis</List.Content>
            </List.Item>
          )}
          {proView?.dateArchived ? (
            <List.Item>
              <List.Content floated="right">
                {proView ? (
                  <LabelUi color="grey">
                    {formatDate(new Date(proView.dateArchived).toISOString())}
                  </LabelUi>
                ) : (
                  <Loader active inline size="tiny" />
                )}
              </List.Content>
              <List.Content>Archivé le</List.Content>
            </List.Item>
          ) : null}
          <List.Item>
            <List.Content floated="right">
              {proPresentation ? (
                <>
                  <Popup
                    trigger={
                      <LabelUi
                        as={Link}
                        to={`/pro_reviews?proPresentationId=${proPresentation.id}&status=ok`}
                        color="green"
                      >
                        {proPresentation?.okProReviews.aggregate?.count}
                      </LabelUi>
                    }
                    content="Publiés"
                    position="top center"
                  />
                  <Popup
                    trigger={
                      <LabelUi
                        as={Link}
                        color="red"
                        to={`/pro_reviews?proPresentationId=${proInfosQueryResult.data?.proViewByPk?.proPresentation.id}&status=draft`}
                      >
                        {proPresentation?.draftProReviews.aggregate?.count}
                      </LabelUi>
                    }
                    position="top center"
                    content="En attente"
                  />
                </>
              ) : (
                <Loader active inline size="tiny" />
              )}
            </List.Content>
            <List.Content>Avis</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {proPresentation ? (
                <LabelUi color="grey">
                  <LastCheckedProReview
                    proPresentationId={proPresentation?.id}
                  />
                </LabelUi>
              ) : (
                <Loader active inline size="tiny" />
              )}
            </List.Content>
            <List.Content>Dernier avis vérifié</List.Content>
          </List.Item>
        </>
      ) : null}
    </List>
  );
};

export default ProInfosList;
