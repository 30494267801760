import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';

type TimelineSmsDetailsProps = {
  sms: MongoEvent;
};

const TimelineSmsDetails: FunctionComponent<TimelineSmsDetailsProps> = ({
  sms,
}) => (
  <>
    {sms.data.vonage ? (
      <p>
        <b>De : </b> {sms.data.vonage.from} <br />
        <b>À : </b> {sms.data.vonage.to}
        <br />
        <b>ID Sms : </b> {sms.data.messageId}
      </p>
    ) : (
      <p>
        <b>De : </b> {sms.data.twilio.From} <br />
        <b>À : </b> {sms.data.twilio.To}
        <br />
        <b>ID Sms : </b> {sms.data.messageId}
      </p>
    )}
  </>
);

export default TimelineSmsDetails;
