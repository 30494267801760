import { BilikZoneFormUpdateQuery, BilikZoneSetInput } from 'generated/graphql';
import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';

import { BilikZoneFormFields } from './bilik-zone-form-fields.type';
import BilikZoneFormView from './bilik-zone-form-view';
import { Loader } from 'semantic-ui-react';
import { MemberOrManager } from './bilik-zone-form-update-repository';
import { bilikZoneFormValidationSchema } from './bilik-zone-form-validation-schema';
import { useNavigate } from 'react-router';
import { addCrs } from 'utils/geometry';
import { formatE164Telephone } from 'utils';

type BilikZoneFormUpdateProps = {
  updateBilikZone: (
    bilikZone: BilikZoneSetInput,
    removeManagerIds: number[],
    addManagers: MemberOrManager[],
    removeMemberIds: number[],
    addMembers: MemberOrManager[],
  ) => Promise<void>;
  formData?: BilikZoneFormUpdateQuery['bilikZoneByPk'];
  bilikZoneId: number;
  loading: boolean;
};

const BilikZoneFormUpdateContainer: FunctionComponent<
  BilikZoneFormUpdateProps
> = ({ loading, formData, updateBilikZone, bilikZoneId }) => {
  const history = useNavigate();

  const startManagerIds =
    formData?.managers.map((manager) => manager.bilikPerson.id) || [];

  const startMemberIds =
    formData?.members.map((member) => member.bilikPerson.id) || [];

  const onSubmit = useCallback(
    async (
      values: BilikZoneFormFields,
      actions: FormikHelpers<BilikZoneFormFields>,
    ): Promise<void> => {
      try {
        // Diff initial managers and new values to find which managers need removing.
        const removeManagerIds: number[] = startManagerIds?.filter((item) => {
          return values?.managers.indexOf(item) < 0;
        });

        // Diff initial managers with new values to find which managers need to be added.
        const addManagers: MemberOrManager[] = values?.managers
          ?.filter((item) => startManagerIds.indexOf(item) < 0)
          .map((managerId) => {
            return { bilikPersonId: managerId, bilikZoneId };
          });

        // Diff initial members and new values to find which members need removing.
        const removeMemberIds: number[] = startMemberIds?.filter((item) => {
          return values?.members.indexOf(item) < 0;
        });

        // Diff initial members with new values to find which members need to be added.
        const addMembers: MemberOrManager[] = values?.members
          ?.filter((item) => startMemberIds.indexOf(item) < 0)
          .map((memberId) => {
            return { bilikPersonId: memberId, bilikZoneId };
          });

        await updateBilikZone(
          {
            id: bilikZoneId,
            name: values?.name,
            guideName: values?.guideName,
            mainCityCode: values?.mainCityCode,
            teamMessage: values?.teamMessage,
            slug: values?.slug,
            telephoneSms: formatE164Telephone(values?.telephoneSms),
            genericEmail: values?.genericEmail,
            telephone: formatE164Telephone(values?.telephone),
            addressLocality: values?.addressLocality,
            postOfficeBoxNumber: values?.postOfficeBoxNumber,
            streetAddress: values?.streetAddress,
            postalCode: values?.postalCode,
            regionPostalCode: values?.regionPostalCode,
            status: values?.status,
            zohoOrganizationId: values?.zohoOrganizationId,
            callTrackingTarget: {
              ...values?.callTrackingTarget,
              telephone: values?.callTrackingTarget.telephone
                ? formatE164Telephone(values?.callTrackingTarget.telephone)
                : undefined,
            },
            area: addCrs(values?.area),
            hasMultiplePostalCode: values?.hasMultiplePostalCode,
            hasDistrict: values?.hasDistrict,
            mainCityId: values?.mainCityId,
          },
          removeManagerIds,
          addManagers,
          removeMemberIds,
          addMembers,
        );

        ToastSuccess('Succès', 'Zone enregistré');
        actions.setSubmitting(false);
        history('/bilik_zones');
      } catch (err) {
        ToastError('Erreur', "Impossible d'enregistrer la zone");
      }
    },
    [updateBilikZone, history, bilikZoneId, startMemberIds],
  );

  const initialValues: BilikZoneFormFields = useMemo(() => {
    return {
      id: formData?.id || null,
      name: formData?.name || '',
      guideName: formData?.guideName || '',
      mainCityCode: formData?.mainCityCode || '',
      teamMessage: formData?.teamMessage || '',
      slug: formData?.slug || '',
      telephoneSms: formData?.telephoneSms || '',
      genericEmail: formData?.genericEmail || '',
      telephone: formData?.telephone || '',
      addressLocality: formData?.addressLocality || '',
      postOfficeBoxNumber: formData?.postOfficeBoxNumber || '',
      streetAddress: formData?.streetAddress || '',
      postalCode: formData?.postalCode || '',
      regionPostalCode: formData?.regionPostalCode || '',
      status: formData?.status || 'published',
      zohoOrganizationId: formData?.zohoOrganization?.id,
      managers:
        formData?.managers.map((manager) => manager.bilikPerson.id) || [], // ManagerIds
      members: formData?.members.map((member) => member.bilikPerson.id) || [], // MemberIds
      callTrackingTarget: formData?.callTrackingTarget || {
        name: '',
        telephone: '',
      },
      area: formData?.area || undefined,
      hasMultiplePostalCode: formData?.hasMultiplePostalCode || false,
      hasDistrict: formData?.hasDistrict || false,
      mainCityId: formData?.mainCityId || 0,
    };
  }, [loading]);

  if (loading) {
    return (
      <Loader size="big" active inline="centered" content="Chargement..." />
    );
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={bilikZoneFormValidationSchema({
        currentSlug: formData?.slug,
      })}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <BilikZoneFormView />
    </Formik>
  );
};

export default BilikZoneFormUpdateContainer;
