import { Dimmer, Loader, Message, Table, Label } from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { map, pipe, uniq } from 'remeda';
import { ProPeopleTableQuery } from 'generated/graphql';
import ProFullName from 'components/pro-full-name/pro-full-name';
import { formatTelephone } from 'utils';

type ProPeopleTableProps = {
  loading: boolean;
  proPeople?: ProPeopleTableQuery['proPerson'];
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
};
export const ProPeopleTableView: FunctionComponent<ProPeopleTableProps> = ({
  loading,
  proPeople,
  orderBy,
  onSort,
}) => {
  const history = useNavigate();

  const groups = useMemo(() => {
    const data: string[][] = [];
    if (proPeople) {
      proPeople.forEach((proPerson) => {
        data[proPerson.id] = pipe(
          proPerson.proViews,
          map((proView) => proView.proView.bilikZone.mainCityCode),
          uniq(),
        );
      });
    }
    return data;
  }, [proPeople]);

  const direction = useMemo(() => {
    switch (orderBy?.direction) {
      case 'ascending':
      case 'descending':
        return orderBy.direction;
      default:
        return 'ascending';
    }
  }, [orderBy?.direction]);

  if (!proPeople || proPeople?.length === 0) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }
  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer inverted verticalAlign="top" active={loading}>
          <Loader style={{ marginTop: '20px' }} inline>
            Chargement...
          </Loader>
        </Dimmer>
        <Table striped celled sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="not sortable">
                Prénom
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  orderBy?.column === 'familyName' ? direction : undefined
                }
                onClick={(): void => {
                  if (onSort) {
                    onSort(
                      'familyName',
                      direction === 'ascending' ? 'descending' : 'ascending',
                    );
                  }
                }}
              >
                Nom
              </Table.HeaderCell>
              <Table.HeaderCell className="not sortable">
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className="not sortable">
                Téléphone
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" className="not sortable">
                Groupes
              </Table.HeaderCell>
              <Table.HeaderCell className="not sortable">
                Fiches
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {proPeople.map((proPerson) => (
              <Table.Row key={proPerson.id}>
                <Table.Cell>{proPerson.givenName}</Table.Cell>
                <Table.Cell>{proPerson.familyName}</Table.Cell>
                <Table.Cell>{proPerson.email}</Table.Cell>
                <Table.Cell>
                  {proPerson.telephone
                    ? formatTelephone(proPerson.telephone)
                    : null}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {groups[proPerson.id].map((group) => {
                    return (
                      <Label
                        key={`pro-person-${proPerson.id}-${group}`}
                        color="grey"
                      >
                        {group}
                      </Label>
                    );
                  })}
                </Table.Cell>
                <Table.Cell>
                  {proPerson.proViews.map(({ proView }) => {
                    return (
                      <Fragment
                        key={`pro-person-${proPerson.id}-pro-view-${proView.id}`}
                      >
                        <a
                          onClick={(): void => {
                            history(`/pros/update/${proView.id}`);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <ProFullName
                            name={proView.proPresentation?.name}
                            mainCityCode={proView?.bilikZone?.mainCityCode}
                            mainTradeName={
                              proView.proPresentation.mainTrade?.name
                            }
                          />
                        </a>
                        <br />
                      </Fragment>
                    );
                  })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>
    </>
  );
};
