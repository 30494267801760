import React, { FunctionComponent } from 'react';
import {
  AnimateLayoutChanges,
  NewIndexGetter,
  useSortable,
} from '@dnd-kit/sortable';
import { ImageItem, ImageItemProps } from './image-item/image-item';

import { Active } from '@dnd-kit/core';
export const SortableImageItem: FunctionComponent<
  ImageItemProps & {
    animateLayoutChanges?: AnimateLayoutChanges;
    getNewIndex?: NewIndexGetter;
    index: number;
    useDragOverlay?: boolean;
    style(values: any): React.CSSProperties;
    wrapperStyle?(args: {
      active: Pick<Active, 'id'> | null;
      index: number;
      isDragging: boolean;
      hash: string;
    }): React.CSSProperties;
  }
> = ({
  animateLayoutChanges,
  getNewIndex,
  index,
  useDragOverlay,
  wrapperStyle,
  style,
  hash,
  openImageModal,
  onDelete,
}) => {
  const {
    active,
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: hash,
    animateLayoutChanges,
    getNewIndex,
  });

  return (
    <ImageItem
      ref={setNodeRef}
      hash={hash}
      onDelete={onDelete}
      openImageModal={openImageModal}
      dragging={isDragging}
      sorting={isSorting}
      listeners={listeners}
      style={style({
        index,
        isDragging,
        isSorting,
        overIndex,
      })}
      transform={transform}
      transition={transition}
      wrapperStyle={wrapperStyle?.({ index, isDragging, active, hash })}
      data-index={index}
      data-id={hash}
      dragOverlay={!useDragOverlay && isDragging}
      {...attributes}
    />
  );
};
