import { AccountProViewDeleteConfirmModalQuery } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import AccountProViewDeleteConfirmModalView, {
  AccountProViewDeleteConfirmModalProps,
} from './account-pro-view-delete-confirm-modal-view';

type AccountProViewDeleteConfirmModalContainerProps = AccountProViewDeleteConfirmModalProps & {
  currentProViewId?: number;
  data?: AccountProViewDeleteConfirmModalQuery;
  loading: boolean;
  onConfirm?: (accountId: number) => void;
  accountId: number;
};

const AccountProViewDeleteConfirmModalContainer: FunctionComponent<AccountProViewDeleteConfirmModalContainerProps> = ({
  data,
  currentProViewId,
  loading,
  onClose,
  onConfirm,
  accountId,
  ...rest
}) => {
  const confirmDelete = useCallback(() => {
    if (onConfirm) {
      onConfirm(accountId);
    }
    if (onClose) {
      onClose();
    }
  }, [onClose, onConfirm]);

  const proViews = useMemo(
    () => data?.proView.filter((proView) => proView.id !== currentProViewId),
    [data?.proView],
  );

  const account = useMemo(() => data?.accountByPk, [data?.accountByPk]);

  // If no others proViews linked then auto confirm
  useEffect(() => {
    if (proViews && proViews.length === 0) {
      if (onConfirm) {
        onConfirm(accountId);
      }
      if (onClose) {
        onClose();
      }
    }
  }, [proViews]);

  if (!account && !loading) {
    return <>Erreur compte introuvable</>;
  }

  // Loading state
  if (!account || !proViews) {
    return null;
  }

  return (
    <AccountProViewDeleteConfirmModalView
      proViews={proViews}
      account={account}
      confirmDelete={confirmDelete}
      onClose={onClose}
      {...rest}
    />
  );
};

export default AccountProViewDeleteConfirmModalContainer;
