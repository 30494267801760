import { Formik } from 'formik';
import { AccountInsertInput } from 'generated/graphql';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { AccountFormFields } from './account-form-field.type';
import { AccountFormModalProps } from './account-form-modal';
import accountFormValidationSchema from './account-form-validation-schema';
import AccountFormView from './account-form-view';

type AccountFormCreateContainerProps = AccountFormModalProps & {
  createAccount: (account: AccountInsertInput) => Promise<void>;
};

const AccountFormCreateContainer: FunctionComponent<AccountFormCreateContainerProps> = ({
  createAccount,
  onClose,
  ...rest
}) => {
  const onSubmit = useCallback(async (values: AccountFormFields) => {
    await createAccount({
      email: values.email,
    }).catch((error) => {
      ToastError('Erreur', 'Impossible de créer le compte');
      throw error;
    });

    if (onClose) {
      onClose();
    }

    ToastSuccess('Succès', "Accès à l'espace pro créé");
  }, []);

  const initialValues: AccountFormFields = useMemo(
    () => ({
      email: '',
    }),
    [],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={accountFormValidationSchema}
      validateOnChange={false}
    >
      <AccountFormView onClose={onClose} {...rest} />
    </Formik>
  );
};

export default AccountFormCreateContainer;
