import React, { useCallback, useMemo } from 'react';
import { FunctionComponent } from 'react';
import NoteContainer from './note-container';
import {
  NoteIdentitySetInput,
  useNoteIdentityDeleteMutation,
  useNoteIdentityInsertMutation,
  useNotesIdentityByIdentifiersQueryLazyQuery,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';

type Props = {
  emails?: string[];
  telephones?: string[];
};

const NoteRepository: FunctionComponent<Props> = ({ emails, telephones }) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  //transform emails and telephones to identifier "email:exemple@gmail.com" or "telephone:+3301010101"
  const identifiers = useMemo(() => {
    return emails
      ?.map((email) => `email:${email}`)
      .concat(
        telephones
          ? telephones?.map((telephone) => `telephone:${telephone}`)
          : [],
      );
  }, [emails, telephones]);

  const [getNotesIdentiyQuery, { data }] =
    useNotesIdentityByIdentifiersQueryLazyQuery({
      variables: {
        identifiers: identifiers,
      },
    });

  const getNotesIdentity = useCallback(async () => {
    await getNotesIdentiyQuery();
  }, [getNotesIdentiyQuery]);

  const [insertNoteIdentityOne] = useNoteIdentityInsertMutation();
  const [deleteNoteIdentityDeleteMutation] = useNoteIdentityDeleteMutation();

  const insertNoteIdentity = useCallback(
    async (noteIdentity: NoteIdentitySetInput) => {
      return await insertNoteIdentityOne({
        variables: {
          noteIdentityInput: noteIdentity,
        },
      });
    },
    [],
  );

  const deleteNoteIdentity = useCallback(async (noteIdentityId: number) => {
    if (!noteIdentityId) return;

    await deleteNoteIdentityDeleteMutation({
      variables: {
        noteIdentityId,
      },
    });
  }, []);

  return (
    <NoteContainer
      noteIdentitys={data?.noteIdentity}
      insertNoteIdentity={insertNoteIdentity}
      deleteNoteIdentity={deleteNoteIdentity}
      currentBilikPerson={currentBilikPerson}
      refetch={getNotesIdentity}
      emails={emails}
      telephones={telephones}
    />
  );
};
export default NoteRepository;
