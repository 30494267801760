import FormField from 'components/form-field/form-field';
import { FieldArray, useFormikContext } from 'formik';
import { ProFormUpdateFields } from 'pages/pros/pro-form/pro-form-update-fields-type';
import React, { Fragment, FunctionComponent } from 'react';
import { Button, Container, Icon, Table } from 'semantic-ui-react';

const ProPriceField: FunctionComponent = () => {
  const { values } = useFormikContext<ProFormUpdateFields>();

  return (
    <FieldArray
      name="proPrices"
      render={(arrayHelpers): React.ReactElement => (
        <Fragment>
          {values.proPrices && values.proPrices.length > 0 ? (
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="6">Titre</Table.HeaderCell>
                  <Table.HeaderCell width="7">Tarif</Table.HeaderCell>
                  <Table.HeaderCell width="2" textAlign="center">
                    Tarif dépannage ?
                  </Table.HeaderCell>
                  <Table.HeaderCell width="1">&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {values.proPrices.map((_, proPriceIndex) => (
                  <Table.Row
                    verticalAlign="top"
                    style={{ height: '73px' }}
                    key={proPriceIndex}
                  >
                    <Table.Cell>
                      <FormField
                        name={`proPrices.${proPriceIndex}.title`}
                        type="text"
                        placeholder="Ex: Dépannage semaine"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormField
                        type="text"
                        name={`proPrices.${proPriceIndex}.content`}
                        placeholder="Ex: 68 € HT / heure (hors pièces) + 38 € HT de déplacement"
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <FormField
                        name={`proPrices.${proPriceIndex}.isRepair`}
                        type="checkbox"
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Button
                        basic
                        negative
                        icon
                        type="button"
                        onClick={(): void => {
                          arrayHelpers.remove(proPriceIndex);
                        }}
                      >
                        <Icon name="trash alternate outline" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : null}
          <Container fluid style={{ marginTop: '14px' }} textAlign="right">
            <Button
              type="button"
              positive
              onClick={(): void => {
                arrayHelpers.push({ title: '', content: '', isRepair: true });
              }}
            >
              <Icon name="plus" />
              Nouveau tarif
            </Button>
          </Container>
        </Fragment>
      )}
    />
  );
};

export default ProPriceField;
