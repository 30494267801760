import { CitiesTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useEffect } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import { CitiesTableQueryResult } from './cities-table-repository';
import CitiesTableView from './cities-table-view';

type CitiesTableContainerProps = {
  loading: boolean;
  cities: CitiesTableQuery['city'];
  bilikZoneId?: number;
  fetchCities: () => Promise<CitiesTableQueryResult>;
  deleteCity: (cityId: number) => Promise<void>;
};

const CitiesTableContainer: FunctionComponent<CitiesTableContainerProps> = ({
  loading,
  cities,
  bilikZoneId,
  fetchCities,
  deleteCity,
}) => {
  const onDeleteCity = async (cityId: number): Promise<void> => {
    await deleteCity(cityId).catch((error) => {
      ToastError('Erreur', 'Impossible de supprimer la ville');
      throw error;
    });

    await fetchCities();

    ToastSuccess('Succès', 'Ville supprimée');
  };

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      await fetchCities();
    };
    fetch();
  }, [fetchCities]);

  if (!loading && !cities.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  if (loading && !cities.length) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <CitiesTableView
      cities={cities}
      loading={loading}
      bilikZoneId={bilikZoneId}
      onDeleteCity={onDeleteCity}
    />
  );
};

export default CitiesTableContainer;
