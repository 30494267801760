import { CopyButton, CopyRefButton } from 'components/copy-button/copy-button';
import env from 'env';
import { useProWidgetQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Segment,
  FormField as FormFieldUi,
  Checkbox,
  Select,
  Loader,
  Modal,
} from 'semantic-ui-react';

type OldWidget = {
  size: string;
  link: boolean;
  static: boolean;
  text: boolean;
};

type ProWidgetProps = {
  proViewId: number;
};

const ProWidget: FunctionComponent<ProWidgetProps> = ({ proViewId }) => {
  const { data, error } = useProWidgetQuery({
    variables: { proViewId },
  });

  const carouselCodeRef = useRef<HTMLDivElement>(null);

  const oldWidgetCodeRef = useRef<HTMLDivElement>(null);
  const oldWidgetRef = useRef<HTMLDivElement>(null);
  const signatureRef = useRef<HTMLDivElement>(null);

  const [oldWidget, setOldWidget] = useState<OldWidget>({
    size: 'medium',
    link: true,
    static: false,
    text: false,
  });

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const [isDarkModeIframe, setIsDarkModeIframe] = useState(false);

  const oldWidgetSize = useMemo(() => {
    switch (oldWidget.size) {
      case 'small':
        return '100px';
      case 'medium':
        return '150px';
      case 'large':
        return '250px';
      default:
        return '100px';
    }
  }, [oldWidget.size]);

  const proView = data?.proViewByPk;

  const proReviewLink = useMemo(() => {
    return `${env.SITE_URL}/pro/fiche/${data?.proViewByPk?.id}/widget#reviews`;
  }, [data?.proViewByPk?.proPresentation.id]);

  const signatureImageSrc = useMemo(() => {
    return `${env.SITE_URL}/widget/${proView?.proPresentation.id}/99px/default/signature`;
  }, [proView?.proPresentation.id, oldWidget.static]);

  const widgetImageSrc = useMemo(() => {
    return `${env.SITE_URL}/widget/${
      data?.proViewByPk?.proPresentation.id
    }/${oldWidgetSize}/${oldWidget.static ? 'static' : ''}`;
  }, [oldWidgetSize, oldWidget.static, data?.proViewByPk?.proPresentation.id]);

  const iframeSrc = useMemo(() => {
    const darkModeSearchParam = isDarkModeIframe ? '?darkMode=true' : '';
    return `${env.SITE_URL}/widgets/carousel-avis/${data?.proViewByPk?.proPresentation.id}${darkModeSearchParam}`;
  }, [data?.proViewByPk?.proPresentation.id, isDarkModeIframe]);

  const tradeLink = useMemo(() => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.SITE_URL}/${data?.proViewByPk?.bilikZone.slug}/${data?.proViewByPk?.proPresentation.mainTrade?.slug}?utm_source=widget&utm_medium=textlink`}
      >
        {data?.proViewByPk?.proPresentation.mainTrade?.name} à{' '}
        {data?.proViewByPk?.bilikZone.mainCity?.name}
      </a>
    );
  }, [data?.proViewByPk]);

  const proReviewFormLinkText = `${env.SITE_URL}/avis?pro=${proView?.proPresentation.id}`;
  const proReviewsLinkText = `${env.SITE_URL}/pro/fiche/${proView?.id}#reviews`;
  const proPageLinkText = `${env.SITE_URL}/pro/fiche/${proView?.id}`;

  const proReviewFormLink = (
    <>
      <a
        href={`${env.SITE_URL}/avis?pro=${proView?.proPresentation.id}`}
        className="anchor"
      >
        {proReviewFormLinkText}
      </a>
    </>
  );

  const proReviewsLink = (
    <a
      href={`${env.SITE_URL}/pro/fiche/${proView?.id}#reviews`}
      className="anchor"
    >
      {proReviewsLinkText}
    </a>
  );

  const proPageLink = (
    <a href={`${env.SITE_URL}/pro/fiche/${proView?.id}`} className="anchor">
      {proPageLinkText}
    </a>
  );

  const htmlCodeIframe = `
  <iframe
    title="Widget avis Bilik"
    src="${iframeSrc}"
    loading="lazy"
    style="border: none; position: relative; display: block; overflow: hidden;
    height: 348px; width: 100%; background: transparent;"
    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
    scrolling="no">
  </iframe>
  `;

  const htmlCodeSignature = `
  <p>Cordialement,</p>
  <p>
    <b>
      ${proView?.proPeople
        .map(({ proPerson }) => {
          return `${proPerson.givenName} ${proPerson.familyName}`;
        })
        .join(', ')}
    </b>
  </p>
  <p>
    ${proView?.proPresentation?.name}
    <br />
    ${proView?.proPostalAddress?.street}
    <br />
    ${proView?.proPostalAddress?.postalCode} ${proView?.proPostalAddress?.city}
  </p>
  <p>PS : Retrouvez tous nos avis sur Bilik :
    <a href="${proReviewsLinkText}" className="anchor">
      ${proReviewsLinkText}
    </a>
  </p>
  <p>
    PPS: A la fin de chaque prestation il est important pour moi d'avoir votre témoignage sur notre travail. Pour cela, c'est très simple, suivez ce lien
    <a href="${proReviewFormLinkText}" className="anchor">
      ${proReviewFormLinkText}
    </a>
  </p>
  <a href="${env.SITE_URL}/pro/fiche/${proView?.id}"
    class="anchor"
    target="_blank" rel="noopener noreferrer">
    <img alt="Voir page pro sur Bilik"
    title="Voir page pro sur Bilik"
    style="width: 99px; height: 139px;"
    src="${signatureImageSrc}" />
  </a>
  `;

  const htmlCodeOldWidget = `
  ${
    oldWidget.text
      ? `
  <p>
    Voir <a href="${proReviewLink}">mes avis</a> sur Bilik
  </p>
  `
      : `
  <a href="${proReviewLink}" target="_blank" rel="noopener noreferrer">
    <img alt="Voir les avis sur Bilik"
    title="Voir les avis sur Bilik"
    style="width: ${oldWidgetSize}"
    src="${widgetImageSrc}" />
  </a>
  `
  }${
    oldWidget.link
      ? `
  <br />
  ${
    oldWidget.size === 'large'
      ? '<p>Retrouvez notre entreprise dans la rubrique'
      : ''
  }
  <a target="_blank"
    rel="noopener noreferrer"
    href="${env.SITE_URL}/${data?.proViewByPk?.bilikZone.slug}/${
          data?.proViewByPk?.proPresentation?.mainTrade?.slug
        }?utm_source=widget&utm_medium=textlink">
    ${data?.proViewByPk?.proPresentation?.mainTrade?.name} à ${
          data?.proViewByPk?.bilikZone.mainCity?.name
        }
  </a>
    ${oldWidget.size === 'large' ? ` sur Bilik </p>` : ''}
  `
      : ''
  }
  `;

  if (error) return <>Error! {error.message}</>;

  if (!data?.proViewByPk) {
    return <Loader active inline="centered" />;
  }

  return (
    <>
      <style>
        {`
          .anchor {
                color: #15c;
                text-decoration: underline;
                cursor: pointer;
              }

              .anchor:hover {
                color: #15c;
                text-decoration: underline;
              }
            `}
      </style>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <h3>Carrousel</h3>
            <CopyButton
              text={'Copier le code HTML'}
              selection={htmlCodeIframe}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={10}>
            <Segment inverted>
              <div ref={carouselCodeRef}>{htmlCodeIframe}</div>
            </Segment>
            <Form.Group>
              <FormFieldUi>
                <Checkbox
                  label="Mode sombre"
                  checked={isDarkModeIframe}
                  onChange={(): void => {
                    setIsDarkModeIframe(!isDarkModeIframe);
                  }}
                />
              </FormFieldUi>
            </Form.Group>
          </Grid.Column>
          <Grid.Column
            width={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              compact
              onClick={(event): void => {
                event.preventDefault();
                setShouldOpenModal(true);
              }}
            >
              <Icon name="eye" size="small" />
              Aperçu
            </Button>

            <Modal
              open={shouldOpenModal}
              onClose={(): void => {
                setShouldOpenModal(false);
              }}
              closeIcon
              size="large"
            >
              <div
                style={{
                  backgroundColor: isDarkModeIframe ? '#000' : '#fff',
                }}
              >
                <iframe
                  title="Widget avis Bilik"
                  src={`${iframeSrc}`}
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                  loading="lazy"
                  style={{
                    position: 'relative',
                    display: 'block',
                    border: 'none',
                    overflow: 'hidden',
                    width: '100%',
                    height: '348px',
                    background: 'transparent',
                  }}
                  scrolling="no"
                />
              </div>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <h3>Liens</h3>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={10}>
            <p>
              Lien court vers le formulaire d&apos;avis : {proReviewFormLink}
            </p>
          </Grid.Column>
          <Grid.Column
            width={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CopyButton
              text={'Copier le lien'}
              selection={proReviewFormLinkText}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={10}>
            <p>Lien court vers avis : {proReviewsLink}</p>
          </Grid.Column>
          <Grid.Column
            width={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CopyButton
              text={'Copier le lien'}
              selection={proReviewsLinkText}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={10}>
            <p>lien court vers fiche : {proPageLink}</p>
          </Grid.Column>
          <Grid.Column
            width={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CopyButton text={'Copier le lien'} selection={proPageLinkText} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={10}>
            <h3>Signature</h3>
            <div>
              <Divider hidden />
              <CopyButton
                text={'Copier le code HTML'}
                selection={htmlCodeSignature}
              />
              <Segment inverted>
                <div
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {htmlCodeSignature}
                </div>
              </Segment>
            </div>

            <Divider hidden />

            <CopyRefButton
              text={'Copier la signature'}
              elementToCopyRef={signatureRef}
            />

            {/* Do not remove this div, it is used to copy the signature correctly */}
            <div
              style={{
                color: '#000',
                fontFamily: 'Sans Serif, Helvetica, sans-serif',
                fontSize: '12px',
                marginTop: '10px',
              }}
            >
              <div ref={signatureRef}>
                <p>Cordialement,</p>
                <p>
                  <b>
                    {proView?.proPeople
                      .map(({ proPerson }) => {
                        return `${proPerson.givenName} ${proPerson.familyName}`;
                      })
                      .join(', ')}
                  </b>
                </p>
                <p>
                  {proView?.proPresentation.name}
                  <br />
                  {proView?.proPostalAddress?.street}
                  <br />
                  {proView?.proPostalAddress?.postalCode}{' '}
                  {proView?.proPostalAddress?.city}
                </p>
                <p>PS : Retrouvez tous nos avis sur Bilik : {proReviewsLink}</p>
                <p>
                  PPS: A la fin de chaque prestation il est important pour moi
                  d&apos;avoir votre témoignage sur notre travail. Pour cela,
                  c&apos;est très simple, suivez ce lien {proReviewFormLink}
                </p>
                <a
                  href={`${env.SITE_URL}/pro/fiche/${proView?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="anchor"
                >
                  <img
                    alt="Voir page pro sur Bilik"
                    title="Voir page pro sur Bilik"
                    style={{ width: '99px', height: '139px' }}
                    src={signatureImageSrc}
                  />
                </a>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <h3>Ancien Widget</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <CopyButton
              text={'Copier le code HTML'}
              selection={htmlCodeOldWidget}
            />
            <Segment inverted>
              <div ref={oldWidgetCodeRef}>{htmlCodeOldWidget}</div>
            </Segment>
            <Form.Group>
              <FormFieldUi>
                <Select
                  options={[
                    { key: 'small', value: 'small', text: 'Petit' },
                    { key: 'medium', value: 'medium', text: 'Moyen' },
                    { key: 'large', value: 'large', text: 'Grand' },
                  ]}
                  value={oldWidget.size}
                  onChange={(event, { value }): void => {
                    setOldWidget({
                      size: String(value),
                      link: value !== 'small',
                      static: oldWidget.static,
                      text: oldWidget.text,
                    });
                  }}
                />
              </FormFieldUi>
              <FormFieldUi style={{ marginTop: '6px' }}>
                <Checkbox
                  label="Afficher le lien vers la catégorie"
                  checked={oldWidget.link}
                  onChange={(): void => {
                    setOldWidget({
                      link: !oldWidget.link,
                      size: oldWidget.size,
                      static: oldWidget.static,
                      text: oldWidget.text,
                    });
                  }}
                />
              </FormFieldUi>
              <FormFieldUi style={{ marginTop: '6px' }}>
                <Checkbox
                  label="Créer un lien statique"
                  checked={oldWidget.text ? false : oldWidget.static}
                  onChange={(): void => {
                    setOldWidget({
                      link: oldWidget.link,
                      size: oldWidget.size,
                      static: !oldWidget.static,
                      text: oldWidget.text,
                    });
                  }}
                />
              </FormFieldUi>
              <FormFieldUi style={{ marginTop: '6px' }}>
                <Checkbox
                  label="Créer une signature textuelle"
                  checked={oldWidget.text}
                  onChange={(): void => {
                    setOldWidget({
                      link: oldWidget.link,
                      size: oldWidget.size,
                      static: !oldWidget.static,
                      text: !oldWidget.text,
                    });
                  }}
                />
              </FormFieldUi>
            </Form.Group>
          </Grid.Column>
          <Grid.Column width={6} textAlign="center">
            <CopyRefButton
              text={'Copier le widget'}
              elementToCopyRef={oldWidgetRef}
            />
            <div
              style={{
                width: oldWidgetSize,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <div ref={oldWidgetRef}>
                {oldWidget.text ? (
                  <>
                    <p>
                      Voir <a href={proReviewLink}>mes avis</a> sur Bilik
                    </p>
                  </>
                ) : (
                  <>
                    <a
                      href={proReviewLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Voir les avis sur Bilik"
                        title="Voir les avis sur Bilik"
                        style={{ width: oldWidgetSize }}
                        src={widgetImageSrc}
                      />
                    </a>
                  </>
                )}
                <br />
                {oldWidget.link ? (
                  <>
                    {oldWidget.size === 'large' ? (
                      <p>
                        Retrouvez notre entreprise dans la rubrique {tradeLink}{' '}
                        sur Bilik
                      </p>
                    ) : (
                      tradeLink
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};

export default ProWidget;
