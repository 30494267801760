import { format } from 'date-fns-tz';

export const formatDateTime = (date: string): string =>
  format(new Date(date), "dd/MM/yyyy 'à' HH'h'mm");

export const formatDate = (date: string): string =>
  format(new Date(date), 'dd/MM/yyyy');

export const formatTime = (date: string): string =>
  format(new Date(date), "HH'h'mm");
