import { ProMandatesQuery } from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent } from 'react';
import ProMandateFormCreateRepository from './pro-mandate-form-create-repository';

type ProMandateFormModalProps = {
  open: boolean;
  onClose: () => void;
};

export type ProMandateFormProps = ProMandateFormModalProps & {
  proOrganizationId: number;
  onProMandateCreated?: () => void;
  existingProMandates?: ProMandatesQuery['proMandate'];
};

const ProMandateFormModal: FunctionComponent<ProMandateFormProps> = ({
  ...rest
}) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  if (!currentBilikPerson) {
    return <div>Chargement...</div>;
  }

  return (
    <ProMandateFormCreateRepository
      {...rest}
      currentBilikPersonId={currentBilikPerson?.id}
    />
  );
};

export default ProMandateFormModal;
