import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, Icon } from 'semantic-ui-react';

type ExportGuideCardProps = Record<string, unknown>;

export const ExportGuideCard: FunctionComponent<
  ExportGuideCardProps
> = ({}) => {
  const history = useNavigate();

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="book" />
        <Card.Header>Export guide</Card.Header>
        <Card.Description>
          {`Outil d'export de données pour la réalisation des guides papiers.`}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          fluid
          primary
          onClick={(): void => {
            history('/export_guide');
          }}
        >
          Ouvrir
        </Button>
      </Card.Content>
    </Card>
  );
};
