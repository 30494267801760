import { apiClient } from 'axios-client';
import FormField from 'components/form-field/form-field';
import HelpText from 'components/help-text/help-text';
import Label from 'components/label/label';
import {
  useDeleteProCertificationRgeMutation,
  useInsertProCertificationRgeMutation,
  useProLabelsMutation,
  useProLabelsQuery,
} from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { range } from 'remeda';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  Loader,
  Popup,
  Table,
  Label as LabelUi,
} from 'semantic-ui-react';
import { formatDateTime, ToastError, ToastSuccess } from 'utils';

interface ProLabelsProps {
  proPresentationId: number;
}

const ProLabels: FunctionComponent<ProLabelsProps> = ({
  proPresentationId,
}) => {
  const onClickCheckRge = useCallback(async (proPresentationId: number) => {
    setCheckRgeLoading(true);
    await apiClient.post(`form/pro-organization/check-labels`, {
      proPresentationId,
    });
    setCheckRgeLoading(false);
    refetch();
  }, []);

  const onClickCheckDoremi = useCallback(async (proPresentationId: number) => {
    setCheckDoremiLoading(true);
    await apiClient.post(`form/pro-organization/check-doremi`, {
      proPresentationId,
    });
    setCheckDoremiLoading(false);
    refetch();
  }, []);

  const { data, loading, refetch } = useProLabelsQuery({
    variables: { proPresentationId },
  });

  const [proLabelsMutation] = useProLabelsMutation();

  const [insertProCertificationRgeMutation] =
    useInsertProCertificationRgeMutation();
  const [deleteProCertificationRgeMutation] =
    useDeleteProCertificationRgeMutation();

  useEffect(() => {
    if (data?.proLabels.length) {
      // Delete rge certification for pro if there is no visible RGE label
      if (data.proLabels.some((l) => l.isVisible)) {
        insertProCertificationRgeMutation({
          variables: {
            proPresentationId,
          },
        });
      } else {
        deleteProCertificationRgeMutation({
          variables: {
            proPresentationId,
          },
        });
      }
    }
  }, [data?.proLabels]);

  const [checkRgeLoading, setCheckRgeLoading] = useState(false);
  const [checkDoremiLoading, setCheckDoremiLoading] = useState(false);

  return (
    <>
      <Divider horizontal style={{ marginTop: '50px' }}>
        Labels / Qualifications / Assurances
      </Divider>
      <Popup
        trigger={
          <Button
            loading={checkRgeLoading}
            disabled={checkRgeLoading}
            onClick={async () => {
              await onClickCheckRge(proPresentationId).catch((error) => {
                console.error(error);
                ToastError(
                  'Erreur',
                  "Impossible de récupérer les informations RGE, vérifiez que l'entreprise est bien renseignée (siret necessaire)",
                );
                setCheckRgeLoading(false);
              });
              ToastSuccess('Succès', 'Vérification effectuée !');
            }}
            basic
            color="blue"
          >
            Vérifier les labels RGE
          </Button>
        }
      >
        Effectue une vérification des certificats RGE du pro directement via la
        base de données officielles de l&apos;état. L&apos;entreprise doit avoir
        été renseignée pour utiliser cette action (siret nécessaire). Une
        vérification automatique est réalisée à intervalle régulier.
      </Popup>
      <Popup
        trigger={
          <Button
            loading={checkDoremiLoading}
            disabled={checkDoremiLoading}
            onClick={async () => {
              await onClickCheckDoremi(proPresentationId).catch((error) => {
                console.error(error);
                ToastError(
                  'Erreur',
                  "Impossible de récupérer les informations Dorémi, vérifiez que l'entreprise est bien renseignée (siret necessaire)",
                );
                setCheckDoremiLoading(false);
                throw error;
              });
              ToastSuccess('Succès', 'Vérification effectuée !');
            }}
            basic
            color="blue"
          >
            Vérifier le label Dorémi
          </Button>
        }
      >
        Effectue une vérification du label Dorémi directement via la base de
        données Dorémi. L&apos;entreprise doit avoir été renseignée pour
        utiliser cette action (siret nécessaire). Une vérification automatique
        est réalisée à intervalle régulier.
      </Popup>
      {!loading ? (
        <>
          {data?.proLabels.length ? (
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Visible</Table.HeaderCell>
                  <Table.HeaderCell>Nom du certificat</Table.HeaderCell>
                  <Table.HeaderCell>Domaine</Table.HeaderCell>
                  <Table.HeaderCell>PDF</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    Dernière vérification
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.proLabels.map((label) => (
                  <Table.Row key={`pro-label-${label.id}`}>
                    <Table.Cell>
                      <Checkbox
                        checked={label.isVisible}
                        onChange={async () => {
                          await proLabelsMutation({
                            variables: {
                              id: label.id,
                              isVisible: !label.isVisible,
                            },
                          });

                          refetch();
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell disabled={!label.isVisible}>
                      {label.certificateName}
                    </Table.Cell>
                    <Table.Cell disabled={!label.isVisible}>
                      {label.domain}
                    </Table.Cell>
                    <Table.Cell>
                      <a
                        href={label.qualificationUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon name="file pdf" size="big" />
                      </a>
                    </Table.Cell>
                    <Table.Cell disabled={!label.isVisible} textAlign="right">
                      {!label.isCheckActive && (
                        <LabelUi style={{ marginRight: '8px' }} color="red">
                          MANUEL
                        </LabelUi>
                      )}
                      {formatDateTime(label.dateChecked)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <>
              <br />
              <br />
              <i>Aucun Label RGE associé à l&apos;entreprise</i>
            </>
          )}
        </>
      ) : (
        <Loader active inline="centered" />
      )}
      <Divider hidden />
      {data?.certifications ? (
        <FormField
          type="checkboxes"
          label="Autres labels :"
          name="proPresentation.certifications"
          options={data.certifications.map((certification) => ({
            key: certification.id,
            value: certification.id,
            label: certification.name,
            disabled: certification.id === 7, // Dorémi
          }))}
        />
      ) : null}

      <Label>Autres</Label>
      <HelpText>
        Ex: Assurance décennale et RCP, Brevet Professionnel, RGE manquant...
      </HelpText>
      {range(1, 6).map((index) => (
        <FormField
          key={`extra-${index}`}
          type="text"
          name={`proPresentation.extra${index}`}
        />
      ))}
    </>
  );
};

export default ProLabels;
