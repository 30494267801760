import ImageDeleteButton from 'components/image-delete-button/image-delete-button';
import env from 'env';
import React from 'react';
import { Image } from 'semantic-ui-react';
import type { Transform } from '@dnd-kit/utilities';
import classNames from 'classnames';
import styles from './image-item.module.css';
import { DraggableSyntheticListeners } from '@dnd-kit/core';

export type ImageItemProps = {
  hash: string;
  withOpacity?: boolean;
  dragOverlay?: boolean;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  dragging?: boolean;
  openImageModal: (hash: string) => void;
  onDelete: (hash: string) => void;
  sorting?: boolean;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;

  height?: number;
  index?: number;

  style?: React.CSSProperties;
};

export const ImageItem = React.forwardRef<HTMLDivElement, ImageItemProps>(
  (
    {
      hash,
      dragging,
      fadeIn,
      transition,
      transform,
      listeners,
      sorting,
      wrapperStyle,
      style,
      dragOverlay,
      openImageModal,
      onDelete,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={classNames(
          styles.Wrapper,
          fadeIn && styles.fadeIn,
          sorting && styles.sorting,
          dragOverlay && styles.dragOverlay,
        )}
        style={
          {
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(', '),
            '--translate-x': transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            '--translate-y': transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
            '--index': hash,
          } as React.CSSProperties
        }
        ref={ref}
      >
        {!dragging && (
          <div
            className={classNames(
              styles.ImageItem,
              dragging && styles.dragging,
              dragOverlay && styles.dragOverlay,
            )}
            style={style}
            {...props}
            {...listeners}
            tabIndex={0}
          >
            <Image
              fluid
              onClick={(): void => {
                openImageModal(hash);
              }}
              src={`${env.API_URL}/images/${hash}/full/300,300/0/default.jpg`}
            />
            <ImageDeleteButton
              onDelete={(): void => {
                onDelete(hash);
              }}
              style={{
                position: 'absolute',
                top: 20,
                right: 15,
                zIndex: 1,
              }}
              confirm
            />
          </div>
        )}
      </div>
    );
  },
);
