import FormField from 'components/form-field/form-field';
import Label from 'components/label/label';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  Button,
  Container,
  DropdownItemProps,
  Form,
  Modal,
  Select,
  FormField as FormFieldUi,
  Transition,
} from 'semantic-ui-react';
import { formatTelephone } from 'utils';
import {
  DocumentSendFormAdditionalFields,
  DocumentSendFormModalFields,
} from './document-send-form-modal-container';

type Signatory = {
  givenName: string;
  familyName: string;
  telephone: string;
  isLegalRepresentative?: boolean;
};

type ProMandateSendModalProps = {
  open: boolean;
  onClose: () => void;
  additionalFields?: DocumentSendFormAdditionalFields;
  receiptEmailSuggestions?: string[];
  proPersonSuggestions?: Signatory[] | undefined;
};

const DocumentSendFormModalView: FunctionComponent<
  ProMandateSendModalProps
> = ({
  open,
  receiptEmailSuggestions,
  proPersonSuggestions,
  additionalFields,
  onClose,
}) => {
  const {
    setFieldValue,
    initialValues,
    handleSubmit,
    isSubmitting,
    handleReset,
    submitForm,
  } = useFormikContext<DocumentSendFormModalFields>();

  const [receiptEmailValue, setReceiptEmailValue] = useState<string>(
    initialValues.proReceiptEmail || '',
  );
  const [signatoryValue, setSignatoryValue] = useState<string>(
    initialValues.telephone || '',
  );

  const signatorySuggestionOptions = useMemo(() => {
    const proPeopleOptions =
      proPersonSuggestions?.map((signatory) => ({
        key: `signatory-options-${signatory.telephone}`,
        text: (
          <>
            {signatory.givenName} {signatory.familyName} -{' '}
            {formatTelephone(signatory.telephone || 'error')}
            {signatory.isLegalRepresentative ? ' (Représentant légal)' : ''}
          </>
        ),
        property: {
          ...signatory,
        },
        value: signatory.telephone,
      })) || [];

    // Adding the custom option
    proPeopleOptions.push({
      key: `pro-people-options-custom`,
      text: <>Personnalisé</>,
      property: {
        givenName: '',
        familyName: '',
        telephone: '',
        isLegalRepresentative: false,
      },
      value: 'custom',
    });

    return proPeopleOptions;
  }, [proPersonSuggestions]);

  const receiptEmailSuggestionOptions = useMemo(() => {
    const options: DropdownItemProps[] =
      receiptEmailSuggestions?.map((emailSuggestion, index) => ({
        key: `email-suggestion-${index}`,
        value: emailSuggestion,
        text: emailSuggestion,
      })) || [];

    options.push({
      key: 'email-suggestion-custom',
      text: 'Personnalisé',
      value: 'custom',
    });
    return options;
  }, [receiptEmailSuggestions]);

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon={true}
      onClose={onClose}
    >
      <Modal.Header>Envoyer le document</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormFieldUi>
            <Label required>Envoyer le lien de signature à :</Label>
            <Select
              onChange={(e, { value }): void => {
                if (value !== undefined && value !== null) {
                  const selectedOption = signatorySuggestionOptions?.filter(
                    (option) => option.value === value,
                  )[0];
                  setFieldValue('telephone', selectedOption.property.telephone);
                  if (additionalFields?.givenName) {
                    setFieldValue(
                      'givenName',
                      selectedOption.property.givenName,
                    );
                  }
                  if (additionalFields?.familyName) {
                    setFieldValue(
                      'familyName',
                      selectedOption.property.familyName,
                    );
                  }
                  setSignatoryValue(selectedOption.value);
                }
              }}
              placeholder="Sélectionnez le numéro..."
              value={signatoryValue}
              options={signatorySuggestionOptions}
            />
          </FormFieldUi>
          <Transition.Group duration={250}>
            {signatoryValue === 'custom' ? (
              <Container fluid style={{ paddingLeft: '50px' }}>
                <Form.Group widths="equal">
                  <FormField
                    type="telephone"
                    label="Numéro"
                    name="telephone"
                    placeholder="Ex: 06 44 64 27 74"
                    required
                  />
                  {additionalFields?.familyName && (
                    <FormField
                      type="text"
                      placeholder="Ex: Nom"
                      label="Nom"
                      name="familyName"
                      required
                    />
                  )}
                  {additionalFields?.givenName && (
                    <FormField
                      type="text"
                      placeholder="Ex: Prénom"
                      label="Prénom"
                      name="givenName"
                      required
                    />
                  )}
                </Form.Group>
              </Container>
            ) : null}
          </Transition.Group>
          <FormFieldUi>
            <Label required>Email d&apos;accusé de réception du pro :</Label>
            <Select
              onChange={(e, { value }): void => {
                if (value !== undefined && value !== null) {
                  const selectedOption = receiptEmailSuggestionOptions.filter(
                    (option) => option.value === value,
                  )[0];
                  const optionValue = selectedOption.value as string;

                  setFieldValue(
                    'proReceiptEmail',
                    optionValue === 'custom' ? '' : optionValue,
                  );

                  setReceiptEmailValue(
                    optionValue === 'custom' ? 'custom' : optionValue,
                  );
                }
              }}
              placeholder="Selectionnez l'email de réception du mandat..."
              value={receiptEmailValue}
              options={receiptEmailSuggestionOptions}
            />
          </FormFieldUi>
          <Transition.Group duration={250}>
            {receiptEmailValue === 'custom' ? (
              <Container fluid style={{ paddingLeft: '50px' }}>
                <Form.Group widths="equal">
                  <FormField
                    type="email"
                    label="Email"
                    name="proReceiptEmail"
                    placeholder="Ex : machin@truc.fr"
                    required
                  />
                </Form.Group>
              </Container>
            ) : null}
          </Transition.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose}>
          Fermer
        </Button>
        <Button
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          primary
          onClick={submitForm}
        >
          Envoyer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DocumentSendFormModalView;
