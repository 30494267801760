import {
  ProOrganizationSetInput,
  useProOrganizationFormUpdateMutation,
  useProOrganizationFormUpdateQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ProOrganizationFormModalProps } from './pro-organization-form-modal';
import ProOrganizationFormUpdateContainer from './pro-organization-form-update-container';

type ProOrganizationFormUpdateRepositoryProps =
  ProOrganizationFormModalProps & {
    proOrganizationId: number;
    onProOrganizationUpdated?: () => void;
  };

const ProOrganizationFormUpdateRepository: FunctionComponent<
  ProOrganizationFormUpdateRepositoryProps
> = ({ proOrganizationId, onProOrganizationUpdated, ...rest }) => {
  const { data } = useProOrganizationFormUpdateQuery({
    variables: { id: proOrganizationId },
  });

  const [proOrganizationFormUpdateMutation] =
    useProOrganizationFormUpdateMutation();

  const updateProOrganization = useCallback(
    async (proOrganizationInput: ProOrganizationSetInput) => {
      await proOrganizationFormUpdateMutation({
        variables: { proOrganizationId, proOrganizationInput },
      });
      onProOrganizationUpdated?.();
    },
    [proOrganizationId],
  );

  return (
    <ProOrganizationFormUpdateContainer
      proOrganizationId={proOrganizationId}
      proOrganization={data?.proOrganizationByPk}
      updateProOrganization={updateProOrganization}
      {...rest}
    />
  );
};

export default ProOrganizationFormUpdateRepository;
