import React, { FunctionComponent, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import SolicitationsGraphView from './solicitations-graph-view';
import { SolicitationGraphQuery } from 'generated/graphql';
import { CountByMonth } from '../utils/count-by-month.type';
import { isSameMonth, startOfMonth } from 'date-fns';
import { convertTableData } from '../utils/convert-table-data';
import { Serie } from '@nivo/line';

type SolicitationsGraphContainerProps = {
  loading: boolean;
  solicitations?: SolicitationGraphQuery['solicitation'];
  date: Date;
  compareDate: Date;
};

const SolicitationsGraphContainer: FunctionComponent<
  SolicitationsGraphContainerProps
> = ({ loading, date, compareDate, solicitations }) => {
  const [chartData, setChartData] = useState<Serie[]>([]);

  useEffect(() => {
    if (!solicitations) return;

    const countByMonth: CountByMonth[] = [];

    solicitations.forEach((solicitation) => {
      const solicitationDate = new Date(solicitation.dateCreated);
      const solicitationMonth = startOfMonth(solicitationDate);

      const existingMonth = countByMonth.find((item) =>
        isSameMonth(item.month, solicitationMonth),
      );

      if (existingMonth) {
        existingMonth.count++;
      } else {
        countByMonth.push({
          month: solicitationMonth,
          count: 1,
        });
      }
    });

    setChartData(
      convertTableData(countByMonth, [
        date.getFullYear(),
        compareDate.getFullYear(),
      ]),
    );
  }, [solicitations]);

  if (loading) {
    return (
      <div style={{ height: 276 }}>
        <Loader
          size="large"
          active
          inline="centered"
          style={{ marginTop: 224 }}
        />
      </div>
    );
  }

  return <SolicitationsGraphView data={chartData} />;
};

export default SolicitationsGraphContainer;
