import { useMailSuggestion } from 'hooks/use-mail-suggestion/use-mail-suggestion';
import React, { FunctionComponent } from 'react';

type EmailSuggestionProps = {
  email: string;
  onAcceptEmailSuggestion: (suggestion: string) => void;
};

export const EmailSuggestion: FunctionComponent<EmailSuggestionProps> = ({
  onAcceptEmailSuggestion,
  email,
}) => {
  const emailSuggestion = useMailSuggestion(email);

  if (emailSuggestion) {
    return (
      <p>
        Voulez-vous dire{' '}
        <a
          onClick={(): void => onAcceptEmailSuggestion(emailSuggestion)}
          style={{ cursor: 'pointer' }}
        >
          {emailSuggestion}
        </a>{' '}
        ?
      </p>
    );
  }

  return null;
};
