import { useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export const useIsUserAdmin = (): boolean => {
  const { keycloak } = useKeycloak();

  const isUserAdmin = useMemo(() => keycloak.hasResourceRole('admin'), [
    keycloak,
  ]);

  return isUserAdmin;
};
