import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import { useSelectProViewFieldQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

type SelectProViewFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & {
  filters?: {
    proOrganizationId?: number;
    proPresentationId?: number;
    bilikZoneId?: number;
    exceptProViewIds?: number[];
    exceptProViewId?: number;
    exceptProPresentationId?: number;
    exceptStatus?: string[];
  };
  selectedProViews?: number[];
};

const SelectProViewField: FunctionComponent<SelectProViewFieldProps> = ({
  filters,
  multiple,
  selectedProViews,
  ...rest
}) => {
  const { data, loading, error } = useSelectProViewFieldQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        bilikZoneId: filters?.bilikZoneId
          ? { _eq: filters.bilikZoneId }
          : undefined,
        id: filters?.exceptProViewId
          ? { _neq: filters.exceptProViewId }
          : filters?.exceptProViewIds
          ? { _nin: filters.exceptProViewIds }
          : undefined,
        status: filters?.exceptStatus
          ? { _nin: filters.exceptStatus }
          : undefined,
        proPresentation: {
          proOrganizationId: filters?.proOrganizationId
            ? { _eq: filters.proOrganizationId }
            : undefined,

          id: filters?.exceptProPresentationId
            ? { _neq: filters.exceptProPresentationId }
            : filters?.proPresentationId
            ? { _eq: filters.proPresentationId }
            : undefined,
        },
      },
    },
  });

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.proView) {
      return data.proView.map((proView) => {
        return {
          key: proView.id,
          value: proView.id,
          text: `${proView.proPresentation?.name || 'NULL'} - ${
            proView.proPresentation.mainTrade?.name
          } - ${
            filters?.bilikZoneId
              ? `(${proView.proPresentation.name})`
              : proView.bilikZone.mainCityCode
          }`,
          disabled: selectedProViews?.includes(proView.id),
        };
      });
    }
    return [];
  }, [data?.proView, selectedProViews]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des pros...';
    }
    if (multiple) {
      return 'Selectionnez des pros...';
    }
    return 'Selectionnez un pro...';
  }, [loading, multiple]);

  if (error) return <>Error! ${error.message}</>;

  return (
    <FormField
      type="select"
      loading={loading}
      disabled={loading}
      options={options}
      multiple={multiple}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SelectProViewField;
