import { ResponsiveBar } from '@nivo/bar';
import { ProReviewsCountByDateQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Container, Header, Statistic } from 'semantic-ui-react';

type Props = {
  chartData: ProReviewsCountByDateQuery['countProReviewsWithStatusOkByMonthByProPresentation'];
  totalCount: number;
};

export const ProStatsProReviewInvitationsView: FunctionComponent<Props> = ({
  chartData,
  totalCount,
}) => {
  return (
    <Container fluid>
      <Header>Avis</Header>
      <br />
      <Statistic horizontal size="tiny">
        <Statistic.Value style={{ color: '#E55934' }}>
          {totalCount}
        </Statistic.Value>{' '}
        <Statistic.Label style={{ color: '#E55934', fontWeight: 'bold' }}>
          Avis
        </Statistic.Label>
      </Statistic>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#E55934']}
          groupMode="grouped"
          data={chartData}
          keys={['count']}
          indexBy="month"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          axisLeft={{
            tickValues: chartData.length,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};
