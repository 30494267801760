import { useApolloClient } from '@apollo/client';
import { apiClient } from 'axios-client';
import { useFormikContext } from 'formik';
import {
  ProPublishButtonGetValidDataQueryDocument,
  ProPublishButtonGetValidDataQuery,
  ProPublishButtonGetValidDataQueryVariables,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import { ToastError } from 'utils';
import { flattenObject } from 'utils/flatten-object';
import { getFormErrorMessage } from 'utils/form-error-message';
import { ProDocument } from '../pro-documents/pro-document.type';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';

type ProPublishButtonProps = {
  proViewId: number;
};

const ProPublishButton: FunctionComponent<ProPublishButtonProps> = ({
  proViewId,
}) => {
  const {
    values,
    isSubmitting,
    isValidating,
    validateForm,
    setFieldValue,
    handleSubmit,
  } = useFormikContext<ProFormUpdateFields>();

  const client = useApolloClient();

  const isDocumentsValid = useCallback(
    async (proViewId: number, proOrganizationId: number): Promise<boolean> => {
      const response = await apiClient
        .get<ProDocument[]>(`/attachment/pro-documents-informations`, {
          params: {
            pro_organization_id: proOrganizationId,
          },
        })
        .catch((error) => {
          ToastError(
            'Erreur',
            `Impossible de vérifier si le mandat de prélèvement et le contract sont présents`,
          );
          throw error;
        });

      const documents = response.data;

      // if (documents.length < 2) {
      //   return false;
      // }

      // Hacky solution, using a lazy query is not working (render issue)
      const { data } = await client.query<
        ProPublishButtonGetValidDataQuery,
        ProPublishButtonGetValidDataQueryVariables
      >({
        query: ProPublishButtonGetValidDataQueryDocument,
        variables: {
          proViewId,
        },
        fetchPolicy: 'no-cache',
      });

      const signedProMandates =
        data?.proViewByPk?.proPresentation?.proOrganization?.proMandates;

      const proViewHasSignedContract =
        data?.proViewByPk?.contractEvents?.some(
          (event) => event.type === 'ProSigned',
        ) &&
        data?.proViewByPk?.contractEvents?.some(
          (event) => event.type === 'BilikSigned',
        );

      const hasMandate =
        documents.findIndex(
          (document) => document.metaData['type'] === 'mandate',
        ) !== -1 &&
        signedProMandates &&
        signedProMandates.length > 0;

      const hasContract =
        documents.findIndex(
          (document) => document.metaData['type'] === 'contract',
        ) !== -1 && proViewHasSignedContract;

      return hasContract && hasMandate;
    },
    [client],
  );

  const onPublish = useCallback(async (): Promise<void> => {
    setFieldValue('status', 'published');
    values.status = 'published';

    // Set datePublished only on first proView publication
    if (values.datePublished === null) {
      setFieldValue('datePublished', new Date());
      values.datePublished = new Date();
    }

    // Check if proOrganization exists on proPresentation
    if (!values.proPresentation.proOrganizationId) {
      // Revert
      setFieldValue('status', 'draft');
      setFieldValue('datePublished', null);

      return ToastError(
        'Impossible de publier la fiche',
        "La fiche n'a pas d'entreprise, veuillez soit en créer une, soit en séléctionner une qui existe déjà.",
      );
    }

    const isDocumentValid = await isDocumentsValid(
      proViewId,
      values.proPresentation.proOrganizationId,
    );

    if (!isDocumentValid) {
      // Revert
      setFieldValue('status', 'draft');
      setFieldValue('datePublished', null);

      return ToastError(
        'Impossible de publier la fiche',
        'Il manque des documents obligatoires (au minimum un mandat signé et un contrat signé par les deux parties) pour publier la fiche.',
      );
    }

    // Check if proOrganization has at least one subscription
    const response = await apiClient.get<unknown[]>(
      `/zoho/subscription/pro/${encodeURIComponent(proViewId || '')}`,
    );

    if (response.data.length === 0) {
      return ToastError(
        'Impossible de publier la fiche',
        "Il n'y a pas d'abonnement reliés à cette fiche, veuillez en créer au moins un.",
      );
    }

    // Validate form when the status equal to published
    const formErrors = await validateForm(values);

    if (Object.values(formErrors).length === 0) {
      setTimeout(() => handleSubmit());
    } else {
      const errorValues = Object.values(flattenObject(formErrors)) as string[];
      const errorMsg = getFormErrorMessage(errorValues);

      // Revert
      setFieldValue('status', 'draft');
      setFieldValue('datePublished', null);

      ToastError('Impossible de publier la fiche', errorMsg);
    }
  }, [values, proViewId, isDocumentsValid]);

  return (
    <Button
      type="button"
      positive
      onClick={onPublish}
      loading={isSubmitting || isValidating}
      disabled={isSubmitting || isValidating}
    >
      Publier
    </Button>
  );
};

export default ProPublishButton;
