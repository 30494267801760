export const getToken = (): string | null =>
  localStorage.getItem('keycloakToken');
export const getTokenExp = (): number =>
  Number(localStorage.getItem('keycloakTokenExp'));
export const getRefreshToken = (): string | null =>
  localStorage.getItem('refreshToken');

export const setToken = (token: string): void =>
  localStorage.setItem('keycloakToken', token);
export const setTokenExp = (tokenExp: number): void =>
  localStorage.setItem('keycloakTokenExp', tokenExp.toString());
export const setRefreshToken = (refreshToken: string): void =>
  localStorage.setItem('refreshToken', refreshToken);

export const isTokenExpired = (): boolean => getTokenExp() > Date.now() - 1000;
