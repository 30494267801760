import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ProPresentationSearchFilterQuery } from 'generated/graphql';
import ProPresentationSearchFilterView from './pro-presentation-search-filter-view';

type Props = {
  loading: boolean;
  proPresentations: ProPresentationSearchFilterQuery['searchProPresentation'];
  proPresentationId?: number;
  setProPresentationId: (setProPresentationId: number | undefined) => void;
  setCriteria: (setCriteria: string) => void;
  criteria?: string;
  defaultValue?: string;
  search: boolean;
  setSearch: (value?: string) => void;
  setPage: (id: number) => void;
};
const ProPresentationSearchFilterContainer: FunctionComponent<Props> = ({
  loading,
  proPresentations,
  search,
  setSearch,
  setCriteria,
  criteria,
  setPage,
  setProPresentationId,
  defaultValue,
}) => {
  // State.
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(criteria);

  if (defaultValue && value == '') {
    setValue(defaultValue);
  }

  // On click cross.
  const onClick = useCallback((): void => {
    setValue('');
    setProPresentationId(undefined);
    setSearch(undefined);
    setCriteria('');
    setOpen(false);
  }, []);

  const onResultSelect = useCallback(
    (result): void => {
      setOpen(false);

      // All result containing ...
      if (result.id === -1) {
        setValue(value);
        setSearch(value);
      } else {
        setValue(`${result.title}`);
        setProPresentationId(result.id);
      }
      setPage(1);
    },
    [value],
  );

  const onSearchChange = useCallback((value): void => {
    setOpen(true);
    if (value) {
      setValue(value);
      setCriteria(value);
    } else {
      setValue('');
      setCriteria('');
    }
  }, []);

  const onKeyPress = useCallback(
    (key): void => {
      if (key === 'Enter') {
        setOpen(false);

        if (search) {
          setSearch(value);
        }
      }
    },
    [value],
  );

  const onBlur = useCallback((): void => {
    setOpen(false);
  }, []);

  const results = useMemo(() => {
    const _results = proPresentations.map((proPresentation) => {
      return {
        id: proPresentation.id,
        title: `${proPresentation.name}`,
        mainTrade: `${proPresentation.mainTrade?.name}`,
        proViews: proPresentation.proViews,
      };
    });

    if (search) {
      _results.unshift({
        id: -1,
        title: `Afficher tous les résultats contenant '${value}'`,
        mainTrade: '',
        proViews: [],
      });
    }

    return _results;
  }, [proPresentations, search, value]);

  return (
    <ProPresentationSearchFilterView
      defaultValue={defaultValue}
      value={value ?? ''}
      loading={loading}
      onBlur={onBlur}
      onClick={onClick}
      onKeyPress={onKeyPress}
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      open={open}
      results={results}
    />
  );
};

export default ProPresentationSearchFilterContainer;
