import { bboxPolygon } from '@turf/turf';
import { useCityFieldBilikZoneQueryLazyQuery } from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { Geometry } from 'geojson';

import { CityFieldContainer } from './city-field-container';

type CityFieldRepositoryProps = {
  label?: string;
  labelCoordinates?: string;
  helpText?: string;
  buttonText?: string;
  bilikZoneId?: number | null;
  cityFieldName: string;
  geometryFieldName: string;
  onPlaceSelected?: (value) => void;
  shouldDisplayCities?: boolean;
  drawMode: 'Point' | 'Polygon';
};

export const CityFieldRepository: FunctionComponent<
  CityFieldRepositoryProps
> = ({
  label,
  labelCoordinates,
  bilikZoneId,
  helpText,
  buttonText,
  cityFieldName,
  geometryFieldName,
  onPlaceSelected,
  shouldDisplayCities = true,
  drawMode,
}) => {
  const [getBilikZone, { data: bilikZone }] =
    useCityFieldBilikZoneQueryLazyQuery();

  const fetchBilikZone = useCallback(async () => {
    getBilikZone({
      variables: {
        id: bilikZoneId ?? 0,
      },
    });
  }, [bilikZoneId]);

  const getCityRectangle = useCallback(
    async (cityName: string): Promise<Geometry> => {
      const geoCoder = new google.maps.Geocoder();
      let bounds;

      await geoCoder.geocode(
        { address: cityName, componentRestrictions: { country: 'FR' } },
        (results, status) => {
          if (status === 'OK') {
            bounds = results[0].geometry.viewport;
          }
        },
      );

      // Convert bounds to bbox
      return bboxPolygon([
        bounds?.getSouthWest().lng(),
        bounds?.getSouthWest().lat(),
        bounds?.getNorthEast().lng(),
        bounds?.getNorthEast().lat(),
      ]).geometry;
    },
    [],
  );

  return (
    <CityFieldContainer
      label={label}
      labelCoordinates={labelCoordinates}
      helpText={helpText}
      fetchBilikZone={fetchBilikZone}
      bilikZone={bilikZone}
      cityFieldName={cityFieldName}
      buttonText={buttonText}
      geometryFieldName={geometryFieldName}
      onPlaceSelected={onPlaceSelected}
      shouldDisplayCities={shouldDisplayCities}
      getCityRectangle={getCityRectangle}
      drawMode={drawMode}
    />
  );
};
