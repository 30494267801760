import React, { FunctionComponent, useCallback } from 'react';

import TradeGroupSearchFilterContainer from './trade-group-search-filter-container';
import {
  useTradeGroupSearchFilterDefaultQuery,
  useTradeGroupSearchFilterQueryLazyQuery,
} from 'generated/graphql';
import { SearchResultData } from 'semantic-ui-react';

type Props = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  tradeGroupId?: number;
  onDelete?: () => void;
};

const TradeGroupSearchFilterRepository: FunctionComponent<Props> = ({
  onResultSelect,
  tradeGroupId,
  onDelete,
}) => {
  const [tradeGroupSearchFilterQuery, { data }] =
    useTradeGroupSearchFilterQueryLazyQuery();

  const fetchTradeGrous = useCallback(
    async (criteria?: string) => {
      const variables = {
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        },
      };
      await tradeGroupSearchFilterQuery(variables);
    },
    [tradeGroupSearchFilterQuery],
  );

  const { data: defaultTradeGroupData } = useTradeGroupSearchFilterDefaultQuery(
    {
      variables: {
        id: tradeGroupId || 0, // TODO works, but is hacky
      },
      skip: !tradeGroupId,
    },
  );

  return (
    <TradeGroupSearchFilterContainer
      onDelete={onDelete}
      onResultSelect={onResultSelect}
      tradeGroups={data?.searchTradeGroup || []}
      fetchTradeGroups={fetchTradeGrous}
      defaultValue={defaultTradeGroupData?.tradeGroupByPk?.name}
    />
  );
};

export default TradeGroupSearchFilterRepository;
