import {
  addMonths,
  compareAsc,
  differenceInMonths,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
} from 'date-fns';
import { ProReviewsCountByDateQuery } from 'generated/graphql';
import { map } from 'remeda';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Container, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ProStatsProReviewInvitationsView } from './pro-stats-pro-reviews-view';

type Props = {
  countProReviewsByMonth?: ProReviewsCountByDateQuery['countProReviewsWithStatusOkByMonthByProPresentation'];
  startDate: Date;
  endDate: Date;
};

export const ProStatsProReviewsContainer: FunctionComponent<Props> = ({
  countProReviewsByMonth,
  startDate,
  endDate,
}) => {
  const [chartData, setChartData] = useState<
    ProReviewsCountByDateQuery['countProReviewsWithStatusOkByMonthByProPresentation']
  >();

  useEffect(() => {
    if (countProReviewsByMonth) {
      const numberOfMonthBetween: number = differenceInMonths(
        endOfMonth(endDate),
        startOfMonth(startDate),
      );

      const monthsNumber = Array.from(Array(numberOfMonthBetween + 2).keys());
      // Each months between startDate and EndDate
      const months = map(monthsNumber, (month) => addMonths(startDate, month));

      const newChartData: {
        month: string;
        date: Date;
        count: number;
      }[] = [];

      // Create object of date for empty month
      months.pop();
      months.forEach((month) => {
        const doesExist = countProReviewsByMonth.find((value) => {
          const valueDate = new Date(value.month);
          const monthDate = new Date(month);
          return isSameMonth(valueDate, monthDate);
        });

        if (!doesExist) {
          newChartData.push({
            month: format(month, 'MM/yyyy'),
            count: 0,
            date: month,
          });
        } else {
          newChartData.push({
            date: doesExist.month,
            count: doesExist.count,
            month: format(new Date(doesExist.month), 'MM/yyyy'),
          });
        }
      });

      //Sorting data
      newChartData.sort((a, b) =>
        compareAsc(new Date(a.date), new Date(b.date)),
      );

      setChartData(newChartData);
    }
  }, [countProReviewsByMonth, startDate, endDate]);

  const totalCount = useMemo(
    () =>
      chartData
        ?.map((value) => value.count)
        .reduce((acc, value) => acc + value),
    [chartData],
  );

  if (!chartData) {
    return (
      <Segment style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </Segment>
    );
  }

  if (chartData.length <= 0) {
    return (
      <Container fluid style={{ height: '576px' }}>
        <Header as="h2">Relances d&apos;avis</Header>
        <Message>Pas de stats</Message>
      </Container>
    );
  }

  return (
    <ProStatsProReviewInvitationsView
      chartData={chartData}
      totalCount={totalCount}
    />
  );
};
