import React, { FunctionComponent, useState } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEmailPreview from '../timeline-email-preview';
import TimelineEvent from '../timeline-event';
import { Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

const ProReviewCheckEndV1: FunctionComponent<ProReviewCheckEndProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Vérification avis terminée"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Vérification de l&apos;avis sur{' '}
        <strong>{event.actors.pro.proPresentation.name}</strong> terminée
      </h3>
      <TimelineEmailPreview
        eventDate={event.date}
        messageId={event.data.email?.messageId}
        link={{
          url: `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
          text: 'Voir avis',
        }}
      />
    </TimelineEvent>
  );
};

const ProReviewCheckEndV2: FunctionComponent<ProReviewCheckEndProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Vérification avis terminée"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Vérification de l&apos;avis sur{' '}
        <strong>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proPresentation.mainTrade?.name})
        </strong>{' '}
        terminée
      </h3>
      <TimelineEmailPreview
        eventDate={event.date}
        messageId={event.data.email?.messageId}
        link={{
          url: `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
          text: 'Voir avis',
        }}
      />
    </TimelineEvent>
  );
};

type ProReviewCheckEndProps = {
  event: MongoEvent;
};

const ProReviewCheckEnd: FunctionComponent<ProReviewCheckEndProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <ProReviewCheckEndV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewCheckEndV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewCheckEnd (version ${event.version})`}
    />
  );
};

export default ProReviewCheckEnd;
