import { ProPersonBoolExp, useProPeopleCountQuery } from 'generated/graphql';

type ProPeopleCounter = {
  count?: number;
  loading: boolean;
};

export const useProPeopleCount = (
  where?: ProPersonBoolExp,
): ProPeopleCounter => {
  const { data, loading } = useProPeopleCountQuery({
    variables: {
      where,
    },
  });

  return {
    loading,
    count: data?.proPersonAggregate.aggregate?.count,
  };
};
