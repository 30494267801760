import { FunctionComponent, useCallback, useState } from 'react';
import React from 'react';
import { Button, Divider, Label, Modal, Segment } from 'semantic-ui-react';
import { apiClient } from 'axios-client';
import { CallRecording } from 'components/call-recording/call-recording';

interface CallTranscriptionProps {
  conversationUuid?: string;
}

export const CallTranscription: FunctionComponent<CallTranscriptionProps> = ({
  conversationUuid,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transcription, setTranscription] = useState<string | null>(null);
  const [analyse, setAnalyse] = useState<string | null>(null);

  const handleOpen = useCallback(async () => {
    setTranscription(null);
    setLoading(true);
    if (!conversationUuid) return;

    const response = await apiClient
      .get(`/event/transcription/${conversationUuid}`)
      .catch((error) => {
        setLoading(false);
        setOpen(true);
        throw error;
      });

    setOpen(true);
    setTranscription(response.data);
    setLoading(false);
  }, [conversationUuid]);

  const handleAnalyse = useCallback(async () => {
    if (!transcription) return;

    setLoading(true);

    const response = await apiClient.post('/form/openai/call-analyze', {
      transcription,
    });

    setAnalyse(response.data);
    setLoading(false);
  }, [transcription]);

  return (
    <>
      <Button loading={loading} icon="file" onClick={handleOpen} />
      <Modal
        size="small"
        open={open}
        onClose={() => setOpen(false)}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Content>
          {transcription ? (
            <>
              <Divider horizontal>Transcription</Divider>
              <Segment inverted>{transcription}</Segment>
              {!analyse ? (
                <Button loading={loading} onClick={handleAnalyse}>
                  Analyse GPT
                </Button>
              ) : (
                <>
                  <Divider horizontal>Analyze</Divider>
                  <Segment inverted>{analyse}</Segment>
                </>
              )}
            </>
          ) : (
            <Label>Indisponible</Label>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};
