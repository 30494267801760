import React, { FunctionComponent } from 'react';
import { Serie } from '@nivo/line';

import DefaultGraphView from '../default-graph-view';

type Props = {
  data: Serie[];
};

const SmsReceivedGraphView: FunctionComponent<Props> = ({ data }) => {
  return <DefaultGraphView data={data} colors={['#5500FF', '#A87CFF']} />;
};

export default SmsReceivedGraphView;
