interface Pro {
  id: number;
  mainTrade: {
    id?: number;
    tradeGuidesByFirstTrade?: {
      name: string;
      firstTradeId: number;
      secondTradeId: number;
    }[];
    tradeGuidesBySecondTrade?: {
      name: string;
      firstTradeId: number;
      secondTradeId: number;
    }[];
  };
  secondaryTradeId?: number;
}

const extractTradeGuideName = (pro: Pro): string | undefined => {
  // Extract mainTrade and secondaryTrade from pro.proPresentation
  const mainTrade = pro.mainTrade;
  const secondaryTradeId = pro.secondaryTradeId;

  const tradeGuides =
    mainTrade?.tradeGuidesByFirstTrade?.concat(
      mainTrade?.tradeGuidesBySecondTrade || [],
    ) ?? [];

  // Find the tradeGuide that matches the pro's mainTrade and secondaryTrade
  // By comparing the ids (order doesn't matter)
  const tradeGuideName =
    tradeGuides?.length && secondaryTradeId
      ? tradeGuides.find((tradeGuide) => {
          const { firstTradeId, secondTradeId } = tradeGuide;
          const { id: mainId } = mainTrade;

          const isFirstTradeMatch =
            mainId === firstTradeId && secondaryTradeId === secondTradeId;
          const isSecondTradeMatch =
            mainId === secondTradeId && secondaryTradeId === firstTradeId;

          return isFirstTradeMatch || isSecondTradeMatch;
        })?.name
      : undefined;

  return tradeGuideName;
};

export default extractTradeGuideName;
