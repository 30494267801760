import React, { FunctionComponent } from 'react';
import { Label, Popup } from 'semantic-ui-react';

type ProReviewInvitationCheckerIndicatorViewProps = {
  wasInitiatedWithProReviewInvitation: boolean;
  hasPiBeenInvitedByOtherPros: boolean;
  openPiTimeline: () => void;
  openProReviewInvitations: () => void;
};
export const ProReviewInvitationCheckerIndicatorView: FunctionComponent<
  ProReviewInvitationCheckerIndicatorViewProps
> = ({
  wasInitiatedWithProReviewInvitation,
  hasPiBeenInvitedByOtherPros,
  openPiTimeline,
  openProReviewInvitations,
}) => {
  if (wasInitiatedWithProReviewInvitation) {
    return (
      <Label size="mini" color="green">
        Connu
      </Label>
    );
  }

  if (hasPiBeenInvitedByOtherPros) {
    return (
      <Popup
        content="Ce particulier a été invité à faire un avis par d'autre pros"
        trigger={
          <Label
            onClick={openPiTimeline}
            style={{ cursor: 'pointer' }}
            size="mini"
            color="red"
          >
            Verifier
          </Label>
        }
      />
    );
  }

  return (
    <Popup
      content="Pas de demande d'avis effectué avec cette adresse email. Pense à désactiver les relances si une demande d'avis a été effectué avec une autre adresse email."
      trigger={
        <Label
          as="a"
          size="mini"
          color="orange"
          onClick={openProReviewInvitations}
        >
          Vérifier
        </Label>
      }
    />
  );
};
