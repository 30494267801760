import { Loader } from 'semantic-ui-react';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Exact, StopSmsQuery } from 'generated/graphql';
import { StopSmsView } from './stop-sms-view';
import { ToastSuccess } from 'utils/toast';
import { QueryResult } from '@apollo/client';

type StopSmsContainerProps = {
  telephone?: string;
  triggerNotStopped: React.ReactElement;
  triggerStopped: React.ReactElement;
  stopSmss?: StopSmsQuery['stopSms'];
  fetchStopSms: (telephones: string[]) => Promise<
    QueryResult<
      StopSmsQuery,
      Exact<{
        telephones?: string | string[] | undefined;
      }>
    >
  >;
  insertStopSms: (stopSms: string) => Promise<unknown>;
  deleteStopSms: (stopSms: string) => Promise<unknown>;
  onActionTriggered?: () => void;
};

export const StopSmsContainer: FunctionComponent<StopSmsContainerProps> = ({
  telephone,
  triggerNotStopped,
  triggerStopped,
  stopSmss,
  fetchStopSms,
  insertStopSms,
  deleteStopSms,
  onActionTriggered,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStopped, setStopped] = useState<boolean>();
  const [telephones, setTelephones] = useState<string[]>([]);
  const [trigger, setTrigger] = useState<ReactElement>();
  const [isLoading, setLoading] = useState<boolean>(false);

  //search if entity is blocked in database and set telephone
  useEffect(() => {
    const newTelephones: string[] = [];

    if (telephone) {
      newTelephones.push(telephone);
    }
    fetchStopSms(newTelephones);
    setTelephones(newTelephones);
  }, [telephone]);

  //Set trigger if telephone can receive sms or not
  useEffect(() => {
    if (stopSmss) {
      if (stopSmss.length == 0) {
        triggerNotStopped = React.cloneElement(triggerNotStopped, {
          key: 'unStopped',
          onClick: () => setIsModalOpen(true),
        });
        setTrigger(triggerNotStopped);
        setStopped(false);
      } else {
        triggerStopped = React.cloneElement(triggerStopped, {
          key: 'stopped',
          onClick: () => setIsModalOpen(true),
        });
        setTrigger(triggerStopped);
        setStopped(true);
      }
    }
  }, [stopSmss, isStopped]);

  // this Callback is triggered when user confirm the stopped sms action
  const stopTelephone = useCallback(async () => {
    setLoading(true);
    for (const telephone of telephones) {
      await insertStopSms(telephone);
    }

    if (onActionTriggered) {
      onActionTriggered();
    }

    fetchStopSms(telephones);
    setIsModalOpen(false);
    ToastSuccess('Succès', 'SMS bloquées !');
    setLoading(false);
  }, [telephones]);

  //Callback triggered when user confirm the unblocked Action
  const unStopTelephone = useCallback(async () => {
    setLoading(true);
    for (const telephone of telephones) {
      await deleteStopSms(telephone);
    }

    if (onActionTriggered) {
      onActionTriggered();
    }

    fetchStopSms(telephones);
    setIsModalOpen(false);
    setLoading(false);
    ToastSuccess('Succès', 'SMS débloquées !');
  }, [telephones]);

  if (!stopSmss) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <StopSmsView
      isStopped={isStopped}
      telephone={telephone}
      closeModal={(): void => setIsModalOpen(false)}
      stopTelephone={stopTelephone}
      unStopTelephone={unStopTelephone}
      stopSmss={stopSmss}
      isModalOpened={isModalOpen}
      trigger={trigger}
      isLoading={isLoading}
    />
  );
};
