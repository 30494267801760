import { BilikZonesTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import BilikZonesTableView from './bilik-zones-table-view';

type BilikZonesTableContainerProps = {
  loading: boolean;
  bilikZones: BilikZonesTableQuery['bilikZone'];
};

const BilikZonesTableContainer: FunctionComponent<BilikZonesTableContainerProps> = ({
  loading,
  bilikZones,
}) => {
  if (!loading && !bilikZones.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  if (loading && !bilikZones.length) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return <BilikZonesTableView bilikZones={bilikZones} loading={loading} />;
};

export default BilikZonesTableContainer;
