import { Loader, Message, Table } from 'semantic-ui-react';
import React, { FunctionComponent, useMemo } from 'react';
import { useExpiredProHolidaysTableQuery } from 'generated/graphql';
import { formatDate } from 'utils';

type ExpiredProHolidaysTableProps = {
  proViewId: number;
};

const ExpiredProHolidaysTable: FunctionComponent<
  ExpiredProHolidaysTableProps
> = ({ proViewId }) => {
  const [yesterday] = useMemo(
    () => [new Date(new Date().setDate(new Date().getDate() - 1))],
    [],
  );

  const { data, loading } = useExpiredProHolidaysTableQuery({
    variables: {
      id: proViewId,
      yesterday: yesterday,
    },
  });

  const expiredProHolidays = useMemo(() => data?.proViewByPk?.proHolidays, [
    data,
  ]);

  if (loading) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return expiredProHolidays && expiredProHolidays.length > 0 ? (
    <Table striped celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date de début</Table.HeaderCell>
          <Table.HeaderCell>Date de fin</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {expiredProHolidays.map((expiredProHoliday, index) => (
          <Table.Row key={index}>
            <Table.Cell>{formatDate(expiredProHoliday.startDate)}</Table.Cell>
            <Table.Cell>{formatDate(expiredProHoliday.endDate)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Message>Aucun congés expirés</Message>
  );
};

export default ExpiredProHolidaysTable;
