import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import env from 'env';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

type SolicitationDeclinedProps = {
  event: MongoEvent;
};

const SolicitationDeclinedV1: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    color="red"
    icon="mail"
    title="Déclin"
    initiator="pro"
  >
    <h3>
      Demande mail du particulier déclinée par{' '}
      <span>{event.initiator.pro.proPresentation.name}</span>
    </h3>
    <div>
      <p>
        <b>Raison du déclin : </b>
        {event.data.solicitation.declineReason}
      </p>
    </div>
    <div>
      <TimelineSolicitationActions solicitation={event.data.solicitation} />
    </div>
  </TimelineEvent>
);

const SolicitationDeclinedV2: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    color="red"
    icon="mail"
    title="Déclin"
    initiator="pro"
  >
    <h3>
      Demande mail du particulier déclinée par{' '}
      <span>
        {event.actors.pro.proPresentation.name} (
        {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
        {event.actors.pro.proPresentation.mainTrade?.name})
      </span>{' '}
    </h3>
    <div>
      <p>
        <b>Raison du déclin : </b>
        {event.data.solicitation.declineReason}
      </p>
    </div>
    <div>
      <TimelineSolicitationActions solicitation={event.data.solicitation} />
    </div>
  </TimelineEvent>
);

const SolicitationDeclined: FunctionComponent<SolicitationDeclinedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <SolicitationDeclinedV1 event={event} />;
  } else if (event.version === 2) {
    return <SolicitationDeclinedV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour SolicitationDeclined (version ${event.version})`}
    />
  );
};

export default SolicitationDeclined;
