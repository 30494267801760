import React, { FunctionComponent, useMemo } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { useCurrentBilikZone } from '../../hooks/use-current-bilik-zone/use-current-bilik-zone';

type SelectCurrentBilikZoneContainerProps = {
  loading: boolean;
  bilikZones: { id: number; name: string }[];
  onChange?: (zoneId: number) => void;
  value?: number;
  allAuthorizedZones?: boolean;
  disabled?: boolean;
};

export const SelectCurrentBilikZoneContainer: FunctionComponent<
  SelectCurrentBilikZoneContainerProps
> = (props) => {
  const { setCurrentBilikZone, currentBilikZone, loading } =
    useCurrentBilikZone();

  const { bilikZones, onChange, allAuthorizedZones, disabled } = props;

  const options: DropdownItemProps[] = useMemo(() => {
    const newOptions: DropdownItemProps[] = [];

    if (allAuthorizedZones) {
      newOptions.push({ value: 0, key: 0, text: 'Toutes les zones' });
    }

    bilikZones.map((bilikZone) =>
      newOptions.push({
        value: bilikZone.id,
        key: bilikZone.id,
        text: bilikZone.name,
      }),
    );

    return newOptions;
  }, [bilikZones, allAuthorizedZones]);

  return (
    <Dropdown
      button
      selection
      loading={loading}
      disabled={loading || disabled}
      onChange={(_, { value }): void => {
        if (onChange) {
          onChange(Number(value));
        }
        setCurrentBilikZone(Number(value));
        localStorage.setItem('currentBilikZoneId', Number(value)?.toString());
      }}
      value={currentBilikZone === null ? 0 : currentBilikZone?.id}
      options={options || []}
      placeholder={
        currentBilikZone
          ? 'Selectionnez une zone...'
          : 'Chargement des zones...'
      }
    />
  );
};
