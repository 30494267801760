import { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import Client from 'client';
import { SlugRedirect, SlugRedirectQuery } from 'generated/graphql';

export const useSlugInCircularDependency = (
  currentSlug: string,
  initialSlug: string,
): SlugRedirect[] => {
  const [circularDependency, setCircularDependency] = useState<SlugRedirect[]>(
    [],
  );

  const getSlugRedirect = useCallback(async (fromslug: string) => {
    const { data } = await Client.query<SlugRedirectQuery>({
      query: gql`
        query slugRedirectQuery($fromslug: String!) {
          slugRedirect(where: { fromslug: { _eq: $fromslug } }) {
            id
            fromslug
            toslug
          }
        }
      `,
      variables: {
        fromslug,
      },
      fetchPolicy: 'network-only',
    });

    return data.slugRedirect[0];
  }, []);

  const getCircularDependency = useCallback(
    async (currentSlug: string, initialSlug: string) => {
      const slugRedirects: SlugRedirect[] = [];

      do {
        const fromslug = slugRedirects.length
          ? slugRedirects[slugRedirects.length - 1].toslug
          : currentSlug;

        const slugRedirect = await getSlugRedirect(fromslug);
        if (!slugRedirect) {
          break;
        }

        slugRedirects.push(slugRedirect as SlugRedirect);

        // Circular dependency found
        if (
          slugRedirects.length &&
          (currentSlug === slugRedirects[slugRedirects.length - 1].toslug ||
            initialSlug === slugRedirects[slugRedirects.length - 1].toslug)
        ) {
          break;
        }
      } while (true);

      setCircularDependency(slugRedirects);
    },
    [setCircularDependency],
  );

  useEffect(() => {
    setCircularDependency([]);
    getCircularDependency(currentSlug, initialSlug);
  }, [currentSlug, initialSlug]);

  return circularDependency;
};
