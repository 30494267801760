import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Container,
  Divider,
  DropdownItemProps,
  Form,
  FormField as FormFieldUi,
  Select,
  Transition,
} from 'semantic-ui-react';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import ProTelephonesField from '../pro-telephones-field/pro-telephones-field';
import SelectProViewField from 'components/select-pro-view-field/select-pro-view-field';
import FormField from 'components/form-field/form-field';
import ProPeopleField from 'components/pro-people-field/pro-people-field';
import AccountsField from 'components/accounts-field/accounts-field';
import Label from 'components/label/label';
import ProPriceField from 'components/pro-price-field/pro-price-field';
import HelpText from 'components/help-text/help-text';
import TradeFields from 'components/trade-fields/trade-fields';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import { formatTelephone } from 'utils';

type ProViewRouteProps = {
  bilikZoneId: number;
  proViewId: number;
  proPresentationId: number;
};

const ProViewRoute: FunctionComponent<ProViewRouteProps> = ({
  bilikZoneId,
  proViewId,
  proPresentationId,
}) => {
  const { values, errors, touched, setFieldValue, initialValues } =
    useFormikContext<ProFormUpdateFields>();

  const [callTrackingOptions, setCallTrackingOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [callTrackingTargetValue, setCallTrackingTargetValue] =
    useState<number>();

  const [callTrackingForwardValue, setCallTrackingForwardValue] =
    useState<number>();

  const [accountEmailSuggestions, setAccountEmailSuggestion] =
    useState<string[]>();

  const [contactConfigAlert, setContactConfigAlert] = useState<{
    isModalOpen: boolean;
    lastConfigChanged: 'call' | 'solicitation' | null;
  }>({
    isModalOpen: false,
    lastConfigChanged: null,
  });

  useEffect(() => {
    const isContactDisabled =
      values.isCallsDisabled && values.isSolicitationsDisabled;
    const isUpdate =
      values.isCallsDisabled !== initialValues.isCallsDisabled ||
      values.isSolicitationsDisabled !== initialValues.isSolicitationsDisabled;

    setContactConfigAlert((prevState) => ({
      ...prevState,
      isModalOpen: isContactDisabled && isUpdate,
    }));
  }, [values.isCallsDisabled, values.isSolicitationsDisabled]);

  return (
    <>
      <Divider horizontal>Catégorie(s)</Divider>
      <HelpText>
        Les chiffres correspondent à : Nombre de pros de la zone assignées à la
        catégorie avec entre parenthèse ceux en catégorie principale et bis
      </HelpText>
      <TradeFields bilikZoneId={bilikZoneId} action="update-pro" />
      <Divider horizontal style={{ marginTop: '0px' }}>
        Contact(s)
      </Divider>
      <ProPeopleField
        name="proPeople"
        currentProViewId={proViewId}
        toastContent="Pense à enregistrer la fiche"
        onProPeopleLoaded={(proPeople): void => {
          // Get proPeople that have a phone number
          const proPeopleWithTelephone = proPeople.filter(
            (proPerson) =>
              proPerson.telephone !== undefined &&
              proPerson.telephone !== null &&
              proPerson.telephone !== '',
          );

          // Format and construct options
          const callTrackingOptions = proPeopleWithTelephone.map(
            (proPerson) => ({
              key: `call-tracking-options-${proPerson.id}`,
              text: (
                <>
                  {proPerson.givenName} {proPerson.familyName} -{' '}
                  {formatTelephone(proPerson.telephone || 'error')}
                </>
              ),
              property: {
                name: `${proPerson.givenName} ${proPerson.familyName}`,
                telephone: proPerson.telephone,
              },
              value: proPerson.id,
            }),
          );

          // Adding the custom option
          callTrackingOptions.push({
            key: `call-tracking-options-custom`,
            text: <>Personnalisé</>,
            property: {
              name: '',
              telephone: '',
            },
            value: 0,
          });

          // Set options
          setCallTrackingOptions(callTrackingOptions);

          // Check if current callTrackingTarget.telephone match one of our options
          // Return `undefined` if not
          const callTrackingTargetOptionValue = callTrackingOptions.filter(
            (option) =>
              option.property.telephone ===
              values.callTrackingTarget?.telephone,
          )[0]?.value;

          // Set the select value
          setCallTrackingTargetValue(
            callTrackingTargetOptionValue // ProPerson option
              ? callTrackingTargetOptionValue
              : values.callTrackingTarget?.telephone
              ? 0 // Custom option
              : undefined, // Never selected (create case)
          );

          // Check if current callTrackingForward.telephone match one of our options
          // Return `undefined` if not
          const currentCallTrackingForwardValue = callTrackingOptions.filter(
            (option) =>
              option.property.telephone ===
              values.callTrackingForward?.telephone,
          )[0]?.value;

          // Set the select value
          setCallTrackingForwardValue(
            currentCallTrackingForwardValue // ProPerson option
              ? currentCallTrackingForwardValue
              : values.callTrackingForward?.telephone
              ? 0 // Custom option
              : undefined, // Never selected (create case)
          );

          setAccountEmailSuggestion(
            proPeople
              .map((proPerson) => proPerson.email || 'null')
              .filter((proPersonEmail) => proPersonEmail !== 'null'),
          );
        }}
      />

      <Divider horizontal style={{ marginTop: '50px' }}>
        Accès espace pro
      </Divider>
      <AccountsField
        name="accounts"
        mainName="mainAccountId"
        toastContent="Pense à enregistrer la fiche"
        currentProViewId={proViewId}
        accountEmailSuggestions={accountEmailSuggestions}
      />
      <Divider horizontal style={{ marginTop: '50px' }}>
        Call tracking
      </Divider>
      <ProTelephonesField proViewId={proViewId} />
      <FormFieldUi>
        <Label required>Numéro de téléphone cible</Label>
        <Select
          onChange={(e, { value }): void => {
            // undefined/null check needed because of value `0` (custom choice)
            if (value !== undefined && value !== null) {
              const selectedOption = callTrackingOptions.filter(
                (option) => option.value === value,
              )[0];
              setFieldValue(
                'callTrackingTarget.telephone',
                selectedOption.property.telephone,
              );
              setFieldValue(
                'callTrackingTarget.name',
                selectedOption.property.name,
              );
              setCallTrackingTargetValue(selectedOption.value as number);
            }
          }}
          error={!!errors.callTrackingTarget && !!touched.callTrackingTarget}
          placeholder="Selectionnez un numéro..."
          options={callTrackingOptions}
          value={callTrackingTargetValue}
        />
      </FormFieldUi>
      <Transition.Group duration={250}>
        {callTrackingTargetValue === 0 ? (
          <Container fluid style={{ paddingLeft: '50px' }}>
            <Form.Group widths="equal">
              <FormField
                type="telephone"
                label="Numéro"
                name="callTrackingTarget.telephone"
                placeholder="Ex: 06 44 64 27 74"
                required
              />
              <FormField
                type="text"
                placeholder="Ex: Nom"
                label="Libéllé"
                name="callTrackingTarget.name"
                required
              />
            </Form.Group>
          </Container>
        ) : null}
      </Transition.Group>
      <Divider hidden />
      <FormField
        type="checkbox"
        name="callTrackingForward.isActive"
        label="Redirection d'appel"
        helpText="Permet de rediriger l'appel vers un autre numéro si pas de réponse"
      />
      <Transition.Group duration={250}>
        {values.callTrackingForward && values.callTrackingForward.isActive ? (
          <>
            <FormFieldUi>
              <Label required>Numéro de téléphone redirigé</Label>
              <Select
                onChange={(e, { value }): void => {
                  // Undefined/null check needed because value `0` need to pass
                  if (value !== undefined && value !== null) {
                    const selectedOption = callTrackingOptions.filter(
                      (option) => option.value === value,
                    )[0];
                    setFieldValue(
                      'callTrackingForward.telephone',
                      selectedOption.property.telephone,
                    );
                    setFieldValue(
                      'callTrackingForward.name',
                      selectedOption.property.name,
                    );
                    setCallTrackingForwardValue(selectedOption.value as number);
                  }
                }}
                placeholder="Selectionnez un numéro..."
                options={callTrackingOptions}
                value={callTrackingForwardValue}
              />
            </FormFieldUi>
            {callTrackingForwardValue === 0 ? (
              <Container fluid style={{ paddingLeft: '50px' }}>
                <Form.Group widths="equal">
                  <FormField
                    label="Numéro"
                    type="telephone"
                    name="callTrackingForward.telephone"
                    placeholder="Ex: 06 44 64 27 74"
                    required
                  />
                  <FormField
                    type="text"
                    placeholder="Ex: Nom"
                    label="Libéllé"
                    name="callTrackingForward.name"
                    required
                  />
                  <FormField
                    type="number"
                    placeholder="Ex: 12"
                    label="Temps avant redirection de l'appel (secondes)"
                    name="callTrackingForward.timeout"
                    required
                  />
                </Form.Group>
              </Container>
            ) : null}
          </>
        ) : null}
      </Transition.Group>
      <Divider horizontal style={{ marginTop: '50px' }}>
        Collègues
      </Divider>
      <SelectProViewField
        name="colleagues"
        label="Collègues"
        helpText="Collègues de Bilik avec qui le pro travaille (Même zone - Listé sur la fiche du pro)"
        filters={{
          exceptProPresentationId: proPresentationId,
          exceptStatus: ['archived'],
        }}
        multiple
        selection
        search
        clearable
      />
      <Divider hidden />
      <Divider hidden />
      <Divider horizontal>Tarif(s)</Divider>
      <ProPriceField />
      <FormField
        type="textarea"
        label="Commentaire"
        name="priceComment"
        placeholder="Ex: Pas de dépannage les nuits, samedis et dimanches."
        rows={3}
      />
      <Divider horizontal style={{ marginTop: '50px' }}>
        Paramètres
      </Divider>
      <FormField
        type="checkbox"
        name="isSolicitationsDisabled"
        label="Désactiver les demandes mails"
        helpText='Désactive les demandes mails vers le pro: le bouton "Envoyer un mail" deviens indisponible sur la fiche du pro'
        onChange={(e): void => {
          setContactConfigAlert({
            ...contactConfigAlert,
            lastConfigChanged: 'solicitation',
          });
        }}
      />
      <FormField
        type="checkbox"
        name="isSmsDisabled"
        label="Désactiver les SMS"
        helpText="Désactive les SMS vers le pro : le particulier reçoit un sms qui le redirige vers le formulaire de demande mail"
      />
      <FormField
        type="checkbox"
        name="isCallsDisabled"
        label="Désactiver les appels téléphoniques"
        helpText="Désactive les appels vers le pro: le numéro de téléphone est caché sur la fiche du pro"
        onChange={(e): void => {
          setContactConfigAlert({
            ...contactConfigAlert,
            lastConfigChanged: 'call',
          });
        }}
      />
      <FormField
        type="checkbox"
        name="isCallAlertActivated"
        label="Activer les alertes d'appels par sms"
        helpText="Active les alertes d'appels par sms vers le pro: le pro reçoit un sms à chaque appel provenant de Bilik"
      />
      <FormField
        type="checkbox"
        name="isCallRecordable"
        label="Enregistrer les appels"
        helpText="Active l'enregistrement des appels, le pro peut écouter les appels reçus depuis son espace pro"
      />
      <Divider horizontal>Paramètrage du suivi</Divider>
      <FormField type="checkbox" name="hasContact" label="Relance contact" />
      <Transition.Group duration={250}>
        {values.hasContact ? (
          <Container fluid>
            <Form.Group widths="equal">
              <FormField
                noFastField
                disabled={!values.hasContact}
                type="number"
                placeholder="Ex: 2"
                label="Temps moyen de réponse (jours ouvrés)"
                helpText={
                  <>
                    Temps entre la demande et la programmation du mail de suivi
                    (2/4) - Avez-vous pu joindre ... ?
                  </>
                }
                name="averageResponseDays"
                required
                min={1}
              />
            </Form.Group>
          </Container>
        ) : null}
      </Transition.Group>
      <FormField type="checkbox" name="hasQuotation" label="Relance devis" />
      <Transition.Group duration={250}>
        {values.hasQuotation ? (
          <Container fluid>
            <Form.Group widths="equal">
              <FormField
                noFastField
                disabled={!values.hasQuotation}
                type="number"
                placeholder="Ex: 21"
                label="Temps moyen avant devis (jours)"
                helpText={
                  <>
                    Temps entre la demande et la programmation du mail de suivi
                    (3/4) - Avez-vous reçus le devis de ... ?
                  </>
                }
                name="averageQuotationDays"
                required
                min={2}
              />
            </Form.Group>
          </Container>
        ) : null}
      </Transition.Group>
      <FormField
        noFastField
        type="number"
        placeholder="Ex: 66"
        label="Temps moyen avant réalisation (jours)"
        helpText={
          <>
            Temps entre la demande et la programmation du mail de suivi (4/4) -
            La prestation de ... est-elle terminée ?
          </>
        }
        name="averageProjectDays"
        required
        min={3}
      />
      <ConfirmModal
        header="Confirmation"
        content={
          <>
            <p>Etes-vous sûr de vouloir désactiver :</p>
            <ul>
              <li>Les appels téléphoniques</li>
              <li>Les demandes mails</li>
            </ul>
            <p>Le pro risque de ne plus être contacté.</p>
          </>
        }
        modalOpen={contactConfigAlert.isModalOpen}
        onDecline={(): void => {
          if (contactConfigAlert.lastConfigChanged === 'call') {
            setFieldValue('isCallsDisabled', false);
          }
          if (contactConfigAlert.lastConfigChanged === 'solicitation') {
            setFieldValue('isSolicitationsDisabled', false);
          }
        }}
      />
    </>
  );
};

export default ProViewRoute;
