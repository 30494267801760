import { InvoiceStatus } from 'components/invoice-status/invoice-status';
import { clone } from 'remeda';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  Loader,
  Table,
  Label as LabelUi,
  Form,
  Input,
  Segment,
} from 'semantic-ui-react';
import { formatDate, ToastError } from 'utils';
import ExportSepaForm from './export-sepa-form/export-sepa-form';
import { Invoice, ProOrganizationInvoice } from './invoice.type';
import { apiClient } from 'axios-client';

type ExportSepaInvoicesTableContainerProps = {
  startDate: string;
  endDate: string;
  zohoOrganizationId: number;
};

const statusSortOrder = {
  draft: 1,
  unpaid: 2,
  partially_paid: 3,
  overdue: 4,
  viewed: 5,
  sent: 5,
};

export const ExportSepaInvoicesTableContainer: FunctionComponent<
  ExportSepaInvoicesTableContainerProps
> = ({ endDate, startDate, zohoOrganizationId }) => {
  const [invoices, setInvoices] = useState<Invoice[]>();

  useEffect(() => {
    const getInvoices = async (): Promise<void> => {
      const proOrganizationInvoices = await apiClient
        .get<ProOrganizationInvoice[]>(
          `/zoho/invoice?start_date=${startDate}&end_date=${endDate}&zoho_organization_id=${zohoOrganizationId}`,
        )
        .then((response) => response.data)
        .catch((error) => {
          ToastError('Erreur', 'Impossible de charger les factures');
          throw error;
        });
      setInvoices(
        proOrganizationInvoices
          .filter(
            (zohoInvoice) =>
              zohoInvoice.status !== 'paid' && zohoInvoice.status !== 'void',
          )
          .map((zohoInvoice) => {
            const hasMissingInformation = !(
              zohoInvoice.dateOfMandate &&
              zohoInvoice.bic &&
              zohoInvoice.iban
            );

            return {
              ...zohoInvoice,
              customAppliedAmount: zohoInvoice.balance,
              hasMissingInformation,
              hide: hasMissingInformation,
            };
          })
          .sort((a, b) => statusSortOrder[a.status] - statusSortOrder[b.status])
          // Set invoices with missing information first
          .sort((a, b) => (a.hasMissingInformation ? -1 : 1)),
      );
    };
    getInvoices();
  }, [startDate, endDate, zohoOrganizationId]);

  if (!invoices) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  if (invoices.length === 0) {
    return (
      <Container style={{ marginTop: '50px' }} textAlign="center">
        Pas de donnée sur cette période.
      </Container>
    );
  }

  return (
    <>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Exclure</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>N° FAC</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Date Echéance</Table.HeaderCell>
            <Table.HeaderCell>Total HT</Table.HeaderCell>
            <Table.HeaderCell>Total HT Dû</Table.HeaderCell>
            <Table.HeaderCell>OK ?</Table.HeaderCell>
            <Table.HeaderCell>Montant à appliquer (TTC)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoices.map((invoice) => {
            return (
              <Table.Row key={`invoice-id-${invoice.invoice_id}`}>
                <Table.Cell>
                  <Checkbox
                    disabled={invoice.hasMissingInformation}
                    checked={invoice.hide}
                    onClick={(): void => {
                      // Block the user from using the invoice if it has missing information
                      if (invoice.hasMissingInformation) {
                        return;
                      }

                      const invoiceId = invoices.findIndex(
                        (value) => value.invoice_id === invoice.invoice_id,
                      );

                      const editInvoices = clone(invoices);

                      editInvoices[invoiceId].hide =
                        editInvoices[invoiceId].hide === undefined
                          ? true
                          : !editInvoices[invoiceId].hide;

                      setInvoices(editInvoices);
                    }}
                  />
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  <InvoiceStatus status={invoice.status} />
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {invoice.invoice_number}
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {invoice.customer_name}
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {formatDate(invoice.date)}
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {formatDate(invoice.due_date)}
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {invoice.total / 1.2} €
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {invoice.balance / 1.2} €
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  {invoice.hasMissingInformation ? (
                    <LabelUi color="red">Il manque des informations</LabelUi>
                  ) : (
                    <LabelUi color="green">Ok</LabelUi>
                  )}
                </Table.Cell>
                <Table.Cell disabled={invoice.hide}>
                  <Form>
                    <Input
                      fluid
                      type="number"
                      value={invoice.customAppliedAmount}
                      onChange={(e, { value }): void => {
                        const asNumber = Number(value);

                        if (isNaN(asNumber)) {
                          return;
                        }

                        const invoiceId = invoices.findIndex(
                          (value) => value.invoice_id === invoice.invoice_id,
                        );

                        const editInvoices = clone(invoices);
                        editInvoices[invoiceId].customAppliedAmount = asNumber;

                        setInvoices(editInvoices);
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Segment>
        <p>
          Si il manque des informations dans l&apos;entreprise du pro (iban,
          bic, date de mandat, etc), la colomne &quot;OK&quot; ? aura un label
          rouge.
        </p>
        <ExportSepaForm
          invoices={invoices}
          zohoOrganizationId={zohoOrganizationId}
        />
      </Segment>
    </>
  );
};
