import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Message } from 'semantic-ui-react';
import Raw from 'components/raw/raw';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

type SolicitationFollowedUpProps = {
  event: MongoEvent;
};

const SolicitationFollowedUpV1: FunctionComponent<
  SolicitationFollowedUpProps
> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      color={'red'}
      title={
        event.data.type === 'contact'
          ? 'Relance prise de contact'
          : 'Relance devis'
      }
      initiator="particulier"
    >
      <h3>
        Le particulier a relancé{' '}
        <span>{event.actors.pro.proPresentation.name}</span> (
        {event.data.solicitation.proView.proPerson.givenName}{' '}
        {event.data.solicitation.proView.proPerson.familyName}) pour{' '}
        {event.data.type === 'contact' ? ' la prise de contact' : ' le devis'}
      </h3>
    </TimelineEvent>
  );
};

const SolicitationFollowedUpV2: FunctionComponent<
  SolicitationFollowedUpProps
> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      color={'red'}
      icon="mail"
      title={
        event.data.type === 'contact'
          ? 'Relance prise de contact'
          : event.data.type === 'quotation'
          ? 'Relance devis'
          : 'Relance personnalisée'
      }
      initiator="particulier"
    >
      <h3>
        Le particulier a relancé (
        {event.data.solicitation.proView.proPerson.givenName}{' '}
        {event.data.solicitation.proView.proPerson.familyName}){' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>{' '}
        pour {event.data.type === 'other' ? ' une raison personnalisée' : ''}
        {event.data.type === 'contact' ? ' la prise de contact' : ''}
        {event.data.type === 'quotation' ? ' le devis' : ''}
      </h3>
      <div>
        {event.data.reason && (
          <p>
            {' '}
            <b>Raison de la relance: </b>
            <br />
            <Raw>{event.data.reason}</Raw>
          </p>
        )}
      </div>
    </TimelineEvent>
  );
};

const SolicitationFollowedUpV3: FunctionComponent<
  SolicitationFollowedUpProps
> = ({ event }) => {
  const communicationChannel = event.data.communicationChannel;
  const icon = communicationChannel === 'sms' ? 'mobile alternate' : 'mail';

  return (
    <TimelineEvent
      date={event.date}
      color={'red'}
      icon={icon}
      title={
        event.data.type === 'contact'
          ? 'Relance prise de contact'
          : event.data.type === 'quotation'
          ? 'Relance devis'
          : 'Relance personnalisée'
      }
      initiator="particulier"
    >
      <h3>
        Le particulier a relancé{' '}
        <b>{communicationChannel === 'sms' ? 'par SMS ' : 'par mail '}</b>{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>{' '}
        pour {event.data.type === 'other' ? ' une raison personnalisée' : ''}
        {event.data.type === 'contact' ? ' la prise de contact' : ''}
        {event.data.type === 'quotation' ? ' le devis' : ''}
      </h3>
      <div>
        {event.data.solicitation.subject ? (
          <>
            <b>Sujet : </b>
            <Raw>{event.data.solicitation.subject}</Raw>
            <br />
            <br />
          </>
        ) : null}
        <b>Description du projet : </b>
        <br />
        <Raw>{event.data.solicitation.message}</Raw>
      </div>
      {event.data.reason && (
        <div>
          <p>
            {' '}
            <b>Raison de la relance: </b>
            <br />
            <Raw>{event.data.reason}</Raw>
          </p>
        </div>
      )}
    </TimelineEvent>
  );
};

// V4 => Solicitation has only subject and message
// v5 => Solicitation has all solicitation object (used to count SolicitationFollowedUp in follow-up process)
const SolicitationFollowedUpV4Or5: FunctionComponent<
  SolicitationFollowedUpProps
> = ({ event }) => {
  const communicationChannel = event.data.communicationChannel;
  const icon = communicationChannel === 'sms' ? 'mobile alternate' : 'mail';

  return (
    <TimelineEvent
      date={event.date}
      color={'red'}
      icon={icon}
      title={
        event.data.type === 'contact'
          ? 'Relance prise de contact'
          : event.data.type === 'quotation'
          ? 'Relance devis'
          : 'Relance personnalisée'
      }
      initiator="particulier"
    >
      <h3>
        Le particulier a relancé{' '}
        {communicationChannel === 'sms' ? 'par SMS ' : 'par mail '}{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>{' '}
        pour {event.data.type === 'other' ? ' une raison personnalisée' : ''}
        {event.data.type === 'contact' ? ' la prise de contact' : ''}
        {event.data.type === 'quotation' ? ' le devis' : ''}
      </h3>
      <div>
        <TimelineSolicitationActions solicitation={event.data.solicitation} />
      </div>
    </TimelineEvent>
  );
};

const SolicitationFollowedUp: FunctionComponent<
  SolicitationFollowedUpProps
> = ({ event }) => {
  if (event.version === 1) {
    return <SolicitationFollowedUpV1 event={event} />;
  } else if (event.version === 2) {
    return <SolicitationFollowedUpV2 event={event} />;
  } else if (event.version === 3) {
    return <SolicitationFollowedUpV3 event={event} />;
  } else if (event.version === 4 || event.version === 5) {
    return <SolicitationFollowedUpV4Or5 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour SolicitationFollowedUp (version ${event.version})`}
    />
  );
};

export default SolicitationFollowedUp;
