import { useKeycloakDiffCardQueryLazyQuery } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Card, Grid, Icon, Modal, Table } from 'semantic-ui-react';
import { getUsers } from 'utils';

type KeycloakDiffCardProps = Record<string, unknown>;
type ModalState = {
  open: boolean;
  data?: {
    userEmails: any[];
    acoountEmails: any[];
  };
};

// important : account entity is used in our app's database and user entity is used in our authentification management's database
export const KeycloakDiffCard: FunctionComponent<
  KeycloakDiffCardProps
> = ({}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    data: {
      userEmails: [],
      acoountEmails: [],
    },
  });
  const [getAccounts, { data: accounts }] = useKeycloakDiffCardQueryLazyQuery();

  const onClickKeycloakDiff = useCallback(async (): Promise<void> => {
    setLoading(true);
    getAccounts();
  }, [getAccounts]);

  const accountEmails = useMemo(
    () => accounts?.account.map((account) => account.email),
    [accounts?.account],
  );

  useEffect(() => {
    const run = async (): Promise<void> => {
      if (accountEmails) {
        // Getting all keycloak users
        const users: any[] = [];
        while (users.length % 100 === 0) {
          const length = users.length;
          users.push(...(await getUsers(users.length)));
          if (length === users.length) {
            break;
          }
        }

        // Extract keycloak emails
        const userEmails = users.map((account) => account.email);

        // Calculate the diffs
        const onlyInAccounts = accountEmails.filter(
          (element) => !userEmails.includes(element),
        );
        const onlyInUsers = userEmails.filter(
          (element) => !accountEmails.includes(element),
        );

        // stop loading and open the model with calculated data
        setLoading(false);
        setModalState({
          open: true,
          data: {
            userEmails: onlyInUsers,
            acoountEmails: onlyInAccounts,
          },
        });
      }
    };
    run();
  }, [accountEmails, loading]);

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="user" />
        <Card.Header>Keycloak Diff</Card.Header>
        <Card.Description>
          Outil de comparaison des comptes entre la database Keycloak et Bilik
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Modal
          size="large"
          open={modalState.open}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          closeIcon
          onClose={(): void => {
            setModalState({
              open: false,
              data: {
                userEmails: [],
                acoountEmails: [],
              },
            });
          }}
          trigger={
            <Button
              fluid
              primary
              onClick={onClickKeycloakDiff}
              loading={loading}
            >
              Executer
            </Button>
          }
        >
          <Modal.Header>Keycloak Diff</Modal.Header>
          <Modal.Content scrolling>
            <Grid>
              <Grid.Column>
                <Table celled basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>#</Table.HeaderCell>
                      <Table.HeaderCell>email</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Keycloak
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Bilik
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {modalState.data?.userEmails
                      .concat(modalState.data?.acoountEmails)
                      .sort()
                      .map((email, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width={1}>{index}</Table.Cell>
                          <Table.Cell>{email}</Table.Cell>
                          <Table.Cell textAlign="center">
                            {modalState.data?.userEmails.includes(email) ? (
                              <Icon color="green" size="large" name="check" />
                            ) : (
                              <Icon size="large" color="red" name="times" />
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {modalState.data?.acoountEmails.includes(email) ? (
                              <Icon color="green" size="large" name="check" />
                            ) : (
                              <Icon size="large" color="red" name="times" />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={(): void => {
                setModalState({
                  open: false,
                });
              }}
            >
              Fermer
            </Button>
          </Modal.Actions>
        </Modal>
      </Card.Content>
    </Card>
  );
};
