import { Icon, Label } from 'semantic-ui-react';
import React, { FunctionComponent, ReactNode } from 'react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { formatTime } from 'utils/locale';
import styled from 'styled-components';
import TimelineEventDevice from './timeline-event-device';
import { capitalizeName } from 'utils/capitalize';

const ItemContent = styled.div`
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 100px;
  padding: 0;
  position: relative;

  span.right {
    color: #999;
    float: right;
    padding: 10px;
    font-size: 12px;
  }

  a:not(.label) {
    text-decoration: underline;
  }

  h3 {
    margin: 0;
    color: #555;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    font-size: 16px;
    line-height: 1.1;

    span {
      font-weight: 600;
    }
  }

  div {
    padding: 10px;
  }
`;

const Item = styled.li`
  position: relative;
  margin-bottom: 15px;
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: white;
  border: 4px solid #3c8dbc;
  border-radius: 50%;
  position: absolute;
  left: 23px;
  top: 8px;
`;

type Initiator = 'bilik-user' | 'bilik-bot' | 'particulier' | 'pro';
type IconName =
  | 'mail'
  | 'phone'
  | 'mobile alternate'
  | 'warning'
  | 'comment'
  | 'comment outline'
  | 'ticket alternate'
  | 'dont';

type EventProps = {
  color?: SemanticCOLORS;
  date: string;
  userAgent?: string | null;
  callForwarded?: boolean;
  icon?: IconName;
  title?: string;
  initiator?: Initiator;
  bilikUserName?: string;
  takingContact?: boolean;
  children: ReactNode;
};

const TimelineEvent: FunctionComponent<EventProps> = ({
  color = 'blue',
  date,
  userAgent = null,
  children,
  callForwarded = false,
  icon,
  title,
  initiator,
  bilikUserName,
  takingContact = false,
}) => {
  const getInitiatorName = (initiator: Initiator): string => {
    if (initiator === 'bilik-user') {
      return `Bilik - ${bilikUserName ?? 'User'}`;
    }
    if (initiator === 'bilik-bot') {
      return 'Bilik - Bot';
    }

    return capitalizeName(initiator);
  };

  return (
    <Item>
      <Circle />
      <div
        style={{
          position: 'absolute',
          left: '25px',
          top: '8px',
          marginLeft: '20px',
        }}
      >
        <span
          style={{
            marginLeft: '5px',
            fontWeight: 'bold',
          }}
        >
          {formatTime(date)}
        </span>
      </div>
      <ItemContent>
        <span className="right">
          {callForwarded ? <Label>Appel redirigé</Label> : null}
          <TimelineEventDevice userAgent={userAgent} />
        </span>
        <div>
          <Label size="large" color={color}>
            <Icon
              name={icon}
              style={{
                fontSize: '0.85em',
              }}
            />
            {title}
          </Label>
          {initiator && (
            <Label size="large" color="grey">
              {getInitiatorName(initiator)}
            </Label>
          )}{' '}
          {takingContact && (
            <Label size="large" color="green">
              Prise de contact
            </Label>
          )}
        </div>

        {children}
      </ItemContent>
    </Item>
  );
};

export default TimelineEvent;
