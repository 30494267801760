import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { RefuseProReviewForm } from 'components/refuse-pro-review-form';
import CheckButtonContainer from 'pages/pro-reviews/check-button/check-button';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Button, Divider, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { formatDateTime, ToastError, ToastSuccess } from 'utils';
import { ReviewModalMetadata } from './pro-review-actions-modal-repository';
import Raw from 'components/raw/raw';
import { Commands, triggerCommand } from 'utils/commands/command';
import { DuplicateProReviewForm } from 'components/duplicate-pro-review-form';
import { Formik } from 'formik';
import { ProReviewActionModalCityView } from './pro-review-action-modal-city-view';
import * as Yup from 'yup';
import { ProReviewSetInput } from 'generated/graphql';
import { ReplyProReviewForm } from 'components/reply-pro-review-form';
import FormField from 'components/form-field/form-field';
import HelpText from 'components/help-text/help-text';
import { addCrs } from 'utils/geometry';

type ProReviewActionsModalContainerProps = {
  modalMetadata: ReviewModalMetadata;
  closeModal: () => void;
  refetch: () => void;
  onUpdateProReview: (
    proReviewId: number,
    proReviewSetInput: ProReviewSetInput,
  ) => Promise<void>;
};

export const ProReviewActionsModalContainer: FunctionComponent<
  ProReviewActionsModalContainerProps
> = ({ modalMetadata, closeModal, refetch, onUpdateProReview }) => {
  const smsRef = useRef<HTMLDivElement>(null);

  const { currentBilikPerson } = useCurrentBilikPerson();

  const [isSubmit, setIsSubmit] = useState(false);

  if (modalMetadata.isOpen) {
    if (!modalMetadata.proReview) {
      return <p>No Pro Review on Modal Metadata</p>;
    }

    if (!currentBilikPerson) {
      return <p>No Current Bilik Person</p>;
    }

    return (
      <Modal
        open={modalMetadata.isOpen}
        onClose={(): void => {
          closeModal();
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        {modalMetadata.type === 'sms' ? (
          <>
            <Modal.Header>
              Relance SMS pour vérification de l&apos;avis
            </Modal.Header>
            <Modal.Content>
              <p>
                Si pas de réponse du particulier le SMS de relance suivant doit
                lui être envoyé :
              </p>
              <Message>
                <div ref={smsRef}>
                  <p>
                    Bonjour,
                    <br />
                    Vous avez réalisé un avis le{' '}
                    {formatDateTime(modalMetadata.proReview.dateCreated)} sur le
                    site Bilik relatif à la prestation de notre adhérent :{' '}
                    {modalMetadata.proReview.proPresentation.name}.<br />
                    <br />
                    Afin de vérifier la validité de votre avis et donc du lien
                    commercial, et conformément à nos Conditions Générales
                    d&apos;Utilisation, nous vous demandons de bien vouloir nous
                    communiquer une copie de la facture liée à cette prestation
                    <br />
                    <br />
                    Cette démarche a pour objectif de garantir la qualité de
                    tous les témoignages et retours concernant les
                    professionnels du Réseau Bilik et de nous assurer de la
                    qualité des professionnels présents au sein de notre réseau.
                    <br />
                    <br />
                    Toutes les informations collectées dans le cadre de la
                    vérification de la validité de votre avis seront traitées
                    par Bilik de façon confidentielle puis détruites à
                    l&apos;issue de la vérification ; les éventuelles données
                    personnelles seront traitées suivant notre Politique de
                    confidentialité.
                    <br />
                    Cordialement, <br />
                    {currentBilikPerson.givenName}{' '}
                    {currentBilikPerson.familyName} - Bilik
                  </p>
                </div>
              </Message>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Fermer
              </Button>
              <Button
                primary
                onClick={(): void => {
                  try {
                    const range = document.createRange();
                    const selection = window.getSelection();

                    if (selection && smsRef.current) {
                      range.selectNode(smsRef.current);
                      selection.removeAllRanges();
                      selection.addRange(range);
                      document.execCommand('copy');
                      ToastSuccess(
                        'Succès',
                        'Contenu du message copié dans le presse papier !',
                      );
                    }
                  } catch (error) {
                    ToastError('Erreur', 'Erreur lors de la copie du message');
                  }
                }}
              >
                <Icon name="copy outline" />
                Copier le message
              </Button>
            </Modal.Actions>
          </>
        ) : null}
        {modalMetadata.type === 'reply' ? (
          <>
            <Modal.Header>Répondre à l&apos;avis</Modal.Header>
            <Modal.Content>
              <p>
                <strong>Auteur</strong> <br />
                {modalMetadata.proReview.givenName}{' '}
                {modalMetadata.proReview.familyName}
              </p>
              <p>
                <strong>Nature de la prestation</strong>
                <br />
                {modalMetadata.proReview.reviewAspect}
              </p>
              <p>
                <strong>Commentaire public</strong>
                <br />
                <Raw>{modalMetadata.proReview.reviewBody}</Raw>
              </p>
              {modalMetadata.proReview.privateComment ? (
                <p>
                  <strong>Remarque privée</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.privateComment}</Raw>
                </p>
              ) : null}
              {modalMetadata.proReview.comment ? (
                <p>
                  <strong>Ce qui peut être amélioré</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.comment}</Raw>
                </p>
              ) : null}

              <Divider />
              <ReplyProReviewForm
                proReviewId={modalMetadata.proReview.id}
                onCompleted={(): void => {
                  refetch();
                  closeModal();
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Annuler
              </Button>
            </Modal.Actions>
          </>
        ) : null}

        {modalMetadata.type === 'reply-edit' ? (
          <>
            <Modal.Header>Modifier la réponse</Modal.Header>
            <Modal.Content>
              <p>
                <strong>Auteur</strong> <br />
                {modalMetadata.proReview.givenName}{' '}
                {modalMetadata.proReview.familyName}
              </p>
              <p>
                <strong>Nature de la prestation</strong>
                <br />
                {modalMetadata.proReview.reviewAspect}
              </p>
              <p>
                <strong>Commentaire public</strong>
                <br />
                <Raw>{modalMetadata.proReview.reviewBody}</Raw>
              </p>
              {modalMetadata.proReview.privateComment ? (
                <p>
                  <strong>Remarque privée</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.privateComment}</Raw>
                </p>
              ) : null}
              {modalMetadata.proReview.comment ? (
                <p>
                  <strong>Ce qui peut être amélioré</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.comment}</Raw>
                </p>
              ) : null}
              <Divider />
              <Formik
                validationSchema={Yup.object().shape({
                  reply: Yup.string().required('Réponse requise !'),
                })}
                validateOnChange={true}
                initialValues={{ reply: modalMetadata.proReview.reply ?? '' }}
                onSubmit={async (values): Promise<void> => {
                  if (modalMetadata.proReview) {
                    try {
                      await onUpdateProReview(modalMetadata.proReview.id, {
                        reply: values.reply,
                      });
                      ToastSuccess('Succès', 'Réponse mise à jour');
                      closeModal();
                    } catch (e) {
                      ToastError(
                        'Erreur',
                        'Une erreur est survenue lors de la mise à jour des coordonnées géographiques',
                      );
                    } finally {
                      setIsSubmit(false);
                    }
                  }
                }}
                onReset={(): void => {
                  closeModal();
                }}
              >
                {(props): React.ReactElement => (
                  <Form
                    onReset={props.handleReset}
                    onSubmit={props.handleSubmit}
                  >
                    <FormField
                      required
                      type="textarea"
                      label="Réponse"
                      name="reply"
                      rows={10}
                    />
                    <Button
                      primary
                      type="submit"
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting}
                    >
                      Enregistrer
                    </Button>
                    <HelpText>Ne déclenchera pas d&apos;emails</HelpText>
                  </Form>
                )}
              </Formik>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Annuler
              </Button>
            </Modal.Actions>
          </>
        ) : null}

        {modalMetadata.type === 'duplicate' ? (
          <>
            <Modal.Header>Dupliquer l&apos;avis</Modal.Header>
            <Modal.Content>
              <p>
                <strong>Auteur</strong> <br />
                {modalMetadata.proReview.givenName}{' '}
                {modalMetadata.proReview.familyName}
              </p>
              <p>
                <strong>Nature de la prestation</strong>
                <br />
                {modalMetadata.proReview.reviewAspect}
              </p>
              <p>
                <strong>Commentaire public</strong>
                <br />
                <Raw>{modalMetadata.proReview.reviewBody}</Raw>
              </p>
              {modalMetadata.proReview.privateComment ? (
                <p>
                  <strong>Remarque privée</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.privateComment}</Raw>
                </p>
              ) : null}
              {modalMetadata.proReview.comment ? (
                <p>
                  <strong>Ce qui peut être amélioré</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.comment}</Raw>
                </p>
              ) : null}
              <Divider />
              <DuplicateProReviewForm
                proReviewId={modalMetadata.proReview.id}
                currentProPresentationId={
                  modalMetadata.proReview.proPresentation.id
                }
                onCompleted={(): void => {
                  refetch();
                  closeModal();
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Annuler
              </Button>
            </Modal.Actions>
          </>
        ) : null}
        {modalMetadata.type === 'refuse' ? (
          <>
            <Modal.Header>Refuser l&apos;avis</Modal.Header>
            <Modal.Content>
              <p>
                <strong>Auteur</strong> <br />
                {modalMetadata.proReview.givenName}{' '}
                {modalMetadata.proReview.familyName}
              </p>
              <p>
                <strong>Nature de la prestation</strong>
                <br />
                {modalMetadata.proReview.reviewAspect}
              </p>
              <p>
                <strong>Commentaire public</strong>
                <br />
                <Raw>{modalMetadata.proReview.reviewBody}</Raw>
              </p>
              {modalMetadata.proReview.privateComment ? (
                <p>
                  <strong>Remarque privée</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.privateComment}</Raw>
                </p>
              ) : null}
              {modalMetadata.proReview.comment ? (
                <p>
                  <strong>Ce qui peut être amélioré</strong>
                  <br />
                  <Raw>{modalMetadata.proReview.comment}</Raw>
                </p>
              ) : null}
              <Divider />
              <RefuseProReviewForm
                proReviewId={modalMetadata.proReview.id}
                onCompleted={(): void => {
                  triggerCommand(Commands.REFETCH_REVIEW_COUNTER);
                  refetch();
                  closeModal();
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Annuler
              </Button>
            </Modal.Actions>
          </>
        ) : null}
        {modalMetadata.type === 'check' ? (
          <>
            <Modal.Header>Demande de vérification d&apos;avis</Modal.Header>
            <Modal.Content>
              <h4>Processus de vérification</h4>
              <ol>
                <li>
                  Un mail de demande de vérification va être envoyé au
                  particulier.
                </li>
                <li>
                  Appeler le particulier en laissant un message si besoin{' '}
                  <strong>dès que le mail de vérification est lancé</strong>
                </li>
                <li>
                  Si pas de réponse, faire une relance SMS (Action disponible
                  sur l&apos;avis).
                </li>
                <li>
                  Si pas de réponse, appeler de pro et lui demander copie du
                  devis ou de la facture.
                </li>
                <li>
                  Suite à cette vérification, valider ou refuser l&apos;avis en
                  conséquence, le pro et le particulier seront remerciés
                  automatiquement.
                </li>
              </ol>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Annuler
              </Button>
              <CheckButtonContainer
                proReviewId={modalMetadata.proReview.id}
                onCompleted={(): void => {
                  refetch();
                  closeModal();
                }}
              />
            </Modal.Actions>
          </>
        ) : null}
        {modalMetadata.type === 'city' ? (
          <>
            <Modal.Header>
              Coordonnées géographiques du particulier
            </Modal.Header>
            <Modal.Content>
              <Formik
                initialValues={{
                  addressLocality: modalMetadata.proReview.addressLocality,
                  geoCoordinates: modalMetadata.proReview.geoCoordinates,
                }}
                onSubmit={async (values): Promise<void> => {
                  if (modalMetadata.proReview) {
                    try {
                      await onUpdateProReview(modalMetadata.proReview.id, {
                        geoCoordinates: addCrs(values.geoCoordinates),
                        addressLocality: values.addressLocality,
                      });

                      ToastSuccess(
                        'Succès',
                        'Coordonnées géographiques mises à jour',
                      );

                      closeModal();
                    } catch (e) {
                      ToastError(
                        'Erreur',
                        'Une erreur est survenue lors de la mise à jour des coordonnées géographiques',
                      );
                    } finally {
                      setIsSubmit(false);
                    }
                  }
                }}
                onReset={(): void => {
                  closeModal();
                }}
                validationSchema={Yup.object().shape({
                  addressLocality: Yup.string().required('Ville requise !'),
                  geoCoordinates: Yup.object().required(
                    'Coordonnées requises ! (sélectionne une ville ou clique sur la carte)',
                  ),
                })}
              >
                <ProReviewActionModalCityView
                  submit={isSubmit}
                  onSubmit={(): void => {
                    setIsSubmit(true);
                  }}
                  onStopLoading={() => {
                    setIsSubmit(false);
                  }}
                />
              </Formik>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(): void => {
                  closeModal();
                }}
              >
                Annuler
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmit}
                loading={isSubmit}
                onClick={(): void => {
                  setIsSubmit(true);
                }}
              >
                Valider
              </Button>
            </Modal.Actions>
          </>
        ) : null}
      </Modal>
    );
  }

  return null;
};
