import React, { Fragment, FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { pathOr } from 'remeda';

const FormCreateProReviewV1: FunctionComponent<FormCreateProReviewProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      userAgent={pathOr(
        event,
        ['initiator', 'pi', 'userAgent'] as unknown as [keyof MongoEvent],
        null,
      )}
      icon="comment"
      title="Avis à valider"
      initiator="particulier"
    >
      <h3>
        Nouvel avis reçu sur{' '}
        <span>{event.actors.pro.proPresentation.name}</span>
        {event.initiator.bilik ? (
          <Fragment>
            {' '}
            via{' '}
            <span>
              {event.initiator.bilik.givenName}{' '}
              {event.initiator.bilik.familyName}
            </span>
          </Fragment>
        ) : null}
      </h3>
      <div>
        <p>
          <b>Prestation : </b>
          {event.data.proReview.reviewAspect}
        </p>
        <p>
          <b>Commentaire public : </b>
          <br />
          {event.data.proReview.reviewBody}
        </p>
        {event.data.proReview.privateComment ? (
          <p>
            <b>Remarque privée : </b>
            <br />
            {event.data.proReview.privateComment}
          </p>
        ) : null}
        {event.data.proReview.comment ? (
          <p>
            <b>Ce qui peut être amélioré : </b>
            {event.data.proReview.comment}
          </p>
        ) : null}
      </div>
      <h3 />
      <div>
        <Label
          as="a"
          onClick={(): void => {
            window.open(
              `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
              '_blank',
            );
          }}
        >
          <Icon name="eye" size="small" />
          Voir avis
        </Label>
      </div>
    </TimelineEvent>
  );
};

const FormCreateProReviewV2: FunctionComponent<FormCreateProReviewProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      userAgent={pathOr(
        event,
        ['initiator', 'pi', 'userAgent'] as unknown as [keyof MongoEvent],
        null,
      )}
      icon="comment"
      title="Avis à valider"
      initiator="particulier"
    >
      <h3>
        Nouvel avis reçu sur{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>
        {event.initiator.bilik ? (
          <Fragment>
            {' '}
            via{' '}
            <span>
              {event.initiator.bilik.givenName}{' '}
              {event.initiator.bilik.familyName}
            </span>
          </Fragment>
        ) : null}
      </h3>
      <div>
        <p>
          <b>Prestation : </b>
          {event.data.proReview.reviewAspect}
        </p>
        <p>
          <b>Commentaire public : </b>
          <br />
          {event.data.proReview.reviewBody}
        </p>
        {event.data.proReview.privateComment ? (
          <p>
            <b>Remarque privée : </b>
            {event.data.proReview.privateComment}
          </p>
        ) : null}
        {event.data.proReview.comment ? (
          <p>
            <b>Ce qui peut être amélioré : </b>
            {event.data.proReview.comment}
          </p>
        ) : null}
      </div>
      <h3 />
      <div>
        <Label
          as="a"
          onClick={(): void => {
            window.open(
              `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
              '_blank',
            );
          }}
        >
          <Icon name="eye" size="small" />
          Voir avis
        </Label>
      </div>
    </TimelineEvent>
  );
};

type FormCreateProReviewProps = {
  event: MongoEvent;
};

const FormCreateProReview: FunctionComponent<FormCreateProReviewProps> = ({
  event,
}) => {
  if (event.version === 0 || event.version === 1) {
    return <FormCreateProReviewV1 event={event} />;
  } else if (event.version === 2) {
    return <FormCreateProReviewV2 event={event} />;
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour FormCreateProReview (version ${event.version})`}
    />
  );
};

export default FormCreateProReview;
