import React, { FunctionComponent } from 'react';
import { Segment } from 'semantic-ui-react';
import { Navigate } from 'react-router';
import ProPresentationRoute from './pro-presentation-route';
import ProViewRoute from './pro-view-route';
import ProMessagesRoute from './pro-messages-route';
import ProHolidaysRoute from './pro-holidays-route';
import ProGalleryRoute from './pro-gallery-route';
import ProStatsRoute from './pro-stats-route';
import ProOrganizationRoute from './pro-organization-route';
import ProCallsRoute from './pro-calls-route';
import ProReviewInvitationsRoute from './pro-review-invitations-route';
import ProReviewsRoute from './pro-reviews-route';
import ProSolicitationsRoute from './pro-solicitation-route';
import ProSmsReceivedRoute from './pro-sms-received-route';
import ProSectorsRoute from './pro-sectors-route';

type ProRouterProps = {
  bilikZoneId: number;
  proPresentationId: number;
  proOrganizationId?: number;
  proViewId: number;
  activeRoute?: string;
  hasProOrganizationChanged: boolean;
};

const ProRouter: FunctionComponent<ProRouterProps> = ({
  activeRoute,
  proViewId,
  bilikZoneId,
  proOrganizationId,
  proPresentationId,
  hasProOrganizationChanged,
}) => {
  switch (activeRoute) {
    // Main route
    case undefined:
      return (
        <Segment clearing>
          <ProViewRoute
            proViewId={proViewId}
            bilikZoneId={bilikZoneId}
            proPresentationId={proPresentationId}
          />
        </Segment>
      );
    case 'presentation':
      return (
        <Segment clearing>
          <ProPresentationRoute
            proViewId={proViewId}
            bilikZoneId={bilikZoneId}
            proPresentationId={proPresentationId}
          />
        </Segment>
      );
    case 'sectors':
      return (
        <Segment clearing>
          <ProSectorsRoute
            bilikZoneId={bilikZoneId}
            proViewId={proViewId}
            proPresentationId={proPresentationId}
          />
        </Segment>
      );
    case 'gallery':
      return (
        <Segment clearing>
          <ProGalleryRoute />
        </Segment>
      );
    case 'organization':
      return (
        <Segment clearing>
          <ProOrganizationRoute
            proViewId={proViewId}
            proOrganizationId={proOrganizationId}
            bilikZoneId={bilikZoneId}
            hasProOrganizationChanged={hasProOrganizationChanged}
          />
        </Segment>
      );
    case 'messages':
      return <ProMessagesRoute />;
    case 'holidays':
      return <ProHolidaysRoute proViewId={proViewId} />;
    case 'stats':
      return (
        <ProStatsRoute
          proViewId={proViewId}
          proPresentationId={proPresentationId}
        />
      );
    case 'calls':
      return <ProCallsRoute proViewId={proViewId} />;
    case 'review_invitations':
      return (
        <ProReviewInvitationsRoute
          proPresentationId={proPresentationId}
          proViewId={proViewId}
        />
      );
    case 'pro_reviews':
      return <ProReviewsRoute proPresentationId={proPresentationId} />;
    case 'solicitations':
      return (
        <ProSolicitationsRoute
          proPresentationId={proPresentationId}
          bilikZoneId={bilikZoneId}
        />
      );
    case 'sms-received':
      return <ProSmsReceivedRoute proViewId={proViewId} />;
    default:
      return <Navigate to="/404" replace />;
  }
};

export default ProRouter;
