import React, { FunctionComponent, useCallback, useMemo } from 'react';

import PrivateIndividualSearchContainer from './private-individual-search-container';
import {
  usePrivateIndividualSearchProReviewInvitationQueryLazyQuery,
  usePrivateIndividualSearchProReviewQueryLazyQuery,
  usePrivateIndividualSearchSolicitationQueryLazyQuery,
} from 'generated/graphql';
import { SearchResultData } from 'semantic-ui-react';

type PrivateIndividualSearchRepositoryProps = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  searchContext: 'solicitation' | 'proReview' | 'proReviewInvitation';
  proViewId?: number;
  proPresentationId?: number;
};

const PrivateIndividualSearchRepository: FunctionComponent<
  PrivateIndividualSearchRepositoryProps
> = ({ onResultSelect, searchContext, proViewId, proPresentationId }) => {
  const [privateIndividualSolicitationSearchQuery, { data: solicitationData }] =
    usePrivateIndividualSearchSolicitationQueryLazyQuery();

  const [privateIndividualProReviewSearchQuery, { data: proReviewData }] =
    usePrivateIndividualSearchProReviewQueryLazyQuery();

  const [
    privateIndividualProReviewInvitationSearchQuery,
    { data: proReviewInvitationData },
  ] = usePrivateIndividualSearchProReviewInvitationQueryLazyQuery();

  const fetchPrivateIndividuals = useCallback(
    async (criteria?: string) => {
      // also used as proReviewInvitationVariables
      const proReviewVariables = {
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
          where: {
            proPresentationId: {
              _eq: proPresentationId,
            },
          },
        },
      };

      const solicitationVariables = {
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
          where: {
            proViewId: { _eq: proViewId },
          },
        },
      };

      switch (searchContext) {
        case 'solicitation': {
          privateIndividualSolicitationSearchQuery(solicitationVariables);
          break;
        }
        case 'proReview': {
          privateIndividualProReviewSearchQuery(proReviewVariables);
          break;
        }
        case 'proReviewInvitation': {
          privateIndividualProReviewInvitationSearchQuery(proReviewVariables);
        }
      }
    },
    [
      privateIndividualProReviewSearchQuery,
      privateIndividualSolicitationSearchQuery,
      privateIndividualProReviewInvitationSearchQuery,
    ],
  );

  const data = useMemo(() => {
    if (solicitationData) {
      return solicitationData.searchSolicitation.map((value) => {
        return {
          givenName: value.givenName,
          familyName: value.familyName,
          email: value.email,
        };
      });
    }
    if (proReviewData) {
      return proReviewData.searchProReview.map((value) => {
        return {
          givenName: value.givenName,
          familyName: value.familyName,
          email: value.email,
        };
      });
    }
    if (proReviewInvitationData) {
      return proReviewInvitationData.searchProReviewInvitation.map((value) => {
        return {
          givenName: value.givenName,
          familyName: value.familyName,
          email: value.email,
        };
      });
    }
  }, [proReviewData, solicitationData, proReviewInvitationData]);

  return (
    <PrivateIndividualSearchContainer
      privateIndividuals={data}
      fetchPrivateIndividuals={fetchPrivateIndividuals}
      onResultSelect={onResultSelect}
    />
  );
};

export default PrivateIndividualSearchRepository;
