import {
  ProOrganizationInsertInput,
  useProOrganizationFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import ProOrganizationFormCreateContainer from './pro-organization-form-create-container';
import { ProOrganizationFormModalProps } from './pro-organization-form-modal';

type ProOrganizationFormCreateRepositoryProps =
  ProOrganizationFormModalProps & {
    onProOrganizationCreated?: (proOrganizationId: number) => void;
  };

const ProOrganizationFormCreateRepository: FunctionComponent<
  ProOrganizationFormCreateRepositoryProps
> = ({ onProOrganizationCreated, ...rest }) => {
  const [proOrganizationFormCreateMutation] =
    useProOrganizationFormCreateMutation();

  const createProOrganization = useCallback(
    async (proOrganizationInput: ProOrganizationInsertInput) => {
      const createdProOrganization = await proOrganizationFormCreateMutation({
        variables: { proOrganizationInput },
      });

      if (
        onProOrganizationCreated &&
        createdProOrganization.data?.insertProOrganizationOne
      ) {
        onProOrganizationCreated(
          createdProOrganization.data.insertProOrganizationOne.id,
        );
      }

      return createdProOrganization.data?.insertProOrganizationOne?.id;
    },
    [],
  );

  return (
    <ProOrganizationFormCreateContainer
      createProOrganization={createProOrganization}
      {...rest}
    />
  );
};

export default ProOrganizationFormCreateRepository;
