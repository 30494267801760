import ProOrganizationField from 'components/pro-organization-field/pro-organization-field';
import env from 'env';
import { useFormikContext } from 'formik';
import { useProOrganizationRouteQuery } from 'generated/graphql';
import ProDocuments from 'pages/pros/pro-form/pro-documents/pro-documents';
import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { concat, filter, pipe, uniq } from 'remeda';
import { Divider, Icon, Message, Grid } from 'semantic-ui-react';
import ProContract from '../pro-contract/pro-contract';
import { ProCreditNotes } from '../pro-credit-notes';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import { ProInvoices } from '../pro-invoices';
import ProMandates from '../pro-mandates/pro-mandates';
import ProSubscriptions from '../pro-subscriptions/pro-subscriptions';
import styled from 'styled-components';
import { getToken } from 'utils';

const DocumentGrid = styled(Grid)`
  div {
    padding: 16px 6px !important;

    &:first-child {
      padding-left: 12px !important;
    }

    &:last-child {
      padding-right: 12px !important;
    }
  }
`;

const DocumentCard = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease;
  height: 100%;

  span {
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 30px;
    line-height: 15px;
  }

  i {
    font-size: 2em;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  &:hover {
    background-color: #f9f9f9;
    text-decoration: none;
  }
`;

type ProOrganizationRouteProps = {
  proViewId: number;
  proOrganizationId?: number;
  hasProOrganizationChanged: boolean;
  bilikZoneId: number;
};

/**
 * ProOrganizationRoute is rendered when a user is on the billing tab of the pro page.
 * It's responsible of handling all organization stuff including billing, documents.
 */
const ProOrganizationRoute: FunctionComponent<ProOrganizationRouteProps> = ({
  proViewId,
  proOrganizationId,
  bilikZoneId,
  hasProOrganizationChanged,
}) => {
  const { values } = useFormikContext<ProFormUpdateFields>();

  const { data } = useProOrganizationRouteQuery({
    variables: {
      accountIds: values.accounts,
      proPeopleIds: values.proPeople.map((proPerson) => proPerson.id),
      bilikZoneId: bilikZoneId,
    },
  });

  // Concat all proPerson emails and account emails from the current proView to make an billingEmail suggestion (uniq)
  const billingEmailSuggestions = useMemo(() => {
    const proPersonEmails =
      data?.proPerson.map((proPerson) => proPerson.email || 'null') || [];
    const accountEmails = data?.account.map((account) => account.email) || [];

    return pipe(
      proPersonEmails,
      filter((proPersonEmail) => proPersonEmail !== 'null'),
      concat(accountEmails),
      uniq(),
    );
  }, [data?.account, data?.proPerson]);

  const proPersonSuggestions = useMemo(
    () =>
      data?.proPerson
        .map((proPerson) => ({
          familyName: proPerson.familyName,
          givenName: proPerson.givenName,
          telephone: proPerson.telephone || 'null',
        }))
        .filter((proPerson) => proPerson.telephone !== 'null'),
    [data?.proPerson],
  );

  const [proContractKey, setProContractKey] = React.useState(0);

  return (
    <>
      <Divider horizontal>Ressources</Divider>
      <DocumentGrid columns={6}>
        <Grid.Column>
          <DocumentCard
            href="#"
            // Using onClick to be sure to give the latest token
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${env.API_URL}/form/pdf/base-contract/${data?.zohoOrganization?.[0].id}`,
              );
            }}
          >
            <Icon name="file pdf outline" />
            <span>Contrat vierge</span>
          </DocumentCard>
        </Grid.Column>
        <Grid.Column>
          <DocumentCard
            href="#"
            // Using onClick to be sure to give the latest token
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${
                  env.API_URL
                }/attachment/object-by-key-token?key=assets/documents/accord_confidentialite_bilik${
                  data?.zohoOrganization?.[0].id === 2 ? '_aix' : ''
                }.pdf&token=${getToken()}`,
              );
            }}
          >
            <Icon name="file pdf outline" />
            <span>Accord de confidentialité</span>
          </DocumentCard>
        </Grid.Column>
        <Grid.Column>
          <DocumentCard
            href="#"
            // Using onClick to be sure to give the latest token
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${
                  env.API_URL
                }/attachment/object-by-key-token?key=assets/documents/checklist.pdf&token=${getToken()}`,
              );
            }}
          >
            <Icon name="file pdf outline" />
            <span>Checklist d&apos;entrée</span>
          </DocumentCard>
        </Grid.Column>
        <Grid.Column>
          <DocumentCard
            href={`https://docs.google.com/spreadsheets/d/19fBRD_jvU89HavIi9GveahVW_c6qSZa-y56nrqXinLs/edit#gid=0`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="file excel outline" />
            <span>Grille des tarifs</span>
          </DocumentCard>
        </Grid.Column>
        <Grid.Column>
          <DocumentCard
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${
                  env.API_URL
                }/attachment/object-by-key-token?key=assets/documents/cargo_grille_des_prix.pdf&token=${getToken()}`,
              );
            }}
          >
            <Icon name="file pdf outline" />
            <span>Accord Cadre CarGo</span>
          </DocumentCard>
        </Grid.Column>
        <Grid.Column>
          <DocumentCard
            href="#"
            // Using onClick to be sure to give the latest token
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${
                  env.API_URL
                }/attachment/object-by-key-token?key=assets/documents/loxam_tarifs.pdf&token=${getToken()}`,
              );
            }}
          >
            <Icon name="file pdf outline" />
            <span>Accord Cadre Loxam</span>
          </DocumentCard>
        </Grid.Column>
      </DocumentGrid>
      <Divider horizontal style={{ marginTop: '50px' }}>
        Entreprise
      </Divider>
      {/* An explanation on what to do when proOrganization doesn't exists */}
      {!values.proPresentation.proOrganizationId ? (
        <>
          <p>
            Vous devez créer une nouvelle entreprise pour cette fiche, ou en
            séléctionner une déjà existante. Une entreprise peut déjà exister
            dans le cas ou deux fiches existent pour un même pro (fiches dans
            deux catégories différents).
          </p>
          <Divider />
        </>
      ) : null}
      <ProOrganizationField
        billingEmailSuggestions={billingEmailSuggestions}
        label="Sélectionner une entreprise de la liste"
        helpText="L'entreprise sélectionnée sera l'entreprise facturée pour les abonnements"
        name="proPresentation.proOrganizationId"
        value={values.proPresentation.proOrganizationId}
        onProOrganizationUpdated={() => setProContractKey((prev) => prev + 1)} // Force refresh of the proContract component
      />
      {/* Display organization actions only when a proOrganization is selected */}
      {values.proPresentation.proOrganizationId &&
      !hasProOrganizationChanged ? (
        <Fragment>
          <Divider horizontal style={{ marginTop: '50px' }}>
            Mandats
          </Divider>
          <ProMandates
            proOrganizationId={values.proPresentation.proOrganizationId}
            receiptEmailSuggestions={billingEmailSuggestions}
            proPersonSuggestions={proPersonSuggestions}
          />
          <Divider horizontal style={{ marginTop: '50px' }}>
            Contrat
          </Divider>
          <ProContract
            key={`pro-contract-${values.proPresentation.proOrganizationId}-${proContractKey}`}
            proOrganizationId={values.proPresentation.proOrganizationId}
            proViewId={proViewId}
            receiptEmailSuggestions={billingEmailSuggestions}
            proPersonSuggestions={proPersonSuggestions}
          />

          <Divider horizontal style={{ marginTop: '50px' }}>
            Documents & Assurances
          </Divider>

          <ProDocuments
            proOrganizationId={values.proPresentation.proOrganizationId}
          />
          <Divider horizontal style={{ marginTop: '50px' }}>
            Abonnements
          </Divider>
          <ProSubscriptions
            proOrganizationId={values.proPresentation.proOrganizationId}
          />
          <Divider horizontal style={{ marginTop: '50px' }}>
            Factures
          </Divider>
          <ProInvoices
            proOrganizationId={values.proPresentation.proOrganizationId}
          />
          <Divider horizontal style={{ marginTop: '50px' }}>
            Avoirs
          </Divider>
          <ProCreditNotes
            proOrganizationId={values.proPresentation.proOrganizationId}
          />
        </Fragment>
      ) : null}

      {/* If the selected pro organization isn't yet commited to db */}
      {values.proPresentation.proOrganizationId && hasProOrganizationChanged ? (
        <Message color="orange">
          Veuillez{' '}
          <span>
            <Icon name="save" />
            Enregistrer
          </span>{' '}
          la fiche pour accèder aux informations liées à l&apos;entreprise.
        </Message>
      ) : null}
    </>
  );
};

export default ProOrganizationRoute;
