import {
  Container,
  Segment,
  Header,
  Button,
  Icon,
  Transition,
  Form,
} from 'semantic-ui-react';
import { FieldArray, useFormikContext } from 'formik';
import React, { Fragment, FunctionComponent } from 'react';
import { fr } from 'date-fns/locale';
import FormField from 'components/form-field/form-field';
import {
  ProFormUpdateFields,
  ProMessageFields,
} from '../pro-form-update-fields-type';

const emptyProMessage: ProMessageFields = {
  body: '',
  isPermanent: false,
  startDate: null,
  endDate: null,
};

const ProMessagesRoute: FunctionComponent = () => {
  const { values } = useFormikContext<ProFormUpdateFields>();

  return (
    <Container fluid style={{ marginTop: '14px' }}>
      <FieldArray
        name="proMessages"
        render={(arrayHelpers): React.ReactElement => (
          <Fragment>
            {values.proMessages && values.proMessages.length > 0
              ? values.proMessages.map((proMessage, proMessageIndex) => (
                  <Segment.Group key={proMessageIndex}>
                    <Segment clearing>
                      <Header>Message n°{proMessageIndex + 1}</Header>
                      <Button
                        basic
                        negative
                        icon
                        floated="right"
                        type="button"
                        onClick={(): void => {
                          arrayHelpers.remove(proMessageIndex);
                        }}
                      >
                        <Icon name="trash alternate outline" />
                      </Button>
                    </Segment>
                    <Segment>
                      <FormField
                        type="checkbox"
                        name={`proMessages.${proMessageIndex}.isPermanent`}
                        label="Permanent ?"
                      />
                      <FormField
                        label="Message"
                        type="textarea"
                        name={`proMessages.${proMessageIndex}.body`}
                        rows={8}
                        required
                      />
                      <Transition.Group duration={250}>
                        {!values.proMessages[proMessageIndex].isPermanent ? (
                          <Container fluid>
                            <Form.Group widths="equal">
                              <FormField
                                label="Date de début"
                                values={values}
                                name={`proMessages.${proMessageIndex}.startDate`}
                                type="date"
                                dateFormat="dd/MM/yyyy"
                                locale={fr}
                                clearable
                              />
                              <FormField
                                label="Date de fin"
                                values={values}
                                name={`proMessages.${proMessageIndex}.endDate`}
                                type="date"
                                dateFormat="dd/MM/yyyy"
                                locale={fr}
                                clearable
                              />
                            </Form.Group>
                          </Container>
                        ) : null}
                      </Transition.Group>
                    </Segment>
                  </Segment.Group>
                ))
              : null}
            <Button
              floated="right"
              positive
              type="button"
              onClick={(): void => {
                arrayHelpers.push(emptyProMessage);
              }}
            >
              <Icon name="plus" />
              Nouveau message
            </Button>
          </Fragment>
        )}
      />
    </Container>
  );
};

export default ProMessagesRoute;
