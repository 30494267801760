import React, { FunctionComponent, useCallback } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import SmsReceivedTableContainer from './sms-received-table-container';
import { apiClient } from 'axios-client';
import { useCallsTableGloballyBlockedQuery } from 'generated/graphql';

type SmsReceivedTableRepositoryProps = {
  filters?: {
    page?: number;
    bilikZoneId?: number;
    proViewId?: number;
    piTelephone?: string;
    isSpam?: boolean;
  };
  itemPerPage: number;
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
};

const SmsReceivedTableRepository: FunctionComponent<
  SmsReceivedTableRepositoryProps
> = ({ filters, itemPerPage, orderBy, onSort, onFilter }) => {
  const fetchSmsReceived = useCallback(
    async (skip: number, limit: number) => {
      return await apiClient
        .post<MongoEvent[]>(`/event/pro-sms`, {
          bilikZoneId: filters?.bilikZoneId,
          proViewId: filters?.proViewId,
          piTelephone: filters?.piTelephone,
          skip,
          limit,
          orderByDate: orderBy?.direction === 'ascending' ? 'ASC' : 'DESC',
          isSpam: filters?.isSpam,
        })
        .then((response) => {
          return response.data;
        });
    },
    [
      orderBy?.direction,
      filters?.bilikZoneId,
      filters?.page,
      filters?.piTelephone,
      filters?.proViewId,
      filters?.isSpam,
    ],
  );

  const { data } = useCallsTableGloballyBlockedQuery();

  return (
    <SmsReceivedTableContainer
      fetchSmsReceived={fetchSmsReceived}
      filters={filters}
      itemPerPage={itemPerPage}
      onSort={onSort}
      onFilter={onFilter}
      orderBy={orderBy}
      globallyBlocked={data?.globallyBlockedIdentity || []}
    />
  );
};

export default SmsReceivedTableRepository;
