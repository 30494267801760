import { OrderBy, useBilikZonesTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';

import BilikZonesTableContainer from './bilik-zones-table-container';

const BilikZonesTableRepository: FunctionComponent<unknown> = () => {
  const { loading, data } = useBilikZonesTableQuery({
    variables: {
      order_by: [{ name: OrderBy.Asc }],
    },
  });

  return (
    <BilikZonesTableContainer
      loading={loading}
      bilikZones={data?.bilikZone ?? []}
    />
  );
};

export default BilikZonesTableRepository;
