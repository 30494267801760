import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { flatten, difference } from 'remeda';
import { TimelineFilterEventsView } from './timeline-filter-events-view';

type Props = {
  filters: any;
  setFilters: (filters: any) => void;
  direction?: 'left' | 'right';
  float?: 'left' | 'right';
};

export const TimelineFilterEventsContainer: FunctionComponent<Props> = ({
  filters,
  setFilters,
  direction,
  float,
}) => {
  const filterGroups = useMemo(
    () => [
      {
        name: 'Demandes mail',
        filters: [
          { name: 'Nouvelles demandes', values: ['FormCreateSolicitation'] },
          {
            name: 'Actions',
            values: [
              'SolicitationToggleAutorespond',
              'SolicitationDeclined',
              'SolicitationFollowedUp',
              'SollicitationResent',
            ],
          },
        ],
      },
      {
        name: 'Avis',
        filters: [
          {
            name: 'Nouveaux avis',
            values: ['FormCreateProReview'],
          },
          {
            name: 'Actions',
            values: [
              'ProReviewPublished',
              'ProReviewRefused',
              'ProReviewReplied',
              'ProReviewReplyRequested',
              'ProReviewDuplicated',
              'ProReviewToggleSpam',
              'ProReviewEdited',
              'ProReviewCheck',
              'ProReviewReplyModerated',
              'ProReviewCheckEnd',
            ],
          },
        ],
      },
      {
        name: "Demandes d'avis",
        filters: [
          {
            name: "Nouvelles demandes d'avis",
            values: ['FormCreateProReviewInvitation'],
          },
          {
            name: 'Actions',
            values: ['ProReviewInvitationToggleAutorespond'],
          },
        ],
      },
      {
        name: 'Suivis mail',
        values: ['AutorespondMailSent'],
      },
      {
        name: 'Suivi prolongé',
        values: ['FormContinueSolicitation'],
      },
      {
        name: 'Appels',
        values: [
          'CallDenied',
          'CallStatusUpdated',
          'CallRegionBlocked',
          'CallIdentityBlocked',
        ],
      },
      {
        name: 'Sms',
        values: ['SmsSent', 'SmsReceived'],
      },
      {
        name: 'Contact vers Bilik',
        values: ['FormContactBilik', 'FormCreateNoResult'],
      },
      {
        name: 'Bloquage mail / telephone',
        values: [
          'GloballyBlockedIdentityCreated',
          'GloballyBlockedIdentityDeleted',
          'SuspiciousIdentityCreated',
        ],
      },
      {
        name: 'Tickets Freshdesk',
        values: ['FreshdeskTicket'],
      },
    ],
    [],
  );

  const isFilterChecked = useCallback(
    (filter): boolean => {
      const values = filter.values
        ? filter.values
        : flatten(filter.filters.map((filter) => filter.values));
      if (
        filters?.eventTypes &&
        values.map((value) => filters.eventTypes.includes(value)).includes(true)
      ) {
        return true;
      }
      return false;
    },
    [filters],
  );

  const toggleFilter = useCallback(
    (checked: boolean, filter): void => {
      const values = filter.values
        ? filter.values
        : flatten(filter.filters.map((filter) => filter.values));

      if (checked) {
        setFilters({
          eventTypes: filters?.eventTypes
            ? [...filters.eventTypes, ...values]
            : values,
        });
      } else {
        setFilters({ eventTypes: difference(filters.eventTypes, values) });
      }
    },
    [setFilters, filters],
  );

  return (
    <TimelineFilterEventsView
      filterGroups={filterGroups}
      toggleFilter={toggleFilter}
      isFilterChecked={isFilterChecked}
      direction={direction}
      float={float}
    />
  );
};
