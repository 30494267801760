import React, { FunctionComponent, useCallback } from 'react';

import { BilikActor, MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { pathOr } from 'remeda';
import TimelineSmsPreview from '../timeline-sms-preview';
import { Icon, Label } from 'semantic-ui-react';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import { ToastError, ToastSuccess } from 'utils';
import { apiClient } from 'axios-client';

type SmsReceivedProps = {
  event: MongoEvent;
};

// SMS send by pi to pro on twilio number
const SmsReceived: FunctionComponent<SmsReceivedProps> = ({ event }) => {
  const proTargetTelephone =
    event.actors.pro?.proView?.callTrackingTarget?.telephone;

  const getMessageText = (event: MongoEvent): string => {
    switch (event.version) {
      case 0:
        return event.data.callrMessage.text;
      case 1:
        return event.data.callrMessage.text;
      case 2:
        return event.data.twilio.Body;
      case 3:
        return event.data.vonage.text;
      default:
        return event.data.vonage.text;
    }
  };

  const getBilikMainCity = (bilik: BilikActor): string => {
    switch (event.version) {
      case 1:
        return bilik.bilikZone.mainCity as any; // Any because In v1, mainCity was a string
      case 0 | 2 | 3:
        return bilik.bilikZone.mainCityCode;
      default:
        return bilik.bilikZone.mainCityCode;
    }
  };

  const resendSmsToPro = useCallback(async (messageId: string) => {
    await apiClient
      .post(`/form/contact-pro/resend-sms-to-pro`, {
        messageId,
      })
      .catch((error) => {
        ToastError('Erreur', 'Impossible de renvoyer le SMS au pro');
        throw error;
      });

    window.location.reload();

    ToastSuccess('Succès', 'SMS renvoyé au pro');
  }, []);

  return (
    <TimelineEvent
      color={event.data.isSpam ? 'red' : 'blue'}
      date={event.date}
      icon="mobile alternate"
      title={
        event.initiator.pi && event.actors.pro ? 'SMS au pro' : 'SMS à Bilik'
      }
      initiator="particulier"
      takingContact={event.data.isFirstContact}
    >
      {/* Sms to pro */}
      {event.initiator.pi && event.actors.pro ? (
        <>
          <h3>
            {event.data.resendBy ? (
              <>
                <span>
                  {event.data.resendBy.givenName}{' '}
                  {event.data.resendBy.familyName}
                </span>{' '}
                a renvoyé le{' '}
              </>
            ) : (
              <>
                Le particulier a{' '}
                {event.data.isSpam ? "tenté d'envoyer" : 'envoyé'} un{' '}
              </>
            )}
            SMS à <span>{event.actors.pro.proPresentation.name}</span>
          </h3>
          {event.data.isSpam ? (
            <div
              style={{
                color: 'red',
              }}
            >
              <i>Suspicion de spam, ce sms n&apos;a pas été transféré au pro</i>
            </div>
          ) : null}
          <div>
            <p>
              <b>Message : </b>
              {getMessageText(event)}
            </p>
            <p>
              {!proTargetTelephone ||
              (!proTargetTelephone.startsWith('+336') &&
                !proTargetTelephone.startsWith('+337')) ? (
                <i>Ce sms a été transféré au pro par email</i>
              ) : null}
            </p>
          </div>
          <TimelineSmsPreview
            title={'Suivi du sms transmis au pro : '}
            messageId={event.data.messageId}
          />
          {event.data.isSpam ? (
            <div>
              <ConfirmModal
                onConfirm={() => resendSmsToPro(event.data.messageId)}
                trigger={
                  <Label
                    as="a"
                    onClick={(): void => {
                      resendSmsToPro(event.data.messageId);
                    }}
                  >
                    <Icon name="redo" />
                    Renvoyer le sms au pro
                  </Label>
                }
                content={
                  <p>
                    Vous vous apprêtez à renvoyer le SMS au pro. Vérifiez bien
                    que cette action n&apos;a pas déjà été effectuée. Êtes-vous
                    sûr vouloir faire cela ?
                  </p>
                }
                header="Confirmation de l'envoi du sms au pro"
              />
            </div>
          ) : null}
        </>
      ) : null}
      {/* Sms to bilikZone */}
      {event.initiator.pi && event.actors.bilik ? (
        <>
          <>
            <h3>
              Le particulier a envoyé un SMS à{' '}
              <span>Bilik {getBilikMainCity(event.actors.bilik)}</span>
            </h3>
            <div>
              <p>
                <b>Message: </b>
                {getMessageText(event)}
              </p>
            </div>
          </>
        </>
      ) : null}
    </TimelineEvent>
  );
};

export default SmsReceived;
