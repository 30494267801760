import { merge } from 'remeda';
import React, { FunctionComponent, Fragment } from 'react';
import { Loader } from 'semantic-ui-react';
import {
  ExportGuideProViewsTableQuery,
  ProView,
  useExportGuideProViewsTableQuery,
} from 'generated/graphql';
import SortableTable from '../sortable-table/sortable-table';
import extractTradeGuideName from 'utils/extract-trade-guide-name';

type ManageProsContainerProps = {
  proViewItems: ProViewItem[];
  bilikZoneId?: number;
  onItemChange: (items: ProViewItem[]) => void;
};

type ProViewItem = ProView & {
  isChecked: boolean;
  mainTrade: {
    normalizedName: string;
    isTradeGuide: boolean;
  };
};

export type ManageProViewContainerItem = ProViewItem;

const ManageProsContainer: FunctionComponent<ManageProsContainerProps> = ({
  onItemChange,
  bilikZoneId,
  proViewItems,
}) => {
  const { error, data, loading } = useExportGuideProViewsTableQuery({
    variables: {
      where: {
        status: {
          _in: ['draft', 'published'],
        },
        bilikZone: {
          id: {
            _eq: Number(bilikZoneId),
          },
          status: {
            _eq: 'published',
          },
        },
      },
    },
  });

  if (error) return <Fragment>Error! ${error.message}</Fragment>;

  if (loading) {
    return (
      <Loader size="large" active inline="centered">
        Chargement...
      </Loader>
    );
  }

  let shuffledProViews: ExportGuideProViewsTableQuery['proView'] = [];

  if (data) {
    shuffledProViews = data.proView.slice().sort(() => Math.random() - 0.5);
  }

  const items: ProViewItem[] = shuffledProViews
    .map((proView) => {
      const pro = {
        id: proView.id,
        mainTrade: {
          id: proView.proPresentation.mainTrade?.id,
          tradeGuidesByFirstTrade:
            proView.proPresentation.mainTrade?.tradeGuidesByFirstTrade,
          tradeGuidesBySecondTrade:
            proView.proPresentation.mainTrade?.tradeGuidesBySecondTrade,
        },
        secondaryTradeId: proView.proPresentation.secondaryTrade?.id,
      };

      return merge(proView, {
        isChecked: false,
        mainTrade: {
          normalizedName: (
            extractTradeGuideName(pro) ??
            proView.proPresentation.mainTrade?.name ??
            ''
          )
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          isTradeGuide: !!extractTradeGuideName(pro),
        },
      }) as ProViewItem;
    })

    // Sort on normalized main trade name
    .sort((a, b) =>
      a.mainTrade.normalizedName.localeCompare(b.mainTrade.normalizedName),
    );

  return proViewItems.length > 1 ? (
    <SortableTable onItemChange={onItemChange} items={proViewItems} />
  ) : (
    <SortableTable onItemChange={onItemChange} items={items} />
  );
};

export default ManageProsContainer;
