import React, { FunctionComponent, useCallback } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { useReviewLocker } from 'hooks/use-review-locker/use-review-locker';
import { ToastError } from 'utils';

type ToggleLockButtonProps = {
  proReviewId: number;
  onComplete?: () => void;
  disabled?: boolean;
};

const ToggleLockButton: FunctionComponent<ToggleLockButtonProps> = ({
  proReviewId,
  onComplete,
  disabled,
}) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  const { isLock, lock, unlock, loading } = useReviewLocker(proReviewId);

  const handleClick = useCallback(async () => {
    if (!currentBilikPerson?.id) {
      ToastError('Erreur', "Impossible de verrouiller l'avis");
      throw new Error("Impossible de verrouiller l'avis");
    }

    if (!isLock) {
      await lock(currentBilikPerson?.id);
    }

    if (isLock) {
      await unlock();
    }

    onComplete?.();
  }, [proReviewId, currentBilikPerson, isLock]);

  return (
    <Popup
      content={isLock ? "Déverrouiller l'avis" : "Verrouiller l'avis"}
      position="top center"
      trigger={
        <Button
          disabled={disabled}
          loading={loading || disabled}
          onClick={handleClick}
          icon
          basic
          color="grey"
        >
          <Icon name={isLock ? 'unlock' : 'lock'} />
        </Button>
      }
    />
  );
};

export default ToggleLockButton;
