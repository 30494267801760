import React from 'react';
import { useFormikContext } from 'formik';
import { FunctionComponent } from 'react';
import { Button, Form } from 'semantic-ui-react';
import FormField from 'components/form-field/form-field';

export const PiBouncedSolicitationFormView: FunctionComponent = () => {
  const {
    handleSubmit,
    handleReset,
    isSubmitting,
    errors,
    initialValues,
    values,
  } = useFormikContext<{
    email: string;
  }>();

  return (
    <>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormField type="text" label="Email" name="email" required />
        <Button
          primary
          type="submit"
          loading={isSubmitting}
          disabled={
            isSubmitting ||
            !!errors.email ||
            initialValues.email === values.email
          }
        >
          Valider et renvoyer la demande
        </Button>
      </Form>
    </>
  );
};
