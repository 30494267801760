import * as Yup from 'yup';
import { ToastError } from 'utils';
import Client from 'client';
import gql from 'graphql-tag';

const isExistingSlug = async (slug?: string | null): Promise<boolean> => {
  return Client.query({
    query: gql`
      query ($slug: String!) {
        trade(where: { slug: { _eq: $slug } }) {
          id
          slug
        }
      }
    `,
    variables: { slug: slug || '' },
  }).then((response) => response.data.trade && response.data.trade.length > 0);
};

type TradeFormValidationSchemaArgs = {
  currentSlug?: string;
};

export const tradeFormValidationSchema = (
  args?: TradeFormValidationSchemaArgs,
): Yup.ObjectSchema => {
  return Yup.object().shape({
    name: Yup.string().required('Nom requis !'),
    slug: Yup.string()
      .required('Slug requis !')
      .matches(/^[a-z0-9-]+$/, {
        excludeEmptyString: true,
        message: 'Format incorrect !',
      })
      .test('is-slug-uniq', 'Slug déjà existant !', async (slug) => {
        if (args?.currentSlug !== slug) {
          return await isExistingSlug(slug)
            .then((res) => res === false)
            .catch((error) => {
              ToastError(
                'Erreur',
                "Impossible de vérifier l'unicité du slug !",
              );
              throw error;
            });
        }
        return true;
      }),
    pageTitle: Yup.string().required('Titre de la page requis !'),
    anchor: Yup.string().required('Ancre maillage interne requise !'),
    pageTitleVariant: Yup.string().required('Titre de la page requis !'),
    pageSubtitle: Yup.string().required('Sous-titre de la page requis !'),
    pageTitleDirectory: Yup.string().required("Title de l'annuaire requis !"),
    metaTitle: Yup.string().required('Meta title requis !'),
    metaDescription: Yup.string().required('Meta description requis !'),
    label: Yup.string().required('Label requis !'),
    tradeGroups: Yup.array().of(Yup.string()).min(1),
    tradeQuestions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string()
          .required('Question requise !')
          .max(80, '80 caractères maximum !'),
        answer: Yup.string().required('Réponse requise !'),
      }),
    ),
  });
};
