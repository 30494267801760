import { useProReviewsCountQuery } from 'generated/graphql';

import React, { FunctionComponent, useMemo } from 'react';
import { DuplicateCheckerIndicatorContainer } from './duplicate-checker-indicator-container';

type IpCheckerIndicatorRepositoryProps = {
  proPresentationId: number;
  pi: {
    email: string;
    familyName: string;
    givenName: string;
  };
};
export const DuplicateCheckerIndicatorRepository: FunctionComponent<
  IpCheckerIndicatorRepositoryProps
> = ({ proPresentationId, pi }) => {
  const { data, loading } = useProReviewsCountQuery({
    variables: {
      where: {
        _or: [
          {
            email: { _eq: pi.email },
          },
          {
            givenName: { _eq: pi.givenName },
            familyName: { _eq: pi.familyName },
          },
        ],
        proPresentationId: { _eq: proPresentationId },
      },
    },
  });

  const isDuplicateReview = useMemo(() => {
    const count = data?.proReviewAggregate.aggregate?.count;
    return count !== undefined ? count >= 2 : false;
  }, [data, loading]);

  return (
    <DuplicateCheckerIndicatorContainer
      pi={pi}
      proPresentationId={proPresentationId}
      isDuplicateReview={isDuplicateReview}
      loading={loading}
    />
  );
};
