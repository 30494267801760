import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';
import { TradeCreate } from './trade-form-create-repository';
import TradeFormView from './trade-form-view';
import { tradeFormValidationSchema } from './trade-form-validation-schema';
import { useNavigate } from 'react-router-dom';

type TradesFormCreateContainerProps = {
  createTrade: (trade: TradeCreate) => Promise<unknown>;
};

type TradeFormCreateFields = {
  name: string;
  slug: string;
  pageTitle: string;
  anchor: string;
  pageTitleVariant: string;
  pageSubtitle: string;
  pageTitleDirectory: string;
  label: string;
  isChild: boolean;
  tradeGroups: number[];
  tradeKeywords: number[];
  metaTitle: string;
  metaDescription: string;
  isCallRecordable: boolean;
  isAnonymised: boolean;
  isRgeFilterable: boolean;
};

const TradesFormCreateContainer: FunctionComponent<
  TradesFormCreateContainerProps
> = ({ createTrade }) => {
  const history = useNavigate();

  const initialFormValues: TradeFormCreateFields = useMemo(() => {
    return {
      name: '',
      slug: '',
      pageTitle: '',
      anchor: '',
      pageTitleVariant: '',
      pageSubtitle: '',
      pageTitleDirectory: '',
      label: '',
      isChild: false,
      metaTitle: '',
      metaDescription: '',
      tradeGroups: [],
      tradeKeywords: [],
      isCallRecordable: true,
      isAnonymised: false,
      isRgeFilterable: true,
    };
  }, []);

  const handleSubmit = async (
    values: TradeFormCreateFields,
    actions: FormikHelpers<TradeFormCreateFields>,
  ): Promise<void> => {
    try {
      await createTrade(values);

      ToastSuccess('Succès', 'Catégorie enregistrée');
      actions.setSubmitting(false);
      history('/trades');
    } catch (error) {
      ToastError('Erreur', "Impossible d'enregistrer la catégorie");
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validationSchema={tradeFormValidationSchema()}
      initialValues={initialFormValues}
      onSubmit={(value, actions): Promise<void> => {
        return handleSubmit(value, actions);
      }}
    >
      <TradeFormView />
    </Formik>
  );
};

export default TradesFormCreateContainer;
