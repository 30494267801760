import FormField from 'components/form-field/form-field';
import SelectProViewField from 'components/select-pro-view-field/select-pro-view-field';
import { FieldArray, useFormikContext } from 'formik';
import { useGoogleReviewLinkFieldQuery } from 'generated/graphql';
import { ProFormUpdateFields } from 'pages/pros/pro-form/pro-form-update-fields-type';
import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Container,
  Divider,
  Icon,
  Image,
  Modal,
  Table,
} from 'semantic-ui-react';

interface GoogleReviewLinkFieldProps {
  proPresentationId: number;
  proViewId: number;
}

const GoogleReviewLinkField: FunctionComponent<GoogleReviewLinkFieldProps> = ({
  proPresentationId,
  proViewId,
}) => {
  const { values } = useFormikContext<ProFormUpdateFields>();

  const [allProViewsSelected, setAllProViewsSelected] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = useGoogleReviewLinkFieldQuery({
    variables: {
      id: proPresentationId,
    },
  });

  const proViewCount =
    data?.proPresentationByPk?.proViewsAggregate?.aggregate?.count ?? 0;

  const selectedProViews = values.googleReviewLinks
    .map((googleReviewLink) =>
      googleReviewLink.proViewIds?.map((proViewId) => proViewId),
    )
    .flat()
    .filter((proViewId) => proViewId !== undefined) as number[];

  useEffect(() => {
    if (data) {
      if (selectedProViews.length === proViewCount) {
        setAllProViewsSelected(true);
      } else {
        setAllProViewsSelected(false);
      }
    }
  }, [selectedProViews]);

  return (
    <>
      <Button
        compact
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <Icon name="question circle outline" /> Comment trouver le lien
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Header>Comment le pro peut-il trouver ce lien ?</Modal.Header>
        <Modal.Content>
          <p>
            1. Pour trouver le lien d&apos;avis Google, il faut se rendre sur sa
            fiche Google My Business ( en veillant à bien être connecté ) en
            tapant le nom de l&apos;entreprise dans la barre de recherche
            Google.
          </p>
          <p>2. Cliquer sur le bouton « Voir les avis »</p>
          <p>3. Cliquer sur le bouton « Recevez plus d&apos;avis »</p>
          <p>
            4. Copier le lien qui s&apos;affiche dans la fenêtre qui s&apos;est
            ouverte
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Image src={'/gmb-link-tuto/step-1.png'} maxWidth={600} />
            <Divider hidden />
            <Image src={'/gmb-link-tuto/step-2.png'} maxWidth={600} />
            <Divider hidden />
            <Image src={'/gmb-link-tuto/step-3.png'} maxWidth={600} />
          </div>
        </Modal.Content>
      </Modal>
      <FieldArray
        name="googleReviewLinks"
        render={(arrayHelpers): ReactElement => (
          <Fragment>
            {values && values.googleReviewLinks.length > 0 ? (
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="6">Url</Table.HeaderCell>
                    <Table.HeaderCell width="7">Fiche(s)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {values.googleReviewLinks.map((_, googleReviewLinkIndex) => (
                    <Table.Row
                      verticalAlign="top"
                      style={{ height: '73px' }}
                      key={googleReviewLinkIndex}
                    >
                      <Table.Cell>
                        <FormField
                          name={`googleReviewLinks.${googleReviewLinkIndex}.url`}
                          type="text"
                          placeholder="https://g.page/r/{variable}/review"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <SelectProViewField
                          multiple
                          selection
                          name={`googleReviewLinks.${googleReviewLinkIndex}.proViewIds`}
                          filters={{
                            proPresentationId,
                          }}
                          selectedProViews={selectedProViews}
                          required
                        />
                      </Table.Cell>

                      <Table.Cell textAlign="right">
                        <Button
                          basic
                          negative
                          icon
                          type="button"
                          onClick={(): void => {
                            arrayHelpers.remove(googleReviewLinkIndex);
                          }}
                        >
                          <Icon name="trash alternate outline" />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : null}
            <Container fluid style={{ marginTop: '14px' }} textAlign="right">
              {!allProViewsSelected && (
                <Button
                  type="button"
                  positive
                  onClick={(): void => {
                    arrayHelpers.push({
                      url: '',
                      proViewIds: proViewCount === 1 ? [proViewId] : [],
                    });
                  }}
                >
                  <Icon name="plus" />
                  Nouveau lien
                </Button>
              )}
            </Container>
          </Fragment>
        )}
      />
    </>
  );
};

export default GoogleReviewLinkField;
