import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import OrganizationSearchFieldContainer from './organization-search-field-container';

export type OrganizationResult = {
  siret: string;
  name: string;
  street1: string;
  street2: string;
  postalCode: string;
  city: string;
  activity: string;
  activityCode: string;
  dateCreated: string;
  country: string;
  businessStatus: string;
};

type Props = {
  onSelectResult?: (organization: OrganizationResult) => void;
};

const OrganizationSearchFieldRepository: FunctionComponent<Props> = ({
  onSelectResult,
}) => {
  const searchOrganization = useCallback(async (criteria: string) => {
    const response = await apiClient.post(`/form/insee/search`, {
      search: criteria
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase(),
    });

    return response.data;
  }, []);

  return (
    <OrganizationSearchFieldContainer
      searchOrganization={searchOrganization}
      onSelectResult={onSelectResult}
    />
  );
};

export default OrganizationSearchFieldRepository;
