import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';
import { CityFormFields } from './city-form-fields.type';
import CityFormView from './city-form-view';
import { CityFormCreateMutation, CityInsertInput } from 'generated/graphql';
import { cityFormValidationSchema } from './city-form-validation-schema';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useNavigate } from 'react-router';
import { addCrs } from 'utils/geometry';

type CityFormCreateProps = {
  createCity: (
    city: CityInsertInput,
  ) => Promise<CityFormCreateMutation | null | undefined>;
};

const CityFormCreateContainer: FunctionComponent<CityFormCreateProps> = ({
  createCity,
}) => {
  const history = useNavigate();

  const [bilikZoneId] = useQueryParam('bilikZoneId', NumberParam);

  const initialValues: CityFormFields = useMemo(() => {
    return {
      name: '',
      slug: '',
      area: undefined,
      bilikZoneId: bilikZoneId ?? undefined,
      geoNameId: undefined,
      population: 0,
    };
  }, []);

  const onSubmit = useCallback(
    async (
      values: CityFormFields,
      actions: FormikHelpers<CityFormFields>,
    ): Promise<void> => {
      try {
        await createCity({
          name: values?.name,
          slug: values?.slug,
          bilikZoneId: values?.bilikZoneId,
          area: addCrs(values?.area),
          geoNameId: values?.geoNameId,
          population: values?.population,
        });

        ToastSuccess('Succès', 'Zone enregistré');
        actions.setSubmitting(false);
        history(
          `${bilikZoneId ? `/bilik_zones/update/${bilikZoneId}` : '/cities'}`,
        );
      } catch (error) {
        ToastError('Erreur', "Impossible d'enregistrer la zone");
      }
    },
    [createCity, history],
  );

  return (
    <Formik
      validateOnChange={false}
      validationSchema={cityFormValidationSchema()}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <CityFormView />
    </Formik>
  );
};

export default CityFormCreateContainer;
