import React, { FunctionComponent, useEffect } from 'react';
import SmsReceivedGraphView from './sms-received-graph-view';
import { Serie } from '@nivo/line';
import { Loader } from 'semantic-ui-react';

type Props = {
  fetchData: () => Promise<Serie[]>;
};

const SmsReceivedGraphContainer: FunctionComponent<Props> = ({ fetchData }) => {
  const [chartData, setChartData] = React.useState<Serie[] | null>(null);

  useEffect(() => {
    setChartData(null);
    fetchData().then((data) => {
      setChartData(data);
    });
  }, [fetchData]);

  if (chartData) {
    return <SmsReceivedGraphView data={chartData} />;
  } else {
    return (
      <div style={{ height: 276 }}>
        <Loader
          size="large"
          active
          inline="centered"
          style={{ marginTop: 224 }}
        />
      </div>
    );
  }
};

export default SmsReceivedGraphContainer;
