import { isValidFrTelephone } from 'utils/telephone';
import * as Yup from 'yup';

export default (): Yup.ObjectSchema =>
  Yup.object().shape({
    givenName: Yup.string().required('Prénom requis !'),
    familyName: Yup.string().required('Nom requis !'),
    telephone: Yup.string().test(
      'is-valid',
      'Numéro de téléphone invalide !',
      (value?: string) => (value ? isValidFrTelephone(value) : true),
    ),
    email: Yup.string().email('Adresse email non valide !'),
  });
