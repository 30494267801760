import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { ToastError, ToastInfo, ToastSuccess } from 'utils';

type BackupCardProps = Record<string, unknown>;

export const BackupCard: FunctionComponent<BackupCardProps> = ({}) => {
  const onClickBackup = useCallback(async (): Promise<void> => {
    await ToastInfo('Info', 'Backup en cours de création...');
    await apiClient.post(`/form/admin-utilities/backup`).catch((error) => {
      if (error.status === 'Request failed with status code 504') {
        ToastInfo('Info', 'Request timeout, backup toujours en cours...');
      } else {
        ToastError('Erreur', 'Impossible de lancer la backup');
        throw error;
      }
    });

    ToastSuccess(
      'Succès',
      'Backup lancé, elle sera disponible dans quelques minutes',
    );
  }, []);

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="save" />
        <Card.Header>Backup</Card.Header>
        <Card.Description>Lancer un backup manuel</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button fluid primary onClick={onClickBackup}>
          Executer
        </Button>
      </Card.Content>
    </Card>
  );
};
