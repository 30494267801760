import { useState, useEffect } from 'react';
import { apiClient } from 'axios-client';

type CallsCounter = {
  count?: number;
  loading: boolean;
};
type Filters = {
  after?: Date;
  before?: Date;
  bilikZoneId?: number;
  proViewIds?: number[];
  proViewId?: number;
  filters?: any[];
};

export const useCallsCount = (
  filters?: Filters,
  disabled?: boolean, // Prevent execution when disabled
): CallsCounter => {
  const [count, setCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (disabled) return;
    setLoading(true);
    apiClient.post<number>(`/event/calls-count`, filters).then((response) => {
      setCount(response.data);
      setLoading(false);
    });
  }, [
    filters?.after,
    filters?.before,
    filters?.bilikZoneId,
    filters?.proViewIds,
    filters?.proViewId,
    filters?.filters,
    disabled,
  ]);

  return {
    count,
    loading,
  };
};
