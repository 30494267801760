import { useIpCheckerIndicatorQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import { IpCheckerIndicatorContainer } from './ip-checker-indicator-container';

type IpCheckerIndicatorRepositoryProps = {
  ip?: string;
  proPresentationId: number;
};
export const IpCheckerIndicatorRepository: FunctionComponent<
  IpCheckerIndicatorRepositoryProps
> = ({ ip, proPresentationId }) => {
  const proReviewsByIpCount = useIpCheckerIndicatorQuery({
    variables: {
      where: {
        ip: { _eq: ip },
      },
    },
  });

  const proReviewByIpOnSameProCount = useIpCheckerIndicatorQuery({
    variables: {
      where: {
        ip: { _eq: ip },
        proPresentationId: { _eq: proPresentationId },
      },
    },
  });

  const isFirstReviewWithThisIp = useMemo(() => {
    const count = proReviewsByIpCount.data?.proReviewAggregate.aggregate?.count;
    return count !== undefined ? count <= 1 : true;
  }, [ip, proReviewsByIpCount]);

  const isMultipleReviewsWithThisIpOnSamePro = useMemo(() => {
    if (isFirstReviewWithThisIp) {
      return false;
    }
    const count =
      proReviewByIpOnSameProCount.data?.proReviewAggregate.aggregate?.count;
    return count !== undefined ? count >= 2 : false;
  }, [ip, proReviewByIpOnSameProCount, isFirstReviewWithThisIp]);

  return (
    <IpCheckerIndicatorContainer
      ip={ip}
      isFirstReviewWithThisIp={isFirstReviewWithThisIp}
      isMultipleReviewsWithThisIpOnSamePro={
        isMultipleReviewsWithThisIpOnSamePro
      }
      loading={
        proReviewByIpOnSameProCount.loading || proReviewsByIpCount.loading
      }
    />
  );
};
