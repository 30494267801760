import { isValidFrTelephone } from 'utils/telephone';
import * as Yup from 'yup';
import { DocumentSendFormAdditionalFields } from './document-send-form-modal-container';

export default (
  additionalFields?: DocumentSendFormAdditionalFields,
): Yup.ObjectSchema =>
  Yup.object().shape({
    givenName: additionalFields?.givenName
      ? Yup.string().required('Prénom requis !')
      : Yup.string(),
    familyName: additionalFields?.familyName
      ? Yup.string().required('Nom requis !')
      : Yup.string(),
    telephone: Yup.string()
      .test('is-valid', 'Numéro de téléphone invalide !', isValidFrTelephone)
      .required('Numéro de téléphone requis !'),
    proReceiptEmail: Yup.string()
      .required('mail requis !')
      .email('Adresse email non valide !'),
  });
