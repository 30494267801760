import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import TradeSearchFilterView from './trade-search-filter-view';
import { TradeSearchFilterQuery } from 'generated/graphql';
import {
  SearchProps,
  SearchResultData,
  SearchResultsProps,
} from 'semantic-ui-react';
import { useDebounce } from 'use-debounce';
import { ToastError } from 'utils';

type Props = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  trades: TradeSearchFilterQuery['searchTrade'];
  fetchTrades: (criteria?: string | undefined) => Promise<void>;
  defaultValue?: string;
  onDelete?: () => void;
};
const TradeSearchFilterContainer: FunctionComponent<Props> = ({
  onResultSelect,
  fetchTrades,
  trades,
  defaultValue,
  onDelete,
}) => {
  // State.
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResultsProps>();
  const [value, setValue] = useState<string>();
  const [debouncedValue] = useDebounce(value, 600);

  if (defaultValue && value == '') {
    setValue(defaultValue);
  }

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (debouncedValue) {
        await fetchTrades(debouncedValue).catch((error) => {
          ToastError('Erreur', 'Impossible de rechercher le particulier');
          setLoading(false);
          throw error;
        });
      }
    };
    fetch();
  }, [debouncedValue]);

  const onSearchChange = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      { value }: SearchProps,
    ) => {
      setValue(value);
      if (value) {
        // only when value are not empty
        // Set loading to true here to start loading before debouncing
        setLoading(true);
      }
    },
    [],
  );

  // Click on arrow
  const onClick = useCallback((): void => {
    if (onDelete) onDelete();
    setValue(undefined);
  }, []);

  useEffect(() => {
    if (trades) {
      const newSearchResults = trades.map((trade) => {
        return {
          id: trade.id,
          title: trade.name,
        };
      });
      setSearchResults(newSearchResults);
    }
    setLoading(false); // Disabling loading only when searchResults are computed
  }, [trades]);

  return (
    <TradeSearchFilterView
      onClick={onClick}
      defaultValue={defaultValue}
      value={value}
      loading={loading}
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      searchResults={searchResults}
    />
  );
};

export default TradeSearchFilterContainer;
