import {
  ProPersonInsertInput,
  useProPersonFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import ProPersonFormCreateContainer from './pro-person-form-create-container';
import { ProPersonFormModalProps } from './pro-person-form-modal';
import { ToastError } from 'utils/toast';
import Client from 'client';
import gql from 'graphql-tag';

type ProPersonFormCreateRepository = ProPersonFormModalProps & {
  onProPersonCreated?: (proPersonId: number) => void;
};

const ProPersonFormCreateRepository: FunctionComponent<ProPersonFormCreateRepository> = ({
  onProPersonCreated,
  ...rest
}) => {
  const isExistingProPerson = useCallback(
    async (
      givenName: string,
      familyName: string,
      email: string,
      telephone: string,
    ): Promise<boolean> => {
      const result = await Client.query({
        query: gql`
          query getProPerson(
            $givenName: String!
            $familyName: String!
            $email: String!
            $telephone: String!
          ) {
            proPerson(
              where: {
                _and: [
                  { givenName: { _ilike: $givenName } }
                  { familyName: { _ilike: $familyName } }
                  {
                    _or: [
                      { email: { _ilike: $email } }
                      { telephone: { _ilike: $telephone } }
                    ]
                  }
                ]
              }
            ) {
              id
            }
          }
        `,
        variables: {
          givenName: givenName.trim(),
          familyName: familyName.trim(),
          email: email.trim(),
          telephone: telephone.trim(),
        },
        fetchPolicy: 'network-only',
      });

      return result.data.proPerson.length > 0;
    },
    [],
  );

  const [proPersonFormCreateMutation] = useProPersonFormCreateMutation();

  const createProPerson = useCallback(
    async (proPerson: ProPersonInsertInput) => {
      const createdProPerson = await proPersonFormCreateMutation({
        variables: { proPerson },
      }).catch((error) => {
        ToastError('Erreur', 'Impossible de créer le contact');
        throw error;
      });

      if (onProPersonCreated && createdProPerson.data?.insertProPersonOne) {
        onProPersonCreated(createdProPerson.data?.insertProPersonOne?.id);
      }
    },
    [onProPersonCreated],
  );

  return (
    <ProPersonFormCreateContainer
      isExistingProPerson={isExistingProPerson}
      createProPerson={createProPerson}
      {...rest}
    />
  );
};

export default ProPersonFormCreateRepository;
