import { OrderBy, useBilikPeopleTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';

import BilikPeopleTableContainer from './bilik-people-table-container';

const BilikPeopleTableRepository: FunctionComponent<unknown> = () => {
  const { loading, data } = useBilikPeopleTableQuery({
    variables: {
      order_by: [{ givenName: OrderBy.Asc }],
    },
  });

  return (
    <BilikPeopleTableContainer
      loading={loading}
      bilikPeople={data?.bilikPerson || []}
    />
  );
};

export default BilikPeopleTableRepository;
