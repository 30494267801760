import { FunctionComponent } from 'react';
import { Loader } from 'semantic-ui-react';
import { ProReviewFormCreate } from './pro-review-form';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useProReviewCreatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';

export const ProReviewsCreatePage: FunctionComponent = () => {
  const { proPresentationId } = useParams();

  const { data, loading } = useProReviewCreatePageQuery({
    variables: { proPresentationId: Number(proPresentationId) },
  });

  if ((!loading && !data?.proPresentationByPk) || !proPresentationId)
    return <Navigate to="/404" replace />; // Pro view not found

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Nouvel avis sur {data?.proPresentationByPk?.name} (
          {data?.proPresentationByPk?.mainTrade?.name})
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <ProReviewFormCreate proPresentationId={proPresentationId} />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};
