import React, { Fragment } from 'react';
import { toast } from 'react-toastify';

export const Toast =
  (func, type) =>
  (name, str): void => {
    func(
      <Fragment>
        <h4 className="Toastify__toast-title">{name}</h4>
        {str}
      </Fragment>,
      {
        autoClose: type !== 'error' && type !== 'warning',
        position: 'bottom-right',
      },
    );
  };

export const ToastError = Toast(toast.error, 'error');
export const ToastInfo = Toast(toast.info, 'info');
export const ToastSuccess = Toast(toast.success, 'success');
export const ToastWarn = Toast(toast.warn, 'warning');
