import React, { FunctionComponent } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import { Timeline } from 'components/timeline';

const TimelinePage: FunctionComponent = () => {
  const { searchValue } = useParams();

  if (!searchValue) return <Navigate to="/404" replace />;

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Timeline particulier : {decodeURIComponent(searchValue)}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <Timeline searchValue={decodeURIComponent(searchValue)} />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default TimelinePage;
