import env from 'env';
import { useFormikContext } from 'formik';
import {
  useClearRelatedProViewsMutation,
  useDeleteProViewAccountsMutation,
  useUpdateProViewDateArchivedMutation,
} from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ToastError } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import { Link } from 'react-router-dom';
import { ProFormCreateModal } from 'components/pro-form-create-modal';
import { useNavigate } from 'react-router';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import { equals } from 'remeda';
import { apiClient } from 'axios-client';
import { getFormErrorMessage } from 'utils/form-error-message';
import { flattenObject } from 'utils/flatten-object';

type ProActionsDropdownProps = {
  proPresentationId: number;
  proViewId: number;
};

const ProActionsDropdown: FunctionComponent<ProActionsDropdownProps> = ({
  proPresentationId,
  proViewId,
}) => {
  const { values, setFieldValue, validateForm, handleSubmit } =
    useFormikContext<ProFormUpdateFields>();

  const [proFormCreateModalOpen, setProFormCreateModalOpen] = useState(false);
  const [clearRelatedProViews] = useClearRelatedProViewsMutation();
  const [updateProViewDateArchived] = useUpdateProViewDateArchivedMutation();
  const [deleteProViewAccounts] = useDeleteProViewAccountsMutation();
  const history = useNavigate();

  const onDraft = useCallback(async (): Promise<void> => {
    const errors = await validateForm();
    const errorValues = Object.values(flattenObject(errors)) as string[];
    const errorMsg = getFormErrorMessage(errorValues);

    if (equals(errors, {})) {
      setFieldValue('status', 'draft');
      setTimeout(() => handleSubmit());
    } else {
      ToastError('Impossible de passer la fiche en brouillon !', errorMsg);
    }
  }, [handleSubmit, setFieldValue]);

  const onOpenWebProView = useCallback(() => {
    window.open(
      `${env.SITE_URL}/presentations/${proViewId}-${values.proPresentation.slug}`,
      '_blank',
    );
  }, [proViewId, values.proPresentation.slug]);

  const onProReviewInvitationPdf = useCallback((): void => {
    window.open(
      `${env.API_URL}/form/review-invitation/generate-paper-review-invitation/${proPresentationId}/${proViewId}`,
    );
  }, [proViewId]);

  const onProReviewsExportPdf = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${env.API_URL}/form/review-pro/export/${proPresentationId}`,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([response.data], {
        type: response.data.type,
      });

      window.open(window.URL.createObjectURL(blob));
    } catch (error) {
      ToastError('Erreur', "Impossible de télécharger l'attestation d'avis");
    }
  }, [proPresentationId]);

  const onArchive = useCallback(async (): Promise<void> => {
    const errors = await validateForm();

    if (equals(errors, {})) {
      await apiClient
        .delete(`/zoho/subscription/pro/${encodeURIComponent(proViewId || '')}`)
        .catch((error) => {
          ToastError('Erreur', 'Impossible de supprimer les abonnements');
          throw error;
        });

      await updateProViewDateArchived({
        variables: {
          proViewId,
          dateArchived: new Date(),
        },
      }).catch((error) => {
        ToastError('Erreur', "Impossible d'archiver la fiche");
        throw error;
      });

      await apiClient
        .post(`/form/pro-view/archive`, {
          proViewId,
        })
        .catch((error) => {
          ToastError(
            'Erreur',
            "erreur lors de la notification de l'archivage aux particuliers",
          );
          throw error;
        });

      await deleteProViewAccounts({
        variables: {
          proViewId,
          accountIds: values.accounts,
        },
      }).catch((error) => {
        ToastError(
          'Erreur',
          "Impossible de supprimer les accès à l'espace pro",
        );
        throw error;
      });

      // Clear related pro views
      await clearRelatedProViews({
        variables: { proViewId },
      }).catch((error) => {
        ToastError('Erreur', "Impossible d'archiver la fiche");
        throw error;
      });
      setFieldValue('status', 'archived');

      setTimeout(() => handleSubmit());
    } else {
      const errorValues = Object.values(flattenObject(errors)) as string[];
      const errorMsg = getFormErrorMessage(errorValues);

      ToastError("Impossible d'archiver la fiche", errorMsg);
    }
  }, [proViewId]);

  return (
    <>
      <Dropdown text="Actions" labeled button floating className="icon">
        <Dropdown.Menu>
          {values.status === 'published' ? (
            <Dropdown.Item
              icon="firstdraft"
              text="Passer en brouillon"
              onClick={onDraft}
            />
          ) : null}
          <Dropdown.Item
            icon="eye"
            text="Voir la fiche"
            onClick={onOpenWebProView}
          />
          <Dropdown.Item
            icon="file pdf"
            text="Formulaire avis papier"
            onClick={onProReviewInvitationPdf}
          />
          <Dropdown.Item
            icon="file pdf"
            text="Attestation d'avis client"
            onClick={onProReviewsExportPdf}
          />
          <Dropdown.Item
            as={Link}
            to={`/pro_review_invitations/create/${proPresentationId}/${proViewId}`}
            icon="plus"
            text="Nouvelle demande d'avis"
          />
          <Dropdown.Item
            as={Link}
            to={`/pro_reviews/create/${proPresentationId}`}
            icon="plus"
            text="Nouvel avis"
          />
          <Dropdown.Item
            icon="plus"
            text="Nouvelle zone"
            onClick={(): void => {
              setProFormCreateModalOpen(true);
            }}
          />
          {values.status !== 'archived' ? (
            <ConfirmModal
              header="Confirmation"
              content={
                <>
                  <p>
                    Liste des actions provoquées par l&apos;archivage de la
                    fiche :
                  </p>
                  <ul>
                    <>
                      <li>
                        Un mail sera envoyé à tous les particuliers ayant un
                        suivi actif.
                      </li>
                      <li>
                        Un sms sera envoyé à tous les particuliers l&apos;ayant
                        contacté les 4 derniers mois.
                      </li>
                      <li>
                        Vous recevrez un mail listant les particuliers avec une
                        ligne fixe à prévenir
                      </li>
                    </>
                    <li>
                      Tous les abonnements liés à cette fiche seront désactivés.
                    </li>
                    <li>
                      Le numéro Call Tracking sera redirigé vers &quot;Pro plus
                      dans Bilik&quot;.
                    </li>
                    <li>La fiche ne sera plus visible sur bilik.fr.</li>
                    <li>
                      Toutes les relances (mails, avis, demande d&apos;avis)
                      seront désactivées.
                    </li>
                    <li>
                      Les accès à l&apos;espace pro liès à cette fiche seront
                      supprimés.
                    </li>
                    <li>
                      La date d&apos;archive sera affectée à la date courante.
                    </li>
                    <li>
                      La fiche sera enlevée des collegues sur lesquels elle
                      était présente.
                    </li>
                  </ul>
                  <p>
                    La fiche va être archivée, vous ne pourrez plus revenir en
                    arrière, Continuer ?
                  </p>
                </>
              }
              trigger={<Dropdown.Item icon="archive" text="Archiver" />}
              onConfirm={onArchive}
            />
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
      <ProFormCreateModal
        open={proFormCreateModalOpen}
        onClose={(): void => {
          setProFormCreateModalOpen(false);
        }}
        proPresentationId={proPresentationId}
        onProCreated={(proViewId): void => {
          history(`/pros/update/${proViewId}`);
        }}
      />
    </>
  );
};

export default ProActionsDropdown;
