import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import { useSelectProPresentationFieldQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

type SelectProPresentationFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & {
  filters?: {
    exceptProPresentationId?: number;
    disabledProPresentationIds?: number[];
    bilikZoneId?: number;
    proPresentationIds?: number[];
  };
};

const SelectProPresentationField: FunctionComponent<
  SelectProPresentationFieldProps
> = ({ filters, multiple, ...rest }) => {
  const { data, loading, error } = useSelectProPresentationFieldQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        proViews: {
          status: {
            _in: ['published', 'draft'],
          },
          bilikZoneId: filters?.bilikZoneId
            ? { _eq: filters.bilikZoneId }
            : undefined,
        },
        id: filters?.exceptProPresentationId
          ? { _neq: filters.exceptProPresentationId }
          : filters?.proPresentationIds
          ? { _in: filters.proPresentationIds }
          : undefined,
      },
    },
  });

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.proPresentation) {
      return data.proPresentation.map((proPresentation) => ({
        key: proPresentation.id,
        value: proPresentation.id,
        text: `${proPresentation.name ?? 'Pas de nom'} ( ${
          proPresentation?.mainTrade?.name ?? 'Pas de catégorie principal'
        } )`,
        disabled: filters?.disabledProPresentationIds?.includes(
          proPresentation.id,
        ),
      }));
    }
    return [];
  }, [data?.proPresentation]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des fiches...';
    }
    if (multiple) {
      return 'Selectionnez des fiches...';
    }
    return 'Selectionnez une fiche...';
  }, [loading, multiple]);

  if (error) return <>Error! ${error.message}</>;

  return (
    <FormField
      type="select"
      loading={loading}
      disabled={loading}
      options={options}
      multiple={multiple}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SelectProPresentationField;
