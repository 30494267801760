import React, { FunctionComponent, useMemo } from 'react';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import SmsReceivedList from 'components/sms-received-list/sms-received-list';

const SmsReceivedPage: FunctionComponent = () => {
  const { currentBilikZone } = useCurrentBilikZone();

  const title: React.ReactElement = useMemo(
    () => (
      <>
        Demandes sms
        <small>
          {currentBilikZone
            ? currentBilikZone.mainCityCode
            : 'Toutes les zones'}
        </small>
      </>
    ),
    [currentBilikZone],
  );

  return <SmsReceivedList title={title} />;
};

export default SmsReceivedPage;
