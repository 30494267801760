import { useProZonesQuery } from 'generated/graphql';
import React, { Fragment, FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Button, Divider, Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledZoneButton = styled(Button)`
  border: 0 !important;
  width: 70px;

  &:hover,
  &:focus,
  &:active {
    background-color: #3c8dbc !important;
    border: 0 !important;
  }
`;

type ProZonesProps = {
  activeRoute?: string;
  proPresentationId: number;
  proViewId: number;
};

const ProZones: FunctionComponent<ProZonesProps> = ({
  proPresentationId,
  proViewId,
  activeRoute,
}) => {
  const { loading, data } = useProZonesQuery({
    variables: {
      proPresentationId,
    },
  });

  const history = useNavigate();

  return (
    <>
      <Divider horizontal>Zones</Divider>

      {!loading && data?.proPresentations ? (
        <>
          {data.proPresentations.map((proPresentation, index) => (
            <Fragment key={index}>
              <p
                style={{
                  fontWeight: 'bold',
                  marginBottom: 0,
                  marginLeft: '4px',
                }}
              >
                {proPresentation.mainTrade?.label}{' '}
                {proPresentation.secondaryTrade
                  ? `/ ${proPresentation.secondaryTrade.label}`
                  : null}
              </p>
              {proPresentation.proViews.map((proView) => (
                <StyledZoneButton
                  type="button"
                  key={`pro-zone-button-${proView.id}`}
                  style={{
                    margin: '5px',
                    cursor: proViewId === proView.id ? 'default' : null,
                  }}
                  color={proViewId !== proView.id ? 'grey' : 'blue'}
                  basic={proViewId !== proView.id}
                  onClick={(event): void => {
                    event.preventDefault();
                    if (proViewId !== proView.id) {
                      history(
                        `/pros/update/${proView.id}${
                          activeRoute ? `/${activeRoute}` : ''
                        }`,
                      );
                    }
                  }}
                >
                  <Icon
                    name="circle"
                    size="small"
                    color={
                      proView.status === 'published'
                        ? 'green'
                        : proView.status === 'draft'
                        ? 'red'
                        : 'grey'
                    }
                  />
                  {proView.bilikZone.mainCityCode}
                </StyledZoneButton>
              ))}
              <Divider hidden />
            </Fragment>
          ))}
        </>
      ) : (
        <Loader active inline="centered" />
      )}
    </>
  );
};

export default ProZones;
