import * as Yup from 'yup';

export const proContractFormValidationSchema = (): Yup.ObjectSchema => {
  return Yup.object().shape({
    startDate: Yup.date().when('isStartDateDelayed', {
      is: true,
      then: Yup.date().required("Date d'entrée en vigueur requise !"),
    }),
    entranceFee: Yup.object({
      appliedAmount: Yup.string().required(
        "Montant du droit d'entrée requis !",
      ),
      monthSpreadAmount: Yup.number().required(),
      managers: Yup.array().min(1, 'Sélectionnez au moins un responsable !'),
    })
      .default(undefined)
      .optional(),
    signatory: Yup.object({
      givenName: Yup.string().required('Prénom requis !'),
      familyName: Yup.string().required('Nom Requis !'),
      title: Yup.string().required('Titre du représentant légal requis !'),
    }),
    subscriptionAmount: Yup.number().required('Sélectionnez une mensualité !'),
    discounts: Yup.array().of(
      Yup.object({
        appliedAmount: Yup.string().required('Montant du palier requis !'),
        occurencesNumber: Yup.number().required(),
      }),
    ),
    interlocutor: Yup.object().shape({
      isActive: Yup.boolean(),
      givenName: Yup.string().when('isActive', {
        is: true,
        then: Yup.string().required('Prénom requis !'),
      }),
      familyName: Yup.string().when('isActive', {
        is: true,
        then: Yup.string().required('Nom requis !'),
      }),
      title: Yup.string().when('isActive', {
        is: true,
        then: Yup.string().required('Titre requis !'),
      }),
    }),
    managers: Yup.array().min(1, 'Sélectionnez au moins un responsable !'),
    specialConditions: Yup.string().optional().max(500, '500 caractères max !'),
  });
};
