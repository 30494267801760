import ContentBody from 'components/content-body/content-body';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import React, { FunctionComponent } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const NotFoundTitle = styled.h1`
  font-size: 100px;
  font-weight: 300;
  text-align: center;
  color: #f39c12;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const NotFoundMessageTitle = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
  color: inherit;
  font-weight: 300;
  font-size: 25px;
  font-family: 'Source Sans Pro', sans-serif;
`;

const NotFoundMessage = styled.p`
  text-align: center;
  margin: 0 0 10px;
`;

const NotFoundPage: FunctionComponent = () => (
  <Content>
    <ContentHeader>
      <ContentHeaderTitle>Erreur 404</ContentHeaderTitle>
    </ContentHeader>
    <ContentBody>
      <NotFoundTitle>404</NotFoundTitle>
      <NotFoundMessageTitle>
        <Icon style={{ color: '#f39c12' }} name="exclamation triangle" />
        Oops ! Page introuvable
      </NotFoundMessageTitle>
      <NotFoundMessage>
        La page que vous recherchez n&apos;est pas / plus disponible !
      </NotFoundMessage>
    </ContentBody>
  </Content>
);

export default NotFoundPage;
