import React, { FunctionComponent } from 'react';

import { CountProPresentationReviewByStatusContainer } from './count-pro-presentation-review-by-status-container';
import { useCountProPresentationReviewByStatusQuery } from 'generated/graphql';

type Props = {
  proPresentationId: number;
  proPresentationName: string;
};

export const CountProPresentationReviewByStatusRepository: FunctionComponent<Props> = ({
  proPresentationId,
}) => {
  const { data, loading } = useCountProPresentationReviewByStatusQuery({
    variables: {
      where: {
        _and: [
          { proPresentationId: { _eq: proPresentationId } },
          { _or: [{ status: { _eq: 'draft' } }, { status: { _eq: 'ok' } }] },
        ],
      },
    },
  });

  return (
    <CountProPresentationReviewByStatusContainer
      countByStatus={data?.countProReviewByStatus}
      loading={loading}
      proPresentationId={proPresentationId}
    />
  );
};
