import React, { FunctionComponent, useCallback } from 'react';
import {
  Trade,
  TradeKeywordInsertInput,
  TradeKeywordSetInput,
  TradeQuestionInsertInput,
  TradeQuestionSetInput,
  useTradeFormCreateSlugRedirectMutation,
  useTradeFormUpdateMutation,
  useTradeFormUpdateQuery,
  useTradeFormUpdateTradeKeywordMutation,
  useTradeFormUpdateTradeQuestionMutation,
} from 'generated/graphql';
import TradesFormUpdateContainer from './trade-form-update-container';
import { useParams } from 'react-router';

export type TradeUpdate = Pick<
  Trade,
  | 'id'
  | 'slug'
  | 'name'
  | 'metaTitle'
  | 'metaDescription'
  | 'pageTitle'
  | 'anchor'
  | 'pageTitleVariant'
  | 'pageSubtitle'
  | 'pageTitleDirectory'
  | 'label'
  | 'isAnonymised'
  | 'isCallRecordable'
  | 'isRgeFilterable'
>;

export type TradeGroup = {
  tradeGroupId: number;
  tradeId: number;
};

const TradesFormUpdateRepository: FunctionComponent = () => {
  const { tradeId } = useParams();

  const { loading, data, refetch } = useTradeFormUpdateQuery({
    variables: {
      id: Number(tradeId),
    },
  });
  const [updateByPkTrade] = useTradeFormUpdateMutation();

  const [updateTradeQuestionMutation] =
    useTradeFormUpdateTradeQuestionMutation();

  const updateTradeQuestion = useCallback(
    async (id: number, tradeQuestion: TradeQuestionSetInput): Promise<void> => {
      await updateTradeQuestionMutation({
        variables: {
          tradeQuestionId: id,
          tradeQuestion,
        },
      });
    },
    [],
  );

  const [updateTradeKeywordMutation] = useTradeFormUpdateTradeKeywordMutation();

  const updateTradeKeyword = useCallback(
    async (id: number, tradeKeyword: TradeKeywordSetInput): Promise<void> => {
      await updateTradeKeywordMutation({
        variables: {
          tradeKeywordId: id,
          tradeKeyword,
        },
      });
    },
    [],
  );

  const updateTrade = useCallback(
    async (
      trade: TradeUpdate,
      insertTradeGroups: TradeGroup[],
      deleteTradeGroupIds: number[],
      insertTradeQuestions: TradeQuestionInsertInput[],
      deleteTradeQuestionsIds: number[],
      insertTradeKeywords: TradeKeywordInsertInput[],
      deleteTradeKeywordIds: number[],
    ) => {
      await updateByPkTrade({
        variables: {
          tradeId: Number(tradeId),
          trade: {
            slug: trade?.slug,
            name: trade?.name,
            pageTitle: trade?.pageTitle,
            anchor: trade?.anchor,
            pageTitleVariant: trade?.pageTitleVariant,
            pageSubtitle: trade?.pageSubtitle,
            pageTitleDirectory: trade?.pageTitleDirectory,
            label: trade?.label,
            metaTitle: trade?.metaTitle,
            metaDescription: trade?.metaDescription,
            isAnonymised: trade?.isAnonymised,
            isCallRecordable: trade?.isCallRecordable,
            isRgeFilterable: trade?.isRgeFilterable,
          },
          deleteTradeGroupIds: deleteTradeGroupIds ?? [],
          insertTradeGroups: insertTradeGroups ?? [],
          insertTradeKeywords: insertTradeKeywords ?? [],
          deleteTradeKeyword: deleteTradeKeywordIds ?? [],
          insertTradeQuestions: insertTradeQuestions ?? [],
          deleteTradeQuestions: deleteTradeQuestionsIds ?? [],
        },
      });
    },
    [updateByPkTrade],
  );

  const [createSlugRedirectMutation] = useTradeFormCreateSlugRedirectMutation();

  const createSlugRedirect = useCallback(
    async (fromSlug: string, toSlug: string) => {
      await createSlugRedirectMutation({
        variables: {
          input: {
            fromslug: fromSlug,
            toslug: toSlug,
            slugtype: 1,
          },
        },
      });
    },
    [],
  );

  return (
    <TradesFormUpdateContainer
      refetch={refetch}
      updateTrade={updateTrade}
      formData={data?.tradeByPk}
      tradeId={Number(tradeId)}
      loading={loading}
      createSlugRedirect={createSlugRedirect}
      updateTradeQuestion={updateTradeQuestion}
      updateTradeKeyword={updateTradeKeyword}
    />
  );
};

export default TradesFormUpdateRepository;
