import React, { FunctionComponent, useEffect, useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { MongoEvent } from 'interfaces/events.interface';
import ProCallsTableView from './calls-table-view';
import { ToastError } from 'utils';
import { CallsTableGloballyBlockedQuery } from 'generated/graphql';

type CallsTableContainerProps = {
  fetchCalls: () => Promise<MongoEvent[]>;
  orderBy?: {
    column: string;
    direction: string;
  };
  filters?: {
    proViewId?: number;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
  globallyBlocked: CallsTableGloballyBlockedQuery['globallyBlockedIdentity'];
};

const CallsTableContainer: FunctionComponent<CallsTableContainerProps> = ({
  fetchCalls,
  orderBy,
  filters,
  onSort,
  onFilter,
  globallyBlocked,
}) => {
  const [calls, setCalls] = useState<MongoEvent[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      setLoading(true);

      const newCalls = await fetchCalls().catch((error) => {
        ToastError('Erreur', "Impossible de charger le journal d'appels");
        setError(true);
        setLoading(false);
        throw error;
      });
      setCalls(newCalls);
      setLoading(false);
    };
    fetch();
  }, [fetchCalls]);

  if (error) {
    return null;
  }

  if (!calls) {
    return (
      <Loader active inline="centered" style={{ marginTop: '14px' }}>
        Chargement...
      </Loader>
    );
  }

  if (calls.length == 0) {
    return (
      <Message>
        <p>Aucun appels enregistrés</p>
      </Message>
    );
  }
  return (
    <ProCallsTableView
      onFilter={onFilter}
      onSort={onSort}
      loading={loading}
      calls={calls}
      filters={{
        proViewId: filters?.proViewId,
      }}
      orderBy={orderBy}
      globallyBlocked={globallyBlocked}
    />
  );
};

export default CallsTableContainer;
