import { ResponsiveBar } from '@nivo/bar';
import React, { FunctionComponent } from 'react';
import { Container, Header, Statistic } from 'semantic-ui-react';

type Props = {
  totalCount: number;
  uniqTotalCount: number;
  chartData: {
    Date: string;
    dateISO: string;
    'Sms uniques': number;
    Sms: number;
  }[];
};

export const ProStatsSmsView: FunctionComponent<Props> = ({
  totalCount,
  uniqTotalCount,
  chartData,
}) => {
  return (
    <Container fluid>
      <Header>Sms</Header>
      <Statistic.Group size="tiny">
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#A87CFF' }}>
            {totalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#A87CFF', fontWeight: 'bold' }}>
            Sms
          </Statistic.Label>
        </Statistic>
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#5500FF' }}>
            {uniqTotalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#5500FF', fontWeight: 'bold' }}>
            Sms uniques
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#A87CFF', '#5500FF']}
          groupMode="grouped"
          data={chartData}
          keys={['Sms', 'Sms uniques']}
          indexBy="Date"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};
