import React, { RefObject } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { ToastError, ToastSuccess } from 'utils';

const CopyButton = ({
  text,
  selection,
}: {
  text: string;
  selection: string;
}) => {
  const handleCopy = async (event) => {
    event.preventDefault();

    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(selection);
    }
    // Executed only in dev mode (http)
    else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = selection;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
        ToastSuccess('Succès', 'Copié dans le presse papier !');
      } catch (error) {
        console.error(error);
        ToastError('Erreur', 'Erreur lors de la copie de la sélection');
      } finally {
        textArea.remove();
      }
    }
  };

  return (
    <Button compact onClick={handleCopy}>
      <Icon name="copy outline" />
      {text}
    </Button>
  );
};

const CopyRefButton = ({
  text,
  elementToCopyRef,
}: {
  text: string;
  elementToCopyRef: RefObject<HTMLDivElement>;
}) => {
  const handleCopy = () => {
    if (!elementToCopyRef.current) {
      ToastError('Erreur', 'Erreur lors de la copie de la sélection');
      return;
    }
    try {
      const docRange = document.createRange();
      const selection = window.getSelection();

      docRange.selectNode(elementToCopyRef.current);
      selection?.removeAllRanges();
      selection?.addRange(docRange);
      document.execCommand('copy');
      ToastSuccess('Succès', 'Copié dans le presse papier !');
    } catch (error) {
      ToastError('Erreur', 'Erreur lors de la copie de la sélection');
    }
  };

  return (
    <Button compact onClick={handleCopy}>
      <Icon name="copy outline" />
      {text}
    </Button>
  );
};

export { CopyButton, CopyRefButton };
