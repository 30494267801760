import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import { OrganizationResult } from './organization-search-field-repository';
import OrganizationSearchFieldView from './organization-search-field-view';

type Props = {
  searchOrganization(criteria: string): Promise<OrganizationResult[]>;
  onSelectResult?: (organization: OrganizationResult) => void;
};

const OrganizationSearchFieldContainer: FunctionComponent<Props> = ({
  searchOrganization,
  onSelectResult,
}) => {
  // State.
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState<OrganizationResult[]>([]);
  const [value, setValue] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [debouncedValue] = useDebounce(value, 600);

  // On click cross.
  const onClickCross = useCallback((): void => {
    setValue('');
    setOpen(false);
  }, []);

  const onResultSelect = useCallback(
    (result): void => {
      setOpen(false);

      if (onSelectResult) {
        onSelectResult(result);
      }
    },
    [value],
  );

  const onSearchChange = useCallback(async (value): Promise<void> => {
    setOpen(true);
    if (value) {
      setValue(value);
    } else {
      setResults([]);
      setValue(undefined);
    }
  }, []);

  useEffect(() => {
    const search = async (criteria: string) => {
      const results = await searchOrganization(criteria);
      setResults(results);
      setLoading(false);
    };

    if (debouncedValue && value) {
      setLoading(true);
      search(value);
    }
  }, [debouncedValue]);

  const onBlur = useCallback((): void => {
    setOpen(false);
  }, []);

  return (
    <OrganizationSearchFieldView
      value={value ?? undefined}
      loading={loading}
      onBlur={onBlur}
      onClickCross={onClickCross}
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      open={open}
      results={results}
    />
  );
};

export default OrganizationSearchFieldContainer;
