import React, { FunctionComponent } from 'react';
import { CountProPresentationReviewByStatusQuery } from 'generated/graphql';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const Relative = styled.span`
  position: relative;
`;

const StyledLabel = styled(Label)`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
`;

type Props = {
  countByStatus?: CountProPresentationReviewByStatusQuery['countProReviewByStatus'];
  draftCount: number;
  okCount: number;
  onProReviewFilterClicked: (status: string) => void;
};

export const CountProPresentationReviewByStatusView: FunctionComponent<
  Props
> = ({ draftCount, okCount, onProReviewFilterClicked }) => {
  return (
    <Relative>
      <Label
        style={{
          marginBottom: '0px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
        color="grey"
        pointing="below"
        onClick={(): void => {
          onProReviewFilterClicked('ok');
        }}
      >
        {okCount || 0}
      </Label>
      {draftCount && draftCount > 0 ? (
        <StyledLabel
          size="mini"
          color="red"
          circular
          onClick={(): void => {
            onProReviewFilterClicked('draft');
          }}
        >
          {draftCount}
        </StyledLabel>
      ) : null}
    </Relative>
  );
};
