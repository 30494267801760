import {
  compareAsc,
  eachMonthOfInterval,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subYears,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { titlecase } from 'utils/titlecase';
import { Datum } from '@nivo/line';
import { CountByMonth } from './count-by-month.type';
import { formatDate } from 'utils';

export const getDataPoints = (
  data: CountByMonth[],
  numberOfYears: number,
  year: number,
): Array<Datum>[] => {
  if (!data) {
    return [];
  }

  const date = new Date();
  date.setFullYear(year);

  const dataPoints: Array<Datum>[] = [];

  for (let i = 0; i < numberOfYears; i++) {
    const months = eachMonthOfInterval({
      start: startOfYear(subYears(date, i)),
      end: endOfYear(subYears(date, i)),
    });

    dataPoints[i] = [];

    months.map((month: Date) => {
      // Dont show future months
      if (compareAsc(startOfMonth(new Date()), startOfMonth(month)) <= 0) {
        return;
      }
      const exists = data.find((countThisMonth) => {
        return (
          formatDate(month.toISOString()) ===
          formatDate(new Date(countThisMonth.month).toISOString())
        );
      });
      const formattedMonth = titlecase(format(month, 'MMMM', { locale: fr }));
      if (exists) {
        dataPoints[i].push({
          x: formattedMonth,
          y: exists.count,
        });
      } else {
        dataPoints[i].push({
          x: formattedMonth,
          y: 0,
        });
      }
    });
  }

  return dataPoints;
};
