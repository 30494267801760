import { FieldArray, useFormikContext } from 'formik';
import { TradeFormFields } from 'pages/trades/trade-form/trade-form-fields-type';
import React, { Fragment, FunctionComponent } from 'react';
import {
  Button,
  Divider,
  Header,
  Icon,
  Loader,
  Segment,
} from 'semantic-ui-react';
import FormField from 'components/form-field/form-field';
type TradeQuestionFieldsProps = {
  tradeId: number;
};

export const TradeQuestionFields: FunctionComponent<TradeQuestionFieldsProps> = ({
  tradeId,
}) => {
  const { values } = useFormikContext<TradeFormFields>();

  if (!values.tradeQuestions && tradeId) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <>
      <FieldArray
        name="tradeQuestions"
        render={(arrayHelpers): React.ReactElement => (
          <Fragment>
            {values.tradeQuestions && values.tradeQuestions.length > 0
              ? values.tradeQuestions.map((_, tradeQuestionIndex) => (
                  <Fragment key={tradeQuestionIndex}>
                    <Header>Question/Réponse n°{tradeQuestionIndex + 1}</Header>
                    <Button
                      basic
                      negative
                      icon
                      floated="right"
                      type="button"
                      onClick={(): void => {
                        arrayHelpers.remove(tradeQuestionIndex);
                      }}
                    >
                      <Icon name="trash alternate outline" />
                    </Button>
                    <FormField
                      label="Question"
                      name={`tradeQuestions.${tradeQuestionIndex}.question`}
                      type="text"
                      placeholder="Écrire la question ici"
                    />
                    <FormField
                      label="Réponse"
                      name={`tradeQuestions.${tradeQuestionIndex}.answer`}
                      type="textarea"
                      rows={20}
                      placeholder="Écrire la réponse ici"
                    />
                    <Divider hidden />
                    <Divider />
                  </Fragment>
                ))
              : null}
            <Segment basic textAlign={'right'}>
              <Button
                type="button"
                positive
                onClick={(): void => {
                  arrayHelpers.push({ question: '', answer: '' });
                }}
              >
                <Icon name="plus" />
                Nouvelle question-réponse
              </Button>
            </Segment>
          </Fragment>
        )}
      />
    </>
  );
};
