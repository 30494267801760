import React, { FunctionComponent } from 'react';
import { useLastCheckedProReviewQuery } from 'generated/graphql';
import { LastCheckedProReviewContainer } from './last-checked-pro-review-container';
type LastCheckedProReviewRepositoryProps = {
  proPresentationId: number; // TODO check this is not optional
};

export const LastCheckedProReviewRepository: FunctionComponent<LastCheckedProReviewRepositoryProps> = ({
  proPresentationId,
}) => {
  const { data, loading } = useLastCheckedProReviewQuery({
    variables: { proPresentationId },
  });

  return (
    <LastCheckedProReviewContainer
      loading={loading}
      lastCheckedDate={
        data && data.proReview.length ? data.proReview[0].checkDate : undefined
      }
    />
  );
};
