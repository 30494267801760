import { apiClient } from 'axios-client';
import {
  ProReviewInsertInput,
  useProReviewFormCreateMutation,
  useProReviewFormCreateQuery,
  useProReviewInvitationCreatedUpdateMutation,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent, useCallback } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import removeProperties from 'utils/remove-properties';
import sendEvent from 'utils/send-event';

import { ProReviewFormCreateContainer } from './pro-review-form-create-container';

type Props = {
  proPresentationId: string;
};

export type ProReviewCreateInput = ProReviewInsertInput & {
  shouldInformPi?: boolean;
  shouldInformPro?: boolean;
};

export const ProReviewFormCreateRepository: FunctionComponent<Props> = ({
  proPresentationId,
}) => {
  const [proReviewFormCreateMutation] = useProReviewFormCreateMutation();
  const [proReviewInvitationCreatedUpdateMutation] =
    useProReviewInvitationCreatedUpdateMutation();
  const { data, loading } = useProReviewFormCreateQuery({
    variables: {
      proPresentationId: Number(proPresentationId),
    },
  });

  const { currentBilikPerson } = useCurrentBilikPerson();

  const formatDate = useCallback((date: string): string => {
    const formatedDate = date.split('(')[0];
    // we remove the GMT data because it bugs sometimes
    return formatedDate.split('GMT')[0];
  }, []);

  const updateRelatedProReviewInvitation = useCallback(
    async (
      proReviewId: number,
      email: string,
      dateOfWork: string | undefined,
    ) => {
      try {
        await proReviewInvitationCreatedUpdateMutation({
          variables: {
            proReviewId,
            proPresentationId: Number(proPresentationId),
            email,
            dateOfWork,
          },
        });
      } catch (err) {
        throw Error(
          'Impossible de mettre à jour les proReviewInvitation en lien avec la proReview',
        );
      }
    },
    [proReviewInvitationCreatedUpdateMutation],
  );

  const createProReview = useCallback(
    async (proReview: ProReviewCreateInput) => {
      if (proReview.email === 'NULL' && proReview.shouldInformPi) {
        ToastError(
          'Erreur',
          "Impossible d'envoyer un email au particulier sans email (valeur NULL).",
        );
        throw Error('Cannot send email');
      }

      const insertProReview = removeProperties(
        proReview,
        'shouldInformPi',
        'shouldInformPro',
      );

      const createdProReview = await proReviewFormCreateMutation({
        variables: {
          proReview: {
            proPresentationId: data?.proPresentationByPk?.id,
            status: 'ok',
            isSpam: false,
            ...insertProReview,
          },
        },
      });

      if (
        createdProReview.data &&
        createdProReview.data?.insertProReviewOne &&
        createdProReview.data?.insertProReviewOne?.id &&
        proReview.email
      ) {
        const dateOfWorkTimestamp = proReview.dateOfWork
          ? formatDate(String(proReview.dateOfWork))
          : undefined;

        await updateRelatedProReviewInvitation(
          createdProReview.data?.insertProReviewOne.id,
          proReview.email,
          dateOfWorkTimestamp,
        );

        if (proReview.shouldInformPi) {
          await apiClient
            .post('/form/review-pro/send-publish-email', {
              proReviewId: createdProReview.data.insertProReviewOne.id,
              receiverType: 'pi',
            })
            .then((response) => {
              if (response.data?.success) {
                ToastSuccess('Succès', 'Email envoyé au particulier.');
              }
            })
            .catch((err) => {
              ToastError(
                'Erreur',
                `Impossible d'envoyer l'email au particulier : ${err}`,
              );
            });
        }

        if (proReview.shouldInformPro) {
          await apiClient
            .post('/form/review-pro/send-publish-email', {
              proReviewId: createdProReview.data.insertProReviewOne.id,
              receiverType: 'pro',
            })
            .then((response) => {
              if (response.data?.success) {
                ToastSuccess('Succès', 'Email envoyé au pro.');
              }
            })
            .catch((err) => {
              ToastError(
                'Erreur',
                `Impossible d'envoyer l'email au pro : ${err}`,
              );
            });
        }
      }

      await sendEvent(
        'FormCreateProReview',
        {
          proReview: {
            ...proReview,
            id: createdProReview.data?.insertProReviewOne?.id,
          },
        },
        {
          pi: {
            email: proReview.email,
            telephone: proReview.telephone,
          },
          pro: {
            proPresentation: {
              name: createdProReview.data?.insertProReviewOne?.proPresentation
                .name,
              mainTrade:
                createdProReview.data?.insertProReviewOne?.proPresentation
                  .mainTrade,
            },
          },
        },
        {
          bilik: {
            id: currentBilikPerson?.id,
            email: currentBilikPerson?.account.email,
            givenName: currentBilikPerson?.givenName,
            familyName: currentBilikPerson?.familyName,
          },
        },
        2,
      );
    },
    [proReviewFormCreateMutation, data, currentBilikPerson],
  );

  return (
    <ProReviewFormCreateContainer
      createProReview={createProReview}
      loading={loading}
    />
  );
};
