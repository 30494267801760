import SmsReceivedList from 'components/sms-received-list/sms-received-list';
import React, { FunctionComponent } from 'react';

type ProSmsReceivedRouteProps = {
  proViewId: number;
};

const ProSmsReceivedRoute: FunctionComponent<ProSmsReceivedRouteProps> = ({
  proViewId,
}) => <SmsReceivedList givenProViewId={proViewId} noMargin={true} />;

export default ProSmsReceivedRoute;
