import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { FieldArray, useFormikContext } from 'formik';
import React, {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useState,
} from 'react';
import FormField from 'components/form-field/form-field';
import {
  ProReviewInvitationsFormFields,
  ProReviewInvitation,
} from './pro-review-invitations-form-fields.type';
import { fr } from 'date-fns/locale';
import HelpText from 'components/help-text/help-text';
import { ProReviewInvitationsFormCreateQuery } from 'generated/graphql';
import { formatDateTime } from 'utils';
import { ProReviewInvitationsFormCreateRepositoryProReview } from './pro-review-invitations-form-create-repository';

type ProReviewInvitationsFormViewProps = {
  defaultProReviewInvitation: ProReviewInvitation;
  proReviews?: ProReviewInvitationsFormCreateRepositoryProReview;
  proReviewInvitations?: ProReviewInvitationsFormCreateQuery['proReviewInvitation'];
};

export const ProReviewInvitationsFormView: FunctionComponent<
  ProReviewInvitationsFormViewProps
> = ({ defaultProReviewInvitation, proReviews, proReviewInvitations }) => {
  const { values, isSubmitting, handleSubmit, handleReset } =
    useFormikContext<ProReviewInvitationsFormFields>();

  const [isWarnProReviewsModalOpen, setIsWarnProReviewsModalOpen] =
    useState(false);
  const [
    isWarnProReviewInvitationsModalOpen,
    setIsWarnProReviewInvitationsModalOpen,
  ] = useState(false);

  const askInviteEmail = values.proReviewInvitations.map(
    (proReviewInvitation) => proReviewInvitation.email,
  );

  const alreadyGivenReview =
    proReviews?.filter((proReview) => {
      return askInviteEmail.includes(proReview.email);
    }) ?? [];

  const alreadyInvited =
    proReviewInvitations?.filter((proReviewInvitations) => {
      return askInviteEmail.includes(proReviewInvitations.email);
    }) ?? [];

  const isSubmitButton =
    alreadyGivenReview?.length === 0 && alreadyInvited?.length === 0;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <FieldArray
        name="proReviewInvitations"
        render={(arrayHelpers): React.ReactElement => (
          <Segment color="blue">
            {values.proReviewInvitations &&
            values.proReviewInvitations.length > 0
              ? values.proReviewInvitations.map((_, index) => (
                  <Fragment key={index}>
                    <Form.Group widths="equal">
                      <input
                        type="hidden"
                        name={`proReviewInvitations.${index}.proViewId`}
                        value={`proReviewInvitations.${index}.proViewId`}
                      />

                      <FormField
                        type="text"
                        label="Prénom"
                        name={`proReviewInvitations.${index}.givenName`}
                        required
                      />
                      <FormField
                        type="text"
                        label="Nom"
                        name={`proReviewInvitations.${index}.familyName`}
                        required
                      />
                      <FormField
                        type="email"
                        placeholder="Ex : admin@bilik.fr"
                        label="Email du particulier"
                        name={`proReviewInvitations.${index}.email`}
                        required
                      />
                      <FormField
                        label="Date des travaux"
                        values={values}
                        name={`proReviewInvitations.${index}.dateOfWork`}
                        type="date"
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        locale={fr}
                        clearable
                      />
                      <Button
                        style={{
                          marginTop: '24px',
                          minWidth: '45px',
                          marginRight: '10px',
                        }}
                        icon
                        negative
                        basic
                        type="button"
                        onClick={(): void => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        <Icon name="trash alternate outline" />
                      </Button>
                    </Form.Group>
                    <Divider />
                  </Fragment>
                ))
              : null}
            <Container fluid textAlign="right">
              <Button
                icon
                style={{ minWidth: '45px' }}
                type="button"
                onClick={(): void => {
                  arrayHelpers.push(defaultProReviewInvitation);
                }}
              >
                <Icon name="plus" />
              </Button>
            </Container>
          </Segment>
        )}
      />
      <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
        <HelpText>
          Si le client n&apos;a pas fait d&apos;avis dans les 15 jours, il est
          relancé automatiquement
        </HelpText>

        <Button
          primary
          type={isSubmitButton ? 'submit' : 'button'}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            if (alreadyGivenReview?.length > 0) {
              setIsWarnProReviewsModalOpen(true);
            } else if (alreadyInvited?.length > 0) {
              setIsWarnProReviewInvitationsModalOpen(true);
            }
          }}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Container>

      <WarnProReviewsModal
        isOpen={isWarnProReviewsModalOpen}
        setIsOpen={setIsWarnProReviewsModalOpen}
        proReviews={alreadyGivenReview}
      />
      <WarnProReviewInvitationsModal
        isOpen={isWarnProReviewInvitationsModalOpen}
        setIsOpen={setIsWarnProReviewInvitationsModalOpen}
        alreadyInvited={alreadyInvited}
      />
    </Form>
  );
};

const WarnProReviewsModal = ({
  isOpen,
  setIsOpen,
  proReviews,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  proReviews: ProReviewInvitationsFormCreateRepositoryProReview;
}): React.ReactElement => {
  const { isSubmitting, submitForm } =
    useFormikContext<ProReviewInvitationsFormFields>();

  return (
    <Modal
      open={isOpen}
      onClose={(): void => {
        setIsOpen(false);
      }}
    >
      <Header icon="send" content="Attention" />
      <Modal.Content>
        Attention :
        <ul>
          {proReviews.map((proReview, i) => {
            return (
              <li key={proReview.email + i}>
                Le particlier {proReview.givenName} {proReview.familyName}
                &nbsp;(
                <a
                  href={`/pros/update/${proReview.proPresentation.proViews[0].id}/pro_reviews?privateIndividualEmail=${proReview.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {proReview.email})
                </a>{' '}
                a déja fait un avis le {formatDateTime(proReview.dateCreated)}{' '}
              </li>
            );
          })}
        </ul>
        Es-tu sûr de vouloir le relancer de nouveau ?
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setIsOpen(false)}>
          Annuler
        </Button>
        <Button
          primary
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={submitForm}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const WarnProReviewInvitationsModal = ({
  isOpen,
  setIsOpen,
  alreadyInvited,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  alreadyInvited: ProReviewInvitationsFormCreateQuery['proReviewInvitation'];
}) => {
  const { isSubmitting, submitForm } =
    useFormikContext<ProReviewInvitationsFormFields>();

  if (alreadyInvited) {
    return (
      <Modal open={isOpen} onClose={() => setIsOpen(false)} closeIcon>
        <Header icon="send" content="Attention" />
        <Modal.Content>
          Tu as déjà demandé à{' '}
          {alreadyInvited.length === 1 ? `ce particulier` : `ces particuliers`}{' '}
          de faire un avis :
          <ul>
            {alreadyInvited.map((proReviewInvitation, i) => {
              return (
                <li key={proReviewInvitation.email + i}>
                  {proReviewInvitation.givenName}{' '}
                  {proReviewInvitation.familyName} &nbsp;
                  <a
                    href={`/pros/update/${proReviewInvitation.proPresentation?.proViews[0].id}/review_invitations?email=${proReviewInvitation.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {proReviewInvitation.email}
                  </a>{' '}
                  invité le {formatDateTime(proReviewInvitation.dateCreated)}
                </li>
              );
            })}
          </ul>
          Es-tu sûr de vouloir {alreadyInvited.length === 1 ? `le` : `les`}{' '}
          relancer de nouveau ?
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setIsOpen(false)}>
            Annuler
          </Button>
          <Button
            primary
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={submitForm}
          >
            <Icon name="save" />
            Enregistrer
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};
