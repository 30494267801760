import { Icon, Label, Message } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import Raw from 'components/raw/raw';
import SolicitationAutorespondToggle from 'components/solicitations/solicitation-autorespond-toggle/solicitation-autorespond-toggle';
import env from 'env';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

const FormCreateSolicitationV0: FunctionComponent<
  FormCreateSolicitationProps
> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      userAgent={event.initiator.pi.userAgent}
      icon="mail"
      title="Demande mail"
      initiator="particulier"
      takingContact
    >
      <h3>
        Nouvelle demande mail à{' '}
        <span>{event.actors.pro.proPresentation.name}</span> (
        {event.data.solicitation.proView.bilikZone.mainCityCode})
      </h3>
      <div>
        {event.data.solicitation.subject ? (
          <>
            <b>Sujet : </b>
            <Raw>{event.data.solicitation.subject}</Raw>
            <br />
            <br />
          </>
        ) : null}
        <b>Description du projet : </b>
        <br />
        <br />
        <Raw>{event.data.solicitation.message}</Raw>

        {Object.hasOwn(event.data.solicitation, 'delay') ? (
          <>
            <br />
            <br />
            <b>Délai : </b>
            <Raw>{event.data.solicitation.delay ?? 'Non Renseigné'}</Raw>
          </>
        ) : null}
        <br />
        <br />
        <b>Relance :</b>
        <br />
        <SolicitationAutorespondToggle id={event.data.solicitation._id} />
      </div>
      <div>
        <TimelineSolicitationActions solicitation={event.data.solicitation} />
        <b>Email: </b>
        <Label color="yellow">
          <Icon name="times" size="small" />
          Non dispo (Old data)
        </Label>
      </div>
    </TimelineEvent>
  );
};

const FormCreateSolicitationV1: FunctionComponent<
  FormCreateSolicitationProps
> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      userAgent={event.initiator.pi.userAgent}
      icon="mail"
      title="Demande mail"
      initiator="particulier"
      takingContact
    >
      <h3>
        Nouvelle demande mail à{' '}
        <span>{event.actors.pro.proPresentation.name}</span> (
        {event.data.solicitation.proView.bilikZone.mainCityCode})
      </h3>
      <div>
        {event.data.solicitation.subject ? (
          <>
            <b>Sujet : </b>
            <Raw>{event.data.solicitation.subject}</Raw>
            <br />
            <br />
          </>
        ) : null}
        <b>Description du projet : </b>
        <br />
        <Raw>{event.data.solicitation.message}</Raw>
        {Object.hasOwn(event.data.solicitation, 'delay') ? (
          <>
            <br />
            <br />
            <b>Délai : </b>
            <Raw>{event.data.solicitation.delay ?? 'Non Renseigné'}</Raw>
          </>
        ) : null}
        <br />
        <br />
        <b>Relance :</b>
        <br />
        <SolicitationAutorespondToggle id={event.data.solicitation._id} />
      </div>
      <TimelineSolicitationActions solicitation={event.data.solicitation} />
    </TimelineEvent>
  );
};

const FormCreateSolicitationV2: FunctionComponent<
  FormCreateSolicitationProps
> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      userAgent={event.initiator.pi.userAgent}
      icon="mail"
      title="Demande mail"
      initiator="particulier"
      takingContact
      color={
        event.data.solicitation.isRedirectedToBilik === false ||
        event.data.solicitation.isRedirectedToBilik === undefined
          ? undefined
          : 'red'
      }
    >
      {event.data.solicitation.isRedirectedToBilik === false ||
      event.data.solicitation.isRedirectedToBilik === undefined ? (
        <h3>
          Nouvelle demande mail à{' '}
          <span>
            {event.actors.pro.proPresentation.name} (
            {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
            {event.actors.pro.proPresentation.mainTrade?.name})
          </span>
        </h3>
      ) : (
        <h3>
          Tentative de demande mail à{' '}
          <span>
            {event.actors.pro.proPresentation.name} (
            {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
            {event.actors.pro.proPresentation.mainTrade?.name})
          </span>{' '}
          vient d&lsquo;être bloquée et a été redirigée au service client (pour
          aiguillage éventuel).
        </h3>
      )}
      <div>
        {event.data.solicitation.subject ? (
          <>
            <b>Sujet : </b>
            <Raw>{event.data.solicitation.subject}</Raw>
            <br />
            <br />
          </>
        ) : null}
        <b>Description du projet : </b>
        <br />
        <Raw>{event.data.solicitation.message}</Raw>
        {Object.hasOwn(event.data.solicitation, 'delay') ? (
          <>
            <br />
            <br />
            <b>Délai : </b>
            <Raw>{event.data.solicitation.delay ?? 'Non Renseigné'}</Raw>
          </>
        ) : null}
        <br />
        <br />
        <b>Relance :</b>
        <br />
        <SolicitationAutorespondToggle id={event.data.solicitation._id} />
      </div>
      <div>
        <TimelineSolicitationActions solicitation={event.data.solicitation} />
      </div>
    </TimelineEvent>
  );
};

type FormCreateSolicitationProps = {
  event: MongoEvent;
};

const FormCreateSolicitation: FunctionComponent<
  FormCreateSolicitationProps
> = ({ event }) => {
  if (event.version === 0) {
    return <FormCreateSolicitationV0 event={event} />;
  } else if (event.version === 1) {
    return <FormCreateSolicitationV1 event={event} />;
  } else if (event.version === 2) {
    return <FormCreateSolicitationV2 event={event} />;
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour FormCreateSolicitation (version ${event.version})`}
    />
  );
};

export default FormCreateSolicitation;
