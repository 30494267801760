import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import { useSelectTradeFieldQuery } from 'generated/graphql';
import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';

import { DropdownItemProps, Popup } from 'semantic-ui-react';

type SelectTradeFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & {
  tradeId?: number;
  trades?: number[];
  bilikZoneId: number;
  onOptionsLoad?: (options: DropdownItemProps[]) => void;
  renderLabel?: (item: DropdownItemProps) => ReactNode;
};

const SelectTradeField: FunctionComponent<SelectTradeFieldProps> = ({
  multiple,
  onOptionsLoad,
  bilikZoneId,
  renderLabel,
  ...rest
}) => {
  const { data, loading, error } = useSelectTradeFieldQuery({
    variables: {
      bilikZoneId: bilikZoneId,
    },
    fetchPolicy: 'no-cache',
  });

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.trade) {
      return (
        data.trade
          .map((trade) => {
            const mainTradeCount =
              trade.mainTradeProPresentationAggregate.aggregate?.count || 0;

            const secondaryTradeCount =
              trade.secondaryTradeProPresentationAggregate.aggregate?.count ||
              0;

            return {
              key: trade.id,
              value: trade.id,
              searchableText: trade.name,
              text: `${trade.name} -
              ${trade.tradeProViewsAggregate.aggregate?.count} (${
                mainTradeCount + secondaryTradeCount
              })`,
            };
          })
          // Filter out duplicates
          .filter(
            (option, index, self) =>
              index === self.findIndex((t) => t.value === option.value),
          )
      );
    }
    return [];
  }, [data?.trade]);

  useEffect(() => {
    if (onOptionsLoad && !loading) {
      onOptionsLoad(options);
    }
  }, [options, loading]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des catégories...';
    }
    if (multiple) {
      return 'Selectionnez des catégories...';
    }
    return 'Selectionnez un catégorie...';
  }, [loading, multiple]);

  if (error) return <Fragment>Error! {error.message}</Fragment>;

  return (
    <FormField
      type="select"
      disabled={loading}
      renderLabel={renderLabel}
      loading={loading}
      options={options}
      multiple={multiple}
      placeholder={placeholder}
      search
      {...rest}
    />
  );
};

export default SelectTradeField;
