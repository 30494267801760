import React, { FunctionComponent, useCallback } from 'react';
import ProMandateFormCreateContainer from './pro-mandate-form-create-container';
import { apiClient } from 'axios-client';
import { ProMandateFormProps } from './pro-mandate-form-modal';

type ProMandateFormCreateRepositoryProps = ProMandateFormProps & {
  currentBilikPersonId: number;
};

export type CreateMandateInput = {
  proOrganizationId: number;
  zohoOrganizationId: number;
  bic: string;
  iban: string;
};

const ProMandateFormCreateRepository: FunctionComponent<
  ProMandateFormCreateRepositoryProps
> = ({ onProMandateCreated, ...rest }) => {
  const createProMandate = useCallback(
    async (proMandate: CreateMandateInput) => {
      return await apiClient.post(`/form/pro-mandate/create`, {
        proOrganizationId: proMandate.proOrganizationId,
        zohoOrganizationId: proMandate.zohoOrganizationId,
        bic: proMandate.bic,
        iban: proMandate.iban,
      });
    },
    [onProMandateCreated],
  );

  return (
    <ProMandateFormCreateContainer
      createProMandate={createProMandate}
      onProMandateCreated={onProMandateCreated}
      {...rest}
    />
  );
};

export default ProMandateFormCreateRepository;
