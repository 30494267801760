import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { formatE164Telephone, ToastError, ToastSuccess } from 'utils';
import { Loader } from 'semantic-ui-react';
import { ProReviewFormFields } from './pro-review-form-fields.type';
import { ProReviewFormView } from './pro-review-form-view';
import { ProReviewInsertInput } from 'generated/graphql';
import { proReviewFormValidationSchema } from './pro-review-form-validation-schema';
import { addCrs } from 'utils/geometry';
import { ProReviewCreateInput } from './pro-review-form-create-repository';
import removeProperties from 'utils/remove-properties';

type Props = {
  createProReview: (proReview: ProReviewInsertInput) => Promise<unknown>;
  loading: boolean;
};

export const ProReviewFormCreateContainer: FunctionComponent<Props> = ({
  createProReview,
  loading,
}) => {
  const onSubmit = useCallback(
    async (
      values: ProReviewFormFields,
      actions: FormikHelpers<ProReviewFormFields>,
    ) => {
      try {
        // Set source = "BILIK" if the source is not external
        if (!values.isExternalSource) {
          values.source = 'BILIK';
        }

        const proReviewInput: ProReviewCreateInput = removeProperties(
          values,
          'isExternalSource',
        );

        await createProReview({
          ...proReviewInput,
          reviewBody: values.reviewBody.trim(),
          privateComment: values.privateComment.trim(),
          comment: values.comment.trim(),
          email: values.email !== 'NULL' ? values.email.toLowerCase() : 'NULL',
          geoCoordinates:
            values?.addressLocality !== 'NULL'
              ? addCrs(values?.geoCoordinates)
              : undefined,
          telephone:
            values.telephone !== 'NULL'
              ? formatE164Telephone(values.telephone)
              : 'NULL',
          source: values.source === '' ? 'BILIK' : values.source,
        });

        ToastSuccess('Succès', 'Avis enregistré.');

        actions.setSubmitting(false);

        actions.resetForm();
      } catch (error) {
        ToastError('Erreur', "Impossible d'enregistrer l'avis");
      }
    },
    [createProReview],
  );

  const initialValues: ProReviewFormFields = useMemo(() => {
    return {
      addressLocality: '',
      comment: '',
      privateComment: '',
      dateOfWork: undefined,
      email: '',
      familyName: '',
      givenName: '',
      reviewAspect: '',
      reviewBody: '',
      telephone: '',
      rating: 0,
      geoCoordinates: undefined,
      isExternalSource: false,
      source: '',
      reply: undefined, // No reply at creation
      shouldInformPi: false,
      shouldInformPro: false,
    };
  }, []);

  if (loading) {
    return (
      <Loader style={{ marginTop: '20px' }} inline>
        Chargement...
      </Loader>
    );
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={proReviewFormValidationSchema(!!initialValues.reply)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <ProReviewFormView action="create" />
    </Formik>
  );
};
