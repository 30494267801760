import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { getBilikUserName } from 'utils/get-bilik-username';
import { formatTelephone } from 'utils';

type Props = {
  event: MongoEvent;
};

const StopSmsDeleted: FunctionComponent<Props> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="mobile alternate"
      title="Réactivation des sms automatiques"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        <span>
          {event.initiator.bilik.givenName} {event.initiator.bilik.familyName}
        </span>{' '}
        a réactivé l&nbsp;envoi de sms automatiques pour le :{' '}
        {formatTelephone(event.data.stopSms.telephone)}
      </h3>
    </TimelineEvent>
  );
};

export default StopSmsDeleted;
