import React, { FunctionComponent } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { ProReviewInvitationsFormCreate } from './pro-review-invitations-form';
import { useProReviewInvitationsCreatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';

const ProReviewInvitationsCreatePage: FunctionComponent = () => {
  const { proPresentationId, proViewId } = useParams();

  const { loading, data } = useProReviewInvitationsCreatePageQuery({
    variables: {
      id: Number(proPresentationId),
    },
  });

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  if (
    (!loading && !data?.proPresentationByPk) ||
    !proViewId ||
    !proPresentationId
  ) {
    return <Navigate to="/404" replace />; // Pro view not found
  }

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Demandes d’avis : {data?.proPresentationByPk?.name} -{' '}
          {data?.proPresentationByPk?.mainTrade?.name}{' '}
          {data?.proPresentationByPk?.secondaryTrade?.name
            ? `et ${data?.proPresentationByPk?.secondaryTrade?.name}`
            : ''}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <ProReviewInvitationsFormCreate
            proPresentationId={Number(proPresentationId)}
            proViewId={Number(proViewId)}
          />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default ProReviewInvitationsCreatePage;
