import env from 'env';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Container,
  DropdownItemProps,
  Grid,
  Loader,
  Progress,
  Table,
} from 'semantic-ui-react';
import { formatDate } from 'utils/locale';
import { ToastError, ToastSuccess } from 'utils/toast';
import { ProDocumentTypes } from '../../../../interfaces/pro-document-types';
import DocumentsUpload from 'components/documents-upload/documents-upload';
import DocumentQualification from 'components/documents-qualification/documents-qualification';
import { Document } from 'components/documents-qualification/document.type';
import { ProDocument } from './pro-document.type';
import DocumentPreviewModal from 'components/document-preview-modal/document-preview-modal';
import { apiClient } from 'axios-client';
import { getToken } from 'utils';

type DocumentsUploadContainerProps = {
  proOrganizationId: number;
  onDocumentUploaded: () => Promise<unknown>;
};

export type UploadProgress = {
  timeStamp: number;
  loaded: number;
  total: number;
  type: string;
};

/**
 * A container responsible of uploading new pro documents and qualificate them
 */
const DocumentsUploadContainer: FunctionComponent<
  DocumentsUploadContainerProps
> = ({ proOrganizationId, onDocumentUploaded }) => {
  const [selectedProDocuments, setSelectedProDocuments] = useState<Document[]>(
    [],
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>();

  const documentOptions = useMemo(() => {
    // We could have used a reducer here since options.length <= ProDocumentTypes.length
    const options: DropdownItemProps[] = [];

    ProDocumentTypes.forEach((value, key) => {
      // Exclude Mandate and IMPORT
      if (!['import', 'mandate', 'contract'].includes(key)) {
        options.push({
          key: key,
          value: key,
          text: value,
        });
      }
    });

    return options;
  }, []);

  return (
    <>
      <DocumentsUpload onDocumentsChanged={setSelectedProDocuments} />
      {selectedProDocuments.length > 0 ? (
        <>
          <DocumentQualification
            onQualificationChanged={(event, proDocument, newValue): void =>
              // @TODO: Find a better way to do this since this isn't clear
              // Explanation: Here, we are trying to change the component state based
              // on the new qualification (only change what chaged).
              setSelectedProDocuments(
                selectedProDocuments.map((_proDocument) => {
                  if (_proDocument === proDocument) {
                    return {
                      ..._proDocument,
                      type: newValue,
                    };
                  }
                  return _proDocument;
                }),
              )
            }
            onRemove={(proDocument): void =>
              // @TODO: Find a better way to do this since this isn't clear
              // Explanation: Here, we are trying to remove an item from a state array.
              setSelectedProDocuments(
                selectedProDocuments.filter(
                  (selectedFile) => selectedFile !== proDocument,
                ),
              )
            }
            documents={selectedProDocuments}
            qualificationOptions={documentOptions}
            title="Documents à ajouter :"
          />
          {uploadProgress ? (
            <Progress
              precision={1}
              value={uploadProgress.loaded}
              total={uploadProgress.total}
              progress="percent"
              color="teal"
            />
          ) : null}
          <Container fluid textAlign="right">
            <Button
              style={{ marginTop: '16px', minWidth: '34px' }}
              type="button"
              onClick={async (): Promise<void> => {
                setIsUploading(true);

                const proDocumentsQualificationless =
                  selectedProDocuments.filter(
                    (proDocument) => !proDocument.type,
                  );

                if (proDocumentsQualificationless.length > 0) {
                  ToastError(
                    'Erreur',
                    "Il manque la qualification d'au moins un des documents",
                  );
                  return setIsUploading(false);
                }

                // We didn't find a better way to upload files.
                // FormData seemed the best option here.
                const formData = new FormData();

                selectedProDocuments.map((selectedProDocument) => {
                  formData.append('documents', selectedProDocument.file);
                  formData.append('documentKinds', selectedProDocument.type);
                });

                formData.append('proOrganizationId', String(proOrganizationId));

                await apiClient
                  .post(`/attachment/upload-pro-documents`, formData, {
                    onUploadProgress: (progressEvent) => {
                      setUploadProgress(
                        progressEvent as unknown as UploadProgress,
                      );
                    },
                  })
                  // If request succeed
                  .then(async () => {
                    ToastSuccess('Succès', 'Les documents ont été ajoutés');
                    await onDocumentUploaded();
                  })
                  // If request failed
                  .catch(() => {
                    ToastError('Erreur', "Les documents n'ont pas été ajoutés");
                  })
                  // In both case
                  .finally(() => {
                    setSelectedProDocuments([]);
                    setUploadProgress(undefined);
                    setIsUploading(false);
                  });
              }}
              disabled={isUploading}
              loading={isUploading}
              primary
              content={`Ajouter ${selectedProDocuments.length} document(s)`}
            />
          </Container>
        </>
      ) : null}
    </>
  );
};

type DocumentPreviewModalState = {
  open: boolean;
  document?: {
    mimeType: string;
    url: string;
    name: string;
    options?: unknown;
  };
};

type ProDocumentsProps = {
  proOrganizationId: number;
};

/**
 * Component responsible of upload pro documents and qualificate them.
 * Also contains a list of actually uploaded pro documents.
 * By default the renderer is triggered by a button.
 */
const ProDocuments: FunctionComponent<ProDocumentsProps> = ({
  proOrganizationId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [proDocuments, setProDocuments] = useState<ProDocument[]>();
  const [documentPreviewModalState, setDocumentPreviewModalState] =
    useState<DocumentPreviewModalState>({ open: false });

  const fetchProDocuments = useCallback(async () => {
    setLoading(true);

    const response = await apiClient
      .get<ProDocument[]>(`/attachment/pro-documents-informations`, {
        params: {
          pro_organization_id: proOrganizationId,
        },
      })
      .catch((error) => {
        ToastError('Erreur', 'Impossible de charger les documents');
        setLoading(false);
        throw error;
      });

    setProDocuments(response.data);
    setLoading(false);
  }, [proOrganizationId]);

  useEffect(() => {
    setProDocuments(undefined);
  }, [proOrganizationId]);

  // Loading state
  if (loading) {
    return (
      <Loader
        size="large"
        active
        inline="centered"
        content="Chargement des documents..."
      />
    );
  }

  if (!proDocuments) {
    return (
      <Grid>
        <Grid.Column textAlign="center">
          <Button
            style={{ marginTop: '15px', width: '210px' }}
            onClick={fetchProDocuments}
          >
            Voir et Gérer les documents
          </Button>
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <>
      <DocumentsUploadContainer
        onDocumentUploaded={fetchProDocuments}
        proOrganizationId={proOrganizationId}
      />
      {/* Only display the documents table if there are uploaded documents */}
      {proDocuments.length > 0 ? (
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Date dernière modification</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {proDocuments.map((proDocument) => {
              const documentType =
                ProDocumentTypes.get(proDocument.metaData.type) ?? 'Autre'; // Default to 'Autre' if type is not found (to match old types)
              const documentName = proDocument.key.split('/').pop();
              const formattedLastModifiedDate = formatDate(
                proDocument.lastModifiedDate,
              );

              return (
                <Table.Row key={proDocument.key}>
                  {/* Nom */}
                  <Table.Cell>{documentName}</Table.Cell>
                  {/* Type */}
                  <Table.Cell>
                    {/* The color is different for import type since it require a manual action from the end user */}
                    <span
                      style={{
                        color: documentType === 'IMPORT' ? '#F2711C' : 'black',
                      }}
                    >
                      {documentType}
                    </span>
                  </Table.Cell>

                  {/* Date dernière modiciation */}
                  <Table.Cell>{formattedLastModifiedDate}</Table.Cell>

                  {/* Actions */}
                  <Table.Cell textAlign="right">
                    <Button
                      type="button"
                      icon="eye"
                      onClick={(): void => {
                        setDocumentPreviewModalState({
                          open: true,
                          document: {
                            ...proDocument,
                            url: `${
                              env.API_URL
                            }/attachment/object-by-key?key=${encodeURIComponent(
                              proDocument.key,
                            )}`,
                            name: proDocument.key.split('/').pop() || '',
                            options: {
                              httpHeaders: {
                                Authorization: `Bearer ${getToken()}`,
                              },
                            },
                          },
                        });
                      }}
                    />
                    <Button
                      type="button"
                      icon="download"
                      onClick={(): void => {
                        window.open(
                          `${
                            env.API_URL
                          }/attachment/object-by-key-token?key=${encodeURIComponent(
                            proDocument.key,
                          )}&token=${getToken()}`,
                          '_blank',
                        );
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <p>Aucun document</p>
      )}
      <DocumentPreviewModal
        open={documentPreviewModalState.open}
        onClose={(): void => {
          setDocumentPreviewModalState({ open: false });
        }}
        document={documentPreviewModalState.document}
      />
    </>
  );
};

export default ProDocuments;
