import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { map, pipe, uniq } from 'remeda';
import {
  SearchProps,
  SearchResultData,
  SearchResultsProps,
} from 'semantic-ui-react';
import { useDebounce } from 'use-debounce';
import { ToastError } from 'utils';
import { AccountSearchFilterView } from './account-search-filter-view';
import { AccountSearchFilterQuery } from 'generated/graphql';

type AccountSearchFilterContainerProps = {
  accounts: AccountSearchFilterQuery['searchAccount'];
  fetchAccounts: (criteria?: string) => Promise<void>;
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  style?: CSSProperties;
  placeholder?: string;
};

const AccountSearchFilterContainer: FunctionComponent<
  AccountSearchFilterContainerProps
> = ({ accounts, fetchAccounts, ...rest }) => {
  const [value, setValue] = useState<string>();
  const [searchResults, setSearchResults] = useState<SearchResultsProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedValue] = useDebounce(value, 600);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (debouncedValue) {
        await fetchAccounts(debouncedValue).catch((error) => {
          ToastError('Erreur', 'Impossible de rechercher le professionnel');
          setLoading(false);
          throw error;
        });
      }
    };
    fetch();
  }, [debouncedValue]);

  const onSearchChange = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      { value }: SearchProps,
    ) => {
      setValue(value);
      if (value) {
        // only when value are not empty
        // Set loading to true here to start loading before debouncing
        setLoading(true);
      } else {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (accounts) {
      const newSearchResults = pipe(
        accounts,
        map((account) => ({
          title: account.email,
          key: `search-account-${account.id}`,
          value: account.id,
        })),
        uniq(),
      );
      setSearchResults(newSearchResults);
    }
    setLoading(false); // Disabling loading only when searchResults are computed
  }, [accounts]);

  return (
    <AccountSearchFilterView
      loading={loading}
      value={value}
      onSearchChange={onSearchChange}
      searchResults={searchResults}
      {...rest}
    />
  );
};

export default AccountSearchFilterContainer;
