import { OrderBy, useTradesInfoTableQueryLazyQuery } from 'generated/graphql';
import React, { FunctionComponent, useEffect } from 'react';

import { TradesInfoTableContainer } from './trades-info-table-container';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';

type Props = {
  filters?: {
    tradeId?: number;
  };
  orderBy: {
    column: string;
    direction: string;
  };

  onSort?: (column: string, direction: string) => void;
};

export const TradesInfoTableRepository: FunctionComponent<Props> = ({
  filters,
  orderBy,
  onSort,
}) => {
  const { currentBilikZone } = useCurrentBilikZone();

  const [getTradesInfoTableQuery, { loading, data }] =
    useTradesInfoTableQueryLazyQuery({
      variables: {
        order_by: [
          {
            tradeGroupsAggregate: {
              count: OrderBy.Desc,
            },
          },
        ],
        where: {
          id: {
            _eq: filters?.tradeId,
          },
        },
        whereProView: {
          bilikZoneId: {
            _eq: currentBilikZone?.id,
          },
          status: {
            _eq: 'published',
          },
        },
        whereProPresentation: {
          proViews: {
            bilikZoneId: {
              _eq: currentBilikZone?.id,
            },
            status: {
              _eq: 'published',
            },
          },
        },
        whereTradeProView: {
          proView: {
            bilikZoneId: {
              _eq: currentBilikZone?.id,
            },
            status: {
              _eq: 'published',
            },
          },
        },
      },
    });

  useEffect(() => {
    getTradesInfoTableQuery();
  }, [getTradesInfoTableQuery]);

  return (
    <TradesInfoTableContainer
      loading={loading}
      orderBy={orderBy}
      onSort={onSort}
      trades={data?.trade || []}
      currentBilikZone={currentBilikZone}
    />
  );
};
