import React, { FunctionComponent, useCallback, useMemo } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { getBilikUserName } from 'utils/get-bilik-username';
import ModalBlockedIdentityInfo from './modal-blocked-identity-info';
import { formatTelephone } from 'utils';
import { GloballyBlocked } from 'components/globally-blocked';
import { Icon, Label } from 'semantic-ui-react';
import { useGloballyBlockedIdentityQuery } from 'generated/graphql';

type Props = {
  event: MongoEvent;
};

const GloballyBlockedIdentityCreated: FunctionComponent<Props> = ({
  event,
}) => {
  const eventIdentifier = event.data.globallyBlockedIdentity.identifier;

  // Entity is either 'telephone' or 'email'
  const [entity, value] = eventIdentifier.split(':');

  const isTelephoneEntity = entity === 'telephone';

  const { data } = useGloballyBlockedIdentityQuery({
    variables: {
      identifier: event.data.globallyBlockedIdentity.identifier,
    },
  });

  const globallyBlockedIdentities = useMemo(() => {
    return data?.globallyBlockedIdentity || [];
  }, [data?.globallyBlockedIdentity]);

  const isGloballyBlocked = useCallback(
    (eventIdentifier: string): boolean => {
      return globallyBlockedIdentities.some(
        (blockedEntity) => blockedEntity.identifier === eventIdentifier,
      );
    },
    [globallyBlockedIdentities],
  );

  return (
    <TimelineEvent
      date={event.date}
      color="red"
      icon="dont"
      title="Identité bloquée"
      initiator={event.initiator.bilik ? 'bilik-user' : 'pro'}
      bilikUserName={
        event.initiator.bilik
          ? event.initiator.bilik.familyName === 'Automatique'
            ? 'bot'
            : getBilikUserName(event.initiator.bilik)
          : undefined
      }
    >
      <h3>
        {event.initiator.bilik ? (
          <>
            {event.initiator.bilik.familyName === 'Automatique' ? (
              <span>Le système</span>
            ) : (
              <span>
                {event.initiator.bilik.givenName}{' '}
                {event.initiator.bilik.familyName}
              </span>
            )}
          </>
        ) : null}
        {event.initiator.pro ? (
          <span>{event.initiator.pro.proPresentation.name}</span>
        ) : null}{' '}
        a bloqué l&apos;identité :{' '}
        {isTelephoneEntity ? formatTelephone(value) : <span>{value}</span>}
      </h3>
      <div>
        <p>
          <b>Raison du bloquage : </b>
          {event.data.globallyBlockedIdentity.reason}
          {event.initiator.bilik &&
          event.initiator.bilik.familyName === 'Automatique' ? (
            <ModalBlockedIdentityInfo
              reason={event.data.globallyBlockedIdentity.reason}
            />
          ) : null}
        </p>
      </div>
      <div>
        {isGloballyBlocked(eventIdentifier) ? (
          <GloballyBlocked
            triggerBlocked={
              <Label as="a">
                <Icon name="dont" />
                Débloquer
              </Label>
            }
            onActionTriggered={(): void => {
              window.location.reload();
            }}
            telephone={isTelephoneEntity ? value : undefined}
            email={!isTelephoneEntity ? value : undefined}
          />
        ) : null}
      </div>
    </TimelineEvent>
  );
};

export default GloballyBlockedIdentityCreated;
