import {
  Search,
  SearchProps,
  SearchResultData,
  SearchResultsProps,
} from 'semantic-ui-react';
import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import Raw from 'components/raw/raw';

// Override style to fix search input when we are into an <Form> component
const SearchStyled = styled(Search)`
  input {
    border-radius: 3px !important;
    border-color: #767676 !important;
    padding: 5px !important;

    &:focus {
      border-color: #3c8dbc !important;
    }
  }
`;

type PrivateIndividualSearchViewProps = {
  value?: string;
  onSearchChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: SearchProps,
  ) => void;
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  searchResults?: SearchResultsProps;
  loading?: boolean;
};

const PrivateIndividualSearchView: FunctionComponent<
  PrivateIndividualSearchViewProps
> = ({ value, searchResults, loading, onSearchChange, onResultSelect }) => (
  <SearchStyled
    style={{
      display: 'inline-block',
      marginLeft: '12px',
    }}
    input={{
      icon: 'search',
      iconPosition: 'left',
    }}
    noResultsMessage="Pas de résultats"
    placeholder="Particulier..."
    value={value}
    results={searchResults}
    onSearchChange={onSearchChange}
    loading={loading}
    // Change renderer to allow brealines (with \n) in [description] field [hacky but working]
    resultRenderer={({ title, description }): ReactElement => (
      <div key="content" className="content">
        {title && <div className="title">{title}</div>}
        {description && (
          <div className="description">
            <Raw>{description}</Raw>
          </div>
        )}
      </div>
    )}
    onResultSelect={onResultSelect}
  />
);

export default PrivateIndividualSearchView;
