import {
  Button,
  Icon,
  Search,
  SearchProps,
  SearchResultData,
  SearchResultsProps,
  Label,
} from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const SearchStyled = styled(Search)`
  input {
    border-radius: 3px !important;
    border-color: #767676 !important;
    padding: 5px !important;

    &:focus {
      border-color: #3c8dbc !important;
    }
  }
`;

type Props = {
  value?: string;
  loading: boolean;
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  onSearchChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: SearchProps,
  ) => void;
  searchResults: SearchResultsProps | undefined;
  defaultValue?: string;
  onClick: () => void;
};

const TradeGroupSearchFilterView: FunctionComponent<Props> = ({
  value,
  loading,
  searchResults,
  onResultSelect,
  defaultValue,
  onSearchChange,
  onClick,
}) => {
  if (defaultValue) {
    return (
      <div style={{ marginLeft: '12px', display: 'inline-block' }}>
        <Button as="div" labelPosition="right">
          <Button color="blue" onClick={onClick}>
            <Icon name="delete" />
          </Button>
          <Label as="a" basic pointing="left">
            {defaultValue}
          </Label>
        </Button>
      </div>
    );
  }

  return (
    <SearchStyled
      fluid
      style={{
        display: 'inline-block',
        marginLeft: '12px',
      }}
      input={{
        icon: 'search',
        iconPosition: 'left',
      }}
      noResultsMessage="Pas de résultats"
      placeholder="Univers..."
      value={value}
      results={searchResults}
      loading={loading}
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
    />
  );
};

export default TradeGroupSearchFilterView;
