import React, { Fragment, FunctionComponent } from 'react';
import { Icon } from 'semantic-ui-react';

type ProFullNameProps = {
  name: string;
  mainCityCode?: string;
  mainTradeName: string | undefined;
  status?: string;
};

const ProFullName: FunctionComponent<ProFullNameProps> = ({
  name,
  mainCityCode,
  mainTradeName,
  status,
}) => {
  const mainCityCodeText = mainCityCode ? `- ${mainCityCode}` : '';
  const mainTradeNameText = mainTradeName ? ` - ${mainTradeName}` : '';
  return (
    <Fragment>
      {status ? (
        <Icon
          name="circle"
          size="small"
          color={
            status === 'published'
              ? 'green'
              : status === 'draft'
              ? 'red'
              : 'grey'
          }
        />
      ) : null}
      {name} {mainCityCodeText} <em>{mainTradeNameText}</em>
    </Fragment>
  );
};

export default ProFullName;
