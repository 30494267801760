import React, { FunctionComponent, useCallback } from 'react';
import CallsGraphContainer from './calls-graph-container';
import { Serie } from '@nivo/line';
import { endOfYear, startOfYear, subYears } from 'date-fns';
import { convertTableData } from '../utils/convert-table-data';
import { apiClient } from 'axios-client';
import { CountByMonth } from '../utils/count-by-month.type';
import { count } from 'console';

type CallsGraphRepositoryProps = {
  bilikZoneId?: number;
  proViewIds?: number[];
  year?: number;
  compareYear?: number;
  filters?: any[];
};

const now = new Date();

const CallsGraphRepository: FunctionComponent<CallsGraphRepositoryProps> = ({
  bilikZoneId,
  proViewIds,
  year = now.getFullYear(),
  compareYear = subYears(now, 1).getFullYear(),
  filters,
}) => {
  const getYearData = useCallback(
    async (year: number, proViewIds?: number[], bilikZoneId?: number) => {
      const date = new Date(now);
      date.setFullYear(year);

      const result = await apiClient.post('event/bilik-calls-by-month', {
        after: startOfYear(date),
        before: endOfYear(date),
        bilikZoneId,
        proViewIds,
        filters,
      });

      const countByMonth: CountByMonth[] = result.data;

      return countByMonth;
    },
    [],
  );

  const fetchData = useCallback(async () => {
    const data: CountByMonth[] = [];

    if (compareYear !== year) {
      const compareYearData = await getYearData(
        compareYear,
        proViewIds,
        bilikZoneId,
      );

      data.push(...compareYearData);
    }

    const yearData = await getYearData(year, proViewIds, bilikZoneId);

    data.push(...yearData);

    return convertTableData(data, [year, compareYear]);
  }, [bilikZoneId, proViewIds, year, compareYear]);

  return <CallsGraphContainer fetchData={fetchData} />;
};

export default CallsGraphRepository;
