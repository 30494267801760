import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { getBilikUserName } from 'utils/get-bilik-username';
import { format } from 'path';
import { formatTelephone } from 'utils';

type Props = {
  event: MongoEvent;
};

const GloballyBlockedIdentityDeleted: FunctionComponent<Props> = ({
  event,
}) => {
  const [entity, value] =
    event.data.globallyBlockedIdentity.identifier.split(':');

  return (
    <TimelineEvent
      date={event.date}
      icon="dont"
      title="Identité débloquée"
      initiator={event.initiator.bilik ? 'bilik-user' : 'pro'}
      bilikUserName={
        event.initiator.bilik
          ? getBilikUserName(event.initiator.bilik)
          : undefined
      }
    >
      <h3>
        {event.initiator.bilik ? (
          <span>
            {event.initiator.bilik.givenName} {event.initiator.bilik.familyName}
          </span>
        ) : null}
        {event.initiator.pro ? (
          <span>{event.initiator.pro.proPresentation.name}</span>
        ) : null}{' '}
        a débloqué l&apos;identité :{' '}
        {entity === 'telephone' ? formatTelephone(value) : <span>{value}</span>}
      </h3>
    </TimelineEvent>
  );
};

export default GloballyBlockedIdentityDeleted;
