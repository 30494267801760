import React, { FunctionComponent, useCallback } from 'react';
import { Label } from 'semantic-ui-react';
import { DuplicateCheckerIndicatorView } from './duplicate-checker-indicator-view';

type DuplicateCheckerIndicatorContainerProps = {
  isDuplicateReview: boolean;
  pi: {
    email: string;
    familyName: string;
    givenName: string;
  };
  proPresentationId: number;
  loading: boolean;
};

export const DuplicateCheckerIndicatorContainer: FunctionComponent<
  DuplicateCheckerIndicatorContainerProps
> = ({ isDuplicateReview, pi, proPresentationId, loading }) => {
  const openProReviews = useCallback(() => {
    window.open(
      `/pro_reviews?privateIndividualEmail=${pi.email}&familyName=${pi.familyName}&givenName=${pi.givenName}&proPresentationId=${proPresentationId}&isAllZones=1`, // Filters
    );
  }, [proPresentationId, pi.email, pi.givenName, pi.familyName]);

  if (loading) {
    return (
      <Label size="mini" color="grey">
        Chargement...
      </Label>
    );
  }

  return (
    <DuplicateCheckerIndicatorView
      openProReviews={openProReviews}
      isDuplicateReview={isDuplicateReview}
    />
  );
};
