import { useUpdateProReviewIsSpamByIdMutation } from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { useReviewLocker } from 'hooks/use-review-locker/use-review-locker';
import React, { FunctionComponent, useCallback } from 'react';
import { Commands, triggerCommand } from 'utils/commands/command';
import sendEvent from 'utils/send-event';
import { ProReviewSpamButtonContainer } from './pro-review-spam-button-container';

type ProReviewSpamButtonRepositoryProps = {
  isSpam: boolean;
  proReviewId: number;
  refetch: () => void;
  disabled?: boolean;
};

export const ProReviewSpamButtonRepository: FunctionComponent<
  ProReviewSpamButtonRepositoryProps
> = ({ isSpam, proReviewId, refetch, disabled }) => {
  const [updateProReviewIsSpamByIdMutation, { loading }] =
    useUpdateProReviewIsSpamByIdMutation();

  const { currentBilikPerson } = useCurrentBilikPerson();

  const { unlock } = useReviewLocker(proReviewId);

  const toggleSpam = useCallback(async () => {
    await unlock();

    const updatedProReview = (
      await updateProReviewIsSpamByIdMutation({
        variables: { id: proReviewId, isSpam: !isSpam },
      })
    ).data?.updateProReviewByPk;

    sendEvent(
      'ProReviewToggleSpam',
      {
        proReview: {
          id: updatedProReview?.id,
          isSpam: !isSpam,
        },
      },
      {
        pi: {
          email: updatedProReview?.email,
          telephone: updatedProReview?.telephone,
        },

        pro: {
          proPresentation: {
            name: updatedProReview?.proPresentation.name,
            mainTrade: {
              name: updatedProReview?.proPresentation?.mainTrade?.name,
            },
          },
        },
      },
      {
        bilik: {
          id: currentBilikPerson?.id,
          email: currentBilikPerson?.account.email,
          givenName: currentBilikPerson?.givenName,
          familyName: currentBilikPerson?.familyName,
        },
      },
      2,
    );

    triggerCommand(Commands.REFETCH_REVIEW_COUNTER);

    refetch();
  }, [isSpam, proReviewId, currentBilikPerson, refetch, unlock]);

  return (
    <ProReviewSpamButtonContainer
      isSpam={isSpam}
      loading={loading}
      toggleSpam={toggleSpam}
      disabled={disabled}
    />
  );
};
