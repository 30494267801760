import { Icon, Label } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

import parseUserAgent from 'ua-parser-js';

type Props = {
  userAgent: string | null;
};

type UserAgent = {
  device: {
    type: string;
  };
  os: {
    name: string;
  };
};

const TimelineEventDevice: FunctionComponent<Props> = ({ userAgent }) => {
  if (userAgent) {
    const userAgentData: UserAgent = parseUserAgent();
    switch (userAgentData.device.type) {
      case 'tablet':
        return (
          <Label>
            <Icon name="tablet alternate" />
            Tablette
          </Label>
        );
      case 'mobile':
        return (
          <Label>
            <Icon name="mobile alternate" />
            Mobile
          </Label>
        );
      default:
        if (userAgentData.os.name) {
          return (
            <Label>
              <Icon name="computer" />
              PC
            </Label>
          );
        }
        return null;
    }
  }
  return null;
};

export default TimelineEventDevice;
