import { InvoiceStatus } from 'components/invoice-status/invoice-status';
import { ZohoCreditNote } from 'interfaces/zoho-credit-note.interface';
import React, { Fragment, FunctionComponent } from 'react';
import { Button, Icon, Message, Table } from 'semantic-ui-react';
import { formatDate } from 'utils';

type ProCreditNotesViewProps = {
  fetchMore: () => void;
  creditNotes: ZohoCreditNote[];
  loading: boolean;
};

const ProCreditNotesView: FunctionComponent<ProCreditNotesViewProps> = ({
  creditNotes,
  fetchMore,
  loading,
}) => {
  if (creditNotes.length === 0) {
    return <Message>Il n&apos;y a pas d&apos;avoir</Message>;
  }

  return (
    <>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>N° avoir</Table.HeaderCell>
            <Table.HeaderCell>Montant</Table.HeaderCell>
            <Table.HeaderCell>Restant</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Facture(s) affectée(s)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {creditNotes.map((creditNote) => {
            const formattedDate = formatDate(creditNote.date);
            const taxlessBalance = Math.ceil(creditNote.total / 1.2);

            return (
              <Fragment key={creditNote.creditnote_id}>
                <Table.Row>
                  <Table.Cell>{formattedDate}</Table.Cell>
                  <Table.Cell>{creditNote.creditnote_number}</Table.Cell>
                  <Table.Cell>{taxlessBalance}€</Table.Cell>
                  <Table.Cell>{creditNote.balance}€ TTC</Table.Cell>
                  <Table.Cell>
                    <InvoiceStatus status={creditNote.status} />
                  </Table.Cell>
                  <Table.Cell>{creditNote.applied_invoices}</Table.Cell>
                </Table.Row>
              </Fragment>
            );
          })}
        </Table.Body>
      </Table>
      <Button
        type="button"
        floated="right"
        loading={loading}
        disabled={loading}
        icon
        labelPosition="right"
        onClick={fetchMore}
      >
        <Icon name="arrow down" />
        Voir plus
      </Button>
    </>
  );
};

export default ProCreditNotesView;
