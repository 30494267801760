import AccountFormModal from 'components/account-form-modal/account-form-modal';
import React, { FunctionComponent } from 'react';
import { Button, Icon, Label, Modal } from 'semantic-ui-react';

type ProBouncedSolicitationViewProps = {
  resendSolicitationsToPro: () => Promise<void>;
  isExplanationModalOpen: boolean;
  setIsExplanationModalOpen: (boolean) => void;
  accountModalOpen: boolean;
  setAccountModalOpen: (boolean) => void;
  isActionInProgress: boolean;
  mainAccountId: number;
  reactivateEmailInPostmark: () => Promise<void>;
};

export const ProBouncedSolicitationView: FunctionComponent<
  ProBouncedSolicitationViewProps
> = ({
  isActionInProgress,
  isExplanationModalOpen,
  accountModalOpen,
  resendSolicitationsToPro,
  mainAccountId,
  setIsExplanationModalOpen,
  setAccountModalOpen,
  reactivateEmailInPostmark,
}) => {
  return (
    <>
      <Label
        as="a"
        onClick={(): void => setIsExplanationModalOpen(true)}
        color="red"
      >
        <>
          <Icon name="exclamation triangle" />
          Important : Cliquez-ici ! (demande non transmise au pro)
        </>
      </Label>
      <Modal
        size="tiny"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={isExplanationModalOpen}
      >
        <Modal.Header>Action à faire</Modal.Header>
        <Modal.Content>
          <p>
            Cette demande n&apos;a pas été transmise au pro. Pour réparer ce
            problème tu as 2 options :
          </p>

          <Button
            loading={isActionInProgress}
            disabled={isActionInProgress}
            basic
            style={{ marginBottom: '8px' }}
            onClick={(): void => {
              setAccountModalOpen(true);
            }}
            color="red"
          >
            <Icon name="pencil" />
            Modifier l&apos;adresse mail du pro ET renvoyer les demandes non
            reçues
          </Button>
          <AccountFormModal
            accountId={mainAccountId}
            open={accountModalOpen}
            onClose={(): void => {
              setAccountModalOpen(false);
            }}
            onAccountUpdate={resendSolicitationsToPro}
          />
          <Button
            loading={isActionInProgress}
            disabled={isActionInProgress}
            onClick={reactivateEmailInPostmark}
            basic
            color="red"
          >
            <Icon name="mail" />
            L&apos;adresse mail du pro fonctionne de nouveau, renvoyer
            simplement les demandes non reçues
          </Button>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            icon="remove"
            content="Fermer"
            onClick={(): void => setIsExplanationModalOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
