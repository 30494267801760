import { Formik } from 'formik';
import { ProPersonInsertInput } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { formatE164Telephone, ToastError, ToastSuccess } from 'utils';
import { capitalizeName } from 'utils/capitalize';
import { ProPersonFormFields } from './pro-person-form-fields-type';
import { ProPersonFormModalProps } from './pro-person-form-modal';
import proPersonFormValidationSchema from './pro-person-form-validation-schema';
import ProPersonFormView from './pro-person-form-view';

type ProPersonFormCreateContainerProps = ProPersonFormModalProps & {
  createProPerson: (proPersonInput: ProPersonInsertInput) => Promise<void>;
  isExistingProPerson: (
    givenName: string,
    familyName: string,
    email: string,
    telephone: string,
  ) => Promise<boolean>;
};

const ProPersonFormCreateContainer: FunctionComponent<
  ProPersonFormCreateContainerProps
> = ({ createProPerson, isExistingProPerson, onClose, ...rest }) => {
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const onSubmit = useCallback(async (values: ProPersonFormFields) => {
    // Check for duplicate proPerson (same givenName/familyName/account.email)
    if (
      await isExistingProPerson(
        values.givenName,
        values.familyName,
        values.email,
        values.telephone,
      )
    ) {
      setIsDuplicate(true);
      return;
    }

    await createProPerson({
      givenName: capitalizeName(values.givenName).trim(),
      familyName: capitalizeName(values.familyName).trim(),
      telephone: values.telephone
        ? formatE164Telephone(values.telephone)
        : undefined,
      email: values.email.toLowerCase().trim(),
    }).catch((error) => {
      ToastError('Erreur', `Impossible de créer le contact`);
      throw error;
    });

    if (onClose) {
      onClose();
    }

    ToastSuccess('Succès', `Contact créé`);
  }, []);

  const initialValues: ProPersonFormFields = useMemo(() => {
    return {
      givenName: '',
      familyName: '',
      telephone: '',
      email: '',
    };
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={proPersonFormValidationSchema}
      validateOnChange={false}
    >
      <ProPersonFormView
        onClose={onClose}
        isDuplicate={isDuplicate}
        {...rest}
      />
    </Formik>
  );
};

export default ProPersonFormCreateContainer;
