import React, { FunctionComponent } from 'react';
import ProReviewsList from '../../../../components/pro-reviews-list/pro-reviews-list';

type ProReviewsRouteProps = {
  proPresentationId: number;
};

const ProReviewsRoute: FunctionComponent<ProReviewsRouteProps> = ({
  proPresentationId,
}) => (
  <ProReviewsList givenProPresentationId={proPresentationId} noMargin={true} />
);

export default ProReviewsRoute;
