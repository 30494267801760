import Label from 'components/label/label';
import React, { Fragment, FunctionComponent, SyntheticEvent } from 'react';
import {
  Button,
  Divider,
  DropdownItemProps,
  FormField,
  Grid,
  Icon,
  Select,
} from 'semantic-ui-react';
import { Document } from './document.type';

type DocumentQualificationProps = {
  documents: Document[];
  onQualificationChanged: (
    event: SyntheticEvent,
    newProDocument: Document,
    qualificationValue: string,
  ) => void;
  onRemove: (proDocument) => void;
  qualificationOptions: DropdownItemProps[];
  title?: string;
};

/**
 * Component responible of pro document qualification (document type) and document upload managment (delete)
 */
const DocumentQualification: FunctionComponent<DocumentQualificationProps> = ({
  documents,
  onQualificationChanged,
  onRemove,
  qualificationOptions,
  title,
}) => {
  return (
    <Grid>
      {title ? <h4>{title}</h4> : null}
      {documents.map((proDocument, i) => (
        <Fragment key={proDocument.file.name}>
          <Grid.Row columns="3">
            <Grid.Column verticalAlign="bottom">
              <p>{proDocument.file.name}</p>
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <FormField>
                <Label required htmlFor={`${proDocument.file.name}`}>
                  Type de document
                </Label>
                <Select
                  onChange={(event, data): void => {
                    onQualificationChanged(
                      event,
                      proDocument,
                      data.value as string,
                    );
                  }}
                  value={proDocument.type}
                  options={qualificationOptions}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column verticalAlign="bottom" textAlign="right">
              <Button
                icon
                negative
                type="button"
                basic
                onClick={(): void => onRemove(proDocument)}
              >
                <Icon name="trash alternate outline" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          {i - 1 !== documents.length ? <Divider /> : null}
        </Fragment>
      ))}
    </Grid>
  );
};

export default DocumentQualification;
