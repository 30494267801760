import { ProPeopleTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
// import { ProPeopleTableQuery } from 'generated/graphql';
import { Loader, Message } from 'semantic-ui-react';
import { ProPeopleTableView } from './pro-people-table-view';

type ProPeopleTableContainerProps = {
  loading: boolean;
  proPeople?: ProPeopleTableQuery['proPerson'];
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
};

export const ProPeopleTableContainer: FunctionComponent<
  ProPeopleTableContainerProps
> = ({ loading, proPeople, onSort, orderBy }) => {
  if (!proPeople) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }
  if (!proPeople.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  return (
    <ProPeopleTableView
      loading={loading}
      proPeople={proPeople}
      onSort={onSort}
      orderBy={orderBy}
    />
  );
};
