import styled from 'styled-components';

const ContentHeaderTitle = styled.div`
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  display: inline;

  small {
    font-size: 15px;
    font-weight: 300;
    padding-left: 8px;
    color: #777;
    display: inline-block;
    line-height: 1;
  }
`;

export default ContentHeaderTitle;
