import env from 'env';
import React, { FunctionComponent, useMemo } from 'react';
import { Button, Icon, Loader, Message } from 'semantic-ui-react';
import { GalleryImageModal } from './pro-gallery-field-type';
import { ProMediaObjectFields } from '../pro-form-update-fields-type';
import Dropzone from 'components/dropzone/dropzone';
import HelpText from 'components/help-text/help-text';
import { useDropzone } from 'react-dropzone';
import SortableItemModal from './sortable-item-modal/sortable-item-modal';
import { ImageSortableList } from './image-sortable-list/image-sortable-list';

type ProGalleryFieldViewProps = {
  loading: boolean;
  onImagesDropped: (files: File[]) => Promise<void>;
  proMediaObjects: ProMediaObjectFields[];
  onSortEnd: (hashs: string[]) => void;
  imageModal: GalleryImageModal;
  openImageModal: (hash: string) => void;
  closeImageModal: () => void;
  deleteImageByHash: (hash: string) => void;
  isProAbleToUploadImage: boolean;
  getNextImageHash: (hash: string) => string | null;
  getPrevImageHash: (hash: string) => string | null;
  maxImageSize: number;
  onDeleteAll: () => void;
};

export const ProGalleryFieldView: FunctionComponent<
  ProGalleryFieldViewProps
> = ({
  loading,
  closeImageModal,
  imageModal,
  openImageModal,
  onImagesDropped,
  onSortEnd,
  proMediaObjects,
  deleteImageByHash,
  isProAbleToUploadImage,
  getPrevImageHash,
  getNextImageHash,
  maxImageSize,
  onDeleteAll,
}) => {
  const currentImagesNumber = useMemo(
    () => `${proMediaObjects.length} / 20`,
    [proMediaObjects],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
    onDrop: onImagesDropped,
  });

  return (
    <>
      <div style={{ marginBottom: '1em' }}>
        {isProAbleToUploadImage ? (
          <Dropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          >
            <Icon size="huge" name="image outline" />
            <div style={{ marginTop: '10px' }}>
              {!loading ? (
                <Button positive type="button">
                  <Icon name="upload" />
                  Ajouter des images {currentImagesNumber}
                </Button>
              ) : (
                <Loader inline active />
              )}
            </div>
          </Dropzone>
        ) : (
          <Message
            header="Vous ne pouvez plus mettre d'images en ligne"
            content={`Veuillez supprimer des images existantes pour en mettre d'autres en ligne : ${currentImagesNumber}`}
          />
        )}
      </div>
      <HelpText>
        Attention au format ! Éviter les photos supérieure à{' '}
        {maxImageSize / 1048576} Mo, pensez à les redimensionner dans ce cas.
      </HelpText>
      <br />
      <ImageSortableList
        hashs={proMediaObjects
          .sort((a, b) => a.position - b.position)
          .map((p) => p.hash)}
        activationConstraint={{ distance: 10 }}
        onDelete={deleteImageByHash}
        openImageModal={openImageModal}
        onSortEnd={onSortEnd}
        onDeleteAll={onDeleteAll}
      />
      {imageModal.hash ? (
        <SortableItemModal
          isOpen={imageModal.isOpen}
          onClose={closeImageModal}
          imgSrc={`${env.API_URL}/images/${imageModal.hash}/full/0,0/0/default.jpg`}
          imageHash={imageModal.hash}
          onDelete={(imageHash: string): void => {
            deleteImageByHash(imageHash);
            closeImageModal();
          }}
          pagination={{
            onImageChange: (imageHash: string): void => {
              openImageModal(imageHash);
            },
            prevImageHash: getPrevImageHash(imageModal.hash),
            nextImageHash: getNextImageHash(imageModal.hash),
          }}
        />
      ) : null}
    </>
  );
};
