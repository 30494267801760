import {
  useAccountEmailSuggestionsMutation,
  useAccountEmailSuggestionsQuery,
} from 'generated/graphql';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import {
  addKeycloakUserGroup,
  createKeycloakUser,
  getGroupId,
  getKeycloakUserIdByEmail,
  ToastSuccess,
} from 'utils';

type AccountEmailSuggestionsProps = {
  emailSuggestions: string[];
  onSuggestionAdded?: (accountId) => void;
};

const AccountEmailSuggestions: FunctionComponent<
  AccountEmailSuggestionsProps
> = ({ emailSuggestions, onSuggestionAdded }) => {
  const { data, loading } = useAccountEmailSuggestionsQuery({
    variables: { emails: emailSuggestions },
  });

  const accounts = useMemo(() => data?.account, [data?.account]);

  /**
   * it return a boolean to validate succes
   * we add the pro to keycloak by creating a keycloak user, and adding this user to the pro-person group
   */
  const addToKeycloak = useCallback(
    async (email?: string): Promise<boolean> => {
      if (email) {
        try {
          // we set the email as the keycloak account username
          await createKeycloakUser({
            email,
            username: email,
            enabled: true,
          });

          const groupId = await getGroupId('pro-people/pros');
          const keycloakUserId = await getKeycloakUserIdByEmail(email);

          if (groupId && keycloakUserId) {
            const status = await addKeycloakUserGroup(keycloakUserId, groupId);
            return status === 204;
          }
        } catch (err) {
          console.error(err);
        }
      }

      return false;
    },
    [],
  );

  const [accountEmailSuggestionsMutation] =
    useAccountEmailSuggestionsMutation();

  const addSuggestion = useCallback(
    async (emailSuggestion: string): Promise<void> => {
      if (accounts) {
        const account = accounts.filter(
          (account) => account.email === emailSuggestion,
        )[0];

        // Account already exist ?
        if (account && onSuggestionAdded) {
          onSuggestionAdded(account.id);
        } else {
          const result = await accountEmailSuggestionsMutation({
            variables: { account: { email: emailSuggestion } },
          });

          const keycloakAccountCreationSuccess = await addToKeycloak(
            emailSuggestion,
          );

          if (!keycloakAccountCreationSuccess) {
            throw Error('Keycloak account creation failed');
          }

          ToastSuccess('Succès', "Accès à l'espace pro créé");

          if (onSuggestionAdded) {
            onSuggestionAdded(result.data?.insertAccountOne?.id);
          }
        }
      }
    },
    [accounts],
  );

  // Just return null when loading
  if (loading && !accounts) {
    return null;
  }

  // No suggestions
  if (emailSuggestions.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: '10px' }}>
      {emailSuggestions.map((emailSuggestion, index) => (
        <Fragment key={`account-email-suggestion-${index}`}>
          <a
            href="#/"
            onClick={(): void => {
              addSuggestion(emailSuggestion);
            }}
          >
            Suggestion, ajouter {emailSuggestion}
          </a>
          <br />
        </Fragment>
      ))}
    </div>
  );
};

export default AccountEmailSuggestions;
