import {
  BilikZoneInsertInput,
  useBilikZoneFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';

import BilikZoneFormCreateContainer from './bilik-zone-form-create-container';

const BilikZoneFormCreateRepository: FunctionComponent = () => {
  const [insertBilikZoneOne] = useBilikZoneFormCreateMutation();

  const createBilikZone = useCallback(
    async (bilikZone: BilikZoneInsertInput) =>
      insertBilikZoneOne({
        variables: {
          object: bilikZone,
        },
      }),
    [insertBilikZoneOne],
  );

  return <BilikZoneFormCreateContainer createBilikZone={createBilikZone} />;
};

export default BilikZoneFormCreateRepository;
