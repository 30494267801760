import ConfirmModal from 'components/confirm-modal/confirm-modal';
import { CitiesTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Icon,
  Label,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';

type CitiesTableProps = {
  loading: boolean;
  cities: CitiesTableQuery['city'];
  bilikZoneId?: number;
  onDeleteCity: (cityId: number) => void;
};

const CitiesTableView: FunctionComponent<CitiesTableProps> = ({
  cities,
  loading,
  bilikZoneId,
  onDeleteCity,
}) => {
  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            {!bilikZoneId ? <Table.HeaderCell>Zone</Table.HeaderCell> : null}
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {cities.map((city) => (
            <Table.Row key={city.id}>
              <Table.Cell>{city.name}</Table.Cell>
              {!bilikZoneId ? (
                <Table.Cell>
                  {city.bilikZone?.name ? (
                    <Link to={`/bilik_zones/update/${city.bilikZone.id}`}>
                      {city.bilikZone.name}
                    </Link>
                  ) : (
                    <Label color="red">Non renseignée</Label>
                  )}
                </Table.Cell>
              ) : null}
              <Table.Cell textAlign="right">
                <Link
                  to={`/cities/update/${city.id}${
                    bilikZoneId ? `?bilikZoneId=${bilikZoneId}` : ''
                  }`}
                >
                  <Popup
                    content="Modifier"
                    position="top center"
                    trigger={
                      <Button primary icon>
                        <Icon name="pencil alternate" />
                      </Button>
                    }
                  />
                </Link>
                <ConfirmModal
                  header="Confirmation"
                  content={
                    <p>
                      Êtes vous sûr de vouloir supprimer la ville{' '}
                      <strong>{city.name}</strong> ?
                    </p>
                  }
                  onConfirm={(): void => onDeleteCity(city.id)}
                  trigger={
                    <Button negative icon>
                      <Icon name="trash" />
                    </Button>
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default CitiesTableView;
