import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

export const getProViewStatusColor = (status: string): SemanticCOLORS => {
  switch (status) {
    case 'published':
      return 'green';
    case 'draft':
      return 'red';
    default:
      return 'grey';
  }
};
