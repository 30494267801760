import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';

type ProReviewRepliedProps = {
  event: MongoEvent;
};

const ProReviewRepliedV1: FunctionComponent<ProReviewRepliedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    icon="comment"
    title="Avis répondu"
    initiator="pro"
  >
    <h3>
      Réponse à l&apos;avis sur{' '}
      <span>{event.actors.pro.proPresentation.name}</span>
      via
      <span>
        {' '}
        {event.initiator.bilik.givenName} {event.initiator.bilik.familyName}
      </span>
    </h3>
    <div>
      <b>Réponse: </b>
      {event.data.proReview.reply}
    </div>
    <div>
      <Label
        as="a"
        onClick={(): void => {
          window.open(
            `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            '_blank',
          );
        }}
      >
        <Icon name="eye" size="small" />
        Voir avis
      </Label>
    </div>
  </TimelineEvent>
);

const ProReviewRepliedV2: FunctionComponent<ProReviewRepliedProps> = ({
  event,
}) => (
  <TimelineEvent
    date={event.date}
    icon="comment"
    title="Avis répondu"
    initiator="pro"
  >
    <h3>
      Réponse à l&apos;avis sur{' '}
      <span>
        {event.actors.pro.proPresentation.name} (
        {event.actors.pro.proPresentation.mainTrade?.name})
      </span>{' '}
      via
      <span>
        {' '}
        {event.initiator.bilik.givenName} {event.initiator.bilik.familyName}
      </span>
    </h3>
    <div>
      <b>Réponse: </b>
      {event.data.proReview.reply}
    </div>
    <div>
      <Label
        as="a"
        onClick={(): void => {
          window.open(
            `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            '_blank',
          );
        }}
      >
        <Icon name="eye" size="small" />
        Voir avis
      </Label>
    </div>
  </TimelineEvent>
);

const ProReviewReplied: FunctionComponent<ProReviewRepliedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <ProReviewRepliedV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewRepliedV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewReplied (version ${event.version})`}
    />
  );
};

export default ProReviewReplied;
