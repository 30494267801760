import {
  Button,
  Dimmer,
  Icon,
  Label,
  List,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';
import { useNavigate, Link } from 'react-router-dom';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import {
  formatDate,
  formatDateTime,
  formatTelephone,
  ToastSuccess,
} from 'utils';
import { ModerateProReviewReplyActions } from './moderate-pro-review-reply-actions/moderate-pro-review-reply-actions';
import { CallCheckerIndicator } from './call-checker-indicator';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CountProPresentationReviewByStatus } from 'components/count-pro-presentation-review-by-status';
import { IpCheckerIndicator } from './ip-checker-indicator';
import { LastCheckedProReview } from 'components/last-checked-pro-review';
import { ProReviewInvitationCheckerIndicator } from './pro-review-invitation-checker-indicator';
import { ProReviewSpamButton } from 'components/pro-review-spam-button';
import { CurrentBilikZoneQuery, ProReviewsTableQuery } from 'generated/graphql';
import { PublishProReviewButton } from 'components/publish-pro-review-button';
import { SolicitationCheckerIndicator } from './solicitation-checker-indicator';
import env from 'env';
import styled from 'styled-components';
import Raw from 'components/raw/raw';
import { DuplicateCheckerIndicator } from './duplicate-checker-indicator';

import { differenceInBusinessDays } from 'date-fns';
import ProFullName from 'components/pro-full-name/pro-full-name';
import { Rating } from 'components/rating';
import { Commands, triggerCommand } from 'utils/commands/command';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import ToggleLockButton from 'pages/pro-reviews/toggle-lock-button/toggle-lock-button';

const ActionsBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 11px;
`;

const StyledZoneButton = styled(Button)`
  border: 0 !important;
  width: 70px;
`;

type Unpacked<T> = T extends (infer U)[] ? U : T;
type ProReview = Unpacked<ProReviewsTableQuery['proReview']>;

type ProReviewsTableViewProps = {
  loading: boolean;
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
  openCheckModal: (proReview: ProReview) => void;
  openRefuseModal: (proReview: ProReview) => void;
  openReplyModal: (proReview: ProReview) => void;
  openReplyEditModal: (proReview: ProReview) => void;
  openSmsModal: (proReview: ProReview) => void;
  openDuplicateModal: (proReview: ProReview) => void;
  openCityModal: (proReview: ProReview) => void;
  orderBy: {
    column: string;
    direction: string;
  };
  proReviews: ProReviewsTableQuery['proReview'];
  refetch: () => void;
  page?: number;
  isInProViewForm: boolean;
  currentBilikZone: CurrentBilikZoneQuery['bilikZoneByPk'] | null;
};

export const ProReviewsTableView: FunctionComponent<
  ProReviewsTableViewProps
> = ({
  loading,
  page,
  orderBy,
  openCheckModal,
  openRefuseModal,
  openReplyModal,
  openReplyEditModal,
  openSmsModal,
  openDuplicateModal,
  openCityModal,
  onFilter,
  onSort,
  proReviews,
  refetch,
  isInProViewForm,
}) => {
  const history = useNavigate();
  const { currentBilikPerson } = useCurrentBilikPerson();

  const currentBilikPersonEmail = currentBilikPerson?.account.email;

  const direction = useMemo(() => {
    switch (orderBy?.direction) {
      case 'ascending':
      case 'descending':
        return orderBy.direction;
      default:
        return 'ascending';
    }
  }, [orderBy?.direction]);

  const getReviewReplyStatus = useCallback(
    (proReview: ProReviewsTableQuery['proReview'][0]) => {
      if (!proReview.reply) {
        return <Label color="grey">Aucune</Label>;
      }

      if (proReview.isSpam || proReview.status === 'ko') {
        return (
          <Popup
            content={proReview.isSpam ? 'Avis spam' : 'Avis refusé'}
            trigger={<Label color="grey">Non publié</Label>}
          />
        );
      }

      switch (proReview.replyStatus) {
        case 'draft':
          return <Label color="red">En attente</Label>;
        case 'ok':
          return <Label color="green">Validé</Label>;
        case 'ko':
          return <Label color="red">Refusé</Label>;
      }
    },
    [],
  );

  const isButtonDisabledByLocker = (proReview: ProReview): boolean => {
    return (
      !!proReview.locker?.id &&
      currentBilikPersonEmail !== proReview.locker?.account.email
    );
  };

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={4}
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'dateCreated',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={orderBy?.column === 'dateCreated' ? direction : undefined}
            >
              Particulier
            </Table.HeaderCell>
            <Table.HeaderCell width={6} className="not sortable">
              Avis
            </Table.HeaderCell>
            <Table.HeaderCell width={4} className="not sortable">
              Réponse du pro
            </Table.HeaderCell>
            {!isInProViewForm ? (
              <Table.HeaderCell width={2} className="not sortable">
                Professionnel
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {proReviews.map((proReview) => (
            <Table.Row key={proReview.id} verticalAlign="top">
              <Table.Cell>
                <List>
                  <List.Item>
                    {proReview.source !== 'BILIK' ? (
                      <List.Content floated="right">
                        <Popup
                          content={proReview.source}
                          trigger={<Label color="blue">Avis externe</Label>}
                        />
                      </List.Content>
                    ) : null}
                    <List.Content>
                      {proReview.dateCreated
                        ? formatDateTime(proReview.dateCreated)
                        : null}
                    </List.Content>
                  </List.Item>{' '}
                  <List.Item>
                    <List.Content>
                      {`${proReview.givenName} ${proReview.familyName}`}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      {proReview.telephone.toUpperCase() !== 'NULL'
                        ? formatTelephone(proReview.telephone)
                        : 'Téléphone non renseigné (NULL)'}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    {proReview.addressLocality.toUpperCase() !== 'NULL' ? (
                      <List.Content>{proReview.addressLocality}</List.Content>
                    ) : (
                      'Lieu non renseigné (NULL)'
                    )}
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      {proReview.email.toUpperCase() !== 'NULL' ? (
                        <a
                          href="#"
                          onClick={(): void => {
                            if (onFilter) {
                              onFilter(
                                'privateIndividualEmail',
                                proReview.email,
                              );
                            }
                          }}
                        >
                          {proReview.email}
                        </a>
                      ) : (
                        'Email non renseigné (NULL)'
                      )}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <a
                        href="#"
                        onClick={(): void => {
                          if (proReview.ip && onFilter) {
                            onFilter('ip', proReview.ip);
                          }
                        }}
                      >
                        {proReview.ip}
                      </a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <Popup
                        position="top center"
                        trigger={
                          <Link
                            to={`/timeline/${
                              proReview.email.toUpperCase() !== 'NULL'
                                ? proReview.email
                                : proReview.telephone !== 'NULL'
                                ? proReview.telephone
                                : null
                            }`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Icon fitted name="time" />
                            <Icon fitted name="long arrow alternate down" />
                          </Link>
                        }
                        content="Timeline du particulier"
                      />
                    </List.Content>
                  </List.Item>
                  {proReview.dateOfWork ? (
                    <List.Item>
                      <List.Content floated="right">
                        {formatDate(proReview.dateOfWork)}
                      </List.Content>
                      <List.Content>Date des travaux</List.Content>
                    </List.Item>
                  ) : null}{' '}
                  {proReview.status === 'draft' ? (
                    <>
                      <List.Item>
                        <List.Content floated="right">
                          <SolicitationCheckerIndicator
                            email={proReview.email}
                            proPresentationId={proReview.proPresentation.id}
                          />
                        </List.Content>
                        <List.Content>Demande mail</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          <ProReviewInvitationCheckerIndicator
                            email={proReview.email}
                            proPresentationId={proReview.proPresentation.id}
                            proViewId={proReview.proPresentation.proViews[0].id}
                          />
                        </List.Content>
                        <List.Content>Demande d’avis</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          <CallCheckerIndicator
                            telephone={proReview.telephone}
                            proViewIds={proReview.proPresentation.proViews.map(
                              (proView) => proView.id,
                            )}
                          />
                        </List.Content>
                        <List.Content>Appels</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          <IpCheckerIndicator
                            ip={proReview.ip}
                            proPresentationId={proReview.proPresentation.id}
                          />
                        </List.Content>
                        <List.Content>IP</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          <DuplicateCheckerIndicator
                            proPresentationId={proReview.proPresentation.id}
                            pi={{
                              email: proReview.email,
                              givenName: proReview.givenName,
                              familyName: proReview.familyName,
                            }}
                          />
                        </List.Content>
                        <List.Content>Doublon</List.Content>
                      </List.Item>
                    </>
                  ) : null}
                </List>
              </Table.Cell>
              <Table.Cell
                // ActionsBar Integration in the cell
                style={{
                  position: 'relative',
                  paddingBottom: '65px',
                }}
              >
                <b style={{ display: 'inline-block', marginBottom: '1em' }}>
                  Note
                  {proReview.ratingType === 'ia' ? ' (automatique) :' : ' :'}
                </b>

                {proReview.rating ? (
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '8px',
                      marginBottom: '1em',
                      height: '18px',
                      verticalAlign: 'bottom',
                    }}
                  >
                    <Rating
                      value={proReview.rating}
                      color={
                        proReview.ratingType === 'ia' ? 'green' : '#fbbd08'
                      }
                    />
                  </div>
                ) : (
                  <Label style={{ fontSize: '14px' }}>Aucune</Label>
                )}

                {proReview.reviewAspect ? (
                  <p>
                    <b>Prestation : </b>
                    {proReview.reviewAspect}
                  </p>
                ) : null}
                {proReview.reviewBody ? (
                  <p>
                    <b>Commentaire public : </b>
                    <br />
                    <Raw>{proReview.reviewBody}</Raw>
                  </p>
                ) : null}
                {proReview.privateComment ? (
                  <Fragment>
                    <p>
                      <b>Remarque privée : </b>
                      <br />
                      <Raw>{proReview.privateComment}</Raw>
                    </p>
                  </Fragment>
                ) : null}
                {proReview.comment ? (
                  <Fragment>
                    <p>
                      <b>Ce qui peut être amélioré : </b>
                      <br />
                      <Raw>{proReview.comment}</Raw>
                    </p>
                  </Fragment>
                ) : null}
                {!proReview.geoCoordinates &&
                proReview.addressLocality !== 'NULL' ? (
                  <Fragment>
                    <Popup
                      content="Le particulier n'a pas sélectionné de ville dans les propositions de google, veuillez le faire si possible avant de valider l'avis"
                      trigger={
                        <Label
                          as="a"
                          color="red"
                          onClick={(): void => {
                            openCityModal(proReview);
                          }}
                        >
                          La localité n&apos;a pas de coordonnées associées,
                          veuillez les renseigner manuellement
                        </Label>
                      }
                    />
                    <br />
                    <br />
                  </Fragment>
                ) : null}
                {proReview.status === 'ko' && proReview.refuseReason ? (
                  <Fragment>
                    <b>Raison du refus : </b>
                    <p>{proReview.refuseReason}</p>
                  </Fragment>
                ) : null}
                {proReview.duplicateProReviews &&
                proReview.duplicateProReviews.length > 0 ? (
                  <Popup
                    position="top center"
                    content={
                      <>
                        <b>À été dupliqué sur:</b>
                        <ul>
                          {proReview.duplicateProReviews.map((duplicate) => (
                            <li key={duplicate.id}>
                              {duplicate.proPresentation.name}
                              <br />
                            </li>
                          ))}
                        </ul>
                      </>
                    }
                    trigger={
                      <Label
                        color="grey"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Icon name="copy" />
                        Dupliqué {proReview.duplicateProReviews.length}x
                      </Label>
                    }
                  />
                ) : null}
                {proReview.originalProReviewId ? (
                  <Popup
                    position="top center"
                    content="Voir l'avis original"
                    trigger={
                      <Label
                        color="orange"
                        onClick={(): void => {
                          window.open(
                            `/pro_reviews?proReviewId=${proReview.originalProReviewId}&isAllZones=1`,
                            '_blank',
                          );
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Icon name="copy" />
                        Avis dupliqué (cliquer pour voir l&apos;original)
                      </Label>
                    }
                  />
                ) : null}
                {proReview.checker &&
                (proReview.status === 'draft' || proReview.status === 'ok') ? (
                  <div
                    style={{
                      marginBottom: '15px',
                      display: 'block',
                    }}
                  >
                    <Popup
                      content={
                        <p style={{ textAlign: 'center' }}>
                          Demande de vérification effectué le :
                          <br />
                          <strong>
                            {proReview.checkDate
                              ? formatDateTime(proReview.checkDate)
                              : null}
                          </strong>
                        </p>
                      }
                      position="top center"
                      trigger={
                        <Label
                          as="a"
                          onClick={(): void => {
                            if (onFilter) {
                              onFilter('hasCheckerId', 'true');
                            }
                          }}
                          color={proReview.status === 'ok' ? 'green' : 'orange'}
                        >
                          {proReview.status === 'ok' ? (
                            <Fragment>
                              <Icon name="clipboard check" />
                              Avis vérifié
                            </Fragment>
                          ) : null}
                          {proReview.status === 'draft' ? (
                            <Fragment>
                              <Icon name="exclamation triangle" />
                              Avis en cours de vérification
                            </Fragment>
                          ) : null}{' '}
                          ({proReview.checker.givenName}{' '}
                          {proReview.checker.familyName})
                        </Label>
                      }
                    />
                  </div>
                ) : null}
                {proReview.locker && proReview.status === 'draft' ? (
                  <div
                    style={{
                      marginBottom: '15px',
                      display: 'block',
                    }}
                  >
                    <Popup
                      content={
                        <p style={{ textAlign: 'center' }}>
                          Avis verrouillé le :{' '}
                          <strong>
                            {proReview.lockDate
                              ? formatDateTime(proReview.lockDate)
                              : null}
                          </strong>
                        </p>
                      }
                      trigger={
                        <Label
                          as="a"
                          onClick={(): void => {
                            if (onFilter) {
                              onFilter('hasLockerId', 'true');
                            }
                          }}
                          color="orange"
                        >
                          <Icon name="lock" />
                          Avis verrouillé{' '}
                          {proReview.locker.account.email ===
                          currentBilikPersonEmail
                            ? 'par vous'
                            : `par ${proReview.locker.givenName} ${proReview.locker.familyName}`}
                        </Label>
                      }
                    />
                  </div>
                ) : null}
                <Popup
                  content="Avis verrouillé"
                  disabled={!isButtonDisabledByLocker(proReview)}
                  position="top center"
                  trigger={
                    <ActionsBar>
                      {proReview.isSpam === false &&
                      proReview.status !== 'ok' ? (
                        <PublishProReviewButton
                          refetch={refetch}
                          isACheckEnd={proReview.checker !== null}
                          proReviewId={proReview.id}
                          disabled={isButtonDisabledByLocker(proReview)}
                        />
                      ) : null}
                      {proReview.isSpam === false &&
                      proReview.status !== 'ko' ? (
                        <Popup
                          content="Refuser"
                          trigger={
                            <Button
                              icon
                              basic
                              negative
                              onClick={(): void => openRefuseModal(proReview)}
                              disabled={isButtonDisabledByLocker(proReview)}
                            >
                              <Icon name="remove" />
                            </Button>
                          }
                        />
                      ) : null}
                      {proReview.isSpam === false &&
                      proReview.status !== 'ok' &&
                      proReview.checker ? (
                        <Button
                          basic
                          color="grey"
                          onClick={(): void => openSmsModal(proReview)}
                          disabled={isButtonDisabledByLocker(proReview)}
                        >
                          <Icon name="mobile alternate" />
                          Relance SMS
                        </Button>
                      ) : null}
                      {/* disable Check button if proReview is in draft for more than 7 days or external source */}
                      {proReview.status === 'draft' &&
                      proReview.source === 'BILIK' &&
                      !proReview.checker &&
                      proReview.isSpam === false &&
                      differenceInBusinessDays(
                        new Date(proReview.dateCreated),
                        new Date(),
                      ) < 7 ? (
                        <Button
                          basic
                          color="grey"
                          onClick={async (): Promise<void> =>
                            openCheckModal(proReview)
                          }
                          disabled={isButtonDisabledByLocker(proReview)}
                        >
                          <Icon name="clipboard check" />
                          Vérifier
                        </Button>
                      ) : null}
                      {!proReview.checker ? (
                        <ProReviewSpamButton
                          isSpam={proReview.isSpam}
                          proReviewId={proReview.id}
                          refetch={refetch}
                          disabled={isButtonDisabledByLocker(proReview)}
                        />
                      ) : null}
                      {proReview.isSpam === false ? (
                        <Popup
                          content="Editer"
                          position="top center"
                          trigger={
                            <Button
                              basic
                              primary
                              icon
                              onClick={(): void => {
                                history({
                                  pathname: `/pro_reviews/update/${proReview.id}`,
                                  search: `?page=${page ? page : 1}`,
                                });
                              }}
                              disabled={isButtonDisabledByLocker(proReview)}
                            >
                              <Icon name="pencil" />
                            </Button>
                          }
                        />
                      ) : null}
                      {proReview.status === 'ok' &&
                      proReview.isSpam === false ? (
                        <>
                          <Popup
                            content="Voir l'avis en ligne"
                            position="top center"
                            trigger={
                              <Button
                                basic
                                color="grey"
                                icon
                                onClick={(): void => {
                                  window.open(
                                    `${env.SITE_URL}/presentations/${
                                      proReview.proPresentation.proViews.find(
                                        (proView) =>
                                          proView.status !== 'archived',
                                      )?.id
                                    }-${
                                      proReview.proPresentation.slug
                                    }#reviews`,
                                    '_blank',
                                  );
                                }}
                              >
                                <Icon name="eye" />
                              </Button>
                            }
                          />
                          <Popup
                            content="Copier le lien vers l'avis"
                            position="top center"
                            trigger={
                              <CopyToClipboard
                                text={`${env.SITE_URL}/presentations/${
                                  proReview.proPresentation.proViews.find(
                                    (proView) => proView.status !== 'archived',
                                  )?.id
                                }-${proReview.proPresentation.slug}#reviews`}
                              >
                                <Button
                                  basic
                                  color="grey"
                                  icon
                                  onClick={(): void => {
                                    ToastSuccess('Succès', 'Lien copié !');
                                  }}
                                >
                                  <Icon name="linkify" />
                                </Button>
                              </CopyToClipboard>
                            }
                          />
                        </>
                      ) : null}
                      <Popup
                        content="Dupliquer l'avis"
                        position="top center"
                        trigger={
                          <Button
                            basic
                            color="grey"
                            icon
                            disabled={
                              proReview.isSpam ||
                              proReview.status !== 'ok' ||
                              proReview.replyStatus === 'draft' ||
                              proReview.originalProReviewId !== null
                            }
                            onClick={(): void => openDuplicateModal(proReview)}
                          >
                            <Icon name="copy" />
                          </Button>
                        }
                      />
                      {proReview.status === 'draft' ? (
                        <ToggleLockButton
                          proReviewId={proReview.id}
                          disabled={isButtonDisabledByLocker(proReview)}
                          onComplete={() => {
                            refetch();
                          }}
                        />
                      ) : null}
                    </ActionsBar>
                  }
                />
              </Table.Cell>
              <Table.Cell
                // ActionsBar Integration in the cell
                style={{
                  position: 'relative',
                  paddingBottom: '65px',
                }}
              >
                <p>
                  <b>Réponse : </b>
                  {getReviewReplyStatus(proReview)}
                </p>
                {proReview.replyDate ? (
                  <p>
                    <b>Date : </b>
                    {formatDate(proReview.replyDate)}
                  </p>
                ) : null}
                <p>{proReview.reply ? <Raw>{proReview.reply}</Raw> : null}</p>
                <ActionsBar>
                  <Popup
                    content={
                      isButtonDisabledByLocker(proReview)
                        ? 'Avis verrouillé'
                        : proReview.status === 'draft'
                        ? "Veuillez d'abord modérer l'avis avant de modérer la réponse"
                        : null
                    }
                    disabled={
                      !isButtonDisabledByLocker(proReview) &&
                      proReview.status !== 'draft'
                    }
                    trigger={
                      <span>
                        {proReview.status !== 'ko' &&
                        !proReview.isSpam &&
                        proReview.source === 'BILIK' ? (
                          <>
                            <ModerateProReviewReplyActions
                              proReviewId={proReview.id}
                              proReviewStatus={proReview.status}
                              reply={proReview.reply}
                              replyStatus={proReview.replyStatus}
                              onModerateSuccess={(type) => {
                                ToastSuccess(
                                  'Succes',
                                  `Réponse ${
                                    type === 'validate' ? 'validée' : 'refusée'
                                  }`,
                                );
                                refetch();
                                triggerCommand(Commands.REFETCH_REVIEW_COUNTER);
                              }}
                            />
                            {!proReview.reply ? (
                              <Popup
                                content={'Répondre'}
                                trigger={
                                  <Button
                                    color="blue"
                                    basic
                                    disabled={
                                      isButtonDisabledByLocker(proReview) ||
                                      proReview.status === 'draft'
                                    }
                                    onClick={(): void =>
                                      openReplyModal(proReview)
                                    }
                                  >
                                    <Icon name="reply" />
                                    Répondre
                                  </Button>
                                }
                              />
                            ) : null}
                          </>
                        ) : null}
                      </span>
                    }
                  />
                  {proReview.reply ? (
                    <Popup
                      content="Éditer"
                      trigger={
                        <Button
                          onClick={() => openReplyEditModal(proReview)}
                          color="blue"
                          icon
                          basic
                        >
                          <Icon name="pencil" />
                        </Button>
                      }
                    />
                  ) : null}
                </ActionsBar>
              </Table.Cell>
              {!isInProViewForm ? (
                <Table.Cell textAlign="center" verticalAlign="middle">
                  <div>
                    <CountProPresentationReviewByStatus
                      proPresentationId={proReview.proPresentation.id}
                      proPresentationName={proReview.proPresentation.name}
                    />
                    <p style={{ marginTop: '10px' }}>
                      <ProFullName
                        name={proReview.proPresentation.name}
                        mainTradeName={
                          proReview.proPresentation.mainTrade?.name
                        }
                      />
                    </p>
                    {proReview.proPresentation.proViews.map((proView) => {
                      return (
                        <StyledZoneButton
                          type="button"
                          key={`pro-zone-button-${proView.id}`}
                          style={{
                            marginBottom: '14px',
                          }}
                          basic={true}
                          color={'grey'}
                          onClick={(event): void => {
                            event.preventDefault();
                            history(`/pros/update/${proView.id}/zone`);
                            window.scrollTo(0, 0);
                          }}
                          disabled={isButtonDisabledByLocker(proReview)}
                        >
                          <Icon
                            name="circle"
                            size="small"
                            color={
                              proView.status === 'published'
                                ? 'green'
                                : proView.status === 'draft'
                                ? 'red'
                                : 'grey'
                            }
                          />
                          {proView.bilikZone.mainCityCode}
                        </StyledZoneButton>
                      );
                    })}
                    <div>
                      <span>Dernier avis vérifié :</span>
                    </div>
                    <Label color="grey">
                      <LastCheckedProReview
                        proPresentationId={proReview.proPresentation.id}
                      />
                    </Label>
                  </div>
                </Table.Cell>
              ) : null}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};
