import Client from 'client';
import gql from 'graphql-tag';
import { ToastError } from 'utils';
import * as Yup from 'yup';

const isExistingEmail = async (email?: string | null): Promise<boolean> => {
  const accountResult = await Client.query({
    query: gql`
      query ($email: String!) {
        account(where: { email: { _eq: $email } }) {
          id
          email
        }
      }
    `,
    variables: { email: email || '' },
  });

  return accountResult.data.account && accountResult.data.account.length > 0;
};

export default (currentEmail: string): Yup.ObjectSchema =>
  Yup.object().shape({
    email: Yup.string()
      .required('Adresse email requise !')
      .test(
        'is-email-uniq',
        'Cette adresse email est déjà utilisé !',
        async (email) => {
          if (currentEmail !== email) {
            return await isExistingEmail(email)
              .then((res) => res === false || currentEmail === email)
              .catch((error) => {
                ToastError(
                  'Erreur',
                  "Impossible de vérifier l'unicité de l'adresse email",
                );
                throw error;
              });
          }
          return true;
        },
      ),
  });
