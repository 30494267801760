import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import React, { FunctionComponent } from 'react';
import {} from 'react-router';
import { BilikPersonFormCreate } from './bilik-person-form';

const BilikPersonCreatePage: FunctionComponent = () => (
  <Content>
    <ContentHeader>
      <ContentHeaderTitle>Nouvel utilisateur</ContentHeaderTitle>
    </ContentHeader>
    <ContentBody>
      <ContentContainer>
        <BilikPersonFormCreate />
      </ContentContainer>
    </ContentBody>
  </Content>
);

export default BilikPersonCreatePage;
