import React, { FunctionComponent } from 'react';
import { Loader } from 'semantic-ui-react';
import { formatDate } from 'utils';

type LastCheckedProReviewContainerProps = {
  lastCheckedDate?: string;
  loading: boolean;
};

export const LastCheckedProReviewContainer: FunctionComponent<LastCheckedProReviewContainerProps> = ({
  loading,
  lastCheckedDate,
}) => {
  if (loading) {
    return <Loader size="mini" active inline />;
  }

  if (!lastCheckedDate) {
    return <span style={{ color: 'white' }}> Aucun</span>;
  }
  return <span style={{ color: 'white' }}>{formatDate(lastCheckedDate)}</span>;
};
