import React, { FunctionComponent, useCallback } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';

type ProReviewSpamButtonContainerProps = {
  isSpam: boolean;
  loading: boolean;
  toggleSpam: () => Promise<void>;
  disabled?: boolean;
};

export const ProReviewSpamButtonContainer: FunctionComponent<
  ProReviewSpamButtonContainerProps
> = ({ isSpam, toggleSpam, loading, disabled }) => {
  const onSpamButtonClicked = useCallback(async () => {
    try {
      await toggleSpam();

      ToastSuccess(
        'Succès',
        `${!isSpam ? `Avis ajouté comme spam.` : `Avis enlevé des spams.`}`,
      );
    } catch (e) {
      ToastError('Erreur', "Impossible de changer le status de l'avis.");
    }
  }, [isSpam, toggleSpam]);

  return (
    <Button
      color={isSpam ? 'grey' : 'red'}
      basic
      loading={loading}
      onClick={onSpamButtonClicked}
      disabled={disabled}
    >
      {isSpam ? (
        <>
          <Icon name="remove" />
          Enlever des spams
        </>
      ) : (
        <>
          <Icon name="exclamation" />
          Spam
        </>
      )}
    </Button>
  );
};
