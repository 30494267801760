import React, { FunctionComponent, useCallback } from 'react';
import { Trade, useTradeFormCreateMutation } from 'generated/graphql';

import TradesFormCreateContainer from './trade-form-create-container';
import { mapTradeGroupsFormValuesToTradeGroupObjects } from './map-trade-groups-form-values-to-trade-group-objects';

export type TradeCreate = Pick<
  Trade,
  | 'name'
  | 'slug'
  | 'pageTitle'
  | 'anchor'
  | 'pageTitleVariant'
  | 'pageSubtitle'
  | 'pageTitleDirectory'
  | 'label'
  | 'metaTitle'
  | 'metaDescription'
  | 'isAnonymised'
  | 'isCallRecordable'
  | 'isRgeFilterable'
> & { tradeGroups: number[] };

const TradesFormCreateRepository: FunctionComponent = () => {
  const [insertTradeOne] = useTradeFormCreateMutation();

  const createTrade = useCallback(
    async (trade: TradeCreate) =>
      insertTradeOne({
        variables: {
          trade: {
            name: trade.name,
            slug: trade.slug,
            pageTitle: trade.pageTitle,
            anchor: trade.anchor,
            pageTitleVariant: trade.pageTitleVariant,
            pageSubtitle: trade.pageSubtitle,
            pageTitleDirectory: trade.pageTitleDirectory,
            label: trade.label,
            metaTitle: trade.metaTitle,
            metaDescription: trade.metaDescription,
            isAnonymised: trade.isAnonymised,
            isCallRecordable: trade.isCallRecordable,
            isRgeFilterable: trade.isRgeFilterable,
            tradeGroups: {
              data: [
                ...mapTradeGroupsFormValuesToTradeGroupObjects(
                  trade.tradeGroups,
                ),
              ],
            },
          },
        },
      }),
    [insertTradeOne],
  );

  return <TradesFormCreateContainer createTrade={createTrade} />;
};

export default TradesFormCreateRepository;
