import React, { Fragment, FunctionComponent, useState } from 'react';
import { Button, Form, Icon, Modal, Popup, Radio } from 'semantic-ui-react';

type PublishProReviewViewProps = {
  onPublishButtonClicked: (proofProvider?: string) => Promise<void>;
  isACheckEnd: boolean;
  isPublishing: boolean;
  disabled?: boolean;
};

// @NOTE: We allow the use of a state in a view here because the state only affect the view.
// It's not concerning any datas taken from the repo nor a callback.
// Also because the modal state in the view is linked to the view, not to the container managing the view.
export const PublishProReviewButtonView: FunctionComponent<
  PublishProReviewViewProps
> = ({ onPublishButtonClicked, isACheckEnd, isPublishing, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [proofProvider, setProofProvider] = useState<string>();

  return (
    <>
      <Popup
        content="Valider"
        trigger={
          <Button
            icon
            basic
            positive
            disabled={isPublishing || disabled}
            loading={isPublishing}
            onClick={(): void => {
              if (isACheckEnd) {
                setIsModalOpen(true);
              } else {
                onPublishButtonClicked();
              }
            }}
          >
            <Icon name="checkmark" />
          </Button>
        }
      />
      <Modal
        open={isModalOpen}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Qui a transmis le devis / facture ?</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Radio
                label="Le particulier"
                value="pi"
                name="proofProvider"
                checked={proofProvider === 'pi'}
                onChange={(): void => {
                  setIsModalOpen(true);
                  setProofProvider('pi');
                }}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="Le pro"
                value="pro"
                name="proofProvider"
                checked={proofProvider === 'pro'}
                onChange={(): void => {
                  setIsModalOpen(true);
                  setProofProvider('pro');
                }}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="Le particulier et le pro"
                value="proAndPi"
                name="proofProvider"
                checked={proofProvider === 'proAndPi'}
                onChange={(): void => {
                  setIsModalOpen(true);
                  setProofProvider('proAndPi');
                }}
              />{' '}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              setIsModalOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            loading={isPublishing}
            disabled={proofProvider === undefined}
            onClick={async (): Promise<void> =>
              onPublishButtonClicked(proofProvider)
            }
          >
            <Icon name="envelope" />
            Envoyer
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
