import { SolicitationsTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Unpacked } from 'utils/unpacked';
import { ProBouncedSolicitationView } from './pro-bounced-solicitation-view';

type ProBouncedSolicitationContainerProps = {
  resendMailToPro: (uuid: string) => Promise<void>;
  mainAccountId: number;
  reactivateEmailInPostmarkRequest: () => Promise<void>;
  refetchSolicitations: () => void;
  solicitation: Unpacked<SolicitationsTableQuery['solicitation']>;
};

export const ProBouncedSolicitationContainer: FunctionComponent<
  ProBouncedSolicitationContainerProps
> = ({
  resendMailToPro,
  mainAccountId,
  reactivateEmailInPostmarkRequest,
  solicitation,
}) => {
  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  const reactivateEmailInPostmark = useCallback(async () => {
    setIsActionInProgress(true);
    await reactivateEmailInPostmarkRequest();
    setIsActionInProgress(false);
    setIsExplanationModalOpen(false);
  }, [reactivateEmailInPostmarkRequest]);

  const resendSolicitationToPro = useCallback(async () => {
    setIsActionInProgress(true);
    if (solicitation.uuid) {
      await resendMailToPro(solicitation.uuid).then(() => {
        setIsActionInProgress(false);
        setIsExplanationModalOpen(false);
        setAccountModalOpen(false);
      });
    }
  }, [resendMailToPro, solicitation]);

  return (
    <ProBouncedSolicitationView
      reactivateEmailInPostmark={reactivateEmailInPostmark}
      mainAccountId={mainAccountId}
      resendSolicitationsToPro={resendSolicitationToPro}
      isExplanationModalOpen={isExplanationModalOpen}
      setIsExplanationModalOpen={setIsExplanationModalOpen}
      accountModalOpen={accountModalOpen}
      setAccountModalOpen={setAccountModalOpen}
      isActionInProgress={isActionInProgress}
    />
  );
};
