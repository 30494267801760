import React, { FunctionComponent } from 'react';

import { GeoJSONProps, GeoJSON, Marker } from 'react-leaflet';
import { Icon, IconOptions } from 'leaflet';
import { Point } from 'geojson';

interface MapPointProps extends GeoJSONProps {
  type: 'Point' | 'MultiPoint';
  icon: Partial<IconOptions>;
}

type MapGeoJSONProps = GeoJSONProps | MapPointProps;

const MapGeoJSON: FunctionComponent<MapGeoJSONProps> = ({ data, ...rest }) => {
  if (data.type === 'Point') {
    const { icon } = rest as MapPointProps;
    const point = data as Point;

    return (
      <Marker
        position={{
          lat: point.coordinates[1],
          lng: point.coordinates[0],
        }}
        icon={
          new Icon({
            ...icon,
            iconUrl: icon?.iconUrl ?? '/map-marker.png',
            iconSize: icon?.iconSize ?? [25, 25],
          })
        }
      />
    );
  }

  return (
    <>
      <GeoJSON data={data} {...rest} />
    </>
  );
};

export default MapGeoJSON;
