import { Serie } from '@nivo/line';
import { getDataPoints } from './get-data-points';
import { CountByMonth } from './count-by-month.type';

export const convertTableData = (
  data: CountByMonth[],
  years: number[],
): Serie[] => {
  const chartData: Serie[] = [];

  years
    // Prevent same years errors
    .filter(
      (year, index) => years.indexOf(year) === index && year !== undefined,
    )
    .forEach((year) => {
      chartData.push({
        id: year,
        data: getDataPoints(data, 1, year)[0],
      });
    });

  return chartData;
};
