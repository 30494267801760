import { CurrentBilikPersonQuery } from 'generated/graphql';
import WSWrapper, { Channel } from 'utils/websocket';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import {
  useLocation,
  useNavigate,
  Location as RouteLocation,
} from 'react-router-dom';
import env from 'env';
import { getToken } from 'utils';
import { toast } from 'react-toastify';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

// @TODO: move all websocket part to another file
export type Me = RouteLocation &
  Partial<CurrentBilikPersonQuery['bilikPerson'][0]> & {
    claim?: boolean;
  };

export const WebSocketContext = React.createContext<{
  socket?: WSWrapper;
  members: Me[];
  askClaim: () => void;
  me: Me | null;
  askedAClaim: boolean;
  refuseClaim: (url: string) => void;
  forceClaim: () => void;
  closeSocket: () => void;
  isWebSocketReady: boolean;
}>({
  socket: undefined,
  members: [],
  askClaim: () => null,
  me: null,
  askedAClaim: false,
  refuseClaim: () => null,
  forceClaim: () => null,
  closeSocket: () => null,
  isWebSocketReady: false,
});

export const Socket: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  const location = useLocation();
  const history = useNavigate();

  const [socket, setSocket] = useState<WSWrapper>();
  const [me, setMe] = useState<Me>({
    ...currentBilikPerson,
    ...location,
  });
  const [askedAClaim, setAskedAClaim] = useState<boolean>(false);
  const [members, setMembers] = useState<Me[]>([]);
  const [routesChannel, setRoutesChannel] = useState<Channel>();
  const [backendChannel, setBackendChannel] = useState<Channel>();
  const [deployChannel, setDeployChannel] = useState<Channel>();
  const [claimChannel, setClaimChannel] = useState<Channel>();
  const [isWebSocketReady, setIsWebSocketReady] = useState(false);

  // Create / Close the websocket connection
  useEffect(() => {
    let socket: WSWrapper;
    try {
      socket = new WSWrapper(
        {
          url: `${env.PROTOCOL === 'https' ? 'wss' : 'ws'}://${
            env.API_URL.split('//')[1]
          }/socket`,
          // Pass the required jwt
          jwt: getToken(),
        },
        // auto-reconnect to true
        true,
      );

      socket.on('ready', () => {
        setMe({
          ...currentBilikPerson,
          ...location,
          claim: false,
        });
        setSocket(socket);
      });
    } catch (error) {
      console.error(error);
    }

    // Clear the connection when the component is demounting
    return (): void => {
      socket.close();
    };
  }, []);

  // Setup subscribers
  useEffect(() => {
    if (currentBilikPerson && socket) {
      // Subscribe to routes channel
      const _routesChannel = socket.subscribe(
        'presence-routes',
        currentBilikPerson.id,
        { ...currentBilikPerson, ...location, claim: false },
      );

      // Subscribe to deploy channel
      const _deployChannel = socket.subscribe('private-deploy');

      // Subscribe to backend-events (see stacks/nats-websocket)
      const _backendChannel = socket.subscribe('private-backend');

      // Subscribe to claim channel
      const _claimChannel = socket.subscribe('claim-update');

      _routesChannel?.on('ready', () => {
        setRoutesChannel(_routesChannel);
      });

      _deployChannel?.on('ready', () => {
        setDeployChannel(_deployChannel);
      });

      _backendChannel?.on('ready', () => {
        setBackendChannel(_backendChannel);
      });

      _claimChannel?.on('ready', () => {
        setClaimChannel(_claimChannel);
      });
    }
  }, [socket, currentBilikPerson]);

  // Init ready
  useEffect(() => {
    if (routesChannel && claimChannel && backendChannel && deployChannel) {
      setIsWebSocketReady(true);
    }
  }, [routesChannel, claimChannel, backendChannel, deployChannel]);

  // Setup routes channel
  useEffect(() => {
    if (routesChannel && currentBilikPerson) {
      routesChannel.on('update-route', (data) => {
        // Remove empty object.
        if (data && data.members) {
          const members = data.members.filter(
            (member) =>
              Object.keys(member).length !== 0 && member.constructor === Object,
          );
          // Update members and reset askedAClaim
          setMembers(members);
          setAskedAClaim(false);
        }
      });
      routesChannel.send('update-route', {
        info: {
          ...currentBilikPerson,
          ...location,
          claim: false,
        },
        id: currentBilikPerson.id,
      });
    }
  }, [routesChannel, currentBilikPerson]);

  // Setup deploy channel
  useEffect(() => {
    if (deployChannel) {
      deployChannel.on('deploy:succeed', () => {
        toast.warn(
          "Une nouvelle version de l'application est disponible, cliquez ici pour recharger la page.",
          { onClick: () => window.location.reload(), autoClose: false },
        );
      });
    }
  }, [deployChannel]);

  // Setup backend channel
  useEffect(() => {
    if (backendChannel && me) {
      backendChannel.on('download.link', ({ data }) => {
        if (data.targetEmail === me?.account?.email) {
          toast.success(data.message || 'Message vide', {
            onClick: () => window.open(data.url, '_blank'),
            closeButton: true,
            autoClose: false,
          });
        }
      });
    }
  }, [backendChannel, me]);

  // Setup claim channel
  useEffect(() => {
    if (claimChannel && currentBilikPerson) {
      // When loosing the claim (being rejected by other member)
      claimChannel.on('lose-claim', ({ data }) => {
        toast.warn(
          `${data.givenName} ${data.familyName} vient de prendre la main sur l'édition de la page sur laquelle vous étiez.`,
          { autoClose: false },
        );
        history('/pros');
      });
      claimChannel.on('gain-claim', () => {
        setMe({ ...currentBilikPerson, ...location, claim: true });

        setAskedAClaim(true);
      });
      claimChannel.on('update-claim', ({ data }) => {
        // Remove empty object
        if (data) {
          const members = data.filter(
            (member) =>
              Object.keys(member).length !== 0 && member.constructor === Object,
          );
          // Update members and reset askedAClaim
          setAskedAClaim(false);
          setMembers(members);
        }
      });
    }
  }, [claimChannel, currentBilikPerson]);

  // Update route when changed
  useEffect(() => {
    if (routesChannel && currentBilikPerson) {
      // Do not update when using the pro sub router page
      // /pros/update/${id}/${subroute}
      const meIsOnUpdateProPage = me.pathname.startsWith('/pros/update/');
      const meIsGoingToAProPageUpdate =
        location.pathname.startsWith('/pros/update/');

      if (
        meIsOnUpdateProPage &&
        meIsGoingToAProPageUpdate &&
        location.pathname.split('/')[3] === me.pathname.split('/')[3]
      ) {
        return;
      }

      // Inform others we moved to another page.
      routesChannel.send('update-route', {
        info: {
          ...currentBilikPerson,
          ...location,
          claim: false,
        },
        id: currentBilikPerson.id,
      });
      setMe({ ...currentBilikPerson, ...location });
      setAskedAClaim(false);
    }
  }, [location.pathname, currentBilikPerson, routesChannel]);

  // Trigerred when confirm we accept to take the control of the page.
  const forceClaim = useCallback((): void => {
    if (claimChannel) {
      claimChannel.send('force-claim', {
        route: me.pathname,
      });
    }
  }, [claimChannel, me.pathname]);

  // Trigerred when confirm we refuse to take the control of the page.
  const refuseClaim = useCallback((url: string) => {
    history(url);
  }, []);

  // Trigerred when we want to take the initial control of the page.
  const askClaim = useCallback((): void => {
    if (claimChannel && !askedAClaim) {
      // workaround to avoid change state on render()
      setTimeout(() => setAskedAClaim(true), 0);
      claimChannel.send('ask-claim', {
        route: me.pathname,
      });
    }
  }, [claimChannel, askedAClaim]);

  const closeSocket = useCallback((): void => {
    if (socket) {
      socket.autoReconnect = false;
      socket.close();
    }
  }, [socket]);

  return (
    <WebSocketContext.Provider
      value={{
        askClaim: askClaim,
        askedAClaim: askedAClaim,
        forceClaim: forceClaim,
        me: me,
        members: members,
        refuseClaim: refuseClaim,
        closeSocket: closeSocket,
        socket: socket,
        isWebSocketReady: isWebSocketReady,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
