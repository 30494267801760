import { useFormikContext } from 'formik';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Icon,
  Modal,
  Transition,
  FormField as FormFieldUi,
  Label as LabelUi,
} from 'semantic-ui-react';
import {
  ProFormUpdateFields,
  ProPostalAddressField,
} from '../pro-form-update-fields-type';
import FormField from 'components/form-field/form-field';
import 'leaflet/dist/leaflet.css';
import Label from 'components/label/label';
import FieldError from 'components/field-error/field-error';
import InterventionsField from 'components/interventions-field/interventions-field';
import { useState } from 'react';
import Map from 'components/map/map';
import HelpText from 'components/help-text/help-text';
import { formattedPostalAddress } from 'utils/formatted-postal-address';
import { getLatLngCenter, isPoint } from 'utils/geometry';
import { Point } from 'geojson';
import MapGeoJSON from 'components/map/map-geojson/map-geojson';
import { CityField } from 'components/city-field';
import { useProSectorsRouteQuery } from 'generated/graphql';

type ProSectorsRouteProps = {
  bilikZoneId: number;
  proViewId: number;
  proPresentationId: number;
};

type ProPostalAddressModalStateProps = {
  open: boolean;
  proPostalAddress?: ProPostalAddressField;
};

const ProSectorsRoute: FunctionComponent<ProSectorsRouteProps> = ({
  bilikZoneId,
  proPresentationId,
  proViewId,
}) => {
  const [invalidAddress, setInvalidAddress] = useState<boolean>(false);

  // If there is no proPostalAddress and no interventions, center the map with bilikZone geocoordinates
  const { data: bilikZone } = useProSectorsRouteQuery({
    variables: {
      bilikZoneId: bilikZoneId,
    },
  });

  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<ProFormUpdateFields>();

  const [proPostalAddressModalState, setProPostalAddressModalState] =
    useState<ProPostalAddressModalStateProps>({ open: false });

  const fetchGeoCoordinates = useCallback(
    async (address: string): Promise<Point> => {
      const geoCoder = new google.maps.Geocoder();
      let geoCoordinates;

      await geoCoder.geocode(
        {
          address,
          componentRestrictions: { country: 'fr' },
        },
        (results, status) => {
          if (status === 'OK') {
            geoCoordinates = {
              type: 'Point',
              coordinates: [
                results[0].geometry?.location.lng(),
                results[0].geometry?.location.lat(),
              ],
            };
          }
        },
      );

      return geoCoordinates;
    },
    [],
  );

  const onDisplayOptionChange = useCallback(
    (value) => {
      if (
        value === 'city' &&
        !values.proPostalAddress?.cityGeoCoordinates &&
        values.proPostalAddress?.city &&
        values.proPostalAddress?.postalCode
      ) {
        fetchGeoCoordinates(
          `${values.proPostalAddress?.city} ${values.proPostalAddress?.postalCode}`,
        ).then((geoCoordinates: Point) => {
          setFieldValue('proPostalAddress.cityGeoCoordinates', geoCoordinates);
        });
      }
    },
    [
      values.proPostalAddress?.city,
      values.proPostalAddress?.postalCode,
      values.proPostalAddress?.cityGeoCoordinates,
    ],
  );

  const onPlaceSelected = useCallback(
    async (place: google.maps.places.PlaceResult): Promise<void> => {
      // Get street_number from google autocomplete object
      const streetNumber = place.address_components?.find((item) =>
        item.types.includes('street_number'),
      )?.long_name;

      // Get street name from google autocomplete object
      const streetName = place.address_components?.find((item) =>
        item.types.includes('route'),
      )?.long_name;

      // Get postal code from google autocomplete object
      const postalCode = place.address_components?.find((item) =>
        item.types.includes('postal_code'),
      )?.long_name;

      // Get city from google autocomplete object
      const city = place.address_components?.find((item) =>
        item.types.includes('locality'),
      )?.long_name;

      const street = `${streetNumber ?? ''} ${streetName ?? ''}`.trim();

      const geoCoordinates = {
        type: 'Point',
        coordinates: [
          place.geometry?.location.lng(),
          place.geometry?.location.lat(),
        ],
      };

      const cityGeoCoordinates = await fetchGeoCoordinates(
        `${city} ${postalCode}`,
      );

      setFieldValue('proPostalAddress', {
        displayOption: values.proPostalAddress?.displayOption,
        formatted: place.formatted_address,
        street: street,
        postalCode: postalCode,
        city: city,
        geoCoordinates: geoCoordinates,
        cityGeoCoordinates: cityGeoCoordinates,
      });

      setInvalidAddress(false);
    },
    [values.proPostalAddress?.displayOption],
  );

  if (!bilikZone) {
    return <>Erreur</>;
  }

  return (
    <>
      <Divider horizontal>Adresse</Divider>
      {invalidAddress || errors.proPostalAddress ? (
        <FieldError color="red">
          Veuillez sélectionner une adresse valide parmi les propositions. Si
          aucune adresse ne vous convient, remplissez les champs manuellement à
          l&apos;aide du bouton d&apos;édition à droite
        </FieldError>
      ) : null}
      <Form.Group widths="equal">
        <FormField
          type="place"
          name={'proPostalAddress.formatted'}
          label={`Adresse complète du professionnel`}
          placeholder="Ex : 15 rue Pierre Dupont, Grenoble"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setFieldTouched('proPostalAddress.formatted');
            setFieldValue('proPostalAddress.formatted', e.target.value);
            setInvalidAddress(true);
          }}
          onPlaceSelected={onPlaceSelected}
          options={{
            componentRestrictions: { country: 'fr' },
            fields: ['address_components', 'geometry', 'formatted_address'],
            types: ['address'],
          }}
        />
        <Button
          icon
          primary
          style={{ minWidth: '45px', maxHeight: '32px', marginTop: '25px' }}
          type="button"
          onClick={(): void => {
            setProPostalAddressModalState({
              open: true,
              proPostalAddress: values.proPostalAddress,
            });
          }}
        >
          <Icon name="pencil alternate" />
        </Button>
      </Form.Group>
      <FormField
        type="radio"
        name="proPostalAddress.displayOption"
        options={[
          {
            label: "Afficher l'adresse complète (recommandé)",
            value: 'complete',
            key: 'complete',
          },
          {
            label: 'Afficher uniquement la ville',
            value: 'city',
            key: 'city',
          },
          {
            label: "Masquer l'adresse complètement (non recommandé)",
            value: 'none',
            key: 'none',
          },
        ]}
        onChange={onDisplayOptionChange}
        label="Option d'affichage de l'adresse"
        helpText="Choisissez comment l'adresse du professionnel sera affichée sur le site"
      />
      <FormField
        type="checkbox"
        name="isLocalBusiness"
        label="Afficher les horaires d'ouvertures"
      />
      <Transition.Group duration={250}>
        {values.isLocalBusiness ? (
          <>
            <Container fluid>
              <FormField
                type="textarea"
                label="Horaires"
                name="openingHours"
                required
                rows={8}
              />
            </Container>
            <Divider hidden />
          </>
        ) : null}
      </Transition.Group>
      <Divider horizontal style={{ marginTop: '25px' }}>
        Secteurs d&apos;intervention
      </Divider>
      <FormField
        type="checkbox"
        label={`Exclure le centre-ville de ${bilikZone?.bilikZoneByPk?.mainCity?.name}`}
        name="isMainCityCenterExcluded"
        helpText={`Attention à ne pas dessiner de secteur d'exclusion pour le centre-ville de ${bilikZone?.bilikZoneByPk?.mainCity?.name}`}
      />
      <InterventionsField
        name="proInterventions"
        proPostalAddress={values.proPostalAddress}
        bilikZoneId={bilikZoneId}
        proPresentationId={proPresentationId}
        proViewId={proViewId}
        mapHeight={500}
      />
      <Modal
        onClose={() => {
          setFieldValue('proPostalAddress', {
            ...proPostalAddressModalState.proPostalAddress,
          });
          setProPostalAddressModalState({
            open: false,
          });
        }}
        open={proPostalAddressModalState.open}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Édition de l&apos;adresse du pro</Modal.Header>
        <Modal.Content>
          <Form>
            <FormField
              name="proPostalAddress.street"
              type="text"
              label="Adresse"
              placeholder="Ex : 15 rue Pierre Dupont"
              required
            />
            <Label>
              Saisir les coordonnées géographiques de l&apos;adresse
            </Label>
            <HelpText style={{ marginTop: '0px' }}>
              Double-cliquez sur la carte pour ajouter les coordonnées de
              l&apos;adresse
            </HelpText>
            <Map
              style={{ width: '100%', height: '400px' }}
              center={
                bilikZone?.bilikZoneByPk?.area
                  ? getLatLngCenter(bilikZone?.bilikZoneByPk?.area)
                  : { lat: 48.856614, lng: 2.3522219 }
              }
              zoom={10}
              doubleClickZoom={false}
              fit={true}
              eventHandlers={{
                dblclick: (event) =>
                  setFieldValue('proPostalAddress.geoCoordinates', {
                    type: 'Point',
                    coordinates: [event.latlng.lng, event.latlng.lat],
                  }),
              }}
            >
              {values.proPostalAddress?.geoCoordinates ? (
                <MapGeoJSON
                  data={values.proPostalAddress.geoCoordinates}
                  interactive={false}
                />
              ) : null}
            </Map>
            <FormFieldUi style={{ marginTop: '15px' }}>
              <Label>
                Coordonnées de l&apos;adresse
                <LabelUi size="large" style={{ marginLeft: '15px' }}>
                  {values.proPostalAddress?.geoCoordinates &&
                  isPoint(values.proPostalAddress?.geoCoordinates)
                    ? `${values.proPostalAddress?.geoCoordinates?.coordinates[0]} lat / ${values.proPostalAddress?.geoCoordinates?.coordinates[1]} lng `
                    : 'non renseigné'}{' '}
                </LabelUi>
              </Label>
              {errors.proPostalAddress &&
              errors.proPostalAddress['geoCoordinates'] ? (
                <FieldError>
                  {errors.proPostalAddress['geoCoordinates']}
                </FieldError>
              ) : null}
            </FormFieldUi>
            <FormField
              name="proPostalAddress.postalCode"
              type="text"
              label="Code postal"
              placeholder='Ex : "38000"'
              required
            />
            <CityField
              drawMode="Point"
              cityFieldName="proPostalAddress.city"
              label="Ville"
              buttonText="Placer manuellement les coordonnées de la ville"
              labelCoordinates="Coordonnées de la ville"
              helpText="Les coordonnées géographiques de la ville sont utilisées lorsque l'option d'affichage de l'adresse est 'Afficher uniquement la ville'"
              geometryFieldName="proPostalAddress.cityGeoCoordinates"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setFieldValue(
                'proPostalAddress',
                proPostalAddressModalState.proPostalAddress,
              );
              setProPostalAddressModalState({
                open: false,
              });
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            type="button"
            disabled={errors.proPostalAddress ? true : false}
            onClick={() => {
              if (values.proPostalAddress) {
                setFieldValue(
                  'proPostalAddress.formatted',
                  formattedPostalAddress(values.proPostalAddress),
                );
              }
              setInvalidAddress(false);
              setProPostalAddressModalState({ open: false });
            }}
          >
            <Icon name="check" />
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProSectorsRoute;
