import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { TradeGroup, TradeUpdate } from './trade-form-update-repository';
import { Loader } from 'semantic-ui-react';
import { TradeFormFields } from './trade-form-fields-type';
import {
  TradeQuestionInsertInput,
  TradeQuestionSetInput,
  TradeFormUpdateQuery,
  TradeKeywordInsertInput,
  TradeKeywordSetInput,
} from 'generated/graphql';
import TradeFormView from './trade-form-view';
import { tradeFormValidationSchema } from './trade-form-validation-schema';
import { useNavigate } from 'react-router';

type TradeFormUpdateProps = {
  updateTrade: (
    trade: TradeUpdate,
    insertTradeGroups: TradeGroup[],
    deleteTradeGroupIds: number[],
    insertTradeQuestions: TradeQuestionInsertInput[],
    deleteTradeQuestionIds: number[],
    insertTradeKeywords: TradeKeywordInsertInput[],
    deleteTradeKeywordIds: number[],
  ) => Promise<void>;
  updateTradeQuestion: (
    id: number,
    tradeQuestion: TradeQuestionSetInput,
  ) => Promise<void>;
  updateTradeKeyword: (
    id: number,
    tradeKeyword: TradeKeywordSetInput,
  ) => Promise<void>;
  formData?: TradeFormUpdateQuery['tradeByPk'];
  tradeId: number;
  loading: boolean;
  refetch: () => void;
  createSlugRedirect: (fromSlug: string, toSlug: string) => Promise<void>;
};

const TradesFormUpdateContainer: FunctionComponent<TradeFormUpdateProps> = ({
  loading,
  updateTrade,
  updateTradeQuestion,
  updateTradeKeyword,
  formData,
  tradeId,
  refetch,
  createSlugRedirect,
}) => {
  const history = useNavigate();

  const tradeGroupInitialIds = useMemo(() => {
    return (
      formData?.tradeGroups.map((tradeGroup) => tradeGroup.tradeGroup.id) || []
    );
  }, [formData]);

  // Get all ids before any modifications
  const tradeQuestionInitialIds = useMemo(() => {
    return (
      formData?.tradeQuestions.map((tradeQuestion) => tradeQuestion.id) || []
    );
  }, [formData]);

  // Get all ids before any modifications
  const tradeKeywordInitialIds = useMemo(() => {
    return formData?.tradeKeywords.map((tradeKeyword) => tradeKeyword.id) || [];
  }, [formData]);

  const onSubmit = useCallback(
    async (
      values: TradeFormFields,
      actions: FormikHelpers<TradeFormFields>,
    ): Promise<void> => {
      const tradeGroups = values.tradeGroups;

      try {
        // Diff initial trade groups and new values to find which trade groups need removing.
        const deleteTradeGroupIds: number[] = tradeGroupInitialIds
          ?.filter((item) => tradeGroups.indexOf(item) < 0)
          .map((tradeGroup) => tradeGroup);

        // Diff initial trade groups with new values to find which trade groups need to be inserted.
        const insertTradeGroups: TradeGroup[] = tradeGroups
          ?.filter(
            (item) =>
              tradeGroupInitialIds
                .map((tradeGroup) => tradeGroup)
                .indexOf(item) < 0,
          )
          .map((tradeGroupId) => {
            return { tradeGroupId: tradeGroupId, tradeId };
          });

        // TradeQuestions
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertTradeQuestions = values.tradeQuestions
          .filter((tradeQuestion) => !tradeQuestion.id)
          .map(
            (tradeQuestion): TradeQuestionInsertInput => ({
              ...tradeQuestion,
              tradeId: tradeId,
            }),
          );

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateTradeQuestions = values.tradeQuestions.filter(
          (tradeQuestion) => tradeQuestion.id,
        );

        // Get all ids after any modifications
        const tradeQuestionsIds = values.tradeQuestions.map(
          (tradeQuestion) => tradeQuestion.id,
        );

        // Determine which objects should be delete from the database (difference between before/after modifications)
        const deleteTradeQuestionsIds = tradeQuestionInitialIds.filter(
          (id) => !tradeQuestionsIds.includes(id),
        );

        // TradeKeywords
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertTradeKeywords = values.tradeKeywords
          .filter((tradeKeyword) => !tradeKeyword.id)
          .map(
            (tradeKeyword): TradeKeywordInsertInput => ({
              ...tradeKeyword,
              tradeId: tradeId,
            }),
          );

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateTradeKeywords = values.tradeKeywords.filter(
          (tradeKeyword) => tradeKeyword.id,
        );

        // Get all ids after any modifications
        const tradeKeywordsIds = values.tradeKeywords.map(
          (tradeKeyword) => tradeKeyword.id,
        );

        // Determine which objects should be delete from the database (difference between before/after modifications)
        const deleteTradeKeywordsIds = tradeKeywordInitialIds.filter(
          (id) => !tradeKeywordsIds.includes(id),
        );

        // Update entities who need to be updated
        await Promise.all(
          updateTradeQuestions.map(async (tradeQuestion) => {
            if (tradeQuestion.id) {
              await updateTradeQuestion(tradeQuestion.id, tradeQuestion);
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update tradeQuestions)",
          );
          throw error;
        });

        // Update entities who need to be updated
        await Promise.all(
          updateTradeKeywords.map(async (tradeKeyword) => {
            if (tradeKeyword.id) {
              await updateTradeKeyword(tradeKeyword.id, tradeKeyword);
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update TradeKeywords)",
          );
          throw error;
        });

        await updateTrade(
          {
            id: tradeId,
            slug: values?.slug,
            name: values?.name,
            pageTitle: values?.pageTitle,
            anchor: values?.anchor,
            pageTitleVariant: values?.pageTitleVariant,
            pageSubtitle: values?.pageSubtitle,
            pageTitleDirectory: values?.pageTitleDirectory,
            label: values?.label,
            metaTitle: values?.metaTitle,
            metaDescription: values?.metaDescription,
            isAnonymised: values?.isAnonymised,
            isCallRecordable: values?.isCallRecordable,
            isRgeFilterable: values?.isRgeFilterable,
          },
          insertTradeGroups,
          deleteTradeGroupIds,
          insertTradeQuestions,
          deleteTradeQuestionsIds,
          insertTradeKeywords,
          deleteTradeKeywordsIds,
        );

        if (formData?.slug && formData.slug !== values.slug) {
          await createSlugRedirect(formData.slug, values.slug).catch(() => {
            ToastError('Erreur', 'Impossible de créer la redirection de slug');
          });
        }
        if (refetch) {
          refetch();
        }
        ToastSuccess('Succès', 'Catégorie enregistrée');
        actions.setSubmitting(false);
      } catch {
        ToastError('Erreur', "Impossible d'enregistrer la catégorie");
      }
    },
    [tradeGroupInitialIds, tradeQuestionInitialIds, tradeKeywordInitialIds],
  );

  const initialValues: TradeFormFields = useMemo(() => {
    const tradeGroupIds = formData?.tradeGroups
      .filter(
        ({ tradeGroup }) =>
          !tradeGroup.iconName?.startsWith('fal') &&
          !tradeGroup.iconName?.startsWith('custom'),
      )
      .map(({ tradeGroup }) => tradeGroup.id);

    return {
      id: formData?.id || null,
      slug: formData?.slug || '',
      name: formData?.name || '',
      pageTitle: formData?.pageTitle || '',
      anchor: formData?.anchor || '',
      pageTitleVariant: formData?.pageTitleVariant || '',
      pageSubtitle: formData?.pageSubtitle || '',
      pageTitleDirectory: formData?.pageTitleDirectory || '',
      label: formData?.label || '',
      metaTitle: formData?.metaTitle || '',
      metaDescription: formData?.metaDescription || '',
      tradeGroups: tradeGroupIds ?? [],
      tradeKeywords:
        formData?.tradeKeywords.map((tradeKeyword) => ({
          id: tradeKeyword.id,
          name: tradeKeyword.name,
        })) || [],
      tradeQuestions:
        formData?.tradeQuestions.map((tradeQuestion) => ({
          id: tradeQuestion.id,
          question: tradeQuestion.question,
          answer: tradeQuestion.answer,
        })) || [],
      isAnonymised:
        formData?.isAnonymised !== undefined ? formData?.isAnonymised : false,
      isCallRecordable:
        formData?.isCallRecordable !== undefined
          ? formData?.isCallRecordable
          : true,
      isRgeFilterable:
        formData?.isRgeFilterable !== undefined
          ? formData?.isRgeFilterable
          : true,
    };
  }, [updateTrade, history, tradeId, loading]);

  if (loading) {
    return (
      <Loader size="big" active inline="centered" content="Chargement..." />
    );
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={tradeFormValidationSchema({
        currentSlug: formData?.slug,
      })}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <TradeFormView />
    </Formik>
  );
};

export default TradesFormUpdateContainer;
