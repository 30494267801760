import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import NoteView from './note-view';
import {
  CurrentBilikPersonQuery,
  NoteIdentityInsertMutation,
  NoteIdentitySetInput,
  NotesIdentityByIdentifiersQuery,
} from 'generated/graphql';
import { Formik } from 'formik';
import { NoteFields } from './note-fields-type';
import { ToastError, ToastSuccess } from 'utils';
import { Loader } from 'semantic-ui-react';
import { FetchResult } from '@apollo/client';

type Props = {
  noteIdentitys?: NotesIdentityByIdentifiersQuery['noteIdentity'];
  insertNoteIdentity: (
    noteIdentity: NoteIdentitySetInput,
  ) => Promise<FetchResult<NoteIdentityInsertMutation> | undefined>;
  deleteNoteIdentity: (noteIdentityId: number) => Promise<void>;
  currentBilikPerson?: CurrentBilikPersonQuery['bilikPerson'][0];
  refetch: () => Promise<void>;
  emails: string[] | undefined;
  telephones: string[] | undefined;
};

const NoteContainer: FunctionComponent<Props> = ({
  noteIdentitys,
  insertNoteIdentity,
  deleteNoteIdentity,
  currentBilikPerson,
  refetch,
  emails,
  telephones,
}) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (values: NoteFields) => {
      setLoading(true);

      if (values.message?.trim() === '' && values.id) {
        await deleteNoteIdentity(values.id).catch((error) => {
          throw error;
        });
      } else {
        await insertNoteIdentity({
          id: values.id,
          lastUpdatedDate: new Date(),
          message: values.message || '',
          identifier:
            values.identifier ||
            (telephones?.length
              ? `telephone:${telephones[0]}`
              : emails?.length
              ? `email:${emails[0]}`
              : `id:${values.id}`),
          lastEditorId: currentBilikPerson?.id,
        }).catch((error) => {
          ToastError('Erreur', "Impossible d'enregistrer la note");
          throw error;
        });
      }
      const noteIdentitysToDelete = noteIdentitys?.filter(
        (noteIdentity) => noteIdentity?.id !== initialValues.id,
      );

      noteIdentitysToDelete?.map((noteIdentity) => {
        deleteNoteIdentity(noteIdentity.id).catch((error) => {
          throw error;
        });
      });

      await refetch();

      ToastSuccess('Succès', 'Note enregistrée');
      setLoading(false);
    },
    [insertNoteIdentity, currentBilikPerson, noteIdentitys, loading, refetch],
  );

  useEffect(() => {
    const fetch = async () => {
      await refetch();
    };
    fetch();
  }, []);

  const initialValues: NoteFields = useMemo(() => {
    // Contain all messages of noteIdentitys of the private individual
    const newMessage = [''];
    const emptyInitialValues: NoteFields = {
      id: undefined,
      identifier: undefined,
      message: undefined,
      lastEditorId: undefined,
      lastUpdatedDate: new Date(),
      bilikPerson: undefined,
    };

    if (!noteIdentitys || noteIdentitys.length === 0) {
      return emptyInitialValues;
    } else if (noteIdentitys.length === 1) {
      return noteIdentitys[0];
    }

    const lastUpdatedNote = noteIdentitys.reduce((prev, current) => {
      return prev?.lastUpdatedDate > current?.lastUpdatedDate ? prev : current;
    });

    newMessage.push(
      noteIdentitys.reduce((acc, note) => {
        if (note?.id !== lastUpdatedNote?.id) {
          acc += '\n\n' + note?.message;
        }
        return acc;
      }, ''),
    );

    if (lastUpdatedNote) {
      return {
        ...lastUpdatedNote,
        message: lastUpdatedNote?.message + newMessage.splice(1).join(''),
      };
    }

    return emptyInitialValues;
  }, [noteIdentitys]);

  if (loading || !noteIdentitys) return <Loader active inline="centered" />;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <NoteView />
    </Formik>
  );
};
export default NoteContainer;
