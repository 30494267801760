import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ConfirmModal from '../confirm-modal/confirm-modal';

type ImageDeleteButtonProps = {
  onDelete: () => void;
  style?: Record<string, unknown>;
  confirm?: boolean;
  text?: string;
};

const ImageDeleteButton: FunctionComponent<ImageDeleteButtonProps> = ({
  onDelete,
  style,
  confirm,
  text,
}) => {
  const button = (
    <Button
      negative
      style={
        style ?? {
          position: 'absolute',
          top: '8px',
          right: '5px',
          padding: '5px',
        } // Default style
      }
      icon
      type="button"
      onClick={!confirm ? onDelete : undefined}
    >
      <Icon name="trash alternate outline" /> {text}
    </Button>
  );

  if (!confirm) {
    return button;
  }

  return (
    <ConfirmModal
      trigger={button}
      header="Confirmation"
      content={<p>Voulez vous vraiment supprimer la photo ?</p>}
      onConfirm={onDelete}
    />
  );
};

export default ImageDeleteButton;
