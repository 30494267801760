import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';

type ProReviewReplyRequestedProps = {
  event: MongoEvent;
};

const ProReviewReplied: FunctionComponent<ProReviewReplyRequestedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return (
      <TimelineEvent
        date={event.date}
        icon="comment"
        title="Avis répondu par le pro"
        initiator="pro"
      >
        <h3>
          Réponse à l&apos;avis sur{' '}
          <span>{event.actors.pro.proPresentation.name}</span>
          &nbsp;via
          <span> {event.initiator.pro.email}</span>
        </h3>
        <div>
          <b>Réponse: </b>
          {event.data.proReview.reply}
        </div>
        <div>
          <Label
            as="a"
            onClick={(): void => {
              window.open(
                `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
                '_blank',
              );
            }}
          >
            <Icon name="eye" size="small" />
            Voir avis
          </Label>
        </div>
      </TimelineEvent>
    );
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewReplyRequested (version ${event.version})`}
    />
  );
};

export default ProReviewReplied;
