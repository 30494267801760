import { QueryResult } from '@apollo/client';
import {
  OrderBy,
  useCitiesTableQueryLazyQuery,
  useCityTableDeleteMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';

import CitiesTableContainer from './cities-table-container';

import {
  CitiesTableQuery,
  Exact,
  CityOrderBy,
  CityBoolExp,
} from 'generated/graphql';

type props = {
  bilikZoneId?: number;
};

export type CitiesTableQueryResult = QueryResult<
  CitiesTableQuery,
  Exact<{
    limit?: number | undefined;
    offset?: number | undefined;
    order_by?: CityOrderBy | CityOrderBy[] | undefined;
    where?: CityBoolExp | undefined;
  }>
>;

const CitiesTableRepository: FunctionComponent<props> = ({ bilikZoneId }) => {
  const [citiesTableQuery, { data, loading }] = useCitiesTableQueryLazyQuery();

  const fetchCities = useCallback(async (): Promise<CitiesTableQueryResult> => {
    return citiesTableQuery({
      variables: {
        order_by: [{ name: OrderBy.Asc }],
        where: bilikZoneId
          ? {
              bilikZoneId: {
                _eq: bilikZoneId,
              },
            }
          : {},
      },
    });
  }, [bilikZoneId]);

  const [deleteCityMutation] = useCityTableDeleteMutation();

  const deleteCity = async (cityId: number) => {
    await deleteCityMutation({
      variables: {
        cityId,
      },
    });
  };

  return (
    <CitiesTableContainer
      loading={loading}
      cities={data?.city ?? []}
      bilikZoneId={bilikZoneId}
      fetchCities={fetchCities}
      deleteCity={deleteCity}
    />
  );
};

export default CitiesTableRepository;
