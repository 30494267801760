import React, { Fragment, FunctionComponent } from 'react';
import { Ticket } from './timeline-freshdesk-ticket-preview';
import { formatDateTime } from 'utils';
import { Divider, Icon } from 'semantic-ui-react';

type Props = {
  ticket: Ticket;
};

const TimelineFreshdeskTicket: FunctionComponent<Props> = ({ ticket }) => {
  const conversations = ticket.conversations || [];

  const renderAttachment = (attachment) => (
    <Fragment key={attachment.id}>
      <a
        style={{ color: '#3C8DBC', cursor: 'pointer' }}
        href={attachment.attachment_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {attachment.name}{' '}
      </a>
      <span>
        (
        {attachment.size / 1000 >= 1000
          ? attachment.size / 1000000 + ' Mo'
          : attachment.size / 1000 + ' Ko'}
        )
      </span>{' '}
    </Fragment>
  );

  const removeBlockquotesContents = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const blockquotes = doc.querySelectorAll('blockquote');

    blockquotes.forEach((blockquote) => {
      blockquote.parentNode?.removeChild(blockquote);
    });

    return doc.body.innerHTML;
  };
  return (
    <>
      {/* Conversations */}
      {conversations ? (
        <div>
          {conversations
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime(),
            )
            .map((conversation, index) => (
              <Fragment key={conversation.id}>
                <Divider horizontal>
                  Message{' '}
                  {conversations?.length
                    ? conversations.length - index + 1
                    : ''}{' '}
                  ● {formatDateTime(conversation.created_at)}
                  {conversation.attachments?.length ? (
                    <>
                      <span> ● </span>
                      <Icon name="paperclip" />
                    </>
                  ) : null}
                </Divider>
                {/* ff8c001a */}
                <div
                  style={{
                    backgroundColor: conversation.from_email?.includes(
                      '@bilik.fr',
                    )
                      ? '#a3a19e1a'
                      : '',
                    padding: '10px',
                    borderRadius: '6px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '11pt',
                    }}
                  >
                    De : <b>{conversation.from_email || "Pas l'information"}</b>
                    <br />À :{' '}
                    {conversation.to_emails?.join(', ') || "Pas l'information"}
                    <br />
                    Le :{' '}
                    {formatDateTime(conversation.created_at) ||
                      "Pas l'information"}
                    {conversation.attachments?.length ? (
                      <>
                        <br />
                        <Icon name="paperclip" />
                        {conversation.attachments.map((attachment) =>
                          renderAttachment(attachment),
                        )}
                      </>
                    ) : null}
                  </div>
                  <br />
                  {/* We want to keep only the response text of mails */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: removeBlockquotesContents(conversation.body),
                    }}
                  />
                </div>
                <br />
              </Fragment>
            ))}
          {/* First message which create the ticket */}
          <Divider horizontal>
            Message 1 ● {formatDateTime(ticket.created_at)}{' '}
            {ticket.attachments?.length ? (
              <>
                <span> ● </span>
                <Icon name="paperclip" />
              </>
            ) : null}
          </Divider>
          <div style={{ fontSize: '11pt' }}>
            De : <b>{ticket.from_email}</b>
            <br />
            {ticket.to_emails?.length ? (
              <>
                À : {ticket.to_emails.join(', ')}
                <br />
              </>
            ) : null}
            {ticket.cc_emails?.length ? (
              <>
                Cc : {ticket.cc_emails.join(', ')}
                <br />
              </>
            ) : null}
            {ticket.fwd_emails?.length ? (
              <>
                Transféré à : {ticket.fwd_emails.join(', ')}
                <br />
              </>
            ) : null}
            Le : {formatDateTime(ticket.created_at)}
            {ticket.attachments?.length ? (
              <>
                <br />
                <Icon name="paperclip" />
                {ticket.attachments.map((attachment) =>
                  renderAttachment(attachment),
                )}
              </>
            ) : null}
          </div>

          <br />

          <div
            dangerouslySetInnerHTML={{
              __html: ticket.description || '',
            }}
          />
        </div>
      ) : null}
    </>
  );
};
export default TimelineFreshdeskTicket;
