import { Button, Grid, Icon } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

import { BilikPeopleTable } from './bilik-people-table';
import { Link } from 'react-router-dom';
import {} from 'react-router';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';

const BilikPeopleListPage: FunctionComponent = () => {
  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>Utilisateurs</ContentHeaderTitle>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to="/bilik_people/create">
              <Button positive>
                <Icon name="plus" />
                Nouveau
              </Button>
            </Link>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <BilikPeopleTable />
      </ContentBody>
    </Content>
  );
};

export default BilikPeopleListPage;
