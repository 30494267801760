import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import React, { Fragment, FunctionComponent } from 'react';
import { Trade } from 'generated/graphql';
import { FieldArray, useFormikContext } from 'formik';
import SelectTradeGroupField from 'components/select-trade-group-field/select-trade-group-field';
import FormField from 'components/form-field/form-field';
import { useSlugInCircularDependency } from 'hooks/use-slug-in-circular-dependency/use-slug-in-circular-dependency';
import SlugRedirectModal from 'components/slug-redirect-modal/slug-redirect-modal';
import { useDebounce } from 'use-debounce';
import { TradeQuestionFields } from 'components/trade-question-fields/trade-question-fields';

type FormValues = Pick<
  Trade,
  | 'name'
  | 'slug'
  | 'id'
  | 'metaTitle'
  | 'metaDescription'
  | 'tradeKeywords'
  | 'pageTitleVariant'
  | 'pageSubtitle'
  | 'pageTitleDirectory'
  | 'label'
  | 'tradeGroups'
> & {
  tradeGroups: number[];
};

const TradeFormView: FunctionComponent = () => {
  const { isSubmitting, handleReset, handleSubmit, values, initialValues } =
    useFormikContext<FormValues>();

  const [debounceSlugValue] = useDebounce(values.slug, 600);

  const circularDependencies = useSlugInCircularDependency(
    debounceSlugValue,
    initialValues.slug,
  );

  return (
    <>
      <SlugRedirectModal circularDependencies={circularDependencies} />
      <Form onReset={handleReset} onSubmit={handleSubmit}>
        <Segment.Group>
          <Segment color="blue">
            <Header>Général</Header>
          </Segment>
          <Segment>
            <FormField
              type="text"
              placeholder="Ex: Plombier chauffagiste (dépannage)"
              label="Nom"
              name="name"
              helpText="Apparait dans les menus du site"
              required
            />
            <FormField
              type="text"
              placeholder="Ex: peintre"
              label="Slug"
              helpText="Le slug est la version normalisée du nom. Il ne contient généralement que des lettres minuscules non accentuées, des chiffres et des traits d’union."
              name="slug"
              required
            />
            <FormField
              type="text"
              placeholder="Ex: plombier, plombier chauffagiste, plombier chauffagiste [ZONE_NAME]"
              label="Label (version courte du nom)"
              helpText="Ex: Agent immo."
              name="label"
              required
            />
            <SelectTradeGroupField
              label="Univers"
              name="tradeGroups"
              helpText="Liste des univers où la catégorie est disponible"
              required
              multiple
              search
              selection
              clearable
            />
            <FormField
              type="checkbox"
              name="isCallRecordable"
              label="Enregistrer les appels"
              helpText="Enregistre les conversations téléphoniques"
            />
            <FormField
              type="checkbox"
              name="isAnonymised"
              label="Anonymiser les noms des particuliers"
              helpText="Les noms des particuliers sont anonymisés dans l'affichage des avis sur le site (ex: J D au lieu de Jean Dupont)"
            />
          </Segment>
        </Segment.Group>
        <Segment.Group>
          <Segment color="blue">
            <Header>SEO</Header>
          </Segment>
          <Segment>
            <FormField
              type="text"
              label="Titre"
              helpText="Ex: Peintres à [ZONE_NAME] | Variable disponible : [ZONE_NAME]"
              name="pageTitle"
              required
            />
            <FormField
              type="text"
              label="Ancre maillage interne"
              helpText="Ex: Peintre"
              name="anchor"
              required
            />
            <FormField
              type="text"
              label="Variante du titre (pro solo)"
              helpText="Ex: Peintre à [ZONE_NAME] | Variable disponible : [ZONE_NAME]"
              name="pageTitleVariant"
              required
            />
            <FormField
              type="text"
              label="Titre de l'annuaire"
              name="pageTitleDirectory"
              helpText="Ex: Peintres (intérieur)"
              required
            />
            <FormField
              type="text"
              label="Sous-titre"
              name="pageSubtitle"
              helpText="Ex: Trouver une entreprise de peinture de confiance près de chez vous"
              required
            />
            <FormField
              type="text"
              label="Meta title "
              helpText="Ex: Peintre à [ZONE_NAME] | Artisans de confiance | Variable disponible : [ZONE_NAME]"
              name="metaTitle"
              required
            />
            <FormField
              type="text"
              placeholder="Ex: Trouvez un plombier chauffagiste (dépannage) à [ZONE_NAME]"
              label="Meta description"
              helpText="Ex: Faites confiance à nos peintres à [ZONE_NAME]. Entreprises de peinture auditées et recommandées. Contactez-les maintenant ! | Variable disponible : [ZONE_NAME]"
              name="metaDescription"
              required
            />
            <FormField
              type="checkbox"
              name="isRgeFilterable"
              label="Référencé RGE"
              helpText="Affichage du métier dans la liste des métiers RGE sur le site"
            />
          </Segment>
        </Segment.Group>
        {initialValues.id ? (
          <Fragment>
            <Segment.Group>
              <Segment color="blue">
                <Header>Mots-clés pour le moteur de recherche </Header>
              </Segment>
              <Segment>
                {' '}
                <FieldArray
                  name="tradeKeywords"
                  render={(arrayHelpers): React.ReactElement => (
                    <Fragment>
                      {values.tradeKeywords && values.tradeKeywords.length > 0
                        ? values.tradeKeywords.map((_, tradeKeywordIndex) => (
                            <Fragment key={tradeKeywordIndex}>
                              <Form.Group widths="equal">
                                <FormField
                                  name={`tradeKeywords.${tradeKeywordIndex}.name`}
                                  type="text"
                                  placeholder="Mot-clé"
                                />
                                <Button
                                  basic
                                  negative
                                  icon
                                  floated="right"
                                  type="button"
                                  onClick={(): void => {
                                    arrayHelpers.remove(tradeKeywordIndex);
                                  }}
                                >
                                  <Icon name="trash alternate outline" />
                                </Button>
                              </Form.Group>
                              <Divider hidden />
                            </Fragment>
                          ))
                        : null}
                      <Segment basic textAlign={'right'}>
                        <Button
                          type="button"
                          positive
                          onClick={(): void => {
                            arrayHelpers.push({ name: '' });
                          }}
                        >
                          <Icon name="plus" />
                          Nouveau mot-clé
                        </Button>
                      </Segment>
                    </Fragment>
                  )}
                />
              </Segment>
            </Segment.Group>

            <Segment.Group>
              <Segment color="blue">
                <Header>FAQ</Header>
              </Segment>
              <Segment>
                <TradeQuestionFields tradeId={values.id} />
              </Segment>
            </Segment.Group>
          </Fragment>
        ) : null}
        <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
          <Button
            type="submit"
            primary
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <Icon name="save" />
            Enregistrer
          </Button>
        </Container>
      </Form>
    </>
  );
};

export default TradeFormView;
