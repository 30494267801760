import { OrderBy, useProReviewInvitationsTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import ProReviewInvitationsTableContainer from './pro-review-invitations-table-container';

type ProReviewInvitationsTableRepository = {
  filters?: {
    proViewId?: number;
    proReviewInvitationId?: number;
    hasProReview?: boolean;
    isAutorespondActive?: boolean;
    email?: string;
  };
  limit?: number;
  offset?: number;
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
};

const ProReviewInvitationsTableRepository: FunctionComponent<
  ProReviewInvitationsTableRepository
> = ({ filters, limit, offset, orderBy, onSort, onFilter }) => {
  const { loading, data } = useProReviewInvitationsTableQuery({
    variables: {
      where: {
        id: { _eq: filters?.proReviewInvitationId },
        email: { _eq: filters?.email },
        proPresentation: { proViews: { id: { _eq: filters?.proViewId } } },
        proReviewId: {
          _isNull:
            filters?.hasProReview !== undefined
              ? !filters.hasProReview
              : undefined,
        },
        isAutorespondActive: { _eq: filters?.isAutorespondActive },
      },
      orderBy: orderBy
        ? [
            {
              [orderBy.column]:
                orderBy.direction === 'ascending' ? OrderBy.Asc : OrderBy.Desc,
            },
          ]
        : undefined,
      limit,
      offset,
    },
  });

  return (
    <ProReviewInvitationsTableContainer
      loading={loading}
      proReviewInvitations={data?.proReviewInvitation}
      orderBy={orderBy}
      onSort={onSort}
      onFilter={onFilter}
    />
  );
};

export default ProReviewInvitationsTableRepository;
