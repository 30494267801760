import Dropzone from 'components/dropzone/dropzone';
import React, { FunctionComponent } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Button, Icon } from 'semantic-ui-react';
import { ToastError } from 'utils';

type Document = {
  file: File;
  type: string;
};

type DocumentsUploadProps = {
  onDocumentsChanged: (documents: Document[]) => void;
  accept?: Accept;
  maxFiles?: number;
  label?: string;
};

const DocumentsUpload: FunctionComponent<DocumentsUploadProps> = ({
  onDocumentsChanged,
  label,
  maxFiles,
  accept = {
    'image/*': ['.png', '.jpeg', '.jpg'],
    'application/*': ['.pdf'],
  },
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    maxFiles,
    onDrop: (droppedFiles: File[], errors) => {
      if (errors.length > 0) {
        ToastError('Erreur', errors[0].errors[0].message);
        console.error(errors[0].errors[0].message);

        return;
      }

      if (droppedFiles.length > 0) {
        const documents = droppedFiles.map((doppedFile): Document => {
          return {
            file: doppedFile,
            type: 'other',
          };
        });

        onDocumentsChanged(documents);
      }
    },
  });

  return (
    <Dropzone
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragActive}
      isDragAccept={isDragAccept}
      isDragReject={isDragReject}
    >
      <Icon size="huge" name="folder open" />
      <Button positive type="button" style={{ marginTop: '10px' }}>
        <Icon name="upload" />
        {label ? label : 'Ajouter des documents'}
      </Button>
    </Dropzone>
  );
};

export default DocumentsUpload;
