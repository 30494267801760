import React, { FunctionComponent } from 'react';
import SolicitationsList from '../../../../components/solicitations-list/solicitations-list';

type ProSolicitationsRouteProps = {
  bilikZoneId: number;
  proPresentationId: number;
};

const ProSolicitationsRoute: FunctionComponent<ProSolicitationsRouteProps> = ({
  bilikZoneId,
  proPresentationId,
}) => (
  <SolicitationsList
    givenBilikZoneId={bilikZoneId}
    givenProPresentationId={proPresentationId}
    noMargin={true}
  />
);

export default ProSolicitationsRoute;
