import {
  addMonths,
  compareAsc,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from 'date-fns';
import { isSameMonth } from 'date-fns/esm';
import { ProSolicitationsByDateQuery } from 'generated/graphql';
import { map, uniqBy } from 'remeda';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Container, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ProStatsSolicitationsView } from './pro-stats-solicitations-view';

type Props = {
  solicitations?: ProSolicitationsByDateQuery['solicitation'];
  startDate: Date;
  endDate: Date;
};

export const ProStatsSolicitationsContainer: FunctionComponent<Props> = ({
  solicitations,
  startDate,
  endDate,
}) => {
  const [chartData, setChartData] = useState<
    {
      month: string;
      date: string;
      mails: number;
      'mails uniques': number;
    }[]
  >();

  useEffect(() => {
    if (solicitations) {
      const numberOfMonthBetween: number = differenceInMonths(
        endOfMonth(endDate),
        startOfMonth(startDate),
      );

      const monthsNumber = Array.from(Array(numberOfMonthBetween + 2).keys());

      // Each months between startDate and EndDate
      const months = map(monthsNumber, (month) => addMonths(startDate, month));

      const newChartData: {
        month: string;
        date: string;
        mails: number;
        'mails uniques': number;
      }[] = [];

      // Create object of date for empty month
      months.pop();
      months.forEach((month) => {
        const doesExist = solicitations.filter((value) => {
          if (isSameMonth(new Date(value.dateCreated), new Date(month)))
            return value;
        });

        if (!doesExist) {
          newChartData.push({
            month: format(month, 'MM/yyyy'),
            mails: 0,
            'mails uniques': 0,
            date: month.toString(),
          });
        } else {
          newChartData.push({
            date: month.toString(),
            mails: doesExist.length,
            'mails uniques': uniqBy(doesExist, (value) => value.email).length,
            month: format(new Date(month), 'MM/yyyy'),
          });
        }
      });
      //Sorting data
      newChartData.sort((a, b) =>
        compareAsc(new Date(a.date), new Date(b.date)),
      );

      setChartData(newChartData);
    }
  }, [solicitations, startDate, endDate]);

  const totalCount = useMemo(
    () =>
      chartData
        ?.map((value) => value.mails)
        .reduce((acc, value) => acc + value),
    [chartData],
  );

  const uniqTotalCount = useMemo(
    () =>
      chartData
        ?.map((value) => value['mails uniques'])
        .reduce((acc, value) => acc + value),
    [chartData],
  );

  if (!chartData) {
    return (
      <Segment style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </Segment>
    );
  }

  if (chartData.length <= 0) {
    return (
      <Container fluid style={{ height: '576px' }}>
        <Header as="h2">Relances d&apos;avis</Header>
        <Message>Pas de stats</Message>
      </Container>
    );
  }

  return (
    <ProStatsSolicitationsView
      chartData={chartData}
      totalCount={totalCount || 0}
      uniqTotalCount={uniqTotalCount || 0}
    />
  );
};
