import * as Yup from 'yup';

export default (): Yup.ObjectSchema =>
  Yup.object({
    name: Yup.string().required('Le nom est requis !'),
    street1: Yup.string().required("L'adresse est requise !"),
    street2: Yup.string().nullable().notRequired(),
    postalCode: Yup.string().required('Le code postal est requis !'),
    city: Yup.string().required('La ville est requise !'),
    billingEmail: Yup.string()
      .required('Adresse email de facturation requise !')
      .email('Email invalide !'),
    siret: Yup.string()
      .trim()
      .when('isForeign', {
        is: false,
        then: Yup.string().length(14, 'Un SIRET français fait 14 chiffres !'),
      })
      .required('le numéro SIRET est requis'),
    tvaNumber: Yup.string()
      .trim()
      .when('isForeign', {
        is: false,
        then: Yup.string()
          .length(13, 'le numéro de TVA doit faire 13 caractères !')
          .matches(
            /^FR[0-9]{11}$/,
            'Le numéro de TVA est invalide, FR + 11 chiffres !',
          ),
      })
      .required('le numéro TVA est requis'),
    businessStatus: Yup.string()
      .required("Le statut de l'entreprise est requis !")
      .max(
        45,
        'Le statut est trop long, cela ne rentrera pas dans le contract',
      ),
  });
