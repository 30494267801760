import React, { FunctionComponent } from 'react';
import ProOrganizationFormCreateRepository from './pro-organization-form-create-repository';
import ProOrganizationFormUpdateRepository from './pro-organization-form-update-repository';

export type ProOrganizationFormModalProps = {
  open: boolean;
  onClose: () => void;
  billingEmailSuggestions?: string[];
};

type ProOrganizationFormProps = ProOrganizationFormModalProps & {
  proOrganizationId?: number | null;
  onProOrganizationCreated?: (proOrganizationId: number) => void;
  onProOrganizationUpdated?: () => void;
  billingEmailSuggestions: any;
};

const ProOrganizationFormModal: FunctionComponent<ProOrganizationFormProps> = ({
  proOrganizationId,
  onProOrganizationUpdated,
  onProOrganizationCreated,
  ...rest
}) => {
  return (
    <>
      {proOrganizationId ? (
        <ProOrganizationFormUpdateRepository
          onProOrganizationUpdated={onProOrganizationUpdated}
          proOrganizationId={proOrganizationId}
          {...rest}
        />
      ) : (
        <ProOrganizationFormCreateRepository
          onProOrganizationCreated={onProOrganizationCreated}
          {...rest}
        />
      )}
    </>
  );
};

export default ProOrganizationFormModal;
