import { Icon, Label, List } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import {
  SemanticCOLORS,
  SemanticICONS,
} from 'semantic-ui-react/dist/commonjs/generic';

import { formatDateTime } from 'utils/locale';

type TimelineSmsActionProps = {
  date: string;
  color: SemanticCOLORS;
  text: string;
  icon: SemanticICONS;
};

const TimelineSmsAction: FunctionComponent<TimelineSmsActionProps> = ({
  date,
  color,
  text,
  icon,
}) => (
  <List.Item>
    <Label
      color={color}
      horizontal
      size="large"
      style={{ width: '100px', textAlign: 'left' }}
    >
      <Icon name={icon} style={{ width: '15px' }} />
      {text}
    </Label>
    - {formatDateTime(date)}
  </List.Item>
);

export default TimelineSmsAction;
