import React from 'react';

export const getFormErrorMessage = (errors: string[]) => {
  const errorMsg = (
    <>
      Description des erreurs :
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{`${error}`}</li>
        ))}
      </ul>
    </>
  );
  return errorMsg;
};
