import { FunctionComponent } from 'react';
import { Label, Popup } from 'semantic-ui-react';
import React from 'react';

type SolicitationCheckerIndicatorViewProps = {
  hasMadeSolicitationBefore: boolean;
  hasSolicitedReviewedPro: boolean;
  hasActiveSolicitationToOtherPros: boolean;
  openOtherSolicitationPiMade: () => void;
};

export const SolicitationCheckerIndicatorView: FunctionComponent<
  SolicitationCheckerIndicatorViewProps
> = ({
  hasMadeSolicitationBefore,
  hasSolicitedReviewedPro,
  hasActiveSolicitationToOtherPros,
  openOtherSolicitationPiMade,
}) => {
  if (!hasMadeSolicitationBefore) {
    return (
      <Label size="mini" color="grey">
        Inconnu
      </Label>
    );
  }

  if (hasSolicitedReviewedPro) {
    return (
      <Label size="mini" color="green">
        Connu et à jour
      </Label>
    );
  }

  /*
    If there are any solicitations that have an active autoresponder and that are not linked
    to the pro that the author is currently reviewing.
    */
  if (hasActiveSolicitationToOtherPros && !hasSolicitedReviewedPro) {
    return (
      <Popup
        content="Il existe d'autres demandes actives, pensez à désactiver le suivi pour les autres pro du même catégorie"
        trigger={
          <Label
            onClick={openOtherSolicitationPiMade}
            style={{ cursor: 'pointer' }}
            size="mini"
            color="red"
          >
            Verifier
          </Label>
        }
      />
    );
  }

  if (hasMadeSolicitationBefore && !hasSolicitedReviewedPro) {
    return (
      <Popup
        content="L'auteur de l'avis à fait une demande par mail à un autre pro"
        trigger={
          <Label
            onClick={openOtherSolicitationPiMade}
            style={{ cursor: 'pointer' }}
            size="mini"
            color="red"
          >
            Verifier
          </Label>
        }
      />
    );
  }

  // All remaining solicitations are either inactive or all linked the the currently reviewed pro.
  return (
    <Label size="mini" color="green">
      Connu et à jour
    </Label>
  );
};
