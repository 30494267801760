import {
  Button,
  Dimmer,
  Icon,
  Label,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';
import { SelectCurrentBilikZone } from 'components/select-current-bilik-zone';
import { SelectBilikZone } from 'components/select-bilik-zone/select-bilik-zone';
import { Trade } from './trades-table-container';

type Props = {
  loading: boolean;
  trades: Trade[];
  onSort?: (column: string, direction: string) => void;
  orderBy?: {
    column: string;
    direction: string;
  };
  refetch: () => Promise<void>;
};

export const TradesTableView: FunctionComponent<Props> = ({
  loading,
  trades,
  refetch,
  orderBy,
  onSort,
}) => {
  const direction = useMemo(() => {
    switch (orderBy?.direction) {
      case 'ascending':
      case 'descending':
        return orderBy.direction;
      default:
        return 'ascending';
    }
  }, [orderBy?.direction]);

  const isCurrentUserAdmin = useIsUserAdmin();

  const [bilikZoneIdBisSelected, setBilikZoneIdBisSelected] = useState(
    Number(localStorage.getItem('bilikZoneIdBis')),
  );

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'name',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={orderBy?.column === 'name' ? direction : undefined}
            >
              Nom
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'tradeGroups',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={orderBy?.column === 'tradeGroups' ? direction : undefined}
            >
              Univers
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'tradeProViews',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={
                orderBy?.column === 'tradeProViews' ? direction : undefined
              }
            >
              Total pros{' '}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'tradeProViewsBis',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={
                orderBy?.column === 'tradeProViewsBis' ? direction : undefined
              }
            >
              Total pros
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'tradeProViewsInMainTradeAndSecondaryTrade',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={
                orderBy?.column === 'tradeProViewsInMainTradeAndSecondaryTrade'
                  ? direction
                  : undefined
              }
            >
              Total pros (en principal + bis)
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              onClick={(): void => {
                if (onSort) {
                  onSort(
                    'tradeProViewsInMainTradeAndSecondaryTradeBis',
                    direction === 'ascending' ? 'descending' : 'ascending',
                  );
                }
              }}
              sorted={
                orderBy?.column ===
                'tradeProViewsInMainTradeAndSecondaryTradeBis'
                  ? direction
                  : undefined
              }
            >
              Total pros (en principal + bis)
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Mots-clés pour moteur de recherche
            </Table.HeaderCell>
            {isCurrentUserAdmin && (
              <Table.HeaderCell textAlign="right" className="not sortable">
                Actions
              </Table.HeaderCell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="not sortable" />
            <Table.HeaderCell className="not sortable" />
            <Table.HeaderCell className="not sortable">
              <SelectCurrentBilikZone
                onChange={(bilikZone): void => {
                  console.log(bilikZone);
                }}
                allAuthorizedZones
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              <SelectBilikZone
                onChange={(bilikZoneId): void => {
                  localStorage.setItem(
                    'bilikZoneIdBis',
                    Number(bilikZoneId)?.toString(),
                  );
                  setBilikZoneIdBisSelected(Number(bilikZoneId));
                  refetch();
                }}
                value={bilikZoneIdBisSelected}
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              <SelectCurrentBilikZone
                onChange={(bilikZone): void => {
                  console.log(bilikZone);
                }}
                allAuthorizedZones
                disabled
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              <SelectBilikZone
                onChange={(bilikZoneId): void => {
                  localStorage.setItem(
                    'bilikZoneIdBis',
                    Number(bilikZoneId)?.toString(),
                  );
                  setBilikZoneIdBisSelected(Number(bilikZoneId));
                  refetch();
                }}
                value={bilikZoneIdBisSelected}
                disabled
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable" />
            <Table.HeaderCell className="not sortable" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {trades.map((trade) => {
            const totalProCount = trade.totalProCount || 0;

            const totalMainAndSecondaryTradeProCount =
              trade.totalMainAndSecondaryTradeProCount || 0;

            const totalProsCountBis = trade.totalProsCountBis || 0;

            const totalMainAndSecondaryTradeProCountBis =
              trade.totalMainAndSecondaryTradeProCountBis || 0;

            return (
              <Table.Row key={trade.id}>
                <Table.Cell>{trade.name}</Table.Cell>
                <Table.Cell>
                  {(trade.tradeGroups || []).map((tradeGroup) => (
                    <Label key={tradeGroup.tradeGroup.id} color="grey">
                      {tradeGroup.tradeGroup.name}
                    </Label>
                  ))}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {totalProCount || <Label>Aucun</Label>}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {totalProsCountBis || <Label>Aucun</Label>}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {totalMainAndSecondaryTradeProCount || <Label>Aucun</Label>}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {totalMainAndSecondaryTradeProCountBis || (
                    <Label>Aucun</Label>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Table.Cell>
                    {(trade.tradeKeywords || [])
                      .map((keyword) => keyword.name)
                      .join(' | ')}
                  </Table.Cell>
                </Table.Cell>
                {isCurrentUserAdmin && (
                  <Table.Cell textAlign="right">
                    <Link to={`/trades/update/${trade.id}`}>
                      <Popup
                        content="Modifier"
                        position="top center"
                        trigger={
                          <Button primary icon>
                            <Icon name="pencil alternate" />
                          </Button>
                        }
                      />
                    </Link>
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};
