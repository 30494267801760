import { useFormikContext } from 'formik';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Container,
  DropdownItemProps,
  Form,
  Icon,
  Modal,
  Select,
  FormField as FormFieldUi,
  Divider,
  Transition,
} from 'semantic-ui-react';
import { ProOrganizationFormFields } from './pro-organization-form-fields-type';
import { ProOrganizationFormModalProps } from './pro-organization-form-modal';
import FormField from 'components/form-field/form-field';
import Label from 'components/label/label';
import { OrganizationSearchField } from 'components/organization-search-field';
import { formattedPostalAddress } from 'utils/formatted-postal-address';
import { apiClient } from 'axios-client';
import { generateTvaNumber } from 'utils/generate-tva-number';
import FieldError from 'components/field-error/field-error';
import HelpText from 'components/help-text/help-text';

type ProOrganizationFormViewProps = ProOrganizationFormModalProps & {
  proOrganizationId?: number;
  shouldStep: boolean;
};

const ProOrganizationFormView: FunctionComponent<
  ProOrganizationFormViewProps
> = ({
  open,
  onClose,
  proOrganizationId,
  billingEmailSuggestions,
  shouldStep,
}) => {
  const {
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    handleReset,
    resetForm,
    submitForm,
    setValues,
  } = useFormikContext<ProOrganizationFormFields>();

  const [tvaNumberState, setTvaNumberState] = useState<{
    isValid?: boolean;
    isChecked?: boolean;
  }>({
    isValid: undefined,
    isChecked: undefined,
  });

  const [isInvalidAddress, setIsInvalidAddress] = useState<boolean>(false);

  const [addressModalState, setAddressModalState] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  const [isOrganizationSelected, setIsOrganizationSelected] = useState(false);

  const [step, setStep] = useState(shouldStep ? 1 : 0);

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const checkTvaNumber = useCallback(async (tvaNumber: string) => {
    const response = await apiClient.get(
      `/form/pro-organization/check-tva-number/${tvaNumber}`,
    );

    if (!response?.data) {
      return;
    }

    const { isValid, isChecked } = response.data;

    setTvaNumberState({
      isValid: isValid,
      isChecked,
    });
  }, []);

  const billingEmailSuggestionOptions = useMemo(() => {
    const options: DropdownItemProps[] =
      billingEmailSuggestions?.map((emailSuggestion, index) => ({
        key: `billing-email-suggestion-${index}`,
        value: emailSuggestion,
        text: emailSuggestion,
      })) || [];

    options.push({
      key: 'billing-email-suggestion-custom',
      text: 'Personnalisé',
      value: 'custom',
    });
    return options;
  }, [billingEmailSuggestions]);

  const [billingEmailSuggestionValue, setBillingEmailSuggestionValue] =
    useState<string>();

  useEffect(() => {
    if (values.billingEmail) {
      setBillingEmailSuggestionValue(
        billingEmailSuggestions?.includes(values.billingEmail)
          ? values.billingEmail
          : 'custom',
      );
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={(): void => {
          if (onClose) {
            onClose();
          }
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>
          {proOrganizationId
            ? "Édition de l'entreprise"
            : `Nouvelle entreprise`}
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit} onReset={handleReset}>
            {step === 1 ? (
              <>
                <Label>Rechercher dans la base INSEE</Label>
                <Form.Group>
                  <OrganizationSearchField
                    onSelectResult={(organization) => {
                      const tvaNumber =
                        generateTvaNumber(organization.siret) ?? '';

                      checkTvaNumber(tvaNumber);

                      setValues({
                        ...values,
                        name: organization.name,
                        street1: organization.street1,
                        street2: organization.street2,
                        postalCode: organization.postalCode,
                        siret: organization.siret,
                        businessStatus: organization.businessStatus,
                        tvaNumber: tvaNumber,
                        formattedAddress: `${formattedPostalAddress({
                          city: organization.city,
                          postalCode: organization.postalCode,
                          street: organization.street1,
                        })}
                        ${
                          organization.country !== 'FRANCE'
                            ? `, ${organization.country}`
                            : ''
                        }`, // TODO foreign companies => future ticket
                        city: organization.city,
                      });
                      setIsOrganizationSelected(true);
                      nextStep();
                    }}
                  />
                  <Button
                    primary
                    style={{ marginLeft: '15px', minWidth: '270px' }}
                    type="submit"
                    onClick={() => {
                      nextStep();
                      setFieldTouched('name', true);

                      if (isOrganizationSelected) {
                        resetForm();
                        setIsOrganizationSelected(false);
                        setTvaNumberState({
                          isChecked: undefined,
                          isValid: undefined,
                        });
                      }
                    }}
                  >
                    <Icon name="edit" />
                    Ajouter une entreprise manuellement
                  </Button>
                </Form.Group>
                <HelpText style={{ margin: '0px' }}>
                  Si vous souhaitez rechercher un entrepreneur individuel,
                  écrivez son prénom + nom (Ex: Jean Dupont). Celui-ci est
                  automatiquement complété par MADAME ou MONSIEUR.
                </HelpText>
              </>
            ) : null}
            {!step || step === 2 ? (
              <>
                <FormField
                  disabled={!!proOrganizationId || isOrganizationSelected}
                  name="name"
                  type="text"
                  onChange={(event): void => {
                    setFieldValue('name', event.target.value.toUpperCase());
                  }}
                  label="Nom de l'entreprise"
                  required
                />
                <FormField
                  type="checkbox"
                  name="isForeign"
                  helpText="Cochez cette case si l'entreprise a un SIRET étranger"
                  label="SIRET étranger"
                />
                <FormField
                  disabled={!!proOrganizationId || isOrganizationSelected}
                  name="siret"
                  type="text"
                  helpText="Le SIRET est composé de 14 chiffres"
                  label="Siret du siège social"
                  required
                />
                <FormField
                  name="businessStatus"
                  type="text"
                  label="Statut de l'entreprise"
                  helpText="Nécessaire pour la signature du contrat"
                  placeholder="Ex: SAS, SARL, EURL, etc."
                  required
                />

                <Form.Group widths="equal">
                  <FormField
                    name="tvaNumber"
                    type="text"
                    helpText={
                      <>
                        {tvaNumberState.isValid && (
                          <span style={{ color: 'green' }}>
                            Numéro de TVA validé par les instances européennes
                          </span>
                        )}
                        {tvaNumberState.isChecked === undefined && (
                          <a
                            href={'#'}
                            onClick={() => {
                              if (
                                values.tvaNumber &&
                                values.tvaNumber.length === 13
                              ) {
                                // Check TVA Number
                                checkTvaNumber(values.tvaNumber);
                              } else if (values.siret) {
                                // If there is no TVA number but there is SIRET, generate it
                                const tvaNumber =
                                  generateTvaNumber(values.siret) ?? '';
                                setFieldValue('tvaNumber', tvaNumber);
                                checkTvaNumber(tvaNumber);
                              } else {
                                // Do nothing
                                return;
                              }
                            }}
                          >
                            {values.tvaNumber && values.tvaNumber.length === 13
                              ? 'Vérifier le numéro de TVA'
                              : 'Générer le numéro de TVA'}
                          </a>
                        )}
                        {tvaNumberState.isChecked &&
                          !tvaNumberState.isValid && (
                            <FieldError>
                              Numéro de TVA non repertorié par les instances
                              européennes
                            </FieldError>
                          )}
                        {tvaNumberState.isChecked === false && (
                          <FieldError>
                            API de vérification de numéro de TVA indisponible,
                            vérifiez le numéro de TVA ultérieurement
                          </FieldError>
                        )}
                      </>
                    }
                    label="Numéro de TVA"
                    placeholder="FR + 11 chiffres (ex: FR12345678901)"
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal" style={{ marginBottom: 0 }}>
                  <FormField
                    type="place"
                    name={'formattedAddress'}
                    label={`Adresse du siège social (utilisée pour la facturation)`}
                    required
                    placeholder="Ex : 15 rue Pierre Dupont, Grenoble"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFieldTouched('formattedAddress');
                      setFieldValue('formattedAddress', e.target.value);
                      setIsInvalidAddress(true);
                    }}
                    onPlaceSelected={(place) => {
                      // Get street_number from google autocomplete object
                      const streetNumber = place.address_components?.find(
                        (item) => item.types.includes('street_number'),
                      )?.long_name;

                      // Get street name from google autocomplete object
                      const streetName = place.address_components?.find(
                        (item) => item.types.includes('route'),
                      )?.long_name;

                      // Get postal code from google autocomplete object
                      const postalCode = place.address_components?.find(
                        (item) => item.types.includes('postal_code'),
                      )?.long_name;

                      // Get city from google autocomplete object
                      const city = place.address_components?.find((item) =>
                        item.types.includes('locality'),
                      )?.long_name;

                      const street = `${streetNumber ?? ''} ${
                        streetName ?? ''
                      }`.trim();

                      setFieldValue(
                        'formattedAddress',
                        place.formatted_address,
                      );
                      setFieldValue('street1', street);
                      setFieldValue('postalCode', postalCode);
                      setFieldValue('city', city);
                      setIsInvalidAddress(false);
                    }}
                    options={{
                      componentRestrictions: { country: 'fr' },
                      fields: [
                        'address_components',
                        'geometry',
                        'formatted_address',
                      ],
                      types: ['address'],
                    }}
                  />
                  <Button
                    icon
                    primary
                    style={{
                      minWidth: '45px',
                      marginTop: '22px',
                    }}
                    type="button"
                    onClick={(): void => {
                      setAddressModalState({
                        open: true,
                      });
                    }}
                  >
                    <Icon name="pencil alternate" />
                  </Button>
                </Form.Group>
                {isInvalidAddress ||
                errors.postalCode ||
                errors.city ||
                errors.street1 ? (
                  <FieldError color="red">
                    Veuillez sélectionner une adresse valide parmi les
                    propositions. Si aucune adresse ne vous convient, remplissez
                    les champs manuellement à l&apos;aide du bouton
                    d&apos;édition à droite
                  </FieldError>
                ) : null}
                <Divider hidden style={{ margin: '6px 0' }} />
                <FormFieldUi>
                  <Label required>Email de facturation</Label>
                  <Select
                    onChange={(e, { value }): void => {
                      // Undefined/null check needed because value `0` need to pass
                      if (value !== undefined && value !== null) {
                        const selectedOption =
                          billingEmailSuggestionOptions.filter(
                            (option) => option.value === value,
                          )[0];

                        const optionValue = selectedOption.value as string;

                        setBillingEmailSuggestionValue(optionValue);
                        setFieldValue(
                          'billingEmail',
                          optionValue !== 'custom' ? optionValue : '',
                        );
                      }
                    }}
                    placeholder="Selectionnez votre email de facturation..."
                    options={billingEmailSuggestionOptions}
                    value={billingEmailSuggestionValue}
                    error={!!errors.billingEmail && touched.billingEmail}
                  />
                </FormFieldUi>
                <Transition.Group duration={250}>
                  {billingEmailSuggestionValue === 'custom' ? (
                    <Container fluid style={{ paddingLeft: '25px' }}>
                      <Form.Group widths="equal">
                        <FormField
                          type="email"
                          label="Email personnalisé"
                          name="billingEmail"
                          required
                        />
                      </Form.Group>
                    </Container>
                  ) : null}
                </Transition.Group>
              </>
            ) : null}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={(): void => {
              if (onClose) {
                onClose();
              }
              setStep(1);
            }}
          >
            Fermer
          </Button>

          {step > 1 ? (
            <Button
              onClick={(): void => {
                previousStep();
              }}
            >
              Retour
            </Button>
          ) : null}
          {step && step < 2 ? (
            <Button
              disabled={
                errors.formattedAddress ||
                isInvalidAddress ||
                errors.siret ||
                errors.name ||
                errors.tvaNumber
                  ? true
                  : false
              }
              onClick={(): void => {
                nextStep();
              }}
              primary
            >
              Suivant
            </Button>
          ) : null}

          {!step || step === 2 ? (
            <Button
              primary
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              onClick={submitForm}
            >
              <Icon name="save" />
              Enregistrer
            </Button>
          ) : null}
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => {
          setAddressModalState({
            open: false,
          });
        }}
        open={addressModalState.open}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Édition de l&apos;adresse du pro</Modal.Header>
        <Modal.Content>
          <Form>
            <FormField name="street1" type="text" label="Adresse" required />
            <FormField
              name="postalCode"
              type="text"
              label="Code postal"
              required
            />
            <FormField name="city" type="text" label="Ville" required />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setAddressModalState({
                open: false,
              });
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            type="button"
            disabled={
              errors.postalCode || errors.city || errors.street1 ? true : false
            }
            onClick={() => {
              setAddressModalState({
                open: false,
              });
              const addressFormatted = formattedPostalAddress({
                city: values.city,
                postalCode: values.postalCode,
                street: values.street1,
              });
              setIsInvalidAddress(false);
              setFieldValue('formattedAddress', addressFormatted);
            }}
          >
            <Icon name="check" />
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProOrganizationFormView;
