import React, { FunctionComponent } from 'react';

import { CityFormUpdate } from './city-form';
import { Loader } from 'semantic-ui-react';
import { Navigate, useParams } from 'react-router';
import { useCityUpdatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';

const CityUpdatePage: FunctionComponent = () => {
  const params = useParams();
  const cityId = Number(params.cityId);
  const { data, loading } = useCityUpdatePageQuery({
    variables: {
      id: cityId,
    },
  });

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  if (!data?.cityByPk) return <Navigate to="/404" replace />; // Bilik zone not found

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>{data.cityByPk?.name}</ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <CityFormUpdate />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default CityUpdatePage;
