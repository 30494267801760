import {
  ProPerson,
  ProViewProPersonDeleteConfirmModalQuery,
} from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Button, Modal } from 'semantic-ui-react';

export type ProViewProPersonDeleteConfirmModalProps = {
  open: boolean;
  onClose?: () => void;
};

type ProViewProPersonDeleteConfirmModalViewProps = ProViewProPersonDeleteConfirmModalProps & {
  proPerson: Pick<ProPerson, 'id' | 'givenName' | 'familyName' | 'email'>;
  relatedProViewProPeople: ProViewProPersonDeleteConfirmModalQuery['relatedProViewProPeople'];
  relatedProViewAccounts: ProViewProPersonDeleteConfirmModalQuery['relatedProViewAccounts'];
  hasCurrentAccountRelated: boolean;
  confirmDelete: () => void;
};

const ProViewProPersonDeleteConfirmModalView: FunctionComponent<ProViewProPersonDeleteConfirmModalViewProps> = ({
  proPerson,
  relatedProViewProPeople,
  relatedProViewAccounts,
  hasCurrentAccountRelated,
  open,
  onClose,
  confirmDelete,
}) => (
  <Modal
    onClose={onClose}
    open={open}
    closeOnDimmerClick={false}
    closeOnEscape={false}
    closeIcon
  >
    <Modal.Header>
      Suppression du contact : {proPerson.givenName} {proPerson.familyName}
    </Modal.Header>
    <Modal.Content>
      {relatedProViewProPeople ? (
        <>
          <p>
            Ce <b>contact</b> est utilisé par <b>d&apos;autres fiches</b> :
          </p>
          <ul>
            {relatedProViewProPeople.map((proView) => (
              <li key={`related-pro-view-${proView.id}`}>
                <a
                  href={`/pros/update/${proView.id}/zone`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {proView.proPresentation.name} -{' '}
                  {proView.proPresentation.mainTrade?.name} -{' '}
                  {proView.bilikZone.mainCityCode}
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      {relatedProViewAccounts ? (
        <>
          <p>
            <b>L&apos;adresse email</b> ({proPerson.email}) du contact est
            utilisé comme accès à{' '}
            <b>l&apos; espace pro sur d&apos;autres fiches</b> :
          </p>
          <ul>
            {relatedProViewAccounts.map((proView) => (
              <li key={`related-account-${proView.id}`}>
                <a
                  href={`/pros/update/${proView.id}/zone`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {proView.proPresentation.name} -{' '}
                  {proView.proPresentation.mainTrade?.name} -{' '}
                  {proView.bilikZone.mainCityCode}
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      <p>
        <b>
          Pense donc à mettre à jour les fiches si nécéssaire !
          {hasCurrentAccountRelated ? (
            <>
              <br />
              Pense aussi à supprimer l&apos;accès à l&apos;espace pro :
              {proPerson.email} de cette fiche !
            </>
          ) : null}
        </b>
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button
        onClick={(): void => {
          if (onClose) {
            onClose();
          }
        }}
      >
        Annuler
      </Button>
      <Button negative onClick={confirmDelete}>
        Supprimer
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ProViewProPersonDeleteConfirmModalView;
