import React, { FunctionComponent, useMemo } from 'react';
import ProReviewsList from 'components/pro-reviews-list/pro-reviews-list';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';

const ProReviewsListPage: FunctionComponent = () => {
  const { currentBilikZone } = useCurrentBilikZone();

  const title: React.ReactElement = useMemo(
    () => (
      <>
        Avis
        <small>
          {currentBilikZone
            ? currentBilikZone.mainCityCode
            : 'Toutes les zones'}
        </small>
      </>
    ),
    [currentBilikZone],
  );

  return <ProReviewsList title={title} />;
};

export default ProReviewsListPage;
