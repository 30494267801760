import {
  OrderBy,
  useTradesTableQuery,
  useTradesTableQueryLazyQuery,
} from 'generated/graphql';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import React, { FunctionComponent, useCallback } from 'react';

import { TradesTableContainer } from './trades-table-container';

type Props = {
  filters?: {
    tradeId?: number;
    tradeGroupId?: number;
  };
  orderBy: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
};

export const TradesTableRepository: FunctionComponent<Props> = ({
  filters,
  orderBy,
  onSort,
}) => {
  const { currentBilikZone } = useCurrentBilikZone();

  const getVariablesByBilikZone = (bilikZoneId: number | undefined) => ({
    order_by: [{ name: OrderBy.Asc }],
    where: {
      id: {
        _eq: filters?.tradeId,
      },
      tradeGroups: {
        tradeGroupId: {
          _eq: filters?.tradeGroupId,
        },
      },
    },
    whereProView: {
      bilikZoneId: {
        _eq: bilikZoneId,
      },
      status: {
        _eq: 'published',
      },
    },
    whereProPresentation: {
      proViews: {
        bilikZoneId: {
          _eq: bilikZoneId,
        },
        status: {
          _eq: 'published',
        },
      },
    },
    whereTradeProView: {
      proView: {
        bilikZoneId: {
          _eq: bilikZoneId,
        },
        status: {
          _eq: 'published',
        },
      },
    },
  });

  const { loading, data } = useTradesTableQuery({
    variables: getVariablesByBilikZone(currentBilikZone?.id),
  });

  const [tradeTableBisQuery, { data: dataBis }] =
    useTradesTableQueryLazyQuery();

  const bilikZoneIdBis = localStorage.getItem('bilikZoneIdBis');

  const fetchTradeTableBisQuery = useCallback(async () => {
    if (bilikZoneIdBis) {
      await tradeTableBisQuery({
        variables: getVariablesByBilikZone(Number(bilikZoneIdBis)),
      });
    }
  }, [bilikZoneIdBis]);

  return (
    <TradesTableContainer
      loading={loading}
      orderBy={orderBy}
      onSort={onSort}
      trades={data?.trade || []}
      tradesBis={dataBis?.trade || []}
      refetch={fetchTradeTableBisQuery}
    />
  );
};
