import { Formik } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';
import { Loader } from 'semantic-ui-react';
import { ProContractFormFields } from './pro-contract-form-fields';
import ProContractFormView from './pro-contract-form-view';
import { proContractFormValidationSchema } from './pro-contract-form-validation-schema';
import { apiClient } from 'axios-client';
import { addMonths, setDate } from 'date-fns';

type ProContractFormCreateProps = {
  onProContractGenerated?: () => void;
  formData?: ProContractFormFields;
  proViewId: number;
  proOrganizationId: number;
  loading: boolean;
  open: boolean;
  onClose: () => void;
  proPersonSuggestions?: {
    givenName: string;
    familyName: string;
    telephone: string;
  }[];
  update?: boolean;
  isProPublished: boolean;
};

export type PreviewSubscriptionRow = {
  date: string;
  dateISO: string;
  text: string;
  amount: string;
};

const ProContractFormContainer: FunctionComponent<
  ProContractFormCreateProps
> = ({
  loading,
  formData,
  onProContractGenerated,
  proViewId,
  proOrganizationId,
  proPersonSuggestions,
  onClose,
  update,
  isProPublished,
  ...rest
}) => {
  const onSubmit = useCallback(
    async (values: ProContractFormFields): Promise<void> => {
      try {
        const response = await apiClient.post(
          '/form/pro-view/generate-contract',
          {
            ...values,
            specialConditions: values.specialConditions?.trim() || undefined,
            proViewId,
            update,
          },
        );

        if (response.data?.success === false) {
          ToastError('Erreur', response.data?.message);
        } else {
          ToastSuccess('Succès', 'Contrat généré');
        }

        if (onProContractGenerated) {
          onProContractGenerated();
        }

        if (onClose) {
          onClose();
        }
      } catch {
        ToastError('Erreur', 'Impossible de générer le contrat');
      }
    },
    [onProContractGenerated, proViewId],
  );

  const initialValues: ProContractFormFields = useMemo(() => {
    return {
      entranceFee: formData?.entranceFee || undefined,
      subscriptionAmount: formData?.subscriptionAmount || 315,
      discounts: formData?.discounts || [],
      startDate: isProPublished
        ? setDate(addMonths(new Date(), 1), 1)
        : formData?.startDate || undefined,
      isStartDateDelayed: isProPublished
        ? false
        : formData?.startDate
        ? true
        : false,
      managers: formData?.managers || [],
      signatory: formData?.signatory || {
        givenName: proPersonSuggestions?.[0]?.givenName || '',
        familyName: proPersonSuggestions?.[0]?.familyName || '',
        title: '',
      },
      interlocutor: formData?.interlocutor || {
        isActive: false,
        givenName: '',
        familyName: '',
        title: '',
      },
      specialConditions: formData?.specialConditions || '',
    };
  }, [formData, proPersonSuggestions]);

  if (loading) {
    return (
      <Loader size="big" active inline="centered" content="Chargement..." />
    );
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={proContractFormValidationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <ProContractFormView
        proViewId={proViewId}
        proOrganizationId={proOrganizationId}
        proPersonSuggestions={proPersonSuggestions}
        onClose={onClose}
        isProPublished={isProPublished}
        {...rest}
      />
    </Formik>
  );
};

export default ProContractFormContainer;
