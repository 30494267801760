import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { ToastError, ToastInfo, ToastSuccess } from 'utils';

type RefreshMaterializedViewsCardProps = Record<string, unknown>;

export const RefreshMaterializedViewsCard: FunctionComponent<
  RefreshMaterializedViewsCardProps
> = ({}) => {
  const onClickRefreshMaterializedViews =
    useCallback(async (): Promise<void> => {
      await ToastInfo(
        'Info',
        'Rafraichissement des vues materialisées en cours...',
      );
      await apiClient
        .post(`/form/admin-utilities/refresh-materialized-views`)
        .catch((error) => {
          if (error.status === 'Request failed with status code 504') {
            ToastInfo(
              'Info',
              'Request timeout, Rafraichissement des vues matérialisées toujours en cours...',
            );
          } else {
            ToastError(
              'Erreur',
              'Impossible de lancer le rafraichissement des vues matérialisées',
            );
            throw error;
          }
        });

      ToastSuccess('Succès', 'Vue matérialisées rafraichies');
    }, []);

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="save" />
        <Card.Header>Rafraichissement des vues matérialisées</Card.Header>
        <Card.Description>
          Rafraichissement des vues matérialisées pour mettre à jour les données
          du site NEXT
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button fluid primary onClick={onClickRefreshMaterializedViews}>
          Executer
        </Button>
      </Card.Content>
    </Card>
  );
};
