import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Navigate, useParams } from 'react-router';
import { useProUpdatePageQuery } from 'generated/graphql';
import { Loader } from 'semantic-ui-react';
import { ClaimModal } from './claim-modal/claim-modal';
import Content from 'components/content/content';
import ContentBody from 'components/content-body/content-body';
import { Me, WebSocketContext } from 'components/socket/socket';
import { ProFormUpdate } from './pro-form';

type RouteParams = {
  proViewId: string;
};

const ProUpdatePage: FunctionComponent = () => {
  const params = useParams<RouteParams>();

  const proViewId = Number(params.proViewId);

  const {
    askClaim,
    askedAClaim,
    forceClaim,
    me,
    members,
    refuseClaim,
    isWebSocketReady,
  } = useContext(WebSocketContext);
  const [claimBy, setClaimBy] = useState<Me>();

  useEffect(() => {
    // Ready to operate and location is updated with pro/update page change
    if (
      members &&
      isWebSocketReady &&
      me?.pathname.indexOf('/pros/update/') !== -1
    ) {
      // Find if someone already claimed the page
      const claimBy = members.find(
        (member) =>
          member.claim &&
          me?.pathname.split('/')[3] === member.pathname.split('/')[3],
      );

      setClaimBy(claimBy);

      // if we're the first and we did not asked before, ask for claim
      if (claimBy === undefined && !askedAClaim) {
        askClaim();
      }
    }
  }, [members, askedAClaim, isWebSocketReady]);

  const { data, loading } = useProUpdatePageQuery({
    variables: {
      id: proViewId,
    },
  });

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  if (!data?.proViewByPk) return <Navigate to="/404" replace />;

  return (
    <Content>
      <ContentBody>
        <ProFormUpdate
          proPresentationId={data.proViewByPk.proPresentationId}
          proViewId={proViewId}
        />
        {/* Me infos are ready and there's someone that claimed the page before */}
        {me && !me.claim && claimBy ? (
          <ClaimModal
            onAccept={forceClaim}
            onRefuse={refuseClaim}
            member={claimBy}
          />
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default ProUpdatePage;
