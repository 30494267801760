import {
  Button,
  Checkbox,
  Container,
  Grid,
  Icon,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { ProViewBoolExp } from 'generated/graphql';
import { ProsTable } from './pros-table';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import { useProViewsCount } from 'hooks/use-pro-views-count/use-pro-views-count';
import { ProPresentationSearchFilter } from 'components/pro-presentation-search-filter';
import { ProFormCreateModal } from 'components/pro-form-create-modal';
import { useNavigate } from 'react-router';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { TradeSearchFilter } from 'components/trade-search-filter';
import styled from 'styled-components';

const CheckboxFilter = styled(Checkbox)`
  margin-left: 12px !important;
  margin-right: 0px;
  height: 32px;

  label {
    margin-top: 2px;
  }
`;

const ProsListPage: FunctionComponent = () => {
  const { currentBilikZone } = useCurrentBilikZone();

  const itemPerPage = 20;

  const history = useNavigate();

  const [proFormCreateModalOpen, setProFormCreateModalOpen] = useState(false);
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [status, setStatus] = useQueryParam('status', StringParam);
  const [tradeId, setTradeId] = useQueryParam('tradeId', NumberParam);
  const [proPresentationId, setProPresentationId] = useQueryParam(
    'proPresentationId',
    NumberParam,
  );
  const [onHoliday, setOnHoliday] = useQueryParam(
    'onHoliday',
    withDefault(BooleanParam, null),
  );

  const [search, setSearch] = useQueryParam('search', StringParam);

  const [filterOnMainTrades, setFilterOnMainTrades] = useState(true);

  const [orderBy, setOrderBy] = useQueryParam(
    'orderBy',
    withDefault(ObjectParam, { column: 'id', direction: 'descending' }),
  );

  const whereAll: ProViewBoolExp = useMemo(() => {
    const whereTrade: ProViewBoolExp | undefined = tradeId
      ? filterOnMainTrades
        ? {
            proPresentation: {
              _or: [
                {
                  mainTradeId: {
                    _eq: tradeId !== null ? tradeId : undefined,
                  },
                },
                {
                  secondaryTradeId: {
                    _eq: tradeId !== null ? tradeId : undefined,
                  },
                },
              ],
            },
          }
        : {
            proPresentation: {
              proViews: {
                trades: {
                  tradeId: {
                    _eq: tradeId,
                  },
                },
              },
            },
          }
      : undefined;

    return {
      ...whereTrade,
      proPresentationId: {
        _eq: proPresentationId !== null ? proPresentationId : undefined,
      },

      bilikZoneId: {
        _eq: currentBilikZone?.id,
      },
      holidayEndDate: onHoliday
        ? {
            _isNull: !onHoliday,
          }
        : {},
    };
  }, [
    proPresentationId,
    tradeId,
    search,
    currentBilikZone?.id,
    filterOnMainTrades,
    onHoliday,
  ]);

  const whereTable: ProViewBoolExp = useMemo(() => {
    return {
      ...whereAll,
      status: { _eq: status !== null ? status : undefined },
    };
  }, [whereAll, status]);

  const whereIsStatusPublished: ProViewBoolExp = useMemo(() => {
    return {
      ...whereAll,
      status: { _eq: 'published' },
    };
  }, [whereAll]);

  const whereIsStatusDraft: ProViewBoolExp = useMemo(() => {
    return {
      ...whereAll,
      status: { _eq: 'draft' },
    };
  }, [whereAll]);

  const whereIsStatusArchived: ProViewBoolExp = useMemo(() => {
    return {
      ...whereAll,
      status: { _eq: 'archived' },
    };
  }, [whereAll]);

  const { count: countAll } = useProViewsCount(whereAll, search ?? undefined);

  const { count: countTable } = useProViewsCount(
    whereTable,
    search ?? undefined,
  );

  const totalPages = useMemo(
    () => Math.ceil((countTable || 0) / itemPerPage),
    [countTable],
  );

  const { count: countIsStatusPublished, loading: loadingIsStatusPublished } =
    useProViewsCount(whereIsStatusPublished, search ?? undefined);

  const { count: countIsStatusDraft, loading: loadingIsStatusDraft } =
    useProViewsCount(whereIsStatusDraft, search ?? undefined);

  const { count: countIsStatusArchived, loading: loadingIsStatusArchived } =
    useProViewsCount(whereIsStatusArchived, search ?? undefined);

  if (currentBilikZone === undefined) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>
              Fiches pros
              <small>
                {currentBilikZone
                  ? currentBilikZone.mainCityCode
                  : 'Toutes les zones'}
              </small>
            </ContentHeaderTitle>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              positive
              type="button"
              onClick={(): void => {
                setProFormCreateModalOpen(true);
              }}
            >
              <Icon name="plus" />
              Nouveau
            </Button>
            <ProFormCreateModal
              open={proFormCreateModalOpen}
              onClose={(): void => {
                setProFormCreateModalOpen(false);
              }}
              onProCreated={(proViewId): void => {
                history(`/pros/update/${proViewId}`);
              }}
            />
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <Button.Group>
          <Button
            basic={status !== 'published'}
            color="green"
            loading={loadingIsStatusPublished}
            onClick={(): void => {
              setStatus('published');
              setPage(1);
            }}
          >
            Publiés ({countIsStatusPublished ?? 0})
          </Button>
          <Button
            basic={status !== 'draft'}
            color="red"
            loading={loadingIsStatusDraft}
            onClick={(): void => {
              setStatus('draft');
              setPage(1);
            }}
          >
            Brouillon ({countIsStatusDraft ?? 0})
          </Button>
          <Button
            basic={status !== 'archived'}
            color="grey"
            loading={loadingIsStatusArchived}
            onClick={(): void => {
              setStatus('archived');
              setPage(1);
            }}
          >
            Archivés ({countIsStatusArchived ?? 0})
          </Button>
          <Button
            basic={status !== undefined}
            color="grey"
            onClick={(): void => {
              setStatus(undefined);
              setPage(1);
            }}
          >
            Tous ({countAll ?? 0})
          </Button>
        </Button.Group>

        <ProPresentationSearchFilter
          proPresentationId={
            proPresentationId !== null ? proPresentationId : undefined
          }
          setProPresentationId={setProPresentationId}
          bilikZoneId={currentBilikZone?.id}
          setPage={setPage}
          search={true}
          initialValue={search ?? undefined}
          setSearch={setSearch}
        />
        <TradeSearchFilter
          tradeId={tradeId || undefined}
          onDelete={(): void => {
            setTradeId(undefined);
            setPage(1);
          }}
          onResultSelect={(event, { result }): void => {
            setTradeId(result.id);
            setPage(1);
          }}
        />
        <CheckboxFilter
          checked={onHoliday}
          className="button basic grey"
          label="En congé"
          onChange={(): void => {
            setOnHoliday(!onHoliday);
          }}
        />
        {tradeId && (
          <CheckboxFilter
            label="Catégories principales uniquement"
            className="button basic grey"
            checked={filterOnMainTrades}
            onChange={(): void => {
              setFilterOnMainTrades(!filterOnMainTrades);
            }}
          />
        )}
        <ProsTable
          search={search ?? ''}
          where={whereTable}
          page={page ?? undefined}
          setPage={setPage}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          itemPerPage={itemPerPage}
          selectedTradeId={tradeId}
        />
        {totalPages > 1 ? (
          <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
            <Pagination
              activePage={page ?? 1}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
              onPageChange={(_, data): void => {
                setPage(Number(data.activePage));
              }}
            />
          </Container>
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default ProsListPage;
