import React, { Fragment, FunctionComponent } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Form, Icon, Button, Segment, Header } from 'semantic-ui-react';
import { fr } from 'date-fns/locale';
import FormField from 'components/form-field/form-field';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';

import ExpiredProHolidaysTable from 'components/expired-pro-holidays-table/expired-pro-holidays-table';

const emptyProHoliday = {
  startDate: null,
  endDate: null,
};

type ProHolidaysRouteProps = {
  proViewId: number;
};

const ProHolidaysRoute: FunctionComponent<ProHolidaysRouteProps> = ({
  proViewId,
}) => {
  const { values } = useFormikContext<ProFormUpdateFields>();

  return (
    <Fragment>
      <Header>Congés en cours / à venir</Header>
      <Segment>
        <FieldArray
          name="proHolidays"
          render={(arrayHelpers): React.ReactElement => (
            <Fragment>
              {values.proHolidays && values.proHolidays.length > 0
                ? values.proHolidays.map((_, proHolidayIndex) => (
                    <Fragment key={proHolidayIndex}>
                      <Form.Group widths="equal">
                        <FormField
                          label="Date de début"
                          values={values}
                          name={`proHolidays.${proHolidayIndex}.startDate`}
                          type="date"
                          dateFormat="dd/MM/yyyy"
                          locale={fr}
                        />
                        <FormField
                          label="Date de fin"
                          values={values}
                          name={`proHolidays.${proHolidayIndex}.endDate`}
                          type="date"
                          dateFormat="dd/MM/yyyy"
                          locale={fr}
                        />

                        <Button
                          style={{
                            marginTop: '24px',
                            minWidth: '45px',
                            marginRight: '10px',
                          }}
                          icon
                          negative
                          type="button"
                          basic
                          onClick={(): void => {
                            arrayHelpers.remove(proHolidayIndex);
                          }}
                        >
                          <Icon name="trash alternate outline" />
                        </Button>
                      </Form.Group>
                    </Fragment>
                  ))
                : null}
              <Segment basic textAlign={'right'}>
                <Button
                  style={{ minWidth: '45px' }}
                  type="button"
                  positive
                  onClick={(): void => {
                    arrayHelpers.push(emptyProHoliday);
                  }}
                >
                  <Icon name="plus" />
                  Nouveau congé
                </Button>
              </Segment>
            </Fragment>
          )}
        />
      </Segment>
      {/* space  */}
      <Header>Congés expirés</Header>
      <ExpiredProHolidaysTable proViewId={proViewId} />
    </Fragment>
  );
};

export default ProHolidaysRoute;
