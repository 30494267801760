import {
  CitySetInput,
  useCityFormUpdateMutation,
  useCityFormUpdateQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { useParams } from 'react-router';

import CityFormUpdateContainer from './city-form-update-container';

const CityFormUpdateRepository: FunctionComponent = () => {
  const params = useParams();
  const cityId = Number(params.cityId);

  const { loading, data } = useCityFormUpdateQuery({
    variables: {
      id: cityId,
    },
  });

  const [updateByPkCity] = useCityFormUpdateMutation();

  const updateCity = useCallback(
    async (city: CitySetInput) => {
      await updateByPkCity({
        variables: {
          cityId: cityId,
          city: {
            name: city.name,
            slug: city.slug,
            bilikZoneId: city.bilikZoneId,
            area: city.area,
            geoNameId: city.geoNameId,
            population: city.population,
          },
        },
      });
    },
    [updateByPkCity],
  );

  return (
    <CityFormUpdateContainer
      updateCity={updateCity}
      formData={data?.cityByPk}
      cityId={cityId}
      loading={loading}
    />
  );
};

export default CityFormUpdateRepository;
