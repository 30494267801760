import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { AccountFormFields } from './account-form-field.type';
import { AccountFormModalProps } from './account-form-modal';

const AccountFormView: FunctionComponent<AccountFormModalProps> = ({
  open,
  onClose,
}) => {
  const { isSubmitting, handleSubmit, handleReset, setFieldValue, submitForm } =
    useFormikContext<AccountFormFields>();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // lowercase email for validation
    if (name === 'email') {
      setFieldValue(name, value.toLowerCase());
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
    >
      <Modal.Header>Édition de l&apos;adresse email</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormField
            type="email"
            label="email"
            name="email"
            onChange={onChange}
            required
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={(): void => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Annuler
        </Button>
        <Button
          primary
          disabled={isSubmitting}
          loading={isSubmitting}
          type="button"
          onClick={submitForm}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AccountFormView;
