import { FunctionComponent } from 'react';
import { Label, Popup } from 'semantic-ui-react';
import React from 'react';

type CallCheckerIndicatorViewProps = {
  hasPiCalledPro: boolean;
  hasPiCalledOtherPros: boolean;
  loading: boolean;
  openTimeline: any;
};

export const CallCheckerIndicatorView: FunctionComponent<
  CallCheckerIndicatorViewProps
> = ({ hasPiCalledOtherPros, hasPiCalledPro, loading, openTimeline }) => {
  if (loading) {
    return (
      <Label size="mini" color="grey">
        Chargement...
      </Label>
    );
  }

  if (hasPiCalledPro) {
    return (
      <Label size="mini" color="green">
        Appelé le Pro
      </Label>
    );
  }

  if (hasPiCalledOtherPros) {
    return (
      <Popup
        position="top center"
        content="Voir la timeline des appels du particulier"
        trigger={
          <Label
            style={{ cursor: 'pointer' }}
            onClick={openTimeline}
            size="mini"
            color="red"
          >
            Appelé mais pas ce pro
          </Label>
        }
      />
    );
  }

  // Unknown
  return (
    <Label size="mini" color="grey">
      Inconnu
    </Label>
  );
};
