import React, { FunctionComponent, useCallback } from 'react';
import { Label } from 'semantic-ui-react'; //
import { SolicitationCheckerIndicatorView } from './solicitation-checker-indicator-view';

type SolicitationCheckerIndicatorContainerProps = {
  email: string;
  loading: boolean;
  hasMadeSolicitationBefore: boolean;
  hasSolicitedReviewedPro: boolean;
  hasActiveSolicitationToOtherPros: boolean;
};

export const SolicitationCheckerIndicatorContainer: FunctionComponent<SolicitationCheckerIndicatorContainerProps> = ({
  email,
  loading,
  hasMadeSolicitationBefore,
  hasSolicitedReviewedPro,
  hasActiveSolicitationToOtherPros,
}) => {
  const openOtherSolicitationPiMade = useCallback(() => {
    window.open(
      `/solicitations?privateIndividualEmail=${encodeURIComponent(
        email,
      )}&isAllZones=1`,
    );
  }, [email]);

  if (loading) {
    return (
      <Label size="mini" color="grey">
        Chargement...
      </Label>
    );
  }

  return (
    <SolicitationCheckerIndicatorView
      hasActiveSolicitationToOtherPros={hasActiveSolicitationToOtherPros}
      hasMadeSolicitationBefore={hasMadeSolicitationBefore}
      hasSolicitedReviewedPro={hasSolicitedReviewedPro}
      openOtherSolicitationPiMade={openOtherSolicitationPiMade}
    />
  );
};
