import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  FormCheckbox,
  FormField as FormFieldUi,
  FormInput,
  TextArea,
} from 'semantic-ui-react';
import {
  FastField,
  Field,
  FormikHandlers,
  FormikValues,
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import { map, pathOr } from 'remeda';
import DatePicker from 'react-datepicker';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import FieldError from 'components/field-error/field-error';
import Label from 'components/label/label';
import HelpText from 'components/help-text/help-text';
import { EmailSuggestion } from 'components/email-suggestion/email-suggestion';
import Autocomplete, {
  ReactGoogleAutocompleteInputProps,
} from 'react-google-autocomplete';
import env from 'env';
import { formatE164Telephone } from 'utils';

type FormFieldOptions = {
  key: number | string;
  value: string | number | boolean;
  label: string;
}[];

type Option<T> = {
  key: number | string | boolean;
  value: T;
  label: string | React.ReactElement;
};

type CheckboxesOption<T> = Option<T> & {
  disabled?: boolean;
};

type FormFieldHelpText = string | React.ReactNode;

type InputRadioProps = {
  type: 'radio';
  options: FormFieldOptions;
  helpText?: FormFieldHelpText;
  label?: string;
  required?: boolean;
  onChange?: (value: string | number | boolean) => void;
};

type InputCheckboxProps = {
  helpText?: FormFieldHelpText;
  label?: string;
  required?: boolean;
  onChange?: FormikHandlers['handleChange'];
};

type InputMultipleCheckboxProps<T> = {
  type: 'checkboxes';
  options: CheckboxesOption<Unpacked<T>>[];
  onChange?: (
    event: SyntheticEvent,
    data: {
      checked: T;
      options: CheckboxesOption<Unpacked<T>>[];
    },
  ) => void;
  disabled?: boolean;
} & InputCheckboxProps;

type InputSimpleCheckboxProps = {
  type: 'checkbox';
} & InputCheckboxProps;

type InputFieldProps = {
  type: 'text' | 'number' | 'password' | 'email';
  noFastField?: boolean;
  helpText?: FormFieldHelpText;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: FormikHandlers['handleChange'];
  icon?: SemanticICONS;
  disabled?: boolean;
  min?: number;
  max?: number;
  onBlur?: FormikHandlers['handleBlur']; // @TODO
  step?: number;
};

type InputTextareaProps = {
  type: 'textarea';
  required?: boolean;
  helpText?: FormFieldHelpText;
  label?: string;
  placeholder?: string;
  rows?: number;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
};

type InputDateProps = {
  type: 'date';
  values: FormikValues;
  helpText?: FormFieldHelpText;
  label?: string;
  required?: boolean;
  noFastField?: boolean;
  dateFormat?: string;
  locale?: Locale;
  showMonthYearPicker?: boolean;
  clearable?: boolean;
  startDate?: Date;
  endDate?: Date;
  disabled?: boolean;
  filterDate?: (date: Date) => boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
};

type InputPlaceProps = {
  type: 'place';
  helpText?: FormFieldHelpText;
  label?: string;
  required?: boolean;
  placeholder?: string;
  clearable?: boolean;
  onChange?: FormikHandlers['handleChange'];
} & ReactGoogleAutocompleteInputProps;

type InputTelephoneProps = {
  type: 'telephone';
  onBlur?: FormikHandlers['handleBlur'];
  placeholder?: string;
  label?: string;
  helpText?: FormFieldHelpText;
  required?: boolean;
};

type InputSelectProps = {
  type: 'select';
  label?: string;
  placeholder?: string;
  helpText?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  multiple?: boolean;
  selection?: boolean;
  selectOnBlur?: boolean;
  renderLabel?: (option: DropdownItemProps) => React.ReactNode;
  search?:
    | boolean
    | ((options: DropdownItemProps[], value: string) => DropdownItemProps[]);
  clearable?: boolean;
  options: DropdownItemProps[];
  onChange?: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
  ) => void;
};

export type FormFieldSelectProps = FormFieldName & InputSelectProps;

type FormFieldName = { name: string };
type FormFieldType = { type: string };
type Unpacked<T> = T extends (infer U)[] ? U : T;

type FormFieldProps<T> = FormFieldName &
  FormFieldType &
  (
    | InputRadioProps
    | InputMultipleCheckboxProps<T>
    | InputSimpleCheckboxProps
    | InputTextareaProps
    | InputTelephoneProps
    | InputSelectProps
    | InputDateProps
    | InputPlaceProps
    | InputFieldProps
  );

function FormField<T>(props: FormFieldProps<T>): React.ReactElement {
  switch (props.type) {
    case 'text':
    case 'number':
    case 'password':
      return <InputField {...props} />;
    case 'email':
      return <EmailField {...props} />;
    case 'textarea':
      return <InputTextarea {...props} />;
    case 'checkbox':
      return <InputSimpleCheckbox {...props} />;
    case 'checkboxes':
      return <InputMultipleCheckbox {...props} />;
    case 'date':
      return <InputDate {...props} />;
    case 'place':
      return <InputPlace {...props} />;
    case 'telephone':
      return <InputTelephone {...props} />;
    case 'select':
      return <InputSelect {...props} />;
    case 'radio':
      return <InputRadio {...props} />;
    default:
      throw new Error('type got invalid value for FormField');
  }
}

const getError = (name, errors, touched): string | false => {
  // we need to convert name.split as unknow first in order to convert it to a readonly [string]
  // because split return an array of an unknow size
  const names: readonly [string] = name.split('.') as unknown as readonly [
    string,
  ];

  return pathOr(errors, names, false) && pathOr(touched, names, false)
    ? pathOr(errors, names, false)
    : false;
};

const removeProperties = (object, ...keys): Record<string, unknown> =>
  Object.entries(object).reduce(
    (prev, [key, value]) => ({
      ...prev,
      ...(!keys.includes(key) && { [key]: value }),
    }),
    {},
  );

const InputField: FunctionComponent<FormFieldName & InputFieldProps> = ({
  name,
  required,
  noFastField,
  helpText,
  label,
  ...rest
}) => {
  const { errors, touched } = useFormikContext();
  const error = getError(name, errors, touched);

  return (
    <FormFieldUi required={required}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      {noFastField ? (
        <Field name={name}>
          {({ field }): React.ReactNode => (
            <FormInput {...field} {...rest} id={name} error={error} />
          )}
        </Field>
      ) : (
        <FastField name={name}>
          {({ field }): React.ReactNode => (
            <FormInput {...field} {...rest} id={name} error={error} />
          )}
        </FastField>
      )}
    </FormFieldUi>
  );
};

// TODO: refacto with InputField but we might want to make it clean
const EmailField: FunctionComponent<FormFieldName & InputFieldProps> = ({
  name,
  required,
  noFastField,
  helpText,
  label,
  ...rest
}) => {
  const { errors, touched, values, setFieldValue, setFieldTouched } =
    useFormikContext();
  const error = getError(name, errors, touched);

  const onAcceptEmailSuggestion = useCallback(
    (suggestion: string) => {
      setFieldTouched(name, true);
      setFieldValue(name, suggestion);
    },
    [setFieldValue, setFieldTouched],
  );

  const emailValue = useMemo(() => {
    const names = name.split('.');
    return pathOr<any, string>(values, names as any, '');
  }, [name, values]);

  return (
    <FormFieldUi required={required}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      {noFastField ? (
        <Field name={name}>
          {({ field }): React.ReactNode => (
            <FormInput {...field} {...rest} id={name} error={error} />
          )}
        </Field>
      ) : (
        <FastField name={name}>
          {({ field }): React.ReactNode => (
            <FormInput {...field} {...rest} id={name} error={error} />
          )}
        </FastField>
      )}
      <EmailSuggestion
        email={emailValue}
        onAcceptEmailSuggestion={onAcceptEmailSuggestion}
      />
    </FormFieldUi>
  );
};

const InputTextarea: FunctionComponent<FormFieldName & InputTextareaProps> = ({
  name,
  helpText,
  label,
  required,
  resize,
  ...rest
}) => {
  const { errors, touched } = useFormikContext();
  const error = getError(name, errors, touched);

  return (
    <FormFieldUi required={required} error={!!error}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      <FastField name={name}>
        {({ field }): React.ReactNode => (
          <React.Fragment>
            <TextArea
              {...field}
              {...rest}
              id={name}
              style={{ resize: resize }}
            />
            {error ? <FieldError>{error}</FieldError> : null}
          </React.Fragment>
        )}
      </FastField>
    </FormFieldUi>
  );
};

function InputMultipleCheckbox<T>({
  name,
  options,
  onChange,
  helpText,
  label,
  required,
  ...rest
}: FormFieldName & InputMultipleCheckboxProps<T>): React.ReactElement {
  const { setFieldValue, values } = useFormikContext();

  const props = removeProperties(rest, 'type');
  const checked = getIn(values, name);

  return (
    <FormFieldUi required={required} name={name}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      {options.map((option) => (
        <FastField
          type="checkbox"
          component={FormCheckbox}
          className="checkbox"
          key={option.key ? option.key : option.value}
          name={name}
          label={option.label}
          disabled={option.disabled}
          checked={checked ? checked.includes(option.value) : false}
          onChange={(event): void => {
            const safeChecked = checked ? checked : [option.value];

            const checkedResult = safeChecked.includes(option.value)
              ? safeChecked.filter((element) => element !== option.value)
              : [...safeChecked, option.value];

            if (onChange) {
              onChange(event, {
                checked: checkedResult,
                options: options,
              });
            } else {
              setFieldValue(name, checkedResult);
            }
          }}
          {...props}
        />
      ))}
    </FormFieldUi>
  );
}

const InputSimpleCheckbox: FunctionComponent<
  FormFieldName & InputSimpleCheckboxProps
> = ({ name, helpText, label, required, onChange, ...rest }) => {
  const { setFieldValue, values } = useFormikContext();
  const checked = getIn(values, name);

  return (
    <FormFieldUi required={required} name={name}>
      <FastField
        component={FormCheckbox}
        onChange={(e): void => {
          setFieldValue(name, !checked);
          onChange?.(e);
        }}
        checked={checked}
        name={name}
        id={name}
        label={
          label ? (
            <FormInputLabel name={name} label={label} helpText={helpText} />
          ) : undefined
        }
        {...rest}
      />
    </FormFieldUi>
  );
};

const dateToUTC = (date): Date => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
  );
};

const InputDate: FunctionComponent<FormFieldName & InputDateProps> = ({
  name,
  values,
  helpText,
  label,
  required,
  noFastField,
  placeholder,
  ...rest
}) => {
  const { setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext();

  const error = getError(name, errors, touched);

  // we need to convert name.split as unknow first in order to convert it to a readonly [string]
  // because split return an array of an unknow size
  const names: readonly [string] = name.split('.') as unknown as readonly [
    string,
  ];

  return (
    <FormFieldUi required={required} error={!!error}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      {noFastField ? (
        <Field
          component={DatePicker}
          selected={
            pathOr(values, names, null)
              ? dateToUTC(new Date(pathOr(values, names, 0)))
              : null
          }
          onChange={(date): void => {
            if (date) {
              const UTCDate = dateToUTC(date);
              setFieldValue(name, UTCDate);
            } else {
              setFieldValue(name, undefined);
            }
            setFieldTouched(name, true);
          }}
          placeholderText={placeholder}
          {...rest}
        />
      ) : (
        <FastField
          component={DatePicker}
          selected={
            pathOr(values, names, null)
              ? dateToUTC(new Date(pathOr(values, names, 0)))
              : null
          }
          onChange={(date): void => {
            setFieldTouched(name, true);
            if (date) {
              const UTCDate = dateToUTC(date);
              setFieldValue(name, UTCDate);
            } else {
              setFieldValue(name, undefined);
            }
          }}
          placeholderText={placeholder}
          {...rest}
        />
      )}

      {error ? <FieldError>{error}</FieldError> : null}
    </FormFieldUi>
  );
};

const InputPlace: FunctionComponent<FormFieldName & InputPlaceProps> = ({
  name,
  helpText,
  label,
  required,
  onPlaceSelected,
  ...rest
}) => {
  const { setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext();

  const error = getError(name, errors, touched);

  const props = removeProperties(rest, 'type');

  return (
    <FormFieldUi required={required} error={!!error}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      <FastField name={name}>
        {({ field }): React.ReactNode => (
          <React.Fragment>
            <Autocomplete
              inputAutocompleteValue="off"
              apiKey={env.GOOGLE_API_KEY}
              id={name}
              onPlaceSelected={
                onPlaceSelected
                  ? onPlaceSelected
                  : (place): void => {
                      setFieldValue(
                        name,
                        place.address_components?.find(
                          (component) => component.types[0] === 'locality',
                        )?.long_name,
                      );
                      setFieldTouched(name, true);
                    }
              }
              {...field}
              {...props}
            />
          </React.Fragment>
        )}
      </FastField>
      {error ? <FieldError>{error}</FieldError> : null}
    </FormFieldUi>
  );
};

const InputTelephone: FunctionComponent<
  FormFieldName & InputTelephoneProps
> = ({ name, onBlur, ...rest }) => {
  const props = removeProperties(rest, 'type');

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <InputField
      type="text"
      name={name}
      onBlur={(event): void => {
        setFieldTouched(name, true);
        setFieldValue(
          name,
          formatE164Telephone(event.target.value) ?? event.target.value,
        );
        onBlur?.(event);
      }}
      {...props}
    />
  );
};

function InputSelect<T>({
  name,
  label,
  helpText,
  required,
  onChange,
  disabled,
  loading,
  multiple,
  search,
  renderLabel,
  ...rest
}: FormFieldName & InputSelectProps): React.ReactElement {
  const { touched, setFieldValue, setFieldTouched, values, errors } =
    useFormikContext();

  const value = useMemo(() => {
    if (!loading) {
      return getIn(values, name);
    }
    return multiple ? [] : null;
  }, [loading, multiple, name, values]);

  const props = {
    id: name,
    name,
    loading,
    value,
    multiple,
    renderLabel,
    error: !!getError(name, errors, touched),
    onChange: onChange
      ? onChange
      : (event: SyntheticEvent, data): void => {
          setFieldTouched(name, true);
          setFieldValue(
            name,
            data.value || data.value === 0 ? data.value : null,
            true,
          );
        },
    search: search
      ? (options, searchValue): void => {
          const normalizedSearchValue = searchValue
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

          const matchedOptions = options.filter((option) => {
            const normalizedOptionName = String(
              option.searchableText ?? option.text,
            )
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();

            return normalizedOptionName.includes(normalizedSearchValue);
          });
          return matchedOptions;
        }
      : false,
    ...rest,
  };

  return (
    <FormFieldUi required={required}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      <FastField
        disabled={disabled}
        {...props}
        component={Dropdown}
        shouldUpdate={(props, nextProps): boolean => {
          // Base shouldUpdate used by formik
          if (
            props.name !== nextProps.name ||
            getIn(props.formik.values, nextProps.name) !==
              getIn(nextProps.formik.values, nextProps.name) ||
            getIn(props.formik.errors, nextProps.name) !==
              getIn(nextProps.formik.errors, nextProps.name) ||
            getIn(props.formik.touched, nextProps.name) !==
              getIn(nextProps.formik.touched, nextProps.name) ||
            Object.keys(nextProps).length !== Object.keys(props).length ||
            props.formik.isSubmitting !== nextProps.formik.isSubmitting
          ) {
            return true;
          }

          // Custom rule
          if (props.options === nextProps.options) {
            return false;
          }

          return true;
        }}
      />
    </FormFieldUi>
  );
}

const InputRadio: FunctionComponent<FormFieldName & InputRadioProps> = ({
  name,
  helpText,
  label,
  required,
  options,
  onChange,
  ...rest
}) => {
  const { setFieldValue, values } = useFormikContext();
  const checked = getIn(values, name);

  return (
    <FormFieldUi required={required}>
      <FormInputLabel name={name} label={label} helpText={helpText} />
      <Form.Group>
        {map(options, (option) => (
          <FastField
            key={option.key ? option.key : option.value}
            component={Form.Radio}
            name={name}
            label={option.label}
            checked={checked === option.value}
            onChange={(): void => {
              setFieldValue(name, option.value);

              if (onChange) {
                onChange(option.value);
              }
            }}
            {...rest}
          />
        ))}
      </Form.Group>
    </FormFieldUi>
  );
};

type FormInputLabelProps = {
  name: string;
  label?: string;
  helpText?: FormFieldHelpText;
  children?: ReactNode;
};

export const FormInputLabel: FunctionComponent<FormInputLabelProps> = ({
  label,
  name,
  helpText,
  children,
}) => (
  <React.Fragment>
    {label ? <Label htmlFor={name}>{label}</Label> : null}
    {helpText ? <HelpText>{helpText}</HelpText> : null}
    {children ? children : null}
  </React.Fragment>
);

export default FormField;
