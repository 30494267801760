import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import { CityFormCreate } from 'pages/cities/city-form';
import React, { FunctionComponent } from 'react';
import {} from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, GridColumn, Icon } from 'semantic-ui-react';
import { BilikZoneFormCreate } from './bilik-zone-form';

const BilikZoneCreatePage: FunctionComponent = () => (
  <Content>
    <ContentHeader>
      <ContentHeaderTitle>Nouvelle zone</ContentHeaderTitle>
    </ContentHeader>
    <ContentBody>
      <Grid columns="2">
        <GridColumn>
          <ContentContainer>
            <BilikZoneFormCreate />
          </ContentContainer>
        </GridColumn>
        <GridColumn>
          <Container fluid>
            <CityFormCreate />
          </Container>
        </GridColumn>
      </Grid>
    </ContentBody>
  </Content>
);

export default BilikZoneCreatePage;
