import {
  ProPresentationBoolExp,
  useProPresentationSearchFilterProPresentationQuery,
  useProPresentationSearchFilterQueryLazyQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import ProPresentationSearchFilterContainer from './pro-presentation-search-filter-container';

type Props = {
  setProPresentationId: (setProPresentationId: number | undefined) => void;
  proPresentationId?: number | undefined;
  isAutorespondActive?: boolean | undefined;
  bilikZoneId: number | undefined;
  search?: boolean;
  setPage: (id: number) => void;
  setSearch?: (value?: string) => void;
  initialValue?: string;
};

const ProPresentationSearchFilterRepository: FunctionComponent<Props> = ({
  bilikZoneId,
  setProPresentationId,
  proPresentationId,
  search = false,
  setPage,
  initialValue,
  setSearch = (): void => undefined,
}) => {
  const filters: ProPresentationBoolExp[] = [];

  if (bilikZoneId) {
    filters.push({
      proViews: {
        bilikZoneId: { _eq: bilikZoneId },
      },
    });
  }

  const [criteria, setCriteria] = useState(initialValue);
  const [debouncedCriteria] = useDebounce(criteria, 600);

  const [
    proPresentationSearchFilterQuery,
    { data: proPresentationsData, loading: proPresentationsLoading },
  ] = useProPresentationSearchFilterQueryLazyQuery();

  useEffect(() => {
    if (debouncedCriteria) {
      proPresentationSearchFilterQuery({
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        },
      });
    }
  }, [debouncedCriteria]);

  const {
    data: defaultProPresentationData,
    loading: defaultProPresentationLoading,
  } = useProPresentationSearchFilterProPresentationQuery({
    variables: {
      id: proPresentationId || 0, // TODO works, but is hacky
    },
    skip: !proPresentationId,
  });

  return (
    <ProPresentationSearchFilterContainer
      search={search}
      setSearch={setSearch}
      setPage={setPage}
      defaultValue={defaultProPresentationData?.proPresentationByPk?.name}
      proPresentationId={proPresentationId}
      setCriteria={setCriteria}
      criteria={criteria}
      setProPresentationId={setProPresentationId}
      loading={proPresentationsLoading || defaultProPresentationLoading}
      proPresentations={proPresentationsData?.searchProPresentation || []}
    />
  );
};

export default ProPresentationSearchFilterRepository;
