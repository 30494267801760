import {
  BilikZoneSetInput,
  useBilikZoneFormUpdateMutation,
  useBilikZoneFormUpdateQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { useParams } from 'react-router';

import BilikZoneFormUpdateContainer from './bilik-zone-form-update-container';
export type MemberOrManager = {
  bilikPersonId: number;
  bilikZoneId: number;
};

const BilikZoneFormUpdateRepository: FunctionComponent = () => {
  const params = useParams<{ bilikZoneId?: string }>();
  const bilikZoneId = Number(params.bilikZoneId);

  const { loading, data } = useBilikZoneFormUpdateQuery({
    variables: {
      id: bilikZoneId,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateByPkBilikZone] = useBilikZoneFormUpdateMutation();

  const updateBilikZone = useCallback(
    async (
      bilikZone: BilikZoneSetInput,
      removeManagerIds: number[],
      addManagers: MemberOrManager[],
      removeMemberIds: number[],
      addMembers: MemberOrManager[],
    ) => {
      await updateByPkBilikZone({
        variables: {
          bilikZoneId: bilikZoneId,
          bilikZone: {
            name: bilikZone.name,
            guideName: bilikZone.guideName,
            mainCityCode: bilikZone.mainCityCode,
            teamMessage: bilikZone.teamMessage,
            slug: bilikZone.slug,
            telephoneSms: bilikZone.telephoneSms,
            genericEmail: bilikZone.genericEmail,
            telephone: bilikZone.telephone,
            addressLocality: bilikZone.addressLocality,
            postOfficeBoxNumber: bilikZone.postOfficeBoxNumber,
            streetAddress: bilikZone.streetAddress,
            postalCode: bilikZone.postalCode,
            regionPostalCode: bilikZone.regionPostalCode,
            status: bilikZone.status,
            zohoOrganizationId: bilikZone.zohoOrganizationId,
            callTrackingTarget: bilikZone.callTrackingTarget,
            area: bilikZone.area,
            hasMultiplePostalCode: bilikZone.hasMultiplePostalCode,
            hasDistrict: bilikZone.hasDistrict,
            mainCityId: bilikZone.mainCityId,
          },
          removeManagerIds: removeManagerIds ?? [],
          addManagers: addManagers ?? [],
          removeMemberIds: removeMemberIds ?? [],
          addMembers: addMembers ?? [],
        },
      });
    },
    [updateByPkBilikZone],
  );

  return (
    <BilikZoneFormUpdateContainer
      updateBilikZone={updateBilikZone}
      formData={data?.bilikZoneByPk}
      bilikZoneId={bilikZoneId}
      loading={loading}
    />
  );
};

export default BilikZoneFormUpdateRepository;
