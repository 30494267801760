import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import {
  Button,
  Checkbox,
  Container,
  Icon,
  Label,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo } from 'react';
import { ProPresentationSearchFilter } from 'components/pro-presentation-search-filter';
import { ProReviewBoolExp } from 'generated/graphql';
import { ProReviewTable } from '../pro-reviews-table/pro-reviews-table';
import styled from 'styled-components';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import { useProReviewsCount } from 'hooks/use-pro-reviews-count/use-pro-reviews-count';
import { PrivateIndividualSearch } from 'components/private-individual-search-filter';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { useRefetchReviewAction } from 'utils/commands/refetch-review-counter.command';

const CheckboxFilter = styled(Checkbox)`
  margin-left: 12px !important;
  margin-right: 0px;
  height: 32px;

  label {
    margin-top: 2px;
  }
`;

type ProReviewsListProps = {
  givenProPresentationId?: number;
  noMargin?: boolean;
  title?: React.ReactElement;
};

const ProReviewsList: FunctionComponent<ProReviewsListProps> = ({
  givenProPresentationId,
  noMargin,
  title,
}) => {
  const { currentBilikZone } = useCurrentBilikZone();

  const itemPerPage = useMemo(() => 10, []);

  const [filters, setFilters] = useQueryParams({
    source: StringParam,
    page: NumberParam,
    ip: StringParam,
    proReviewId: NumberParam,
    proPresentationId: NumberParam,
    privateIndividualEmail: StringParam,
    isSpam: BooleanParam,
    status: StringParam,
    orderBy: ObjectParam,
    familyName: StringParam,
    givenName: StringParam,
    hasCheckerId: BooleanParam,
    replyStatus: StringParam,
    // it acts as an overide to the default bilik zone selector.
    isAllZones: BooleanParam,
  });

  const isInProViewForm: boolean = useMemo(
    () => Boolean(givenProPresentationId),
    [givenProPresentationId],
  );

  const whereAll: ProReviewBoolExp = useMemo(() => {
    const piCredentialFilters: ProReviewBoolExp[] = [];

    if (filters.givenName || filters.familyName) {
      piCredentialFilters.push({
        familyName: { _eq: filters.familyName ?? undefined },
        givenName: { _eq: filters.givenName ?? undefined },
      });
    }

    if (filters.privateIndividualEmail) {
      piCredentialFilters.push({
        email: { _eq: filters.privateIndividualEmail },
      });
    }

    return {
      id: {
        _eq: filters.proReviewId ?? undefined,
      },

      isSpam: {
        _eq: false,
      },
      _or: piCredentialFilters.length > 0 ? piCredentialFilters : undefined,
      ip: {
        _eq: filters.ip ?? undefined,
      },
      proPresentation: {
        id: {
          _eq: givenProPresentationId ?? filters.proPresentationId ?? undefined,
        },
        proViews: {
          _and: [
            {
              bilikZone: {
                id: {
                  _eq:
                    filters.isAllZones || isInProViewForm
                      ? undefined
                      : currentBilikZone?.id,
                },
              },
            },
            {
              status: {
                _neq:
                  !filters.isAllZones && currentBilikZone
                    ? 'archived'
                    : undefined,
              },
            },
          ],
        },
      },
    };
  }, [
    currentBilikZone,
    filters.privateIndividualEmail,
    filters.familyName,
    filters.givenName,
    filters.ip,
    filters.proPresentationId,
    filters.isAllZones,
    filters.proReviewId,
    givenProPresentationId,
    isInProViewForm,
  ]);

  const whereTable: ProReviewBoolExp = useMemo(() => {
    return {
      ...whereAll,
      isSpam: {
        _eq: filters.isSpam ?? undefined,
      },
      replyStatus: {
        _eq: filters.replyStatus ?? undefined,
      },
      status: filters.status?.startsWith('not_')
        ? { _neq: filters.status.slice(4) ?? undefined }
        : {
            _eq: filters.status ?? undefined,
          },
      source: filters.source
        ? filters.source === 'external'
          ? {
              _neq: 'BILIK',
            }
          : {
              _eq: 'BILIK',
            }
        : undefined,
      checkerId: {
        _isNull:
          filters.hasCheckerId === null || filters.hasCheckerId === undefined
            ? undefined
            : !filters.hasCheckerId,
      },
    };
  }, [
    filters.isSpam,
    filters.status,
    filters.hasCheckerId,
    filters.replyStatus,
    whereAll,
    filters.source,
  ]);

  const {
    count: countAll,
    loading: loadingAll,
    refetch: refetchAllCount,
  } = useProReviewsCount(whereAll);

  const { count: countTable, refetch: refetchTableCount } =
    useProReviewsCount(whereTable);

  const {
    count: countIsStatusOk,
    loading: loadingIsStatusOk,
    refetch: refetchOkCount,
  } = useProReviewsCount({
    ...whereAll,
    status: {
      _eq: 'ok',
    },
    isSpam: {
      _eq: false,
    },
  });

  const {
    count: countOnlyBilik,
    loading: loadingOnlyBilik,
    refetch: refetchOnlyBilikCount,
  } = useProReviewsCount({
    ...whereAll,
    source: {
      _eq: 'BILIK',
    },
  });

  const {
    count: countExternal,
    loading: loadingExternal,
    refetch: refetchExternalCount,
  } = useProReviewsCount({
    ...whereAll,
    source: {
      _neq: 'BILIK',
    },
  });

  const {
    count: countIsStatusKo,
    loading: loadingIsStatusKo,
    refetch: refetchKoCount,
  } = useProReviewsCount({
    ...whereAll,
    status: {
      _eq: 'ko',
    },
    isSpam: {
      _eq: false,
    },
  });

  const {
    count: countReplyKo,
    loading: loadingReplyKo,
    refetch: refetchReplyKoCount,
  } = useProReviewsCount({
    ...whereAll,
    replyStatus: {
      _eq: 'ko',
    },
    isSpam: {
      _eq: false,
    },
  });

  const {
    count: countReplyDraft,
    loading: loadingReplyDraft,
    refetch: refetchReplyDraftCount,
  } = useProReviewsCount({
    ...whereAll,
    replyStatus: {
      _eq: 'draft',
    },
    status: {
      _neq: 'ko',
    },
    isSpam: {
      _eq: false,
    },
  });

  const {
    count: countIsStatusDraft,
    loading: loadingIsStatusDraft,
    refetch: refetchDraftCount,
  } = useProReviewsCount({
    ...whereAll,
    status: {
      _eq: 'draft',
    },
    isSpam: {
      _eq: false,
    },
  });

  const {
    count: countIsSpam,
    loading: loadingIsSpam,
    refetch: refetchSpamCount,
  } = useProReviewsCount({
    ...whereAll,
    isSpam: {
      _eq: true,
    },
  });

  useRefetchReviewAction([
    refetchAllCount,
    refetchTableCount,
    refetchOkCount,
    refetchKoCount,
    refetchDraftCount,
    refetchSpamCount,
    refetchExternalCount,
    refetchOnlyBilikCount,
    refetchReplyKoCount,
    refetchReplyDraftCount,
  ]);

  const totalPages = useMemo(
    () => Math.ceil((countTable ?? 0) / itemPerPage),
    [countTable],
  );

  if (currentBilikZone === undefined && !isInProViewForm) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content noMargin={noMargin}>
      <ContentHeader>
        <ContentHeaderTitle>{title}</ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <Button.Group style={{ marginBottom: '12px' }}>
          <Button
            loading={loadingAll}
            basic={!(filters.status === undefined && filters.isSpam === false)}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                isSpam: false,
                status: undefined,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                replyStatus: undefined,
              });
            }}
          >
            Tous ({countAll ?? 0})
          </Button>
          <Button
            loading={loadingIsStatusOk}
            basic={filters.status !== 'ok'}
            color="green"
            type="button"
            onClick={(): void => {
              setFilters({
                status: 'ok',
                isSpam: false,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                replyStatus: undefined,
              });
            }}
          >
            Validés ({countIsStatusOk ?? 0})
          </Button>
          <Button
            loading={loadingIsStatusKo}
            basic={filters.status !== 'ko'}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                status: 'ko',
                isSpam: false,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                replyStatus: undefined,
              });
            }}
          >
            Refusés ({countIsStatusKo ?? 0})
          </Button>
          <Button
            loading={loadingIsSpam}
            basic={filters.isSpam !== true}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                status: undefined,
                isSpam: true,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                replyStatus: undefined,
              });
            }}
          >
            Spam ({countIsSpam ?? 0})
          </Button>
          <Button
            loading={loadingIsStatusDraft}
            basic={filters.status !== 'draft'}
            color="red"
            type="button"
            onClick={(): void => {
              setFilters({
                status: 'draft',
                isSpam: false,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                replyStatus: undefined,
              });
            }}
          >
            En attente ({countIsStatusDraft ?? 0})
          </Button>
        </Button.Group>
        <Button.Group style={{ marginLeft: '12px' }}>
          <Button
            loading={loadingReplyKo}
            basic={filters.replyStatus !== 'ko'}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                replyStatus: 'ko',
                isSpam: undefined,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                status: undefined,
              });
            }}
          >
            Réponse refusée ({countReplyKo ?? 0})
          </Button>
          <Button
            loading={loadingReplyDraft}
            basic={filters.replyStatus !== 'draft'}
            color="red"
            type="button"
            onClick={(): void => {
              setFilters({
                replyStatus: 'draft',
                isSpam: false,
                hasCheckerId: undefined,
                page: 1,
                source: undefined,
                status: 'not_ko',
              });
            }}
          >
            Réponses en attente ({countReplyDraft ?? 0})
          </Button>
        </Button.Group>
        <Button.Group style={{ marginLeft: '12px' }}>
          <Button
            loading={loadingOnlyBilik}
            basic={filters.source !== 'BILIK'}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                source: 'BILIK',
                status: undefined,
                isSpam: undefined,
                page: 1,
                hasCheckerId: undefined,
              });
            }}
          >
            Avis Bilik ({countOnlyBilik ?? 0})
          </Button>
          <Button
            loading={loadingExternal}
            basic={filters.source !== 'external'}
            color="grey"
            type="button"
            onClick={(): void => {
              setFilters({
                source: 'external',
                status: undefined,
                isSpam: undefined,
                page: 1,
                hasCheckerId: undefined,
              });
            }}
          >
            Avis externes ({countExternal ?? 0})
          </Button>
        </Button.Group>
        <br />
        {!isInProViewForm ? (
          <span style={{ marginLeft: '-12px' }}>
            <ProPresentationSearchFilter
              proPresentationId={
                filters.proPresentationId !== null
                  ? filters.proPresentationId
                  : undefined
              }
              setProPresentationId={(value): void => {
                setFilters({
                  proPresentationId: value,
                });
              }}
              setPage={(value): void => {
                setFilters({
                  page: value,
                });
              }}
              bilikZoneId={currentBilikZone?.id}
            />
          </span>
        ) : null}

        {filters.familyName || filters.givenName ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({
                  familyName: undefined,
                  givenName: undefined,
                });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {filters.givenName} {filters.familyName}
            </Label>
          </Button>
        ) : null}
        {filters.privateIndividualEmail ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({
                  privateIndividualEmail: undefined,
                });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {filters.privateIndividualEmail}
            </Label>
          </Button>
        ) : (
          <PrivateIndividualSearch
            onResultSelect={(event, { result }): void => {
              setFilters({
                privateIndividualEmail: result.value,
                page: undefined,
              });
            }}
            searchContext="proReview"
            proPresentationId={givenProPresentationId}
          />
        )}
        {filters.status === 'ok' || filters.status === 'draft' ? (
          <CheckboxFilter
            checked={filters.hasCheckerId ? true : false}
            className="button basic grey"
            label={
              filters.status === 'ok'
                ? 'Avis vérifiés'
                : 'En cours de vérification'
            }
            onChange={(_, data): void => {
              if (data.checked) {
                setFilters({ hasCheckerId: true });
              } else {
                setFilters({
                  hasCheckerId: undefined,
                });
              }
              setFilters({ page: 1 });
            }}
          />
        ) : null}
        {filters.ip ? (
          <Button
            basic
            style={{ marginLeft: '12px' }}
            color="grey"
            content={filters.ip}
            icon={
              <Icon
                name="remove"
                onClick={(): void => setFilters({ ip: undefined })}
              />
            }
            labelPosition="left"
          />
        ) : null}
        {filters.proReviewId ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({ proReviewId: undefined });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              {`Avis : ${filters.proReviewId}`}
            </Label>
          </Button>
        ) : null}
        {filters.isAllZones ? (
          <Button
            as="div"
            style={{ marginLeft: '12px', marginRight: '0' }}
            labelPosition="right"
          >
            <Button
              color="blue"
              onClick={(): void => {
                setFilters({ isAllZones: undefined });
              }}
            >
              <Icon name="delete" />
            </Button>
            <Label basic pointing="left">
              Toutes les zones
            </Label>
          </Button>
        ) : null}
        <ProReviewTable
          where={whereTable}
          onFilter={(column, value): void => {
            setFilters({
              [column]: value,
              page: 1,
            });
          }}
          orderBy={{
            column: filters.orderBy?.column ?? 'dateCreated',
            direction: filters.orderBy?.direction ?? 'descending',
          }}
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
              page: 1,
            });
          }}
          page={filters.page ?? undefined}
          limit={itemPerPage}
          offset={filters.page ? (filters.page - 1) * itemPerPage : 0}
          isInProViewForm={isInProViewForm}
        />
        {totalPages > 1 ? (
          <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
            <Pagination
              activePage={filters.page ?? 1}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
              onPageChange={(_, data): void => {
                setFilters({ page: Number(data.activePage) });
              }}
            />
          </Container>
        ) : null}
      </ContentBody>
    </Content>
  );
};

export default ProReviewsList;
