import * as Yup from 'yup';
import { ToastError } from 'utils';
import Client from 'client';
import gql from 'graphql-tag';

const isExistingSlug = async (slug?: string | null): Promise<boolean> => {
  return Client.query({
    query: gql`
      query ($slug: String!) {
        city(where: { slug: { _eq: $slug } }) {
          id
          slug
        }
      }
    `,
    variables: { slug: slug || '' },
  }).then((response) => response.data.city && response.data.city.length > 0);
};

type CityFormValidationSchemaArgs = {
  currentSlug?: string;
};

export const cityFormValidationSchema = (
  args?: CityFormValidationSchemaArgs,
): Yup.ObjectSchema => {
  return Yup.object().shape({
    name: Yup.string().required('Nom requis !'),
    slug: Yup.string()
      .required('Slug requis !')
      .matches(/^[a-z0-9-]+$/, {
        excludeEmptyString: true,
        message: 'Format incorrect !',
      })
      .test('is-slug-uniq', 'Slug déjà existant !', async (slug) => {
        if (args?.currentSlug !== slug) {
          return await isExistingSlug(slug)
            .then((res) => res === false)
            .catch((error) => {
              ToastError(
                'Erreur',
                "Impossible de vérifier l'unicité du slug !",
              );
              throw error;
            });
        }
        return true;
      }),
    geoNameId: Yup.number().nullable().required(`GeoNameId requis !`),
    population: Yup.number()
      .min(1, 'Population doit être supérieure à 0')
      .nullable()
      .required(`Population requise !`),
    area: Yup.object().required('Une zone est requise !'),
  });
};
