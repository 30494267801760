import React, { Fragment, FunctionComponent, useEffect, useMemo } from 'react';
import { useSelectProOrganizationFieldQuery } from 'generated/graphql';
import { DropdownItemProps } from 'semantic-ui-react';
import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';

type SelectProOrganizationFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & { shouldRefetch: boolean; onRefetch: () => void };

const SelectProOrganizationField: FunctionComponent<
  SelectProOrganizationFieldProps
> = ({ multiple, shouldRefetch, onRefetch, ...rest }) => {
  const { data, loading, error, refetch } = useSelectProOrganizationFieldQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      onRefetch();
    }
  }, [shouldRefetch]);

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.proOrganization) {
      return data.proOrganization.map((proOrganization) => ({
        key: proOrganization.id,
        value: proOrganization.id,
        text: proOrganization.name,
      }));
    }
    return [];
  }, [data?.proOrganization]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des entreprises...';
    }
    if (multiple) {
      return 'Selectionnez des entreprises...';
    }
    return 'Selectionnez une entreprise...';
  }, [loading, multiple]);

  if (error) return <Fragment>Error! {error.message}</Fragment>;

  return (
    <FormField
      type="select"
      loading={loading}
      disabled={loading}
      options={options}
      multiple={multiple}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SelectProOrganizationField;
