import { useProContractFormQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import ProContractFormContainer from './pro-contract-form-container';

type ProContractFormProps = {
  proOrganizationId: number;
  proViewId: number;
  open: boolean;
  onClose: () => void;
  onProContractGenerated?: () => void;
  proPersonSuggestions?: {
    givenName: string;
    familyName: string;
    telephone: string;
  }[];
  update?: boolean;
  isProPublished: boolean;
};

const ProContractFormRepository: FunctionComponent<ProContractFormProps> = ({
  proOrganizationId,
  proViewId,
  update,
  ...rest
}) => {
  const { loading, data } = useProContractFormQuery({
    variables: { proViewId },
    skip: !update,
  });

  return (
    <ProContractFormContainer
      formData={update ? data?.proViewByPk?.contractConfiguration : null}
      proViewId={proViewId}
      proOrganizationId={proOrganizationId}
      loading={loading}
      update={update}
      {...rest}
    />
  );
};

export default ProContractFormRepository;
