import {
  Geometry as GeometryGeoJSON,
  GeoJsonObject,
  Point,
  GeoJSON,
  Feature,
  FeatureCollection,
  Polygon,
  MultiPolygon,
} from 'geojson';
import { buffer, centroid, Geometry as GeometryTurf, union } from '@turf/turf';
import { FeatureGroup, LatLngLiteral, Layer } from 'leaflet';

export const includeColor = '#0c637b';

export const excludeColor = '#eb5a2c';

export const toTurfGeometry = (geometry?: GeometryGeoJSON): GeometryTurf =>
  geometry as GeometryTurf;

export const toGeoJsonObject = (geometry?: GeometryGeoJSON): GeoJsonObject =>
  geometry as GeoJsonObject;

export const isPoint = (geometry: GeometryGeoJSON): geometry is Point =>
  geometry.type === 'Point';

export const layerToGeoJSON = (layer: Layer): GeoJSON =>
  (layer as unknown as FeatureGroup).toGeoJSON();

export const addCrs = (geometry?: GeometryGeoJSON) => {
  if (!geometry) {
    return undefined;
  }
  return {
    ...geometry,
    crs: { type: 'name', properties: { name: 'EPSG:4326' } },
  };
};

export const toGeometryGeoJSON = (
  latlng: LatLngLiteral,
  radius?: number,
): GeometryGeoJSON => {
  return buffer(
    {
      type: 'Point',
      coordinates: [latlng.lng, latlng.lat],
    },
    radius ?? 1,
  ).geometry as GeometryGeoJSON;
};

export const convertLayersToPolygon = (layers: Layer[]): GeometryGeoJSON => {
  const geoJsons = layers.map((layer) => layerToGeoJSON(layer));

  const polygons: Feature<Polygon | MultiPolygon>[] = (
    geoJsons as Feature<Polygon>[] | FeatureCollection<Polygon>[]
  ).map((geoJson) => {
    if (geoJson.type === 'Feature') {
      return geoJson.geometry;
    }
    if (geoJson.type === 'FeatureCollection') {
      const reduce = geoJson.features.reduce((acc, feature) => {
        if (feature.geometry?.type === 'Polygon' || 'MultiPolygon') {
          return union(acc.geometry as Polygon, feature.geometry as Polygon);
        }
        return acc;
      }, geoJson.features[0]);
      return reduce.geometry;
    }
    return geoJson as Feature<Polygon | MultiPolygon>;
  });

  const polygon: Feature = polygons.reduce((acc, polygon) => {
    return union(acc, polygon) ?? acc;
  }, polygons[0]);

  return polygon.geometry;
};

export const getLatLngCenter = (geometry?: GeometryGeoJSON): LatLngLiteral => {
  const center = centroid(toTurfGeometry(geometry)).geometry.coordinates;

  return {
    lng: center[0],
    lat: center[1],
  };
};
