import { Grid } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { TradesGuideTable } from './trades-guide-table/trade-guide-table';

const TradesListPage: FunctionComponent = () => {
  return (
    <Content>
      <ContentHeader>
        <Grid columns={1}>
          <Grid.Column>
            <ContentHeaderTitle>Catégories Guide</ContentHeaderTitle>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <TradesGuideTable />
      </ContentBody>
    </Content>
  );
};

export default TradesListPage;
