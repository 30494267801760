import FormField from 'components/form-field/form-field';
import { Formik } from 'formik';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent } from 'react';
import { Button, Form, Icon, Message } from 'semantic-ui-react';
import { formatTelephone } from 'utils';
import * as Yup from 'yup';

type RefuseProReviewFormContainerProps = {
  refuseProReview: (reason: string) => unknown;
};

export const RefuseProReviewFormContainer: FunctionComponent<
  RefuseProReviewFormContainerProps
> = ({ refuseProReview }) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  return (
    <Formik
      validationSchema={Yup.object().shape({
        refuseReason: Yup.string().required('Réponse requise !'),
      })}
      validateOnChange={true}
      initialValues={{ refuseReason: '' }}
      onSubmit={async (values, actions): Promise<void> => {
        await refuseProReview(values.refuseReason);

        actions.setSubmitting(false);
      }}
    >
      {(props): React.ReactElement => (
        <Form onReset={props.handleReset} onSubmit={props.handleSubmit}>
          <FormField
            type="textarea"
            placeholder="Raison du refus"
            label="Raison du refus"
            helpText="Ne pas signer ! La signature est automatique. La raison est transmise au particulier et au pro"
            name="refuseReason"
            required
          />
          {currentBilikPerson && (
            <Message>
              <p>Cordialement,</p>
              <p>
                {currentBilikPerson.givenName} {currentBilikPerson.familyName} -
                Bilik
                <br />
                <a href={`tel:${currentBilikPerson.telephone}`}>
                  {formatTelephone(currentBilikPerson.telephone ?? 'error')}
                </a>
              </p>
            </Message>
          )}
          <Button
            primary
            type="submit"
            loading={props.isSubmitting}
            disabled={props.isSubmitting}
          >
            <Icon name="mail" />
            Envoyer
          </Button>
        </Form>
      )}
    </Formik>
  );
};
