import { Loader, TextAreaProps } from 'semantic-ui-react';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { GloballyBlockedQuery } from 'generated/graphql';
import { GloballyBlockedView } from './globally-blocked-view';
import { ToastSuccess } from 'utils/toast';

type GloballyBlockedContainerProps = {
  email?: string;
  telephone?: string;
  triggerNotBlocked?: React.ReactElement;
  triggerBlocked: React.ReactElement;
  fetchGloballyBlockedIdentities: (identifiers: string[]) => void;
  insertGloballyBlockedIdentity: (
    identifier: string,
    reason: string,
  ) => Promise<unknown>;
  deleteGloballyBlockedIdentity: (identifier: string) => Promise<unknown>;
  globallyBlockedIdentities?: GloballyBlockedQuery['globallyBlockedIdentity'];
  onActionTriggered?: () => void;
};

export const GloballyBlockedContainer: FunctionComponent<
  GloballyBlockedContainerProps
> = ({
  email,
  telephone,
  triggerNotBlocked,
  triggerBlocked,
  globallyBlockedIdentities,
  fetchGloballyBlockedIdentities,
  insertGloballyBlockedIdentity,
  deleteGloballyBlockedIdentity,
  onActionTriggered,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBlocked, setBlocked] = useState<boolean>();
  const [reason, setReason] = useState<string>();
  const [identifiers, setIdentifiers] = useState<string[]>([]);
  const [trigger, setTrigger] = useState<ReactElement>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onReasonChange = useCallback(
    (
      event: React.FormEvent<HTMLTextAreaElement>,
      { value }: TextAreaProps,
    ): void => {
      setReason(value as string);
    },
    [],
  );

  //search if entity is blocked in database and set identifier
  useEffect(() => {
    const newIdentifiers: string[] = [];
    if (email) {
      newIdentifiers.push(`email:${email}`);
    }

    if (telephone) {
      newIdentifiers.push(`telephone:${telephone}`);
    }
    fetchGloballyBlockedIdentities(newIdentifiers);
    setIdentifiers(newIdentifiers);
  }, [email, telephone]);

  //Set trigger if identifier is blocked or unblocked
  useEffect(() => {
    if (globallyBlockedIdentities) {
      if (globallyBlockedIdentities.length == 0 && triggerNotBlocked) {
        triggerNotBlocked = React.cloneElement(triggerNotBlocked, {
          key: 'unBlocked',
          onClick: () => setIsModalOpen(true),
        });
        setTrigger(triggerNotBlocked);
        setBlocked(false);
      }

      if (globallyBlockedIdentities.length > 0) {
        triggerBlocked = React.cloneElement(triggerBlocked, {
          key: 'blocked',
          onClick: () => setIsModalOpen(true),
        });
        setTrigger(triggerBlocked);
        setBlocked(true);
      }
    }
  }, [globallyBlockedIdentities, isBlocked]);

  // this Callback is triggered when user confirm the blocked action (don't work if reason string is empty)
  const blockEntity = useCallback(async () => {
    setLoading(true);

    if (!reason) {
      return;
    }

    for (const identifier of identifiers) {
      await insertGloballyBlockedIdentity(identifier, reason);
    }

    if (onActionTriggered) {
      onActionTriggered();
    }

    fetchGloballyBlockedIdentities(identifiers);
    setIsModalOpen(false);
    ToastSuccess('Succès', 'Identité(s) bloquée(s) !');
    setLoading(false);
  }, [reason, identifiers]);

  //Callback triggered when user confirm the unblocked Action
  const unBlockEntity = useCallback(async () => {
    setLoading(true);
    for (const identifier of identifiers) {
      await deleteGloballyBlockedIdentity(identifier);
    }

    if (onActionTriggered) {
      onActionTriggered();
    }

    fetchGloballyBlockedIdentities(identifiers);
    setIsModalOpen(false);
    ToastSuccess('Succès', 'Identité(s) débloquée(s) !');
    setLoading(false);
  }, [identifiers]);

  if (!globallyBlockedIdentities) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <GloballyBlockedView
      isBlocked={isBlocked}
      email={email}
      telephone={telephone}
      closeModal={(): void => setIsModalOpen(false)}
      blockEntity={blockEntity}
      unBlockEntity={unBlockEntity}
      reason={reason}
      onReasonChange={onReasonChange}
      globallyBlockedIdentities={globallyBlockedIdentities}
      isModalOpened={isModalOpen}
      trigger={trigger}
      isLoading={isLoading}
    />
  );
};
