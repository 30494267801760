import {
  Button,
  Dimmer,
  Dropdown,
  Icon,
  Label,
  List,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';
import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { CurrentBilikZoneQuery } from 'generated/graphql';
import env from 'env';
import { formatDate } from 'utils';
import { CountProPresentationReviewByStatus } from 'components/count-pro-presentation-review-by-status';
import { Me } from 'components/socket/socket';
import { getProViewStatusColor } from '../../../utils/index';
import { round } from 'utils/round';
import { Rating } from 'components/rating';
import { ProsTableProView } from './pros-table-repository';
import { format, subMonths } from 'date-fns';

type Props = {
  loading: boolean;
  currentBilikZone: CurrentBilikZoneQuery['bilikZoneByPk'] | null;
  onClickSortByDate: () => void;
  onClickSortByRating: () => void;
  onClickSortByReviewCount: () => void;
  onClickSortByName: () => void;
  proViews: ProsTableProView[];
  orderBy;
  membersByProViewId: { [key in string]: Me };
  statusFilter: any;
  selectedTradeId?: number | null;
};

const hilightStyle = {
  backgroundColor: '#3c8dbc33',
};

export const ProsTableView: FunctionComponent<Props> = ({
  loading,
  orderBy,
  onClickSortByDate,
  onClickSortByRating,
  onClickSortByReviewCount,
  onClickSortByName,
  proViews,
  currentBilikZone,
  membersByProViewId,
  statusFilter,
  selectedTradeId,
}) => {
  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={onClickSortByName}
              sorted={
                orderBy.column === 'proPresentation.name'
                  ? orderBy.direction
                  : undefined
              }
            >
              Nom de la fiche
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Catégories
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={onClickSortByDate}
              sorted={
                ['dateCreated', 'datePublished', 'dateArchived'].includes(
                  orderBy.column,
                )
                  ? orderBy.direction
                  : undefined
              }
            >
              Dates
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={onClickSortByReviewCount}
              sorted={
                orderBy.column === 'proPresentation.proReviews.aggregate.count'
                  ? orderBy.direction
                  : undefined
              }
            >
              Avis
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={onClickSortByRating}
              sorted={
                orderBy.column === 'proPresentationRating'
                  ? orderBy.direction
                  : undefined
              }
            >
              Note
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="not sortable">
              <Popup
                trigger={
                  <span>
                    Mise en relation M-1 (
                    {format(subMonths(new Date(), 1), 'MM/yyyy')})
                  </span>
                }
                content="Nombre de nouveaux particuliers ayant contacté le pro via appel, mail ou sms. Si un particulier n'a pas contacté le pro depuis plus de 4 mois il est de nouveau considéré comme nouveau."
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable" textAlign="right">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {proViews.map((proView) => {
            const secondaryTrades = proView.trades.filter(
              ({ trade }) =>
                trade.id !== proView.proPresentation.mainTrade?.id &&
                trade.id !== proView.proPresentation.secondaryTrade?.id,
            );
            return (
              <Table.Row key={proView.id}>
                <Table.Cell>
                  <List>
                    <List.Item>
                      {membersByProViewId[proView.id] ? (
                        <Popup
                          content={`${
                            membersByProViewId[proView.id].givenName
                          } ${membersByProViewId[proView.id].familyName}`}
                          position="top center"
                          trigger={
                            <List.Content floated="right">
                              <Icon
                                name="lock"
                                size="small"
                                circular
                                inverted
                                color="red"
                              />
                            </List.Content>
                          }
                        />
                      ) : null}
                      <List.Content>
                        <Icon
                          name="circle"
                          color={getProViewStatusColor(proView.status)}
                          size="small"
                        />
                        &nbsp;
                        <b>
                          {proView.proPresentation.name}
                          {!currentBilikZone?.id ? (
                            <> ({proView.bilikZone.mainCityCode})</>
                          ) : null}
                          <br />
                        </b>
                        {proView.proPeople.map((proPerson) => (
                          <div
                            style={{ paddingLeft: '20px' }}
                            key={`pro-person-${proPerson.proPerson.id}`}
                          >
                            {proPerson.proPerson.givenName}{' '}
                            {proPerson.proPerson.familyName}
                          </div>
                        ))}
                        <br />
                        {proView.holidayEndDate ? (
                          <Label
                            as="a"
                            color={'orange'}
                            style={{ marginLeft: '20px' }}
                            href={`/pros/update/${proView.id}/holidays`}
                          >
                            En congé jusqu&apos;au{' '}
                            {formatDate(proView.holidayEndDate)}{' '}
                          </Label>
                        ) : null}
                      </List.Content>
                    </List.Item>
                  </List>
                </Table.Cell>
                <Table.Cell>
                  <strong
                    style={{
                      ...(proView.proPresentation.mainTrade?.id ===
                      selectedTradeId
                        ? hilightStyle
                        : {}),
                    }}
                  >
                    {proView.proPresentation.mainTrade?.name}
                  </strong>
                  <strong>
                    {proView.proPresentation.secondaryTrade ? (
                      <>
                        {' '}
                        | bis :{' '}
                        <span
                          style={{
                            ...(proView.proPresentation.secondaryTrade?.id ===
                            selectedTradeId
                              ? hilightStyle
                              : {}),
                          }}
                        >
                          {proView.proPresentation.secondaryTrade.name}{' '}
                        </span>
                      </>
                    ) : (
                      ' '
                    )}
                  </strong>
                  {secondaryTrades.map(({ trade }) => (
                    <span key={trade.id}>
                      |{' '}
                      <span
                        style={{
                          ...(trade.id === selectedTradeId ? hilightStyle : {}),
                        }}
                      >
                        {trade.name}
                      </span>{' '}
                    </span>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <p>
                    {proView.dateCreated ? (
                      <>
                        {!statusFilter || statusFilter === 'draft' ? (
                          <u>Créé le</u>
                        ) : (
                          <>Créé le</>
                        )}{' '}
                        : {formatDate(proView.dateCreated)}
                      </>
                    ) : (
                      'Inconnu'
                    )}
                    {proView.datePublished ? (
                      <>
                        <br />
                        {statusFilter === 'published' ? (
                          <u>Publié le</u>
                        ) : (
                          <>Publié le</>
                        )}{' '}
                        : {formatDate(proView.datePublished)}
                      </>
                    ) : null}
                    {proView.dateArchived ? (
                      <>
                        <br />
                        {statusFilter === 'archived' ? (
                          <u>Archivé le</u>
                        ) : (
                          <>Archivé le</>
                        )}{' '}
                        : {formatDate(proView.dateArchived)}
                      </>
                    ) : null}
                  </p>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <CountProPresentationReviewByStatus
                    proPresentationId={proView.proPresentation.id}
                    proPresentationName={proView.proPresentation.name}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {proView.proPresentation.proReviewsAggregate.aggregate?.avg
                    ?.rating ? (
                    <Fragment>
                      <div
                        style={{ display: 'inline-block', minWidth: '80px' }}
                      >
                        <Rating
                          value={
                            proView.proPresentation.proReviewsAggregate
                              .aggregate?.avg?.rating
                          }
                        />
                      </div>
                      <br />
                      <Label style={{ fontSize: '14px' }}>
                        {round(
                          proView.proPresentation.proReviewsAggregate.aggregate
                            ?.avg?.rating,
                          10,
                        )}
                      </Label>
                    </Fragment>
                  ) : (
                    "Pas d'avis publiés"
                  )}{' '}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {proView.lastMonthNewContacts}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {/* Copy this */}
                  <Dropdown
                    icon="triangle down"
                    floating
                    button
                    className="icon"
                    direction="left"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="a"
                        href={`${env.SITE_URL}/presentations/${proView.id}-${proView.proPresentation.slug}`}
                        icon="eye"
                        text="Voir la fiche"
                      />
                      <Dropdown.Item
                        as={Link}
                        to={`/pro_review_invitations/create/${encodeURIComponent(
                          proView.proPresentation.id,
                        )}/${encodeURIComponent(proView.id)}`}
                        icon="plus"
                        text="Nouvelle demande d'avis"
                      />
                      <Dropdown.Item
                        as={Link}
                        to={`/pro_reviews/create/${encodeURIComponent(
                          proView.proPresentation.id,
                        )}`}
                        icon="plus"
                        text="Nouvel avis"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    as={Link}
                    to={`/pros/update/${proView.id}`}
                    icon
                    primary
                  >
                    <Icon name="pencil alternate" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};
