import { Formik } from 'formik';
// import { ProPersonFormUpdateQuery, ProPersonSetInput } from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ProPersonFormFields } from './pro-person-form-fields-type';
import ProPersonFormView from './pro-person-form-view';
import { ProPersonFormModalProps } from './pro-person-form-modal';
import { formatE164Telephone, ToastError, ToastSuccess } from 'utils';
import proPersonFormValidationSchema from './pro-person-form-validation-schema';
import { ProPersonFormUpdateQuery, ProPersonSetInput } from 'generated/graphql';
import { capitalizeName } from 'utils/capitalize';

type ProPersonFormUpdateContainerProps = ProPersonFormModalProps & {
  proPersonId: number;
  proPerson?: ProPersonFormUpdateQuery['proPersonByPk'];
  updateProPerson: (proPerson: ProPersonSetInput) => Promise<void>;
};

const ProPersonFormUpdateContainer: FunctionComponent<
  ProPersonFormUpdateContainerProps
> = ({ proPerson, updateProPerson, onClose, proPersonId, ...rest }) => {
  const onSubmit = useCallback(
    async (values: ProPersonFormFields) => {
      await updateProPerson({
        givenName: capitalizeName(values.givenName).trim(),
        familyName: capitalizeName(values.familyName).trim(),
        telephone: values.telephone
          ? formatE164Telephone(values.telephone)
          : undefined,
        email: values.email.toLowerCase().trim(),
      }).catch((error) => {
        ToastError('Erreur', "Impossible d'enregistrer le contact");
        throw error;
      });

      if (onClose) {
        onClose();
      }

      ToastSuccess('Succès', 'Contact modifié');
    },
    [updateProPerson],
  );

  // Loading state
  if (!proPerson) {
    return null;
  }

  const initialValues: ProPersonFormFields = {
    givenName: proPerson.givenName,
    familyName: proPerson.familyName,
    telephone: proPerson.telephone || '',
    email: proPerson.email || '',
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={proPersonFormValidationSchema}
      validateOnChange={false}
    >
      <ProPersonFormView
        onClose={onClose}
        proPersonId={proPersonId}
        {...rest}
      />
    </Formik>
  );
};

export default ProPersonFormUpdateContainer;
