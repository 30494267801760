import React, { FunctionComponent } from 'react';
import { QueryParamProvider } from 'use-query-params';
import Routes from 'routes';
import MainHeader from 'components/main-header/main-header';
import MainSidebar from 'components/main-sidebar/main-sidebar';
import MainFooter from 'components/main-footer/main-footer';
import { Socket } from 'components/socket/socket';
import { BrowserRouter } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

const AppLayout: FunctionComponent = () => {
  return (
    <BrowserRouter>
      {/*TODO CHELOU */}
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Socket>
          <MainHeader />
          <MainSidebar />
          <Routes />
          <MainFooter />
        </Socket>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default AppLayout;
