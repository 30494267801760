import React, { Fragment, FunctionComponent, useMemo } from 'react';

import { useSelectBilikZoneFieldQuery } from 'generated/graphql';
import { DropdownItemProps } from 'semantic-ui-react';
import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';

type SelectBilikZoneFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading' | 'disabled'
> & {
  except?: number[];
};

const SelectBilikZoneField: FunctionComponent<SelectBilikZoneFieldProps> = ({
  multiple,
  except,
  ...rest
}) => {
  const { data, loading, error } = useSelectBilikZoneFieldQuery({
    fetchPolicy: 'no-cache',
  });

  const options: DropdownItemProps[] = useMemo(() => {
    if (data?.bilikZone) {
      const bilikZones =
        except && except.length > 0
          ? data.bilikZone.filter((bilikZone) => !except.includes(bilikZone.id))
          : data.bilikZone;

      return bilikZones.map((bilikZone) => ({
        key: bilikZone.id,
        value: bilikZone.id,
        text: bilikZone.mainCityCode,
      }));
    }
    return [];
  }, [data?.bilikZone, except]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des zones...';
    }
    if (multiple) {
      return 'Selectionnez des zones...';
    }
    return 'Selectionnez une zone...';
  }, [loading, multiple]);

  if (error) return <Fragment>Error! ${error.message}</Fragment>;

  return (
    <FormField
      type="select"
      disabled={loading}
      loading={loading}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      selection
      {...rest}
    />
  );
};
export default SelectBilikZoneField;
