import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import { Icon, Label } from 'semantic-ui-react';
import env from 'env';

type TimelineSolicitationActionsProps = {
  solicitation: MongoEvent['data']['solicitation'];
};

const TimelineSolicitationActions: FunctionComponent<
  TimelineSolicitationActionsProps
> = ({ solicitation }) => (
  <>
    <Label
      as="a"
      onClick={(): void => {
        window.open(
          `/solicitations?solicitationId=${solicitation._id}&isAllZones=1`,
          '_blank',
        );
      }}
    >
      <Icon name="eye" size="small" />
      Voir demande (admin)
    </Label>
    {solicitation.uuid && (
      <Label
        as="a"
        onClick={(): void => {
          window.open(
            `${env.SITE_URL}/mes-demandes/${solicitation.uuid}`,
            '_blank',
          );
        }}
      >
        <Icon name="eye" size="small" />
        Voir demande (bilik.fr)
      </Label>
    )}
  </>
);

export default TimelineSolicitationActions;
