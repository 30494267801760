import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { OrderBy, TradesInfoTableQuery } from 'generated/graphql';
import { Loader, Message } from 'semantic-ui-react';
import { TradesInfoTableView } from './trades-info-tables-view';
import { CurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';

type Props = {
  loading: boolean;
  trades: TradesInfoTableQuery['trade'] | [];
  currentBilikZone: CurrentBilikZone;
  orderBy: {
    column: string;
    direction: string;
  };

  onSort?: (column: string, direction: string) => void;
};

export const TradesInfoTableContainer: FunctionComponent<Props> = ({
  loading,
  trades,
  currentBilikZone,
  orderBy,
  onSort,
}) => {
  const direction = useMemo(() => {
    switch (orderBy.direction) {
      case 'ascending': {
        return OrderBy.Asc;
      }
      case 'descending': {
        return OrderBy.Desc;
      }
    }
  }, [orderBy.direction]);

  const [sortedData, setSortedData] = useState(trades);

  useEffect(() => {
    const sortedTrades = [...trades].sort((a, b) => {
      switch (orderBy.column) {
        case 'tradeProViews':
          const aCount = a.tradeProViewsAggregate.aggregate?.count ?? 0;
          const bCount = b.tradeProViewsAggregate.aggregate?.count ?? 0;
          return (
            (direction === OrderBy.Asc ? aCount - bCount : bCount - aCount) ?? 0
          );
        case 'name':
          return (
            (direction === OrderBy.Asc
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name)) ?? 0
          );
        default:
          return 0;
      }
    });

    setSortedData(sortedTrades);
  }, [trades, orderBy, direction]);

  if (!loading && !trades.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  if (loading && !trades.length) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <TradesInfoTableView
      loading={loading}
      orderBy={orderBy}
      onSort={onSort}
      trades={sortedData}
      currentBilikZone={currentBilikZone}
    />
  );
};
