import { Loader, Message } from 'semantic-ui-react';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { ProReviewActionsModal } from 'components/pro-review-actions-modal';
import { CurrentBilikZoneQuery, ProReviewsTableQuery } from 'generated/graphql';
import { ProReviewsTableView } from './pro-reviews-table-view';
import { ReviewModalMetadata } from 'components/pro-review-actions-modal/pro-review-actions-modal-repository';

type Props = {
  proReviews?: ProReviewsTableQuery['proReview'];
  refetch: () => void;
  page?: number;
  orderBy: {
    column: string;
    direction: string;
  };
  onSort: (column: string, direction: string) => void;
  onFilter: (column: string, value: string) => void;
  isInProViewForm: boolean;
  currentBilikZone: CurrentBilikZoneQuery['bilikZoneByPk'] | null;
};

export const ProReviewsTableContainer: FunctionComponent<Props> = ({
  orderBy,
  proReviews,
  page,
  refetch,
  onSort,
  onFilter,
  isInProViewForm,
  currentBilikZone,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchProReviews = async (): Promise<void> => {
      setLoading(true);
      await refetch();
      setLoading(false);
    };
    fetchProReviews();
  }, [refetch]);

  const [modalMetadata, setModalMetadata] = useState<ReviewModalMetadata>({
    isOpen: false,
  });

  // Define modal callbacks
  const openRefuseModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({ isOpen: true, type: 'refuse', proReview: proReview });
    },
    [setModalMetadata],
  );

  const openReplyModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({ isOpen: true, type: 'reply', proReview: proReview });
    },
    [setModalMetadata],
  );

  const openReplyEditModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({
        isOpen: true,
        type: 'reply-edit',
        proReview: proReview,
      });
    },
    [setModalMetadata],
  );

  const openCheckModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({ isOpen: true, type: 'check', proReview: proReview });
    },
    [setModalMetadata],
  );

  const openDuplicateModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({
        isOpen: true,
        type: 'duplicate',
        proReview: proReview,
      });
    },
    [setModalMetadata],
  );

  const openSmsModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({ isOpen: true, type: 'sms', proReview: proReview });
    },
    [setModalMetadata],
  );

  const openCityModal = useCallback(
    (proReview: ReviewModalMetadata['proReview']) => {
      setModalMetadata({ isOpen: true, type: 'city', proReview: proReview });
    },
    [setModalMetadata],
  );

  const closeModal = useCallback(() => {
    setModalMetadata({ isOpen: false });
  }, [setModalMetadata]);

  if (loading || !proReviews) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  if (!loading && proReviews.length === 0) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  return (
    <>
      <ProReviewsTableView
        loading={loading}
        onSort={onSort}
        onFilter={onFilter}
        openCheckModal={openCheckModal}
        openRefuseModal={openRefuseModal}
        openReplyModal={openReplyModal}
        openReplyEditModal={openReplyEditModal}
        openSmsModal={openSmsModal}
        openDuplicateModal={openDuplicateModal}
        openCityModal={openCityModal}
        proReviews={proReviews}
        refetch={refetch}
        page={page}
        orderBy={orderBy}
        isInProViewForm={isInProViewForm}
        currentBilikZone={currentBilikZone}
      />
      <ProReviewActionsModal
        closeModal={closeModal}
        modalMetadata={modalMetadata}
        refetch={refetch}
      />
    </>
  );
};
