import {
  CityFormCreateMutation,
  CityInsertInput,
  useCityFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';

import CityFormCreateContainer from './city-form-create-container';

const CityFormCreateRepository: FunctionComponent = () => {
  const [insertCityOne] = useCityFormCreateMutation();

  const createCity = useCallback(
    async (
      city: CityInsertInput,
    ): Promise<CityFormCreateMutation | null | undefined> => {
      const { data } = await insertCityOne({
        variables: {
          object: city,
        },
      });
      return data;
    },
    [insertCityOne],
  );

  return <CityFormCreateContainer createCity={createCity} />;
};

export default CityFormCreateRepository;
