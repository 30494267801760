import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import React, { FunctionComponent } from 'react';
import { TradesFormCreate } from './trade-form';

const TradeCreatePage: FunctionComponent = () => {
  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>Nouvelle catégorie</ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <TradesFormCreate />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default TradeCreatePage;
