import { ProViewProPersonDeleteConfirmModalQuery } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import ProViewProPersonDeleteConfirmModalView, {
  ProViewProPersonDeleteConfirmModalProps,
} from './pro-view-pro-person-delete-confirm-modal-view';

type ProViewProPersonDeleteConfirmModalContainerProps = ProViewProPersonDeleteConfirmModalProps & {
  currentProViewId?: number;
  data?: ProViewProPersonDeleteConfirmModalQuery;
  loading: boolean;
  onConfirm?: (proPersonId: number) => void;
  proPersonId: number;
};

const ProViewProPersonDeleteConfirmModalContainer: FunctionComponent<ProViewProPersonDeleteConfirmModalContainerProps> = ({
  data,
  currentProViewId,
  loading,
  onClose,
  onConfirm,
  proPersonId,
  ...rest
}) => {
  const confirmDelete = useCallback(() => {
    if (onConfirm) {
      onConfirm(proPersonId);
    }
    if (onClose) {
      onClose();
    }
  }, [onClose, onConfirm]);

  const relatedProViewProPeople = useMemo(
    () =>
      data?.relatedProViewProPeople.filter(
        (proView) => proView.id !== currentProViewId,
      ),
    [data?.relatedProViewProPeople],
  );

  const relatedProViewAccounts = useMemo(
    () =>
      data?.relatedProViewAccounts.filter(
        (proView) => proView.id !== currentProViewId,
      ),
    [data?.relatedProViewAccounts],
  );

  const hasCurrentAccountRelated = useMemo(
    () =>
      !!data?.relatedProViewAccounts.find(
        (proView) => proView.id === currentProViewId,
      ),
    [data?.relatedProViewAccounts],
  );

  const proPerson = useMemo(() => data?.proPersonByPk, [data?.proPersonByPk]);

  // If no others related entities linked then auto confirm
  useEffect(() => {
    if (
      relatedProViewProPeople &&
      relatedProViewProPeople.length === 0 &&
      relatedProViewAccounts &&
      relatedProViewAccounts.length === 0
    ) {
      if (onConfirm) {
        onConfirm(proPersonId);
      }
      if (onClose) {
        onClose();
      }
    }
  }, [relatedProViewProPeople]);

  if (!proPerson && !loading) {
    return <>Erreur contact introuvable</>;
  }

  // Loading state
  if (!proPerson || !relatedProViewProPeople || !relatedProViewAccounts) {
    return null;
  }

  return (
    <ProViewProPersonDeleteConfirmModalView
      relatedProViewProPeople={relatedProViewProPeople}
      relatedProViewAccounts={relatedProViewAccounts}
      hasCurrentAccountRelated={hasCurrentAccountRelated}
      proPerson={proPerson}
      confirmDelete={confirmDelete}
      onClose={onClose}
      {...rest}
    />
  );
};

export default ProViewProPersonDeleteConfirmModalContainer;
