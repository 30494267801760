import {
  OrderBy,
  ProsTableQuery,
  ProViewBoolExp,
  useProsTableQuery,
} from 'generated/graphql';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ProsTableContainer } from './pros-table-container';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';
import { Me, WebSocketContext } from 'components/socket/socket';
import { apiClient } from 'axios-client';
import { startOfMonth, subMonths } from 'date-fns';

type Props = {
  orderBy?;
  setOrderBy;
  page?: number;
  setPage: (page: number) => void;
  where?: ProViewBoolExp;
  search?: string;
  itemPerPage: number;
  selectedTradeId?: number | null;
};

export type ProsTableProView = ProsTableQuery['searchProView'][0] & {
  lastMonthNewContacts: number;
};

export const ProsTableRepository: FunctionComponent<Props> = ({
  orderBy,
  setOrderBy,
  page,
  setPage,
  where,
  search,
  itemPerPage,
  selectedTradeId,
}) => {
  const [proViews, setProViews] = useState<ProsTableProView[]>();

  const direction = useMemo(() => {
    switch (orderBy.direction) {
      case 'ascending': {
        return OrderBy.Asc;
      }
      case 'descending': {
        return OrderBy.DescNullsLast;
      }
    }
  }, [orderBy.direction]);

  const order_by = useMemo(() => {
    if (orderBy.column === 'id') {
      return [{ id: direction }];
    } else if (orderBy.column === 'proPresentation.name') {
      return [{ proPresentation: { name: direction } }];
    } else if (orderBy.column === 'proPresentationRating') {
      return [
        {
          proPresentation: {
            proReviewsAggregate: { avg: { rating: direction } },
          },
        },
      ];
    } else if (
      orderBy.column === 'proPresentation.proReviews.aggregate.count'
    ) {
      return [
        { proPresentation: { proReviewsAggregate: { count: direction } } },
      ];
    } else if (
      orderBy.column === 'proPresentation.proReviewInvitations.aggregate.count'
    ) {
      return [
        {
          proPresentation: {
            proReviewInvitationsAggregate: { count: direction },
          },
        },
      ];
    } else if (orderBy.column === 'dateCreated') {
      return [{ dateCreated: direction }];
    } else if (orderBy.column === 'datePublished') {
      return [{ datePublished: direction, dateCreated: direction }];
    } else if (orderBy.column === 'dateArchived') {
      return [{ dateArchived: direction }];
    }
    return [];
  }, [orderBy.column, direction]);

  const { currentBilikZone } = useCurrentBilikZone();

  const { data, loading } = useProsTableQuery({
    variables: {
      where,
      limit: itemPerPage,
      offset: page ? (page - 1) * itemPerPage : 0,
      order_by,
      criteria: search
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase(),
    },
  });

  const { members } = useContext(WebSocketContext);

  const membersByProViewId = useMemo(() => {
    const memberOnProUpdatePage = members.filter(
      (member) => member.pathname.indexOf('/pros/update/') !== -1,
    );

    const _membersByProViewId: { [key in string]: Me } = {};
    for (const member of memberOnProUpdatePage) {
      const id = member.pathname.split('/')[3];

      _membersByProViewId[id] = member;
    }

    return _membersByProViewId;
  }, [members]);

  useEffect(() => {
    if (!data?.searchProView) return;

    apiClient
      .post('event/stats', {
        after: startOfMonth(subMonths(new Date(), 1)),
        before: startOfMonth(new Date()),
        proViewIds: data.searchProView.map((proView) => proView.id),
      })
      .then((response) => {
        const proViews: ProsTableProView[] = data.searchProView.map(
          (proView) => ({
            ...proView,
            lastMonthNewContacts:
              response.data.find((item) => item.proViewId === proView.id)
                ?.newContacts ?? 0,
          }),
        );

        setProViews(proViews);
      });
  }, [data?.searchProView]);

  return (
    <ProsTableContainer
      loading={loading || !proViews}
      proViews={proViews}
      currentBilikZone={currentBilikZone}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      setPage={setPage}
      membersByProViewId={membersByProViewId}
      selectedTradeId={selectedTradeId}
    />
  );
};
