import { ApolloQueryResult } from '@apollo/client';
import {
  Exact,
  ProReviewBoolExp,
  ProReviewsCountQuery,
  useProReviewsCountQuery,
} from 'generated/graphql';

type ProReviewsCounter = {
  count?: number;
  loading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            where?: ProReviewBoolExp | undefined;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<ProReviewsCountQuery>>;
};

export const useProReviewsCount = (
  where?: ProReviewBoolExp,
): ProReviewsCounter => {
  const { data, loading, refetch } = useProReviewsCountQuery({
    variables: {
      where,
    },
    pollInterval: 60000, // Auto refetch every minutes
  });

  return {
    loading,
    refetch,
    count: data?.proReviewAggregate.aggregate?.count,
  };
};
