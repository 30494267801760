import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import { Message, Popup } from 'semantic-ui-react';
import TimelineEvent from '../timeline-event';
import { pathOr } from 'remeda';
import { formatTelephone } from 'utils';

type CallRegionBlockedProps = {
  event: MongoEvent;
};

const CallRegionBlockedV0: FunctionComponent<CallRegionBlockedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      color="red"
      date={event.date}
      icon="phone"
      title="Appel bloqué"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a tenté d&apos;appeler{' '}
        <Popup
          content={
            <>
              <>Numéro : {formatTelephone(event.data.twilio.To)}</>
            </>
          }
          trigger={
            <span>
              {event.actors?.pro ? (
                <>
                  {pathOr(event, ['actors', 'pro'], false) ? (
                    <>{event.actors.pro.proPresentation.name}</>
                  ) : (
                    'Pro inconnu ou plus dans Bilik'
                  )}
                </>
              ) : null}
              {event.actors?.bilik ? (
                <>Bilik {event.actors.bilik.bilikZone.mainCity}</>
              ) : null}
            </span>
          }
          position="top center"
        />{' '}
        mais l&apos;appel a été bloqué (pays non autorisé)
      </h3>
    </TimelineEvent>
  );
};

const CallRegionBlockedV1: FunctionComponent<CallRegionBlockedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      color="red"
      date={event.date}
      icon="phone"
      title="Appel bloqué"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a tenté d&apos;appeler{' '}
        <Popup
          content={
            <>
              <>Numéro : {formatTelephone(event.data.twilio.To)}</>
            </>
          }
          trigger={
            <span>
              {event.actors?.pro ? (
                <>
                  {pathOr(event, ['actors', 'pro'], false) ? (
                    <>
                      {event.actors.pro.proPresentation.name} (
                      {event.actors.pro.bilikZone.mainCityCode})
                    </>
                  ) : (
                    'Pro inconnu ou plus dans Bilik'
                  )}
                </>
              ) : null}
              {event.actors?.bilik ? (
                <>Bilik {event.actors.bilik.bilikZone.mainCity}</>
              ) : null}
            </span>
          }
          position="top center"
        />{' '}
        mais l&apos;appel a été bloqué (pays non autorisé)
      </h3>
    </TimelineEvent>
  );
};

const CallRegionBlockedV2: FunctionComponent<CallRegionBlockedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      color="red"
      icon="phone"
      date={event.date}
      title="Appel bloqué"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a tenté d&apos;appeler{' '}
        <Popup
          content={<>Numéro : {formatTelephone(event.data.twilio.To)}</>}
          trigger={
            <span>
              {event.actors?.pro ? (
                <>
                  {pathOr(event, ['actors', 'pro'], false) ? (
                    <>
                      {event.actors.pro.proPresentation.name} (
                      {event.actors.pro.bilikZone.mainCityCode} -{' '}
                      {event.actors.pro.proPresentation.mainTrade?.name})
                    </>
                  ) : (
                    'Pro inconnu ou plus dans Bilik'
                  )}
                </>
              ) : null}
              {event.actors?.bilik ? (
                <>Bilik {event.actors.bilik.bilikZone.mainCity}</>
              ) : null}
            </span>
          }
          position="top center"
        />{' '}
        mais l&apos;appel a été bloqué (pays non autorisé)
      </h3>
    </TimelineEvent>
  );
};

const CallRegionBlockedV3: FunctionComponent<CallRegionBlockedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      color="red"
      icon="phone"
      date={event.date}
      title="Appel bloqué"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a tenté d&apos;appeler{' '}
        <Popup
          content={
            <>
              <>Numéro : {formatTelephone(event.data.vonage.to)}</>
            </>
          }
          trigger={
            <span>
              {event.actors?.pro ? (
                <>
                  {pathOr(event, ['actors', 'pro'], false) ? (
                    <>
                      {event.actors.pro.proPresentation.name} (
                      {event.actors.pro.bilikZone.mainCityCode} -{' '}
                      {event.actors.pro.proPresentation.mainTrade?.name})
                    </>
                  ) : (
                    'Pro inconnu ou plus dans Bilik'
                  )}
                </>
              ) : null}
              {event.actors?.bilik ? (
                <>Bilik {event.actors.bilik.bilikZone.mainCity}</>
              ) : null}
            </span>
          }
          position="top center"
        />{' '}
        mais l&apos;appel a été bloqué (pays non autorisé)
      </h3>
    </TimelineEvent>
  );
};

const CallRegionBlocked: FunctionComponent<CallRegionBlockedProps> = ({
  event,
}) => {
  if (event.version === 0) {
    return <CallRegionBlockedV0 event={event} />;
  } else if (event.version === 1) {
    return <CallRegionBlockedV1 event={event} />;
  } else if (event.version === 2) {
    return <CallRegionBlockedV2 event={event} />;
  } else if (event.version === 3) {
    return <CallRegionBlockedV3 event={event} />;
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour CallRegionBlocked (version ${event.version})`}
    />
  );
};

export default CallRegionBlocked;
