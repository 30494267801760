// Window variables are generated in the docker entrypoint.sh
const env = {
  TAG_VERSION: process.env.REACT_APP_TAG_VERSION || (window as any).TAG_VERSION,
  SITE_URL: process.env.REACT_APP_SITE_URL || (window as any).SITE_URL,
  PRO_WEB_URL: process.env.REACT_APP_PRO_WEB_URL || (window as any).PRO_WEB_URL,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL || (window as any).ADMIN_URL,
  API_URL: process.env.REACT_APP_API_URL || (window as any).API_URL,
  MAP_URL: process.env.REACT_APP_MAP_URL || (window as any).MAP_URL,
  PROTOCOL: process.env.REACT_APP_PROTOCOL || (window as any).PROTOCOL,
  KEYCLOAK_AUTH_URL:
    process.env.REACT_APP_KEYCLOAK_AUTH_URL ||
    (window as any).KEYCLOAK_AUTH_URL,
  KEYCLOAK_REALM:
    process.env.REACT_APP_KEYCLOAK_REALM || (window as any).KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID:
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID ||
    (window as any).KEYCLOAK_CLIENT_ID,
  GA_TRACKING_ID:
    process.env.REACT_APP_GA_TRACKING_ID || (window as any).GA_TRACKING_ID,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || (window as any).ENVIRONMENT,
  GOOGLE_API_KEY:
    process.env.REACT_APP_GOOGLE_API_KEY || (window as any).GOOGLE_API_KEY,
};

export default env;
