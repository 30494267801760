import { eachMonthOfInterval, format } from 'date-fns';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Container, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ToastError } from 'utils';
import { ProStatsSmsView } from './pro-stats-sms-received-view';
import { SmsReceivedCountByMonth } from './pro-stats-sms-received-repository';

type Props = {
  fetchSms: () => Promise<SmsReceivedCountByMonth>;
  startDate: Date;
  endDate: Date;
};

type ChartData = {
  Date: string;
  dateISO: string;
  'Sms uniques': number;
  Sms: number;
}[];

export const ProStatsSmsReceivedContainer: FunctionComponent<Props> = ({
  fetchSms,
  startDate,
  endDate,
}) => {
  const [chartData, setChartData] = useState<ChartData>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [uniqTotalCount, setUniqTotalCount] = useState<number>(0);
  const [error, setError] = useState();

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      const data = await fetchSms().catch((error) => {
        ToastError('Erreur', "Impossible d'afficher les stats d'sms");
        setError(error);
        throw error;
      });

      setTotalCount(
        data.reduce((accumulator, value) => accumulator + value.count, 0),
      );

      setUniqTotalCount(
        data.reduce((accumulator, value) => accumulator + value.uniqueCount, 0),
      );

      const months = eachMonthOfInterval({ start: startDate, end: endDate });

      const newChartData = months.map((month) => {
        const monthString = format(month, 'MM/yyyy');

        const monthData = data.find(
          (data) => format(new Date(data.month), 'MM/yyyy') === monthString,
        );

        if (monthData?.count) {
          return {
            Date: monthString,
            dateISO: format(month, 'yyyy-MM'),
            'Sms uniques': monthData.uniqueCount,
            Sms: monthData.count,
          };
        } else {
          return {
            Date: monthString,
            dateISO: format(month, 'yyyy-MM'),
            'Sms uniques': 0,
            Sms: 0,
          };
        }
      });

      newChartData.sort((a, b) => a.dateISO.localeCompare(b.dateISO));

      setChartData(newChartData);
    };
    fetch();
  }, [fetchSms]);

  if (error) return <>Error!</>;

  if (!chartData) {
    return (
      <Segment style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </Segment>
    );
  }

  if (totalCount === 0) {
    return (
      <Container fluid style={{ height: '576px' }}>
        <Header as="h2">Sms</Header>
        <Message>Pas de stats</Message>
      </Container>
    );
  }

  return (
    <ProStatsSmsView
      chartData={chartData}
      totalCount={totalCount}
      uniqTotalCount={uniqTotalCount}
    />
  );
};
