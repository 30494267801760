import { apiClient } from 'axios-client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { getToken } from 'utils';
import env from 'env';
import { Button, Label } from 'semantic-ui-react';

interface CallRecordingProps {
  conversationUuid?: string;
  duration: number;
}

export const CallRecording: FunctionComponent<CallRecordingProps> = ({
  conversationUuid,
  duration,
}) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handlePlay = useCallback(async () => {
    if (!conversationUuid || Number(duration) === 0) {
      setError(true);
      return;
    }

    const baseUrl = `${env.API_URL}/attachment/object-by-key-token`;
    const key = encodeURIComponent(`call-recordings/${conversationUuid}`);
    const audioUrl = `${baseUrl}?key=${key}&token=${getToken()}`;

    apiClient
      .head(audioUrl)
      .then((response) => {
        if (response.status === 200) {
          setAudioUrl(audioUrl);
          return;
        }
        setError(true);
      })
      .catch(() => {
        setError(true);
      });
  }, [conversationUuid]);

  useEffect(() => {
    setAudioUrl(null);
    setError(false);
  }, [conversationUuid]);

  return (
    <>
      {audioUrl ? (
        <audio controls>
          <source src={audioUrl} type="audio/mp3" />
          Votre navigateur ne supporte pas le lecteur audio.
        </audio>
      ) : null}
      {!audioUrl && !error ? <Button icon="play" onClick={handlePlay} /> : null}
      {error ? <Label>Indisponible</Label> : null}
    </>
  );
};
