import React, { FunctionComponent } from 'react';
import { DatumValue, Point } from '@nivo/line';

type SliceTooltipProps = {
  slice: {
    id: DatumValue;
    height: number;
    width: number;
    x0: number;
    x: number;
    y0: number;
    y: number;
    points: Point[];
  };
  sort?: boolean;
  evolution?: boolean;
};

const SliceTooltip: FunctionComponent<SliceTooltipProps> = ({
  slice,
  sort,
  evolution,
}) => {
  // we convert DatumValue to number, that's why we parseInt
  const evolutionPercent =
    slice.points.length > 1
      ? Math.round(
          ((parseInt(slice.points[1].data.y.toString()) -
            parseInt(slice.points[0].data.y.toString())) /
            parseInt(slice.points[1].data.y.toString())) *
            100,
        )
      : 0;

  if (sort) {
    slice.points.sort((a, b) => (a.data.y < b.data.y ? 1 : -1));
  }

  return (
    <div
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: '2px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
        padding: '5px 9px',
      }}
    >
      <span
        style={{ fontWeight: 'bold', display: 'block', textAlign: 'center' }}
      >
        {slice.points[0].data.x.toString()}
      </span>
      <table style={{ textAlign: 'left' }}>
        <tbody>
          {slice.points.map((point) => (
            <tr key={point.id}>
              <td>
                <span
                  style={{
                    display: 'block',
                    width: '12px',
                    height: '12px',
                    background: point.serieColor,
                    marginRight: '7px',
                  }}
                />
              </td>
              <td style={{ paddingRight: '12px' }}>{point.serieId}</td>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {point.data.y.toString()}
              </td>
            </tr>
          ))}
          {evolution && (
            <tr>
              <td></td>
              <td>Evolution</td>
              <td
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: evolutionPercent >= 0 ? 'green' : 'red',
                }}
              >
                {evolutionPercent}%
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SliceTooltip;
