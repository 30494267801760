import React, { FunctionComponent, useMemo } from 'react';
import {
  Button,
  Icon,
  Modal,
  FormField as FormFieldUi,
  Container,
  Label as LabelUi,
} from 'semantic-ui-react';
import { useFormikContext } from 'formik';
import FormField from 'components/form-field/form-field';
import { CityFieldBilikZoneQuery } from 'generated/graphql';
import FieldError from 'components/field-error/field-error';
import { CityFieldModalState } from './city-field-container';
import HelpText from 'components/help-text/help-text';
import { GeoJSON } from 'react-leaflet';
import Map from 'components/map/map';
import { toGeoJsonObject } from 'utils/geometry';
import { Geometry } from 'geojson';
import MapGeoJSON from 'components/map/map-geojson/map-geojson';
import MapDraw from 'components/map/map-draw/map-draw';
import Label from 'components/label/label';
import {
  LeafletMouseEvent,
  FeatureGroup as LeafletFeatureGroup,
  LatLngLiteral,
} from 'leaflet';
import { center } from '@turf/turf';

type CityFieldViewProps = {
  label?: string;
  labelCoordinates?: string;
  helpText?: string;
  buttonText?: string;
  bilikZone?: CityFieldBilikZoneQuery;
  cityFieldName: string;
  geometryFieldName: string;
  onPlaceSelected?: (value: google.maps.places.PlaceResult) => void;
  onOpenMapModal: () => void;
  modalState: CityFieldModalState;
  setModalState: React.Dispatch<Partial<CityFieldModalState>>;
  cityName?: string;
  shouldDisplayCities: boolean;
  onGeoCoordinatesChange: (event: LeafletMouseEvent) => void;
  onValidateModalChange: () => void;
  drawMode: 'Point' | 'Polygon';
  mapDrawRef: React.MutableRefObject<LeafletFeatureGroup | null>;
  geometry?: Geometry;
};

export const CityFieldView: FunctionComponent<CityFieldViewProps> = ({
  label,
  labelCoordinates,
  helpText,
  bilikZone,
  cityFieldName,
  buttonText,
  geometryFieldName,
  onPlaceSelected,
  onOpenMapModal,
  modalState,
  setModalState,
  cityName,
  shouldDisplayCities,
  onGeoCoordinatesChange,
  onValidateModalChange,
  drawMode,
  mapDrawRef,
  geometry,
}) => {
  const { errors, touched } = useFormikContext();

  const latlng: LatLngLiteral | undefined = useMemo(() => {
    if (geometry) {
      if (geometry.type === 'Point') {
        return {
          lat: geometry.coordinates[0],
          lng: geometry.coordinates[1],
        };
      }
      if (geometry.type === 'Polygon') {
        const polygonCenter = center(geometry);
        return {
          lat: polygonCenter.geometry.coordinates[0],
          lng: polygonCenter.geometry.coordinates[1],
        };
      }
    }
  }, [geometry]);

  return (
    <>
      <FormField
        type="place"
        required
        name={cityFieldName}
        helpText={
          helpText ||
          'Sélectionnez le lieu de la prestation ainsi que ses coordonnées'
        }
        label={label || 'Lieu de la prestation'}
        placeholder="Ex : Meylan"
        onPlaceSelected={onPlaceSelected}
        options={{
          componentRestrictions: {
            country: 'fr',
          },
          types: ['(regions)'],
        }}
      />
      <Button
        type="button"
        icon
        onClick={async (): Promise<void> => {
          await onOpenMapModal();
        }}
      >
        <Icon name="map marker alternate" />
        {drawMode === 'Polygon'
          ? 'Modifier la zone de la ville'
          : buttonText
          ? buttonText
          : 'Placer un point sur la carte'}
      </Button>
      <FormFieldUi style={{ marginTop: '15px' }}>
        <Label>
          {labelCoordinates
            ? labelCoordinates
            : drawMode === 'Polygon'
            ? 'Centre'
            : 'Coordonnées'}{' '}
          :{' '}
          <LabelUi size="large" style={{ marginLeft: '15px' }}>
            {latlng
              ? `${latlng.lat} lat / ${latlng.lng} lng `
              : 'non renseigné'}{' '}
          </LabelUi>
        </Label>
      </FormFieldUi>
      <FormFieldUi style={{ marginTop: '15px' }}>
        {errors[geometryFieldName] && touched[geometryFieldName] ? (
          <FieldError>{errors[geometryFieldName]}</FieldError>
        ) : null}
      </FormFieldUi>
      <Modal
        open={modalState.open}
        size="large"
        onClose={(): void => {
          setModalState({ open: false });
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Positionnez le marqueur sur la carte</Modal.Header>
        <Modal.Content>
          <Map
            eventHandlers={
              drawMode === 'Point'
                ? {
                    dblclick: onGeoCoordinatesChange,
                  }
                : {}
            }
            doubleClickZoom={false}
            style={{ width: '100%', height: '650px' }}
          >
            {modalState.bounds ? (
              <MapGeoJSON
                pmIgnore={true}
                data={modalState.bounds}
                interactive={false}
                style={{
                  fillOpacity: 0.0,
                  stroke: true,
                  color: '#ff0000',
                }}
              />
            ) : null}

            {drawMode === 'Polygon' ? (
              <MapDraw
                ref={mapDrawRef}
                options={{
                  position: 'bottomright',
                  drawPolygon: true,
                }}
              />
            ) : null}

            {modalState.geometry ? (
              <MapGeoJSON
                data={modalState.geometry}
                style={{
                  fillOpacity: 0.4,
                }}
              />
            ) : null}

            {bilikZone?.bilikZoneByPk?.cities.map((city) =>
              city.name !== cityName &&
              city.area !== null &&
              shouldDisplayCities ? (
                <GeoJSON
                  key={city.id}
                  pmIgnore={true}
                  data={toGeoJsonObject(city.area)}
                  interactive={false}
                  style={{
                    fillOpacity: 0.2,
                    stroke: false,
                  }}
                />
              ) : null,
            )}
          </Map>
          {modalState.error ? (
            <Container fluid textAlign="right">
              <FieldError>{modalState.error}</FieldError>
            </Container>
          ) : null}

          <Container fluid style={{ marginTop: '1em' }}>
            <HelpText>
              {drawMode === 'Point' ? (
                <>Double-Cliquez sur la carte pour positionner le marqueur</>
              ) : null}
              {drawMode === 'Polygon' ? (
                <>Utilisez les boutons en bas à droite pour dessiner la zone</>
              ) : null}
              {shouldDisplayCities && (
                <>
                  <br />
                  en bleu : sous-villes de la zone
                </>
              )}
            </HelpText>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              setModalState({ open: false });
            }}
          >
            Annuler
          </Button>
          <Button positive onClick={onValidateModalChange}>
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
