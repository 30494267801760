import { apiClient } from 'axios-client';
import {
  OrderBy,
  SolicitationBoolExp,
  useSolicitationsTableGloballyBlockedQuery,
  useSolicitationsTableQueryLazyQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';

import SolicitationsTableContainer from './solicitations-table-container';

type Props = {
  page?: number;
  orderBy: {
    column: string;
    direction: string;
  };
  onSort: (column: string, direction: string) => void;
  onFilter: (column: string, value: string) => void;
  where?: SolicitationBoolExp;
  isInProViewForm: boolean;
};

const SolicitationTableRepository: FunctionComponent<Props> = ({
  orderBy,
  page,
  onSort,
  onFilter,
  where,
  isInProViewForm,
}) => {
  const direction = useMemo(() => {
    switch (orderBy.direction) {
      case 'ascending': {
        return OrderBy.Asc;
      }
      case 'descending': {
        return OrderBy.Desc;
      }
    }
  }, [orderBy.direction]);

  const itemPerPage = useMemo(() => 10, []);

  const [solicitationsTableQuery, { data }] =
    useSolicitationsTableQueryLazyQuery();

  const fetchSolicitationsTable = useCallback(async () => {
    return solicitationsTableQuery({
      variables: {
        limit: itemPerPage,
        offset: page ? (page - 1) * itemPerPage : 0,
        order_by: [
          {
            [orderBy.column]: direction,
          },
        ],
        where,
      },
    });
  }, [where, direction, page]);

  const { data: globallyBlockedEntities } =
    useSolicitationsTableGloballyBlockedQuery({
      fetchPolicy: 'no-cache',
    });

  const resendMailToPro = useCallback(
    async (solicitationUuid: string) => {
      await apiClient
        .post(`/form/contact-pro/resend-mail-to-pro`, {
          uuid: solicitationUuid,
        })
        .catch((error) => {
          ToastError(
            'Erreur',
            'Impossible de renvoyer la demande au professionnel',
          );
          throw error;
        });

      fetchSolicitationsTable();

      ToastSuccess('Succès', 'Demande renvoyée au professionnel');
    },
    [fetchSolicitationsTable],
  );

  return (
    <SolicitationsTableContainer
      refetch={fetchSolicitationsTable}
      solicitations={data?.solicitation || []}
      orderBy={orderBy}
      onSort={onSort}
      onFilter={onFilter}
      isInProViewForm={isInProViewForm}
      globallyBlockedEntities={
        globallyBlockedEntities?.globallyBlockedIdentity || []
      }
      resendMailToPro={resendMailToPro}
    />
  );
};

export default SolicitationTableRepository;
