import * as Yup from 'yup';
import { isValidBIC, isValidIBAN } from 'ibantools';

export default (): Yup.ObjectSchema =>
  Yup.object().shape({
    iban: Yup.string()
      .required("L'IBAN est requis !")
      .test('is-iban-valid', "L'IBAN n'est pas valide !", (iban) => {
        if (!iban || iban === '') {
          return false;
        }
        return isValidIBAN(iban.toUpperCase().replace(/ /g, ''));
      }),
    bic: Yup.string()
      .required('Le BIC est requis !')
      .test('is-bic-valid', "Le BIC n'est pas valide !", (bic) => {
        if (!bic || bic === '') {
          return false;
        }
        return isValidBIC(bic.toUpperCase().replace(/ /g, ''));
      }),
  });
