import React, { FunctionComponent, useCallback } from 'react';

import TradeSearchFilterContainer from './trade-search-filter-container';
import {
  useTradeSearchFilterDefaultQuery,
  useTradeSearchFilterQueryLazyQuery,
} from 'generated/graphql';
import { SearchResultData } from 'semantic-ui-react';

type Props = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  tradeId?: number;
  onDelete?: () => void;
};

const TradeSearchFilterRepository: FunctionComponent<Props> = ({
  onResultSelect,
  tradeId,
  onDelete,
}) => {
  const [tradeSearchFilterQuery, { data }] =
    useTradeSearchFilterQueryLazyQuery();

  const fetchTrades = useCallback(
    async (criteria?: string) => {
      const variables = {
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        },
      };
      await tradeSearchFilterQuery(variables);
    },
    [tradeSearchFilterQuery],
  );

  const { data: defaultTradeData } = useTradeSearchFilterDefaultQuery({
    variables: {
      id: tradeId || 0, // TODO works, but is hacky
    },
    skip: !tradeId,
  });

  return (
    <TradeSearchFilterContainer
      onDelete={onDelete}
      onResultSelect={onResultSelect}
      trades={data?.searchTrade || []}
      fetchTrades={fetchTrades}
      defaultValue={defaultTradeData?.tradeByPk?.name}
    />
  );
};

export default TradeSearchFilterRepository;
