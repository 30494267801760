import { useFormikContext } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ToastError } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import { getFormErrorMessage } from 'utils/form-error-message';
import { flattenObject } from 'utils/flatten-object';

type ProSubmitButtonProps = {
  disabled?: boolean;
  overridedHandleSubmit?: () => void;
};

const ProSubmitButton: FunctionComponent<ProSubmitButtonProps> = ({
  disabled,
  overridedHandleSubmit,
}) => {
  const { isSubmitting, isValidating, validateForm, handleSubmit } =
    useFormikContext<ProFormUpdateFields>();

  const onSubmit = useCallback(async (): Promise<void> => {
    const proFormFieldErrors = await validateForm();
    const errorValues = Object.values(
      flattenObject(proFormFieldErrors),
    ) as string[];

    if (errorValues.length > 0) {
      const errorMsg = getFormErrorMessage(errorValues);

      ToastError("Impossible d'enregistrer la fiche pro", errorMsg);
    } else {
      if (overridedHandleSubmit) {
        overridedHandleSubmit();
      } else {
        handleSubmit();
      }
    }
  }, [overridedHandleSubmit]);

  return (
    <Button
      type="submit"
      primary
      loading={isSubmitting || isValidating}
      disabled={isSubmitting || isValidating || disabled}
      onClick={onSubmit}
    >
      <Icon name="save" />
      Enregistrer
    </Button>
  );
};

export default ProSubmitButton;
