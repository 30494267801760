import React, { FunctionComponent, useCallback } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import { formatDateTime, formatTelephone } from 'utils';
import { Table, Icon, Dimmer, Loader, Popup, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CallsTableGloballyBlockedQuery } from 'generated/graphql';
import Raw from 'components/raw/raw';
import ProFullName from 'components/pro-full-name/pro-full-name';

type CallsTableViewProps = {
  smsReceivedList: MongoEvent[];
  loading: boolean;
  filters: {
    currentBilikZoneId?: number;
    proViewId?: number;
  };
  orderBy;
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
  globallyBlocked: CallsTableGloballyBlockedQuery['globallyBlockedIdentity'];
};

const SmsReceivedTableView: FunctionComponent<CallsTableViewProps> = ({
  smsReceivedList,
  filters,
  orderBy,
  loading,
  onSort,
  onFilter,
  globallyBlocked,
}) => {
  const onSortByDateClicked = useCallback((): void => {
    if (onSort) {
      onSort(
        'date',
        orderBy?.direction === 'ascending' ? 'descending' : 'ascending',
      );
    }
    if (onFilter) {
      onFilter('page', '1');
    }
  }, [onSort, onFilter, orderBy?.direction]);

  const isGloballyBlocked = useCallback((sms: MongoEvent): boolean => {
    return globallyBlocked.some(
      (value) => value.identifier.split(':')[1] === sms.initiator.pi?.telephone,
    );
  }, []);

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={orderBy?.direction}
              onClick={onSortByDateClicked}
            >
              Date du sms
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Particulier
            </Table.HeaderCell>
            {!filters.proViewId ? (
              <>
                <Table.HeaderCell className="not sortable">
                  Professionnel
                </Table.HeaderCell>
              </>
            ) : null}
            <Table.HeaderCell className="not sortable">
              Message
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {smsReceivedList.map((sms: MongoEvent, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <p>{formatDateTime(sms.date)}</p>
              </Table.Cell>
              <Table.Cell>
                <p>
                  <Popup
                    position="top center"
                    trigger={
                      <Link
                        to={`/timeline/${encodeURIComponent(
                          sms.initiator.pi.telephone,
                        )}`}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <Icon fitted name="time" />
                        <Icon fitted name="long arrow alternate down" />
                      </Link>
                    }
                    content="Timeline du particulier"
                  />
                  &nbsp; &nbsp;
                  {formatTelephone(sms.initiator.pi.telephone)}
                  &nbsp; &nbsp;
                  {isGloballyBlocked(sms) ? (
                    <Popup
                      position="top center"
                      trigger={<Icon color="red" name="dont" />}
                      content="Bloqué"
                    />
                  ) : null}
                </p>
                {sms.data.isSpam ? (
                  <Popup
                    position="top center"
                    trigger={<Label color="grey">Non transmis (spam)</Label>}
                    content="Le sms contient un lien non reconnu, un mot interdit ou est un copier-coller répétitif et n'a donc pas transmit au professionnel."
                  />
                ) : sms.data.isFirstContact ? (
                  <Popup
                    position="top center"
                    trigger={
                      <Label color="grey">Non transmis (premier contact)</Label>
                    }
                    content="Le particulier doit établir un premier contact via un appel ou une demande mail"
                  />
                ) : null}
              </Table.Cell>
              {!filters.proViewId ? (
                <Table.Cell verticalAlign="top">
                  <Link
                    to={`/pros/update/${String(
                      sms.actors.pro.proView.id,
                    ).replace('/pro_views/', '')}/sms-received`}
                    onClick={(): void => {
                      if (onFilter) {
                        onFilter(
                          'proViewId',
                          String(sms.actors.pro.proView.id),
                        );
                        onFilter('page', '1');
                      }
                    }}
                  >
                    <ProFullName
                      name={sms.actors.pro.proPresentation.name}
                      mainCityCode={
                        !filters.currentBilikZoneId
                          ? sms.actors?.pro?.bilikZone.mainCityCode
                          : undefined
                      }
                      mainTradeName={
                        sms.actors.pro.proPresentation.mainTrade?.name
                      }
                    />
                  </Link>
                  <br />
                </Table.Cell>
              ) : null}
              <Table.Cell verticalAlign="top" style={{ maxWidth: '700px' }}>
                <p>
                  <Raw>{sms.data.vonage?.text ?? sms.data.twilio?.Body}</Raw>
                </p>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default SmsReceivedTableView;
