import { CallsTable } from 'components/calls-table';
import { useCallsCount } from 'hooks/use-calls-count/use-calls-count';
import React, { FunctionComponent, useMemo } from 'react';
import { Container, Icon, Pagination } from 'semantic-ui-react';
import { NumberParam, useQueryParam } from 'use-query-params';

type ProCallsRouteProps = {
  proViewId: number;
};

// This component do nothing but we keep it to fit the pro-router structure
const ProCallsRoute: FunctionComponent<ProCallsRouteProps> = ({
  proViewId,
}) => {
  const itemPerPage = useMemo(() => 30, []);
  const [page, setPage] = useQueryParam('page', NumberParam);

  const { count } = useCallsCount({ proViewId: proViewId });

  const totalPages = useMemo(
    () => Math.ceil((count ? count : 0) / itemPerPage),
    [count],
  );
  return (
    <>
      <CallsTable
        skip={page ? itemPerPage * (page - 1) : 0}
        limit={itemPerPage}
        filters={{ proViewId }}
      />
      {totalPages > 1 ? (
        <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
          <Pagination
            activePage={page ?? 1}
            totalPages={totalPages}
            onPageChange={(event, { activePage }): void => {
              setPage(Number(activePage));
            }}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
          />
        </Container>
      ) : null}
    </>
  );
};

export default ProCallsRoute;
