import { EventEmitter } from 'events';

export class Channel extends EventEmitter {
  constructor(name, ws) {
    super();
    this.open = false;
    this.name = name;
    this.ws = ws;
  }

  ready(data) {
    this.open = true;
    this.emit('ready', data);
  }

  close() {
    this.open = false;
    this.emit('close');
  }

  unsubscribe() {
    this.ws.send(
      JSON.stringify({
        event: 'internal:unsubscribe',
        data: { unsubscribeChannel: this.name },
      }),
    );
  }

  metadata(data) {
    this.emit('metadata', data);
  }

  send(event, data) {
    if (typeof event !== 'string' || typeof data !== 'object') {
      throw new Error(
        'The channel expect string type for event and object type for data.',
      );
    }

    if (!this.open) {
      console.warn('Impossible to send from a closed channel.');
    } else if (this.name.startsWith('presence-')) {
      if (!data || !data.id) {
        throw new Error('`data.Id is required in a presence channel.`');
      } else {
        this.ws.send(
          JSON.stringify({
            event,
            channel: this.name,
            info: data.info || {},
            id: data.id,
          }),
        );
      }
    } else {
      this.ws.send(
        JSON.stringify({
          event,
          channel: this.name,
          data,
        }),
      );
    }
  }

  message({ event, ...rest }) {
    this.emit(event, rest);
  }
}

export class WebSocketEvents extends EventEmitter {
  constructor(connectionURI) {
    super();
    this.init(connectionURI);
  }

  init(connectionURI) {
    this.ws = new WebSocket(connectionURI);
    this.ws.onopen = () => this.emit('ready');
    this.ws.onerror = (event) => {
      // Probably a server closed.
      if (this.ws.readyState === 3) {
        this.emit('error', event);
      } else {
        this.emit('error', event);
      }
    };
    this.ws.onclose = (data) => this.emit('close', data);
    this.ws.onmessage = (message) => this.emit('message', message);
  }
}

export class WSWrapper extends WebSocketEvents {
  constructor(options, autoReconnect) {
    if (!options.url) {
      throw new Error(
        'You need to enter an url in order to connect to ws server.',
      );
    }
    if (!options.jwt) {
      throw new Error('A token is required (options.jwt).');
    }
    super(`${options.url}?token=${options.jwt}`);
    this.URI = `${options.url}?token=${options.jwt}`;
    this.options = options;
    this.subscribedChannels = [];
    this.on('message', (message) => {
      if (message.data === 'ping') {
        this.ws.send('pong');
      } else {
        const parsedData = JSON.parse(message.data);
        if (parsedData.channel) {
          // eslint-disable-next-line consistent-return
          this.subscribedChannels.forEach((channel, i) => {
            if (channel.name === parsedData.channel && parsedData.event) {
              const { event } = parsedData;
              switch (event) {
                case 'internal:subscribed':
                  return channel.ready(parsedData.data);
                case 'internal:unsubscribed':
                  channel.close();
                  return this.subscribedChannels.splice(i, 1);
                default:
                  if (channel.name.startsWith('presence-')) {
                    return channel.message({ event, members: parsedData.info });
                  }

                  return channel.message({ event, data: parsedData.data });
              }
            }
          });
        }
      }
    });
    if (autoReconnect) {
      this.autoReconnect = true;
      this.on('close', () => {
        if (this.autoReconnect) {
          this.subscribedChannels = [];
          setTimeout(() => {
            this.init(this.URI);
          }, 1000);
        }
      });
    }
  }

  subscribe(channelName, id, info) {
    if (typeof channelName !== 'string') {
      throw new Error('channelName must be a string');
    }

    if (
      this.subscribedChannels.filter((channel) => channel.name === channelName)
        .length > 0
    ) {
      console.warn(`Cannot subscribe multiple time to: ${channelName}`);
      return;
    }

    if (channelName.startsWith('presence-')) {
      if (id === undefined) {
        throw new Error('Id must be set in presence channel (presence-).');
      }
    }

    const channel = new Channel(channelName, this.ws);
    this.subscribedChannels.push(channel);
    // delay a bit so we can listen to a response.
    setTimeout(
      this.ws.send(
        JSON.stringify({
          event: 'internal:subscribe',
          data: {
            subscribeChannel: channelName,
            id,
            info: info === undefined ? {} : info,
          },
        }),
      ),
      0,
    );
    return channel;
  }

  close() {
    this.subscribedChannels = [];
    this.ws.close();
  }
}

export default WSWrapper;
