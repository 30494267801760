import {
  FunctionComponent,
  Fragment,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';

import React from 'react';
import styled from 'styled-components';
import { range } from 'remeda';
import { ProFormUpdateFields } from 'pages/pros/pro-form/pro-form-update-fields-type';
import { useFormikContext } from 'formik';
import { useProPreviewGuideQuery } from 'generated/graphql';
import { Icon } from 'semantic-ui-react';

const ProPreviewGuideStyle = styled.div`
  width: 100%;
  padding: 10px;
  color: #000;
  text-align: center;
  position: relative;

  .overflow-border {
    position: absolute;
    height: 565px;
    width: 226.5px;

    &.good {
      outline: 4px dashed #00A65A;
    }

    &.bad {
      outline: 4px dashed #f00;
    }
  }

  .expertises {
    font-family: 'Poppins Regular';
    text-align: left;
    height: 565px;
    width: 226px;
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px dashed grey;
  }

  ul {
    font-family: 'Poppins Regular';
    margin-top: 0;
    padding-left: 18px;
    font-size: 15px;
    line-height: 18px
    margin-bottom: 5px;
    list-style: outside;

    li {
      vertical-align: middle;

      span {
        font-family: 'Poppins Regular';
        position: relative;
        left: -8px;
      }
    }
  }

  h1 {
    font-family: 'Poppins Semibold';
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 2px;
    line-height: 26px;
    color: #eb5a2c;
  }

  h2 {
    font-family: 'Poppins Regular';
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 6px;
    line-height: 24px;
  }

  .place {
    margin-bottom: 24px;

    .city {
      font-family: 'Poppins Regular';
      font-size: 12px;
      line-height: 8px;
      margin-bottom: -4px;
    }

    .showroom {
      font-family: 'Poppins Italic';
      font-size: 10px;
      line-height: 8px;
      margin-left: 18px;
    }

  }


  h3 {
    font-family: 'Poppins Semibold';
    font-size: 15px;
    line-height: 18px;
    margin : 0;
  }
`;

const ProPreviewGuide: FunctionComponent<{ proViewId: number }> = ({
  proViewId,
}) => {
  const { values, setFieldValue } = useFormikContext<ProFormUpdateFields>();

  const containerRef = useRef<HTMLDivElement>(null);

  const [isFitInGuide, setIsFitInGuide] = useState<boolean>(true);

  const { data } = useProPreviewGuideQuery({
    variables: {
      proViewId,
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const el = containerRef.current;

    // Check if container child elements are bigger than container size
    const isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    setIsFitInGuide(!isOverflowing);
  }, [containerRef, values, data?.proViewByPk]);

  useEffect(() => {
    setFieldValue('isFitInGuide', isFitInGuide);
  }, [isFitInGuide]);

  //! If you Edit this function, you should also edit the same function in the Export guide file generate-xml.ts
  const proPeopleText = useMemo(() => {
    if (!data?.proViewByPk?.proPeople) {
      return '';
    }

    const contacts = data?.proViewByPk?.proPeople
      .filter(({ proPerson }) => {
        const proPersonName = `${proPerson.givenName} ${proPerson.familyName}`;

        if (proPersonName !== values.proPresentation.name) {
          return true;
        }
      })
      .map(({ proPerson }) => proPerson);

    // Group contacts by family name
    const grouped = contacts.reduce((groups, contact) => {
      (groups[contact.familyName] = groups[contact.familyName] || []).push(
        contact.givenName,
      );
      return groups;
    }, {} as Record<string, string[]>);

    // If there are more than 1 contact with different family name, format all names with initial on givenName
    if (contacts.length > 1 && Object.keys(grouped).length > 1) {
      return contacts
        .map((contact) => `${contact.givenName[0]}. ${contact.familyName}`)
        .join(', ')
        .trim();
    }

    // Format names for each family
    const formattedNames = Object.entries(grouped).map(
      ([familyName, givenNames]) => {
        const lastGivenName = givenNames.pop();
        return `${givenNames.join(', ')}${
          givenNames.length ? ' &' : ''
        } ${lastGivenName} ${familyName}`;
      },
    );

    // Return formatted names
    return formattedNames.join(', ').trim();
  }, [data?.proViewByPk]);

  return (
    <>
      <ProPreviewGuideStyle>
        <div className="expertises" ref={containerRef}>
          <div
            className={`overflow-border ${isFitInGuide ? 'good' : 'bad'}`}
          ></div>
          <h1>{values.proPresentation.name}</h1>
          <h2>{proPeopleText}</h2>
          <div className="place">
            {values.isLocalBusiness ? (
              <>
                <div className="city">
                  <Icon name="home" />
                  {values.proPostalAddress?.city}
                </div>
                <span className="showroom">Showroom / Commerce</span>
              </>
            ) : (
              <div className="city">
                <Icon name="map marker alternate" />
                {values.proPostalAddress?.displayOption === 'none'
                  ? `Intervient à ${data?.proViewByPk?.bilikZone?.guideName}`
                  : values.proPostalAddress?.city}
              </div>
            )}
          </div>
          {range(1, 4).map((i) => (
            <Fragment key={i}>
              <h3>{values.proPresentation[`expertise${i}`]}</h3>
              <ul>
                {range(1, 7).map((j) => {
                  if (!values.proPresentation[`expertise${i}${j}`]) {
                    return null;
                  }

                  return (
                    <li key={j}>
                      <span>{values.proPresentation[`expertise${i}${j}`]}</span>
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          ))}
        </div>
      </ProPreviewGuideStyle>
    </>
  );
};

export default ProPreviewGuide;
