import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import { ProStatsSmsReceivedContainer } from './pro-stats-sms-received-container';

type Props = {
  startDate: Date;
  endDate: Date;
  proViewId: number;
};

export type SmsReceivedCountByMonth = {
  month: Date;
  count: number;
  uniqueCount: number;
}[];

export const ProStatsSmsReceivedRepository: FunctionComponent<Props> = ({
  startDate,
  endDate,
  proViewId,
}) => {
  const fetchSms = useCallback(
    async () =>
      await apiClient
        .post<SmsReceivedCountByMonth>(`/event/pro-sms-received-by-month`, {
          proViewId,
          after: startDate,
          before: endDate,
          isSpamExcluded: true,
          isFirstContactExcluded: true,
        })
        .then((response) => {
          return response.data;
        }),
    [startDate, endDate],
  );

  return (
    <ProStatsSmsReceivedContainer
      startDate={startDate}
      endDate={endDate}
      fetchSms={fetchSms}
    />
  );
};
