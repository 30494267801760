import {
  useProReviewUpdateByPkMutation,
  useReviewLockerQuery,
} from 'generated/graphql';
import { useCallback, useEffect, useState } from 'react';
import { ToastError, ToastSuccess } from 'utils';

export const useReviewLocker = (proReviewId: number) => {
  const [isLock, setIsLock] = useState(false);
  const [updateByPkProReview, { loading: loadingMutation }] =
    useProReviewUpdateByPkMutation();

  const { data, loading, refetch } = useReviewLockerQuery({
    variables: {
      proReviewId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const lockerId = data?.proReviewByPk?.lockerId;
    setIsLock(!!lockerId);
  }, [data?.proReviewByPk?.id]);

  const lock = useCallback(
    async (bilikPersonId: number) => {
      const proReview = await refetch();
      if (proReview.data.proReviewByPk?.lockerId) {
        ToastError('Erreur', 'Avis déjà verrouillé');
        return;
      }

      const updatedProReview = await updateByPkProReview({
        variables: {
          proReviewId,
          lockerDate: new Date(),
          lockerId: bilikPersonId,
        },
      }).catch((error) => {
        ToastError('Erreur', 'Erreur lors de la demande de vérouillage');
        throw error;
      });

      setIsLock(!!updatedProReview.data?.updateProReviewByPk?.id);
      ToastSuccess('Succès', 'Avis verrouillé');
    },
    [proReviewId],
  );

  const unlock = useCallback(async () => {
    const proReview = await refetch();
    if (!proReview.data.proReviewByPk?.lockerId) return;

    const updatedProReview = await updateByPkProReview({
      variables: {
        proReviewId,
        lockerDate: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lockerId: null,
      },
    }).catch((error) => {
      ToastError('Erreur', 'Erreur lors de la demande de dévérouillage');
      throw error;
    });

    setIsLock(!updatedProReview.data?.updateProReviewByPk?.id);
    ToastSuccess('Succès', 'Avis déverrouillé');
  }, [proReviewId]);

  return { isLock, lock, unlock, loading: loading || loadingMutation };
};
