import { apiClient } from 'axios-client';
import DocumentsUpload from 'components/documents-upload/documents-upload';
import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  Button,
  Modal,
  Grid,
  Icon,
  Progress,
  Form,
  Divider,
} from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import { UploadProgress } from '../pro-documents/pro-documents';
import { FormField } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';
import HelpText from 'components/help-text/help-text';
import Label from 'components/label/label';

type Document = {
  file: File;
  type: string;
};
type ProMandateUploadModalProps = {
  proOrganizationId: number;
  proMandate?: {
    id: number;
    fileName: string;
  };
  onProMandateUploaded?: (proMandateId: number, dateSigned: Date) => void;
  onClose: () => void;
  open: boolean;
};

const ProMandateUploadModal: FunctionComponent<ProMandateUploadModalProps> = ({
  proOrganizationId,
  proMandate,
  onProMandateUploaded,
  onClose,
  open,
}) => {
  const [document, setDocument] = useState<Document | undefined>(undefined);

  const [dateSigned, setDateSigned] = useState<Date>(new Date());

  const [uploadProgress, setUploadProgress] = useState<UploadProgress>();

  const uploadProDocuments = useCallback(async () => {
    const formData = new FormData();

    if (document && proMandate) {
      // Create new Document to rename it like the mandate
      const newDocument = new File([document.file], proMandate.fileName, {
        type: document.file.type,
      });

      formData.append('documents', newDocument);
      formData.append('documentKinds', 'mandate');
      formData.append('proOrganizationId', String(proOrganizationId));

      await apiClient.post(`/attachment/upload-pro-documents`, formData, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(progressEvent as unknown as UploadProgress);
        },
      });

      if (onClose) {
        onClose();
      }

      if (onProMandateUploaded) {
        onProMandateUploaded(proMandate.id, dateSigned);
      }

      setDocument(undefined);
      setUploadProgress(undefined);

      ToastSuccess('Succès', 'Mandat signé ajouté');
    } else {
      ToastError('Erreur', 'Veuillez ajouter un Document avant');
    }
  }, [proMandate, document, proOrganizationId, dateSigned]);

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
    >
      <Modal.Header>Ajouter le mandat signé</Modal.Header>
      <Modal.Content>
        {document ? (
          <>
            <Grid>
              <Grid.Row columns="3">
                <Grid.Column verticalAlign="bottom">
                  <p>{document.file.name}</p>
                </Grid.Column>
                <Grid.Column verticalAlign="bottom">type: Mandat</Grid.Column>
                <Grid.Column verticalAlign="bottom" textAlign="right">
                  <Button
                    icon
                    negative
                    type="button"
                    basic
                    onClick={(): void => setDocument(undefined)}
                  >
                    <Icon name="trash alternate outline" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Form>
              <FormField>
                <Label>Date de signature</Label>
                <HelpText>
                  Sélectionner la date de signature présente dans le document
                </HelpText>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  locale={fr}
                  selected={dateSigned}
                  onChange={(date: Date) => setDateSigned(date)}
                />
              </FormField>
            </Form>
          </>
        ) : (
          <DocumentsUpload
            onDocumentsChanged={(documents): void => {
              if (documents.length === 0) {
                setDocument(undefined);
                return;
              }
              setDocument(documents[0]);
            }}
            accept={{
              'application/*': ['.pdf'],
            }}
            label="Ajouter le mandat signé (format PDF uniquement)"
            maxFiles={1}
          />
        )}
        {uploadProgress ? (
          <Progress
            precision={1}
            value={uploadProgress.loaded}
            total={uploadProgress.total}
            progress="percent"
            color="teal"
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose}>
          Fermer
        </Button>
        <Button
          type="button"
          disabled={!document}
          primary
          onClick={() => uploadProDocuments()}
        >
          Ajouter
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProMandateUploadModal;
