import styled from 'styled-components';
const height = window.innerHeight - 101;

const Content = styled.div`
  min-height: ${height}px;
  background-color: #ecf0f5;
  z-index: 800;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: ${(props): string => (props.noMargin ? '0' : '230px')};
`;

export default Content;
