import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { CityFormFields } from './city-form-fields.type';
import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import SelectBilikZoneField from 'components/select-bilik-zone-field/select-bilik-zone-field';
import { CityField } from 'components/city-field';
import slugify from 'slugify';

const CityFormView: FunctionComponent = () => {
  const { handleReset, handleSubmit, setFieldValue, isSubmitting, values } =
    useFormikContext<CityFormFields>();

  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <Segment.Group>
        <Segment color="blue">
          <Header>Ville</Header>
        </Segment>
        <Segment>
          <CityField
            onPlaceSelected={(place: google.maps.places.PlaceResult) => {
              if (place.address_components) {
                setFieldValue(
                  'slug',
                  slugify(place.address_components[0].short_name, {
                    lower: true,
                    // we ignore following characters (*,+,~, ...etc)
                    remove: /[*+~.()'"!:@]/g,
                    locale: 'fr',
                  }),
                );
              }
            }}
            cityFieldName="name"
            geometryFieldName="area"
            drawMode="Polygon"
            bilikZoneId={values.bilikZoneId}
          />
          <FormField
            type="text"
            label="Slug"
            name="slug"
            placeholder="Ex : meylan"
            helpText="Le slug est la version normalisée du nom. Il ne contient généralement que des lettres minuscules non accentuées, des chiffres et des traits d’union."
            required
          />
          <FormField
            type="number"
            label="GeoNameId"
            name="geoNameId"
            placeholder="Ex : 6 433 360"
            helpText={`Permet de faire la liaison entre la ville référencée en base de données et la ville de l'index Algolia, à récupérer dans le champ "objectId" de la ville correspondante dans l'index "city" sur Algolia`}
            required
          />
          <FormField
            type="number"
            label="Population"
            name="population"
            placeholder="Ex : 10000"
            helpText="A récupérer sur google ou dans l'index Algolia"
            required
          />
          <SelectBilikZoneField
            placeholder="Ex: GRE"
            label="Zone"
            helpText="Selectionner la zone de la ville"
            name="bilikZoneId"
            clearable
          />
        </Segment>
      </Segment.Group>
      <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
        <Button
          type="submit"
          primary
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Container>
    </Form>
  );
};

export default CityFormView;
