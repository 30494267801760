import { apiClient } from 'axios-client';
import { SolicitationsTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { Unpacked } from 'utils/unpacked';
import { ProBouncedSolicitationContainer } from './pro-bounced-solicitation-container';

type ProBouncedSolicitationRepositoryProps = {
  solicitation: Unpacked<SolicitationsTableQuery['solicitation']>;
  refetchSolicitations: () => void;
  resendMailToPro: (uuid: string) => Promise<void>;
};

export const ProBouncedSolicitationRepository: FunctionComponent<
  ProBouncedSolicitationRepositoryProps
> = ({ solicitation, refetchSolicitations, resendMailToPro }) => {
  const reactivateEmailInPostmarkRequest = useCallback(async () => {
    await apiClient
      .post(`/form/contact-pro/reactivate-postmark-email`, {
        accountEmail: solicitation.proView.mainAccount?.email,
      })
      .then((response) => {
        if (response.data === 'development') {
          ToastSuccess(
            'Succès',
            "Vous êtes en mode développement, l'email n'a donc pas été réactivé dans Postmark",
          );
        } else {
          ToastSuccess('Succès', "l'email du pro est réactivée");
        }
      })
      .catch((error) => {
        ToastError(
          'Erreur',
          `Impossible de réactiver le mail du professionnel : ${error}`,
        );
      });
  }, [solicitation]);

  if (!solicitation.proView.mainAccount) {
    return <>error</>;
  }

  return (
    <ProBouncedSolicitationContainer
      refetchSolicitations={refetchSolicitations}
      reactivateEmailInPostmarkRequest={reactivateEmailInPostmarkRequest}
      mainAccountId={solicitation.proView.mainAccount.id}
      resendMailToPro={resendMailToPro}
      solicitation={solicitation}
    />
  );
};
