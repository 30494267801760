import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { ToastError, ToastInfo, ToastSuccess } from 'utils';

type SearchEngineUpdateCardProps = Record<string, unknown>;

export const SearchEngineUpdateCard: FunctionComponent<
  SearchEngineUpdateCardProps
> = ({}) => {
  const onClickSearchEngineUpdate = useCallback(async (): Promise<void> => {
    ToastInfo(
      'Info',
      'mise à jour des données du moteur de recherche en cours...',
    );
    await apiClient
      .post(`/form/admin-utilities/update-search-engine`)
      .catch((error) => {
        if (error.status === 'Request failed with status code 504') {
          ToastInfo(
            'Info',
            'Request timeout, mise à jour des données du moteur de recherche toujours en cours...',
          );
        } else {
          ToastError(
            'Erreur',
            'Impossible de mettre à jour le moteur de recherche',
          );
          throw error;
        }
      });

    ToastSuccess(
      'Succès',
      'les données du moteur de recherche ont été mises à jour',
    );
  }, []);

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="search" />
        <Card.Header>Mettre à jour l&apos;index Algolia</Card.Header>
        <Card.Description>
          Mettre à jour l&apos;index Algolia à partir des données en base de
          donnée (moteur de recherche).
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button fluid primary onClick={onClickSearchEngineUpdate}>
          Executer
        </Button>
      </Card.Content>
    </Card>
  );
};
