import { ProViewBoolExp, useProViewsCountQuery } from 'generated/graphql';

type ProViewsCounter = {
  count?: number;
  loading: boolean;
};

export const useProViewsCount = (
  where?: ProViewBoolExp,
  criteria?: string,
): ProViewsCounter => {
  const { data, loading } = useProViewsCountQuery({
    variables: {
      where,
      criteria:
        criteria
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase() ?? '',
    },
  });

  return {
    loading,
    count: data?.searchProViewAggregate.aggregate?.count,
  };
};
