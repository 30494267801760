import { BilikZonesTableQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Icon,
  Label,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';

type BilikZonesTableProps = {
  loading: boolean;
  bilikZones: BilikZonesTableQuery['bilikZone'];
};

const BilikZonesTableView: FunctionComponent<BilikZonesTableProps> = ({
  bilikZones,
  loading,
}) => {
  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            <Table.HeaderCell>Villes</Table.HeaderCell>
            <Table.HeaderCell>Responsable(s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {bilikZones.map((bilikZone) => (
            <Table.Row
              active={bilikZone.status === 'archived'}
              key={bilikZone.id}
            >
              <Table.Cell>
                <Icon
                  name="circle"
                  color={bilikZone.status === 'archived' ? 'grey' : 'green'}
                  size="small"
                />
                &nbsp;
                {bilikZone.name}
              </Table.Cell>
              <Table.Cell>
                {(bilikZone.cities || []).map((city) => (
                  <Label key={city.id} color="grey">
                    {city.name}
                  </Label>
                ))}
              </Table.Cell>
              <Table.Cell>
                {bilikZone.managers
                  .map(
                    (manager) =>
                      `${manager.bilikPerson.givenName} ${manager.bilikPerson.familyName}`,
                  )
                  .join(', ')}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Link to={`/bilik_zones/update/${bilikZone.id}`}>
                  <Popup
                    content="Modifier"
                    position="top center"
                    trigger={
                      <Button primary icon>
                        <Icon name="pencil alternate" />
                      </Button>
                    }
                  />
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default BilikZonesTableView;
