import {
  Grid,
  Segment,
  Statistic,
  Loader,
  Label,
  Divider,
  Container,
  Popup,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo } from 'react';
import { subMonths, subYears } from 'date-fns';
import { useSolicitationsCount } from 'hooks/use-solicitations-count/use-solicitations-count';
import { useCallsCount } from 'hooks/use-calls-count/use-calls-count';
import { useProReviewInvitationsCount } from 'hooks/use-pro-review-invitations-count/use-pro-review-invitations-count';
import { useSmsReceivedCount } from 'hooks/use-sms-received-count/use-sms-received-count';
import { useProViewsCount } from 'hooks/use-pro-views-count/use-pro-views-count';
import { useProReviewsCount } from 'hooks/use-pro-reviews-count/use-pro-reviews-count';
import useLocalStorage from 'hooks/use-local-storage/use-local-storage';

type FilterName =
  | 'last-month'
  | 'last-3-months'
  | 'last-6-months'
  | 'last-year'
  | 'all';

type Filter = {
  beforeDate: Date;
  afterDate?: Date;
};

type GlobalStatsProps = {
  filters?: {
    tradeId?: number;
    proViewIds?: number[];
    bilikZoneId?: number;
  };
};

export const GlobalStats: FunctionComponent<GlobalStatsProps> = ({
  filters,
}) => {
  // Persist activeDateFilter in local storage
  const [activeDateFilter, setActiveDateFilter] = useLocalStorage<FilterName>(
    // I added V2 to avoid crash for people who have the old version in their local storage
    // (V2 can be removed when this code is deployed to production)
    'activeDateFilterV2',
    'last-month',
  );

  const filter: Filter = useMemo(() => {
    switch (activeDateFilter) {
      case 'last-month':
        return {
          beforeDate: new Date(),
          afterDate: subMonths(new Date(), 1),
        };
      case 'last-3-months':
        return {
          beforeDate: new Date(),
          afterDate: subMonths(new Date(), 3),
        };
      case 'last-6-months':
        return {
          beforeDate: new Date(),
          afterDate: subMonths(new Date(), 6),
        };
      case 'last-year':
        return {
          beforeDate: new Date(),
          afterDate: subYears(new Date(), 1),
        };
      case 'all':
        return {
          beforeDate: new Date(),
          afterDate: undefined,
        };
    }
  }, [activeDateFilter]);

  const proViewFilters = useMemo(
    () => ({
      bilikZone: {
        id: {
          _eq: filters?.bilikZoneId,
        },
      },
      proPresentation: {
        _or: [
          {
            mainTradeId: {
              _eq: filters?.tradeId,
            },
          },
          {
            secondaryTradeId: {
              _eq: filters?.tradeId,
            },
          },
        ],
      },
      status: {
        _neq: 'draft',
      },
    }),
    [filters?.bilikZoneId, filters?.tradeId],
  );

  // ProPublishedCount
  const { loading: publishedProLoading, count: publishedProCount } =
    useProViewsCount({
      ...proViewFilters,
      datePublished: {
        _gte: filter.afterDate,
        _lt: filter.beforeDate,
        _isNull: false,
      },
    });

  // ProArchivedCount
  const { loading: archivedProLoading, count: archivedProCount } =
    useProViewsCount({
      ...proViewFilters,
      dateArchived: {
        _gte: filter.afterDate,
        _lt: filter.beforeDate,
        _isNull: false,
      },
      datePublished: {
        _isNull: false, // Do not count pro that are never be published
      },
    });

  // Pro count
  const proLoading = useMemo(() => {
    return publishedProLoading || archivedProLoading;
  }, [publishedProLoading, archivedProLoading]);

  const proCount = useMemo(() => {
    return (publishedProCount ?? 0) - (archivedProCount ?? 0);
  }, [publishedProCount, archivedProCount]);

  const { loading: publishedProReviewLoading, count: publishedProReviewCount } =
    useProReviewsCount({
      status: { _eq: 'ok' },
      isSpam: { _eq: false },
      source: { _eq: 'BILIK' },
      proPresentation: {
        proViews: {
          bilikZone: {
            id: {
              _eq: filters?.bilikZoneId,
            },
          },
          proPresentation: {
            _or: [
              {
                mainTradeId: {
                  _eq: filters?.tradeId,
                },
              },
              {
                secondaryTradeId: {
                  _eq: filters?.tradeId,
                },
              },
            ],
          },
          status: { _eq: 'published' },
        },
      },
      dateCreated: {
        _gte: filter.afterDate,
        _lt: filter.beforeDate,
      },
    });

  const proReviewInvitationFilters = useMemo(
    () => ({
      proPresentation: {
        proViews: {
          bilikZone: {
            id: {
              _eq: filters?.bilikZoneId,
            },
          },
          proPresentation: {
            _or: [
              {
                mainTradeId: {
                  _eq: filters?.tradeId,
                },
              },
              {
                secondaryTradeId: {
                  _eq: filters?.tradeId,
                },
              },
            ],
          },
        },
      },
      dateCreated: {
        _gte: filter.afterDate,
        _lt: filter.beforeDate,
      },
    }),
    [filters?.bilikZoneId, filters?.tradeId, activeDateFilter],
  );

  const {
    loading: proReviewInvitationLoading,
    count: proReviewInvitationCount,
  } = useProReviewInvitationsCount(proReviewInvitationFilters);

  const {
    loading: proReviewInvitationTransformedLoading,
    count: proReviewInvitationTransformedCount,
  } = useProReviewInvitationsCount({
    ...proReviewInvitationFilters,
    proReviewId: {
      _isNull: false,
    },
  });

  const proReviewInvitationTransformationPercentage: number = useMemo(
    () =>
      proReviewInvitationTransformedCount && proReviewInvitationCount
        ? Math.floor(
            (proReviewInvitationTransformedCount / proReviewInvitationCount) *
              100,
          )
        : 0,
    [proReviewInvitationCount, proReviewInvitationTransformedCount],
  );

  const { loading: callLoading, count: callCount } = useCallsCount({
    after: filter.afterDate,
    before: filter.beforeDate,
    bilikZoneId: filters?.bilikZoneId,
    proViewIds: filters?.proViewIds,
  });

  const { loading: smsLoading, count: smsCount } = useSmsReceivedCount({
    after: filter.afterDate,
    before: filter.beforeDate,
    isFirstContactExcluded: true,
    isSpamExcluded: true,
    bilikZoneId: filters?.bilikZoneId,
    proViewIds: filters?.proViewIds,
  });

  const { loading: solicitationLoading, count: soliciationCount } =
    useSolicitationsCount({
      proView: {
        proPresentation: {
          _or: [
            {
              mainTradeId: {
                _eq: filters?.tradeId,
              },
            },
            {
              secondaryTradeId: {
                _eq: filters?.tradeId,
              },
            },
          ],
        },
        bilikZone: {
          id: {
            _eq: filters?.bilikZoneId,
          },
        },
      },
      dateCreated: {
        _gte: filter.afterDate,
        _lt: filter.beforeDate,
      },
    });

  return (
    <>
      <Segment>
        <Container style={{ textAlign: 'right' }}>
          <Label
            as="a"
            color={activeDateFilter === 'last-month' ? 'blue' : undefined}
            onClick={() => setActiveDateFilter('last-month')}
          >
            30 derniers jours
          </Label>
          <Label
            as="a"
            color={activeDateFilter === 'last-3-months' ? 'blue' : undefined}
            onClick={() => setActiveDateFilter('last-3-months')}
          >
            3 derniers mois
          </Label>
          <Label
            as="a"
            color={activeDateFilter === 'last-6-months' ? 'blue' : undefined}
            onClick={() => setActiveDateFilter('last-6-months')}
          >
            6 derniers mois
          </Label>
          <Label
            as="a"
            color={activeDateFilter === 'last-year' ? 'blue' : undefined}
            onClick={() => setActiveDateFilter('last-year')}
          >
            12 derniers mois
          </Label>
          <Label
            as="a"
            color={activeDateFilter === 'all' ? 'blue' : undefined}
            onClick={() => setActiveDateFilter('all')}
          >
            Depuis toujours
          </Label>
        </Container>
        <Divider hidden />
        <Grid columns={3} textAlign="center">
          <Grid.Column>
            <Statistic
              size="small"
              color={
                activeDateFilter !== 'all'
                  ? proCount >= 0
                    ? 'green'
                    : 'red'
                  : undefined
              }
            >
              <Statistic.Label>
                {activeDateFilter === 'all' ? 'Pros publiés' : 'Nouveaux pros'}
              </Statistic.Label>
              <Statistic.Value>
                {!proLoading ? (
                  <>
                    {activeDateFilter !== 'all'
                      ? proCount > 0
                        ? '+'
                        : null
                      : null}
                    {proCount.toLocaleString('FR')}
                  </>
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>Entrées</Statistic.Label>
              <Statistic.Value>
                {!publishedProLoading ? (
                  publishedProCount?.toLocaleString('FR')
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>Sorties</Statistic.Label>
              <Statistic.Value>
                {!archivedProLoading ? (
                  archivedProCount?.toLocaleString('FR')
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Popup
              trigger={
                <Statistic size="small">
                  <Statistic.Label>Avis publiées</Statistic.Label>
                  <Statistic.Value>
                    {!publishedProReviewLoading ? (
                      publishedProReviewCount?.toLocaleString('FR')
                    ) : (
                      <Loader active inline />
                    )}
                  </Statistic.Value>
                </Statistic>
              }
              content="Avis visible sur le site (avis validés sur pros publiés). Ne compabilise pas les avis externes."
            />
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>Demande d&apos;avis</Statistic.Label>
              <Statistic.Value>
                {!proReviewInvitationLoading ? (
                  proReviewInvitationCount?.toLocaleString('FR')
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>
                avis suite aux demandes d&apos;avis
              </Statistic.Label>
              <Statistic.Value>
                {!proReviewInvitationTransformedLoading &&
                !proReviewInvitationLoading ? (
                  `${proReviewInvitationTransformationPercentage?.toLocaleString(
                    'FR',
                  )}%`
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>Appels</Statistic.Label>
              <Statistic.Value>
                {!callLoading ? (
                  callCount?.toLocaleString('FR')
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>Demandes mail</Statistic.Label>
              <Statistic.Value>
                {!solicitationLoading ? (
                  soliciationCount?.toLocaleString('FR')
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Label>SMS</Statistic.Label>
              <Statistic.Value>
                {!smsLoading ? (
                  smsCount?.toLocaleString('FR')
                ) : (
                  <Loader active inline />
                )}
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};
