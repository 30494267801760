import FormField from 'components/form-field/form-field';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
type ReplyProReviewFormContainerProps = {
  replyProReview: (reply: string) => unknown;
  refuseReply?: (reply: string) => unknown;
  initialReply?: string;
  type?: 'reply' | 'reply-moderation';
};

export const ReplyProReviewFormContainer: FunctionComponent<
  ReplyProReviewFormContainerProps
> = ({ replyProReview, refuseReply, type = 'reply', initialReply }) => {
  return (
    <Formik
      validationSchema={Yup.object().shape({
        reply: Yup.string().required('Réponse requise !'),
      })}
      validateOnChange={true}
      initialValues={{ reply: initialReply ?? '' }}
      onSubmit={async (values, actions): Promise<void> => {
        await replyProReview(values.reply);

        actions.setSubmitting(false);
      }}
    >
      {(props): React.ReactElement => (
        <Form onReset={props.handleReset} onSubmit={props.handleSubmit}>
          <FormField
            type="textarea"
            placeholder={type === 'reply' ? 'Réponse' : 'Réponse à modérer'}
            label={type === 'reply' ? 'Réponse' : 'Réponse à modérer'}
            helpText={
              type === 'reply'
                ? 'La réponse est visible sur le site web et est envoyée par email aux deux parties'
                : "La réponse sera envoyée par au pro et visible sur le site web après publication de l'avis"
            }
            name="reply"
            rows={type === 'reply-moderation' ? 10 : undefined}
            required
          />
          <Button.Group>
            <Button
              primary
              type="submit"
              loading={props.isSubmitting}
              disabled={props.isSubmitting}
            >
              {type === 'reply' && <Icon name="mail" />}
              {type === 'reply' ? 'Envoyer' : 'Valider'}
            </Button>
            {type === 'reply-moderation' && <Button.Or text="ou" />}
            {type === 'reply-moderation' && (
              <Button
                negative
                type="button"
                onClick={(): void => {
                  if (refuseReply) {
                    refuseReply(props.values.reply);
                  }
                }}
              >
                Refuser
              </Button>
            )}
          </Button.Group>
        </Form>
      )}
    </Formik>
  );
};
