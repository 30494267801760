import React, { FunctionComponent, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';

const AdminRoute: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const isCurrentUserAdmin = useIsUserAdmin();

  if (!isCurrentUserAdmin) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default AdminRoute;
