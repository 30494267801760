export const generateTvaNumber = (siret: string): string | undefined => {
  if (!(siret.length === 14 && !isNaN(Number(siret)))) {
    return undefined;
  }

  return `FR${String((12 + 3 * (Number(siret.slice(0, 9)) % 97)) % 97).padStart(
    2,
    '0',
  )}${siret.slice(0, 9)}`;
};
