import { Dimmer, Loader, Table } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { useTradesGuideTableQuery } from 'generated/graphql';

export const TradesGuideTable: FunctionComponent = ({}) => {
  const { loading, data } = useTradesGuideTableQuery();

  const trades = data?.tradeGuide || [];
  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="not sortable">Nom</Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Première catégorie
            </Table.HeaderCell>
            <Table.HeaderCell className="not sortable">
              Seconde catégorie
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {trades.map((trade, index) => (
            <Table.Row key={index}>
              <Table.Cell>{trade.name}</Table.Cell>
              <Table.Cell>{trade.firstTrade.name}</Table.Cell>
              <Table.Cell>{trade.secondTrade?.name}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};
