import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, Icon } from 'semantic-ui-react';

type ExportSepaCardProps = Record<string, unknown>;

export const ExportSepaCard: FunctionComponent<ExportSepaCardProps> = ({}) => {
  const history = useNavigate();

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="code" />
        <Card.Header>Export SEPA</Card.Header>
        <Card.Description>
          Génération du XML aux normes ISO 20022 (Pain 008.001.02)
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          fluid
          primary
          onClick={(): void => {
            history('/export_sepa');
          }}
        >
          Ouvrir
        </Button>
      </Card.Content>
    </Card>
  );
};
