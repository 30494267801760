import { Formik } from 'formik';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ExportSepaSearchInvoicesFields } from './export-sepa-search-invoices-fields.type';
import * as Yup from 'yup';
import { Button, Form, Icon, Segment } from 'semantic-ui-react';
import FormField from 'components/form-field/form-field';
import { ExportSepaInvoicesTableContainer } from './export-sepa-invoices-table-container';
import SelectZohoOrganizationField from 'components/select-zoho-organization-field/select-zoho-organization-field';

const exportSepaSearchInvoicesValidation = (): Yup.ObjectSchema =>
  Yup.object().shape({
    startDate: Yup.date().nullable().required('Date de début requise !'),
    endDate: Yup.date().nullable().required('Date de fin requise !'),
    zohoOrganizationId: Yup.number().required('Zoho organisation requise !'),
  });

type ExportSepaSearchInvoicesFormProps = Record<string, unknown>;

export const ExportSepaSearchInvoicesForm: FunctionComponent<
  ExportSepaSearchInvoicesFormProps
> = ({}) => {
  const [searchInvoicesFormValues, setSearchInvoicesFormValues] =
    useState<ExportSepaSearchInvoicesFields>();

  const onSubmit = useCallback(
    async (values: ExportSepaSearchInvoicesFields) => {
      setSearchInvoicesFormValues(values);
    },
    [],
  );

  const initialValues: ExportSepaSearchInvoicesFields = useMemo(() => {
    return {
      startDate: '',
      endDate: '',
      zohoOrganizationId: undefined,
    };
  }, []);
  return (
    <Formik
      validateOnChange={true}
      validationSchema={exportSepaSearchInvoicesValidation()}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        handleReset,
        handleSubmit,
        values,
        isSubmitting,
      }): React.ReactElement => (
        <Segment>
          <Form onReset={handleReset} onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <FormField
                label="Date de début (inclusif)"
                name="startDate"
                type="date"
                dateFormat="dd/MM/yyy"
                values={values}
              />
              <FormField
                label="Date de fin (inclusif)"
                name="endDate"
                type="date"
                dateFormat="dd/MM/yyy"
                values={values}
              />
              <SelectZohoOrganizationField
                required
                name="zohoOrganizationId"
                label="Zoho Organization"
                selection
                search
              />
              <Button
                style={{ marginTop: '23px', minWidth: '200px' }}
                primary
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                <Icon name="save" />
                Rechercher les factures
              </Button>
            </Form.Group>
          </Form>

          {/* Table Container based on form values */}
          {searchInvoicesFormValues ? (
            <ExportSepaInvoicesTableContainer
              endDate={searchInvoicesFormValues.endDate}
              startDate={searchInvoicesFormValues.startDate}
              zohoOrganizationId={
                searchInvoicesFormValues.zohoOrganizationId || 1
              }
            />
          ) : null}
        </Segment>
      )}
    </Formik>
  );
};
