import { set } from 'remeda';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Checkbox, Icon, Table } from 'semantic-ui-react';
import { ManageProViewContainerItem } from 'pages/export-guide/manage-pros/manage-pros';

const DragHandle = SortableHandle<{ checked?: boolean }>(({ checked }) => (
  <Table.Cell className={checked ? 'disabled' : ''} textAlign="center">
    <Icon name="bars" />
  </Table.Cell>
));

type SortableTableProps = {
  onItemChange: (items: ManageProViewContainerItem[]) => void;
  items: ManageProViewContainerItem[];
};

const SortableTable: FunctionComponent<SortableTableProps> = ({
  items,
  onItemChange,
}) => {
  useEffect(() => {
    onItemChange(items);
  }, []);

  return (
    <SortableList
      items={items}
      onCheck={(index, value): void => {
        const list = items.map((item, itemIndex) => {
          if (itemIndex === index) {
            return set(item, 'isChecked', value);
          }
          return item;
        });
        onItemChange(list);
      }}
      onSortEnd={({ oldIndex, newIndex }): void => {
        onItemChange(arrayMove(items, oldIndex, newIndex));
      }}
      useDragHandle
      useWindowAsScrollContainer
    />
  );
};

interface SortableItemProps {
  value: ManageProViewContainerItem;
  arrayIndex;
  onCheck: (index: number, value: boolean) => void;
  checked: boolean;
}

const SortableItem = SortableElement<SortableItemProps>(
  ({ value, arrayIndex, onCheck, checked }) => {
    return (
      <Table.Row>
        <DragHandle checked={checked} />
        <Table.Cell className={checked ? 'disabled' : ''}>
          {value.mainTrade.isTradeGuide ? (
            <i>{value.mainTrade.normalizedName}</i>
          ) : (
            value.mainTrade.normalizedName
          )}
        </Table.Cell>
        <Table.Cell className={checked ? 'disabled' : ''}>
          {value.proPresentation.name}
        </Table.Cell>
        <Table.Cell className={checked ? 'disabled' : ''}>
          {value.proPeople.map((proPerson) => (
            <Fragment key={`pro-person-${proPerson.proPerson.id}`}>
              {proPerson.proPerson.givenName} {proPerson.proPerson.familyName}
              <br />
            </Fragment>
          ))}
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            checked={checked}
            onChange={(event, eventData): void => {
              onCheck(arrayIndex, eventData.checked);
            }}
          />
        </Table.Cell>
      </Table.Row>
    );
  },
);

interface SortableListProps {
  items: ManageProViewContainerItem[];
  onCheck: (index: number, value: boolean) => void;
}

const SortableList = SortableContainer<SortableListProps>(
  ({ items, onCheck }) => {
    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Mouvoir</Table.HeaderCell>
            <Table.HeaderCell>
              Catégorie <i>(ou catégorie guide)</i>
            </Table.HeaderCell>
            <Table.HeaderCell>Nom fiche</Table.HeaderCell>
            <Table.HeaderCell>Contacts</Table.HeaderCell>
            <Table.HeaderCell>Sortir du guide</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((proView, index) => {
            return (
              <SortableItem
                onCheck={onCheck}
                key={proView.id}
                index={index}
                arrayIndex={index}
                value={proView}
                checked={proView.isChecked}
              />
            );
          })}
        </Table.Body>
      </Table>
    );
  },
);

export default SortableTable;
