import { useAccountProViewDeleteConfirmModalQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import AccountProViewDeleteConfirmModalContainer from './account-pro-view-delete-confirm-modal-container';
import { AccountProViewDeleteConfirmModalProps } from './account-pro-view-delete-confirm-modal-view';

type AccountProViewDeleteConfirmModalRepositoryProps = AccountProViewDeleteConfirmModalProps & {
  currentProViewId?: number;
  accountId: number;
  onConfirm?: (accountId: number) => void;
};

const AccountProViewDeleteConfirmModalRepository: FunctionComponent<AccountProViewDeleteConfirmModalRepositoryProps> = ({
  accountId,
  currentProViewId,
  onConfirm,
  onClose,
  ...rest
}) => {
  const { data, loading } = useAccountProViewDeleteConfirmModalQuery({
    variables: {
      accountId,
    },
  });

  return (
    <AccountProViewDeleteConfirmModalContainer
      data={data}
      loading={loading}
      currentProViewId={currentProViewId}
      accountId={accountId}
      onClose={onClose}
      onConfirm={onConfirm}
      {...rest}
    />
  );
};
export default AccountProViewDeleteConfirmModalRepository;
