import { apiClient } from 'axios-client';
import { ZohoInvoice } from 'interfaces/zoho-invoices.interface';
import React, { FunctionComponent, useCallback } from 'react';
import ProInvoicesContainer from './pro-invoices-container';

type ProInvoicesRepositoryProps = {
  proOrganizationId?: number;
};

const ProInvoicesRepository: FunctionComponent<ProInvoicesRepositoryProps> = ({
  proOrganizationId,
}) => {
  const fetchInvoices = useCallback(
    async (pageNumber: number) => {
      if (proOrganizationId) {
        const endpoint = `/zoho/invoice/pro-organization?${new URLSearchParams({
          id: String(proOrganizationId),
          page_number: String(pageNumber),
        })}`;

        const invoices = await apiClient
          .get<{ invoices: ZohoInvoice[]; hasMorePage: boolean }>(endpoint)
          .then((response) => response.data);

        return invoices;
      } else {
        return { invoices: [], hasMorePage: false };
      }
    },
    [proOrganizationId],
  );

  return <ProInvoicesContainer fetchInvoices={fetchInvoices} />;
};

export default ProInvoicesRepository;
