import React, { FunctionComponent } from 'react';
import { Button, Icon, Image, Modal } from 'semantic-ui-react';
import ImageDeleteButton from '../../../../../components/image-delete-button/image-delete-button';

type SortableItemModalProps = {
  isOpen: boolean;
  onClose: () => void;
  imgSrc: string;
  imageHash: string;
  onDelete: (imageHash: string) => void;
  pagination?: {
    prevImageHash?: string | null;
    nextImageHash?: string | null;
    onImageChange?: (imageHash: string) => void;
  };
};

const SortableItemModal: FunctionComponent<SortableItemModalProps> = ({
  isOpen,
  onClose,
  imgSrc,
  imageHash,
  onDelete,
  pagination,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
    >
      <Modal.Header>Image</Modal.Header>
      <Modal.Content
        style={{
          textAlign: 'center',
        }}
      >
        <Image src={imgSrc} centered />
        <br />
        {pagination?.prevImageHash ? (
          <Button
            style={{ margin: '0 5px' }}
            onClick={(): void => {
              if (pagination.onImageChange) {
                pagination.onImageChange(pagination.prevImageHash as string);
              }
            }}
          >
            <Icon name="arrow left" />
            Photo précédente
          </Button>
        ) : null}
        {pagination?.nextImageHash ? (
          <Button
            style={{ margin: '0 5px' }}
            onClick={(): void => {
              if (pagination.onImageChange) {
                pagination.onImageChange(pagination.nextImageHash as string);
              }
            }}
          >
            Photo suivante
            <Icon name="arrow right" />
          </Button>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Fermer</Button>
        <ImageDeleteButton
          confirm
          onDelete={(): void => {
            onDelete(imageHash);
          }}
          style={{}}
          text="Supprimer"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SortableItemModal;
