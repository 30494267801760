import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label, Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

type ProReviewPublishedProps = {
  event: MongoEvent;
};

const ProReviewPublishedV1: FunctionComponent<ProReviewPublishedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Avis publié"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Avis sur <span>{event.actors.pro.proPresentation.name}</span> validé et
        publié
      </h3>
      <div>
        <Label
          as="a"
          onClick={(): void => {
            window.open(
              `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
              '_blank',
            );
          }}
        >
          <Icon name="eye" size="small" />
          Voir avis
        </Label>
      </div>
    </TimelineEvent>
  );
};

const ProReviewPublishedV2: FunctionComponent<ProReviewPublishedProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Avis publié"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Avis sur{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>{' '}
        validé et publié{' '}
      </h3>
      <div>
        <Label
          as="a"
          onClick={(): void => {
            window.open(
              `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
              '_blank',
            );
          }}
        >
          <Icon name="eye" size="small" />
          Voir avis
        </Label>
      </div>
    </TimelineEvent>
  );
};

const ProReviewPublished: FunctionComponent<ProReviewPublishedProps> = ({
  event,
}) => {
  if (event.version === 1) {
    return <ProReviewPublishedV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewPublishedV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewPublished (version ${event.version})`}
    />
  );
};

export default ProReviewPublished;
