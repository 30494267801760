import React, { FunctionComponent, useMemo } from 'react';
import SolicitationsList from 'components/solicitations-list/solicitations-list';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';

const SolicitationsListPage: FunctionComponent = () => {
  const { currentBilikZone } = useCurrentBilikZone();

  const title: React.ReactElement = useMemo(
    () => (
      <>
        Demandes mail
        <small>
          {currentBilikZone
            ? currentBilikZone.mainCityCode
            : 'Toutes les zones'}
        </small>
      </>
    ),
    [currentBilikZone],
  );

  return <SolicitationsList title={title} />;
};

export default SolicitationsListPage;
