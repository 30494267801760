import React, { FunctionComponent } from 'react';
import { Navigate, useParams } from 'react-router';
import { Loader } from 'semantic-ui-react';
import { useProReviewUpdatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import { ProReviewFormUpdate } from './pro-review-form';

type ProReviewUpdatePageProps = Record<string, unknown>;

const ProReviewUpdatePage: FunctionComponent<
  ProReviewUpdatePageProps
> = ({}) => {
  const params = useParams<{ proReviewId?: string }>();
  const proReviewId = Number(params.proReviewId);

  const { data, loading } = useProReviewUpdatePageQuery({
    variables: { proReviewId },
  });

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  // proReview not found
  if (!data?.proReviewByPk) return <Navigate to="/404" replace />;

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Avis : {data.proReviewByPk.givenName}. {data.proReviewByPk.familyName}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <ContentContainer>
          <ProReviewFormUpdate />
        </ContentContainer>
      </ContentBody>
    </Content>
  );
};

export default ProReviewUpdatePage;
