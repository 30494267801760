import React, { FunctionComponent } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import { Message, Popup } from 'semantic-ui-react';
import TimelineEvent from '../timeline-event';
import { pathOr } from 'remeda';
import { formatTelephone } from 'utils';

const CallDeniedV1: FunctionComponent<CallDeniedProps> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="phone"
      title="Appel refusé"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a appelé{' '}
        <Popup
          content={
            <>
              <>Numéro : {formatTelephone(event.data.twilio.To)}</>
            </>
          }
          trigger={
            <span>
              {pathOr(event, ['actors', 'pro'], false) ? (
                <>
                  {event.actors.pro.proPresentation.name} (
                  {event.actors.pro.bilikZone.mainCityCode} -{' '}
                  {event.actors.pro.proPresentation.mainTrade?.name}) (Pro
                  archivé)
                </>
              ) : (
                'Numéro inconnu (aucun pro / zone associé)'
              )}{' '}
              via le {formatTelephone(event.initiator.pi.telephone)}
            </span>
          }
          position="top center"
        />
      </h3>
    </TimelineEvent>
  );
};

const CallDeniedV2: FunctionComponent<CallDeniedProps> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="phone"
      title="Appel refusé"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a appelé{' '}
        <Popup
          content={
            <>
              <>Numéro : {formatTelephone(event.data.twilio.To)}</>
            </>
          }
          trigger={
            <span>
              {pathOr(event, ['actors', 'pro'], false) ? (
                <>
                  {event.actors.pro.proPresentation.name} (
                  {event.actors.pro.bilikZone.mainCityCode} -{' '}
                  {event.actors.pro.proPresentation.mainTrade?.name}) (Pro
                  archivé)
                </>
              ) : (
                'Numéro inconnu (aucun pro / zone associé)'
              )}{' '}
              via le {formatTelephone(event.initiator.pi.telephone)}
            </span>
          }
          position="top center"
        />
      </h3>
    </TimelineEvent>
  );
};

const CallDeniedV3: FunctionComponent<CallDeniedProps> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="phone"
      title="Appel refusé"
      takingContact
      initiator="particulier"
    >
      <h3>
        Le particulier a appelé{' '}
        <Popup
          content={<>Numéro : {formatTelephone(event.data.vonage.to)}</>}
          trigger={
            <span>
              {pathOr(event, ['actors', 'pro'], false) ? (
                <>
                  {event.actors.pro.proPresentation.name} (
                  {event.actors.pro.bilikZone.mainCityCode} -{' '}
                  {event.actors.pro.proPresentation.mainTrade?.name}) (Pro
                  archivé)
                </>
              ) : (
                'Numéro inconnu (aucun pro / zone associé)'
              )}{' '}
              via le {formatTelephone(event.initiator.pi.telephone)}
            </span>
          }
          position="top center"
        />
      </h3>
    </TimelineEvent>
  );
};

type CallDeniedProps = {
  event: MongoEvent;
};

const CallDenied: FunctionComponent<CallDeniedProps> = ({ event }) => {
  if (event.version === 1) {
    return <CallDeniedV1 event={event} />;
  } else if (event.version === 2) {
    return <CallDeniedV2 event={event} />;
  } else if (event.version === 3) {
    return <CallDeniedV3 event={event} />;
  }

  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour CallDenied (version ${event.version})`}
    />
  );
};

export default CallDenied;
