import { useFormikContext } from 'formik';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ToastError, ToastWarn } from 'utils';
import { ProFormUpdateFields } from '../pro-form-update-fields-type';
import {
  UploadImageResponse,
  GalleryImageModal,
} from './pro-gallery-field-type';
import { ProGalleryFieldView } from './pro-gallery-field-view';

type ProGalleryFieldContainerProps = {
  uploadImage(image: File): Promise<UploadImageResponse>;
  name: string;
};

const max_image_size = 20971520;

export const ProGalleryFieldContainer: FunctionComponent<
  ProGalleryFieldContainerProps
> = ({ name, uploadImage }) => {
  const [loading, setLoading] = useState(false);

  const {
    setFieldValue,
    values: {
      proPresentation: { proMediaObjects },
    },
  } = useFormikContext<ProFormUpdateFields>();

  const [imageModal, setImageModal] = useState<GalleryImageModal>({
    isOpen: false,
    hash: undefined,
  });

  const closeImageModal = useCallback(() => {
    setImageModal({ isOpen: false, hash: undefined });
  }, [setImageModal]);

  const openImageModal = useCallback(
    (hash: string) => {
      setImageModal({ isOpen: true, hash });
    },
    [setImageModal],
  );

  const onImagesDropped = useCallback(
    async (files: File[]): Promise<void> => {
      setLoading(true);
      let tmpMediaObjects = proMediaObjects;
      for (const file of files) {
        // image too big
        if (!(file.size < max_image_size)) {
          ToastError(file.name, 'Upoad impossible : Fichier trop volumineux');
          continue;
        }

        // upload image to image micro service
        try {
          const uploadResponse = await uploadImage(file);
          const hash = uploadResponse.id;

          // image already exist in form values
          const existingImage = proMediaObjects.find(
            (proMediaObject) => proMediaObject.hash === hash,
          );

          if (existingImage !== undefined) {
            ToastWarn(
              'Attention',
              `L'image ${file.name} existe déjà dans la gallerie.`,
            );
            setLoading(false);

            continue;
          }

          tmpMediaObjects = tmpMediaObjects.map((proMediaObject) => ({
            ...proMediaObject,
            position: proMediaObject.position + 1,
          }));

          tmpMediaObjects.unshift({
            hash,
            position: 1,
          });
        } catch (e) {
          setLoading(false);
          ToastError('Erreur', "Impossible d'ajouter l'image à la gallery.");
          console.error(e);
        }
      }
      setLoading(false);
      // update the form field value to new images array
      setFieldValue(name, tmpMediaObjects);
    },
    [proMediaObjects, uploadImage],
  );

  const onSortEnd = useCallback(
    (hashs: string[]): void => {
      const proMediaObjectFields = hashs.map((hash, index) => ({
        hash,
        position: index + 1,
      }));

      setFieldValue(name, proMediaObjectFields);
    },
    [proMediaObjects],
  );

  const deleteImageByHash = useCallback(
    (hash: string) => {
      setFieldValue(
        name,
        proMediaObjects.filter(
          (proMediaObject) => proMediaObject.hash !== hash,
        ),
      );
    },
    [proMediaObjects, setFieldValue],
  );

  const isProAbleToUploadImage = useMemo(
    () => proMediaObjects.length < 20,
    [proMediaObjects],
  );

  const getPrevImageHash = useCallback(
    (hash: string): string | null => {
      const currentProMediaObjectKey = proMediaObjects.findIndex(
        (proMediaObject) => proMediaObject.hash === hash,
      );

      return proMediaObjects[currentProMediaObjectKey - 1]
        ? proMediaObjects[currentProMediaObjectKey - 1].hash
        : null;
    },
    [proMediaObjects],
  );

  const onDeleteAll = useCallback(() => {
    setFieldValue(name, []);
  }, [setFieldValue]);

  const getNextImageHash = useCallback(
    (hash: string): string | null => {
      const currentProMediaObjectKey = proMediaObjects.findIndex(
        (proMediaObject) => proMediaObject.hash === hash,
      );

      return proMediaObjects[currentProMediaObjectKey + 1]
        ? proMediaObjects[currentProMediaObjectKey + 1].hash
        : null;
    },
    [proMediaObjects],
  );
  return (
    <ProGalleryFieldView
      loading={loading}
      onImagesDropped={onImagesDropped}
      proMediaObjects={proMediaObjects}
      onSortEnd={onSortEnd}
      imageModal={imageModal}
      openImageModal={openImageModal}
      closeImageModal={closeImageModal}
      deleteImageByHash={deleteImageByHash}
      isProAbleToUploadImage={isProAbleToUploadImage}
      getPrevImageHash={getPrevImageHash}
      getNextImageHash={getNextImageHash}
      maxImageSize={max_image_size}
      onDeleteAll={onDeleteAll}
    />
  );
};
