import { FunctionComponent, useEffect } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LeafletEventHandlerFnMap, Map } from 'leaflet';

export interface MapInteractionProps {
  flyTo?: { lat: number; lng: number };
  onMapReady?: (map: Map) => void;
  eventHandlers?: LeafletEventHandlerFnMap;
}
const MapInteraction: FunctionComponent<MapInteractionProps> = ({
  flyTo,
  eventHandlers,
  onMapReady,
}) => {
  const map = useMap();

  if (eventHandlers) {
    useMapEvents(eventHandlers);
  }

  useEffect(() => {
    if (map) {
      if (flyTo) {
        map.flyTo(flyTo);
      }
      if (onMapReady) {
        map.addOneTimeEventListener('load', () => onMapReady(map));
      }
    }
  }, [map, flyTo]);

  return null;
};

export default MapInteraction;
