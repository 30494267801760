import { startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import React, { FunctionComponent } from 'react';
import { useProSolicitationsByDateQuery } from 'generated/graphql';
import { ProStatsSolicitationsContainer } from './pro-stats-solicitations-container';

type Props = {
  startDate: Date;
  endDate: Date;
  proViewId: number;
};

export const ProStatsSolicitationsRepository: FunctionComponent<Props> = ({
  startDate,
  endDate,
  proViewId,
}) => {
  const { data } = useProSolicitationsByDateQuery({
    variables: {
      before: startOfMonth(startDate),
      after: endOfMonth(endDate),
      proViewId: proViewId,
    },
  });

  return (
    <ProStatsSolicitationsContainer
      solicitations={data?.solicitation}
      startDate={startDate}
      endDate={endDate}
    />
  );
};
