import {
  ProPersonSetInput,
  useProPersonFormUpdateMutation,
  useProPersonFormUpdateQuery,
  // useProPersonFormUpdateQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ToastError } from 'utils';
import { ProPersonFormModalProps } from './pro-person-form-modal';
import ProPersonFormUpdateContainer from './pro-person-form-update-container';

type ProPersonFormUpdateRepositoryProps = ProPersonFormModalProps & {
  proPersonId: number;
  onProPersonUpdated?: (proPersonId: number) => void;
};

const ProPersonFormUpdateRepository: FunctionComponent<
  ProPersonFormUpdateRepositoryProps
> = ({ proPersonId, onProPersonUpdated, ...rest }) => {
  const { data } = useProPersonFormUpdateQuery({
    variables: {
      id: proPersonId,
    },
    fetchPolicy: 'network-only',
  });

  const [proPersonFormUpdateMutation] = useProPersonFormUpdateMutation();

  const updateProPerson = useCallback(
    async (proPerson: ProPersonSetInput) => {
      if (!data?.proPersonByPk) {
        throw Error('Cannot find current Pro Person');
      }

      await proPersonFormUpdateMutation({
        variables: {
          proPersonId: proPersonId,
          proPerson,
        },
      }).catch((error) => {
        ToastError('Erreur', 'Impossible de mettre à jour le contact');
        throw error;
      });

      if (onProPersonUpdated) {
        onProPersonUpdated(proPersonId);
      }
    },
    [proPersonId, data?.proPersonByPk],
  );

  return (
    <ProPersonFormUpdateContainer
      proPersonId={proPersonId}
      proPerson={data?.proPersonByPk}
      updateProPerson={updateProPerson}
      {...rest}
    />
  );
};

export default ProPersonFormUpdateRepository;
