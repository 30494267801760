import React, { FunctionComponent, useCallback } from 'react';
import { PublishProReviewButtonContainer } from './publish-pro-review-button-container';
import { apiClient } from 'axios-client';
import { Commands, triggerCommand } from 'utils/commands/command';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { useReviewLocker } from 'hooks/use-review-locker/use-review-locker';

type PublishProReviewRepositoryProps = {
  proReviewId: number;
  refetch: () => void;
  isACheckEnd: boolean;
  disabled?: boolean;
};

export const PublishProReviewButtonRepository: FunctionComponent<
  PublishProReviewRepositoryProps
> = ({ proReviewId, refetch, isACheckEnd, disabled = false }) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  const { unlock } = useReviewLocker(proReviewId);

  const publishProReview = useCallback(
    async (proofProvider?: string) => {
      await unlock();

      if (isACheckEnd) {
        const response = await apiClient.post(`/form/review-pro/check-end`, {
          proReviewId,
          proofProvider,
        });

        if (!response.data.success) {
          return {
            success: false,
            message: response.data.message,
          };
        }
      }

      // Send emails
      const response = await apiClient.post(`/form/review-pro/publish`, {
        proReviewId,
      });

      triggerCommand(Commands.REFETCH_REVIEW_COUNTER);

      return {
        success: response.data.success,
        message: response.data.message,
      };
    },
    [proReviewId, isACheckEnd, currentBilikPerson],
  );

  return (
    <PublishProReviewButtonContainer
      publishProReview={publishProReview}
      refetch={refetch}
      disabled={disabled}
      isACheckEnd={isACheckEnd}
    />
  );
};
