import FieldError from 'components/field-error/field-error';
import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Button, Divider, Form, Icon, Modal } from 'semantic-ui-react';
import { ProPersonFormFields } from './pro-person-form-fields-type';
import { ProPersonFormModalProps } from './pro-person-form-modal';

type ProPersonFormViewProps = ProPersonFormModalProps & {
  proPersonId?: number;
  isDuplicate?: boolean;
};

const ProPersonFormView: FunctionComponent<ProPersonFormViewProps> = ({
  open,
  onClose,
  proPersonId,
  isDuplicate,
}) => {
  const {
    isSubmitting,
    handleSubmit,
    handleReset,
    submitForm,
  } = useFormikContext<ProPersonFormFields>();

  return (
    <Modal
      open={open}
      onClose={(): void => {
        if (onClose) {
          onClose();
        }
      }}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
    >
      <Modal.Header>
        {proPersonId ? `Édition du contact` : `Nouveau contact`}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormField type="text" label="Prénom" name="givenName" required />
          <FormField type="text" label="Nom" name="familyName" required />
          <FormField
            label="Téléphone"
            type="telephone"
            name="telephone"
            placeholder="Ex: 0651727985"
          />
          <FormField type="email" label="Email" name="email" />
        </Form>
        {isDuplicate ? (
          <>
            <Divider hidden />
            <FieldError>
              Un contact avec le même nom / prénom / email ou telephone existe
              déjà.
            </FieldError>
          </>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={(): void => {
            if (onClose) {
              onClose();
            }
          }}
        >
          Annuler
        </Button>
        <Button
          primary
          disabled={isSubmitting}
          loading={isSubmitting}
          type="button"
          onClick={submitForm}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProPersonFormView;
