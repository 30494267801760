import React, { CSSProperties, FunctionComponent, useCallback } from 'react';
import AccountSearchFilterContainer from './account-search-filter-container';
import { useAccountSearchFilterQueryLazyQuery } from 'generated/graphql';
import { SearchResultData } from 'semantic-ui-react';

type AccountSearchFilterRepositoryProps = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  accountId?: number;
  style?: CSSProperties;
  placeholder?: string;
};

const AccountSearchFilterRepository: FunctionComponent<
  AccountSearchFilterRepositoryProps
> = ({ onResultSelect, accountId, ...rest }) => {
  const [accountSearchFilterQuery, { data }] =
    useAccountSearchFilterQueryLazyQuery();

  const fetchAccounts = useCallback(
    async (criteria?: string) => {
      const variables = {
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
          where: {
            accountId: { _eq: accountId },
          },
        },
      };
      await accountSearchFilterQuery(variables);
    },
    [accountSearchFilterQuery],
  );

  return (
    <AccountSearchFilterContainer
      accounts={data?.searchAccount || []}
      fetchAccounts={fetchAccounts}
      onResultSelect={onResultSelect}
      {...rest}
    />
  );
};

export default AccountSearchFilterRepository;
