import { Button, Grid, Icon, Loader } from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { CitiesTable } from './cities-table';
import { Link } from 'react-router-dom';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { useCurrentBilikZone } from 'hooks/use-current-bilik-zone/use-current-bilik-zone';

const CitiesListPage: FunctionComponent = () => {
  const { currentBilikZone } = useCurrentBilikZone();

  if (currentBilikZone === undefined) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>
              Villes {currentBilikZone ? `à ${currentBilikZone.name}` : null}
            </ContentHeaderTitle>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to="/cities/create">
              <Button positive>
                <Icon name="plus" />
                Nouveau
              </Button>
            </Link>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <CitiesTable bilikZoneId={currentBilikZone?.id ?? undefined} />
      </ContentBody>
    </Content>
  );
};

export default CitiesListPage;
