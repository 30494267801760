import React, { CSSProperties, FunctionComponent, useCallback } from 'react';
import ProPersonSearchFilterContainer from './pro-person-search-filter-container';
import { useProPersonSearchFilterQueryLazyQuery } from 'generated/graphql';
import { SearchResultData } from 'semantic-ui-react';

type ProPersonSearchFilterRepositoryProps = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  proPersonId?: number;
  style?: CSSProperties;
  placeholder?: string;
};

const ProPersonSearchFilterRepository: FunctionComponent<
  ProPersonSearchFilterRepositoryProps
> = ({ onResultSelect, proPersonId, ...rest }) => {
  const [proPersonSearchFilterQuery, { data }] =
    useProPersonSearchFilterQueryLazyQuery();

  const fetchProPeople = useCallback(
    async (criteria?: string) => {
      const variables = {
        variables: {
          criteria: criteria
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
          where: {
            proPersonId: { _eq: proPersonId },
          },
        },
      };
      await proPersonSearchFilterQuery(variables);
    },
    [proPersonSearchFilterQuery],
  );

  return (
    <ProPersonSearchFilterContainer
      proPeople={data?.searchProPerson || []}
      fetchProPeople={fetchProPeople}
      onResultSelect={onResultSelect}
      {...rest}
    />
  );
};

export default ProPersonSearchFilterRepository;
