import {
  SolicitationBoolExp,
  useSolicitationsCountQuery,
} from 'generated/graphql';

type SolicitationsCounter = {
  count?: number;
  loading: boolean;
};

export const useSolicitationsCount = (
  where?: SolicitationBoolExp,
): SolicitationsCounter => {
  const { data, loading } = useSolicitationsCountQuery({
    variables: {
      where,
    },
  });

  return {
    loading,
    count: data?.solicitationAggregate.aggregate?.count,
  };
};
