import { ResponsiveBar } from '@nivo/bar';
import React, { FunctionComponent } from 'react';
import { Container, Header, Statistic } from 'semantic-ui-react';

type Props = {
  totalCount: number;
  uniqTotalCount: number;
  chartData: {
    Date: string;
    dateISO: string;
    'Appels uniques': number;
    Appels: number;
  }[];
};

export const ProStatsCallsView: FunctionComponent<Props> = ({
  totalCount,
  uniqTotalCount,
  chartData,
}) => {
  return (
    <Container fluid>
      <Header>Appels</Header>
      <Statistic.Group size="tiny">
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#5BC0EB' }}>
            {totalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#5BC0EB', fontWeight: 'bold' }}>
            Appels
          </Statistic.Label>
        </Statistic>
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#438CAB' }}>
            {uniqTotalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#438CAB', fontWeight: 'bold' }}>
            Appels uniques
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#5BC0EB', '#438CAB']}
          groupMode="grouped"
          data={chartData}
          keys={['Appels', 'Appels uniques']}
          indexBy="Date"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};
