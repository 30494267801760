import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { filter, map, pipe, prop, uniq } from 'remeda';
import {
  SearchProps,
  SearchResultData,
  SearchResultsProps,
} from 'semantic-ui-react';
import { useDebounce } from 'use-debounce';
import { ToastError } from 'utils';
import PrivateIndividualSearchView from './private-individual-view';

type PrivateIndividualSearchContainerProps = {
  privateIndividuals?: {
    givenName: string;
    familyName: string;
    email: string;
  }[];
  fetchPrivateIndividuals: (criteria?: string | undefined) => Promise<void>;
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
};
const PrivateIndividualSearchContainer: FunctionComponent<
  PrivateIndividualSearchContainerProps
> = ({ privateIndividuals, fetchPrivateIndividuals, onResultSelect }) => {
  const [value, setValue] = useState<string>();
  const [searchResults, setSearchResults] = useState<SearchResultsProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedValue] = useDebounce(value, 600);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (debouncedValue) {
        await fetchPrivateIndividuals(debouncedValue).catch((error) => {
          ToastError('Erreur', 'Impossible de rechercher le particulier');
          setLoading(false);
          throw error;
        });
      }
    };
    fetch();
  }, [debouncedValue]);

  const onSearchChange = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      { value }: SearchProps,
    ) => {
      setValue(value);
      if (value) {
        // only when value are not empty
        // Set loading to true here to start loading before debouncing
        setLoading(true);
      }
    },
    [],
  );

  useEffect(() => {
    if (privateIndividuals) {
      const newSearchResults = pipe(
        privateIndividuals,
        map(prop('email')),
        uniq(),
        map((email) => ({
          title: email,
          value: email,
          description: filter(
            privateIndividuals,
            (privateIndividual) => privateIndividual.email === email,
          )
            .map((value) => `${value.givenName} ${value.familyName}`)
            .filter((value, index, self) => self.indexOf(value) === index) // remove duplicate givenName and familyName
            .join('\n'),
        })),
      );
      setSearchResults(newSearchResults);
    }
    setLoading(false); // Disabling loading only when searchResults are computed
  }, [privateIndividuals]);

  return (
    <PrivateIndividualSearchView
      loading={loading}
      value={value}
      onSearchChange={onSearchChange}
      searchResults={searchResults}
      onResultSelect={onResultSelect}
    />
  );
};

export default PrivateIndividualSearchContainer;
