import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { map, pipe, uniq } from 'remeda';
import {
  SearchProps,
  SearchResultData,
  SearchResultsProps,
} from 'semantic-ui-react';
import { useDebounce } from 'use-debounce';
import { formatTelephone, ToastError } from 'utils';
import ProPeopleSearchView from './pro-person-search-filter-view';
import { ProPersonSearchFilterQuery } from 'generated/graphql';

type ProPersonSearchFilterContainerProps = {
  proPeople: ProPersonSearchFilterQuery['searchProPerson'];
  fetchProPeople: (criteria?: string) => Promise<void>;
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  style?: CSSProperties;
  placeholder?: string;
};

const ProPersonSearchFilterContainer: FunctionComponent<
  ProPersonSearchFilterContainerProps
> = ({ proPeople, fetchProPeople, ...rest }) => {
  const [value, setValue] = useState<string>();
  const [searchResults, setSearchResults] = useState<SearchResultsProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedValue] = useDebounce(value, 600);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (debouncedValue) {
        await fetchProPeople(debouncedValue).catch((error) => {
          ToastError('Erreur', 'Impossible de rechercher le professionnel');
          setLoading(false);
          throw error;
        });
      }
    };
    fetch();
  }, [debouncedValue]);

  const onSearchChange = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      { value }: SearchProps,
    ) => {
      setValue(value);
      if (value) {
        // only when value are not empty
        // Set loading to true here to start loading before debouncing
        setLoading(true);
      } else {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (proPeople) {
      const newSearchResults = pipe(
        proPeople,
        map((proPerson) => ({
          title: `${proPerson.givenName} ${proPerson.familyName}`,
          key: `search-pro-person-${proPerson.id}`,
          value: proPerson.id,
          description: `${proPerson.email ? proPerson.email + '\n' : ''}${
            proPerson.telephone ? formatTelephone(proPerson.telephone) : ''
          }`,
        })),
        uniq(),
      );
      setSearchResults(newSearchResults);
    }
    setLoading(false); // Disabling loading only when searchResults are computed
  }, [proPeople]);

  return (
    <ProPeopleSearchView
      loading={loading}
      value={value}
      onSearchChange={onSearchChange}
      searchResults={searchResults}
      {...rest}
    />
  );
};

export default ProPersonSearchFilterContainer;
