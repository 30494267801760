import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { ToastError, ToastInfo } from 'utils';

type InvoicesAnomalyDetectorCardProps = Record<string, unknown>;

export const InvoicesAnomalyDetectorCard: FunctionComponent<
  InvoicesAnomalyDetectorCardProps
> = ({}) => {
  const onClickAnomalyDetector = useCallback(async (): Promise<void> => {
    await apiClient.post(`/zoho/invoice/anomaly-detection`).catch((error) => {
      if (error.status === 'Request failed with status code 504') {
        ToastInfo(
          'Info',
          "Request timeout, détection d'anomalies toujours en cours...",
        );
      } else {
        ToastError('Erreur', "Impossible de lancer la détection d'anomalies");
        throw error;
      }
    });

    ToastInfo(
      'Info',
      "Détéction d'anomalies lancée, cela peut prendre jusqu'à 20 minutes.\nTu recevras une notification quand le téléchargement sera prêt.",
    );
  }, []);

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="file excel" />
        <Card.Header>Anomalies de facturation</Card.Header>
        <Card.Description>
          {`Outil d'export d'anomalies du système de facturation, le fichier sera au format CSV (séparateur virgule).`}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button fluid primary onClick={onClickAnomalyDetector}>
          Executer
        </Button>
      </Card.Content>
    </Card>
  );
};
