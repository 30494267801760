import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Label,
  LabelGroup,
  Loader,
  Message,
  Popup,
  Table,
} from 'semantic-ui-react';
import React, { Fragment, FunctionComponent, useState } from 'react';

import AutorespondMailSent from './events/autorespond-mail-sent';
import CallDenied from './events/call-denied';
import CallRegionBlocked from './events/call-region-blocked';
import CallIdentityBlocked from './events/call-identity-blocked';
import CallStatusUpdated from './events/call-status-updated';
import FormContactBilik from './events/form-contact-bilik';
import FormCreateNoResult from './events/form-create-no-result';
import FormCreateProReview from './events/form-create-pro-review';
import FormCreateProReviewInvitation from './events/form-create-pro-review-invitation';
import FormCreateSolicitation from './events/form-create-solicitation';
import FormContinueSolicitation from './events/form-continue-solicitation';
import GloballyBlockedIdentityCreated from './events/globally-blocked-identity-created';
import GloballyBlockedIdentityDeleted from './events/globally-blocked-identity-deleted';
import SuspiciousIdentityCreated from './events/suspicious-identity-created';
import { FreshdeskEvent, MongoEvent } from 'interfaces/events.interface';
import ProReviewCheck from './events/pro-review-check';
import ProReviewCheckEnd from './events/pro-review-check-end';
import ProReviewEdited from './events/pro-review-edited';
import ProReviewInvitationToggleAutorespond from './events/pro-review-invitation-toggle-autorespond';
import ProReviewPublished from './events/pro-review-published';
import ProReviewRefused from './events/pro-review-refused';
import ProReviewReplied from './events/pro-review-replied';
import ProReviewReplyRequested from './events/pro-review-reply-requested';
import ProReviewDuplicated from './events/pro-review-duplicated';
import ProReviewToggleSpam from './events/pro-review-toggle-spam';
import SmsSent from './events/sms-sent';
import SmsReceived from './events/sms-received';
import SolicitationDeclined from './events/solicitation-declined';
import SolicitationFollowedUp from './events/solicitation-followed-up';
import SolicitationToggleAutorespond from './events/solicitation-toggle-autorespond';
import FreshdeskTicket from './events/freshdesk-ticket';
import styled from 'styled-components';
import { TimelineFilterEventsContainer } from './timeline-filter-events-container';
import { GloballyBlocked } from 'components/globally-blocked';
import { StopSms } from 'components/stop-sms';
import StopSmsDeleted from './events/stop-sms-deleted';
import StopSmsCreated from './events/stop-sms-created';
import SollicitationResent from './events/sollicitation-resent';
import { Note } from 'components/note';
import ProReviewReplyModerated from './events/pro-review-reply-moderated';
import { formatTelephone } from 'utils';

const TimelineStyle = styled.ul`
  position: relative;
  margin: 14px 0 30px 0;
  padding: 0;
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    background: #ddd;
    left: 32px;
    margin: 0;
    border-radius: 2px;
  }
`;

const Date = styled.span`
  font-weight: 600;
  padding: 5px;
  display: inline-block;
  border-radius: 4px;
  background-color: #ddd;
  color: #000;
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
`;

type Props = {
  contactInfos?: ContactInfos;
  filters: any | undefined;
  setFilters: (filters: any) => void;
  setRefetchEvents: React.Dispatch<React.SetStateAction<boolean>>;
  timelineData?: TimelineData;
  contactedPros: string[] | undefined;
};

type ContactInfos = {
  emails: string[];
  telephones: string[];
  addressLocalities: string[];
  names: string[];
};

type ToggleBlockedIdentityButtonProps = {
  action: 'block' | 'unblock';
  onClick?: () => void;
  text: string | React.ReactElement;
};

type ToggleStopSmsButtonProps = {
  action: 'stop' | 'unStop';
  onClick?: () => void;
  text: string | React.ReactElement;
};

export const TimelineView: FunctionComponent<Props> = ({
  contactInfos,
  filters,
  setFilters,
  setRefetchEvents,
  timelineData,
  contactedPros,
}) => {
  const [isProContactedShown, setIsProContactedShown] = useState(true);

  const nbProTableColumns = 3;

  return (
    <Fragment>
      <Grid columns={4}>
        <Grid.Column>
          <Table striped celled style={{ height: '100%' }} selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Noms</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contactInfos?.names.map((name) => (
                <Table.Row key={name}>
                  <Table.Cell>{name}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Table striped celled style={{ height: '100%' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Emails</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contactInfos?.emails.map((email, index) => (
                <Table.Row key={`email-${index}`}>
                  <Table.Cell>
                    {contactInfos?.emails.length > 1 ? (
                      <Popup
                        position="top center"
                        trigger={
                          <Label
                            as="a"
                            style={{ width: '27px', marginRight: '7px' }}
                            color={
                              filters && filters.email === email
                                ? 'blue'
                                : undefined
                            }
                            onClick={(): void => {
                              if (filters && filters.email === email) {
                                setFilters({ email: undefined });
                              } else {
                                setFilters({
                                  email: email,
                                });
                              }
                            }}
                          >
                            <Icon name="filter" />
                          </Label>
                        }
                        content="Filtrer"
                      />
                    ) : null}
                    <GloballyBlocked
                      triggerBlocked={
                        <ToggleBlockedIdentityButton
                          action="unblock"
                          text={email}
                        />
                      }
                      triggerNotBlocked={
                        <ToggleBlockedIdentityButton
                          action="block"
                          text={email}
                        />
                      }
                      onActionTriggered={(): void => {
                        setRefetchEvents(true);
                      }}
                      email={email}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Table striped celled style={{ height: '100%' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Telephones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contactInfos?.telephones.map((telephone) => (
                <Table.Row key={telephone}>
                  <Table.Cell>
                    {contactInfos?.telephones.length > 1 ? (
                      <Label
                        as="a"
                        style={{ width: '28px', marginRight: '7px' }}
                        color={
                          filters.telephone === telephone ? 'blue' : undefined
                        }
                        onClick={(): void => {
                          if (filters && filters.telephone === telephone) {
                            setFilters({ telephone: undefined });
                          } else {
                            setFilters({
                              telephone: telephone,
                            });
                          }
                        }}
                      >
                        <Icon name="filter" />
                      </Label>
                    ) : null}
                    <StopSms
                      triggerStopped={
                        <ToggleStopSmsButton
                          action="unStop"
                          text={formatTelephone(telephone)}
                        />
                      }
                      triggerNotStopped={
                        <ToggleStopSmsButton
                          action="stop"
                          text={formatTelephone(telephone)}
                        />
                      }
                      telephone={telephone}
                      onActionTriggered={(): void => {
                        setRefetchEvents(true);
                      }}
                    />
                    <GloballyBlocked
                      triggerBlocked={
                        <ToggleBlockedIdentityButton
                          action="unblock"
                          text={formatTelephone(telephone)}
                        />
                      }
                      triggerNotBlocked={
                        <ToggleBlockedIdentityButton
                          action="block"
                          text={formatTelephone(telephone)}
                        />
                      }
                      telephone={telephone}
                      onActionTriggered={(): void => {
                        setRefetchEvents(true);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Table striped celled style={{ height: '100%' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Lieux</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contactInfos?.addressLocalities.map((addressLocality) => (
                <Table.Row key={addressLocality}>
                  <Table.Cell>{addressLocality}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>

      <Grid columns={1}>
        <Grid.Column style={{ width: '100%' }}>
          <Table
            striped
            celled
            columns={nbProTableColumns}
            style={{ height: '100%' }}
          >
            <Table.Header style={{ position: 'fix', top: 0 }}>
              <Table.Row>
                <Table.HeaderCell colSpan={nbProTableColumns}>
                  {contactedPros?.length
                    ? `Professionnels contactés (${contactedPros?.length}) `
                    : 'Pas de professionnels'}{' '}
                  {!isProContactedShown && filters.proPresentationName && (
                    <>
                      :{' '}
                      <Label
                        as="a"
                        color="blue"
                        style={{ width: '27px', marginRight: '7px' }}
                        onClick={(): void => {
                          setFilters({
                            proPresentationName: undefined,
                          });
                        }}
                      >
                        <Icon name="filter" />
                      </Label>
                      {filters.proPresentationName}
                    </>
                  )}
                  {contactedPros?.length != 0 && (
                    <Icon
                      name={isProContactedShown ? 'chevron up' : 'chevron down'}
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={(): void => {
                        setIsProContactedShown(!isProContactedShown);
                      }}
                    />
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {isProContactedShown && (
              <Table.Body>
                {contactedPros?.map((proPresentationName, index) =>
                  index % nbProTableColumns === 0 ? (
                    <Table.Row key={`row-${index}`}>
                      {contactedPros
                        .slice(index, index + nbProTableColumns)
                        .map((proPresentationName) => (
                          <Table.Cell key={`${proPresentationName}`}>
                            <Label
                              as="a"
                              style={{ width: '28px', marginRight: '7px' }}
                              color={
                                filters.proPresentationName ===
                                proPresentationName
                                  ? 'blue'
                                  : undefined
                              }
                              onClick={(): void => {
                                if (
                                  filters &&
                                  filters.proPresentationName ===
                                    proPresentationName
                                ) {
                                  setFilters({
                                    proPresentationName: undefined,
                                  });
                                } else {
                                  setFilters({
                                    proPresentationName: proPresentationName,
                                  });
                                }
                              }}
                            >
                              <Icon name="filter" />
                            </Label>
                            {`${proPresentationName}`}
                          </Table.Cell>
                        ))}
                      {/* add empty cell to fill the row */}
                      {contactedPros.slice(index).length < nbProTableColumns ? (
                        <Table.Cell
                          colSpan={
                            nbProTableColumns -
                            contactedPros.slice(index).length
                          }
                        />
                      ) : null}
                    </Table.Row>
                  ) : null,
                )}
              </Table.Body>
            )}
          </Table>
        </Grid.Column>
      </Grid>
      <Divider />

      <Note
        emails={contactInfos?.emails}
        telephones={contactInfos?.telephones}
      />
      <br />
      <br />
      <Divider hidden />

      <Container fluid style={{ minHeight: '32px' }}>
        {filters && filters.email ? (
          <Button
            basic
            style={{ marginRight: '12px' }}
            color="grey"
            content={filters.email}
            icon={
              <Icon
                name="remove"
                onClick={(): void => {
                  setFilters({ email: undefined });
                }}
              />
            }
            labelPosition="left"
          />
        ) : null}
        {filters && filters.telephone ? (
          <Button
            basic
            style={{ marginRight: '12px' }}
            color="grey"
            content={formatTelephone(filters.telephone)}
            icon={
              <Icon
                name="remove"
                onClick={(): void => {
                  setFilters({ telephone: undefined });
                }}
              />
            }
            labelPosition="left"
          />
        ) : null}
        {filters && filters.proPresentationName ? (
          <Button
            basic
            style={{ marginRight: '12px' }}
            color="grey"
            content={filters.proPresentationName}
            icon={
              <Icon
                name="remove"
                onClick={(): void => {
                  setFilters({ proPresentationName: undefined });
                }}
              />
            }
            labelPosition="left"
          />
        ) : null}
        <Divider hidden />

        <TimelineFilterEventsContainer
          direction="left"
          filters={filters}
          setFilters={setFilters}
          float="right"
        />
      </Container>
      <Container fluid>
        <LabelGroup size="large" color="grey" style={{ marginRight: '7px' }}>
          <span
            style={{
              display: 'inline-block',
              marginRight: '7px',
              fontSize: '16px',
              fontStyle: 'italic',
            }}
          >
            *Initiateurs :
          </span>
          <Label size="large" color="grey">
            Bilik - user
          </Label>
          <Label size="large" color="grey">
            Bilik - bot
          </Label>
          <Label size="large" color="grey">
            Particulier
          </Label>
          <Label size="large" color="grey">
            Pro
          </Label>
        </LabelGroup>{' '}
      </Container>
      {timelineData ? (
        <TimelineStyle data={timelineData}>
          {Object.keys(timelineData).length > 0 ? (
            Object.keys(timelineData).map((date) => (
              <Fragment key={date}>
                <Date>{date}</Date>
                <br />
                {timelineData[date].map((event) => {
                  const components = {
                    FormContinueSolicitation,
                    FormCreateSolicitation,
                    SolicitationToggleAutorespond,
                    SolicitationDeclined,
                    SolicitationFollowedUp,
                    SollicitationResent,
                    CallStatusUpdated,
                    CallDenied,
                    CallRegionBlocked,
                    CallIdentityBlocked,
                    SmsSent,
                    SmsReceived,
                    FormCreateProReview,
                    FormCreateNoResult,
                    ProReviewPublished,
                    ProReviewRefused,
                    ProReviewReplied,
                    ProReviewReplyRequested,
                    ProReviewToggleSpam,
                    ProReviewEdited,
                    ProReviewDuplicated,
                    ProReviewCheck,
                    ProReviewCheckEnd,
                    ProReviewReplyModerated,
                    FormCreateProReviewInvitation,
                    ProReviewInvitationToggleAutorespond,
                    AutorespondMailSent,
                    FormContactBilik,
                    GloballyBlockedIdentityCreated,
                    GloballyBlockedIdentityDeleted,
                    SuspiciousIdentityCreated,
                    StopSmsCreated,
                    StopSmsDeleted,
                    FreshdeskTicket,
                  };

                  const EventContainer = components[event.type];

                  if (EventContainer) {
                    return <EventContainer event={event} key={event._id} />;
                  }

                  return null;
                })}
              </Fragment>
            ))
          ) : (
            <Message>Il n&apos;y a pas de résultats</Message>
          )}
        </TimelineStyle>
      ) : (
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      )}
    </Fragment>
  );
};

type TimelineData = {
  [key: string]: (MongoEvent | FreshdeskEvent)[];
};

const ToggleBlockedIdentityButton: FunctionComponent<
  ToggleBlockedIdentityButtonProps
> = ({ action, onClick, text }) => (
  <>
    <Popup
      position="top center"
      trigger={
        <Label
          as="a"
          style={{ width: '27px', marginRight: '7px' }}
          color={action === 'unblock' ? 'red' : undefined}
          onClick={onClick}
        >
          <Icon name="dont" />
        </Label>
      }
      content={action === 'unblock' ? 'Débloquer' : 'Bloquer'}
    />
    <span style={action === 'unblock' ? { color: 'red' } : undefined}>
      {text}
    </span>
  </>
);

const ToggleStopSmsButton: FunctionComponent<ToggleStopSmsButtonProps> = ({
  action,
  onClick,
}) => (
  <>
    <Popup
      position="top center"
      trigger={
        <Label
          as="a"
          style={{ width: '27px', marginRight: '7px' }}
          color={action === 'unStop' ? 'red' : undefined}
          onClick={onClick}
        >
          <i className="fas fa-comment-slash"></i>
        </Label>
      }
      content={
        action === 'unStop'
          ? `Réactiver l'envoi de sms automatiques`
          : `Bloquer l'envoi de sms automatiques`
      }
    />
  </>
);
