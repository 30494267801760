import {
  CurrentBilikZoneQuery,
  useCurrentBilikZoneLazyQuery,
} from 'generated/graphql';
import gql from 'graphql-tag';
import { useEffect, useCallback, useState, useMemo } from 'react';
import Client from 'client';
import { useQuery } from '@apollo/client';

export type CurrentBilikZone = null | CurrentBilikZoneQuery['bilikZoneByPk'];

export const useCurrentBilikZone = (): {
  currentBilikZone: CurrentBilikZone;
  loading: boolean;
  setCurrentBilikZone: any;
} => {
  const [loading, setLoading] = useState(false);

  const localCurrentBilikZoneId = useMemo(
    () => Number(localStorage.getItem('currentBilikZoneId')) ?? 0,
    [],
  );

  const setCurrentBilikZone = useCallback((zoneId) => {
    Client.writeQuery({
      query: gql`
        query WriteZoneId {
          zoneId
        }
      `,
      data: {
        zoneId: zoneId,
      },
    });
  }, []);

  const { data: zoneData } = useQuery(gql`
    query {
      zoneId @client
    }
  `);

  const [currentBilikZoneQuery, { data }] = useCurrentBilikZoneLazyQuery();

  useEffect(() => {
    setLoading(true);
    if (zoneData && zoneData.zoneId) {
      currentBilikZoneQuery({
        variables: {
          id: zoneData.zoneId,
        },
      });
    } else if (!zoneData) {
      setCurrentBilikZone(localCurrentBilikZoneId);
    }
    setLoading(false);
  }, [zoneData, localCurrentBilikZoneId]);

  if (zoneData && zoneData.zoneId === 0) {
    return {
      currentBilikZone: null,
      loading,
      setCurrentBilikZone: setCurrentBilikZone,
    };
  }

  return {
    currentBilikZone: data?.bilikZoneByPk,
    loading,
    setCurrentBilikZone: setCurrentBilikZone,
  };
};
