import { useApolloClient } from '@apollo/client';
import { apiClient } from 'axios-client';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import {
  ProSubscriptionTableGetBilikPeopleDocument,
  ProSubscriptionTableGetBilikPeopleQuery,
  ProSubscriptionTableGetBilikPeopleQueryVariables,
} from 'generated/graphql';
import { RecurringInvoice } from 'interfaces/recurring-invoice';
import { ZohoFullRecurringInvoice } from 'interfaces/zoho-recurring-invoice';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';
import { formatDate, ToastError, ToastSuccess } from 'utils';

type ProSubscriptionsTableProps = {
  subscriptions: RecurringInvoice[];
  onSubscriptionDeleted?: () => void;
};

interface SubscriptionModalState {
  open: boolean;
  loading: string | null;
  tags?: string[];
}

/**
 * Render a subscription list under a table shape to visualize data
 * and allow to delete subscription one by one.
 */
const ProSubscriptionsTable: FunctionComponent<ProSubscriptionsTableProps> = ({
  subscriptions,
  onSubscriptionDeleted,
}) => {
  const [loading, setLoading] = useState(false);
  const graphqlClient = useApolloClient();

  const [subscriptionModalState, setSubscriptionModalState] =
    useState<SubscriptionModalState>({
      open: false,
      loading: null,
    });

  const getSubscriptionDetails = useCallback(
    async (zohoOrganizationId: string, recurringInvoiceId: string) => {
      setSubscriptionModalState({
        ...subscriptionModalState,
        loading: recurringInvoiceId,
      });

      const response = await apiClient
        .get<ZohoFullRecurringInvoice>(
          `/zoho/subscription/details/${zohoOrganizationId}/${recurringInvoiceId}`,
        )
        .catch((error) => {
          ToastError('Erreur', "Impossible de récupérer l'abonnement.");
          setSubscriptionModalState({
            ...subscriptionModalState,
            loading: null,
          });
          throw error;
        });

      const tags: string[] = [];
      const bilikPeopleIds: number[] = [];

      response.data.line_items.forEach((lineItem) => {
        lineItem.item_custom_fields.forEach((customField) => {
          if (customField.placeholder === 'cf_managers') {
            const value: string = customField.value;

            bilikPeopleIds.push(...value.split('-').map((id) => Number(id)));
            return;
          }
          tags.push(customField.value);
        });
      });

      const result = await graphqlClient.query<
        ProSubscriptionTableGetBilikPeopleQuery,
        ProSubscriptionTableGetBilikPeopleQueryVariables
      >({
        query: ProSubscriptionTableGetBilikPeopleDocument,
        variables: { ids: bilikPeopleIds },
      });

      bilikPeopleIds.map((id) => {
        const bilikPerson = result.data.bilikPeople.find(
          (bilikPerson) => bilikPerson.id === id,
        );
        if (bilikPerson) {
          tags.push(`${bilikPerson.givenName} ${bilikPerson.familyName}`);
        }
      });

      setSubscriptionModalState({
        open: true,
        loading: null,
        tags,
      });
    },
    [],
  );

  /**
   * Given a recurring invoice id and the zoho organization its linked to
   * it stop the recurring invoice
   */
  const deleteSubscription = useCallback(
    async (
      recurring_invoice_id: string,
      zohoOrganizationId: string,
    ): Promise<void> => {
      setLoading(true);

      await apiClient
        .delete(
          `/zoho/subscription/${zohoOrganizationId}/${recurring_invoice_id}`,
        )
        .catch((error) => {
          ToastError('Erreur', "Impossible de désactiver l'abonnement.");
          setLoading(false);
          throw error;
        });

      ToastSuccess('Succès', "L'abonnement a été désactivée");

      if (onSubscriptionDeleted) {
        onSubscriptionDeleted();
      }

      setLoading(false);
    },
    [onSubscriptionDeleted],
  );

  if (subscriptions.length === 0) {
    return <p>{"Il n'y a pas d'abonnement"}</p>;
  }

  return (
    <>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Montant HT</Table.HeaderCell>
            <Table.HeaderCell>Date de début</Table.HeaderCell>
            <Table.HeaderCell>Date de fin</Table.HeaderCell>
            <Table.HeaderCell>Prochaine facture</Table.HeaderCell>
            <Table.HeaderCell>Créé le</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {subscriptions.map((subscription) => {
            const {
              recurring_invoice_id,
              recurrence_name,
              status,
              total,
              start_date,
              end_date,
              next_invoice_date,
              created_time,
              zohoOrganizationId,
            } = subscription;

            const formattedStartDate = formatDate(start_date);
            const formattedEndDate = end_date
              ? formatDate(end_date)
              : 'Ne finit jamais';
            const formattedNextInvoiceDate = next_invoice_date
              ? formatDate(next_invoice_date)
              : 'Aucune';

            const formattedCreatedDate = created_time
              ? formatDate(created_time)
              : 'Aucune';

            return (
              <Table.Row key={recurring_invoice_id}>
                {/* Nom */}
                <Table.Cell>{recurrence_name}</Table.Cell>
                {/* Status */}
                <Table.Cell>
                  {status === 'stopped' ? (
                    <Label color="orange">Arrêté</Label>
                  ) : null}
                  {status === 'active' ? (
                    <Label color="green">Actif</Label>
                  ) : null}
                  {status === 'expired' ? (
                    <Label color="grey">Terminé</Label>
                  ) : null}
                </Table.Cell>
                {/* Montant */}
                <Table.Cell>
                  {Math.round((total / 1.2) * 100) / 100}€
                </Table.Cell>
                {/* Date de début */}
                <Table.Cell>{formattedStartDate}</Table.Cell>
                {/* Date de fin */}
                <Table.Cell>{formattedEndDate}</Table.Cell>
                {/* Prochaine facture */}
                <Table.Cell>{formattedNextInvoiceDate}</Table.Cell>
                {/* Créé le */}
                <Table.Cell>{formattedCreatedDate}</Table.Cell>
                {/* Actions */}
                <Table.Cell textAlign="right">
                  <Button
                    basic
                    icon
                    type="button"
                    loading={
                      subscriptionModalState.loading === recurring_invoice_id
                    }
                    disabled={subscriptionModalState.loading !== null}
                    onClick={() =>
                      getSubscriptionDetails(
                        zohoOrganizationId,
                        recurring_invoice_id,
                      )
                    }
                  >
                    <Icon name="tag" />
                  </Button>
                  {/* We can only stop an active subscription */}
                  {status === 'active' ? (
                    <ConfirmModal
                      trigger={
                        <Button
                          basic
                          negative
                          icon
                          style={{ minWidth: '32px', maxHeight: '32px' }}
                          type="button"
                          loading={loading}
                          disabled={loading}
                        >
                          <Icon name="trash alternate outline" />
                        </Button>
                      }
                      header="Confirmation"
                      content={
                        <p>Voulez-vous vraiment désactiver cet abonnement ?</p>
                      }
                      onConfirm={(): Promise<void> =>
                        deleteSubscription(
                          recurring_invoice_id,
                          zohoOrganizationId,
                        )
                      }
                    />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Modal
        size="small"
        onClose={() =>
          setSubscriptionModalState({ ...subscriptionModalState, open: false })
        }
        open={subscriptionModalState.open}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Tags</Modal.Header>
        <Modal.Content>
          {subscriptionModalState.tags?.map((tag) => (
            <Label key={`custom-field-${tag}`}>{tag}</Label>
          ))}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ProSubscriptionsTable;
