import {
  BilikPersonSetInput,
  useBilikPersonFormUpdateQuery,
  useUseBilikPersonFormUpdateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import BilikPersonFormUpdateContainer from './bilik-person-form-update-container';
import {
  getKeycloakUserIdByEmail,
  updateKeycloakUser,
  updateKeycloakUserGroup,
} from 'utils';
import { useParams } from 'react-router';

const BilikPersonFormUpdateRepository: FunctionComponent = () => {
  const params = useParams();
  const bilikPersonId = Number(params.bilikPersonId);

  const { loading, data } = useBilikPersonFormUpdateQuery({
    variables: {
      id: Number(bilikPersonId),
    },
    fetchPolicy: 'network-only',
  });

  const [updateByPkBilikPerson] = useUseBilikPersonFormUpdateMutation();

  const updateBilikPerson = useCallback(
    async (
      bilikPerson: BilikPersonSetInput & {
        account: { email: string; password: string };
      },
    ) => {
      if (!data?.bilikPersonByPk) {
        throw Error('Cannot find current Bilik Person');
      }
      const oldBilikPerson = data?.bilikPersonByPk;

      // Calculate what changed
      const userId = await getKeycloakUserIdByEmail(
        oldBilikPerson.account.email,
      );

      if (!userId) {
        throw new Error(
          `Cannot find any Keycloak User with email: ${oldBilikPerson.account.email}`,
        );
      }

      // Update credential if new password given
      const credentials = bilikPerson.account.password
        ? [
            {
              type: 'password',
              value: bilikPerson.account.password,
              temporary: false,
            },
          ]
        : undefined;

      const email =
        oldBilikPerson.account.email !== bilikPerson.account.email
          ? bilikPerson.account.email
          : undefined;

      const status = await updateKeycloakUser(userId, {
        email,
        username: email,
        credentials,
        enabled: bilikPerson.status === 'published',
      });

      if (status !== 204) {
        throw new Error('Cannot update keycloak data');
      }

      if (oldBilikPerson.roleName !== bilikPerson.roleName) {
        const oldGroupName =
          oldBilikPerson.roleName === 'user'
            ? 'bilik-people/managers'
            : 'bilik-people/admins';
        const newGroupName =
          bilikPerson.roleName === 'user'
            ? 'bilik-people/managers'
            : 'bilik-people/admins';

        const groupStatus = await updateKeycloakUserGroup(
          userId,
          oldGroupName,
          newGroupName,
        );

        if (groupStatus !== 204) {
          throw new Error('Cannot update bilik-person groups keycloak data');
        }
      }

      await updateByPkBilikPerson({
        variables: {
          bilikPersonId: bilikPersonId,
          bilikPerson: {
            givenName: bilikPerson.givenName,
            familyName: bilikPerson.familyName,
            roleName: bilikPerson.roleName,
            telephone: bilikPerson.telephone,
            avatarHash: bilikPerson.avatarHash,
            employmentDate: bilikPerson.employmentDate,
            status: bilikPerson.status,
            description: bilikPerson.description,
          },
          accountId: oldBilikPerson.account.id,
          account: {
            email: bilikPerson.account.email,
          },
        },
      });
    },
    [updateByPkBilikPerson, data?.bilikPersonByPk],
  );

  return (
    <BilikPersonFormUpdateContainer
      updateBilikPerson={updateBilikPerson}
      formData={data?.bilikPersonByPk}
      bilikPersonId={Number(bilikPersonId)}
      loading={loading}
    />
  );
};

export default BilikPersonFormUpdateRepository;
