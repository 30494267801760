import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo } from 'react';
import FormField from 'components/form-field/form-field';
import { ProReviewFormFields } from './pro-review-form-fields.type';
import { fr } from 'date-fns/locale';
import { useFormikContext } from 'formik';
import HelpText from 'components/help-text/help-text';
import { CityField } from 'components/city-field';

interface ProReviewFormViewProps {
  action: 'create' | 'update';
}

export const ProReviewFormView: FunctionComponent<ProReviewFormViewProps> = ({
  action,
}) => {
  const { values, initialValues, handleReset, handleSubmit, isSubmitting } =
    useFormikContext<ProReviewFormFields>();

  const hasInitialReply = useMemo(() => values.reply !== undefined, []);

  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <Segment.Group>
        <Segment color="blue">
          <Header>Particulier</Header>
        </Segment>
        <Segment>
          <FormField type="text" label="Prénom" name="givenName" required />
          <FormField type="text" label="Nom" name="familyName" required />
          <FormField
            label="Téléphone"
            helpText="Ecrivez NULL dans le champ si vous n'avez pas le téléphone"
            type="text"
            name="telephone"
            placeholder="Ex: +33612345678"
            required
          />
          <FormField
            type="text"
            helpText="Ecrivez NULL dans le champ si vous n'avez pas l'email"
            label="Adresse email"
            name="email"
            required
          />
          <CityField
            drawMode="Point"
            cityFieldName="addressLocality"
            helpText="Sélectionnez le lieu de la prestation ainsi que ses coordonnées géographiques, vous pouvez mettre NULL dans le champ"
            geometryFieldName="geoCoordinates"
          />
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Commentaire</Header>
        </Segment>
        <Segment>
          <FormField
            type="text"
            label="Nature de la prestation"
            name="reviewAspect"
            required
          />
          <FormField
            type="textarea"
            label="Commentaire public"
            name="reviewBody"
            required
            rows={8}
          />
          <FormField
            type="textarea"
            label="Remarque privée"
            helpText="Cette remarque ne sera pas visible sur le site"
            name="privateComment"
            rows={8}
          />
          <FormField
            type="textarea"
            label="Ce qui peut être amélioré"
            name="comment"
            rows={8}
          />
          {/* Stars rating form field could be implemented */}
          <FormField
            type="number"
            label="Note du particulier"
            name="rating"
            icon="star"
            helpText="(Si pas de note, génération possible via l'IA sur demande au service informatique)"
          />
          <FormField
            label="Date des travaux"
            values={values}
            name="dateOfWork"
            type="date"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            locale={fr}
            clearable
          />
          <FormField
            type="checkbox"
            name="isExternalSource"
            label="Provient d'une source externe à Bilik"
          />
          {values?.isExternalSource ? (
            <FormField
              label="Indiquer l'url de la source externe"
              name="source"
              type="text"
              placeholder="Ex: www.avis-verifies.com"
              required
            />
          ) : null}
        </Segment>
      </Segment.Group>

      {hasInitialReply ? (
        <Segment.Group>
          <Segment color="blue">
            <Header>Réponse</Header>
          </Segment>
          <Segment>
            <FormField
              type="textarea"
              label="Réponse du professionnel"
              name="reply"
              rows={8}
            />
          </Segment>
        </Segment.Group>
      ) : null}
      {action === 'create' ? (
        <>
          <Segment.Group>
            <Segment color="blue">
              <Header>A l&apos;enregistrement</Header>
            </Segment>
            <Segment>
              <FormField
                type="checkbox"
                name="shouldInformPi"
                label="Prévenir le particulier "
                helpText='Envoie un mail au particulier avec le message "Votre avis a bien été publié"'
              />
              <FormField
                type="checkbox"
                name="shouldInformPro"
                label="Prévenir le professionnel"
                helpText='Envoie un mail au professionnel avec le message "L avis de M.XXX a été publié"'
              />
            </Segment>
          </Segment.Group>
        </>
      ) : null}
      <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
        {action === 'update' ? (
          <HelpText>
            <Icon name="exclamation triangle" />
            L&apos;enregistrement n&apos;informera ni le pro ni le particulier.
          </HelpText>
        ) : null}
        <Button
          primary
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          <Icon name="save" />
          Enregistrer l&apos;avis
        </Button>
      </Container>
    </Form>
  );
};
