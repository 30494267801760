import React, { FunctionComponent, useState } from 'react';
import {
  Form,
  Header,
  Segment,
  FormField as FormFieldUi,
  Grid,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';
import { subMonths } from 'date-fns';
import { ProStatsProReviewInvitations } from '../pro-stats-pro-review-invitations';
import { ProStatsCalls } from '../pro-stats-calls';
import { ProStatsProReviews } from '../pro-stats-pro-reviews';
import { ProStatsSolicitations } from '../pro-stats-solicitations';
import { ProStatsSmsReceived } from '../pro-stats-sms-received';
import ProStatsNewContacts from '../pro-stats-new-contacts/pro-stats-new-contacts';

type ProStatsRouteProps = {
  proViewId: number;
  proPresentationId: number;
};

const ProStatsRoute: FunctionComponent<ProStatsRouteProps> = ({
  proViewId,
  proPresentationId,
}) => {
  const [startDate, setStartDate] = useState(subMonths(new Date(), 5));
  const [endDate, setEndDate] = useState(new Date());

  return (
    <>
      <Segment.Group>
        <Segment>
          <Header>Période</Header>
        </Segment>
        <Segment>
          <Form.Group>
            <FormFieldUi>
              <DatePicker
                showMonthYearPicker
                dateFormat="MM/yyyy"
                locale={fr}
                selected={startDate}
                onChange={(date): void => {
                  // Interchange date if newDate > endDate
                  if (date > endDate) {
                    setStartDate(endDate);
                    setEndDate(date);
                  } else {
                    setStartDate(date);
                  }
                }}
              />
            </FormFieldUi>
            <FormFieldUi>
              <DatePicker
                style={{ maxWidth: '150px' }}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                locale={fr}
                selected={endDate}
                onChange={(date): void => {
                  // Interchange date if newDate < startDate
                  if (date < startDate) {
                    setEndDate(startDate);
                    setStartDate(date);
                  } else {
                    setEndDate(date);
                  }
                }}
              />
            </FormFieldUi>
          </Form.Group>
        </Segment>
      </Segment.Group>
      <Segment>
        <Grid columns={2}>
          <Grid.Column>
            <ProStatsNewContacts
              startDate={startDate}
              endDate={endDate}
              proViewId={proViewId}
            />
          </Grid.Column>
          <Grid.Column>
            <ProStatsCalls
              startDate={startDate}
              endDate={endDate}
              proViewId={proViewId}
            />
          </Grid.Column>
          <Grid.Column>
            <ProStatsSolicitations
              startDate={startDate}
              endDate={endDate}
              proViewId={proViewId}
            />
          </Grid.Column>
          <Grid.Column>
            <ProStatsSmsReceived
              startDate={startDate}
              endDate={endDate}
              proViewId={proViewId}
            />
          </Grid.Column>
          <Grid.Column>
            <ProStatsProReviews
              startDate={startDate}
              endDate={endDate}
              proPresentationId={proPresentationId}
            />
          </Grid.Column>
          <Grid.Column>
            <ProStatsProReviewInvitations
              startDate={startDate}
              endDate={endDate}
              proPresentationId={proPresentationId}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};

export default ProStatsRoute;
