import React, { FunctionComponent } from 'react';
import { useProReviewInvitationCheckerIndicatorQuery } from 'generated/graphql';
import { ProReviewInvitationCheckerIndicatorContainer } from './pro-review-invitation-checker-indicator-container';

type ProReviewInvitationCheckerIndicatorRepositoryProps = {
  email: string;
  proPresentationId: number;
  proViewId: number;
};

export const ProReviewInvitationCheckerIndicatorRepository: FunctionComponent<
  ProReviewInvitationCheckerIndicatorRepositoryProps
> = ({ email, proPresentationId, proViewId }) => {
  const { data, loading } = useProReviewInvitationCheckerIndicatorQuery({
    variables: { email },
  });

  const hasPiBeenInvitedByOtherPros = data?.proReviewInvitation.length !== 0;

  const wasInitiatedWithProReviewInvitation =
    data?.proReviewInvitation.find((proReviewInvitation) => {
      return proReviewInvitation.proPresentation?.id === proPresentationId;
    }) !== undefined;

  return (
    <ProReviewInvitationCheckerIndicatorContainer
      hasPiBeenInvitedByOtherPros={hasPiBeenInvitedByOtherPros}
      wasInitiatedWithProReviewInvitation={wasInitiatedWithProReviewInvitation}
      loading={loading}
      email={email}
      proViewId={proViewId}
    />
  );
};
