import { apiClient } from 'axios-client';

// Utility reponsible of sending event (Donovan edited proReview 11)
// We should use it without awaiting the response, in-order-to
// Not slows the rest of the app
const sendEvent = (
  type,
  data,
  actors,
  initiator,
  version?: number,
): Promise<void> => {
  return apiClient.post(`/event/`, {
    type,
    data,
    actors,
    initiator,
    version,
  });
};
export default sendEvent;
