import { Button, Grid, Icon } from 'semantic-ui-react';
import { NumberParam, ObjectParam, useQueryParams } from 'use-query-params';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { TradesTable } from './trades-table';
import ContentHeader from 'components/content-header/content-header';
import Content from 'components/content/content';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import { TradeSearchFilter } from 'components/trade-search-filter';
import { TradeGroupSearchFilter } from 'components/trade-group-search-filter';

const TradesListPage: FunctionComponent = () => {
  const [filters, setFilters] = useQueryParams({
    tradeId: NumberParam,
    tradeGroupId: NumberParam,
    orderBy: ObjectParam,
  });

  return (
    <Content>
      <ContentHeader>
        <Grid columns={2}>
          <Grid.Column>
            <ContentHeaderTitle>Catégories</ContentHeaderTitle>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to="/trades/create">
              <Button positive>
                <Icon name="plus" />
                Nouveau
              </Button>
            </Link>
          </Grid.Column>
        </Grid>
      </ContentHeader>
      <ContentBody>
        <div style={{ display: 'inline-block', marginLeft: '-12px' }}>
          <TradeSearchFilter
            onDelete={(): void => {
              setFilters({
                tradeId: undefined,
              });
            }}
            tradeId={filters.tradeId || undefined}
            onResultSelect={(event, { result }): void => {
              setFilters({
                tradeId: result.id,
              });
            }}
          />
          <TradeGroupSearchFilter
            onDelete={(): void => {
              setFilters({
                tradeGroupId: undefined,
              });
            }}
            tradeGroupId={filters.tradeGroupId || undefined}
            onResultSelect={(event, { result }): void => {
              setFilters({
                tradeGroupId: result.id,
              });
            }}
          />
        </div>
        <TradesTable
          filters={{
            tradeId: filters.tradeId ?? undefined,
            tradeGroupId: filters.tradeGroupId ?? undefined,
          }}
          orderBy={{
            column: filters.orderBy?.column ?? 'tradeProViews',
            direction: filters.orderBy?.direction ?? 'descending',
          }}
          onSort={(column, direction): void => {
            setFilters({
              orderBy: { column, direction },
            });
          }}
        />
      </ContentBody>
    </Content>
  );
};

export default TradesListPage;
