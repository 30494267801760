import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Map from 'components/map/map';
import { getIn, useFormikContext } from 'formik';
import { LatLng, FeatureGroup as LeafletFeatureGroup } from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { Loader } from 'semantic-ui-react';
import {
  ProInterventionFields,
  ProPostalAddressField,
} from 'pages/pros/pro-form/pro-form-update-fields-type';
import { useInterventionsFieldQueryLazyQuery } from 'generated/graphql';
import Label from 'components/label/label';
import HelpText from 'components/help-text/help-text';
import FieldError from 'components/field-error/field-error';
import { centroid } from '@turf/turf';
import {
  excludeColor,
  includeColor,
  layerToGeoJSON,
  toTurfGeometry,
  toGeometryGeoJSON,
} from 'utils/geometry';
import { Polygon, Feature, Point } from 'geojson';
import MapDraw from 'components/map/map-draw/map-draw';
import MapGeoJSON from 'components/map/map-geojson/map-geojson';
import './interventions-field.css';

type InterventionsFieldProps = {
  name: string;
  proPostalAddress?: ProPostalAddressField;
  maxInterventions?: number;
  bilikZoneId: number;
  proPresentationId: number;
  proViewId: number;
  mapHeight?: number;
};

const InterventionsField: FunctionComponent<InterventionsFieldProps> = ({
  name,
  proPostalAddress,
  bilikZoneId,
  proViewId,
  proPresentationId,
  mapHeight = 700,
}) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const mapDrawRef = useRef<LeafletFeatureGroup>(null);

  const interventions: ProInterventionFields[] = useMemo(
    () => getIn(values, name),
    [values, name],
  );

  const defaultInterventions: ProInterventionFields[] = useMemo(
    () => interventions,
    [],
  );

  // If there is no proPostalAddress and no interventions, center the map with bilikZone geocoordinates
  const [getInterventionData, { data }] = useInterventionsFieldQueryLazyQuery();

  useEffect(() => {
    getInterventionData({
      variables: {
        bilikZoneId: bilikZoneId,
        proPresentationId: proPresentationId,
        proViewId: proViewId,
      },
    });
  }, [bilikZoneId]);

  // Convert bilikZone geoCoordinates to LatLng data
  const bilikZoneGeoCoordinates: LatLng | null = useMemo(() => {
    if (!data?.bilikZoneByPk?.area) return null;
    const center = centroid(toTurfGeometry(data.bilikZoneByPk.area)).geometry
      .coordinates;
    return new LatLng(center[1], center[0]);
  }, [data?.bilikZoneByPk?.area]);

  const saveMapContent = useCallback(() => {
    const interventions: ProInterventionFields[] = [];
    if (mapDrawRef.current === null) return;

    const layers = mapDrawRef.current.getLayers();

    for (const layer of layers) {
      // If layer is a circle, convert it to a polygon
      if ((layer as any)._mRadius) {
        const geoJSON = toGeometryGeoJSON(
          (layer as any)._latlng,
          (layer as any)._mRadius / 1000,
        );

        interventions.push({
          type: 'include',
          area: geoJSON,
        });
        continue;
      }

      const geoJSON = layerToGeoJSON(layer);

      const type =
        (layer as any).options.color === excludeColor ? 'exclude' : 'include';

      if ((geoJSON as Feature).geometry.type === 'Point') {
        continue;
      }

      interventions.push({
        type,
        area: (geoJSON as Feature<Polygon>).geometry,
      });
    }

    setFieldValue(name, interventions);
  }, []);

  if (!data) {
    return (
      <Loader style={{ marginTop: '20px' }} inline>
        Chargement...
      </Loader>
    );
  }

  return (
    <>
      <Label>Carte interactive</Label>
      {errors[name] ? (
        <FieldError color="#eb5a2c" style={{ marginLeft: '10px' }}>
          Au moins une zone d&apos;intervention requise !
        </FieldError>
      ) : null}
      <HelpText style={{ marginTop: '0px' }}>
        Utilisez les boutons en bas à droite de la carte pour dessiner des zones
      </HelpText>
      {data?.proPresentationByPk?.proViews?.length ? (
        <HelpText style={{ marginTop: '0px' }}>
          en bleu clair sont affichés les secteurs des autres zones attachées au
          meme metier{' '}
        </HelpText>
      ) : null}

      <Map
        style={{ height: `${mapHeight}px` }}
        doubleClickZoom={false}
        zoom={10}
        center={bilikZoneGeoCoordinates ?? undefined}
      >
        <MapDraw
          ref={mapDrawRef}
          options={{
            position: 'bottomright',
            drawPolygon: false,
            rotateMode: false,
            cutPolygon: false,
          }}
          add={(map) => {
            map.pm.Toolbar.copyDrawControl('Circle', {
              name: 'circle',
              title: 'Dessiner un cercle',
              block: 'options',
              toggle: true,
              className: 'polygon-circle',
            });
            map.pm.Toolbar.copyDrawControl('Polygon', {
              name: 'include',
              title: "Dessiner une zone d'intervention",
              block: 'options',
              toggle: true,
              className: 'include',
            });
            map.pm.Toolbar.copyDrawControl('Polygon', {
              name: 'exclude',
              title: "Dessiner une zone d'exclusion",
              block: 'options',
              toggle: true,
              className: 'exclude',
            });
            map.pm.Draw['circle'].setOptions({
              hintlineStyle: { color: includeColor, dashArray: [5, 5] },
              templineStyle: { color: includeColor },
            });
            map.pm.Draw['include'].setOptions({
              hintlineStyle: { color: includeColor, dashArray: [5, 5] },
              templineStyle: { color: includeColor },
            });
            map.pm.Draw['exclude'].setOptions({
              hintlineStyle: { color: '#eb5a2c', dashArray: [5, 5] },
              templineStyle: { color: excludeColor },
            });

            map.addEventListener('mouseout', () => {
              saveMapContent();
            });
          }}
          onCreate={(e) => {
            (e.layer as unknown as LeafletFeatureGroup).setStyle({
              color: e.shape === 'exclude' ? excludeColor : includeColor,
              fillOpacity: 0.3,
            });
          }}
        >
          {defaultInterventions.map((intervention, index) => (
            <MapGeoJSON
              key={`intervention-${index}`}
              data={intervention.area as Polygon}
              style={{
                color:
                  intervention.type === 'exclude' ? excludeColor : includeColor,
                fillColor:
                  intervention.type === 'exclude' ? excludeColor : includeColor,
                fillOpacity: 0.3,
                stroke: true,
              }}
            />
          ))}
        </MapDraw>

        {proPostalAddress?.geoCoordinates &&
        proPostalAddress?.displayOption === 'complete' ? (
          <MapGeoJSON
            data={proPostalAddress.geoCoordinates as Point}
            interactive={false}
            pmIgnore={true}
          />
        ) : null}
        {proPostalAddress?.cityGeoCoordinates &&
        proPostalAddress?.displayOption === 'city' ? (
          <MapGeoJSON
            data={proPostalAddress.cityGeoCoordinates as Point}
            interactive={false}
            pmIgnore={true}
          />
        ) : null}
        {data?.proPresentationByPk?.proViews?.map(
          ({ groupedProInterventions }, index) => {
            if (!groupedProInterventions) return null;

            return (
              <MapGeoJSON
                key={`other-intervention-${index}`}
                data={groupedProInterventions.include as Polygon}
                style={{
                  color: includeColor,
                  fillColor: includeColor,
                  fillOpacity: 0.2,
                  stroke: false,
                }}
                pmIgnore={true}
                interactive={false}
              />
            );
          },
        )}
      </Map>
    </>
  );
};

export default InterventionsField;
