import styled from 'styled-components';
const ContentBody = styled.section`
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

export default ContentBody;
