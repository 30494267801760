import {
  BilikPersonInsertInput,
  useBilikPersonFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import BilikPersonFormCreateContainer from './bilik-person-form-create-container';
import {
  createKeycloakUser,
  getKeycloakUserIdByEmail,
  getGroupId,
  addKeycloakUserGroup,
} from 'utils/index';

const BilikPersonFormCreateRepository: FunctionComponent = () => {
  const [insertBilikPersonOne] = useBilikPersonFormCreateMutation();

  /**
   * it return a boolean to validate succes
   * we add the bilik-person to keycloak by creating a keycloak user, and adding this user to the bilik group and bilik-admin subgroup if he is an admin
   */
  const addToKeycloak = useCallback(
    async (
      email: string,
      roleName: string,
      password: string,
      enabled: boolean,
    ): Promise<boolean> => {
      try {
        const credentials = [
          {
            type: 'password',
            value: password,
            temporary: false,
          },
        ];
        // we set the email as the keycloak account username
        await createKeycloakUser({
          email,
          username: email,
          credentials,
          enabled,
        });
        const groupName =
          roleName === 'user' ? 'bilik-people/managers' : 'bilik-people/admins';
        const groupId = await getGroupId(groupName);
        const keycloakUserId = await getKeycloakUserIdByEmail(email);

        if (groupId && keycloakUserId) {
          const status = await addKeycloakUserGroup(keycloakUserId, groupId);

          return status === 204;
        }
      } catch (err) {
        console.error(err);
      }

      return false;
    },
    [],
  );

  const createBilikPerson = useCallback(
    async (bilikPerson: BilikPersonInsertInput, password?: string) => {
      await insertBilikPersonOne({
        variables: {
          object: bilikPerson,
        },
      });

      if (
        !bilikPerson.account?.data.email ||
        !bilikPerson.roleName ||
        !password
      ) {
        return;
      }

      const keycloakAccountCreationSuccess = await addToKeycloak(
        bilikPerson.account.data.email,
        bilikPerson.roleName,
        password,
        bilikPerson.status === 'published',
      );

      if (!keycloakAccountCreationSuccess) {
        throw Error('Keycloak account creation failed');
      }
    },
    [insertBilikPersonOne],
  );

  return (
    <BilikPersonFormCreateContainer createBilikPerson={createBilikPerson} />
  );
};

export default BilikPersonFormCreateRepository;
