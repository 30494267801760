import { Label, Loader } from 'semantic-ui-react';
import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { CountProPresentationReviewByStatusQuery } from 'generated/graphql';
import { CountProPresentationReviewByStatusView } from './count-pro-presentation-review-by-status-view';
import { useNavigate } from 'react-router';

type Props = {
  countByStatus?: CountProPresentationReviewByStatusQuery['countProReviewByStatus'];
  loading: boolean;
  proPresentationId: number;
};

export const CountProPresentationReviewByStatusContainer: FunctionComponent<
  Props
> = ({ countByStatus, loading, proPresentationId }) => {
  const history = useNavigate();

  const okCount = useMemo(() => {
    return countByStatus?.find((thisByStatus) => thisByStatus.status === 'ok')
      ?.count;
  }, [countByStatus]);

  const draftCount = useMemo(() => {
    return countByStatus?.find(
      (thisByStatus) => thisByStatus.status === 'draft',
    )?.count;
  }, [countByStatus]);

  const onProReviewFilterClicked = useCallback(
    (status: string) => {
      history(
        `/pro_reviews?proPresentationId=${proPresentationId}&status=${status}`,
      );
    },
    [proPresentationId],
  );

  if (loading) {
    return (
      <Label
        style={{ marginBottom: '0px', textDecoration: 'none' }}
        color="grey"
        pointing="below"
      >
        &nbsp;&nbsp;
        <Loader active inverted size="mini" />
      </Label>
    );
  }

  return (
    <CountProPresentationReviewByStatusView
      countByStatus={countByStatus}
      draftCount={draftCount}
      okCount={okCount}
      onProReviewFilterClicked={onProReviewFilterClicked}
    />
  );
};
