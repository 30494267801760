import React, { FunctionComponent } from 'react';
import ProContractFormRepository from './pro-contract-form-repository';

export type ProContractFormModalProps = {
  open: boolean;
  onClose: () => void;
};

export type ProContractFormProps = ProContractFormModalProps & {
  onProContractGenerated?: () => void;
  proViewId: number;
  proOrganizationId: number;
  proPersonSuggestions?: {
    givenName: string;
    familyName: string;
    telephone: string;
  }[];
  update?: boolean;
  isProPublished: boolean;
};

const ProContractFormModal: FunctionComponent<ProContractFormProps> = ({
  ...rest
}) => {
  return <ProContractFormRepository {...rest} />;
};

export default ProContractFormModal;
