import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
`;

const Required = styled.span`
  &::after {
    content: ' *';
    color: #db2828;
  }
`;

type LabelProps = {
  required?: boolean;
  htmlFor?: string;
  children: ReactNode;
};

const Label: FunctionComponent<LabelProps> = ({
  htmlFor,
  required,
  children,
}) => (
  <StyledLabel htmlFor={htmlFor}>
    {children}
    {required ? <Required /> : null}
  </StyledLabel>
);

export default Label;
