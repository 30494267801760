import env from 'env';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Icon,
  Label,
  Loader,
  Table,
} from 'semantic-ui-react';
import {
  formatDate,
  formatE164Telephone,
  getToken,
  ToastError,
  ToastSuccess,
} from 'utils';
import DocumentPreviewModal from 'components/document-preview-modal/document-preview-modal';
import ProMandateFormModal from 'components/pro-mandate-form-modal/pro-mandate-form-modal';
import {
  ProMandateSetInput,
  useProMandatesQuery,
  useProMandateUpdateByPkMutation,
} from 'generated/graphql';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import ProMandateUploadModal from './pro-mandate-upload-modal';
import { apiClient } from 'axios-client';
import { DocumentSendFormModal } from '../document-send-form-modal';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';

type ProMandatesProps = {
  proOrganizationId: number;
  receiptEmailSuggestions?: string[];
  proPersonSuggestions?: {
    telephone: string;
    givenName: string;
    familyName: string;
  }[];
};

type ModalState = {
  open: boolean;
};

const ProMandates: FunctionComponent<ProMandatesProps> = ({
  proOrganizationId,
  receiptEmailSuggestions,
  proPersonSuggestions,
}) => {
  const isUserAdmin = useIsUserAdmin();

  const [proMandatePreviewModalState, setProMandatePreviewModalState] =
    useState<{
      open: boolean;
      document?: {
        mimeType: string;
        url: string;
        name: string;
        options?: unknown;
      };
    }>({ open: false });

  const [proMandateCreateModal, setProMandateCreateModal] =
    useState<ModalState>({
      open: false,
    });

  const [proMandateUploadModal, setProMandateUploadModal] = useState<
    ModalState & {
      proMandate?: {
        fileName: string;
        id: number;
      };
    }
  >({ open: false, proMandate: undefined });

  const [proMandateSendModal, setProMandateSendModal] = useState<
    ModalState & { proMandateId?: number }
  >({
    open: false,
    proMandateId: undefined,
  });

  const { data, loading, refetch } = useProMandatesQuery({
    variables: { proOrganizationId },
  });

  const getProMandateStatus = useCallback(
    (status: string) => {
      switch (status) {
        case 'import':
          return <Label color="yellow">Import</Label>;
        case 'draft':
          return <Label color="red">À signer</Label>;
        case 'sent':
          return <Label color="orange">Envoyé</Label>;
        case 'signed':
          return <Label color="green">Signé</Label>;
        case 'archived':
          return <Label color="grey">Archivé</Label>;
      }
    },
    [data],
  );

  const [proMandateUpdateByPkMutation] = useProMandateUpdateByPkMutation();

  const updateProMandate = useCallback(
    async (proMandateId: number, proMandateInput: ProMandateSetInput) => {
      await proMandateUpdateByPkMutation({
        variables: { proMandateId, proMandateInput },
      });
      refetch();
    },
    [proOrganizationId, refetch],
  );

  const proMandates = useMemo(() => data?.proMandate, [data]);

  if (loading) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <>
      {proMandates && proMandates.length > 0 ? (
        <>
          <Table basic="very" selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>RUM</Table.HeaderCell>
                <Table.HeaderCell>Coordonnées Bancaires</Table.HeaderCell>
                <Table.HeaderCell>Créancier</Table.HeaderCell>
                <Table.HeaderCell>Dates</Table.HeaderCell>
                <Table.HeaderCell>Statut</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {proMandates.map((proMandate) => {
                return (
                  <Table.Row key={proMandate.id}>
                    {/* Nom */}
                    <Table.Cell>
                      {proMandate.rum.length > 15
                        ? `...${proMandate.rum.slice(
                            15,
                            proMandate.rum.length,
                          )}`
                        : proMandate.rum}
                    </Table.Cell>
                    <Table.Cell>
                      <>{proMandate.iban}</>
                      <br />
                      <>{proMandate.bic}</>
                    </Table.Cell>
                    <Table.Cell>{proMandate.zohoOrganization.name}</Table.Cell>

                    <Table.Cell>
                      <p>
                        {proMandate.dateCreated ? (
                          <>Créé le : {formatDate(proMandate.dateCreated)}</>
                        ) : (
                          'Inconnu'
                        )}
                        {proMandate.dateSigned ? (
                          <>
                            <br />
                            Signé le :{' '}
                            <u>{formatDate(proMandate.dateSigned)}</u>
                          </>
                        ) : null}
                        {proMandate.dateArchived ? (
                          <>
                            <br />
                            Archivé le {formatDate(proMandate.dateArchived)}
                          </>
                        ) : null}
                      </p>
                    </Table.Cell>
                    {/* Type */}
                    <Table.Cell>
                      {getProMandateStatus(proMandate.status)}
                    </Table.Cell>

                    {/* Actions */}
                    <Table.Cell textAlign="right">
                      <Dropdown
                        text="Actions"
                        labeled
                        button
                        floating
                        direction="left"
                        className="icon"
                      >
                        <Dropdown.Menu>
                          {['draft', 'sent'].includes(proMandate.status) && (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setProMandateSendModal({
                                    open: true,
                                    proMandateId: proMandate.id,
                                  });
                                }}
                              >
                                <Icon name="mail" />
                                Envoyer au pro pour signature
                              </Dropdown.Item>
                              {isUserAdmin && (
                                <Dropdown.Item
                                  onClick={() => {
                                    setProMandateUploadModal({
                                      open: true,
                                      proMandate: {
                                        fileName: proMandate.fileName ?? '',
                                        id: proMandate.id,
                                      },
                                    });
                                  }}
                                >
                                  <Icon name="upload" />
                                  Ajouter document signé
                                </Dropdown.Item>
                              )}
                            </>
                          )}
                          <Dropdown.Item
                            onClick={() => {
                              setProMandatePreviewModalState({
                                open: true,
                                document: {
                                  mimeType: 'application/pdf',
                                  url: `${
                                    env.API_URL
                                  }/attachment/object-by-key?key=${encodeURIComponent(
                                    `pro-organizations-documents/${proOrganizationId}/${
                                      proMandate.fileName ?? ''
                                    }`,
                                  )}`,
                                  name: proMandate.fileName ?? '',
                                  options: {
                                    httpHeaders: {
                                      Authorization: `Bearer ${getToken()}`,
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            <Icon name="eye" />
                            Voir
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              window.open(
                                `${
                                  env.API_URL
                                }/attachment/object-by-key-token?key=${encodeURIComponent(
                                  `pro-organizations-documents/${proOrganizationId}/${
                                    proMandate.fileName ?? ''
                                  }`,
                                )}&token=${getToken()}`,
                                '_blank',
                              );
                            }}
                          >
                            <Icon name="download" />
                            Télécharger
                          </Dropdown.Item>
                          {proMandate.status !== 'archived' && (
                            <ConfirmModal
                              trigger={
                                <Dropdown.Item>
                                  <Icon name="archive" />
                                  Archiver
                                </Dropdown.Item>
                              }
                              header="Confirmation"
                              content={
                                <p>
                                  En archivant le mandat, vous ne pourrez plus
                                  l&apos;attacher à un abonnement. Continuer ?
                                </p>
                              }
                              onConfirm={async (): Promise<void> => {
                                await updateProMandate(proMandate.id, {
                                  status: 'archived',
                                }).catch((error) => {
                                  ToastError(
                                    'Erreur',
                                    "Impossible d'archiver le mandat",
                                  );
                                  throw error;
                                });
                                ToastSuccess('Succès', `Mandat archivé`);
                              }}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Container fluid textAlign="right">
            <Button
              icon="refresh"
              onClick={() => refetch()}
              style={{ minWidth: '45px', minHeight: '32px' }}
              type="button"
            />
            <Button
              positive
              icon="plus"
              type="button"
              style={{ minWidth: '45px', minHeight: '32px' }}
              onClick={(): void => {
                setProMandateCreateModal({ open: true });
              }}
            />
          </Container>
        </>
      ) : (
        <Grid>
          <Grid.Column textAlign="center">
            <Button
              positive
              style={{ marginTop: '15px', width: '210px' }}
              onClick={() => setProMandateCreateModal({ open: true })}
            >
              Générer un mandat
            </Button>
          </Grid.Column>
        </Grid>
      )}

      <ProMandateFormModal
        open={proMandateCreateModal.open}
        proOrganizationId={proOrganizationId}
        onProMandateCreated={async () => refetch()}
        onClose={(): void => {
          setProMandateCreateModal({ open: false });
        }}
        existingProMandates={proMandates?.filter(
          (proMandate) => proMandate.status !== 'archived',
        )}
      />
      <DocumentPreviewModal
        open={proMandatePreviewModalState.open}
        onClose={(): void => {
          setProMandatePreviewModalState({ open: false });
        }}
        document={proMandatePreviewModalState.document}
      />
      <ProMandateUploadModal
        proOrganizationId={proOrganizationId}
        onClose={(): void => {
          setProMandateUploadModal({ open: false });
        }}
        open={proMandateUploadModal.open}
        proMandate={proMandateUploadModal.proMandate}
        onProMandateUploaded={async (proMandateId, dateSigned) => {
          await updateProMandate(proMandateId, {
            status: 'signed',
            dateSigned: dateSigned,
          }).catch((error) => {
            ToastError('Erreur', 'Impossible de mettre à jour le mandat');
            throw error;
          });
          refetch();
        }}
      />
      <DocumentSendFormModal
        onClose={(): void => {
          setProMandateSendModal({ open: false });
        }}
        additionalFields={{
          givenName: true,
          familyName: true,
        }}
        open={proMandateSendModal.open}
        onSubmitDocument={async (values) => {
          const response = await apiClient.post('/form/pro-mandate/send', {
            proMandateId: proMandateSendModal.proMandateId,
            telephone: values.telephone
              ? formatE164Telephone(values.telephone)
              : undefined,
            proReceiptEmail: values.proReceiptEmail
              ? values.proReceiptEmail.trim()
              : undefined,
            familyName: values.familyName?.trim(),
            givenName: values.givenName?.trim(),
          });
          refetch();
          return response;
        }}
        receiptEmailSuggestions={receiptEmailSuggestions}
        proPersonSuggestions={proPersonSuggestions}
      />
    </>
  );
};

export default ProMandates;
