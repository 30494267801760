import React, { useState, FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ToastError, ToastSuccess, ToastWarn } from '../../../utils/toast';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import { apiClient } from 'axios-client';

type CheckButtonProps = {
  proReviewId: number;
  onCompleted?: () => void;
};

const CheckButton: FunctionComponent<CheckButtonProps> = ({
  proReviewId,
  onCompleted,
}) => {
  const { currentBilikPerson } = useCurrentBilikPerson();
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Button
      disabled={isSubmitting}
      loading={isSubmitting}
      primary
      onClick={async (event): Promise<void> => {
        event.preventDefault();
        setSubmitting(true);

        try {
          const response = await apiClient.post(`/form/review-pro/check`, {
            proReviewId,
            checkerId: currentBilikPerson?.id,
          });

          response.data.success
            ? ToastSuccess('Succès', 'Réponse envoyée')
            : ToastWarn('Attention', response.data.message);
        } catch (error) {
          setSubmitting(false);
          ToastError('Erreur', 'Erreur lors de la demande de vérification');
          throw error;
        }

        if (onCompleted) {
          onCompleted();
        }
      }}
    >
      <Icon name="envelope" />
      Envoyer
    </Button>
  );
};

export default CheckButton;
