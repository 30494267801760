import React, { FunctionComponent } from 'react';
import { pathOr } from 'remeda';
import { MongoEvent } from 'interfaces/events.interface';
import { Popup } from 'semantic-ui-react';
import TimelineEvent from '../timeline-event';
import { CallRecording } from 'components/call-recording/call-recording';
import { formatTelephone } from 'utils';

const getCallDurationFromEvent = (event: MongoEvent): number => {
  return pathOr(
    event,
    ['data', 'twilio', 'CallDuration'] as unknown as [keyof MongoEvent],
    null,
  )
    ? event.data.twilio.CallDuration
    : event.data.twilio.Duration;
};

const formatDuration = (seconds: number): string => {
  const min = Math.floor(seconds / 60);
  const s = seconds % 60;
  return `${min > 0 ? `${min}min ` : ''}${s}s`;
};

const isProActor = (event: MongoEvent): boolean => {
  return pathOr(
    event,
    ['actors', 'pro', 'proPresentation', 'name'] as unknown as [
      keyof MongoEvent,
    ],
    false,
  );
};

const isBilikActor = (event: MongoEvent): boolean => {
  return pathOr(
    event,
    ['actors', 'bilik', 'bilikZone', 'mainCity'] as unknown as [
      keyof MongoEvent,
    ],
    false,
  );
};

const CallStatusUpdatedV3: FunctionComponent<CallStatusUpdatedProps> = ({
  event,
}) => {
  const getCallTargetFromEvent = (event: MongoEvent): string | undefined => {
    if (isProActor(event)) {
      return `${event.actors.pro?.proPresentation?.name} (${event.actors.pro?.bilikZone.mainCityCode} - ${event.actors.pro?.proPresentation.mainTrade?.name})`;
    }

    if (isBilikActor(event)) {
      return `Bilik ${event.actors.bilik?.bilikZone.mainCity}`;
    }

    return undefined;
  };

  return (
    <TimelineEvent
      date={event.date}
      callForwarded={pathOr(event, ['data', 'twilio', 'Forwarded'], false)}
      icon="phone"
      title={
        isProActor(event)
          ? 'Appel vers pro'
          : isBilikActor(event)
          ? 'Appel vers Bilik'
          : 'Appel inconnu'
      }
      takingContact
      initiator={'particulier'}
    >
      <h3>
        Le particulier a appelé <span>{getCallTargetFromEvent(event)}</span> via
        le {formatTelephone(event.initiator.pi.telephone)} (
        {getCallDurationFromEvent(event) > 0
          ? formatDuration(getCallDurationFromEvent(event))
          : 'Appel en absence'}
        )
      </h3>
    </TimelineEvent>
  );
};

const CallStatusUpdatedV4: FunctionComponent<CallStatusUpdatedProps> = ({
  event,
}) => {
  const getCallTargetFromEvent = (event: MongoEvent): string | undefined => {
    if (isProActor(event)) {
      return `${event.actors.pro?.proPresentation?.name} (${event.actors.pro?.bilikZone.mainCityCode} - ${event.actors.pro?.proPresentation.mainTrade?.name})`;
    }

    if (isBilikActor(event)) {
      return `Bilik ${event.actors.bilik?.bilikZone.mainCity}`;
    }

    return undefined;
  };

  const isForwarded =
    pathOr(event, ['data', 'twilio', 'Forwarded'], false) ||
    pathOr(event, ['data', 'vonage', 'forwarded'], false);

  return (
    <TimelineEvent
      date={event.date}
      callForwarded={isForwarded}
      icon="phone"
      title={
        isProActor(event)
          ? 'Appel vers pro'
          : isBilikActor(event)
          ? 'Appel vers Bilik'
          : 'Appel inconnu'
      }
      takingContact
      initiator={'particulier'}
    >
      <h3>
        Le particulier a appelé <span>{getCallTargetFromEvent(event)}</span> via
        le {formatTelephone(event.initiator.pi.telephone)} (
        {Number(event.data.vonage.duration) > 0
          ? formatDuration(event.data.vonage.duration)
          : 'Appel en absence'}
        )
      </h3>
      <CallRecording
        conversationUuid={event.data.vonage?.conversation_uuid}
        duration={Number(event.data.vonage?.duration ?? 0)}
      />
    </TimelineEvent>
  );
};

type CallStatusUpdatedProps = {
  event: MongoEvent;
};

/**
 * v0 : Callr in a db
 * v1: Callr without db (flat data)
 * v2: Twilio
 * v3: Twilio with (cityCode - mainTrade)
 * V4: Vonage
 */
const CallStatusUpdated: FunctionComponent<CallStatusUpdatedProps> = ({
  event,
}) => {
  if (event.version === 4) {
    return <CallStatusUpdatedV4 event={event} />;
  }

  if (event.version === 3) {
    return <CallStatusUpdatedV3 event={event} />;
  }

  const getCallTargetFromEvent = (event: MongoEvent): string | undefined => {
    if (isProActor(event)) {
      return event.actors.pro?.proPresentation?.name;
    }

    if (isBilikActor(event)) {
      return `Bilik ${event.actors.bilik?.bilikZone.mainCity}`;
    }

    return undefined;
  };

  return (
    <TimelineEvent
      date={event.date}
      icon="phone"
      callForwarded={pathOr(event, ['data', 'twilio', 'Forwarded'], false)}
      title={
        isProActor(event)
          ? 'Appel vers pro'
          : isBilikActor(event)
          ? 'Appel vers Bilik'
          : 'Appel inconnu'
      }
      takingContact
      initiator={'particulier'}
    >
      {/* V2 (Twilio) */}
      {event.version === 2 ? (
        <h3>
          Le particulier a appelé <span>{getCallTargetFromEvent(event)}</span>{' '}
          via le {formatTelephone(event.initiator.pi.telephone)} (
          {getCallDurationFromEvent(event) > 0
            ? formatDuration(getCallDurationFromEvent(event))
            : 'Appel en absence'}
          )
        </h3>
      ) : null}

      {/* Callr version (V1) */}
      {event.version === 1 ? (
        <h3>
          Le particulier a appelé <span>{getCallTargetFromEvent(event)}</span>{' '}
          via le {formatTelephone(event.initiator.pi.telephone)} (
          {event.data.callrCall.duration_answered > 0
            ? formatDuration(event.data.callrCall.duration_answered)
            : 'Appel en absence'}
          )
        </h3>
      ) : null}

      {/* Data migration version (V0) */}
      {event.version === 0 ? (
        <h3>
          Le particulier a appelé{' '}
          {getCallTargetFromEvent(event) ? (
            <span>{getCallTargetFromEvent(event)}</span>
          ) : (
            <Popup
              content={`ID Call tracking : ${event.actors.pro.proView['scenarioHash']}`}
              trigger={<span>Pro inconnu ou plus dans bilik</span>}
              position="top center"
            />
          )}{' '}
          via le {formatTelephone(event.initiator.pi.telephone)} (
          {event.data.callrCall.durationAnswered > 0
            ? formatDuration(event.data.callrCall.durationAnswered)
            : 'Appel en absence'}
          )
        </h3>
      ) : null}
    </TimelineEvent>
  );
};

export default CallStatusUpdated;
