import { refetchReviewCounter } from './refetch-review-counter.command';

export enum Commands {
  REFETCH_REVIEW_COUNTER,
  REFETCH_KEYWORD_TRADE_COUNTER,
}

export const triggerCommand = (action: Commands): void => {
  switch (action) {
    case Commands.REFETCH_REVIEW_COUNTER:
      refetchReviewCounter();
      break;
    default:
      console.error(`Unrecognized command : ${action}`);
  }
};
