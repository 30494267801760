import React, { FunctionComponent, useCallback, useState } from 'react';
import { ToastSuccess, ToastWarn } from 'utils';
import { PublishProReviewButtonView } from './publish-pro-review-button-view';

type PublishProReviewContainerProps = {
  publishProReview: (proofProvider?: string) => Promise<{
    success: boolean;
    message: string;
  }>;
  refetch: () => void;
  isACheckEnd: boolean;
  disabled?: boolean;
};

export const PublishProReviewButtonContainer: FunctionComponent<
  PublishProReviewContainerProps
> = ({ publishProReview, refetch, isACheckEnd, disabled }) => {
  const [isPublishing, setIsPublishing] = useState(false);

  const onPublishButtonClicked = useCallback(
    async (proofProvider?: string) => {
      setIsPublishing(true);
      const response = await publishProReview(proofProvider);
      response.success
        ? ToastSuccess('Succès', response.message)
        : ToastWarn('Attention', response.message);
      setIsPublishing(false);
      refetch();
    },
    [publishProReview],
  );

  return (
    <PublishProReviewButtonView
      isACheckEnd={isACheckEnd}
      isPublishing={isPublishing}
      onPublishButtonClicked={onPublishButtonClicked}
      disabled={disabled}
    />
  );
};
