import React, { FunctionComponent, useCallback } from 'react';
import { useParams } from 'react-router';
import {
  useProReviewFormUpdateQuery,
  useProReviewFormUpdateMutation,
  ProReviewSetInput,
} from 'generated/graphql';
import { ProReviewFormUpdateContainer } from './pro-review-form-update-container';
import sendEvent from 'utils/send-event';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';

export const ProReviewFormUpdateRepository: FunctionComponent = () => {
  const params = useParams<{ proReviewId?: string }>();

  const proReviewId = Number(params.proReviewId);

  const { loading, data } = useProReviewFormUpdateQuery({
    variables: { proReviewId },
  });

  const { currentBilikPerson } = useCurrentBilikPerson();

  const [updateProReviewByPk] = useProReviewFormUpdateMutation();

  const updateProReview = useCallback(
    async (proReview: ProReviewSetInput) => {
      const updatedProReview = await updateProReviewByPk({
        variables: {
          proReviewId,
          proReview: {
            ...proReview,
            rating: !proReview.rating ? undefined : proReview.rating,
            dateOfWork: proReview.dateOfWork ?? null,
          },
        },
      });

      await sendEvent(
        'ProReviewEdited',
        {
          proReview: { ...proReview, id: proReviewId },
        },
        {
          pi: {
            email: proReview.email,
            telephone: proReview.telephone,
          },
          pro: {
            proPresentation: {
              name: updatedProReview.data?.updateProReviewByPk?.proPresentation
                .name,
              mainTrade:
                updatedProReview.data?.updateProReviewByPk?.proPresentation
                  .mainTrade,
            },
          },
        },
        {
          bilik: {
            id: currentBilikPerson?.id,
            email: currentBilikPerson?.account.email,
            givenName: currentBilikPerson?.givenName,
            familyName: currentBilikPerson?.familyName,
          },
        },
        2,
      );
    },
    [updateProReviewByPk, data, currentBilikPerson],
  );

  return (
    <ProReviewFormUpdateContainer
      updateProReview={updateProReview}
      loading={loading}
      formData={data?.proReviewByPk}
    />
  );
};
