import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { FeatureGroup as LeafletFeatureGroup, Layer, Map } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GeomanControls, GeomanProps } from 'react-leaflet-geoman-v2';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import './map-draw.css';

type MapDrawProps = {
  children?: ReactNode;
  add?: (map: Map) => void;
} & GeomanProps;

type LeafletFeatureGroupImperativeProps = {
  getLayers: () => Layer[] | null;
};

const MapDraw = forwardRef<LeafletFeatureGroupImperativeProps, MapDrawProps>(
  ({ children, add, ...rest }, ref) => {
    const map = useMap();

    const featureGroupRef = useRef<LeafletFeatureGroup>(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          getLayers() {
            return map.pm.getGeomanLayers();
          },
        };
      },
      [],
    );

    useEffect(() => {
      if (add) add(map);
    }, []);

    return (
      <FeatureGroup ref={featureGroupRef}>
        <GeomanControls
          lang="fr"
          {...rest}
          options={{
            ...rest.options,

            drawPolyline: rest.options?.drawPolyline,
            drawMarker: rest.options?.drawMarker,
            drawCircle: rest.options?.drawCircle,
            drawCircleMarker: rest.options?.drawCircleMarker,
            drawText: rest.options?.drawText,
            drawRectangle: rest.options?.drawRectangle,
            drawPolygon: rest.options?.drawPolygon,
          }}
        />
        {children}
      </FeatureGroup>
    );
  },
);

export default MapDraw;
