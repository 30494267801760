import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { Button, Icon, Form, Modal } from 'semantic-ui-react';
import { NoteFields } from './note-fields-type';
import { formatDateTime } from 'utils';

const NoteView: FunctionComponent = () => {
  const {
    handleSubmit,
    handleReset,
    isSubmitting,
    values,
    initialValues,

    setFieldValue,
  } = useFormikContext<NoteFields>();

  const [displayNote, setDisplayNote] = useState(
    initialValues.id ? true : false,
  );
  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  return displayNote ? (
    <>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <FormField
          type="textarea"
          name="message"
          rows={9}
          resize="none"
          placeholder="Note interne sur le particulier"
        />
        <div>
          {initialValues.id && (
            <span
              style={{
                color: '#999',
                float: 'left',
                fontSize: '12px',
              }}
            >
              Dernière modification le{' '}
              {formatDateTime(
                values.lastUpdatedDate ? values.lastUpdatedDate.toString() : '',
              ) || '?'}{' '}
              {values.bilikPerson
                ? 'par ' + values.bilikPerson.givenName
                : null}
            </span>
          )}
          <Button
            type="submit"
            primary
            style={{ float: 'right' }}
            loading={isSubmitting}
            disabled={
              isSubmitting ||
              values.message?.trim() === initialValues.message?.trim()
            }
          >
            <Icon name="save" />
            Enregistrer
          </Button>
          <Button
            type="button"
            icon="trash"
            style={{ float: 'right' }}
            loading={isSubmitting}
            disabled={isSubmitting}
            color="red"
            inverted
            onClick={() => {
              if (!initialValues.id) {
                setDisplayNote(false);
                return;
              }

              setShouldOpenModal(true);
            }}
          />
        </div>
      </Form>
      <Modal
        open={shouldOpenModal}
        onClose={() => setShouldOpenModal(false)}
        size="tiny"
      >
        <Modal.Header>Supprimer la note</Modal.Header>
        <Modal.Content>
          <p>Êtes-vous sûr de vouloir supprimer cette note ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShouldOpenModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            color="red"
            onClick={() => {
              setFieldValue('message', '');
              setDisplayNote(false);
              handleSubmit();
              setShouldOpenModal(false);
            }}
          >
            Supprimer
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  ) : (
    <Button
      type="button"
      style={{ float: 'right' }}
      primary
      onClick={() => setDisplayNote(true)}
    >
      <Icon name="pencil" />
      Ajouter une note
    </Button>
  );
};
export default NoteView;
