import React, { FunctionComponent, useCallback } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import ProCallsTableContainer from './calls-table-container';
import { apiClient } from 'axios-client';
import { useCallsTableGloballyBlockedQuery } from 'generated/graphql';

type CallsTableRepositoryProps = {
  filters?: {
    startDate?: Date;
    endDate?: Date;
    proViewId?: number;
    filters?: any[];
  };
  skip: number;
  limit: number;
  orderBy?: {
    column: string;
    direction: string;
  };
  onSort?: (column: string, direction: string) => void;
  onFilter?: (column: string, value: string) => void;
};

const CallsTableRepository: FunctionComponent<CallsTableRepositoryProps> = ({
  filters,
  skip,
  limit,
  orderBy,
  onSort,
  onFilter,
}) => {
  const fetchCalls = useCallback(async () => {
    return await apiClient
      .post<MongoEvent[]>(`/event/calls`, {
        ...filters,
        skip,
        limit,
        orderByDate: orderBy?.direction === 'ascending' ? 'ASC' : 'DESC',
      })
      .then((response) => {
        return response.data;
      });
  }, [orderBy?.direction, filters, skip, limit]);

  const { data } = useCallsTableGloballyBlockedQuery();

  return (
    <ProCallsTableContainer
      fetchCalls={fetchCalls}
      filters={filters}
      onSort={onSort}
      onFilter={onFilter}
      orderBy={orderBy}
      globallyBlocked={data?.globallyBlockedIdentity || []}
    />
  );
};

export default CallsTableRepository;
