import { apiClient } from 'axios-client';
import { RecurringInvoice } from 'interfaces/recurring-invoice';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { ToastError } from 'utils';
import ProSubscriptionsTable from '../pro-subscriptions-table/pro-subscriptions-table';

type ProSubscriptionsProps = {
  proOrganizationId: number;
};

const ProSubscriptions: FunctionComponent<ProSubscriptionsProps> = ({
  proOrganizationId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<RecurringInvoice[]>();

  const fetchSubscriptions = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await apiClient
      .get<RecurringInvoice[]>(
        `/zoho/subscription/pro_organization/${encodeURIComponent(
          proOrganizationId,
        )}`,
      )
      .catch((error) => {
        ToastError('Erreur', 'Impossible de récupérer les abonnements.');
        setLoading(false);
        throw error;
      });

    setSubscriptions(response.data);
    setLoading(false);
  }, [proOrganizationId]);

  useEffect(() => {
    setSubscriptions(undefined);
  }, [proOrganizationId]);

  if (loading) {
    return (
      <Loader
        size="large"
        active
        inline="centered"
        content="Chargement des abonnements..."
      />
    );
  }

  if (!subscriptions) {
    return (
      <Grid>
        <Grid.Column textAlign="center">
          <Button
            onClick={fetchSubscriptions}
            style={{ marginTop: '15px', width: '210px' }}
          >
            Voir les abonnements
          </Button>
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <ProSubscriptionsTable
      subscriptions={subscriptions}
      onSubscriptionDeleted={(): void => {
        fetchSubscriptions();
      }}
    />
  );
};

export default ProSubscriptions;
