import { Formik } from 'formik';
import { ProOrganizationInsertInput } from 'generated/graphql';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { ProOrganizationFormFields } from './pro-organization-form-fields-type';
import { ProOrganizationFormModalProps } from './pro-organization-form-modal';
import proOrganizationFormValidationSchema from './pro-organization-form-validation-schema';
import ProOrganizationFormView from './pro-organization-form-view';

type ProOrganizationFormCreateContainerProps = ProOrganizationFormModalProps & {
  createProOrganization: (
    proOrganizationInput: ProOrganizationInsertInput,
  ) => Promise<number | undefined>;
};

const ProOrganizationFormCreateContainer: FunctionComponent<
  ProOrganizationFormCreateContainerProps
> = ({ createProOrganization, onClose, ...rest }) => {
  const onSubmit = useCallback(async (values: ProOrganizationFormFields) => {
    await createProOrganization({
      name: values.name.trim(),
      street1: values.street1.trim(),
      street2: values.street2.trim(),
      postalCode: values.postalCode.trim(),
      tvaNumber: values.tvaNumber.trim(),
      siret: values.siret.trim(),
      city: values.city.trim(),
      billingEmail: values.billingEmail.trim(),
      isForeign: values.isForeign,
      businessStatus: values.businessStatus,
    }).catch((error) => {
      ToastError('Erreur', "Impossible de créer l'entrerpise");
      throw error;
    });

    if (onClose) {
      onClose();
    }

    ToastSuccess('Succès', 'Entreprise créé');

    return;
  }, []);

  const initialValues: ProOrganizationFormFields = useMemo(() => {
    return {
      name: '',
      street1: '',
      street2: '',
      postalCode: '',
      city: '',
      siret: '',
      isForeign: false,
      tvaNumber: '',
      billingEmail: '',
      formattedAddress: '',
      businessStatus: '',
    };
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={proOrganizationFormValidationSchema}
    >
      <ProOrganizationFormView onClose={onClose} shouldStep={true} {...rest} />
    </Formik>
  );
};
export default ProOrganizationFormCreateContainer;
