import React, { FunctionComponent, useCallback } from 'react';
import { DuplicateProReviewFormContainer } from './duplicate-pro-review-form-container';
import { apiClient } from 'axios-client';
import { useDuplicateProReviewQuery } from 'generated/graphql';

type DuplicateProReviewFormRepositoryProps = {
  proReviewId: number;
  currentProPresentationId: number;
  onCompleted: () => void;
};

export const DuplicateProReviewFormRepository: FunctionComponent<
  DuplicateProReviewFormRepositoryProps
> = ({ proReviewId, currentProPresentationId, onCompleted }) => {
  const duplicateProReviewToProView = useCallback(
    async (toProViewId: number) => {
      await apiClient.post(`/form/review-pro/duplicate`, {
        proReviewId: proReviewId,
        toProPresentationId: toProViewId,
      });
      refetch();
      onCompleted();
    },
    [proReviewId],
  );

  // get all pro presentation ids that already have this review
  // it will be usefull to avoid multiple same duplicated review to be on same pro presentation
  const { data, loading, refetch } = useDuplicateProReviewQuery({
    variables: {
      proReviewId,
    },
  });

  const reviewedProPresentationIds =
    data?.proPresentation.map((p) => p.id) ?? [];

  return (
    <DuplicateProReviewFormContainer
      loading={loading}
      proPresentationIds={[
        currentProPresentationId,
        ...reviewedProPresentationIds,
      ]}
      duplicateProReviewToProView={duplicateProReviewToProView}
    />
  );
};
