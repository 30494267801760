import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useMemo,
} from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import styled from 'styled-components';

const DropzoneWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 15px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #d6d6d6;
  border-style: dashed;
  background-color: #eeeeee;
  color: #979797;
  font-size: 1.2em;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 100%;
  margin-bottom: 30px;
`;

type DropzoneProps = {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  children: ReactNode;
};

const Dropzone: FunctionComponent<DropzoneProps> = ({
  children,
  isDragAccept,
  isDragActive,
  isDragReject,
  getRootProps,
  getInputProps,
}) => {
  const activeStyle: CSSProperties = {
    borderColor: '#2196f3',
  };

  const acceptStyle: CSSProperties = {
    borderColor: '#3C8DBC',
  };

  const rejectStyle: CSSProperties = {
    borderColor: '#D73925',
  };

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <DropzoneWrapper {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {children}
    </DropzoneWrapper>
  );
};

export default Dropzone;
