import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type ConfirmModalProps = {
  header: string;
  content: React.ReactElement;
  onConfirm?: () => void | Promise<void>;
  onDecline?: () => void | Promise<void>;
  trigger?: React.ReactElement;
  modalOpen?: boolean;
};

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  header,
  content,
  onConfirm,
  onDecline,
  trigger,
  modalOpen,
}) => {
  const [open, setOpen] = useState(modalOpen);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  return (
    <Fragment>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: () => setOpen(true),
        })}
      <Modal
        size="tiny"
        open={open}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            disabled={loading}
            type="button"
            negative
            icon="remove"
            content="Non"
            onClick={async (): Promise<void> => {
              setLoading(true);
              if (onDecline) {
                await onDecline();
              }
              setLoading(false);
              setOpen(false);
            }}
          />
          <Button
            loading={loading}
            disabled={loading}
            type="button"
            onClick={async (): Promise<void> => {
              setLoading(true);
              if (onConfirm) {
                await onConfirm();
              }
              setLoading(false);
              setOpen(false);
            }}
            positive
            icon="checkmark"
            content="Oui"
          />
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default ConfirmModal;
