import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEmailPreview from '../timeline-email-preview';
import TimelineEvent from '../timeline-event';
import { Message } from 'semantic-ui-react';
import { getBilikUserName } from 'utils/get-bilik-username';

const ProReviewCheckV1: FunctionComponent<ProReviewCheckProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Vérification avis initiée"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Vérification de l&apos;avis sur{' '}
        <strong>{event.actors.pro.proPresentation.name}</strong>
      </h3>
      <div>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
          link={{
            url: `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            text: 'Voir avis',
          }}
        />
      </div>
    </TimelineEvent>
  );
};

const ProReviewCheckV2: FunctionComponent<ProReviewCheckProps> = ({
  event,
}) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="comment"
      title="Vérification avis initiée"
      initiator="bilik-user"
      bilikUserName={getBilikUserName(event.initiator.bilik)}
    >
      <h3>
        Vérification de l&apos;avis sur{' '}
        <strong>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proPresentation.mainTrade?.name})
        </strong>{' '}
        initiée
      </h3>
      <div>
        <TimelineEmailPreview
          eventDate={event.date}
          messageId={event.data.email?.messageId}
          link={{
            url: `/pro_reviews?proReviewId=${event.data.proReview._id}&isAllZones=1`,
            text: 'Voir avis',
          }}
        />
      </div>
    </TimelineEvent>
  );
};

type ProReviewCheckProps = {
  event: MongoEvent;
};

const ProReviewCheck: FunctionComponent<ProReviewCheckProps> = ({ event }) => {
  if (event.version === 1) {
    return <ProReviewCheckV1 event={event} />;
  } else if (event.version === 2) {
    return <ProReviewCheckV2 event={event} />;
  }
  return (
    <Message
      error
      content={`Pas d'event handler trouvé pour ProReviewCheck (version ${event.version})`}
    />
  );
};

export default ProReviewCheck;
