import { ZohoCreditNote } from 'interfaces/zoho-credit-note.interface';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Container, Loader } from 'semantic-ui-react';
import { ToastError } from 'utils';
import ProCreditNotesView from './pro-credit-notes-view';

type ProCreditNotesContainerProps = {
  fetchCreditNotes: (pageNumber: number) => Promise<ZohoCreditNote[]>;
};

const ProCreditNotesContainer: FunctionComponent<ProCreditNotesContainerProps> = ({
  fetchCreditNotes,
}) => {
  const [page, setPage] = useState(1);
  const [creditNotes, setCreditNotes] = useState<ZohoCreditNote[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);

    const newCreditNotes = await fetchCreditNotes(page).catch((error) => {
      ToastError('Erreur', 'Impossible de charger les avoirs');
      setLoading(false);
      setError(true);
      throw error;
    });

    setCreditNotes([...(creditNotes ?? []), ...newCreditNotes]);
    setLoading(false);
  }, [page, fetchCreditNotes]);

  const fetchMore = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    fetch();
  }, [page]);

  if (error) {
    return (
      <Container fluid textAlign="center">
        <Button
          style={{ marginTop: '15px' }}
          type="button"
          onClick={(): void => {
            setError(false);
            fetch();
          }}
        >
          Rééssayer
        </Button>
      </Container>
    );
  }

  if (!creditNotes) {
    return (
      <Loader
        size="large"
        active
        inline="centered"
        content="Chargement des avoirs..."
      />
    );
  }

  return (
    <ProCreditNotesView
      fetchMore={fetchMore}
      creditNotes={creditNotes}
      loading={loading}
    />
  );
};

export default ProCreditNotesContainer;
