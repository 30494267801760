import React, {
  FunctionComponent,
  ReactNode,
  Ref,
  useEffect,
  useRef,
} from 'react';
import {
  FeatureGroup,
  MapContainer,
  MapContainerProps,
  TileLayer,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import env from 'env';
import MapInteraction, {
  MapInteractionProps,
} from './map-interaction/map-interaction';
import { FitBoundsOptions } from 'leaflet';

type MapProps = {
  height?: string;
  children?: ReactNode;
  fit?: boolean;
} & MapContainerProps;

interface FitBoundsProps {
  children?: ReactNode;
}

const FitBounds: FunctionComponent<FitBoundsProps> = ({ children }) => {
  const map = useMap();
  const featureGroupRef = useRef<L.FeatureGroup>(null);
  const boundOptions: FitBoundsOptions = { maxZoom: 12 };

  useEffect(() => {
    if (!featureGroupRef.current) return;
    const bounds = featureGroupRef.current.getBounds();
    if (!bounds.isValid()) return;

    map.fitBounds(bounds, boundOptions);

    map.addEventListener('resize', () => {
      map.fitBounds(bounds, boundOptions);
    });

    return () => {
      map.removeEventListener('resize');
    };
  }, [map]);

  return <FeatureGroup ref={featureGroupRef}>{children}</FeatureGroup>;
};

const Map: FunctionComponent<MapProps & MapInteractionProps> = ({
  children,
  height,
  zoom = 6,
  center = [46.71, 3],
  fit = true,
  ...rest
}) => {
  const mapInteractionProps: MapInteractionProps = rest;

  const mapProps: MapProps = rest;

  return (
    <MapContainer
      style={{ height: height ?? '100vh' }}
      zoom={zoom}
      center={center}
      maxZoom={15}
      minZoom={6}
      zoomSnap={0.5}
      scrollWheelZoom={false}
      attributionControl={false}
      {...mapProps}
    >
      <TileLayer url={`${env.MAP_URL}/styles/bilik/{z}/{x}/{y}.png`} />
      {fit ? <FitBounds>{children}</FitBounds> : children}
      <MapInteraction {...mapInteractionProps} />
    </MapContainer>
  );
};

export default Map;
