import { Checkbox, Divider, Dropdown, List } from 'semantic-ui-react';
import React, { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  border-color: #767676 !important;

  .menu .list {
    .checkbox {
      margin-right: 10px;
      margin-bottom: -4px;
    }

    .list .item {
      padding: 5px 15px;
    }

    .item {
      padding: 11px;
    }
  }
`;

type Props = {
  filterGroups: any;
  isFilterChecked: (filter: any) => boolean;
  toggleFilter: (checked: boolean, filter: any) => void;
  direction?: 'left' | 'right';
  float?: 'left' | 'right';
};

export const TimelineFilterEventsView: FunctionComponent<Props> = ({
  filterGroups,
  isFilterChecked,
  toggleFilter,
  direction,
  float,
}) => {
  return (
    <StyledDropdown
      text="Filtres"
      icon="filter"
      floating
      labeled
      button
      className="icon"
      simple
      direction={direction}
      style={float ? { float } : undefined}
    >
      <Dropdown.Menu>
        <List>
          {filterGroups.map((filterGroup, filterGroupIndex) => (
            <Fragment key={`filter-group-${filterGroupIndex}`}>
              <List.Item>
                <List.Content>
                  <Checkbox
                    checked={isFilterChecked(filterGroup)}
                    onChange={(event, { checked }): void => {
                      toggleFilter(!!checked, filterGroup);
                    }}
                  />
                  {filterGroup.name}
                  {filterGroup.filters ? (
                    <List.List>
                      {filterGroup.filters.map((filter, filterIndex) => (
                        <Fragment
                          key={`filter-${filterGroupIndex}-${filterIndex}`}
                        >
                          <List.Item>
                            <List.Content>
                              <Checkbox
                                checked={isFilterChecked(filter)}
                                onChange={(event, { checked }): void => {
                                  toggleFilter(!!checked, filter);
                                }}
                              />
                              {filter.name}
                            </List.Content>
                          </List.Item>
                        </Fragment>
                      ))}
                    </List.List>
                  ) : null}
                </List.Content>
              </List.Item>
              {filterGroupIndex < filterGroups.length - 1 ? (
                <Divider style={{ margin: 0 }} />
              ) : null}
            </Fragment>
          ))}
        </List>
      </Dropdown.Menu>
    </StyledDropdown>
  );
};
