import React, { FunctionComponent, useCallback } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { ReplyProReviewFormContainer } from './reply-pro-review-form-container';
import { apiClient } from 'axios-client';

type ReplyProReviewFormRepositoryProps = {
  proReviewId: number;
  initialReply?: string;
  onCompleted: (reply?: string) => void;
  onRefused?: (reply: string) => void;
  type?: 'reply' | 'reply-moderation';
};

export const ReplyProReviewFormRepository: FunctionComponent<
  ReplyProReviewFormRepositoryProps
> = ({ proReviewId, onCompleted, onRefused, type = 'reply', initialReply }) => {
  const replyProReview = useCallback(
    async (reply: string) => {
      if (type === 'reply') {
        await apiClient
          .post(`/form/review-pro/reply`, {
            proReviewId,
            reply: reply.trim(),
          })
          .catch((error) => {
            ToastError('Erreur', "Impossible répondre à l'avis");
            throw error;
          });
        ToastSuccess('Succès', 'Réponse envoyée');
      }
      onCompleted(type === 'reply' ? undefined : reply);
    },
    [proReviewId],
  );

  return (
    <ReplyProReviewFormContainer
      replyProReview={replyProReview}
      refuseReply={onRefused}
      initialReply={initialReply}
      type={type}
    />
  );
};
