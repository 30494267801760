import { Formik } from 'formik';
import { AccountFormUpdateQuery, AccountSetInput } from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import { AccountFormFields } from './account-form-field.type';
import { AccountFormModalProps } from './account-form-modal';
import accountFormValidationSchema from './account-form-validation-schema';
import AccountFormView from './account-form-view';

type AccountFormUpdateContainerProps = AccountFormModalProps & {
  updateAccount: (accountInput: AccountSetInput) => Promise<void>;
  account: AccountFormUpdateQuery['accountByPk'];
};

const AccountFormUpdateContainer: FunctionComponent<
  AccountFormUpdateContainerProps
> = ({ updateAccount, account, onClose, onAccountUpdate, ...rest }) => {
  const onSubmit = useCallback(
    async (values: AccountFormFields) => {
      await updateAccount({
        email: values.email,
      }).catch((error) => {
        ToastError('Erreur', "Impossible d'enregister l'adresse email");
        throw error;
      });

      if (onAccountUpdate) {
        onAccountUpdate();
      }

      if (onClose) {
        onClose();
      }

      ToastSuccess('Succès', 'Adresse email modifié');
    },
    [updateAccount, onAccountUpdate, onClose],
  );

  if (!account) {
    return (
      <Modal.Content>
        <Loader active inline="centered" />
      </Modal.Content>
    );
  }

  const initialValues: AccountFormFields = {
    email: account.email,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={accountFormValidationSchema(account.email)}
    >
      <AccountFormView onClose={onClose} {...rest} />
    </Formik>
  );
};

export default AccountFormUpdateContainer;
