import {
  Button,
  Form,
  FormField,
  Modal,
  TextAreaProps,
} from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';
import { GloballyBlockedIdentity } from 'generated/graphql';
import FieldError from 'components/field-error/field-error';
import { FormInputLabel } from 'components/form-field/form-field';
import { formatTelephone } from 'utils';

type GloballyBlockedViewProps = {
  email?: string;
  telephone?: string;
  isBlocked?: boolean;
  globallyBlockedIdentities: GloballyBlockedIdentity[];
  unBlockEntity: () => Promise<void>;
  blockEntity: () => Promise<void>;
  reason?: string;
  isModalOpened: boolean;
  trigger?: React.ReactElement;
  closeModal: () => void;
  onReasonChange: (
    event: React.FormEvent<HTMLTextAreaElement>,
    data: TextAreaProps,
  ) => void;
  isLoading: boolean;
};

export const GloballyBlockedView: FunctionComponent<
  GloballyBlockedViewProps
> = ({
  unBlockEntity,
  isBlocked,
  globallyBlockedIdentities,
  closeModal,
  onReasonChange,
  blockEntity,
  email,
  telephone,
  isModalOpened,
  trigger,
  reason,
  isLoading,
}) => {
  return !isBlocked ? (
    <Modal open={isModalOpened} trigger={trigger}>
      <Modal.Header>Bloquer le particulier</Modal.Header>

      <Modal.Content>
        <p>Vous vous apprêtez à bloquer le/les identitiée(s) suivante(s) : </p>
        <ul>
          {email ? <li>{email}</li> : null}
          {telephone ? <li> {formatTelephone(telephone)}</li> : null}
        </ul>
        <strong>Ce que la personne ne pourra plus faire/recevoir :</strong>
        <ul>
          {/* There is an email in the blocked identity list */}
          {email ? (
            <>
              <li>Envoyer des demandes mails aux pros.</li>
              <li>Recevoir les mails de suivis.</li>
              <li>
                Accèder à son HUB particulier ainsi qu&apos;uitliser les actions
                de relance.
              </li>
            </>
          ) : null}

          {/* There is a telephone in the blocked identity list */}
          {telephone ? (
            <>
              <li>Appeler les pros.</li>
              <li>Envoyer des SMS aux pros.</li>
              <li>Recevoir les SMS1 et SMS2.</li>
            </>
          ) : null}
        </ul>
        Etes-vous sûr de vouloir faire cela ? Si oui, veuillez entrer la raison
        du bloquage :
        <Form style={{ marginTop: '12px' }}>
          <FormField required error={reason === ''}>
            <FormInputLabel
              helpText="Cela permettra de se rappeler de la raison du blocage"
              name="blockedReason"
              label="Raison du blocage"
            />
            <Form.TextArea
              id="blockedReason"
              style={reason === '' ? { marginBottom: '-10px' } : undefined}
              onChange={onReasonChange}
              value={reason}
            />
            {reason === '' ? (
              <FieldError>Raison du bloquage requise !</FieldError>
            ) : null}
          </FormField>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={closeModal}>Fermer</Button>
        <Button
          primary
          disabled={!reason}
          onClick={blockEntity}
          loading={isLoading}
        >
          Bloquer
        </Button>
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal open={isModalOpened} trigger={trigger}>
      <Modal.Header>Débloquer le particulier</Modal.Header>

      <Modal.Content>
        <p>Vous vous apprêtez à débloquer le/les identitiée(s) suivante(s) :</p>
        <ul>
          {globallyBlockedIdentities.map((blockedIdentity) => (
            <li key={blockedIdentity.id}>
              {blockedIdentity.identifier.split(':')[1]} (raison :{' '}
              {blockedIdentity.reason})
            </li>
          ))}
        </ul>
        <strong>Ce que la personne pourra de nouveau faire/recevoir :</strong>
        <ul>
          {/* There is an email in the blocked identity list */}
          {email ? (
            <>
              <li>Envoyer des demandes mails aux pros.</li>
              <li>Recevoir les mails de suivis.</li>
              <li>
                Accèder à son HUB particulier ainsi qu&apos;uitliser les actions
                de relance.
              </li>
            </>
          ) : null}

          {/* There is a telephone in the blocked identity list */}
          {telephone ? (
            <>
              <li>Appeler les pros.</li>
              <li>Envoyer des SMS aux pros.</li>
              <li>Recevoir les SMS1 et SMS2.</li>
              <li>Envoyer une demande mail</li>
            </>
          ) : null}
        </ul>
        <p>
          Pensez à <b>renvoyer les précédents SMS bloqués</b> si nécessaire.{' '}
        </p>
        <p>Etes-vous sûr de vouloir faire cela ?</p>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={closeModal}>Fermer</Button>
        <Button primary onClick={unBlockEntity} loading={isLoading}>
          Débloquer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
