import {
  AccountSetInput,
  useAccountFormUpdateMutation,
  useAccountFormUpdateQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import {
  getKeycloakUserIdByEmail,
  ToastError,
  updateKeycloakUser,
} from 'utils';
import { AccountFormModalProps } from './account-form-modal';
import AccountFormUpdateContainer from './account-form-update-container';

type AccountFormUpdateRepositoryProps = AccountFormModalProps & {
  accountId: number;
};

const AccountFormUpdateRepository: FunctionComponent<
  AccountFormUpdateRepositoryProps
> = ({ accountId, ...rest }) => {
  const { data } = useAccountFormUpdateQuery({
    variables: {
      id: accountId,
    },
  });

  const [updateAccountFormModalMutation] = useAccountFormUpdateMutation();

  const updateAccount = useCallback(
    async (accountInput: AccountSetInput) => {
      const account = data?.accountByPk;

      if (!account) {
        return;
      }

      // Find Keycloak user
      const keycloakUserId = await getKeycloakUserIdByEmail(account.email);

      if (!keycloakUserId) {
        ToastError(
          'Erreur',
          `Impossible de trouver l'utilisateur keycloak correspondant: ${account.email}`,
        );
        throw new Error(
          `Cannot find any Keycloak User with email: ${account.email}`,
        );
      }

      // Update Keyclaok user

      const email =
        accountInput.email != account.email ? accountInput.email : undefined;

      await updateKeycloakUser(keycloakUserId, {
        email,
        username: email,
      });

      // Update entity
      await updateAccountFormModalMutation({
        variables: {
          id: account.id,
          account: accountInput,
        },
      });
    },
    [accountId, data?.accountByPk],
  );

  return (
    <AccountFormUpdateContainer
      account={data?.accountByPk}
      updateAccount={updateAccount}
      {...rest}
    />
  );
};

export default AccountFormUpdateRepository;
