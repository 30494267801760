import { Button, Divider, Icon, Label, List, Modal } from 'semantic-ui-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { find } from 'remeda';
import { MongoEvent } from 'interfaces/events.interface';
import TimelineEmailAction from './timeline-email-action';
import { ToastError } from 'utils/toast';
import HelpText from 'components/help-text/help-text';
import { apiClient } from 'axios-client';

type Props = {
  messageId: string;
  eventDate: string;
  link?: {
    url: string;
    text: string;
  };
};

const TimelineEmailPreview: FunctionComponent<Props> = ({
  messageId,
  eventDate,
  link,
}) => {
  const [activitiesModal, setActivitiesModal] = useState<boolean>(false);
  const [emailEvents, setEmailEvents] = useState<MongoEvent[]>([]);
  const [delivered, setDelivered] = useState<boolean>(false);
  const [bounced, setBounced] = useState<boolean>(false);

  useEffect(() => {
    apiClient
      .get(`/event/messageId/${messageId}`, {})
      .then((result) => {
        const data: MongoEvent[] = result.data;

        if (!!find(data, (n) => n.type === 'EmailDeliveryReceived')) {
          setDelivered(true);
        }

        if (!!find(data, (n) => n.type === 'EmailBounceReceived')) {
          setBounced(true);
        }
        setEmailEvents(result.data);
      })
      .catch((error) => {
        ToastError('Erreur', `HTTP Error ${error.message}`);
      });
  }, [messageId]);

  return (
    <>
      {' '}
      {link ? (
        <Label
          as="a"
          onClick={(): void => {
            window.open(link.url, '_blank');
          }}
        >
          <Icon name="eye" size="small" />
          {link.text}
        </Label>
      ) : null}
      {bounced || !messageId ? (
        <Label color="red">
          <Icon name="circle" size="small" />
          Non reçu
        </Label>
      ) : null}
      {messageId ? (
        <Label
          as="a"
          onClick={(): void => {
            setActivitiesModal(true);
          }}
        >
          <Icon name="info circle" size="small" />
          Suivi mail
        </Label>
      ) : null}
      <Modal
        open={activitiesModal}
        onClose={(): void => {
          setActivitiesModal(false);
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Suivi du mail envoyé au particulier</Modal.Header>
        <Modal.Content>
          <p>
            <b>ID Postmark : </b> {messageId}
          </p>
          <Divider />
          <List>
            {emailEvents && emailEvents.length > 0
              ? emailEvents.map((event) => {
                  switch (event.type) {
                    case 'EmailDeliveryReceived':
                      return (
                        <TimelineEmailAction
                          key={event._id}
                          date={event.date}
                          text="Reçu"
                          color="green"
                          icon="envelope"
                        />
                      );
                    case 'EmailBounceReceived':
                      return (
                        <TimelineEmailAction
                          key={event._id}
                          date={event.date}
                          text="Non reçu"
                          color="red"
                          icon="remove"
                        />
                      );
                    case 'EmailOpenedReceived':
                      return (
                        <TimelineEmailAction
                          key={event._id}
                          date={event.date}
                          text="Ouvert"
                          color="blue"
                          icon="envelope open"
                        />
                      );
                    case 'EmailClickReceived':
                      return (
                        <TimelineEmailAction
                          key={event._id}
                          date={event.date}
                          text="Cliqué"
                          color="purple"
                          icon="mouse pointer"
                        />
                      );
                    default:
                      return null;
                  }
                })
              : null}
            <TimelineEmailAction
              date={eventDate}
              text="Envoyé"
              color="grey"
              icon="send"
            />
          </List>
          {!delivered && !bounced ? (
            <HelpText>
              <Icon name="warning sign" />
              Envoyé ne veut pas forcément dire que le destinataire à reçu le
              mail.
            </HelpText>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              setActivitiesModal(false);
            }}
          >
            Fermer
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TimelineEmailPreview;
