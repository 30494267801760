import React, { FunctionComponent } from 'react';

import { BilikPeopleTableQuery } from 'generated/graphql';
import BilikPeopleTableView from './bilik-people-table-view';
import { Loader, Message } from 'semantic-ui-react';

type BilikPeopleTableContainerProps = {
  loading: boolean;
  bilikPeople: BilikPeopleTableQuery['bilikPerson'];
};

const BilikPeopleTableContainer: FunctionComponent<
  BilikPeopleTableContainerProps
> = ({ loading, bilikPeople }) => {
  if (!loading && !bilikPeople.length) {
    return <Message>Il n&apos;y a pas de résultats</Message>;
  }

  if (loading && !bilikPeople.length) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return <BilikPeopleTableView loading={loading} bilikPeople={bilikPeople} />;
};

export default BilikPeopleTableContainer;
