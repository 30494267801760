import Axios from 'axios';
import { apiClient } from 'axios-client';
import env from 'env';
import React, { FunctionComponent, useCallback } from 'react';
import { ProGalleryFieldContainer } from './pro-gallery-field-container';
import { UploadImageResponse } from './pro-gallery-field-type';

type ProGalleryFieldRepositoryProps = {
  name: string;
};

/**
 * @param name name of the medias  field in the formik form
 *
 * Responsible of uploading new dropped images, sorting images (position) and delete field images.
 */
export const ProGalleryFieldRepository: FunctionComponent<
  ProGalleryFieldRepositoryProps
> = ({ name }) => {
  const uploadImage = useCallback(
    async (image: File): Promise<UploadImageResponse> => {
      const formData = new FormData();

      formData.append('file', image);

      const response = await apiClient.request({
        url: `${env.API_URL}/images`,
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    },
    [],
  );

  return <ProGalleryFieldContainer name={name} uploadImage={uploadImage} />;
};
