import FormField from 'components/form-field/form-field';
import SelectZohoOrganizationField from 'components/select-zoho-organization-field/select-zoho-organization-field';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { ProMandateFormFields } from './pro-mandate-form-fields-type';
import { ProMandateFormProps } from './pro-mandate-form-modal';

type ProMandateFormViewProps = ProMandateFormProps;

const ProMandateFormView: FunctionComponent<ProMandateFormViewProps> = ({
  open,
  onClose,
  existingProMandates,
}) => {
  const {
    isSubmitting,
    handleSubmit,
    handleReset,
    submitForm,
    values,
    errors,
  } = useFormikContext<ProMandateFormFields>();

  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);

  return (
    <>
      <Modal
        open={open}
        onClose={(): void => {
          if (onClose) {
            onClose();
          }
        }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeIcon
      >
        <Modal.Header>Nouveau mandat</Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit} onReset={handleReset}>
            <SelectZohoOrganizationField
              label="Entreprise Bilik Rattachée"
              name="zohoOrganizationId"
              clearable
              required
              selection
              search
            />
            <FormField type="text" label="Iban" name="iban" required />
            <FormField
              type="text"
              label="Bic"
              name="bic"
              helpText={
                <a
                  href="https://www.ibancalculator.com/iban_validieren.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Obtenir le BIC via l&apos;IBAN ici
                </a>
              }
              required
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              if (onClose) {
                onClose();
              }
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            disabled={
              isSubmitting ||
              !!Object.keys(errors).length ||
              !values?.zohoOrganizationId ||
              !values?.iban ||
              !values?.bic
            }
            loading={isSubmitting}
            type="button"
            onClick={() => {
              const proMandateWithSameZohoOrganization =
                existingProMandates?.filter(
                  (proMandate) =>
                    proMandate?.zohoOrganizationId ===
                    values?.zohoOrganizationId,
                );

              if (proMandateWithSameZohoOrganization?.length) {
                setIsWarningModalOpen(true);
              } else {
                submitForm();
              }
            }}
          >
            <Icon name="save" />
            Enregistrer
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={isWarningModalOpen}>
        <Modal.Header>Attention</Modal.Header>
        <Modal.Content>
          <p>
            Un mandat attaché à cette entreprise BILIK existe déjà pour ce
            client. Il sera archivé automatiquement. Continuer ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              setIsWarningModalOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            type="button"
            onClick={(): void => {
              submitForm();
              setIsWarningModalOpen(false);
            }}
          >
            Continuer
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProMandateFormView;
