import React, { FunctionComponent } from 'react';
import AccountFormCreateRepository from './account-form-create-repository';
import AccountFormUpdateRepository from './account-form-update-repository';

export type AccountFormModalProps = {
  open: boolean;
  onClose: () => void;
  onAccountUpdate?: () => void;
};

type AccountFormProps = AccountFormModalProps & {
  accountId?: number;
  onAccountCreated?: (accountId: number) => void;
};

const AccountFormModal: FunctionComponent<AccountFormProps> = ({
  accountId,
  onAccountCreated,
  ...rest
}) => (
  <>
    {accountId ? (
      <AccountFormUpdateRepository accountId={accountId} {...rest} />
    ) : (
      <AccountFormCreateRepository
        onAccountCreated={onAccountCreated}
        {...rest}
      />
    )}
  </>
);

export default AccountFormModal;
