import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { getBilikUserName } from 'utils/get-bilik-username';
import ModalBlockedIdentityInfo from './modal-blocked-identity-info';
import { formatTelephone } from 'utils';

type Props = {
  event: MongoEvent;
};

const SuspiciousIdentityCreated: FunctionComponent<Props> = ({ event }) => {
  const [entity, value] = event.data.suspiciousIdentity.identifier.split(':');

  return (
    <TimelineEvent
      date={event.date}
      color="red"
      icon="warning"
      title="Suspicion de spam"
      initiator="bilik-user"
      bilikUserName={
        event.initiator.bilik.familyName === 'Automatique'
          ? 'bot'
          : getBilikUserName(event.initiator.bilik)
      }
    >
      <h3>
        {event.initiator.bilik.familyName === 'Automatique' ? (
          <span>Le système</span>
        ) : (
          <span>
            {event.initiator.bilik.givenName} {event.initiator.bilik.familyName}
          </span>
        )}{' '}
        a déclaré une suspicion de spam pour le numéro{' '}
        {entity === 'telephone' ? formatTelephone(value) : <span>{value}</span>}
      </h3>
      <div>
        <p>
          <b>Raison : </b>
          {event.data.suspiciousIdentity.reason}
          <ModalBlockedIdentityInfo
            reason={event.data.suspiciousIdentity.reason}
          />
        </p>
      </div>
    </TimelineEvent>
  );
};

export default SuspiciousIdentityCreated;
