import React, { FunctionComponent } from 'react';

import { MongoEvent } from 'interfaces/events.interface';
import TimelineEvent from '../timeline-event';
import { Icon, Label } from 'semantic-ui-react';
import env from 'env';
import TimelineSolicitationActions from '../timeline-solicitation-actions';

type FormContinueSolicitationProps = {
  event: MongoEvent;
};

const FormContinueSolicitation: FunctionComponent<
  FormContinueSolicitationProps
> = ({ event }) => {
  return (
    <TimelineEvent
      date={event.date}
      icon="mail"
      title="Suivi prolongé"
      initiator="particulier"
    >
      <h3>
        Prolongation du suivi de la demande faite à{' '}
        <span>
          {event.actors.pro.proPresentation.name} (
          {event.actors.pro.proView.bilikZone.mainCityCode} -{' '}
          {event.actors.pro.proPresentation.mainTrade?.name})
        </span>
      </h3>
      <div>
        <TimelineSolicitationActions solicitation={event.data.solicitation} />
      </div>
    </TimelineEvent>
  );
};

export default FormContinueSolicitation;
